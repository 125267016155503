export const countryCategoriesCollation = [
    { id: 93, name: 'Эфиопия', slug: 'coffee-ethiopia', page: 'efiopiya', pageId: 273, mapClass: 'af-class-efiopia' },
    { id: 97, name: 'Руанда', slug: 'coffee-rwanda', page: 'ruanda', pageId: 312, mapClass: 'af-class-ruanda' },
    { id: 90, name: 'Кения', slug: 'coffee-kenya', page: 'keniya', pageId: 310, mapClass: 'af-class-kenia' },
    { id: 105, name: 'Перу', slug: 'kofe-peru', page: 'peru', pageId: 318, mapClass: 'af-class-peru' },
    { id: 92, name: 'Колумбия', slug: 'coffee-colombia', page: 'kolumbiya', pageId: 304, mapClass: 'af-class-columbia' },
    { id: 98, name: 'Индонезия', slug: 'coffee-indonesia', page: 'indoneziya', pageId: 298, mapClass: 'af-class-indonesia' },
    { id: 99, name: 'Бразилия', slug: 'kofe-braziliya', page: 'brazilia', pageId: 308, mapClass: 'af-class-brazilia' },
    { id: 411, name: 'Уганда', slug: 'uganda', page: 'uganda', pageId: 322, mapClass: 'af-class-uganda' },
    
    { id: 409, name: 'Боливия', slug: 'bolivia', page: 'bolivia', pageId: 12526, mapClass: 'af-class-bolivia' },
    { id: 410, name: 'Бурунди', slug: 'burudi', page: 'burundi', pageId: 11743, mapClass: 'af-class-burundi' },
    { id: 91, name: 'Коста-Рика', slug: 'coffee-costa-rica', pageId: 300, page: 'kosta-rika', mapClass: 'af-class-costa-rica' },
    { id: 94, name: 'Сальвадор', slug: 'coffee-salvador', pageId: 296, page: 'salvador', mapClass: 'af-class-salvador' },

    { id: 449, name: 'Мексика', slug: 'coffee-mexico', pageId: 17551, page: 'mexico', mapClass: 'af-class-mexico' },

    { id: 95, name: 'Гондурас', slug: 'coffee-honduras', page: 'gonduras', pageId: 306, mapClass: 'af-class-gonduras' },
    { id: 96, name: 'Гватемала', slug: 'kofe-gvatemala', page: 'gvatemela', pageId: 314, mapClass: 'af-class-gvatemala' },
]