import { useState } from 'react'

export default function useFavorites() {
  const getFavorites = () => {
    const dataString = localStorage.getItem('favoriteItems')
    if (dataString) {
      try {
        const data = JSON.parse(dataString)
        return data
      } catch {
        return []
      }
    } else {
      return []
    }
  }

  const [data, setData] = useState(getFavorites())

  const addFavoriteItem = id => {
    const oldData = getFavorites()
    const newData = [...oldData, id]
    localStorage.setItem('favoriteItems', JSON.stringify(newData))
    setData(newData)
  }

  const removeFavoriteItem = id => {
    const oldData = getFavorites()
    const newData = oldData.filter(elem => elem != id)
    if (!newData) {
      newData = []
    }
    localStorage.setItem('favoriteItems', JSON.stringify(newData))
    setData(newData)
  }

  return {
    favoriteItems: data,
    addFavoriteItem: addFavoriteItem,
    removeFavoriteItem: removeFavoriteItem,
  }
}