import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import css from './Header.module.css'
import DropdownNav from './DropdownNav/DropdownNav';
import { MainContext } from '../contexts/MainContext'
import { useNavigate, useLocation } from 'react-router-dom'
import useToken from '../hooks/useToken.js'

export default function Header() {
  const navigate = useNavigate()
  const location = useLocation()

  const { token } = useToken();

  const [isSearching, setSearching] = useState()
  const [isOver, setOver] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [contextMenuNav, setOpenContextMenuNav] = useState(false)
  const mainContext = useContext(MainContext)

  const [ cartItems, setCartItems ] = useState(0)

  useEffect(() => {
    setCartItems(mainContext.cartItems.reduce((accumulator, currentValue) => accumulator + currentValue.cartQty, 0))
  }, [mainContext.cartItems])

  const links = (
    <>
          <Link to="/catalog/coffee" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Кофе в зёрнах</Link>
          <Link to="/catalog/coffee-drips" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Кофе в дрип-пакетах</Link>
          <Link to="/catalog/coffee-capsules" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Кофе в капсулах</Link>
          <Link to="/catalog/tea" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Чай Keen Tiger</Link>
          <Link to="/catalog/notcoffee" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Не кофе</Link>
          <Link to="/catalog/accessories" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Аксессуары</Link>
          {/* <Link to="/catalog/black-friday" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Black Friday</Link> */}
          <Link to="/catalog/archive" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Архив</Link>
    </>
  )

  const educationLinks = (
    <>
      <Link to="/education-all" onClick={() => setOver(false)} id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Курсы и мастер-классы</Link>
      <Link to="/knowledge" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Статьи и способы заваривания</Link>
      <Link to="/coffee-dictionary" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" className="af-class-text-dropdown-grey w-dropdown-link">Кофейный словарь</Link>
    </>
  )

  const hiddenLinks = (
    <>
      {/* <Link to="/shipping-and-payment" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" onClick={() => setOver(false)} className="af-class-text-dropdown-grey w-dropdown-link af-class-dostavka">Доставка и оплата</Link> */}
      <Link to='/coffee-shops' id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" onClick={() => setOver(false)} className="af-class-text-dropdown-grey w-dropdown-link af-class-our-projects">Наши проекты</Link>
      <Link to="/faq" id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" onClick={() => setOver(false)} className="af-class-text-dropdown-grey w-dropdown-link af-class-faq">FAQ</Link>
      <Link to='/reviews' id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" onClick={() => setOver(false)} className="af-class-text-dropdown-grey w-dropdown-link af-class-otzyvy">Отзывы</Link>
      <Link to='/about-us' id="w-node-d92aac32-243c-8609-f19d-a8d88170a86d-3733fd82" onClick={() => setOver(false)} className="af-class-text-dropdown-grey w-dropdown-link af-class-o-nas">О нас</Link>
    </>
  )

  const [ stringSearchProducts, setStringSearchProducts ] = useState('')
  const handleChangeSearchProducts = (value) => {
    setStringSearchProducts(value)
  }
  const handleSubmitSearchProducts = (e) => {
    e.preventDefault()
    setSearching(false)
    if (location.pathname == '/catalog/tea') {
      navigate(`/catalog/tea?search=${stringSearchProducts}`)
    } else if (location.pathname == '/catalog/accessories') {
      navigate(`/catalog/accessories?search=${stringSearchProducts}`)
    } else if (location.pathname == '/catalog/merch') {
      navigate(`/catalog/merch?search=${stringSearchProducts}`)
    } else if (location.pathname == '/education-all') {
      navigate(`/education-all?search=${stringSearchProducts}`)
    } else if (location.pathname == '/knowledge') {
      navigate(`/knowledge?search=${stringSearchProducts}`)
    } else {
      navigate(`/catalog/coffee?search=${stringSearchProducts}`)
    }
    
  }

  const categoriesCoffee = [ 18, 19, 107, 101, 416, 104 ]
  const categoriesNotCoffee = [ 447, 448 ]
  const categoriesTea = [ 25, 109, 108, 110, 450 ] // 381
  const categoriesAccessories = [ 112, 440, 115, 121, 26, 27, 122, 442 ] // 26, 116, 117, 118 // 439, 441
  // const categoriesMerch = [ 27, 122, 123 ]

  const [ headerDropdownMenuOpened, setHeaderDropdownMenuOpened ] = useState(false)

  const openMobileNavDrawer = (e) => {
    e.target.parentNode.parentNode.classList.toggle('af-dl-active');
  }

  const showBlackFriday = () => {
    // return true if today is between 24.11 and 26.11
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    return (month == 10 && day >= 24 && day <= 26);
  }

  const showNewYear = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    return ((month == 11 && day >= 11) || (month == 0 && day <= 15));
  }

  // const [toplineSeen, setToplineSeen] = useState(false);

  // const handleCloseTopline = () => {
  //   localStorage.setItem('toplineSeen', '1');
  //   setToplineSeen(true);
  // }

  // const toplineAnnouncement = useRef();

  // const onScroll = () => {
  //   toplineAnnouncement.current.style.top = document.documentElement.scrollTop + 'px';
  // }

  // useEffect(() => {
  //   window.removeEventListener('scroll', onScroll);
  //   window.addEventListener('scroll', onScroll, { passive: true });
  //   return () => window.removeEventListener('scroll', onScroll);
  // }, []);

    return (
      <>
        {/* {((toplineSeen !== true && localStorage.getItem('toplineSeen') !== '1') || (token && token.token)) && <div
          ref={toplineAnnouncement}
          style={{
            backgroundColor: '#1D71B8', 
            color: '#fff',
            fontSize: '15px',
            lineHeight: '18px',
            padding: '16px 0',
            textAlign: 'center',
            width: '100%',
            position: 'relative',
            zIndex: 7500,
            paddingRight: '53px',
            paddingLeft: '15px',
          }}
        >
          <Link to="/register" style={{ color: '#fff', textDecoration: 'underline' }}>Зарегистрируйтесь</Link> и получите скидку 10% на кофе промокоду <b>new</b>
          <div style={{
            position: 'absolute',
            top: '50%',
            right: '15px',
            transform: 'translateY(-50%)',
            cursor: 'pointer'
          }} onClick={() => handleCloseTopline()}>
            <img src="images/icon--close-popup--white.svg" alt="" />
          </div>
        </div>} */}

        <header bind="527c49ee-e33e-a784-6886-2eb23733fd82" data-w-id="527c49ee-e33e-a784-6886-2eb23733fd82" className={"af-class-header-section af-class-an-load--1 af-class-wf-section" + ` ${css.header} ${menuOpen ? css.fixed : ''}`}>
          <div className="af-class-container af-class-header w-container">
            <div className="af-class-left_header_wrap">
              <Link to="/" onClick={() => setOver(false)} className="af-class-logo-left-header-wrap w-inline-block"><img src={showNewYear() == true ? `/images/logo-ny2.svg` : `/images/logo.png`} loading="lazy" width={80} alt="" className="af-class-logo-left-header" /></Link>
              <div className="af-class-links-left-wrapper">
                <div className="af-view af-class-container">
                  <DropdownNav titleLink={"Каталог"} links={links} />
                </div>
                {/* <div className="af-class-header__dropdown-toggle w-dropdown-toggle" style={{ cursor: 'pointer', fontSize: '13px', fontWeight: 'bold' }} onClick={() => setHeaderDropdownMenuOpened(!headerDropdownMenuOpened)} onMouseOver={() => setHeaderDropdownMenuOpened(true)}>
                    <div className="af-class-header__dropdown-text--options">Каталог</div>
                    <img src="/images/arrow-down.svg" loading="lazy" width="9" alt="" className="af-class-header__dropdown-icon--options" />
                </div> */}
                {showNewYear() == true && <Link to="/catalog/newyear" onClick={() => setOver(false)} className="af-class-link-header af-class-dostavka af-class-link-header-new-year">{/* <div className="af-class-link-header-new-year-background"></div> */}Новый год</Link>}
                {showBlackFriday() == true && <Link to="/catalog/black-friday" onClick={() => setOver(false)} className="af-class-link-header">Black Friday</Link>}
                <Link to="/shipping-and-payment" onClick={() => setOver(false)} className="af-class-link-header af-class-dostavka">Доставка и оплата</Link>
                <a href='https://b2b-rockets.coffee' target='_blank' className="af-class-link-header">Сотрудничество</a>
                <Link to="/loyalty-system" onClick={() => setOver(false)} className="af-class-link-header">Бонусная система</Link>
                <Link to='/coffee-shops' onClick={() => setOver(false)} className="af-class-link-header af-class-our-projects">Наши проекты</Link>
                <Link to="/faq" onClick={() => setOver(false)} className="af-class-link-header af-class-faq">FAQ</Link>
                <DropdownNav titleLink={"Rockets.school"} links={educationLinks} col />
                <Link to='/reviews' onClick={() => setOver(false)} className="af-class-link-header af-class-otzyvy">Отзывы</Link>
                <Link to='/about-us' onClick={() => setOver(false)} className="af-class-link-header af-class-o-nas">О нас</Link>
                <Link to="/contacts" onClick={() => setOver(false)} className="af-class-link-header">Контакты</Link>

                <DropdownNav className="header-links-hidden-container" titleLink={"..."} links={hiddenLinks} col/>
              </div>
              <div className="af-class-nav-drawer--mobile__button-wrapper--open" style={!menuOpen ? {opacity: 1}: undefined}>
                <div data-w-id="8e51e58e-e933-6d28-d871-cb790e59fc98" className="af-class-nav-drawer--mobile__button--open">
                  <div className="af-class-button-nav_drawer-mobile af-class-open" onClick={() => setMenuOpen(val => !val)} style={{position: 'relative', zIndex: 50000000}}>
                    <div className="af-class-icon-wrapper-nav_drawer"><img src={`/images/icon-menu.svg`} loading="lazy" alt="" className="af-class-personal" /></div>
                    <div id="w-node-_527c49ee-e33e-a784-6886-2eb23733fd91-3733fd82" className="af-class-link-header">Меню</div>
                  </div>
                </div>
              </div>
              <div data-w-id="3169a444-1271-0ff8-ccd9-cb5d4d4db640" className="af-class-nav-drawer--mobile__button--close" style={menuOpen ? {opacity: 1}: undefined}>
                <div className="af-class-button-nav_drawer-mobile af-class-close" onClick={() => setMenuOpen(val => !val)}>
                  <div className="af-class-icon-wrapper-nav_drawer"><img src={`/images/icon-button_close.svg`} loading="lazy" alt="" className="af-class-personal" /></div>
                </div>
              </div>
            </div>
            <Link to="/" className="af-class-logo-center-header-wrap w-inline-block"><img src={showNewYear() == true ? `/images/logo-ny2.svg` : `/images/logo.png`} loading="lazy" width={80} alt="" className="af-class-logo-left-header" /></Link>
            <div className="af-class-right_header_wrap">
              <div className={"af-class-icons-wrapper" + ` ${css.iconsWrapper}`}>
                <div className={`${css.search} ${isSearching ? css.visible: undefined}`}>
                  <form onSubmit={handleSubmitSearchProducts}>
                    <input className={`${css.input}`} placeholder={location.pathname == '/education-all' ? 'Поиск курсов...' : location.pathname == '/knowledge' ? 'Поиск статей...' : 'Поиск товаров...'} value={stringSearchProducts} onChange={(e) => handleChangeSearchProducts(e.target.value)} />
                    <img src={`/images/search.png`} className={css.searchImage} alt="search" />
                    <div className={`${css.close} ${isSearching ? css.shown: undefined}`} onClick={() => setSearching(false)}></div>
                  </form>
                </div>
                <a href="#" onClick={() => setSearching(val => !val)} className="af-class-icon-wrapper af-class-search w-inline-block"><img src={`/images/search-ic.svg`} loading="lazy" width={16} alt="" className="af-class-search" /></a>
                <a onClick={() => setMenuOpen(false)} href={`tel:${mainContext.homePageData?.acf?.номер_в_шапке}`} className="af-class-icon-wrapper af-class-call w-inline-block"><img src={`/images/phone-ic.svg`} loading="lazy" width={15} alt="" className="af-class-phone" /></a>
                <Link onClick={() => setMenuOpen(false)} to="/me" className="af-class-icon-wrapper af-class-account w-inline-block">
                  {(!token || !token.token) ? <img src={`/images/user-ic.svg`} loading="lazy" width={17} alt="" className="af-class-personal" /> : (mainContext.userWpData?.acf?.avatar ? <img src={mainContext.userWpData?.acf?.avatar} style={{ width: '24px', height: '24px', borderRadius: '50%' }} /> : <img src={`/images/user-ic-blue.svg`} loading="lazy" width={17} alt="" className="af-class-personal" />)}
                </Link>
                <Link onClick={() => setMenuOpen(false)} to='/cart' className="af-class-icon-wrapper w-inline-block" style={{position: 'relative'}}>
                  { cartItems > 0 ? <div style={{position: 'absolute', top: '-12px', right: '-10px', height: '24px', width: '24px', backgroundColor: '#4a97ca', color: '#fff', fontSize: '12px', lineHeight: '24px', textAlign: 'center', borderRadius: '50%'}}>{cartItems}</div> : <></> }
                  <img src={`/images/basket-ic.svg`} loading="lazy" width={17} alt="" className="af-class-cart" />
                </Link>
              </div>
            </div>
          </div>
          {/* <div className={`header-dropdown-menu${headerDropdownMenuOpened === true ? ' hdm-active' : ''}`} onMouseLeave={() => setHeaderDropdownMenuOpened(!headerDropdownMenuOpened)}>
            <div className="hdm-content">
              <ul>
                <li><Link to={`/catalog/coffee`}><b>Кофе</b></Link></li>
                { mainContext.headerMenuCategories.length > 0 && mainContext.headerMenuCategories.filter(elem => categoriesCoffee.includes(elem.id)).sort((a, b) => categoriesCoffee.indexOf(a.id) - categoriesCoffee.indexOf(b.id)).map(elem => <li key={elem.id}><Link to={`/catalog/coffee?category=${elem.slug}`}>{elem.name}</Link></li>) }
              </ul>
              <ul>
                <li><Link to={`/catalog/notcoffee`}><b>Не кофе</b></Link></li>
                { mainContext.headerMenuCategories.length > 0 && mainContext.headerMenuCategories.filter(elem => categoriesNotCoffee.includes(elem.id)).sort((a, b) => categoriesCoffee.indexOf(a.id) - categoriesCoffee.indexOf(b.id)).map(elem => <li key={elem.id}><Link to={`/catalog/notcoffee?category=${elem.slug}`}>{elem.name}</Link></li>) }
              </ul>
              <ul>
                <li><Link to={`/catalog/tea`}><b>Чай</b></Link></li>
                { mainContext.headerMenuCategories.length > 0 && mainContext.headerMenuCategories.filter(elem => categoriesTea.includes(elem.id)).sort((a, b) => categoriesTea.indexOf(a.id) - categoriesTea.indexOf(b.id)).map(elem => <li key={elem.id}><Link to={`/catalog/tea?category=${elem.slug}`}>{elem.name}</Link></li>) }
              </ul>
              <ul>
                <li><Link to={`/catalog/accessories`}><b>Аксессуары</b></Link></li>
                { mainContext.headerMenuCategories.length > 0 && mainContext.headerMenuCategories.filter(elem => categoriesAccessories.includes(elem.id)).sort((a, b) => categoriesAccessories.indexOf(a.id) - categoriesAccessories.indexOf(b.id)).map(elem => {
                  if (elem.id != 27) {
                    return <li key={elem.id}><Link to={`/catalog/accessories?category=${elem.slug}`}>{elem.name}</Link></li>
                  } else {
                    return <li key={elem.id}><Link to={`/catalog/merch`}>Мерч</Link></li>
                  }
                } ) }
              </ul>
              <ul>
                <li><Link to={`/catalog/archive`}><b>Архив</b></Link></li>
              </ul>
            </div>
          </div> */}
        </header>

        <div className="header-fixed-padding" />

        <div bind="89b22b1e-363a-2a7a-eaf6-8816365e2166" className="af-class-nav-drawer-wrapper--mobile nav-mobile" style={menuOpen ? {display: 'flex'}: undefined}>
          <div className="af-class-nav-drawer--mobile">
            <a href="#" className="af-class-link_and_icon-nav__drawer w-inline-block" onClick={() => {
              setSearching(val => !val);
              setMenuOpen(false);
            }}>
              <div className="af-class-link-nav_drawer-mobile af-class-search">Поиск</div><img src="/images/search-ic.svg" loading="lazy" alt="" className="af-class-image-2" />
            </a>
            <div className="af-class-nav-drawer--mobile__dropdown-link">
              <a onClick={(e) => openMobileNavDrawer(e)} data-w-id="89b22b1e-363a-2a7a-eaf6-8816365e216d" href="#" className="af-class-link_and_icon-nav__drawer w-inline-block">
                <div className="af-class-link-nav_drawer-mobile af-class-catalog">Каталог</div>
                <img src="/images/arrow-down.svg" loading="lazy" alt="" className="af-class-icon-link-nav_drawer" />
              </a>
              <div className="af-class-dropdown__content-wrapper">
                <div className="af-class-content_grid-dropdown">
                  <Link onClick={() => setMenuOpen(false)} to="/catalog/coffee" className="af-class-link-nav_drawer-mobile af-class-dropdown">Кофе в зёрнах</Link>
                  <Link onClick={() => setMenuOpen(false)} to="/catalog/coffee-drips" className="af-class-link-nav_drawer-mobile af-class-dropdown">Кофе в дрип-пакетах</Link>
                  <Link onClick={() => setMenuOpen(false)} to="/catalog/coffee-capsules" className="af-class-link-nav_drawer-mobile af-class-dropdown">Кофе в капсулах</Link>
                  <Link onClick={() => setMenuOpen(false)} to="/catalog/tea" className="af-class-link-nav_drawer-mobile af-class-dropdown">Чай Keen Tiger</Link>
                  <Link onClick={() => setMenuOpen(false)} to="/catalog/notcoffee" className="af-class-link-nav_drawer-mobile af-class-dropdown">Не кофе</Link>
                  <Link onClick={() => setMenuOpen(false)} to="/catalog/accessories" className="af-class-link-nav_drawer-mobile af-class-dropdown">Аксессуары</Link>
                  {/* <Link onClick={() => setMenuOpen(false)} to="/catalog/merch" className="af-class-link-nav_drawer-mobile af-class-dropdown">Мерч</Link> */}
                  {/* <Link onClick={() => setMenuOpen(false)} to="/catalog/black-friday" className="af-class-link-nav_drawer-mobile af-class-dropdown">Black Friday</Link> */}
                </div>
              </div>
            </div>
            {showNewYear() == true && <Link onClick={() => setMenuOpen(false)} to="/catalog/newyear" className="af-class-link-nav_drawer-mobile">Новый год</Link>}
            {showBlackFriday() == true && <Link onClick={() => setMenuOpen(false)} to="/catalog/black-friday"className="af-class-link-nav_drawer-mobile">Black Friday</Link>}
            <Link onClick={() => setMenuOpen(false)} to="/shipping-and-payment" className="af-class-link-nav_drawer-mobile">Доставка и оплата</Link>
            <a onClick={() => setMenuOpen(false)} href='https://b2b-rockets.coffee' target='_blank' className="af-class-link-nav_drawer-mobile">Сотрудничество</a>
            <Link onClick={() => setMenuOpen(false)} to="/loyalty-system" className="af-class-link-nav_drawer-mobile">Бонусная система</Link>
            <Link onClick={() => setMenuOpen(false)} to='/coffee-shops' className="af-class-link-nav_drawer-mobile">Наши проекты</Link>
            <Link onClick={() => setMenuOpen(false)} to="/faq" className="af-class-link-nav_drawer-mobile">FAQ</Link>
            <Link onClick={() => setMenuOpen(false)} to="/about-us" className="af-class-link-nav_drawer-mobile">О нас</Link>
            <Link onClick={() => setMenuOpen(false)} to="/reviews" className="af-class-link-nav_drawer-mobile">Отзывы</Link>
            <div className="af-class-nav-drawer--mobile__dropdown-link">
              <a onClick={(e) => openMobileNavDrawer(e)} data-w-id="89b22b1e-363a-2a7a-eaf6-8816365e2184" href="#" className="af-class-link_and_icon-nav__drawer w-inline-block">
                <div className="af-class-link-nav_drawer-mobile af-class-catalog">Rockets.school</div><img src="/images/arrow-down.svg" loading="lazy" alt="" className="af-class-icon-link-nav_drawer" />
              </a>
              <div className="af-class-dropdown__content-wrapper">
                <div className="af-class-content_grid-dropdown">
                  <Link onClick={() => setMenuOpen(false)} to="/education-all" className="af-class-link-nav_drawer-mobile af-class-dropdown">Курсы и мастер-классы</Link>
                  <Link onClick={() => setMenuOpen(false)} to="/knowledge" className="af-class-link-nav_drawer-mobile af-class-dropdown">Статьи и способы заваривания</Link>
                  <Link onClick={() => setMenuOpen(false)} to="/coffee-dictionary" className="af-class-link-nav_drawer-mobile af-class-dropdown">Кофейный словарь</Link>
                </div>
              </div>
            </div>
            <Link onClick={() => setMenuOpen(false)} to="/contacts" className="af-class-link-nav_drawer-mobile">Контакты</Link>
            {/* <a href={`tel:${mainContext.homePageData?.acf?.номер_в_шапке}`} className="af-class-link-nav_drawer-mobile">
              <img src={`/images/fi_phone_1fi_phone.png`} alt="phone" width={15} style={{marginRight: 15}} />
              Позвонить
            </a>
            <Link onClick={() => setMenuOpen(false)} to="/me" className="af-class-link-nav_drawer-mobile">
              <img src={`/images/fi_user_1fi_user.png`} alt="phone" width={15} style={{marginRight: 15}} />
              Личный кабинет
            </Link> */}
          </div>
        </div>
      </>
    )
}