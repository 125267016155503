const Identificator = ({ count, size }) => {
    let className = 'af-class-product-card__specifications-indicator'
    if (size == 'big') {
      className = 'af-class-specifications__indicator'
    }

    if (count === 5) {
      return <>
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
      </>
    }
    if (count === 4) {
      return <>
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className}`} />
      </>
    }
    if (count === 3) {
      return <>
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className}`} />
        <div className={`${className}`} />
      </>
    }
    if (count === 2) {
      return <>
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className}`} />
        <div className={`${className}`} />
        <div className={`${className}`} />
      </>
    }
    if (count === 1) {
      return <>
        <div className={`${className} af-class-indicator__active`} />
        <div className={`${className}`} />
        <div className={`${className}`} />
        <div className={`${className}`} />
        <div className={`${className}`} />
      </>
    }
}

export default Identificator