import { useState } from 'react'

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('authToken')
    const userToken = JSON.parse(tokenString)
    return userToken
  }

  const getNonce = () => {
    const nonceString = localStorage.getItem('authNonce')
    return nonceString
  }

  const [token, setToken] = useState(getToken())
  const [nonce, setNonce] = useState(getNonce())

  const saveToken = userToken => {
    localStorage.setItem('authToken', JSON.stringify(userToken))
    setToken(userToken)
  }

  const saveNonce = userNonce => {
    localStorage.setItem('authNonce', userNonce)
    setNonce(userNonce)
  }

  return {
    token: token,
    setToken: saveToken,
    nonce: nonce,
    setNonce: saveNonce,
  }
}