import React from 'react'
import { useState, useEffect } from 'react'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import CoffeeShopsItem from '../CoffeeShopsItem/CoffeeShopsItem'

const CoffeeShopsFiltered = ({ activeTab, shops, images }) => {

    const [ filteredShops, setfilteredShops ] = useState([])

    useEffect(() => {
        if (activeTab == 'kids') {
            setfilteredShops(shops?.filter(shop => shop.cappuccino_kids == '1'))
        }
        else if (activeTab == 'withFood') {
            setfilteredShops(shops?.filter(shop => shop.coffee_with_food == '1'))
        }
        else if (activeTab == 'concept') {
            setfilteredShops(shops?.filter(shop => shop.rockets_concept_store == '1'))
        }
        else {
            setfilteredShops(shops)
        }
    }, [activeTab, shops])
    
    return (
        <>
                <div id="w-node-_7a9dc53f-1a1e-1716-bd12-f658b5bd80d2-343d3632" className="af-class-map--p-coffee-shops af-class-an-load--3">
                    <div className="af-class-map__location-wrapper shops__map">
                      <YMaps>
                        <Map defaultState={{center: [55.75, 37.57], zoom: 9}} width="100%" height="100%">
                          {filteredShops?.map((shop, index) => {
                            return <Placemark key={index} defaultGeometry={JSON.parse(shop.map_coordinates)} />
                            })}
                        </Map>
                      </YMaps>
                      {/* <div className="w-layout-grid af-class-map__location-discription">
                        <div id="w-node-_6367b8d9-f337-5b48-d7f1-71a12a70224f-343d3632" className="af-class-map__location-title">Кинотеатр «Звезда»</div>
                        <div id="w-node-fdb404f4-b509-3225-1bc0-d0680b2afd75-343d3632" className="w-layout-grid af-class-map__location-info">
                          <div id="w-node-_85f341b8-8e2c-97f2-9d40-c6736a076c02-343d3632" className="af-class-map__location-adress">Ул. Земляной вал, 18-22, стр 1</div>
                          <div id="w-node-_59c60257-5041-2f65-fec3-2e6949b1af54-343d3632" className="af-class-map__location-work-time">Пн-Вс 9.00-21.00</div>
                        </div>
                      </div>
                      <div className="af-class-map__location-icon" /> */}
                    </div>
                </div>
            <div id="w-node-_437c276b-ac7f-7fc1-79ec-100f061ad2a4-343d3632" className="w-layout-grid af-class-coffe-shop-list">
                {
                    filteredShops?.map((shop, index) => (
                        <CoffeeShopsItem {...shop} key={index} images={images} />
                    ))
                }
            </div>
        </>
    )
}

export default CoffeeShopsFiltered