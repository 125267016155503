/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

let Controller

class OrderConfirmationView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/OrderConfirmationController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = OrderConfirmationView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6261feaf89099885041ddd11'
    htmlEl.dataset['wfSite'] = '6261fc674c12d792773d3625'

    // scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
    //   const loading = active.loading.then((script) => {
    //     new Function(`
    //       with (this) {
    //         eval(arguments[0])
    //       }
    //     `).call(window, script)

    //     return next
    //   })

    //   return active.isAsync ? next : loading
    // }))
  }

  render() {
    const proxies = OrderConfirmationView.Controller !== OrderConfirmationView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rockets-coffee-export.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div bind="6261feaf8909987ef31ddd12aN" data-node-type="commerce-order-confirmation-wrapper" data-wf-order-query data-wf-page-link-href-prefix className="w-commerce-commerceorderconfirmationcontainer">
              <div className="w-commerce-commercelayoutcontainer w-container">
                <div className="w-commerce-commercelayoutmain">
                  <div className="w-commerce-commercecheckoutcustomerinfosummarywrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader">
                      <h4>Customer Information</h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <div className="w-commerce-commercecheckoutrow">
                        <div className="w-commerce-commercecheckoutcolumn">
                          <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Email</label>
                            <div bind="6261feaf8909987ef31ddd12000000000017" />
                          </div>
                        </div>
                        <div bind="6261feaf8909987ef31ddd12000000000018" className="w-commerce-commercecheckoutcolumn">
                          <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Shipping Address</label>
                            <div bind="6261feaf8909987ef31ddd1200000000001c" />
                            <div bind="6261feaf8909987ef31ddd1200000000001d" />
                            <div bind="6261feaf8909987ef31ddd1200000000001e" />
                            <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                              <div bind="6261feaf8909987ef31ddd12000000000020" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                              <div bind="6261feaf8909987ef31ddd12000000000021" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                              <div bind="6261feaf8909987ef31ddd12000000000022" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                            </div>
                            <div bind="6261feaf8909987ef31ddd12000000000023" />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div bind="6261feaf8909987ef31ddd12000000000024" className="w-commerce-commercecheckoutshippingsummarywrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader">
                      <h4>Shipping Method</h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <div className="w-commerce-commercecheckoutrow">
                        <div className="w-commerce-commercecheckoutcolumn">
                          <div className="w-commerce-commercecheckoutsummaryitem">
                            <div bind="6261feaf8909987ef31ddd1200000000002c" />
                            <div bind="6261feaf8909987ef31ddd1200000000002d" />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="w-commerce-commercecheckoutpaymentsummarywrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader">
                      <h4>Payment Info</h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <div className="w-commerce-commercecheckoutrow">
                        <div className="w-commerce-commercecheckoutcolumn">
                          <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Payment Info</label>
                            <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                              <div bind="6261feaf8909987ef31ddd12000000000039" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                              <div bind="6261feaf8909987ef31ddd1200000000003a" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                            </div>
                            <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                              <div bind="6261feaf8909987ef31ddd1200000000003c" />
                              <div bind="6261feaf8909987ef31ddd1200000000003d"> / </div>
                              <div bind="6261feaf8909987ef31ddd1200000000003f" />
                            </div>
                          </div>
                        </div>
                        <div className="w-commerce-commercecheckoutcolumn">
                          <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Billing Address</label>
                            <div bind="6261feaf8909987ef31ddd12000000000044" />
                            <div bind="6261feaf8909987ef31ddd12000000000045" />
                            <div bind="6261feaf8909987ef31ddd12000000000046" />
                            <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                              <div bind="6261feaf8909987ef31ddd12000000000048" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                              <div bind="6261feaf8909987ef31ddd12000000000049" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                              <div bind="6261feaf8909987ef31ddd1200000000004a" className="w-commerce-commercecheckoutsummarytextspacingondiv" />
                            </div>
                            <div bind="6261feaf8909987ef31ddd1200000000004b" />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="w-commerce-commercecheckoutorderitemswrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader">
                      <h4>Items in Order</h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <div template-bind="6261feaf8909987ef31ddd12000000000051" role="list" className="w-commerce-commercecheckoutorderitemslist" data-wf-collection="database.commerceOrder.userItems" data-wf-template-id="wf-template-6261feaf8909987ef31ddd12000000000051" />
                    </fieldset>
                  </div>
                </div>
                <div className="w-commerce-commercelayoutsidebar">
                  <div className="w-commerce-commercecheckoutordersummarywrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader">
                      <h4>Order Summary</h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <div className="w-commerce-commercecheckoutsummarylineitem">
                        <div>Subtotal</div>
                        <div bind="6261feaf8909987ef31ddd1200000000006a" />
                      </div>
                      <div className="w-commerce-commercecheckoutordersummaryextraitemslist" data-wf-collection="database.commerceOrder.extraItems" data-wf-template-id="wf-template-6261feaf8909987ef31ddd1200000000006b">
                        <div className="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                          <div bind="6261feaf8909987ef31ddd1200000000006d" />
                          <div bind="6261feaf8909987ef31ddd1200000000006e" />
                        </div>
                      </div>
                      <div className="w-commerce-commercecheckoutsummarylineitem">
                        <div>Total</div>
                        <div bind="6261feaf8909987ef31ddd12000000000072" className="w-commerce-commercecheckoutsummarytotal" />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default OrderConfirmationView

/* eslint-enable */