/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  // { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6261fc674c12d792773d3625").then(body => body.text()), isAsync: false },
  // { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class HomeCopyView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/HomeCopyController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = HomeCopyView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6261fc674c12d76d753d363a'
    htmlEl.dataset['wfSite'] = '6261fc674c12d792773d3625'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = HomeCopyView.Controller !== HomeCopyView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rockets-coffee-export.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-container af-class-header w-container">
                <div className="af-class-left_header_wrap">
                  <div className="af-class-catalog_wrap">
                    <a href="#" className="af-class-menu_link af-class-padding-3">Каталог</a><img src="images/Vector2_1Vector2.png" loading="lazy" width={9} alt="" className="af-class-catalog_img" />
                  </div>
                  <a href="#" className="af-class-menu_link">Сотрудничество</a>
                  <a href="#" className="af-class-menu_link">Система скидок</a>
                  <a href="#" className="af-class-menu_link af-class-last">О нас</a>
                </div>
                <div className="af-class-logo_wrap"><img src="images/logo_rockets_rgb_blue-2-14.png" loading="lazy" width={80} alt="" className="af-class-image" /></div>
                <div className="af-class-right_header_wrap">
                  <a href="#" className="af-class-menu_link">Доставка и оплата</a>
                  <a href="#" className="af-class-menu_link">Контакты</a>
                  <div className="af-class-icons_wrap">
                    <div className="af-class-icon_wrap"><img src="images/fi_search_1fi_search.png" loading="lazy" width={16} alt="" className="af-class-search" /></div>
                    <div className="af-class-icon_wrap"><img src="images/fi_phone_1fi_phone.png" loading="lazy" width={15} alt="" className="af-class-phone" /></div>
                    <div className="af-class-icon_wrap"><img src="images/fi_user_1fi_user.png" loading="lazy" width={17} alt="" className="af-class-personal" /></div>
                    <div className="af-class-icon_wrap af-class-last"><img src="images/Iconography---Caesarzkn_1Iconography---Caesarzkn.png" loading="lazy" width={17} alt="" className="af-class-cart" /></div>
                    <div className="af-class-burger_wrap"><img src="images/burger_1burger.png" loading="lazy" width={17} alt="" className="af-class-personal" /></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="af-class-content">
                  <div className="af-class-content__left-block">
                    <div className="af-class-product__tags_wrap">
                      <div className="af-class-left_tags_block">
                        <div className="af-class-sale">
                          <div className="af-class-text_tag1">SALE</div>
                        </div>
                        <div className="af-class-rockets">
                          <div className="af-class-text_tag2">Выбор Rockets</div>
                        </div>
                      </div>
                      <div className="af-class-star"><img src="images/Star-3_1Star-3.png" loading="lazy" width={34} alt="" className="af-class-star" /></div>
                    </div>
                    <div className="af-class-product__img_wrap"><img src="images/Frame-738001215-1.png" loading="lazy" srcSet="images/Frame-738001215-1-p-500.png 500w, images/Frame-738001215-1-p-800.png 800w, images/Frame-738001215-1.png 1016w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 48vw, 47vw" alt="" className="af-class-product__preview_img" /></div>
                  </div>
                  <div className="af-class-content__right-block">
                    <div className="af-class-h1">Kenya Mchana</div>
                    <div className="af-class-product__text_caption">Плотный кислотный сочный кофе с ярким комплексным ароматом. В аромате: фейхоа, яблочный сидр, малина, сухофрукты, цитрон.</div>
                    <div className="af-class-product__tags_block">
                      <div className="af-class-product__tags_ingredients">
                        <div className="af-class-text_tag3">Яблочный сидр</div>
                      </div>
                      <div className="af-class-product__tags_ingredients">
                        <div className="af-class-text_tag3">Малиновый джем</div>
                      </div>
                      <div className="af-class-product__tags_ingredients">
                        <div className="af-class-text_tag3">Апельсин</div>
                      </div>
                    </div>
                    <div className="af-class-specifications__wrap">
                      <div className="af-class-specifications__block">
                        <div className="af-class-indicator_text_wrap">
                          <div className="af-class-specifications__lable">Горечь</div>
                          <div className="af-class-question_circle">
                            <div className="af-class-question">?</div>
                          </div>
                        </div>
                        <div className="af-class-specifications__indicators">
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator" />
                          <div className="af-class-specifications__indicator" />
                          <div className="af-class-specifications__indicator" />
                        </div>
                      </div>
                      <div className="af-class-specifications__block">
                        <div className="af-class-indicator_text_wrap">
                          <div className="af-class-specifications__lable">Сладость</div>
                          <div className="af-class-question_circle">
                            <div className="af-class-question">?</div>
                          </div>
                        </div>
                        <div className="af-class-specifications__indicators">
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator" />
                        </div>
                      </div>
                      <div className="af-class-specifications__block">
                        <div className="af-class-indicator_text_wrap">
                          <div className="af-class-specifications__lable">Кислотность</div>
                          <div className="af-class-question_circle">
                            <div className="af-class-question">?</div>
                          </div>
                        </div>
                        <div className="af-class-specifications__indicators">
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                          <div className="af-class-specifications__indicator af-class-indicator__active" />
                        </div>
                      </div>
                    </div>
                    <div className="af-class-methods">
                      <div className="af-class-specifications__lable">Способы заваривания</div>
                      <div className="af-class-method_cards_wrap">
                        <div className="af-class-method_card"><img src="images/Group-738001209_1Group-738001209.png" loading="lazy" width={20} alt="" className="af-class-method_img" />
                          <div className="af-class-method_card__lable">Воронка</div>
                          <div className="af-class-question_circle af-class-absolute">
                            <div className="af-class-question">?</div>
                          </div>
                        </div>
                        <div className="af-class-method_card"><img src="images/Group_1Group.png" loading="lazy" width={22} height={25} alt="" className="af-class-method_img" />
                          <div className="af-class-method_card__lable">ГЕЙЗЕР</div>
                          <div className="af-class-question_circle af-class-absolute">
                            <div className="af-class-question">?</div>
                          </div>
                        </div>
                        <div className="af-class-method_card"><img src="images/Vector4_1Vector4.png" loading="lazy" width={23} alt="" className="af-class-method_img af-class-tur" />
                          <div className="af-class-method_card__lable">ТУРКА</div>
                          <div className="af-class-question_circle af-class-absolute">
                            <div className="af-class-question">?</div>
                          </div>
                        </div>
                        <div className="af-class-method_card"><img src="images/Vector_1Vector.png" loading="lazy" width={22} alt="" className="af-class-method_img" />
                          <div className="af-class-method_card__lable">Воронка</div>
                          <div className="af-class-question_circle af-class-absolute">
                            <div className="af-class-question">?</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-product__options-wrap">
                      <div className="af-class-product__options-block-wrap">
                        <div className="af-class-product__options-block">
                          <div className="af-class-option_text">250 г</div><img src="images/Vector1_1Vector1.png" loading="lazy" width={10} alt="" className="af-class-option_img" />
                        </div>
                        <div className="af-class-product__options-block">
                          <div className="af-class-option_text">В зернах</div><img src="images/Vector1_1Vector1.png" loading="lazy" width={10} alt="" className="af-class-option_img" />
                        </div>
                        <div className="af-class-product__options-block af-class-qty af-class-last">
                          <div className="af-class-option_text af-class-qty">–</div>
                          <div className="af-class-option_text af-class-qty">1</div>
                          <div className="af-class-option_text af-class-plus">+</div>
                        </div>
                      </div>
                      <div className="af-class-product__options-price-block">
                        <div className="af-class-product__price-regular">910₽</div>
                        <div className="af-class-product__price">881 ₽</div>
                      </div>
                    </div>
                    <a href="#" className="af-class-button w-button">Купить</a>
                    <div className="af-class-product__additional_block">
                      <div className="af-class-product__additional_text"><span className="af-class-text-span">Экспресс-доставка</span> за 2 часа сегодня.</div>
                      <div className="af-class-question_circle">
                        <div className="af-class-question">?</div>
                      </div>
                    </div>
                    <div className="af-class-product__additional_block">
                      <div className="af-class-product__additional_text"><span className="af-class-text-span">Доставка завтра</span> бесплатно.</div>
                      <div className="af-class-question_circle">
                        <div className="af-class-question">?</div>
                      </div>
                    </div>
                    <div className="af-class-product__additional_block">
                      <div className="af-class-product__additional_text"><span className="af-class-text-span">Доступно сейчас</span> в 3-х кофейнях.</div>
                      <div className="af-class-question_circle">
                        <div className="af-class-question">?</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default HomeCopyView

/* eslint-enable */