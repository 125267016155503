const Taste = ({ name, color, addable = false, removable = false, onClick = () => {} }) => {
  const hex = color.replace('#', '').split('');
  const r = parseInt(hex[0] + hex[1], 16);
  const g = parseInt(hex[2] + hex[3], 16);
  const b = parseInt(hex[4] + hex[5], 16);

  const textColor = (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000' : '#fff';

  return (
    <div
      style={{display: 'flex', backgroundColor: color, padding: '6px 12px', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', gap: '5px', cursor: (addable || removable) && 'pointer'}}
      onClick={onClick}
    >
      <div
        style={{fontFamily: 'Gotham', fontSize: '13px', fontWeight: '500', color: textColor}}
      >
        {name}
      </div>
      {(addable || removable) && <div
        style={{
          width: '16px',
          height: '16px',
          fontSize: '16px',
          fontWeight: 'bold',
          lineHeight: '16px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          color: '#000',
          textAlign: 'center',
          backgroundImage: addable ? 'url("/images/icon-plus.png")' : (removable ? 'url("/images/icon-minus.png")' : 'none'),
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        
      </div>}
    </div>
  );
}

export default Taste;