import { useState } from 'react';
import CatalogPage from '../components/Catalog/CatalogPage';

export default function CatalogViewNew() {
  const [categoryFiltersQuick, setCategoryFiltersQuick] = useState([
    {
      id: 312,
      option: 'Новинки',
    },
    {
      id: 463,
      option: 'sale',
      text: 'Со скидкой',
    },
  ]);

  const categoryFilters = [
    {
      id: 9991,
      name: 'Тип обжарки',
      options: ['Эспрессо', 'Фильтр']
    },
    {
      id: 1,
      name: 'Способ заваривания',
      options: ['Капельная кофеварка', 'Аэропресс', 'Воронка', 'Гейзер', 'Иммерсия', 'Кофемашина', 'Турка', 'Френч-пресс', 'Чашка', 'Дрип', 'Капсула']
    },
    {
      id: 2,
      name: 'Тип кофе',
      options: ['Арабика', 'Без кофеина', 'Смесь арабики и робусты']
    },
    {
      id: 3,
      name: 'Уровень кислотности',
      options: ['Высокая', 'Средняя', 'Низкая']
    },
    {
      id: 20,
      name: 'Регион',
      options: ['Азия и Океания', 'Африка', 'Центральная Америка', 'Южная Америка']
    },
    {
      id: 21,
      name: 'Обработка',
      options: ['Натуральная (natural process)', 'Мытая (washed process)', 'Экспериментальная']
    },
  ];

  const optionsAliases = [
    {
      name: 'Мытая (washed process)',
      aliases: ['Washed (мытая)', 'Мытая'],
    },
    {
      name: 'Натуральная (natural process)',
      aliases: ['Full Natural (сухая)', 'Натуральная'],
    },
    {
      name: 'Капельная кофеварка',
      aliases: ['Кофеварка капельная'],
    },
    {
      name: 'Без кофеина',
      aliases: ['Кофе без кофеина'],
    },
  ];

  const productsFilter = (products) => {
    return products.filter(elem => !(elem.categories.find(elem => elem.id == 416) || elem.categories.find(elem => elem.id == 107)));
  }

  return <CatalogPage
    categoryId={17}
    categoryFiltersQuick={categoryFiltersQuick}
    setCategoryFiltersQuick={setCategoryFiltersQuick}
    categoryFilters={categoryFilters}
    optionsAliases={optionsAliases}
    filterByAttributes={[1, 2, 3, 20, 21, 26]}
    productsFilter={productsFilter}
  />
}