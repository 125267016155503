/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { fetchPage, fetchMedias, fetchPostsByCategory } from '../fetch/WPAPI'
import { useNavigate } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const GearFiltered = ({ posts, activeTabGear }) => {
  const navigate = useNavigate()

  return posts?.filter(elem => elem.categories.includes(activeTabGear))?.map((elem, index) => <div key={elem.id} className="af-class-card-item--p-about-us af-class-an-scroll-into-view">
    <div className="af-class-card__img-wrapper--p-about-us">
      <img loading="lazy" src={elem?.featured_image_src?.medium} alt="" className="af-class-card__img af-class-an-image--scroll-into-view" />
    </div>
    <div className="w-layout-grid af-class-card__body--p-about-us">
      <h3 className="af-class-card__title--p-about-us">{elem.title.rendered}</h3>
      <div dangerouslySetInnerHTML={{__html: elem.excerpt.rendered}}></div>
      <a onClick={() => navigate(`/post/${elem.id}`)} style={{cursor: 'pointer'}} className="af-class-card__link--p-about-us w-inline-block">
        <div className="af-class-card__link-text--p-about-us">Производство</div>
        <div className="af-class-card__link-underline--p-about-us" />
      </a>
    </div>
  </div> )
}

const AboutUsView = () => {
  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()

  const [ isSearching, setisSearching ] = useState(false)

  const [ activeTab, setActiveTab ] = useState(1)
  const [ activeTabGear, setActiveTabGear ] = useState(431)

  const [ pageContent, setPageContent ] = useState({})
  const [ pageImages, setPageImages ] = useState([])

  const [ posts, setPosts ] = useState([])

  useEffect(() => {
    fetchPage(16085)
      .then(result => {
        console.log(result)
        setPageContent(result)
        let imageIds = [].concat(...result.acf.istoriaa.map(elem => elem.imgg.map(elem => elem.izob)))
        imageIds = imageIds.concat(...result.acf.nashakomanda.map(elem => elem.photograf))
        fetchMedias(imageIds)
          .then(result => {
            console.log(result)
            setPageImages(result)
          })
        
        setIsLoading(false)
      })
    
    fetchPostsByCategory([431, 432, 433])
      .then(result => {
        console.log(result)
        setPosts(result)
      })
  }, [])

  useEffect(() => {
    document.title = pageContent?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", pageContent?.yoast_head_json?.description)
  }, [pageContent])

  const onSearchClick = () => {
    setisSearching(true)
  }

  if (isLoading === true) {
    return <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div>
  }

  return <>
            <div className="af-class-section af-class-p_about_us af-class-an-load--2 af-class-wf-section">
              <div className="af-class-container w-container">
                <h1 className="af-class-title-main-p_about_us" dangerouslySetInnerHTML={{__html: pageContent?.content?.rendered}}></h1>
                <div style={{display: activeTab == 1 ? 'block' : 'none'}} className="w-layout-grid af-class-blocks-content-p_about_us">
                  <div id="w-node-d905dac2-1e4e-5c8a-181d-0633dca54149-c43d362a" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                    <div id="w-node-_784dc4ab-2426-7a20-8198-9fa6d927d958-c43d362a" className="w-layout-grid af-class-title_and_tabs">
                      <div className="af-class-text_and_tabs">
                        <div className="af-class-discription-p_vocabulary">{pageContent?.acf?.opiss}</div>
                        <div className="w-layout-grid af-class-tabs-p_about_us">
                          <a className={`af-class-content_links${activeTab == 1 ? ' af-class-state-active' : ''}`} onClick={() => setActiveTab(1)}>История</a>
                          {/* <a className={`af-class-content_links${activeTab == 2 ? ' af-class-state-active' : ''}`} onClick={() => setActiveTab(2)}>Производство</a> */}
                        </div>
                      </div>
                    </div>
                    <h2 id="w-node-_0da16552-d8ed-d3c0-f6b5-c909d266d06f-c43d362a" className="af-class-title-block-p_about_us">{pageContent?.acf?.zaagg}</h2>

                    <div id="w-node-_153b154e-8ad5-e371-340e-0531daf1dfb7-c43d362a" className="w-layout-grid af-class-text_block-p_about_us">
                      <div className="af-class-subtitle-block-p_about_us">{pageContent?.acf?.istoriaa[0].god}</div>
                      <div className="af-class-text-block-p_about_us" dangerouslySetInnerHTML={{__html: pageContent?.acf?.istoriaa[0].opisaniedolz}}></div>
                    </div>
                    <div id="w-node-_658fa62c-4d4c-90d6-e421-46f31a2f40b3-c43d362a" className={`af-class-image-wrapper-block-p_about_us${pageContent?.acf?.istoriaa[0]?.imgg?.length == 2 ? ' af-class-images_2' : ''}`}>
                      <div className={`w-layout-grid af-class-image-grid-p_about_us${pageContent?.acf?.istoriaa[0]?.imgg?.length == 2 ? ' af-class-images_2' : ''}`}>
                      { pageContent?.acf?.istoriaa[0]?.imgg.map((elem, index) => <div key={index} id={`${pageContent?.acf?.istoriaa[0]?.imgg?.length == 1 ? 'w-node-cc6069f8-74ec-c64b-1d6e-cfc074f84795-c43d362a' : ''}`} className="af-class-image_wrapper-p_about_us">
                          <img src={pageImages.find(img => img.id == elem.izob)?.source_url} loading="lazy" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" />
                        </div> ) }
                      </div>
                    </div>
                  </div>

                  { pageContent?.acf?.istoriaa.map((elem, index) => index == 0 ? '' : <div key={index} id="w-node-_32b3e56f-5c9c-4028-a41d-66415a8bb3dd-c43d362a" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                    <div id="w-node-_32b3e56f-5c9c-4028-a41d-66415a8bb3e0-c43d362a" className="w-layout-grid af-class-text_block-p_about_us">
                      <div className="af-class-subtitle-block-p_about_us">{elem.god}</div>
                      <div className="af-class-text-block-p_about_us af-class-columns_2" dangerouslySetInnerHTML={{__html: elem.opisaniedolz}}></div>
                    </div>
                    <div id="w-node-_32b3e56f-5c9c-4028-a41d-66415a8bb3e5-c43d362a" className={`af-class-image-wrapper-block-p_about_us${elem?.imgg?.length == 2 ? ' af-class-images_2' : ''}`}>
                      <div className={`w-layout-grid af-class-image-grid-p_about_us${elem?.imgg?.length == 2 ? ' af-class-images_2' : ''}`}>
                        { elem?.imgg.map((elemSingle, index) => <div key={index} id={`${elem?.imgg?.length == 1 ? 'w-node-cc6069f8-74ec-c64b-1d6e-cfc074f84795-c43d362a' : ''}`} className="af-class-image_wrapper-p_about_us">
                          <img src={pageImages.find(img => img.id == elemSingle.izob)?.source_url} loading="lazy" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" />
                        </div> ) }
                      </div>
                    </div>
                  </div> )}

                  {/* <div id="w-node-_32b3e56f-5c9c-4028-a41d-66415a8bb3dd-c43d362a" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                    <div id="w-node-_32b3e56f-5c9c-4028-a41d-66415a8bb3e0-c43d362a" className="w-layout-grid af-class-text_block-p_about_us">
                      <div className="af-class-subtitle-block-p_about_us">2014</div>
                      <div className="af-class-text-block-p_about_us af-class-columns_2">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера, позволяет по-новому взглянуть на раскрытие потенциала зеленого зерна, поэтому профили обжарки всегда подбираются с особой тщательностью.<br />‍<br />Чтобы донести этот вкус до каждой чашки, Rockets следит за качеством и стабильностью не только на своём производстве, но и в заведениях партнёров, будь то небольшая кофейня или крупная сеть ресторанов.</div>
                    </div>
                    <div id="w-node-_32b3e56f-5c9c-4028-a41d-66415a8bb3e5-c43d362a" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                      <div className="w-layout-grid af-class-image-grid-p_about_us af-class-images_2">
                        <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-_32b3e56f-5c9c-4028-a41d-66415a8bb3e7-c43d362a" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-3.jpg" loading="lazy" id="w-node-b6604f67-2b9a-86ac-83dc-d5778402819c-c43d362a" sizes="(max-width: 767px) 100vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/img-tab-p_about_us-3-p-500.jpeg 500w, images/img-tab-p_about_us-3.jpg 750w" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                      </div>
                    </div>
                  </div>
                  <div id="w-node-c5238919-fb40-5256-edb2-6c5bf494dc35-c43d362a" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                    <div id="w-node-c5238919-fb40-5256-edb2-6c5bf494dc41-c43d362a" className="w-layout-grid af-class-text_block-p_about_us">
                      <div className="af-class-subtitle-block-p_about_us">2016</div>
                      <div className="af-class-text-block-p_about_us af-class-columns_2">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера, позволяет по-новому взглянуть на раскрытие потенциала зеленого зерна, поэтому профили обжарки всегда подбираются с особой тщательностью. Чтобы донести этот вкус до каждой чашки, Rockets следит за качеством и стабильностью не только на своём производстве, но и в заведениях партнёров, будь то небольшая кофейня или крупная сеть ресторанов.</div>
                    </div>
                    <div id="w-node-c5238919-fb40-5256-edb2-6c5bf494dc46-c43d362a" className="af-class-image-wrapper-block-p_about_us">
                      <div className="w-layout-grid af-class-image-grid-p_about_us">
                        <div id="w-node-_64c96b26-caa7-ec2d-ad0d-fccd129f2cb1-c43d362a" className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-4.jpg" loading="lazy" id="w-node-c5238919-fb40-5256-edb2-6c5bf494dc48-c43d362a" sizes="(max-width: 1279px) 100vw, (max-width: 1439px) 99vw, 98vw" srcSet="images/img-tab-p_about_us-4-p-500.jpeg 500w, images/img-tab-p_about_us-4.jpg 1500w" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                      </div>
                    </div>
                  </div>
                  <div id="w-node-_02b30ad4-5353-a723-953a-77728e4661a6-c43d362a" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                    <div id="w-node-_02b30ad4-5353-a723-953a-77728e4661a7-c43d362a" className="w-layout-grid af-class-text_block-p_about_us">
                      <div className="af-class-subtitle-block-p_about_us">2021</div>
                      <div className="af-class-text-block-p_about_us">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера, позволяет по-новому взглянуть на раскрытие потенциала зеленого зерна, поэтому профили обжарки всегда подбираются с особой тщательностью.</div>
                    </div>
                    <div id="w-node-_02b30ad4-5353-a723-953a-77728e4661ac-c43d362a" className="af-class-image-wrapper-block-p_about_us">
                      <div className="w-layout-grid af-class-image-grid-p_about_us">
                        <div id="w-node-_2f712f85-5022-7a51-244b-cc1af54a1e44-c43d362a" className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-5.jpg" loading="lazy" id="w-node-_02b30ad4-5353-a723-953a-77728e4661af-c43d362a" sizes="100vw" srcSet="images/img-tab-p_about_us-5-p-500.jpeg 500w, images/img-tab-p_about_us-5.jpg 750w" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div style={{display: activeTab == 2 ? 'block' : 'none'}} className="w-layout-grid af-class-blocks-content-p_about_us">
                  <div id="w-node-a784d1a4-a59f-e0bc-e05e-13f55a25dcb1-c43d362a" className="w-layout-grid af-class-block-content-p_about_us af-class-manufacure">
                    <div id="w-node-a784d1a4-a59f-e0bc-e05e-13f55a25dcb2-c43d362a" className="w-layout-grid af-class-title_and_tabs">
                      <div className="af-class-text_and_tabs">
                        <div className="af-class-discription-p_vocabulary">Мы сотрудничаем более чем с 300 заведениями и поставили более 400 комплектов оборудования</div>
                        <div className="w-layout-grid af-class-tabs-p_about_us af-class-manufacture">
                          <a className={`af-class-content_links${activeTab == 1 ? ' af-class-state-active' : ''}`} onClick={() => setActiveTab(1)}>История</a>
                          <a className={`af-class-content_links${activeTab == 2 ? ' af-class-state-active' : ''}`} onClick={() => setActiveTab(2)}>Производство</a>
                        </div>
                      </div>
                    </div>
                    <h2 id="w-node-a784d1a4-a59f-e0bc-e05e-13f55a25dcbb-c43d362a" className="af-class-title-block-p_about_us af-class-blue">Оборудование</h2>
                    <div id="w-node-_7ca133be-0a9e-0914-2019-ffec333d693d-c43d362a" data-current="Tab 3" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs--p-about-us w-tabs">
                      <div className="af-class-tabs-list--p-about-us w-tab-menu">
                        <a className={`af-class-tab-item--p-about-us w-inline-block w-tab-link${activeTabGear == 431 ? ' w--current' : ''}`} onClick={() => setActiveTabGear(431)}>
                          <div className="af-class-tabs__text">Все ростеры</div>
                        </a>
                        <a className={`af-class-tab-item--p-about-us w-inline-block w-tab-link${activeTabGear == 432 ? ' w--current' : ''}`} onClick={() => setActiveTabGear(432)}>
                          <div className="af-class-tabs__text">Колорсортер</div>
                        </a>
                        <a className={`af-class-tab-item--p-about-us w-inline-block w-tab-link${activeTabGear == 433 ? ' w--current' : ''}`} onClick={() => setActiveTabGear(433)}>
                          <div className="af-class-tabs__text">Лаборатория</div>
                        </a>
                      </div>
                      <div className="af-class-tabs-content--p-about-us w-tab-content">
                        <div style={{display: activeTabGear == 431 ? 'block' : 'none'}} className="af-class-tab-pane--p-about-us w-tab-pane">
                          <div className="af-class-cards-wrapper--p-about-us">
                            <div className="w-layout-grid af-class-cards-list--p-about-us">
                              <GearFiltered posts={posts} activeTabGear={activeTabGear} />
                            </div>
                          </div>
                        </div>
                        <div style={{display: activeTabGear == 432 ? 'block' : 'none'}} className="w-tab-pane">
                          <div className="af-class-cards-wrapper--p-about-us">
                            <div className="w-layout-grid af-class-cards-list--p-about-us">
                              <GearFiltered posts={posts} activeTabGear={activeTabGear} />
                            </div>
                          </div>
                        </div>
                        <div style={{display: activeTabGear == 433 ? 'block' : 'none'}} className="w-tab-pane w--tab-active">
                          <div className="af-class-cards-wrapper--p-about-us">
                            <div className="w-layout-grid af-class-cards-list--p-about-us">
                              <GearFiltered posts={posts} activeTabGear={activeTabGear} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {/* <div id="w-node-_0bb82a9a-27fc-1ade-475a-dc18ffd7d03a-c43d362a" className="af-class-splide__arrows">
                    <div bind="2290182e-3878-bafe-a9ee-ae6b2a6327a7" id="w-node-_2290182e-3878-bafe-a9ee-ae6b2a6327a7-c43d362a" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                    <div bind="aaefb37c-7cd7-e0a1-9c3e-3efcd9663838" id="w-node-aaefb37c-7cd7-e0a1-9c3e-3efcd9663838-c43d362a" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                  </div> */}
                  <div id="w-node-_8c02a522-d3e1-101c-f6ba-7a28391e264c-c43d362a" className="af-class-subtitle-block-p_about_us af-class-slider" style={{ marginBottom: '40px', textAlign: 'right' }}>Наша команда</div>

                  {/* af-class-slider2 */}
                  <Splide className="af-class-splide af-class-padding--p-about-us af-class-an-scroll-into-view about-us-slider" options={{ perPage: 4, perMove: 1, gap: '15px', breakpoints: { 991: { perPage: 1 } } }}>
                    { pageContent?.acf?.nashakomanda.map((elem, index) => <SplideSlide className="af-class-splide__slide af-class-p-about-us" key={index}>
                      <div className="af-class-slider-card">
                        <div className="af-class-slider-card__image-wrapper">
                          <img src={pageImages.find(img => img.id == elem.photograf)?.source_url} loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" />
                        </div>
                        <div className="af-class-slider-card__header">
                          <h2 className="af-class-slider-card__title">{elem.fio}</h2>
                          <h3 className="af-class-slider-card__subtitle">{elem.dolznost}</h3>
                        </div>
                        <div className="af-class-slider-card__body">
                          <div className="af-class-slider-card__text">{elem.opisaniedolz}</div>
                        </div>
                        <div className="af-class-slider-card__footer" onClick={() => navigate(`/products/${elem.lovekoffe}`)}>
                          <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                          <div className="af-class-slider-card__link-underline" />
                        </div>
                      </div>
                    </SplideSlide> ) }
                  </Splide>

                  {/* <div id="w-node-_9fc6309a-1f5c-c705-ef22-61a039492152-c43d362a" className="af-class-splide__track">
                    <div className="af-class-splide__list" style={{ columnGap: '10px' }}>

                      { pageContent?.acf?.nashakomanda.map((elem, index) => <div key={index} className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper">
                            <img src={pageImages.find(img => img.id == elem.photograf)?.source_url} loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" />
                          </div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">{elem.fio}</h2>
                            <h3 className="af-class-slider-card__subtitle">{elem.dolznost}</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">{elem.opisaniedolz}</div>
                          </div>
                          <div className="af-class-slider-card__footer" onClick={() => navigate(`/products/${elem.lovekoffe}`)}>
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div> ) }
                      
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/egor-nevejkin.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Егор Невейкин</h2>
                            <h3 className="af-class-slider-card__subtitle">Founder</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/alexander-leonov.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Александр Леонов</h2>
                            <h3 className="af-class-slider-card__subtitle">CEO</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/maxim-karnauhov.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Максим Карнаухов</h2>
                            <h3 className="af-class-slider-card__subtitle">Главный тренер ROCKETS</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/temur-almatov.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Темур Алматов</h2>
                            <h3 className="af-class-slider-card__subtitle">Ведущий тренер ROCKETS</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/egor-nevejkin.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Егор Невейкин</h2>
                            <h3 className="af-class-slider-card__subtitle">Founder</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/alexander-leonov.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Егор Невейкин</h2>
                            <h3 className="af-class-slider-card__subtitle">Founder</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/maxim-karnauhov.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Егор Невейкин</h2>
                            <h3 className="af-class-slider-card__subtitle">Founder</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-splide__slide af-class-p-about-us">
                        <div className="af-class-slider-card">
                          <div className="af-class-slider-card__image-wrapper"><img src="images/temur-almatov.jpg" loading="lazy" alt="" className="af-class-slider-card__image af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-slider-card__header">
                            <h2 className="af-class-slider-card__title">Егор Невейкин</h2>
                            <h3 className="af-class-slider-card__subtitle">Founder</h3>
                          </div>
                          <div className="af-class-slider-card__body">
                            <div className="af-class-slider-card__text">Это компания с узнаваемым стилем. Невероятно чистый и одновременно яркий вкус кофе, который возможен только при использовании конвекционного ростера.</div>
                          </div>
                          <div className="af-class-slider-card__footer">
                            <a href="#" className="af-class-slider-card__link">Любимый кофе</a>
                            <div className="af-class-slider-card__link-underline" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div bind="968c2410-5a4c-1f5c-a238-d75f2c2928cd" className="af-class-splide__embed w-embed">
                    <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="af-class-section af-class-instagram af-class-an-scroll-into-view af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="af-class-s-instagram__content">
                  <h2 className="af-class-s-instagram__title">@rockets.roasters</h2>
                  <div className="af-class-s-instagram__grid-wrapper">
                    <div className="w-layout-grid af-class-s-instagram__grid">
                      <div className="af-class-s-instagram__img-wrapper"><img src="images/instagram__img-1.jpg" loading="lazy" alt="" className="af-class-s-instagram__img af-class-an-image--scroll-into-view" /></div>
                      <div className="af-class-s-instagram__img-wrapper"><img src="images/instagram__img-2.jpg" loading="lazy" alt="" className="af-class-s-instagram__img af-class-an-image--scroll-into-view" /></div>
                      <div className="af-class-s-instagram__img-wrapper"><img src="images/instagram__img-3.jpg" loading="lazy" alt="" className="af-class-s-instagram__img af-class-an-image--scroll-into-view" /></div>
                      <div className="af-class-s-instagram__img-wrapper"><img src="images/instagram__img-4.jpg" loading="lazy" alt="" className="af-class-s-instagram__img af-class-an-image--scroll-into-view" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
}

export default AboutUsView

/* eslint-enable */