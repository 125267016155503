/* eslint-disable */

import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { fetchPost } from '../fetch/WPAPI'

const PostView = () => {

  const params = useParams()
  const navigate = useNavigate()

  const [ pageContent, setPageContent ] = useState({})

  useEffect(() => {
    fetchPost(params.id)
      .then(result => {
        console.log(result)
        setPageContent(result)
      })
  }, [])

  useEffect(() => {
    document.title = pageContent?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", pageContent?.yoast_head_json?.description)
  }, [pageContent])

  return <div className="af-class-section af-class-an-load--2 af-class-wf-section">
    <div className="af-class-container w-container">
      <div className="w-layout-grid af-class-education--all__content">
        <h1 className="af-class-page-title">{pageContent?.title?.rendered}</h1>
        <div dangerouslySetInnerHTML={{__html: pageContent?.content?.rendered}} />
      </div>
    </div>
   </div>
  
}

export default PostView

/* eslint-enable */