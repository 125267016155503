export const tasteTabs = [
  { id: 1, name: "Обжарка" },
  { id: 2, name: "Обработка" },
  { id: 3, name: "Терруар" },
];

export const tasteGroups = [
  { id: 1, tabId: 1, name: "Сладкие ароматы" },
  { id: 2, tabId: 1, name: "Сухофрукты" },
  { id: 3, tabId: 1, name: "Орехи и семечки" },
  { id: 4, tabId: 1, name: "Шоколад" },
  { id: 5, tabId: 1, name: "Специи" },
  { id: 6, tabId: 2, name: "Тропические фрукты" },
  { id: 7, tabId: 2, name: "Алкоголь" },
  { id: 8, tabId: 2, name: "Ароматные травы" },
  { id: 9, tabId: 3, name: "Фрукты" },
  { id: 10, tabId: 3, name: "Ягоды" },
  { id: 11, tabId: 3, name: "Цветочные" },
  { id: 12, tabId: 3, name: "Растительная ароматика" },
];

export const tastes = [
  { id: 1, groupId: 1, name: "Тростниковый сахар", color: "#9F744D" },
  { id: 2, groupId: 1, name: "Карамель", color: "#c37c54" },
  { id: 3, groupId: 1, name: "Мед", color: "#BC9337" },
  { id: 4, groupId: 1, name: "Кленовый сироп", color: "#C37F51" },
  { id: 5, groupId: 1, name: "Ваниль", color: "#F4E7C6" },
  { id: 6, groupId: 1, name: "Выпечка", color: "#c0a682" },
  { id: 7, groupId: 1, name: "Нуга", color: "#B69885" },
  { id: 8, groupId: 2, name: "Изюм", color: "#583432" },
  { id: 9, groupId: 2, name: "Чернослив", color: "#522145" },
  { id: 10, groupId: 2, name: "Финики", color: "#954E28" },
  { id: 11, groupId: 2, name: "Инжир", color: "#532D3B" },
  { id: 12, groupId: 3, name: "Миндаль", color: "#AB784E" },
  { id: 13, groupId: 3, name: "Фундук или лесной орех", color: "#cfb095" },
  { id: 14, groupId: 3, name: "Грецкий орех", color: "#B28965" },
  { id: 15, groupId: 3, name: "Кешью", color: "#A67247" },
  { id: 16, groupId: 3, name: "Арахис", color: "#b59562" },
  { id: 17, groupId: 3, name: "Пекан", color: "#A57053" },
  { id: 18, groupId: 3, name: "Кунжут", color: "#baa38b" },
  { id: 19, groupId: 4, name: "Темный шоколад", color: "#443731" },
  { id: 20, groupId: 4, name: "Молочный шоколад", color: "#84563c" },
  { id: 21, groupId: 4, name: "Какао", color: "#704a49" },
  { id: 22, groupId: 4, name: "Шоколадная выпечка", color: "#A8600B" },
  { id: 23, groupId: 5, name: "Кардамон", color: "#958d34" },
  { id: 24, groupId: 5, name: "Гвоздика", color: "#866459" },
  { id: 25, groupId: 5, name: "Кориандр", color: "#8E826A" },
  { id: 26, groupId: 5, name: "Анис или бадьян", color: "#635242" },
  { id: 27, groupId: 5, name: "Корица", color: "#9B4722" },
  { id: 28, groupId: 5, name: "Мускатный орех", color: "#7E5C54" },
  { id: 29, groupId: 5, name: "Душистый перец", color: "#634832" },
  { id: 30, groupId: 5, name: "Паприка", color: "#CE4D42" },
  { id: 31, groupId: 6, name: "Ананас", color: "#F8CA0A" },
  { id: 32, groupId: 6, name: "Манго", color: "#FBBF53" },
  { id: 33, groupId: 6, name: "Папайя", color: "#ffa266" },
  { id: 34, groupId: 6, name: "Маракуйя", color: "#fdc04e" },
  { id: 35, groupId: 6, name: "Личи", color: "#ba0d32" },
  { id: 36, groupId: 6, name: "Гуава", color: "#b7434d" },
  { id: 37, groupId: 6, name: "Кокос", color: "#f0ede5" },
  { id: 38, groupId: 6, name: "Дыня", color: "#f6896a" },
  { id: 39, groupId: 6, name: "Банан", color: "#f0cd5b" },
  { id: 40, groupId: 6, name: "Клубника", color: "#ee2737" },
  { id: 41, groupId: 6, name: "Черника", color: "#2C3642" },
  { id: 42, groupId: 6, name: "Темный виноград", color: "#7D3F7C" },
  { id: 43, groupId: 6, name: "Светлый виноград", color: "#A0AB4D" },
  { id: 44, groupId: 6, name: "Киви", color: "#7BAE57" },
  { id: 45, groupId: 6, name: "Арбуз", color: "#E8476A" },
  { id: 46, groupId: 7, name: "Ром", color: "#583432" },
  { id: 47, groupId: 7, name: "Виски", color: "#A94007" },
  { id: 48, groupId: 7, name: "Коньяк", color: "#8b645a" },
  { id: 49, groupId: 7, name: "Бурбон", color: "#B67721" },
  { id: 50, groupId: 7, name: "Сидр", color: "#d8a175" },
  { id: 51, groupId: 7, name: "Красное вино", color: "#77333b" },
  { id: 52, groupId: 7, name: "Белое вино", color: "#EEEDC4" },
  { id: 53, groupId: 7, name: "Бейлис", color: "#E7CEB5" },
  { id: 54, groupId: 8, name: "Розмарин", color: "#405e5c" },
  { id: 55, groupId: 8, name: "Тимьян или чабрец", color: "#50574c" },
  { id: 56, groupId: 8, name: "Тмин", color: "#c4bda3" },
  { id: 57, groupId: 8, name: "Эстрагон", color: "#A4B279" },
  { id: 58, groupId: 8, name: "Укроп", color: "#5D7050" },
  { id: 59, groupId: 9, name: "Лимон", color: "#FAF049" },
  { id: 60, groupId: 9, name: "Лайм", color: "#4F9D08" },
  { id: 61, groupId: 9, name: "Грейпфрут", color: "#E86C69" },
  { id: 62, groupId: 9, name: "Апельсин", color: "#FB8904" },
  { id: 63, groupId: 9, name: "Мандарин", color: "#FB6204" },
  { id: 64, groupId: 9, name: "Абрикос", color: "#FCE37F" },
  { id: 65, groupId: 9, name: "Слива", color: "#5D6CB8" },
  { id: 66, groupId: 9, name: "Персик", color: "#F9B63E" },
  { id: 67, groupId: 9, name: "Вишня", color: "#D80B16" },
  { id: 68, groupId: 9, name: "Черешня", color: "#59060B" },
  { id: 69, groupId: 9, name: "Яблоко зеленое", color: "#8DB600" },
  { id: 70, groupId: 9, name: "Яблоко красное", color: "#C7372F" },
  { id: 71, groupId: 9, name: "Груша", color: "#edea9b" },
  { id: 72, groupId: 9, name: "Гранат", color: "#6c2831" },
  { id: 73, groupId: 10, name: "Красная смородина", color: "#E10405" },
  { id: 74, groupId: 10, name: "Черная смородина", color: "#454757" },
  { id: 75, groupId: 10, name: "Малина", color: "#D32E5E" },
  { id: 76, groupId: 10, name: "Ежевика", color: "#432F4E" },
  { id: 77, groupId: 10, name: "Шиповник", color: "#ce8498" },
  { id: 78, groupId: 10, name: "Клюква или брусника", color: "#B7484F" },
  { id: 79, groupId: 10, name: "Крыжовник", color: "#b9b964" },
  { id: 80, groupId: 11, name: "Жасмин", color: "#C9C4A9" },
  { id: 81, groupId: 11, name: "Роза", color: "#CC212B" },
  { id: 82, groupId: 11, name: "Ромашка", color: "#E4A306" },
  { id: 83, groupId: 11, name: "Лаванда", color: "#afa4ce" },
  { id: 84, groupId: 12, name: "Черный чай листовой", color: "#3A0404" },
  { id: 85, groupId: 12, name: "Эрл Грей", color: "#304179" },
  { id: 86, groupId: 12, name: "Зеленый чай с жасмином", color: "#B59806" },
  { id: 87, groupId: 12, name: "Каркаде", color: "#B20404" },
  { id: 88, groupId: 12, name: "Мята", color: "#DDEDE8" },
  { id: 89, groupId: 12, name: "Томат", color: "#eb3c27" },
  { id: 90, groupId: 12, name: "Лемонграсс", color: "#DBD590" },
  { id: 91, groupId: 12, name: "Полевые травы", color: "#848B13" },
  { id: 92, groupId: 12, name: "Табачный лист", color: "#B6836E" },
  { id: 93, groupId: 12, name: "Кедр или сосна", color: "#AE7A56" },
  { id: 94, groupId: 12, name: "Имбирь", color: "#c1a68b" },
  { id: 95, groupId: 12, name: "Эвкалипт", color: "#b0c9b5" },
];