/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  // { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6261fc674c12d792773d3625").then(body => body.text()), isAsync: false },
  // { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class StyleGuideView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/StyleGuideController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = StyleGuideView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6261fc674c12d700fc3d3644'
    htmlEl.dataset['wfSite'] = '6261fc674c12d792773d3625'

    // scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
    //   const loading = active.loading.then((script) => {
    //     new Function(`
    //       with (this) {
    //         eval(arguments[0])
    //       }
    //     `).call(window, script)

    //     return next
    //   })

    //   return active.isAsync ? next : loading
    // }))
  }

  render() {
    const proxies = StyleGuideView.Controller !== StyleGuideView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rockets-coffee-export.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-sg-splider__arrows">
              <div className="af-class-splide__arrow af-class-splide__arrow--prev" />
              <div className="af-class-splide__arrow af-class-splide__arrow--next" />
            </div>
            <div className="af-class-sg-tabs">
              <div className="w-layout-grid af-class-sg-tabs__tabs-wrapper">
                <div style={{color: 'rgb(29,113,184)'}} className="af-class-an-tabs__tab--1">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--1">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--2">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--2">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--3">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--3">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--4">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--4">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--5">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--5">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--6">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--6">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--7">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--7">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--8">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--8">Турка</a>
                </div>
                <div className="af-class-an-tabs__tab--9">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--9">Турка</a>
                </div>
              </div>
              <div id="w-node-_219db0c1-feda-1159-2735-3dcbe9d4033c-fc3d3644" className="af-class-sg-tabs__content-wrapper">
                <div style={{display: 'block'}} className="af-class-an-tabs__content--1">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_1">
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--2">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_2">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--3">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_3">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--4">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_4">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--5">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_5">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--6">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_6">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--7">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_7">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--8">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_8">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--9">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_9">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-sg-tabs">
              <div className="w-layout-grid af-class-sg-tabs__tabs-wrapper">
                <div data-w-id="d4e690d5-535c-1bb2-2649-51f96e63c601" style={{color: 'rgb(29,113,184)'}} className="af-class-an-tabs--2__tab--1">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--1">Турка</a>
                </div>
                <div data-w-id="d4e690d5-535c-1bb2-2649-51f96e63c604" className="af-class-an-tabs--2__tab--2">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--2">Турка</a>
                </div>
                <div className="af-class-an-tabs--2__tab--3">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--3">Турка</a>
                </div>
                <div className="af-class-an-tabs--2__tab--4">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--4">Турка</a>
                </div>
                <div className="af-class-an-tabs--2__tab--5">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--5">Турка</a>
                </div>
                <div className="af-class-an-tabs--2__tab--6">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--6">Турка</a>
                </div>
                <div className="af-class-an-tabs--2__tab--7">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--7">Турка</a>
                </div>
                <div className="af-class-an-tabs--2__tab--8">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--8">Турка</a>
                </div>
                <div className="af-class-an-tabs--2__tab--9">
                  <a href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--9">Турка</a>
                </div>
              </div>
              <div id="w-node-d4e690d5-535c-1bb2-2649-51f96e63c61c-fc3d3644" className="af-class-sg-tabs__content-wrapper">
                <div style={{display: 'block'}} className="af-class-an-tabs--2__content--1">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_1">
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--2">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_2">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--3">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_3">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--4">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_4">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--5">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_5">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--6">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_6">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--7">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_7">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--8">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_8">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs--2__content--9">
                  <div className="w-layout-grid af-class-sg-tabs__content af-class-_9">
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                    <div className="af-class-sg-tabs__card" />
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-sg-animation__load">
              <div style={{opacity: 0}} className="af-class-an-load--1" />
              <div style={{opacity: 0}} className="af-class-an-load--2" />
              <div style={{opacity: 0}} className="af-class-an-load--3" />
            </div>
            <div className="af-class-sg-animation__scroll-into-view">
              <div className="af-class-an-scroll-into-view" />
            </div>
            <div className="af-class-sg-animation__image">
              <div className="af-class-an-image--scroll-into-view" />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default StyleGuideView

/* eslint-enable */