import { useEffect, useContext } from "react";
import { CartFormContext } from "../../../contexts/CartFormContext";

const CartFormFieldText = ({ name, value, placeholder, onChange, validate, valid, setValid, error, setError }) => {
  const cartFormContext = useContext(CartFormContext);

  const handleValidate = (e) => {
    if (!validate(e.target.value)) {
      setError('error');
      setValid(false);
    } else {
      setError(null);
      setValid(true);
    }
  };

  // This is for when the component mounts and gets populated with the values from the localStorage
  useEffect(() => {
    if (value) {
      handleValidate({ target: { value: value } });
    }
  }, [value]);

  const handleChange = (e) => {
    onChange(e);
    // handleValidate(e);
  };

  return (
    <div className="af-class-cart__user-field-wrapper">
      <div className={`
        af-class-cart__user-field-label
        ${value?.length && ' af-class-cart__user-field-label-active'}
      `}>
        {placeholder}
      </div>
      <input
        value={value ? value : ''}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleValidate(e)}
        placeholder={placeholder}
        type="text"
        className={`
          af-class-cart__user-field w-input
          ${error ? ' user-input-invalid' : ''}
        `}
        maxLength={256}
      />
    </div>
  );
}

export default CartFormFieldText