/* eslint-disable */

import React, { useState, useEffect } from 'react'
import useCart from '../hooks/useCart.js'

const ThankYouPageView = () => {

  const { customerData, setCustomerData, setCart, cartOrderId, setCartOrderId, cartOrderBankId, setCartOrderBankId } = useCart()

  useEffect(() => {
    document.title = 'Спасибо за заказ'
    document.querySelector('meta[name="description"]').setAttribute("content", 'Ваш заказ успешно оформлен')

    setCart([])

    // Checking if We've Received the Successful Payment Callback...
    // let query = window.location.search.substring(1)
    // let getParams = query.split('&')
    // for (let i = 0; i < getParams.length; i++) {
    //   let pair = getParams[i].split('=')
    //   if (pair[0] == 'orderBankId' && pair[1] == cartOrderBankId) {
    //     // ...then Clearing Cart, Displaying the Success Plate...
    //     setCart([])
    //     props.setOrderSuccessful(true)

    //     // ...and Updating the Order Paid Status on the Backend
    //     updateOrder(cartOrderId, { set_paid: true })
    //       .then(result => {
            
    //       })
    //   }
    // }
  }, [])

  return <div className="af-class-section af-class-p_loyalty_section af-class-an-load--2 af-class-wf-section">
    <div className="af-class-container w-container">
      <h1 className="af-class-title-main-p_loyalty_system">Спасибо за заказ!</h1>
      <p>Ваш заказ успешно оформлен.</p>
    </div>
  </div>
  
  
}

export default ThankYouPageView

/* eslint-enable */