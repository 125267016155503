/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  // { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6261fc674c12d792773d3625").then(body => body.text()), isAsync: false },
  // { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class TestView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/TestController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = TestView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6261fc674c12d7123a3d3645'
    htmlEl.dataset['wfSite'] = '6261fc674c12d792773d3625'

    // scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
    //   const loading = active.loading.then((script) => {
    //     new Function(`
    //       with (this) {
    //         eval(arguments[0])
    //       }
    //     `).call(window, script)

    //     return next
    //   })

    //   return active.isAsync ? next : loading
    // }))
  }

  render() {
    const proxies = TestView.Controller !== TestView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rockets-coffee-export.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default TestView

/* eslint-enable */