/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { createScope, map, transformProxies } from './helpers'
import { useNavigate } from 'react-router-dom'
import { fetchPostsByCategory, fetchCategories, fetchCustomPosts, fetchCustomCategories, fetchMedias } from '../fetch/WPAPI'

const KnowledgeView = () => {

  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()

  const [ currentCategory, setCurrentCategory ] = useState(null)
  const [ categories, setCategories ] = useState([])
  const [ posts, setPosts ] = useState([])
  const [ postsAll, setPostsAll ] = useState([])
  const [ medias, setMedias ] = useState([])

  useEffect(() => {
    // fetchCategories([30,31,32,33])
    //   .then(result => {
    //     console.log(result)
    //     setCategories(result)
    //   })
    // fetchPostsByCategory([31,32,33])
    //   .then(result => {
    //     console.log(result)
    //     setPosts(result)
    //     setPostsAll(result)
    //   })
    fetchCustomCategories([])
      .then(result => {
        console.log(result)
        setCategories(result.filter(elem => ![429, 430].includes(elem.id)))
      })
    // fetchCustomPosts('znania')
    //   .then(result => {
    //     console.log(result)
    //     setPosts(result)
    //     setPostsAll(result)
    //   })

    document.title = 'Как заварить и рецепты'
    document.querySelector('meta[name="description"]').setAttribute("content", 'Все о кофе для начинающих и профессионалов. Новости кофейной индустрии, статьи и обзоры.')
  }, [])

  const [ searchQuery, setSearchQuery ] = useState('')

  useEffect(() => {
    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0] == 'search') {
        if (searchQuery != pair[1]) {
          setSearchQuery(pair[1])
        }
      }
    }
  }, [window.location.search])

  useEffect(() => {
    console.log(searchQuery)
    fetchCustomPosts('znania', searchQuery)
      .then(result => {
        console.log(result)
        setPostsAll(result)
        if (currentCategory == null || currentCategory == 419) {
          setPosts(result.filter(elem => elem.kategory.indexOf(428) == -1))
        } else {
          setPosts(result.filter(elem => elem.kategory.indexOf(currentCategory) !== -1))
        }

        let media_ids = result.map(elem => elem.featured_media)
        fetchMedias(media_ids)
          .then(result => {
            console.log(result)
            setMedias(result)
          })
        
        setIsLoading(false)
      })
  }, [searchQuery])

  // const handleCurrentCategory = (id) => {
  //   setCurrentCategory(id)

  //   if (id == null) {
  //     setPosts(postsAll)
  //   } else {
  //     setPosts(postsAll.filter(elem => elem.categories.indexOf(id) !== -1))
  //   }
  // }

  const handleCurrentCategory = (id) => {
    setCurrentCategory(id)

    if (id == null || id == 419) {
      setPosts(postsAll.filter(elem => elem.kategory.indexOf(428) == -1))
    } else {
      setPosts(postsAll.filter(elem => elem.kategory.indexOf(id) !== -1))
    }
  }
  
  return <>
            <div className="af-class-section af-class-knowledge af-class-an-load--2 af-class-wf-section">
              <div className="af-class-container w-container">
                <h1 className="af-class-title-main-p_knowledge">Статьи о кофе и способы заваривания</h1>
                <div className="w-layout-grid af-class-grid-main-p_knowledge">
                  <div id="w-node-_5a589d89-8e48-616c-2369-8ba3f6e3c497-c03d363c" className="w-layout-grid af-class-text_and_anchors">
                    <div className="af-class-discription-p_vocabulary">Познавательные статьи о кофе и пошаговые инструкции для разных способов заваривания</div>
                    <div className="w-layout-grid af-class-filter-p_knowledge">
                      {/* <a onClick={() => handleCurrentCategory(null)} className={`af-class-content_links${currentCategory == null ? ' af-class-state-active' : ''}`}>Все статьи</a> */}
                      { categories.map((elem, index) => <a onClick={() => handleCurrentCategory(elem.id)} key={elem.id} className={`af-class-content_links${(currentCategory == elem.id || (currentCategory == null && elem.id == 419)) ? ' af-class-state-active' : ''}`}>{elem.id == 419 ? 'Статьи' : elem.name}</a>) }
                    </div>
                  </div>

                  <div className="w-layout-grid af-class-content-p_knowledge" style={{maxWidth: '800px'}}>
                    <div className="w-layout-grid af-class-title-wrapper-p_knowledge">
                      <h2 className="af-class-title-p_knowledge">Актуальная информация о кофейной культуре и способах заваривания</h2>
                      <div className="af-class-counter-p_knowledge">{posts.length} {(posts.length >= 11 && posts.length <= 14) ? 'статей' : (posts.length % 10 == 1) ? 'статья' : (posts.length % 10 == 2 || posts.length % 10 == 3 || posts.length % 10 == 4) ? 'статьи' : 'статей'}</div>
                    </div>
                    
                    { isLoading === true && <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div> }

                    <div className="w-layout-grid af-class-cards-grid-p_knowledge">

                      {/* { posts.map((elem, index) => <div key={elem.id} className="w-layout-grid af-class-card-p_knowledge af-class-an-scroll-into-view">
                        <div className="af-class-image-wrapper-card-p_knowledge" onClick={() => navigate(`/knowledge/${elem.slug}`)}>
                          <img src={elem?.featured_image_src?.medium} loading="lazy" alt="" className="af-class-image-card-p_knowledge af-class-an-image--scroll-into-view" />
                        </div>
                        <div fs-cmsfilter-field="type" className="af-class-badge-card-p_knowledge">{elem?.categories?.map(cid => categories.find(c => c.id == cid)?.name).join(', ')}</div>
                        <div className="af-class-title-card-p-knowledge" onClick={() => navigate(`/knowledge/${elem.slug}`)}>{elem?.title?.rendered}</div>
                      </div> )} */}

                      { posts.map((elem, index) => <div key={elem.id} className="w-layout-grid af-class-card-p_knowledge af-class-an-scroll-into-view">
                        <div className="af-class-image-wrapper-card-p_knowledge" onClick={() => navigate(`/knowledge/${elem.slug}`)}>
                          <img src={medias.find(media => media.id == elem.featured_media)?.source_url} loading="lazy" alt="" className="af-class-image-card-p_knowledge af-class-an-image--scroll-into-view" />
                        </div>
                        <div fs-cmsfilter-field="type" className="af-class-badge-card-p_knowledge">{elem?.categories?.map(cid => categories.find(c => c.id == cid)?.name).join(', ')}</div>
                        <div className="af-class-title-card-p-knowledge" onClick={() => navigate(`/knowledge/${elem.slug}`)}>{elem?.title?.rendered}</div>
                      </div> )}

                    </div>
                    {/* <div className="af-class-button-wrapper-p_knowledge">
                      <a href="#" className="af-class-button-load_more-p_knowledge w-button">Загрузить еще</a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
    </>
}

export default KnowledgeView

/* eslint-enable */