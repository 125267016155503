import React from 'react'
import { useEffect, useRef } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const DropdownReact = ({ links, titleLink, col, className }) => {
    const [open, setOpen] = useState()
    const display = col ? 'block': 'flex'
    const style = useMemo(() => open ? {display}: {display: 'none'}, [open])

    const [ mouseOverCurrent, setMouseOverCurrent ] = useState('')

    useEffect(() => {
        if (mouseOverCurrent == 'opener' || mouseOverCurrent == 'menu') {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [mouseOverCurrent])

    return (
        <>
            <div data-hover="true" data-delay="0" className={`af-class-header__link--options w-dropdown ${className}`} onClick={(e) => setOpen(val => !val)} onMouseOver={() => setMouseOverCurrent('opener')} onMouseLeave={() => setMouseOverCurrent('')} style={{marginTop: open ? '33px' : 0, flexDirection: 'column', justifyContent: 'flex-start'}}>
                { titleLink != '...' ? <div className="af-class-header__dropdown-toggle w-dropdown-toggle">
                    <div className="af-class-header__dropdown-text--options">{titleLink}</div>
                    <img src="/images/arrow-down.svg" loading="lazy" width="9" alt="" className="af-class-header__dropdown-icon--options" style={{marginTop: open ? '-1px' : '-1px'}} />
                </div> : <div className="af-class-header__dropdown-toggle w-dropdown-toggle" style={{ padding: '5px 0' }}>
                    <div style={{height: '6px', width: '6px', border: '1px #777 solid', borderRadius: '50%', marginRight: '3px'}} />
                    <div style={{height: '6px', width: '6px', border: '1px #777 solid', borderRadius: '50%', marginRight: '3px'}} />
                    <div style={{height: '6px', width: '6px', border: '1px #777 solid', borderRadius: '50%'}} />
                </div> }
                    {/* <nav className="af-class-w-dropdown-list"> */}
                    <div style={{position: 'relative', top: 0, width: '100%', paddingTop: '33px', ...style}}>
                        <div className="af-class-dropdown-list" style={{top: '10px'}}>
                            {links}
                        </div>
                    </div>
                    {/* </nav> */}
            </div>
        </>
    )
}

export default DropdownReact