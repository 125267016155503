import { useState } from 'react';
import FilterField from './Filter/FilterField';
import FilterFieldTooltip from './Filter/FilterFieldTooltip';
import FilterFieldPellet from './Filter/FilterFieldPellet';

const CatalogSortMobile = ({ filters, categoryFiltersQuick, toggleFilter, setParentSort, filtersMobileToggle, showFiltersButton }) => {
  const [ dropdown, setDropdown ] = useState(false);
  const [ sort, setSort ] = useState({ id: 0, name: 'По умолчанию' });

  const handleFilterChange = (value) => {
    setDropdown(false);
    setSort(value);
    setParentSort(value.id);
  }

  return <>
    {categoryFiltersQuick?.length > 0 && <div className="catalog-filters-mobile-wrapper" style={{ marginBottom: '15px' }}>
      <div style={{
        display: 'flex',
        flexWrap: 'nowrap',
        width: 'max-content',
        gap: '10px',
      }}>
        {categoryFiltersQuick.map((filter, index) => <FilterFieldPellet filters={filters} filter={{ id: filter.id, option: filter.option, text: filter?.text }} toggleFilter={toggleFilter} />)}
      </div>
    </div>}

    <div className="af-class-filter_and_sort-wrapper-mobile">
      <div className="af-class-sort-p_catalog af-class-mobile">
        {/* <div className="af-class-title-sort">Сортировать по:</div> */}
        <div data-hover="false" data-delay={0} className="af-class-dropdown w-dropdown" style={{ marginLeft: 0 }}>
          <div className="af-class-dropdown-toggle-2 w-dropdown-toggle" onClick={() => setDropdown(!dropdown)}>
              <div className="af-class-text-dropdown">{sort.name}</div>
              <img src="/images/arrow-down.svg" loading="lazy" alt="" className="af-class-icon-dropdown" />
          </div>
          <nav className="w-dropdown-list" style={{display: dropdown === true ? ' block' : 'none'}}>
              <div className="af-class-dropdown-list af-class-sort-mobile" style={{ left: 0, right: 'auto' }}>
                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 0, name: 'По умолчанию' })}>По умолчанию</a>
                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 1, name: 'Цена: по возрастанию' })}>Цена: по возрастанию</a>
                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 2, name: 'Цена: по убыванию' })}>Цена: по убыванию</a>
                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 3, name: 'Сначала новые' })}>Сначала новые</a>
                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 4, name: 'Сначала старые' })}>Сначала старые</a>
              </div>
          </nav>
        </div>
      </div>
      {showFiltersButton === true && <div
        onClick={filtersMobileToggle}
        style={{
          background: '#fff',
          width: '35px',
          height: '35px',
          borderRadius: '50%',
          cursor: 'pointer',
          position: 'relative',
          // display: 'flex',
          // justifyContent: 'center',
        }}
      >
        <img
          src="/images/icon-filter_1icon-filter.png"
          alt=""
          style={{
            width: '23px',
            height: '23px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-55%, -50%)',
          }}
        />
        {filters.length > 0 && <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: '#EE2A2A',
          color: '#fff',
          fontSize: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}></div>}
      </div>}
    </div>
  </>;
}

export default CatalogSortMobile