import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import {
  getCDEKPrice,
  getDalliPrice,
  getRussianPostPrice,
  getYandexPriceCourier,
  getYandexPricePvz,
  getYandexDeliveryDates
} from "../../../fetch/WPAPI";
import {
  getMinDeliveryDateCoffeeshops,
  calculateDeliveryCostDiscounts,
} from "./functions";
import citiesDalli from "../../../data/dalli-cities.json";

const CartFormGroupFieldDeliveryTypes = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);

  useEffect(() => {
    if (cartFormContext.deliveryType.options.length > 0) {
      handleChangeDeliveryType(cartFormContext.deliveryType.options[0]);
    }
  }, [cartFormContext.deliveryType.options]);

  const handleChangeDeliveryType = (value) => {
    cartFormContext.setDeliveryType({
      ...cartFormContext.deliveryType,
      selected: value,
    });

    // If the delivery type changes, delivery cost is cleared and invalidated (set to false)
    // Also the previously selected delivery details are cleared
    cartFormContext.setDeliveryCost(false);
    cartFormContext.setDeliveryDeadline('');

    cartFormContext.setDeliveryCoffeeshop({
      selected: "Выберите кофейню",
    });

    cartFormContext.setDeliveryPvz({
      selected: "Выберите пункт самовывоза",
      options: [],
    });

    if (value == "Самовывоз из ПВЗ") {
      cartFormContext.setDeliveryCompany({
        selected: "СДЭК", // setting by default
      });
    } else if (value == "Курьером") {
      if (citiesDalli.includes(cartFormContext.city.city)) {
        cartFormContext.setDeliveryCompany({
          selected: "Dalli Service", // setting by default
        });
      } else {
        // СДЭК CDEK hidden for now
        cartFormContext.setDeliveryCompany({
          selected: "СДЭК курьером", // setting by default
        });
      }
    } else if (value == "Почтой России") {
      cartFormContext.setDeliveryCompany({
        selected: "Почтой России", // setting by default
      });
    } else if (value == "Яндекс Доставка") {
      cartFormContext.setDeliveryCompany({
        selected: "Яндекс Доставка", // setting by default
      });
    } else if (value == "Яндекс ПВЗ") {
      cartFormContext.setDeliveryCompany({
        selected: "Яндекс ПВЗ", // setting by default
      });
    } else {
      cartFormContext.setDeliveryCompany({
        selected: "Выберите компанию",
      });
    }

    // If the delivery type is by courier or by Russian Post and the user has a default address in the same city, then the address is automatically selected
    // TODO this doesn't work, since the city is not stored separately in the address data
    const userAddressDefault = mainContext.userAddresses.find((elem) => elem.default === true);
    if ((value == "Курьером" || value == "Почтой России") && userAddressDefault && userAddressDefault?.city == cartFormContext.city.city) {
      cartFormContext.setAddressData({
        ...cartFormContext.addressData,
        ...userAddressDefault,
      });
    } else {
      cartFormContext.setAddressData({
        ...cartFormContext.addressData,
        full: '',
        entrance: '',
        floor: '',
        flat: '',
        homephone: '',
      });
    }

    cartFormContext.setPaymentType("card");
  };

  const getDeliveryTypeName = (deliveryType) => {
    if (deliveryType == "Курьером") {
      if (citiesDalli.includes(cartFormContext.city.city)) {
        return "Курьером Dalli";
      } else {
        // СДЭК CDEK hidden for now
        return "Курьером СДЭК";
      }
    }
    if (deliveryType == "Самовывоз из ПВЗ") {
      return "Самовывоз из ПВЗ СДЭК";
    }
    if (deliveryType == "Яндекс Доставка") {
      return "Курьером Яндекс";
    }
    if (deliveryType == "Яндекс ПВЗ") {
      return "Самовывоз из ПВЗ Яндекс";
    }
    return deliveryType;
  };

  useEffect(() => {
    let discount = false;
    if (cartFormContext.deliveryType.selected == "Самовывоз из кофейни") {
      discount = 0;
    } else if (cartFormContext.deliveryType.selected == "Самовывоз из ПВЗ") {
      discount = cartFormContext.deliveryCostDiscounts.pvz;
    } else if (cartFormContext.deliveryType.selected == "Курьером") {
      discount = cartFormContext.deliveryCostDiscounts.courier;
    } else if (cartFormContext.deliveryType.selected == "Почтой России") {
      discount = cartFormContext.deliveryCostDiscounts.russianPost;
    } else if (cartFormContext.deliveryType.selected == "Яндекс Доставка") {
      discount = cartFormContext.deliveryCostDiscounts.yandex;
    } else if (cartFormContext.deliveryType.selected == "Яндекс ПВЗ") {
      discount = cartFormContext.deliveryCostDiscounts.yandex;
    }

    if (discount !== false) {
      cartFormContext.setDeliveryCostDiscount(discount);
    } else {
      cartFormContext.setDeliveryCost(false);
      cartFormContext.setDeliveryCostDiscount(0);
      cartFormContext.setDeliveryCostDiscounted(0);
    }

    if (cartFormContext.deliveryCost !== false) {
      if (discount !== false) {
        let deliveryCost = cartFormContext.deliveryCost - discount;
        if (deliveryCost < 0) {
          deliveryCost = 0;
        }
        cartFormContext.setDeliveryCostDiscounted(deliveryCost);
      }
    }
  }, [cartFormContext.deliveryType.selected, cartFormContext.deliveryCost, cartFormContext.deliveryCostDiscounts]);

  useEffect(() => {
    const { discountCourier, discountPvz, discountRussianPost, discountYandex } = calculateDeliveryCostDiscounts(mainContext.cartTotalPrice, cartFormContext.city.city);
    cartFormContext.setDeliveryCostDiscounts({
      courier: discountCourier,
      pvz: discountPvz,
      russianPost: discountRussianPost,
      yandex: discountYandex,
    });
  }, [mainContext.cartTotalPrice, cartFormContext.city.city]);

  const [yandexDate, setYandexDate] = useState(null)
  useEffect(() => {
    const fetchYandexDeliveryDates = async () => {
      if (cartFormContext.city && cartFormContext.city.city) {
        const res = await getYandexDeliveryDates(cartFormContext.city.city);
        setYandexDate(res);
      }
    };

    fetchYandexDeliveryDates();
  }, [cartFormContext.city.city]);

  const getDynamicDeliveryDateTime = (deliveryType) => {
    const restrictedDates = [];

    restrictedDates.push(new Date(2024, 2, 8));

    const today = new Date();
    if ((today.getMonth() == 2 && ((today.getDate() == 7 && today.getHours() >= 14) || today.getDate() > 7))) {
      restrictedDates.push(new Date(2024, 2, 9));
      restrictedDates.push(new Date(2024, 2, 10));
      restrictedDates.push(new Date(2024, 2, 11));
    }

    // May holidays
    if (((today.getMonth() == 3 && ((today.getDate() == 27 && today.getHours() >= 14) || today.getDate() > 27)))
     || ((today.getMonth() == 4 && today.getDate() <= 2))) {
      restrictedDates.push(new Date(2024, 3, 27));
      restrictedDates.push(new Date(2024, 3, 28));
      restrictedDates.push(new Date(2024, 3, 29));
      restrictedDates.push(new Date(2024, 3, 30));
      restrictedDates.push(new Date(2024, 4, 1));
      restrictedDates.push(new Date(2024, 4, 2));
    }

    if ((today.getMonth() == 4 && ((today.getDate() == 3 && today.getHours() >= 14) || (today.getDate() >=4 && today.getDate() <= 5)))) {
      restrictedDates.push(new Date(2024, 4, 3));
      restrictedDates.push(new Date(2024, 4, 4));
      restrictedDates.push(new Date(2024, 4, 5));
      restrictedDates.push(new Date(2024, 4, 6));
    }

    if ((today.getMonth() == 4 && ((today.getDate() == 8 && today.getHours() >= 14) || (today.getDate() >= 9 && today.getDate() <= 12)))) {
      restrictedDates.push(new Date(2024, 4, 8));
      restrictedDates.push(new Date(2024, 4, 9));
      restrictedDates.push(new Date(2024, 4, 10));
      restrictedDates.push(new Date(2024, 4, 11));
      restrictedDates.push(new Date(2024, 4, 12));
      restrictedDates.push(new Date(2024, 4, 13));
    }

    restrictedDates.push(new Date(2024, 5, 12));
    if ((today.getMonth() == 5 && ((today.getDate() == 11 && today.getHours() >= 14) || (today.getDate() >= 12 && today.getDate() <= 13)))) {
      restrictedDates.push(new Date(2024, 5, 13));
    }

    let dateStr = '';

    if (deliveryType == 'Курьером') {
      dateStr = cartFormContext.dynamicDeliveryDataCourier.datetime;
    } else if (deliveryType == 'Самовывоз из кофейни') {
      dateStr = getMinDeliveryDateCoffeeshops().toLocaleDateString("ru-RU");
    } else if (deliveryType == 'Самовывоз из ПВЗ') {
      dateStr = cartFormContext.dynamicDeliveryDataPvz.datetime;
    } else if (deliveryType == 'Почтой России') {
      dateStr = cartFormContext.dynamicDeliveryDataRussianPost.datetime;
    } else if (deliveryType == 'Яндекс Доставка') {
      if (yandexDate) {
        const dateObj = new Date(yandexDate.split('.').reverse().join('-'))
        const today = new Date()
        const isWeekDay = today.getDay() > 0 && today.getDay() < 6
        if (isWeekDay) {
          dateObj.setDate(dateObj.getDate() + 1)
        } else {
          dateObj.setDate(dateObj.getDate() + (today.getDay() === 0 ? 2 : 3))
        }
        dateStr = dateObj.toLocaleDateString("ru-RU")
      } else {
        dateStr = yandexDate ?? cartFormContext.dynamicDeliveryDataYandexCourier.datetime;
      }

    } else if (deliveryType == 'Яндекс ПВЗ') {
      dateStr = cartFormContext.dynamicDeliveryDataYandexPvz.datetime;
    }

    let allDates = dateStr.split('-');

    let day = allDates[0].split('.')[0];
    let month = allDates[0].split('.')[1];
    let dateObj = new Date(new Date().getFullYear(), month - 1, day);

    let addedDays = 0;
    while (restrictedDates.some((elem) => elem.toLocaleDateString() == dateObj.toLocaleDateString())) {
      dateObj.setDate(dateObj.getDate() + 1);
      addedDays++;
    }

    // requested patch for pickup options - plus one working day
    if (deliveryType == 'Самовывоз из ПВЗ' || deliveryType == 'Яндекс ПВЗ') {
      dateObj.setDate(dateObj.getDate() + 1);
      if (dateObj.getDay() == 6) {
        dateObj.setDate(dateObj.getDate() + 2);
      }
      if (dateObj.getDay() == 0) {
        dateObj.setDate(dateObj.getDate() + 1);
      }
    }

    dateStr = `${dateObj.toLocaleDateString("ru-RU")}`;

    if (allDates.length > 1) {
      let day = allDates[1].split('.')[0];
      let month = allDates[1].split('.')[1];
      let dateObj2 = new Date(new Date().getFullYear(), month - 1, day);

      dateObj2.setDate(dateObj.getDate() + addedDays + 1);

      dateStr += `-${dateObj2.toLocaleDateString("ru-RU")}`;
    }

    if (dateStr == 'Invalid Date') {
      return '';
    }

    if (deliveryType == 'Самовывоз из кофейни') {
      return `${dateStr}`;
    } else {
      return dateStr;
    }
  }

  const getDynamicDeliveryPrice = (deliveryType) => {
    if (deliveryType == 'Курьером') {
      return (cartFormContext.dynamicDeliveryDataCourier.cost - cartFormContext.deliveryCostDiscounts.courier > 0) ? `(от ${cartFormContext.dynamicDeliveryDataCourier.cost - cartFormContext.deliveryCostDiscounts.courier} ₽)` : `(0 ₽)`;
    } else if (deliveryType == 'Самовывоз из кофейни') {
      return `(0 ₽)`;
    } else if (deliveryType == 'Самовывоз из ПВЗ') {
      return (cartFormContext.dynamicDeliveryDataPvz.cost - cartFormContext.deliveryCostDiscounts.pvz > 0) ? `(от ${cartFormContext.dynamicDeliveryDataPvz.cost - cartFormContext.deliveryCostDiscounts.pvz} ₽)` : `(0 ₽)`;
    } else if (deliveryType == 'Почтой России') {
      return (cartFormContext.dynamicDeliveryDataRussianPost.cost - cartFormContext.deliveryCostDiscounts.russianPost > 0) ? `(от ${cartFormContext.dynamicDeliveryDataRussianPost.cost - cartFormContext.deliveryCostDiscounts.russianPost} ₽)` : `(0 ₽)`;
    } else if (deliveryType == 'Яндекс Доставка') {
      return (cartFormContext.dynamicDeliveryDataYandexCourier.cost - cartFormContext.deliveryCostDiscounts.yandex > 0) ? `(от ${cartFormContext.dynamicDeliveryDataYandexCourier.cost - cartFormContext.deliveryCostDiscounts.yandex} ₽)` : `(0 ₽)`;
    } else if (deliveryType == 'Яндекс ПВЗ') {
      return (cartFormContext.dynamicDeliveryDataYandexPvz.cost - cartFormContext.deliveryCostDiscounts.yandex > 0) ? `(от ${cartFormContext.dynamicDeliveryDataYandexPvz.cost - cartFormContext.deliveryCostDiscounts.yandex} ₽)` : `(0 ₽)`;
    }
  }

  useEffect(() => {
    cartFormContext.setDeliveryCost(false);
    cartFormContext.setDeliveryDeadline('');

    if (cartFormContext.addressData.full.length > 0) {
      if (cartFormContext.deliveryCompany.selected == "СДЭК курьером") {
        getCDEKPrice(
          137,
          cartFormContext.city.city,
          cartFormContext.city.cityPostalCode,
          cartFormContext.addressData.full,
          mainContext.cartTotalWeight,
          cartFormContext.city.country
        ).then((result) => {
          if (result.total_sum) {
            cartFormContext.setDeliveryCost(parseInt(result.total_sum));
            cartFormContext.setDeliveryDeadline(`от ${result.calendar_min} до ${result.calendar_max} дней`);
          }
        });
      } else if (cartFormContext.deliveryCompany.selected == "Dalli Service") {
        getDalliPrice(cartFormContext.addressData.full, mainContext.cartTotalWeight).then((result) => {
          if (result["@attributes"].price) {
            cartFormContext.setDeliveryCost(parseInt(result["@attributes"].price));
            cartFormContext.setDeliveryDeadline(result["@attributes"].msg);
          }
        });
      } else if (cartFormContext.deliveryCompany.selected == "Почтой России") {
        getRussianPostPrice(cartFormContext.city.cityFias, mainContext.cartTotalWeight > 0 ? mainContext.cartTotalWeight : 1).then((result) => {
          if (result.price && result.price[0]["@attributes"].price) {
            cartFormContext.setDeliveryCost(parseInt(result.price[0]["@attributes"].price));
            cartFormContext.setDeliveryDeadline(`${new Date(new Date().setDate(new Date().getDate() + parseInt(result.price[0]["@attributes"].delivery_period))).toLocaleDateString("ru-RU")}`);
          }
        });
      } else if (cartFormContext.deliveryCompany.selected == "Яндекс Доставка") {
        getYandexPriceCourier(cartFormContext.addressData.full, mainContext.cartTotalWeight).then((result) => {
          if (result.cost) {
            cartFormContext.setDeliveryCost(parseInt(result.cost));
            cartFormContext.setDeliveryDeadline(`${new Date(new Date().setDate(new Date().getDate() + 2)).toLocaleDateString("ru-RU")}`);
          }
        });
      }
    }
  }, [cartFormContext.addressData, cartFormContext.deliveryCompany.selected, mainContext.cartTotalWeight]);



  return (
    <>
      {(cartFormContext.city.city != "Выберите город" && cartFormContext.city.city != "") && cartFormContext.deliveryType.options.map((elem, index) => {
        if ((cartFormContext.cityPvzYandex?.points && cartFormContext.cityPvzYandex.points?.length == 0) && (elem == 'Яндекс ПВЗ')) {
          return <></>;
        }

        if (elem == 'Яндекс Доставка' && yandexDate == null) {
          return <></>;
        }

        return <>
        <label
          className="af-class-cart__user-radio-button w-radio"
          style={{cursor: 'pointer'}}
          onClick={() => handleChangeDeliveryType(elem)}
          key={index}
        >
          <div className="af-class-cart__user-radio-button-container">
            <div>
              <div
                className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input${
                  cartFormContext.deliveryType.selected == elem ? " w--redirected-checked" : ""
                }`}
              />
              <input
                type="radio"
                style={{ opacity: 0, position: "absolute", zIndex: -1 }}
              />
              <span
                className="af-class-cart__user-radio-button-text w-form-label"
              >
                {getDeliveryTypeName(elem)}
                &nbsp;
                <span className="branded-color-semibold">{getDynamicDeliveryPrice(elem)}</span>
              </span>
            </div>
            <div>
              <span className="gray-color-normal">{getDynamicDeliveryDateTime(elem)}</span>
            </div>
          </div>
        </label>
        {elem == 'Самовывоз из ПВЗ' && <div style={{fontSize: '14px', marginTop: '-10px'}}>Сроки доставки могут быть увеличены из-за повышенной загруженности транспортной компании</div>}
        {elem == 'Почтой России' && <div style={{fontSize: '14px', marginTop: '-10px'}}>Доставка осуществляется в ближайшее к вам почтовое отделение</div>}
        </>
      }) }
    </>
  );
};

export default CartFormGroupFieldDeliveryTypes;
