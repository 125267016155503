import { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import { getBonusesMCRM } from "../../../fetch/WPAPI";
import useToken from "../../../hooks/useToken.js";

const CartFormFieldBonuses = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);
  const { token, setToken, nonce, setNonce } = useToken();

  // useEffect(() => {
  //   if (Math.floor(mainContext.cartTotalPrice / 2) > cartFormContext.bonusesUserBalance) {
  //     cartFormContext.setBonusesDiscount(cartFormContext.bonusesUserBalance);
  //   } else {
  //     cartFormContext.setBonusesDiscount(Math.floor(mainContext.cartTotalPrice / 2));
  //   }
  // }, [mainContext.cartTotalPrice, cartFormContext.bonusesUserBalance]);

  // useEffect(() => {
  //   if (cartFormContext.bonuses > cartFormContext.orderMaxBonuses) {
  //     cartFormContext.setBonuses(cartFormContext.orderMaxBonuses);
  //   }
  // }, [cartFormContext.orderMaxBonuses]);

  const [bonusesAvailable, setBonusesAvailable] = useState(0);

  const getBonusesUserBalance = async () => {
    const result = await getBonusesMCRM();
    if (result.error === "OK") {
      cartFormContext.setBonusesUserBalance(result.summ);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access")) {
      getBonusesUserBalance();
    }
  }, []);

  useEffect(() => {
    // const orderMaxBonusesDiscount = Math.floor((mainContext.cartTotalPrice + cartFormContext.deliveryCostDiscounted) / 2);
    const orderMaxBonusesDiscount = Math.ceil(mainContext.cartTotalPrice + cartFormContext.deliveryCostDiscounted);
    if (cartFormContext.bonusesUserBalance < orderMaxBonusesDiscount) {
      setBonusesAvailable(cartFormContext.bonusesUserBalance);
    } else {
      setBonusesAvailable(orderMaxBonusesDiscount);
    }
  }, [cartFormContext.bonusesUserBalance, mainContext.cartTotalPrice, cartFormContext.deliveryCostDiscounted]);

  return (
    <>
      {token?.token && nonce && (
        <div className="w-layout-grid af-class-cart__user-info">
          <h3 className="af-class-cart__user-title">
            Доступно для списания{" "}
            <span className="color-branded-blue">
              {bonusesAvailable}
            </span>{" "}
            бонусов
          </h3>
          {/* <p>Списание бонусов временно недоступно, доступно только накопление. Приносим извинения за доставленные неудобства.</p> */}
          <div
            className="w-layout-grid af-class-cart__user-fields"
            style={{ gridRowGap: 0 }}
          >
            <div className={`af-class-cart__user-promocode-wrapper`}>
              <input
                value={cartFormContext.bonusesDiscount}
                onChange={(e) => {
                  if (e.target.value > bonusesAvailable) {
                    cartFormContext.setBonusesDiscount(bonusesAvailable);
                  } else {
                    cartFormContext.setBonusesDiscount(e.target.value);
                  }
                }}
                type="text"
                className={`af-class-cart__user-promocode-field w-input`}
                maxLength={256}
                placeholder="0"
                id="field"
              />
            </div>
            <div className="af-class-cart__cofee-shop-button-wrapper">
              <a
                style={{ cursor: "pointer" }}
                className="af-class-button--secondry w-inline-block"
                onClick={() => {
                  if (cartFormContext.bonusesUserBalance > bonusesAvailable) {
                    cartFormContext.setBonusesDiscount(bonusesAvailable);
                  } else {
                    cartFormContext.setBonusesDiscount(cartFormContext.bonusesUserBalance);
                  }
                }}
              >
                <div className="af-class-cart__button--secondry__text">
                  Списать все бонусы
                </div>
                <div className="af-class-cart__button--secondry__underline" />
              </a>
            </div>
          </div>
        </div>
        )}
    </>
  );
};

export default CartFormFieldBonuses;
