// https://dadata.ru/api/suggest/address/
// https://github.com/vitalybaev/react-dadata
// https://www.npmjs.com/package/react-dadata

import { useState, useContext, useEffect, useRef, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import { CartFormContext } from "../../contexts/CartFormContext";
import {
  createOrder,
  updateOrder,
  getCurrentUserLoyalty,
  payBonusesMCRM,
  getUserMCRM,
} from "../../fetch/WPAPI";
import useCart from "../../hooks/useCart.js";
import useToken from "../../hooks/useToken.js";
import CartFormPopupPvz from "./CartForm/CartFormPopupPvz";
import CartFormPopupPvzYandex from "./CartForm/CartFormPopupPvzYandex";
import CartFormPopupPvzCoffeeShops from "./CartForm/CartFormPopupPvzCoffeeShops";
import CartFormPopupCity from "./CartForm/CartFormPopupCity";
import CartFormPopupAddresses from "./CartForm/CartFormPopupAddresses";
import CartFormFieldPvzCoffeeShop from "./CartForm/CartFormFieldPvzCoffeeShop";
import CartFormFieldPvz from "./CartForm/CartFormFieldPvz";
import CartFormFieldPvzYandex from "./CartForm/CartFormFieldPvzYandex";
import CartFormFieldDeliveryDate from "./CartForm/CartFormFieldDeliveryDate";
import CartFormFieldCertificate from "./CartForm/CartFormFieldCertificate";
import CartFormFieldPromocode from "./CartForm/CartFormFieldPromocode";
import CartFormGroupFieldDeliveryTypes from "./CartForm/CartFormGroupFieldDeliveryTypes";
import CartFormGroupFieldDeliveryAddress from "./CartForm/CartFormGroupFieldDeliveryAddress";
import CartFormGroupFieldPaymentTypes from "./CartForm/CartFormGroupFieldPaymentTypes";
import CartFormFieldBonuses from "./CartForm/CartFormFieldBonuses";
import CartFormCheckbox from "./CartForm/CartFormCheckbox";
import CartFormFieldText from "./CartForm/CartFormFieldText";
import CartFormFieldPhone from "./CartForm/CartFormFieldPhone";

const CartForm = ({ setOrderSuccessful, setCartViewFormIsValid, setCartViewFormSent, cartViewFormSubmit, setCartViewFormSubmit }) => {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);
  const { customerData, setCustomerData, setCart, setCartOrderId } = useCart();
  const { token, setToken, nonce, setNonce } = useToken();

  const metrica = () => {
    // eslint-disable-next-line
    ym(45440976, "reachGoal", "place-order");
    // eslint-disable-next-line
    ym(45440976, "reachGoal", "order-confirm");
    return true;
  };

  // Overall Form States
  const [formIsValid, setFormIsValid] = useState(false);
  const [formSent, setFormSent] = useState(false);

  useEffect(() => {
    setCartViewFormIsValid(formIsValid);
  }, [formIsValid]);

  useEffect(() => {
    setCartViewFormSent(formSent);
  }, [formSent]);

  // When the Component Mounts Populating the Form from the localStorage
  useEffect(() => {
    if (mainContext.userData?.billing?.last_name) {
      cartFormContext.setSurname(mainContext.userData.billing.last_name);
    } else {
      cartFormContext.setSurname(customerData.formSurname);
    }

    if (mainContext.userData?.billing?.first_name) {
      cartFormContext.setName(mainContext.userData.billing.first_name);
    } else {
      cartFormContext.setName(customerData.formName);
    }

    if (mainContext.userData?.billing?.email) {
      cartFormContext.setEmail(mainContext.userData.billing.email);
    } else {
      cartFormContext.setEmail(customerData.formEmail);
    }

    // cartFormContext.setDeliveryType({
    //   ...cartFormContext.deliveryType,
    //   selected: customerData.formDeliveryType,
    // });
    // cartFormContext.setDeliveryCompany({
    //   ...cartFormContext.deliveryCompany,
    //   selected: customerData.formDeliveryCompany,
    // });
    // cartFormContext.setDeliveryCoffeeshop({
    //   ...cartFormContext.deliveryCoffeeshop,
    //   selected: customerData.formCoffeeShop,
    // });
    // cartFormContext.setDeliveryDate(customerData.formDeliveryDate);
    // cartFormContext.setDeliveryTime({
    //   ...cartFormContext.deliveryTime,
    //   selected: customerData.formDeliveryTime,
    // });
    // cartFormContext.setDeliveryPvz({
    //   ...cartFormContext.deliveryPvz,
    //   selected: customerData.formSelectedPvz,
    //   details: customerData.formSelectedPvzDetails,
    // });
    // cartFormContext.setAddressData(customerData.formAddressData);

    cartFormContext.setPaymentType(customerData.formPaymentType);
    cartFormContext.setPaymentWireOrg(customerData.formPaymentWireOrg);
    cartFormContext.setPaymentWireInn(customerData.formPaymentWireInn);
    cartFormContext.setPaymentWireKpp(customerData.formPaymentWireKpp);
    cartFormContext.setPaymentWireAcc(customerData.formPaymentWireAcc);
    cartFormContext.setPaymentWireCorrAcc(customerData.formPaymentWireCorrAcc);
    cartFormContext.setPaymentWireBik(customerData.formPaymentWireBik);
    cartFormContext.setPromoCode(customerData.formPromoCode);
    cartFormContext.setCertificate(customerData.formCertificate);
    cartFormContext.setComments(customerData.formComments);
    cartFormContext.setConfirmationCall(customerData.formConfirmationCall);
    cartFormContext.setUserAgreement(customerData.formUserAgreement);
  }, []);

  // Set Form Validation State + Save Form Data to localStorage - Every Time the Component Rerenders
  useEffect(() => {
    validateForm();
  });

  const validateForm = () => {
    setCustomerData({
      formSurname: cartFormContext.surname,
      formName: cartFormContext.name,
      formPhoneCode: cartFormContext.phoneCode,
      formPhoneFlag: cartFormContext.phoneFlag,
      formPhone: cartFormContext.phone,
      formEmail: cartFormContext.email,
      formDeliveryCountry: cartFormContext.city.country,
      formDeliveryCity: cartFormContext.city.city,
      formDeliveryCityFias: cartFormContext.city.cityFias,
      formDeliveryCityPostalCode: cartFormContext.city.cityPostalCode,
      formDeliveryType: cartFormContext.deliveryType.selected,
      formDeliveryCompany: cartFormContext.deliveryCompany.selected,
      formCoffeeShop: cartFormContext.deliveryCoffeeshop.selected,
      formDeliveryDate: cartFormContext.deliveryDate,
      formDeliveryTime: cartFormContext.deliveryTime.selected,
      formSelectedPvz: cartFormContext.deliveryPvz.selected,
      formSelectedPvzDetails: cartFormContext.deliveryPvz.details,
      formAddressData: cartFormContext.addressData,
      formPaymentType: cartFormContext.paymentType,
      formPaymentWireOrg: cartFormContext.paymentWireOrg,
      formPaymentWireInn: cartFormContext.paymentWireInn,
      formPaymentWireKpp: cartFormContext.paymentWireKpp,
      formPaymentWireAcc: cartFormContext.paymentWireAcc,
      formPaymentWireCorrAcc: cartFormContext.paymentWireCorrAcc,
      formPaymentWireBik: cartFormContext.paymentWireBik,
      formPromoCode: cartFormContext.promoCode,
      formCertificate: cartFormContext.certificate,
      formComments: cartFormContext.comments,
      formConfirmationCall: cartFormContext.confirmationCall,
      formUserAgreement: cartFormContext.userAgreement,
    });

    let deliveryTypeValid = false;
    if (cartFormContext.deliveryType.selected == "Курьером") {
      if (cartFormContext.deliveryCompany.selected == "Dalli Service") {
        deliveryTypeValid = cartFormContext.addressData.full?.length > 0 &&
          cartFormContext.deliveryTime.selected !== "Выберите время доставки";
      } else {
        deliveryTypeValid = cartFormContext.addressData.full?.length > 0;
      }
    } else if (cartFormContext.deliveryType.selected == "Самовывоз из кофейни") {
      deliveryTypeValid = cartFormContext.deliveryCoffeeshop.selected !== "Выберите кофейню";
    } else if (cartFormContext.deliveryType.selected == "Самовывоз из ПВЗ") {
      deliveryTypeValid = cartFormContext.deliveryPvz.selected !== "Выберите пункт самовывоза";
    } else if (cartFormContext.deliveryType.selected == "Почтой России") {
      deliveryTypeValid = cartFormContext.addressData.full?.length > 0;
    } else if (cartFormContext.deliveryType.selected == "Яндекс Доставка") {
      deliveryTypeValid = cartFormContext.addressData.full?.length > 0;
    } else if (cartFormContext.deliveryType.selected == "Яндекс ПВЗ") {
      deliveryTypeValid = cartFormContext.deliveryPvz.selected !== "Выберите пункт самовывоза";
    }

    let wireValid = true;
    if (cartFormContext.paymentType == "wire") {
      wireValid = cartFormContext.paymentWireOrgValid === true &&
        cartFormContext.paymentWireInnValid === true &&
        cartFormContext.paymentWireKppValid === true &&
        cartFormContext.paymentWireAccValid === true &&
        cartFormContext.paymentWireCorrAccValid === true &&
        cartFormContext.paymentWireBikValid === true;
    }

    setFormIsValid(
      cartFormContext.surnameValid === true &&
      cartFormContext.nameValid === true &&
      cartFormContext.phoneValid === true &&
      cartFormContext.emailValid === true &&
      cartFormContext.deliveryCost !== false &&
      deliveryTypeValid === true &&
      wireValid === true &&
      cartFormContext.userAgreement !== false
    );
  };

  useEffect(() => {
    if (cartViewFormSubmit === true) {
      setCartViewFormSubmit(false);
      metrica();
      handleFormSubmit();
    }
  }, [cartViewFormSubmit]);

  const yandexEcommercePurchase = (orderId, total) => {
    window.dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "purchase": {
          "actionField": {
            "id": orderId,
            "revenue": total,
          },
          // "products": [
          //     {
          //         "id": "25341",
          //         "name": "Толстовка Яндекс мужская",
          //         "price": 1345.26,
          //         "brand": "Яндекс / Яndex",
          //         "category": "Одежда/Мужская одежда/Толстовки и свитшоты",
          //         "variant": "Оранжевый цвет",
          //         "quantity": 1,
          //         "list": "Одежда",
          //         "position": 1
          //     },
          //     {
          //         "id": "25314",
          //         "name": "Толстовка Яндекс женская",
          //         "price": 1543.62,
          //         "brand": "Яндекс / Яndex",
          //         "category": "Одежда/Женская одежда/Толстовки и свитшоты",
          //         "variant": "Белый цвет",
          //         "quantity": 3,
          //         "list": "Толстовки",
          //         "position": 2
          //     }
          // ]
        }
      }
    });
  }

  const handleFormSubmit = () => {
    if (formSent === false && formIsValid === true) {
      let total = mainContext.cartTotalPrice +
        cartFormContext.deliveryCostDiscounted -
        cartFormContext.promoCodeDiscount -
        cartFormContext.certificateDiscount -
        cartFormContext.bonusesDiscount;
      if (total < 0) {
        total = 0;
      }
      
      if (cartFormContext.paymentType == "cash" || total == 0) {
        sendOrder("processing").then(async (result) => {
          yandexEcommercePurchase(result.id, total);

          if (cartFormContext.bonusesDiscount > 0) {
            const mCRMUser = await getUserMCRM();
            if (!mCRMUser.card) {
              setFormSent(false);
              alert("Невозможно списать бонусы в данный момент (не найдена бонусная карта). Пожалуйста, свяжитесь с нами.");
              return false;
            }
            const payData = {
              number: mCRMUser.card,
              sum: cartFormContext.bonusesDiscount * 100,
            };
            let bonusesResponse = await payBonusesMCRM(payData);
            console.log(bonusesResponse)
            if (bonusesResponse.status != "success") {
              updateOrder(result.id, { status: "pending" });

              setFormSent(false);
              alert("Невозможно списать бонусы в данный момент. Пожалуйста, свяжитесь с нами.");
              return false;
            }
          }
          
          setCart([]);
          setOrderSuccessful(true);
          navigate(`/thank-you`);
        });
      } else if (cartFormContext.paymentType == "wire") {
        sendOrder("pending").then(async (result) => {
          yandexEcommercePurchase(result.id, total);

          if (cartFormContext.bonusesDiscount > 0) {
            const mCRMUser = await getUserMCRM();
            if (!mCRMUser.card) {
              setFormSent(false);
              alert("Невозможно списать бонусы в данный момент (не найдена бонусная карта). Пожалуйста, свяжитесь с нами.");
              return false;
            }
            const payData = {
              number: mCRMUser.card,
              sum: cartFormContext.bonusesDiscount * 100,
            };
            let bonusesResponse = await payBonusesMCRM(payData);
            console.log(bonusesResponse)
            if (bonusesResponse.status != "success") {
              setFormSent(false);
              alert("Невозможно списать бонусы в данный момент. Пожалуйста, свяжитесь с нами.");
              return false;
            }
          }

          setCart([]);
          setOrderSuccessful(true);
          navigate(`/thank-you`);
        });
      } else {
        sendOrder("pending").then(async (result) => {
          yandexEcommercePurchase(result.id, total);

          if (cartFormContext.bonusesDiscount > 0) {
            const mCRMUser = await getUserMCRM();
            if (!mCRMUser.card) {
              setFormSent(false);
              alert("Невозможно списать бонусы в данный момент (не найдена бонусная карта). Пожалуйста, свяжитесь с нами.");
              return false;
            }
            const payData = {
              number: mCRMUser.card,
              sum: cartFormContext.bonusesDiscount * 100,
            };
            let bonusesResponse = await payBonusesMCRM(payData);
            console.log(bonusesResponse)
            if (bonusesResponse.status != "success") {
              setFormSent(false);
              alert("Невозможно списать бонусы в данный момент. Пожалуйста, свяжитесь с нами.");
              return false;
            }
          }

          // eslint-disable-next-line
          const widget = new cp.CloudPayments();

          const items = mainContext.cartItems.map((i) => {
            return {
              label: i.name,
              price: +i.cartPrice, // цена
              quantity: i.cartQty, // количество
              amount: +i.cartPrice * i.cartQty, // сумма
            };
          });

          if (cartFormContext.deliveryCostDiscounted > 0) {
            items.push({
              label: "Доставка",
              price: cartFormContext.deliveryCostDiscounted, // цена
              quantity: 1, // количество
              amount: cartFormContext.deliveryCostDiscounted, // сумма
            });
          }

          let discountToWriteOff = 0;
          Number(cartFormContext.promoCodeDiscount) > 0
            ? (discountToWriteOff += Number(cartFormContext.promoCodeDiscount))
            : (discountToWriteOff += 0);
          Number(cartFormContext.certificateDiscount) > 0
            ? (discountToWriteOff += Number(cartFormContext.certificateDiscount))
            : (discountToWriteOff += 0);
          Number(cartFormContext.bonusesDiscount) > 0
            ? (discountToWriteOff += Number(cartFormContext.bonusesDiscount))
            : (discountToWriteOff += 0);
          if (discountToWriteOff > 0) {
            items.forEach((item) => {
              let itemPrice = item.price * item.quantity;
              if (discountToWriteOff === itemPrice) {
                itemPrice = 0;
                item.amount = itemPrice;
                discountToWriteOff = 0;
              } else if (itemPrice > discountToWriteOff) {
                itemPrice -= discountToWriteOff;
                item.amount = itemPrice;
                discountToWriteOff = 0;
              } else if (itemPrice < discountToWriteOff) {
                discountToWriteOff -= itemPrice;
                itemPrice = 0;
                item.amount = itemPrice;
              }
            });
          }

          const receipt = {
            Items: items,
            taxationSystem: 2, //система налогообложения; необязательный, если у вас одна система налогообложения
            email: cartFormContext.email, //e-mail покупателя, если нужно отправить письмо с чеком
            phone: cartFormContext.phoneCode + ' ' + cartFormContext.phone, //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
            isBso: false, //чек является бланком строгой отчетности
            amounts: {
              electronic: total, // Сумма оплаты электронными деньгами
              advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
              credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
              provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
            },
          };

          let data = {
            phone: cartFormContext.phoneCode + ' ' + cartFormContext.phone,
            CloudPayments: {
              CustomerReceipt: receipt,
            },
          };

          widget.charge(
            {
              publicId: process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID,
              // accountId: `${result.id}_${Date.now()}`,
              description: "Оплата заказа",
              amount: total,
              currency: "RUB",
              invoiceId: result.id,
              data: data,
              email: cartFormContext.email,
            },
            function (options) {
              // success
              setCart([]);
              setOrderSuccessful(true);
              navigate(`/thank-you`);

              data = { status: "processing" };
              updateOrder(result.id, data);
            },
            function (reason, options) {
              // fail
              setFormSent(false);
            }
          );
          setCartOrderId(result.id); // save to useCart the order ID
        });
      }
    }
  };

  const sendOrder = (status, is_paid) => {
    setFormSent(true);

    let shipping = [];

    // if (cartFormContext.deliveryType.selected == "Почтой России") {
    //   shipping.push("Курьером");
    // } else {
    //   shipping.push(cartFormContext.deliveryType.selected);
    // }
    shipping.push(cartFormContext.deliveryType.selected);

    switch (cartFormContext.deliveryType.selected) {
      case "Самовывоз из кофейни":
        shipping.push(cartFormContext.deliveryCoffeeshop.selected);
        break;
      case "Курьером":
        shipping.push(cartFormContext.deliveryCompany.selected);
        if (cartFormContext.deliveryCompany.selected == "Dalli Service") {
          shipping.push(cartFormContext.deliveryDate);
          shipping.push(cartFormContext.deliveryTime.selected);
        }
        break;
      case "Самовывоз из ПВЗ":
        shipping.push(cartFormContext.deliveryCompany.selected);
        shipping.push(cartFormContext.deliveryPvz.selected);
        break;
      case "Почтой России":
        shipping.push("Почта России Dalli");
        shipping.push(cartFormContext.dynamicDeliveryDataRussianPost.datetime);
        break;
      case "Яндекс ПВЗ":
        shipping.push("Яндекс ПВЗ");
        shipping.push(cartFormContext.deliveryCompany.selected);
        shipping.push(cartFormContext.deliveryPvz.selected);
        break;
      case "Яндекс Доставка":
        shipping.push("Яндекс Доставка");
        break;
    }
    shipping = shipping.join(", ");

    let comments = [];
    if (cartFormContext.city.city?.length > 0) {
      comments.push(cartFormContext.city.city);
    }
    if (shipping.length > 0) {
      comments.push(shipping);
    }

    if (cartFormContext.paymentWireOrg?.length > 0) {
      comments.push(`Наименование организации: ${cartFormContext.paymentWireOrg}`);
    }
    if (cartFormContext.paymentWireInn?.length > 0) {
      comments.push(`ИНН: ${cartFormContext.paymentWireInn}`);
    }
    if (cartFormContext.paymentWireKpp?.length > 0) {
      comments.push(`КПП: ${cartFormContext.paymentWireKpp}`);
    }
    if (cartFormContext.paymentWireAcc?.length > 0) {
      comments.push(`Расчётный счёт: ${cartFormContext.paymentWireAcc}`);
    }
    if (cartFormContext.paymentWireCorrAcc?.length > 0) {
      comments.push(`Корр. счёт: ${cartFormContext.paymentWireCorrAcc}`);
    }
    if (cartFormContext.paymentWireBik?.length > 0) {
      comments.push(`БИК: ${cartFormContext.paymentWireBik}`);
    }

    if (cartFormContext.confirmationCall === true) {
      comments.push("Позвонить для подтверждения");
    }
    if (cartFormContext.comments?.length > 0) {
      comments.push(cartFormContext.comments);
    }
    comments = comments.join("\r\n");
    if (comments.length == 0) {
      comments = null;
    }

    return createOrder(
      {
        status: status,
        items: mainContext.cartItems,
        customerId: mainContext.userData.id ? mainContext.userData.id : 0,
        customer: {
          surname: cartFormContext.surname,
          name: cartFormContext.name,
          phone: cartFormContext.phoneCode + ' ' + cartFormContext.phone,
          email: cartFormContext.email,
        },
        city: cartFormContext.city.city,
        address: {
          fullAddress: cartFormContext.addressData.full,
          entrance: cartFormContext.addressData.entrance,
          floor: cartFormContext.addressData.floor,
          flat: cartFormContext.addressData.flat,
          homephone: cartFormContext.addressData.homephone,
        },
        shipping: shipping,
        paymentType: cartFormContext.paymentType,
        comments: comments,
        promoCode: cartFormContext.promoCodeApplied,
        promoCodeDiscount: cartFormContext.promoCodeDiscount,
        certificate: cartFormContext.certificateApplied,
        certificateDiscount: cartFormContext.certificateDiscount,
        formBonuses: cartFormContext.bonusesDiscount,
        deliveryCostDiscounted: cartFormContext.deliveryCostDiscounted,
        ymClientID: mainContext.ymClientID,
      },
      token?.token,
      nonce
    );
  };

  return (
    <>
      <CartFormPopupPvz />
      <CartFormPopupPvzYandex />
      <CartFormPopupPvzCoffeeShops />
      <CartFormPopupCity />
      <CartFormPopupAddresses />

      <div className="af-class-cart__form w-form">
        <div id="wf-form-User-shipping-info" className="af-class-cart__user">
          <div className="w-layout-grid af-class-cart__user-info">
            <h3 className="af-class-cart__user-title">Данные получателя</h3>
            <div className="w-layout-grid af-class-cart__user-fields af-class-cart__user-fields-condensed">
              <CartFormFieldText
                name="surname"
                value={cartFormContext.surname}
                placeholder="Фамилия"
                onChange={(e) => cartFormContext.setSurname(e.target.value)}
                validate={(value) => value?.length > 0}
                valid={cartFormContext.surnameValid}
                setValid={(value) => cartFormContext.setSurnameValid(value)}
                error={cartFormContext.surnameError}
                setError={(value) => cartFormContext.setSurnameError(value)}
              />
              <CartFormFieldText
                name="name"
                value={cartFormContext.name}
                placeholder="Имя"
                onChange={(e) => cartFormContext.setName(e.target.value)}
                validate={(value) => value?.length > 0}
                valid={cartFormContext.nameValid}
                setValid={(value) => cartFormContext.setNameValid(value)}
                error={cartFormContext.nameError}
                setError={(value) => cartFormContext.setNameError(value)}
              />
              <CartFormFieldPhone
                name="phone"
                onChange={(e) => cartFormContext.setPhone(e.target.value)}
              />
              <CartFormFieldText
                name="email"
                value={cartFormContext.email}
                placeholder="Эл. почта"
                onChange={(e) => cartFormContext.setEmail(e.target.value)}
                validate={(value) => value?.length > 0 && value?.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)}
                valid={cartFormContext.emailValid}
                setValid={(value) => cartFormContext.setEmailValid(value)}
                error={cartFormContext.emailError}
                setError={(value) => cartFormContext.setEmailError(value)}
              />
            </div>
          </div>

          <div className="w-layout-grid af-class-cart__user-info">
            <div className="af-class-cart__user-header-fields">
              <h3 className="af-class-cart__user-title">Доставка</h3>
              <div>
                <img src="../images/pin-drop-google.svg" alt="" style={{marginTop: '-4px'}} />
                <a
                  className="af-class-cart__user-header-city"
                  onClick={() => cartFormContext.setPopup("city")}>
                    {cartFormContext.city.city ? cartFormContext.city.city : 'Выберите город'}
                  </a>
              </div>
            </div>
            <div className="w-layout-grid af-class-cart__user-fields">
              <CartFormGroupFieldDeliveryTypes />
            </div>
          </div>

          {(
            cartFormContext.deliveryType.selected != 'Выберите тип доставки' &&
            !(cartFormContext.deliveryType.selected == 'Курьером' && cartFormContext.deliveryCompany.selected == 'СДЭК курьером') &&
            cartFormContext.deliveryType.selected != 'Почтой России' &&
            cartFormContext.deliveryType.selected != 'Яндекс Доставка'
          ) && <div className="w-layout-grid af-class-cart__user-info">
            <h3 className="af-class-cart__user-title">
              { cartFormContext.deliveryType.selected == 'Курьером' ? 'Дата и время доставки' : 'Пункт самовывоза' }
            </h3>
            <div className="w-layout-grid af-class-cart__user-fields">
              {cartFormContext.deliveryType.selected == "Самовывоз из кофейни" && <CartFormFieldPvzCoffeeShop />}
              {cartFormContext.deliveryType.selected == "Самовывоз из ПВЗ" && cartFormContext.deliveryCompany.selected == "СДЭК" && <CartFormFieldPvz />}
              {cartFormContext.deliveryType.selected == "Яндекс ПВЗ" && cartFormContext.deliveryCompany.selected == "Яндекс ПВЗ" && <CartFormFieldPvzYandex />}
              {cartFormContext.deliveryType.selected == "Курьером" && cartFormContext.deliveryCompany.selected == "Dalli Service" && <CartFormFieldDeliveryDate />}
            </div>
          </div>}

          {["Курьером", "Почтой России", "Яндекс Доставка"].includes(cartFormContext.deliveryType.selected) && <CartFormGroupFieldDeliveryAddress />}

          <div className="w-layout-grid af-class-cart__user-info">
            <h3 className="af-class-cart__user-title">Оплата</h3>
            <CartFormGroupFieldPaymentTypes />
          </div>

          <CartFormFieldBonuses />

          <div className="w-layout-grid af-class-cart__user-info">
            <h3 className="af-class-cart__user-title">
              {(token?.token && nonce) ? 'Сертификат / промокод' : 'Сертификат'}
            </h3>
            <div
              className={`
                w-layout-grid af-class-cart__user-fields
                ${(token?.token && nonce) ? ' af-class-cart__user-fields-condensed' : ''}
              `}
              style={{gridRowGap: '10px'}}
            >
              <CartFormFieldCertificate />
              <CartFormFieldPromocode />
            </div>
            <CartFormCheckbox
              onClick={() => cartFormContext.setConfirmationCall(!cartFormContext.confirmationCall)}
              checked={cartFormContext.confirmationCall !== false}
              description="Позвонить для подтверждения"
            />
          </div>

          <div className="w-layout-grid af-class-cart__user-info">
            <h3 className="af-class-cart__user-title">Комментарии</h3>
            <div className="w-layout-grid af-class-cart__user-fields">
              <textarea
                value={cartFormContext.comments}
                onChange={(e) => cartFormContext.setComments(e.target.value)}
                placeholder="Оставьте комментарий к заказу"
                maxLength={5000}
                id="Comments"
                name="Comments"
                data-name="field"
                className="af-class-cart__user-text-area w-node-_300024e2-b8c0-d70d-fa95-926667c4bfc2-4e3d362f w-input"
              />
            </div>
          </div>

          <div className="w-layout-grid af-class-cart__user-info">
            <div className="w-layout-grid af-class-cart__user-fields">
              <CartFormCheckbox
                onClick={() => cartFormContext.setUserAgreement(!cartFormContext.userAgreement)}
                checked={cartFormContext.userAgreement !== false}
                description={<>Я принимаю{" "}
                <a
                  href="#"
                  onClick={() => navigate(`/user-agreement`)}
                  style={{ color: "#1d71b8", textDecoration: "underline" }}
                  className="cart-user-agreement-checkbox"
                >
                  Пользовательское соглашение
                </a></>}
              />
            </div>
          </div>

          {/* <div
            className="af-class-cart__form-submit-button-wrapper"
            onClick={handleFormSubmit}
          >
            <input
              type="submit"
              onClick={metrica}
              value={formSent ? "Оформляем..." : "Оформить заказ"}
              data-wait="Please wait..."
              className={`af-class-g-submit-button w-button${
                formIsValid === false || formSent === true
                  ? " button-disabled"
                  : ""
              }`}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CartForm;
