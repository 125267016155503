import { useState } from 'react';
import CatalogPage from '../components/Catalog/CatalogPage';

export default function CatalogBlackFridayViewNew() {
  const [categoryFiltersQuick, setCategoryFiltersQuick] = useState([]);

  return <CatalogPage
    categoryId={543}
    categoryFiltersQuick={categoryFiltersQuick}
    setCategoryFiltersQuick={setCategoryFiltersQuick}
    filterByAttributes={[26]}
  />
}