/* eslint-disable */

import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { fetchPost, fetchPostsBySlug, fetchCustomPost, fetchMedia } from '../fetch/WPAPI'
import Product from '../components/Product/Product'
import { fetchProducts } from '../fetch/WPAPI'
import { MainContext } from '../contexts/MainContext'
import { countryCategoriesCollation } from '../scripts/countryCategoriesCollation'

const CountryView = () => {

  const params = useParams()
  const mainContext = useContext(MainContext)
  const navigate = useNavigate()

  const [ post, setPost ] = useState({})
  const [ media, setMedia ] = useState({})
  const [ products, setProducts ] = useState([])

  useEffect(() => {
    setTimeout(() => {
      let karta = document.getElementById('w-node-_94e30b47-b267-b129-7c4b-695df6678dd3-7a3d3634')
      karta.className = 'af-class-content-block__img-container--p-country af-class-images_2 af-class-map content-block__img-container--p-country images_2 map'
      
      // karta.getElementsByClassName('content-block__img-wrapper')[0].className = 'af-class-content-block__img-wrapper'
      // karta.getElementsByClassName('content-block__img--p-country')[0].className = 'af-class-content-block__img--p-country'

      let countries = karta?.getElementsByClassName('af-class-map__label') || []
      let countriesArray = [...countries]
      countriesArray?.forEach((c, index) => {
        // const countryClasses = ['af-class-gonduras', 'af-class-columbia', 'af-class-brazilia', 'af-class-peru', 'af-class-efiopia', 'af-class-uganda', 'af-class-ruanda', 'af-class-kenia', 'af-class-indonesia']

        let collated = countryCategoriesCollation.find(e => e.name == c.innerText)
        if (collated) {
          let classes = ['map__label', 'af-class-map__label-wrapper', collated.mapClass]
          c.classList.add(...classes)
  
          c.addEventListener('click', () => {
            navigate(`/country/${collated.page}`)
          })
        }

      })
    }, 100)
  }, [])

  useEffect(() => {
    loadProducts()
    fetchPostsBySlug([params.slug])
      .then(result => {
        console.log(result)
        if (result.length > 0) {
          setPost(result[0])
        }
      })
  }, [params.slug])

  useEffect(() => {
    document.title = post?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", post?.yoast_head_json?.description)
  }, [post])

  const loadProducts = (params = {}) => {
    let catalog = mainContext.catalogItems.find(elem => elem.categoryId == 17)
    if (catalog) {
      setProducts(catalog.items)
    } else {
      fetchProducts(17)
        .then(result => {
          setProducts(result)
          mainContext.setCatalogItems([...mainContext.catalogItems, { categoryId: 17, items: result }])
        })
    }
  }

  return <>
            <div id="to-top" className="af-class-section af-class-p_country af-class-an-load--2 af-class-wf-section">
              <div className="af-class-container w-container">
                <h1 className="af-class-title-main-p_country">Страны</h1>
                <div style={{display: 'block'}} className="af-class-an-tabs__content--1">
                  <div data-w-id="76147e84-4a41-65a3-c20c-98f416d14e67" className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-d905dac2-1e4e-5c8a-181d-0633dca54149-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_784dc4ab-2426-7a20-8198-9fa6d927d958-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-_39facb2e-1dc5-5055-de12-8be19322038f-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-a9e8582f-f3c4-9238-0ee6-65f82deb9c3d-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">

                            { countryCategoriesCollation.map((elem, index) => <a key={index} onClick={() => navigate(`/country/${elem.page}`)} className={`af-class-content_links af-class-an-tabs__tab--1${post.slug == elem.page ? ' af-class-state-active' : ''}`}>{elem.name}</a> )}

                          </div>
                        </div>
                      </div>
                      <div id="w-node-dc426aaa-892b-4e35-7422-deda98cd080b-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src={post?.featured_image_src?.large} loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country" dangerouslySetInnerHTML={{__html: post?.content?.rendered}} />
                        {/* <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div> */}
                      </div>
                    </div>

                    <div style={{gridColumn: '1 / -1'}}>
                      <Splide className="af-class-splide af-class-splide__country-page" options={{ perPage: 3, perMove: 1, gap: '15px', breakpoints: { 991: { perPage: 1 } } }}>
                          { post.id && products.filter(elem => elem.categories.find(c => c.id == countryCategoriesCollation.find(c => c.page == post.slug).id)).map((elem, index) => <SplideSlide className="af-class-splide__slide" key={elem.id}><Product key={elem.id} {...elem} style={{width: '100%'}} styleContainer={{width: '100%', height: '100%'}} /></SplideSlide> )}
                      </Splide>
                    </div>

                    <div id="w-node-_94e30b47-b267-b129-7c4b-695df6678dcf-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_94e30b47-b267-b129-7c4b-695df6678dd3-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2 af-class-map">
                        <div className="af-class-content-block__img-wrapper">
                          <img src="/images/country__map.png" loading="lazy" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-burundi w-inline-block">
                            <div className="af-class-map__label-icon"></div>
                            <div className="af-class-map__label">Бурунди</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-bolivia w-inline-block">
                            <div className="af-class-map__label-icon"></div>
                            <div className="af-class-map__label">Боливия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-salvador w-inline-block">
                            <div className="af-class-map__label-icon"></div>
                            <div className="af-class-map__label">Сальвадор</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gvatemala w-inline-block">
                            <div className="af-class-map__label-icon"></div>
                            <div className="af-class-map__label">Гватемала</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-costa-rica w-inline-block">
                            <div className="af-class-map__label-icon"></div>
                            <div className="af-class-map__label">Коста-Рика</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-mexico w-inline-block">
                            <div className="af-class-map__label-icon"></div>
                            <div className="af-class-map__label">Мексика</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div style={{display: 'none'}} className="af-class-an-tabs__content--2">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-_983eb96e-4486-6deb-11f4-95e134583fff-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584000-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584001-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584002-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e134584003-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e134584005-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e134584007-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e134584009-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e13458400b-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e13458400d-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e13458400f-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e134584011-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-_983eb96e-4486-6deb-11f4-95e134584013-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584015-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-_983eb96e-4486-6deb-11f4-95e13458401f-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584020-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584021-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="983eb96e-4486-6deb-11f4-95e134584022" id="w-node-_983eb96e-4486-6deb-11f4-95e134584022-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="983eb96e-4486-6deb-11f4-95e134584023" id="w-node-_983eb96e-4486-6deb-11f4-95e134584023-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584024-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="983eb96e-4486-6deb-11f4-95e134584036" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584037-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584038-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="983eb96e-4486-6deb-11f4-95e13458403b" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-_983eb96e-4486-6deb-11f4-95e13458403e-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="983eb96e-4486-6deb-11f4-95e134584047" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-_983eb96e-4486-6deb-11f4-95e13458404a-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="983eb96e-4486-6deb-11f4-95e134584053" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584056-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="983eb96e-4486-6deb-11f4-95e13458405f" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584062-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-_983eb96e-4486-6deb-11f4-95e13458406b-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-_983eb96e-4486-6deb-11f4-95e13458406d-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_983eb96e-4486-6deb-11f4-95e13458406e-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-_983eb96e-4486-6deb-11f4-95e134584074-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-_983eb96e-4486-6deb-11f4-95e134584077-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-_983eb96e-4486-6deb-11f4-95e134584079-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-_983eb96e-4486-6deb-11f4-95e13458407a-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-_983eb96e-4486-6deb-11f4-95e13458407e-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_983eb96e-4486-6deb-11f4-95e13458407f-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--3">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebde7-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebde8-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebde9-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdea-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdeb-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebded-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdef-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdf1-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdf3-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdf5-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdf7-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdf9-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdfb-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebdfd-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe07-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe08-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe09-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="bd47a3b6-dc03-eecf-83f4-3b574feebe0a" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe0a-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="bd47a3b6-dc03-eecf-83f4-3b574feebe0b" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe0b-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe0c-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="bd47a3b6-dc03-eecf-83f4-3b574feebe1e" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe1f-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe20-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="bd47a3b6-dc03-eecf-83f4-3b574feebe23" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe26-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="bd47a3b6-dc03-eecf-83f4-3b574feebe2f" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe32-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="bd47a3b6-dc03-eecf-83f4-3b574feebe3b" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe3e-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="bd47a3b6-dc03-eecf-83f4-3b574feebe47" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe4a-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe53-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe55-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe56-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe5c-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe5f-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe61-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe62-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe66-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-bd47a3b6-dc03-eecf-83f4-3b574feebe67-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--4">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028c3-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028c4-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028c5-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028c6-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028c7-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028c9-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028cb-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028cd-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028cf-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028d1-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028d3-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028d5-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028d7-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028d9-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028e3-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028e4-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028e5-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="1d63789f-8b51-20ae-b690-7a11c54028e6" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028e6-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="1d63789f-8b51-20ae-b690-7a11c54028e7" id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028e7-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028e8-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="1d63789f-8b51-20ae-b690-7a11c54028fa" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028fb-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c54028fc-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="1d63789f-8b51-20ae-b690-7a11c54028ff" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c5402902-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="1d63789f-8b51-20ae-b690-7a11c540290b" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c540290e-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="1d63789f-8b51-20ae-b690-7a11c5402917" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c540291a-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="1d63789f-8b51-20ae-b690-7a11c5402923" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c5402926-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-_1d63789f-8b51-20ae-b690-7a11c540292f-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c5402931-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c5402932-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c5402938-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-_1d63789f-8b51-20ae-b690-7a11c540293b-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-_1d63789f-8b51-20ae-b690-7a11c540293d-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-_1d63789f-8b51-20ae-b690-7a11c540293e-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c5402942-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_1d63789f-8b51-20ae-b690-7a11c5402943-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--5">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f13-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f14-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f15-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f16-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f17-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f19-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f1b-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f1d-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f1f-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f21-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f23-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f25-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f27-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f29-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f33-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f34-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f35-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="1d28c59e-2855-8593-5a38-4dd87ed76f36" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f36-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="1d28c59e-2855-8593-5a38-4dd87ed76f37" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f37-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f38-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="1d28c59e-2855-8593-5a38-4dd87ed76f4a" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f4b-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f4c-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="1d28c59e-2855-8593-5a38-4dd87ed76f4f" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f52-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="1d28c59e-2855-8593-5a38-4dd87ed76f5b" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f5e-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="1d28c59e-2855-8593-5a38-4dd87ed76f67" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f6a-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="1d28c59e-2855-8593-5a38-4dd87ed76f73" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f76-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f7f-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f81-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f82-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f88-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f8b-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f8d-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f8e-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f92-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_1d28c59e-2855-8593-5a38-4dd87ed76f93-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--6">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d99a-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d99b-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d99c-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d99d-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d99e-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9a0-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9a2-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9a4-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9a6-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9a8-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9aa-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9ac-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9ae-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9b0-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9ba-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9bb-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9bc-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="9283494b-8d0a-0e37-5e9d-0d2e8603d9bd" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9bd-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="9283494b-8d0a-0e37-5e9d-0d2e8603d9be" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9be-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9bf-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="9283494b-8d0a-0e37-5e9d-0d2e8603d9d1" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9d2-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9d3-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="9283494b-8d0a-0e37-5e9d-0d2e8603d9d6" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9d9-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="9283494b-8d0a-0e37-5e9d-0d2e8603d9e2" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9e5-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="9283494b-8d0a-0e37-5e9d-0d2e8603d9ee" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9f1-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="9283494b-8d0a-0e37-5e9d-0d2e8603d9fa" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603d9fd-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da06-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da08-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da09-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da0f-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da12-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da14-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da15-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da19-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_9283494b-8d0a-0e37-5e9d-0d2e8603da1a-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--7">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9be-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9bf-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9c0-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9c1-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9c2-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9c4-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9c6-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9c8-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9ca-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9cc-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9ce-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9d0-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9d2-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9d4-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9de-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9df-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9e0-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="0f46808f-284b-914c-9b49-c0788b72b9e1" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9e1-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="0f46808f-284b-914c-9b49-c0788b72b9e2" id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9e2-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9e3-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="0f46808f-284b-914c-9b49-c0788b72b9f5" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9f6-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9f7-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="0f46808f-284b-914c-9b49-c0788b72b9fa" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72b9fd-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="0f46808f-284b-914c-9b49-c0788b72ba06" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba09-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="0f46808f-284b-914c-9b49-c0788b72ba12" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba15-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="0f46808f-284b-914c-9b49-c0788b72ba1e" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba21-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba2a-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba2c-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba2d-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba33-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba36-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba38-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba39-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba3d-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-_0f46808f-284b-914c-9b49-c0788b72ba3e-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--8">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8efe-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8eff-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f00-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f01-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f02-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f04-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f06-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f08-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f0a-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f0c-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f0e-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f10-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f12-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f14-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f1e-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f1f-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f20-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f21" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f21-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f22" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f22-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f23-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f35" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f36-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f37-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f3a" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f3d-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f46" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f49-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f52" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f55-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f5e" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f61-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f6a-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f6c-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f6d-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f73-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f76-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f78-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f79-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f7d-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-ae04c4cc-0ee1-be0d-8e3d-6ea7186b8f7e-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'none'}} className="af-class-an-tabs__content--9">
                  <div className="w-layout-grid af-class-content-blocks--p-country">
                    <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22dd-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22de-7a3d3634" className="w-layout-grid af-class-title_and_tabs af-class-p-country">
                        <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22df-7a3d3634" className="af-class-text_and_tabs">
                          <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22e0-7a3d3634" className="w-layout-grid af-class-tabs-p_about_us">
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22e1-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--1">Эфиопия</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22e3-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--2">Гондурас</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22e5-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--3">Колумбия</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22e7-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--4">Бразилия</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22e9-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--5">Перу</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22eb-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--6">Уганда</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22ed-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--7">Руанда</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22ef-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--8">Кения</a>
                            <a href="#" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22f1-7a3d3634" className="af-class-content_links af-class-an-tabs__tab--9">Индонезия</a>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22f3-7a3d3634" className="w-layout-grid af-class-content-block__wrapper--p-country">
                        <div className="af-class-img-wrapper--p-country"><img src="images/country__img.jpg" loading="lazy" alt="" className="af-class-img--absolute af-class-an-image--scroll-into-view" /></div>
                        <div className="af-class-content-block__subtitle--p-country">Одна из самых почитаемых стран в сфере спешелти кофе Эфиопия, и это неудивительно, ведь арабика была открыта именно здесь. В этой стране многие мелкие производители выращивают в буквальном смысле сотни негибридных разновидностей кофе.</div>
                        <div className="af-class-content-block__text--p-country">Часто на упаковках и маркировках эфиопского зерна вы не встретите указания региона, но сможете увидеть надпись эфиопское наследие или местный негибридный сорт. Именно поэтому эфиопские сорта отличаются таким разнообразием ароматов вкусов. К примеру, кофе из региона Иргачиф в районе Сидамо – известен нотами черного чая, чая Эрл Грей.<br /><br />Харар, расположенный на востоке, знаменит уникальным ароматом своего зерна мытой и натуральной обработки. На западе (к примеру, в Лиму, Джимме, Лекемпти, Велеге и Гимби) кофе имеет больше фруктовых нот, чем зерна откуда-либо еще (многие эфиопские сорта выдают ноты голубики, а натуральная обработка их усиливает).</div>
                      </div>
                    </div>
                    <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22fd-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22fe-7a3d3634" className="af-class-splide af-class-slider2 af-class-p-country">
                        <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe22ff-7a3d3634" className="af-class-splide__arrows af-class-p-country">
                          <div bind="f3a5ff69-90ac-d87e-6f28-391e1ebe2300" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2300-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                          <div bind="f3a5ff69-90ac-d87e-6f28-391e1ebe2301" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2301-7a3d3634" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                        </div>
                        <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2302-7a3d3634" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                            <div className="af-class-splide__slide">
                              <div bind="c0c66ede-ba31-8855-9bd0-f38c99ff9d94" className="af-class-card-catalog">
                                <div className="af-class-products-card__topbar">
                                  <div className="af-class-badge-card-p_catalog">New</div>
                                  <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                                </div>
                                <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 28vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                                <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                <div className="af-class-specifications-card-p_catalog">
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Горечь</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Сладость</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                  <div className="af-class-specifications__block">
                                    <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                    <div className="af-class-specifications__indicators">
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                      <div className="af-class-product-card__specifications-indicator" />
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-options-wrapper-card-p_catalog">
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list">
                                        <a href="#" id="w-node-_44b8beba-734d-eb39-b948-58c6e45fd315-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                    <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                      <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                    </div>
                                    <nav className="w-dropdown-list">
                                      <div className="af-class-dropdown-list af-class-type">
                                        <a href="#" id="w-node-cf929cdd-8fdc-a24c-a3e0-82c4952335b9-99ff9d94" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                        <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                      </div>
                                    </nav>
                                  </div>
                                  <div id="w-node-c0c66ede-ba31-8855-9bd0-f38c99ff9dc3-99ff9d94" className="af-class-button-options-card-p_catalog af-class-quantity">
                                    <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                    <div className="af-class-text-button-option_add-card">1</div>
                                    <div className="af-class-plus-buttonoption-card">+</div>
                                  </div>
                                </div>
                                <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                </a>
                                <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                  <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                  <div className="af-class-price-old-text-button-card">800₽</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="f3a5ff69-90ac-d87e-6f28-391e1ebe2314" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2315-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2316-7a3d3634" className="af-class-accordeon__list">
                        <div className="af-class-accordeon__discription">В Эфиопии очень интересная система производства кофе, которую можно поделить на 4 категории: сад, семифорест, форест, плантация.</div>
                        <div data-w-id="f3a5ff69-90ac-d87e-6f28-391e1ebe2319" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Сад (Гарден)</div>
                          <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe231c-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="f3a5ff69-90ac-d87e-6f28-391e1ebe2325" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Семифорест</div>
                          <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2328-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="f3a5ff69-90ac-d87e-6f28-391e1ebe2331" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Форест</div>
                          <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2334-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                        <div data-w-id="f3a5ff69-90ac-d87e-6f28-391e1ebe233d" className="af-class-accordeon__item">
                          <div className="af-class-accordeon__title">Плантация</div>
                          <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2340-7a3d3634" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
                            <div className="af-class-accordeon__rich-text w-richtext">
                              <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.</p>
                              <p>‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
                            </div>
                          </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
                        </div>
                      </div>
                      <h2 id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2349-7a3d3634" className="af-class-title-main-p_country af-class-_2">Система производства</h2>
                    </div>
                    <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe234b-7a3d3634" className="w-layout-grid af-class-block-content-p_country af-class-an-scroll-into-view">
                      <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe234c-7a3d3634" className="w-layout-grid af-class-text_block-p_about_us">
                        <div className="af-class-content-block__text--p-country">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.<br /><br />Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</div>
                      </div>
                      <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2352-7a3d3634" className="af-class-image-wrapper-block-p_about_us af-class-images_2">
                        <div className="w-layout-grid af-class-content-block__image-grid--p-country af-class-images_2">
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img_6-p_knowledge.jpg" loading="lazy" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2355-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                          <div className="af-class-image_wrapper-p_about_us"><img src="images/img-tab-p_about_us-2.jpg" loading="lazy" id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2357-7a3d3634" alt="" className="af-class-image-p_about_us af-class-an-image--scroll-into-view" /></div>
                        </div>
                      </div>
                      <h2 id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe2358-7a3d3634" className="af-class-title-main-p_country af-class-_2">Особый способ<br />выращивания</h2>
                    </div>
                    <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe235c-7a3d3634" className="w-layout-grid af-class-block-content-p_about_us af-class-an-scroll-into-view">
                      <div id="w-node-f3a5ff69-90ac-d87e-6f28-391e1ebe235d-7a3d3634" className="af-class-content-block__img-container--p-country af-class-images_2">
                        <div className="af-class-content-block__img-wrapper"><img src="images/country__map.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/country__map-p-500.png 500w, images/country__map-p-800.png 800w, images/country__map-p-1080.png 1080w, images/country__map.png 1219w" alt="" className="af-class-content-block__img--p-country" />
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-gonduras af-class-an-tabs__tab--2 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Гондурас</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-columbia af-class-an-tabs__tab--3 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Колумбия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-brazilia af-class-an-tabs__tab--4 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Бразилия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-peru af-class-an-tabs__tab--5 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Перу</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-efiopia af-class-an-tabs__tab--1 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Эфиопия</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-uganda af-class-an-tabs__tab--6 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Уганда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-ruanda af-class-an-tabs__tab--7 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Руанда</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-kenia w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Кения</div>
                          </a>
                          <a href="#to-top" className="af-class-map__label-wrapper af-class-indonesia af-class-an-tabs__tab--9 w-inline-block">
                            <div className="af-class-map__label-icon" />
                            <div className="af-class-map__label">Индонезия</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
}

export default CountryView

/* eslint-enable */