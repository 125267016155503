const CartFormCheckbox = ({ onClick, checked, description }) => {
  return (
    <label
      className="w-checkbox af-class-cart__user-call-checkbox"
      onClick={onClick}
    >
      <div style={{width: '17px', marginRight: '10px'}}>
        <div
          className={`
            w-checkbox-input w-checkbox-input--inputType-custom af-class-cart__user-call-checkbox-icon
            ${checked === false ? "" : " w--redirected-checked"}
          `}
        />
      </div>
      <span
        className="af-class-cart__user-call-checkbox-text w-form-label"
        htmlFor="checkbox"
      >
        {description}
      </span>
    </label>
  );
};

export default CartFormCheckbox;
