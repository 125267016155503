/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { createScope, map, transformProxies } from './helpers'
import { useParams, useNavigate } from 'react-router-dom'
import { fetchCustomPost, fetchMedia, fetchCustomPostsBySlug } from '../fetch/WPAPI'

const texts = {
  1: 'Темперовка и разравнивание. Настройка помола. Виды черного кофе. Обслуживание оборудования.',
  2: 'Темперовка и разравнивание. Настройка помола. Виды черного кофе. Обслуживание оборудования. Эспрессо'
}

const EducationOneView = () => {

  const navigate = useNavigate()

  const params = useParams()

  const [ post, setPost ] = useState({})
  const [ media, setMedia ] = useState({})

  const [ activeTab, setActiveTab ] = useState(1)

  const handleSetActiveTab = (activeTab) => {
    setActiveTab(activeTab)
  }

  useEffect(() => {
    fetchCustomPostsBySlug('obuchenie', [params.slug])
      .then(result => {
        fetchMedia(result[0].featured_media)
          .then(result => {
            console.log(result)
            setMedia(result)
          })
        console.log(result[0])
        setPost(result[0])
      })
  }, [])

  useEffect(() => {
    document.title = post?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", post?.yoast_head_json?.description)
  }, [post])

  const htmlDecode = (html) => {
    return html.replace(/&([a-z]+);/ig, (match, entity) => {
      const entities = { amp: '&', apos: '\'', gt: '>', lt: '<', nbsp: '\xa0', quot: '"' };
      entity = entity.toLowerCase();
      if (entities.hasOwnProperty(entity)) {
        return entities[entity];
      }
      return match;
    });
  }

  return <>
            <div style={{display: 'none'}} className="af-class-popup--p-reviews">
              <div data-w-id="a2dda714-34ef-e591-821f-5b32c872ed27" className="af-class-div-block-13" />
              <div className="af-class-popup--p-reviews__content">
                <div className="af-class-popup--p-reviews__title">Напишите отзыв</div>
                <div className="w-layout-grid af-class-popup--p-reviews__form-wrapper">
                  <div className="af-class-popup--p-reviews__button-wrapper">
                    <div data-hover="false" data-delay={0} className="af-class-popup--p-reviews__button w-dropdown">
                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                        <div className="af-class-text-button-card-p_catalog">О доставке</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="true" className="af-class-icon-button-card-p_catalog" />
                      </div>
                      <nav className="w-dropdown-list">
                        <div className="af-class-dropdown-list af-class-review-popup">
                          <a href="#" id="w-node-a2dda714-34ef-e591-821f-5b32c872ed34-f83d3637" className="af-class-text-dropdown-grey w-dropdown-link">О&nbsp;магазине</a>
                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Об оплате</a>
                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">О команде</a>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div className="af-class-popup--p-reviews__form-block w-form">
                    <form action="#" id="email-form" name="email-form" data-name="Email Form" method="get" className="af-class-popup--p-reviews__form"><textarea placeholder="Комментарий" maxLength={5000} id="field-2" name="field-2" data-name="Field 2" className="af-class-popup--p-reviews__text-area w-input" defaultValue={""} />
                      <div className="af-class-popup--p-reviews__raiting-wrapper--2">
                        <div className="af-class-popup--p-reviews__raiting-title">Ваша оценка</div>
                        <div className="w-layout-grid af-class-popup--p-reviews__raiting-stars">
                          <div data-w-id="a2dda714-34ef-e591-821f-5b32c872ed41" className="af-class-review-mark-wrapper af-class-_1"><img src="images/icon__review-star.svg" loading="lazy" alt="true" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" style={{display: 'none'}} alt="" className="af-class-review-star--checked af-class-_1" /></div>
                          <div data-w-id="a2dda714-34ef-e591-821f-5b32c872ed44" className="af-class-review-mark-wrapper af-class-_2"><img src="images/icon__review-star.svg" loading="lazy" alt="true" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" style={{display: 'none'}} alt="" className="af-class-review-star--checked af-class-_2" /></div>
                          <div data-w-id="a2dda714-34ef-e591-821f-5b32c872ed47" className="af-class-review-mark-wrapper af-class-_3"><img src="images/icon__review-star.svg" loading="lazy" alt="true" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" style={{display: 'none'}} alt="" className="af-class-review-star--checked af-class-_3" /></div>
                          <div data-w-id="a2dda714-34ef-e591-821f-5b32c872ed4a" className="af-class-review-mark-wrapper af-class-_4"><img src="images/icon__review-star.svg" loading="lazy" alt="true" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" style={{display: 'none'}} alt="" className="af-class-review-star--checked af-class-_4" /></div>
                          <div data-w-id="a2dda714-34ef-e591-821f-5b32c872ed4d" className="af-class-review-mark-wrapper af-class-_5"><img src="images/icon__review-star.svg" loading="lazy" alt="true" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" style={{display: 'none'}} alt="" className="af-class-review-star--checked af-class-_5" /></div>
                        </div>
                      </div>
                      <div className="w-layout-grid af-class-grid-34">
                        <div className="af-class-popup--p-reviews__raiting-title">Добавить фото</div>
                        <div id="w-node-a2dda714-34ef-e591-821f-5b32c872ed53-f83d3637" className="af-class-div-block-19"><img src="images/icon--add-photo.svg" loading="lazy" alt="" className="af-class-image-5" /></div>
                      </div><input type="submit" defaultValue="Отправить" data-wait="Please wait..." className="af-class-popup--p-reviews__submit-button w-button" />
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div data-w-id="a2dda714-34ef-e591-821f-5b32c872ed5c" className="af-class-popup--p-reviews__close-icon-wrapper"><img src="images/icon--close-popup.svg" loading="lazy" alt="true" className="af-class-image-7" /></div>
              </div>
            </div>
            
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="w-layout-grid af-class-eduction--one__content">
                  <div style={{cursor: 'pointer'}} onClick={() => navigate(`/education-all`)} id="w-node-_72aea978-0b64-cd2e-2c41-dca9fe8ee47c-f83d3637" className="w-layout-grid af-class-eduction--one__back-button">
                    <img src="/images/icon--back.svg" loading="lazy" id="w-node-_4e5eb958-1004-43c7-3367-1f9b84b5fb54-f83d3637" alt="" />
                    <div id="w-node-_1831b2f5-b254-3fba-1ef8-ea7446b44bd4-f83d3637" className="af-class-text-block-55">Назад ко всем курсам</div>
                  </div>
                  <h1 className="af-class-page-title" dangerouslySetInnerHTML={{__html: post?.title?.rendered}}></h1>
                  <div id="w-node-_747bcdc6-9fa5-bf3b-6f14-e8c1a9e1e221-f83d3637" className="w-layout-grid af-class-eduction--one__list">
                    <div id="w-node-f9dacdfd-3786-ed5c-6c39-e526bf7f1dcb-f83d3637" className="w-layout-grid af-class-eduction--one__hero-item">
                      <div id="w-node-f321cf77-8b72-cd8d-035a-76dac7a32a37-f83d3637" className="af-class-eduction--one__hero-content">
                        {/* <div className="af-class-eduction--one__title">Курс делится на 2 занятия:</div>
                        <div className="af-class-eduction--one__divider" /> */}
                        <div className="w-layout-grid af-class-eduction--one__composition" dangerouslySetInnerHTML={{__html: post?.content?.rendered}}>
                          {/* <div id="w-node-b9ff4b31-7178-edad-34bf-5c1f44215fa5-f83d3637" className="af-class-eduction--one__composition-text">• Молоко</div>
                          <div id="w-node-a94a45cf-e79c-03d0-c312-4bd4b50f984a-f83d3637" className="af-class-eduction--one__composition-text">• Эспрессо</div> */}
                        </div>
                        <div className="w-layout-grid af-class-eduction--one__discription">
                          <div id="w-node-_7e04f6d9-9180-aa17-f85c-9e6d4a46748d-f83d3637" className="w-layout-grid af-class-eduction--one__discription-block">
                            <div id="w-node-_3cdfcf84-1ed2-d7e3-68dd-f8212a86436e-f83d3637" className="af-class-eduction--one__discription-title">Длительность:</div>
                            <div id="w-node-_5c268c3b-9693-cca2-2783-12d36f7c4091-f83d3637" className="af-class-eduction--one__discription-value">{post?.acf?.dlitelnost}</div>
                          </div>
                          <div id="w-node-_9737e405-1de5-5275-41b7-1889a5efec25-f83d3637" className="w-layout-grid af-class-eduction--one__discription-block">
                            <div id="w-node-_9737e405-1de5-5275-41b7-1889a5efec26-f83d3637" className="af-class-eduction--one__discription-title">Стоимость:</div>
                            <div id="w-node-_9737e405-1de5-5275-41b7-1889a5efec28-f83d3637" className="af-class-eduction--one__discription-value">{post?.acf?.stoimost}</div>
                          </div>
                        </div>
                        <div id={post?.acf?.timepad_id} className="af-class-eduction--one__button-wrapper">
                          <a href="#" className="af-class-button--primary af-class-width--auto w-button">Записаться на курс</a>
                        </div>
                      </div>
                      <div id="w-node-db4904d1-676f-b2a7-f5f5-c7f8b3146009-f83d3637" className="af-class-eduction--one__hero-image-wrapper"><img src={media?.source_url} loading="lazy" alt="" className="af-class-eduction--one__hero-image" /></div>
                    </div>
                    <div id="w-node-_5ef31be9-cc55-a512-a363-28490f782a7d-f83d3637" className="w-layout-grid af-class-education--one__item--1">
                      <div id="w-node-_5ef31be9-cc55-a512-a363-28490f782a7e-f83d3637" className="af-class-education--one__item__title">Описание</div>
                      <div id="w-node-_5ef31be9-cc55-a512-a363-28490f782a80-f83d3637" className="w-layout-grid af-class-education--one__item__content" dangerouslySetInnerHTML={{ __html: post?.acf?.dopopisanie }}>
                        {/* <div id="w-node-_5ef31be9-cc55-a512-a363-28490f782a81-f83d3637" className="af-class-education--one__item__subtitle">В этом курсе ты познакомишься с теорией о кофе, начиная с легенды о его возникновении и заканчивая такими понятиями как экстракция, TDS, Brew Ratio и их значимости для вкусной чашки.</div>
                        <div id="w-node-_5ef31be9-cc55-a512-a363-28490f782a83-f83d3637" className="af-class-education--one__item__text">Научишься работе с профессиональным кофейным оборудованием, работе с черным кофе – познакомишься с эспрессо и его разновидностями.</div> */}
                      </div>
                    </div>
                    <div id="w-node-bd05d81c-bd92-6509-915b-17110d71fbe3-f83d3637" className="w-layout-grid af-class-education--one__item--1">
                      <div id="w-node-bd05d81c-bd92-6509-915b-17110d71fbe4-f83d3637" className="af-class-education--one__item__title">Для кого этот курс</div>
                      <div id="w-node-ca917ef5-6cec-2681-29e2-069c9ded743d-f83d3637" className="w-layout-grid af-class-education--one__item__list" dangerouslySetInnerHTML={{ __html: post?.acf?.dliakogoeto }}>
                        {/* <div id="w-node-acc0ee5a-fb7c-fa55-97c2-476e6aff6814-f83d3637" className="af-class-education--one__item__text--2">• Начинающие бариста</div>
                        <div id="w-node-_62b3ebef-d4bd-f2f6-1683-e2d84be85c93-f83d3637" className="af-class-education--one__item__text--2">• Люди, которые хотят разбираться в кофе</div>
                        <div id="w-node-_45c99fc3-6334-60ff-570f-c368b0c14c93-f83d3637" className="af-class-education--one__item__text--2">• Бармены</div> */}
                      </div>
                    </div>
                    <div id="w-node-ce49f3da-63b9-b65c-c1dd-82c2daaba406-f83d3637" className="w-layout-grid af-class-education--one__item--2">
                      <div id="w-node-_645c0b3c-cae0-dd82-d7d1-5f326ebba188-f83d3637" className="af-class-education--one__item__title">Чему вы научитесь</div>
                      <div id="w-node-_24e20233-83e0-46eb-a4f1-6cdc3e1600be-f83d3637" className="w-layout-grid af-class-education--one__item__list" dangerouslySetInnerHTML={{ __html: post?.acf?.chemyvi }}>{/*texts[activeTab]*/}</div>
                      <div id="w-node-f385c2bc-1b46-d57a-a39f-4071b7dad566-f83d3637" className="w-layout-grid af-class-education--one__item__block">
                        {/* <div id="w-node-_6661c18d-d691-0df0-4144-656b8f496d41-f83d3637" className={`af-class-education--one__item__tab ${activeTab == 1 ? ' af-class-active': undefined}`} style={{cursor: 'pointer'}} onClick={() => handleSetActiveTab(1)}>Молоко</div>
                        <div id="w-node-d95b2fc1-625d-f175-5760-7d8b380d46fd-f83d3637" className={`af-class-education--one__item__tab ${activeTab == 2 ? ' af-class-active': undefined}`} style={{cursor: 'pointer'}} onClick={() => handleSetActiveTab(2)}>Эспрессо</div> */}
                      </div>
                    </div>
                    {/* <div id="w-node-d5e85df3-a5cd-1289-1656-bcedb3171110-f83d3637" className="w-layout-grid af-class-education--one__item--1">
                      <div id="w-node-d5e85df3-a5cd-1289-1656-bcedb3171111-f83d3637" className="af-class-education--one__item__title">Отзывы</div>
                      <div className="af-class-comments__wrapper">
                        <div className="af-class-comments__wrap">
                          <div className="af-class-comment__block">
                            <div className="af-class-comment__user_block--p-reviews">
                              <div className="af-class-user__info-block-copy">
                                <img src={`${window.location.origin}/images/avatar_1avatar.jpg`} loading="lazy" width={50} alt="" className="af-class-user__avatar--p-reviews" />
                                <div className="af-class-user__info-text__block--p-reviews-copy">
                                  <div id="w-node-_585248a5-4f13-d0a9-911b-0702e9d8f358-f83d3637" className="af-class-user__info-text"><span className="af-class-user__name">Дмитрий Иванов</span> (<span className="af-class-user-city">Москва</span>)</div>
                                  <div id="w-node-_585248a5-4f13-d0a9-911b-0702e9d8f35f-f83d3637" className="af-class-rating__stars-bock-copy"><img src={`${window.location.origin}/images/heart-filled.svg`} loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src={`${window.location.origin}/images/heart-filled.svg`} loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src={`${window.location.origin}/images/heart-filled.svg`} loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src={`${window.location.origin}/images/heart-filled.svg`} loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src={`${window.location.origin}/images/Star-gray.svg`} loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                  <div id="w-node-_585248a5-4f13-d0a9-911b-0702e9d8f365-f83d3637" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-user__comment--p-reviews-copy">По первым двум заказам магазин производит очень приятное впечатление - удобный и понятный выбор кофе на сайте, без волокиты передача в доставку, большое спасибо.</div>
                          </div>
                        </div>
                        <div className="af-class-education--one__item__button-wrapper">
                          <a data-w-id="585248a5-4f13-d0a9-911b-0702e9d8f38f" href="#" className="af-class-button--primary af-class-width--auto w-button">Оставить отзыв</a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
        </>
}

export default EducationOneView

/* eslint-enable */