import { useContext } from "react";
import { CartFormContext } from "../../../contexts/CartFormContext";

const CartFormFieldPvz = () => {
  const cartFormContext = useContext(CartFormContext);

  return (
    <div
      data-delay={0}
      data-hover="false"
      fs-selectcustom-element="dropdown"
      fs-selectcustom-hideinitial="true"
      className="af-class-g-select w-dropdown"
    >
      <div
        className="af-class-g-select__toggle w-dropdown-toggle"
        onClick={() => cartFormContext.setPopup("pvz")}
      >
        {/* {" "} */}
        <div className="af-class-g-select__text">
          {cartFormContext.deliveryPvz.selected}
        </div>
        <img
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
          }}
          loading="lazy"
          src="images/select-arrow.svg"
          alt=""
          className="af-class-g-select__icon"
        />
      </div>
      <div className="af-class-cart__cofee-shop-button-wrapper">
        <a
          style={{ cursor: "pointer" }}
          className="af-class-button--secondry w-inline-block"
          onClick={() => cartFormContext.setPopup("pvz")}
        >
          <div className="af-class-cart__button--secondry__text">
            Пункты самовывоза на карте
          </div>
          <div className="af-class-cart__button--secondry__underline" />
        </a>
      </div>
    </div>
  );
};

export default CartFormFieldPvz;
