export const getMinDeliveryDateDalli = () => {
    let addedDays;

    // 5 - Fri, 6 - Sat, 0 - Sun
    if (new Date().getDay() == 5) {
        if (new Date().getHours() < 14) {
        addedDays = 1;
        } else {
        addedDays = 4;
        }
    } else if (new Date().getDay() == 6) {
        addedDays = 3;
    } else if (new Date().getDay() == 0) {
        addedDays = 2;
    } else {
        if (new Date().getHours() < 14) {
        addedDays = 1;
        } else {
        addedDays = 2;
        }
    }

    return new Date(new Date().setDate(new Date().getDate() + addedDays));
};

export const getMinDeliveryDateCoffeeshops = () => {
    let addedDays;

    // 5 - Fri, 6 - Sat, 0 - Sun
    if (new Date().getDay() == 5) {
        if (new Date().getHours() < 14) {
        addedDays = 3;
        } else {
        addedDays = 4;
        }
    } else if (new Date().getDay() == 6) {
        addedDays = 3;
    } else if (new Date().getDay() == 0) {
        addedDays = 2;
    } else {
        if (new Date().getHours() < 14) {
        addedDays = 1;
        } else {
        addedDays = 2;
        }
    }

    return new Date(new Date().setDate(new Date().getDate() + addedDays));
};

export const getOffsetDeliveryDateCdek = () => {
    let addedDays;

    // 5 - Fri, 6 - Sat, 0 - Sun
    if (new Date().getDay() == 5) {
        if (new Date().getHours() < 14) {
        addedDays = 0;
        } else {
        addedDays = 4;
        }
    } else if (new Date().getDay() == 6) {
        addedDays = 3;
    } else if (new Date().getDay() == 0) {
        addedDays = 2;
    } else {
        if (new Date().getHours() < 14) {
        addedDays = 0;
        } else {
        addedDays = 1;
        }
    }

    addedDays = addedDays + 2;

    return addedDays;
};

export const calculateDeliveryCostDiscounts = (cartTotal, city) => {
    let discountCourier = 0;
    let discountPvz = 0;
    let discountRussianPost = 0;
    let discountYandex = 0;

    if (city == "Москва") {
        if (cartTotal < 1500) {
            discountCourier = 100;
        } else {
            discountCourier = 99999; // free delivery
        }

        if (cartTotal < 1500) {
            discountPvz = 100;
            discountRussianPost = 100;
            discountYandex = 100;
        } else if (cartTotal >= 1500 && cartTotal < 2500) {
            discountPvz = 300;
            discountRussianPost = 300;
            discountYandex = 300;
        } else if (cartTotal >= 2500 && cartTotal < 4000) {
            discountPvz = 350;
            discountRussianPost = 350;
            discountYandex = 350;
        } else if (cartTotal >= 4000 && cartTotal < 10000) {
            discountPvz = 400;
            discountRussianPost = 400;
            discountYandex = 400;
        } else {
            discountPvz = 99999; // free delivery
            discountRussianPost = 99999; // free delivery
            discountYandex = 99999; // free delivery
        }
    } else {
        if (cartTotal < 1500) {
            discountCourier = 100;
            discountPvz = 100;
            discountRussianPost = 100;
            discountYandex = 100;
        } else if (cartTotal >= 1500 && cartTotal < 2500) {
            discountCourier = 300;
            discountPvz = 300;
            discountRussianPost = 300;
            discountYandex = 300;
        } else if (cartTotal >= 2500 && cartTotal < 4000) {
            discountCourier = 350;
            discountPvz = 350;
            discountRussianPost = 350;
            discountYandex = 350;
        } else if (cartTotal >= 4000 && cartTotal < 10000) {
            discountCourier = 400;
            discountPvz = 400;
            discountRussianPost = 400;
            discountYandex = 400;
        } else {
            discountCourier = 99999; // free delivery
            discountPvz = 99999; // free delivery
            discountRussianPost = 99999; // free delivery
            discountYandex = 99999; // free delivery
        }
    }

    return {
        discountCourier: discountCourier,
        discountPvz: discountPvz,
        discountRussianPost: discountRussianPost,
        discountYandex: discountYandex,
    }
}