/* eslint-disable */

import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  // { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6261fc674c12d792773d3625").then(body => body.text()), isAsync: false },
  // { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class CatalogBusinessView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/CatalogBusinessController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = CatalogBusinessView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6261fc674c12d736a23d3631'
    htmlEl.dataset['wfSite'] = '6261fc674c12d792773d3625'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = CatalogBusinessView.Controller !== CatalogBusinessView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rockets-coffee-export.webflow.css);

          @media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1bf97789-6d43-8063-7cbf-fb9861530033\"] {height:0px;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1bf97789-6d43-8063-7cbf-fb9861530033\"] {height:0px;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1bf97789-6d43-8063-7cbf-fb9861530033\"] {height:0px;}}
        ` }} />
        <span className="af-view">
          <div>
            <Header />
            <div className="af-class-section af-class-overflow-visible af-class-wf-section">
              <div className="af-class-container w-container">
                <div data-current="Tab 1" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-business-catalog__tabs w-tabs">
                  <div id="w-node-b421f2d6-b9b5-9388-e5e3-f3791484e9bd-a23d3631" className="af-class-business-catalog__tabs-menu w-tab-menu">
                    <a data-w-tab="Tab 1" className="af-class-tab-link--business-catalog w-inline-block w-tab-link w--current">
                      <div>Избранное</div>
                    </a>
                    <a data-w-tab="Tab 2" id="w-node-b421f2d6-b9b5-9388-e5e3-f3791484e9c1-a23d3631" className="af-class-tab-link--business-catalog w-inline-block w-tab-link">
                      <div>Кофе</div>
                    </a>
                    <a data-w-tab="Tab 3" id="w-node-b421f2d6-b9b5-9388-e5e3-f3791484e9c4-a23d3631" className="af-class-tab-link--business-catalog w-inline-block w-tab-link">
                      <div>чай</div>
                    </a>
                  </div>
                  <div id="w-node-b421f2d6-b9b5-9388-e5e3-f3791484e9c7-a23d3631" className="af-class-business-catalog__tabs-content w-tab-content">
                    <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                      <div className="w-layout-grid af-class-business-catalog__favorites">
                        <div id="w-node-_139f7437-9e78-209e-364a-d0fe554e4000-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__items-wrapper">
                          <div id="w-node-_8669c93c-979b-7b1d-eb3c-f5509bed84a5-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__block">
                            <div id="w-node-_00165283-9e42-c59b-dcd0-07e70d654cf6-a23d3631" className="af-class-business-catalog__favorites__block-title">Кофе</div>
                            <div id="w-node-f13262fc-c230-acb0-32b3-5480b64136af-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__list">
                              <div id="w-node-_292b2874-5f4e-4d08-1dc9-4936dc344c30-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-_26aedd27-8a93-daf9-0d83-2c65ad5f6231-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                <div id="w-node-_8fede0e1-a04a-205a-3671-1fac9aa11cc2-a23d3631" data-w-id="8fede0e1-a04a-205a-3671-1fac9aa11cc2" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-afe2de6d-633f-924e-c804-b64bf2c8071e-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-afe2de6d-633f-924e-c804-b64bf2c80725-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-f967dc2f-ca3b-ef24-02e8-cbca0bbbd93c-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-_40d4ec96-cebd-4fbb-6529-b94a6d6d1321-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-_914875c1-f1e5-ccb5-2fe0-2e5658375d28-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-_914875c1-f1e5-ccb5-2fe0-2e5658375d29-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                <div id="w-node-_914875c1-f1e5-ccb5-2fe0-2e5658375d2b-a23d3631" data-w-id="914875c1-f1e5-ccb5-2fe0-2e5658375d2b" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-_914875c1-f1e5-ccb5-2fe0-2e5658375d2e-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-_914875c1-f1e5-ccb5-2fe0-2e5658375d35-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-_914875c1-f1e5-ccb5-2fe0-2e5658375d39-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-_914875c1-f1e5-ccb5-2fe0-2e5658375d40-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-ca3c1814-a717-18ec-1591-3c4310e29600-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-ca3c1814-a717-18ec-1591-3c4310e29601-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                <div id="w-node-ca3c1814-a717-18ec-1591-3c4310e29603-a23d3631" data-w-id="ca3c1814-a717-18ec-1591-3c4310e29603" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-ca3c1814-a717-18ec-1591-3c4310e29606-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ca3c1814-a717-18ec-1591-3c4310e2960d-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ca3c1814-a717-18ec-1591-3c4310e29611-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-ca3c1814-a717-18ec-1591-3c4310e29618-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                            </div>
                          </div>
                          <div id="w-node-_607ad5d5-b881-4a41-8fe1-ba4a6b433b57-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__block">
                            <div id="w-node-_607ad5d5-b881-4a41-8fe1-ba4a6b433b58-a23d3631" className="af-class-business-catalog__favorites__block-title">Чай</div>
                            <div id="w-node-_607ad5d5-b881-4a41-8fe1-ba4a6b433b5a-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__list">
                              <div id="w-node-b673c72a-1fd7-05ce-0226-03eee2e95066-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-b673c72a-1fd7-05ce-0226-03eee2e95067-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-b673c72a-1fd7-05ce-0226-03eee2e95069-a23d3631" data-w-id="b673c72a-1fd7-05ce-0226-03eee2e95069" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-b673c72a-1fd7-05ce-0226-03eee2e9506c-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-b673c72a-1fd7-05ce-0226-03eee2e95073-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-b673c72a-1fd7-05ce-0226-03eee2e95077-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-b673c72a-1fd7-05ce-0226-03eee2e9507e-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-_661ddd7c-d00a-9d30-4a4a-ee0997115a8d-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-_661ddd7c-d00a-9d30-4a4a-ee0997115a8e-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-_661ddd7c-d00a-9d30-4a4a-ee0997115a90-a23d3631" data-w-id="661ddd7c-d00a-9d30-4a4a-ee0997115a90" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-_661ddd7c-d00a-9d30-4a4a-ee0997115a93-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-_661ddd7c-d00a-9d30-4a4a-ee0997115a9a-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-_661ddd7c-d00a-9d30-4a4a-ee0997115a9e-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-_661ddd7c-d00a-9d30-4a4a-ee0997115aa5-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-_613ca0b0-c5f7-2df3-aa24-1ca0bc3e5705-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-_613ca0b0-c5f7-2df3-aa24-1ca0bc3e5706-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-_613ca0b0-c5f7-2df3-aa24-1ca0bc3e5708-a23d3631" data-w-id="613ca0b0-c5f7-2df3-aa24-1ca0bc3e5708" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-_613ca0b0-c5f7-2df3-aa24-1ca0bc3e570b-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-_613ca0b0-c5f7-2df3-aa24-1ca0bc3e5712-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-_613ca0b0-c5f7-2df3-aa24-1ca0bc3e5716-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-_613ca0b0-c5f7-2df3-aa24-1ca0bc3e571d-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-ec7bc9e0-33dd-6d64-5a64-65ad67d4bf45-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-ec7bc9e0-33dd-6d64-5a64-65ad67d4bf46-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-ec7bc9e0-33dd-6d64-5a64-65ad67d4bf48-a23d3631" data-w-id="ec7bc9e0-33dd-6d64-5a64-65ad67d4bf48" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-ec7bc9e0-33dd-6d64-5a64-65ad67d4bf4b-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ec7bc9e0-33dd-6d64-5a64-65ad67d4bf52-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ec7bc9e0-33dd-6d64-5a64-65ad67d4bf56-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-ec7bc9e0-33dd-6d64-5a64-65ad67d4bf5d-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-a678984e-45d8-7573-e36f-8ef89dc55df7-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-a678984e-45d8-7573-e36f-8ef89dc55df8-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-a678984e-45d8-7573-e36f-8ef89dc55dfa-a23d3631" data-w-id="a678984e-45d8-7573-e36f-8ef89dc55dfa" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-a678984e-45d8-7573-e36f-8ef89dc55dfd-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-a678984e-45d8-7573-e36f-8ef89dc55e04-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-a678984e-45d8-7573-e36f-8ef89dc55e08-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-a678984e-45d8-7573-e36f-8ef89dc55e0f-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-c0588de1-7da3-1b0d-5a5b-7a70dd644c10-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-c0588de1-7da3-1b0d-5a5b-7a70dd644c11-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-c0588de1-7da3-1b0d-5a5b-7a70dd644c13-a23d3631" data-w-id="c0588de1-7da3-1b0d-5a5b-7a70dd644c13" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-c0588de1-7da3-1b0d-5a5b-7a70dd644c16-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-c0588de1-7da3-1b0d-5a5b-7a70dd644c1d-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-c0588de1-7da3-1b0d-5a5b-7a70dd644c21-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-c0588de1-7da3-1b0d-5a5b-7a70dd644c28-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-_3564195f-6b7a-7bbb-32cd-82ac0c87e63c-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-_3564195f-6b7a-7bbb-32cd-82ac0c87e63d-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-_3564195f-6b7a-7bbb-32cd-82ac0c87e63f-a23d3631" data-w-id="3564195f-6b7a-7bbb-32cd-82ac0c87e63f" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-_3564195f-6b7a-7bbb-32cd-82ac0c87e642-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-_3564195f-6b7a-7bbb-32cd-82ac0c87e649-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-_3564195f-6b7a-7bbb-32cd-82ac0c87e64d-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-_3564195f-6b7a-7bbb-32cd-82ac0c87e654-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-_1a7a4de2-4ff0-e9b5-4e48-a51b1e9591e1-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-_1a7a4de2-4ff0-e9b5-4e48-a51b1e9591e2-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-_1a7a4de2-4ff0-e9b5-4e48-a51b1e9591e4-a23d3631" data-w-id="1a7a4de2-4ff0-e9b5-4e48-a51b1e9591e4" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-_1a7a4de2-4ff0-e9b5-4e48-a51b1e9591e7-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-_1a7a4de2-4ff0-e9b5-4e48-a51b1e9591ee-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-_1a7a4de2-4ff0-e9b5-4e48-a51b1e9591f2-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-_1a7a4de2-4ff0-e9b5-4e48-a51b1e9591f9-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-d97dc9b4-0615-1620-c50e-3d0a4ea9d76b-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-d97dc9b4-0615-1620-c50e-3d0a4ea9d76c-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-d97dc9b4-0615-1620-c50e-3d0a4ea9d76e-a23d3631" data-w-id="d97dc9b4-0615-1620-c50e-3d0a4ea9d76e" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-d97dc9b4-0615-1620-c50e-3d0a4ea9d771-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-d97dc9b4-0615-1620-c50e-3d0a4ea9d778-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-d97dc9b4-0615-1620-c50e-3d0a4ea9d77c-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-d97dc9b4-0615-1620-c50e-3d0a4ea9d783-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                              <div id="w-node-_242f80c3-c8e9-64cf-f0bf-aa0f29ca92a6-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                <div id="w-node-_242f80c3-c8e9-64cf-f0bf-aa0f29ca92a7-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                <div id="w-node-_242f80c3-c8e9-64cf-f0bf-aa0f29ca92a9-a23d3631" data-w-id="242f80c3-c8e9-64cf-f0bf-aa0f29ca92a9" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                <div data-delay={0} data-hover="false" id="w-node-_242f80c3-c8e9-64cf-f0bf-aa0f29ca92ac-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-_242f80c3-c8e9-64cf-f0bf-aa0f29ca92b3-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-_242f80c3-c8e9-64cf-f0bf-aa0f29ca92b7-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                </div>
                                <div id="w-node-_242f80c3-c8e9-64cf-f0bf-aa0f29ca92be-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                              </div>
                            </div>
                          </div>
                          <div id="w-node-_402e16c8-968e-0a71-8b09-2aef508bd3cb-a23d3631" className="af-class-business-catalog__favorites__catalog">
                            <div id="w-node-_91b25889-e4f0-d219-de26-4aa15f9aa83b-a23d3631" className="af-class-business-catalog__favorites__catalog__block">
                              <div id="w-node-a967946b-40e1-4be8-0c6b-e6f3fa7fd856-a23d3631" data-w-id="a967946b-40e1-4be8-0c6b-e6f3fa7fd856" className="af-class-business-catalog__favorites__catalog__head">
                                <div id="w-node-dd0793cc-afd3-d53c-45e5-31eb5d954773-a23d3631" className="af-class-business-catalog__favorites__catalog__title">Чай</div>
                                <div id="w-node-_154e7413-1e48-6b82-8a6c-a8df6e8e0110-a23d3631" className="af-class-business-catalog__favorites__catalog__icon-wrapper"><img style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} loading="lazy" src="images/icon--business-catalog-arrow.svg" alt="" className="af-class-business-catalog__favorites__catalog__icon" /></div>
                              </div>
                              <div style={{height: 0}} className="af-class-business-catalog__favorites__catalog__list">
                                <div className="w-layout-grid af-class-business-catalog__favorites__list af-class-catalog">
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb7a-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb7b-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb7d-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcb7d" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb80-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb87-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb8b-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb92-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb94-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb95-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb97-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcb97" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcb9a-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcba1-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcba5-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbac-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbae-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbaf-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbb1-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcbb1" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbb4-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbbb-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbbf-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbc6-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbc8-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbc9-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbcb-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcbcb" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbce-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbd5-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbd9-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbe0-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbe2-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbe3-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbe5-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcbe5" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbe8-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbef-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbf3-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbfa-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbfc-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbfd-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcbff-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcbff" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc02-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc09-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc0d-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc14-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc16-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc17-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc19-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcc19" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc1c-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc23-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc27-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc2e-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc30-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc31-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc33-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcc33" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc36-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc3d-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc41-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc48-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc4a-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc4b-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc4d-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcc4d" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc50-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc57-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc5b-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc62-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc64-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc65-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc67-a23d3631" data-w-id="b1ba3b93-3862-7bd3-7cac-02d5ea2bcc67" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc6a-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc71-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc75-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-b1ba3b93-3862-7bd3-7cac-02d5ea2bcc7c-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca258c-a23d3631" className="af-class-business-catalog__favorites__catalog__block">
                              <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca258d-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca258d" className="af-class-business-catalog__favorites__catalog__head">
                                <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca258e-a23d3631" className="af-class-business-catalog__favorites__catalog__title">Кофе</div>
                                <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2590-a23d3631" className="af-class-business-catalog__favorites__catalog__icon-wrapper"><img style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} loading="lazy" src="images/icon--business-catalog-arrow.svg" alt="" className="af-class-business-catalog__favorites__catalog__icon" /></div>
                              </div>
                              <div style={{height: 0}} className="af-class-business-catalog__favorites__catalog__list">
                                <div className="w-layout-grid af-class-business-catalog__favorites__list af-class-catalog">
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2594-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2595-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2597-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca2597" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca259a-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25a1-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25a5-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25ac-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25ae-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25af-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25b1-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca25b1" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25b4-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25bb-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25bf-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25c6-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25c8-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25c9-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25cb-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca25cb" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25ce-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25d5-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25d9-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25e0-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25e2-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25e3-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25e5-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca25e5" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25e8-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25ef-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25f3-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25fa-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25fc-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25fd-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca25ff-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca25ff" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2602-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2609-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca260d-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2614-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2616-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2617-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2619-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca2619" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca261c-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2623-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2627-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca262e-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2630-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2631-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2633-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca2633" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2636-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca263d-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2641-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2648-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca264a-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca264b-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca264d-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca264d" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2650-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2657-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca265b-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2662-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2664-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2665-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2667-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca2667" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca266a-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2671-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2675-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca267c-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                  <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca267e-a23d3631" className="w-layout-grid af-class-business-catalog__favorites__item">
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca267f-a23d3631" className="af-class-business-catalog__favorites__item__title">traditional black, assam</div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2681-a23d3631" data-w-id="6c71a690-1de0-add7-4644-5701f8ca2681" className="af-class-review-mark-wrapper af-class-_1 af-class-catalog-business"><img loading="lazy" src="images/icon__review-star.svg" alt="" className="af-class-review-star--unchecked" /><img style={{display: 'none'}} loading="lazy" src="images/review-star--checked.svg" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                                    <div data-delay={0} data-hover="false" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2684-a23d3631" className="af-class-button-options-card-p_catalog w-dropdown">
                                      <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                        <div className="af-class-text-button-card-p_catalog">250 г</div><img width={7} loading="lazy" src="images/arrow-down.svg" alt="" className="af-class-icon-button-card-p_catalog" />
                                      </div>
                                      <nav className="w-dropdown-list">
                                        <div className="af-class-dropdown-list">
                                          <a href="#" id="w-node-_6c71a690-1de0-add7-4644-5701f8ca268b-a23d3631" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                          <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca268f-a23d3631" className="af-class-button-options-card-p_catalog af-class-quantity">
                                      <div className="af-class-cart__summary-item-quantity-button--minus af-class-_2">–</div>
                                      <div className="af-class-text-button-option_add-card">1</div>
                                      <div className="af-class-plus-buttonoption-card af-class-_2">+</div>
                                    </div>
                                    <div id="w-node-_6c71a690-1de0-add7-4644-5701f8ca2696-a23d3631" className="af-class-business-catalog__favorites__item__price">2150 ₽</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="w-node-_4ba4af2f-2df6-3bd9-ac97-238af03429a3-a23d3631" className="af-class-business-catalog__cart">
                          <div id="w-node-adad78c4-a12b-df06-3a64-138f96540d88-a23d3631" className="af-class-business-catalog__cart__block">
                            <div className="w-layout-grid af-class-business-catalog__cart__head">
                              <div id="w-node-_0f71e729-3b12-7a61-8c9c-8d2a27b48953-a23d3631" className="af-class-business-catalog__cart__title">Ваш заказ</div>
                              <a id="w-node-_64ae50e5-e520-b310-55f3-2f6081f14354-a23d3631" href="#" className="af-class-button--secondry w-inline-block">
                                <div id="w-node-ae136819-27e3-d083-e9a0-4494da9b1644-a23d3631" className="af-class-cart__button--secondry__text">Очистить корзину</div>
                                <div id="w-node-ae136819-27e3-d083-e9a0-4494da9b1646-a23d3631" className="af-class-cart__button--secondry__underline" />
                              </a>
                              <div id="w-node-_73697d2b-4bee-fef9-aeba-f4a72bd6597c-a23d3631" data-w-id="73697d2b-4bee-fef9-aeba-f4a72bd6597c" className="af-class-business-catalog__cart__icon-wrapper"><img loading="lazy" src="images/order-history__item__plus-icon.svg" alt="" className="af-class-business-catalog__cart__icon" /></div>
                            </div>
                            <div className="af-class-business-catalog__cart__divider" />
                            <div id="w-node-_1bf97789-6d43-8063-7cbf-fb9861530033-a23d3631" data-w-id="1bf97789-6d43-8063-7cbf-fb9861530033" className="af-class-business-catalog__cart__items-wrapper">
                              <div className="af-class-business-catalog__cart__list">
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-e139715c-d7d8-41b6-15c2-953e0d36d5c6-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                  <div id="w-node-_4215089a-e044-8bf0-625e-fd9f331e7206-a23d3631" className="af-class-business-catalog__favorites__item__title">250Г</div>
                                  <div id="w-node-_48e8837d-9c13-4cbc-fda7-5a0800fc265b-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-d364dcd8-36d3-bc88-b588-fe8338dc11e7-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-b8570818-e106-500d-fe1b-64ccc9086c62-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                  <div id="w-node-b8570818-e106-500d-fe1b-64ccc9086c64-a23d3631" className="af-class-business-catalog__favorites__item__title">250Г</div>
                                  <div id="w-node-b8570818-e106-500d-fe1b-64ccc9086c66-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-b8570818-e106-500d-fe1b-64ccc9086c68-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-_64818fba-af9b-e478-07de-7b8392e6b922-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                  <div id="w-node-_64818fba-af9b-e478-07de-7b8392e6b924-a23d3631" className="af-class-business-catalog__favorites__item__title">250Г</div>
                                  <div id="w-node-_64818fba-af9b-e478-07de-7b8392e6b926-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-_64818fba-af9b-e478-07de-7b8392e6b928-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-db808019-aa26-2767-e315-be879170d442-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                  <div id="w-node-db808019-aa26-2767-e315-be879170d444-a23d3631" className="af-class-business-catalog__favorites__item__title">250Г</div>
                                  <div id="w-node-db808019-aa26-2767-e315-be879170d446-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-db808019-aa26-2767-e315-be879170d448-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-a9e46f08-26e1-e4c4-c8ed-6da4fc1c0267-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                  <div id="w-node-a9e46f08-26e1-e4c4-c8ed-6da4fc1c0269-a23d3631" className="af-class-business-catalog__favorites__item__title">250Г</div>
                                  <div id="w-node-a9e46f08-26e1-e4c4-c8ed-6da4fc1c026b-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-a9e46f08-26e1-e4c4-c8ed-6da4fc1c026d-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-a26f1258-7e5c-b469-c8c0-affc85278b87-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                  <div id="w-node-a26f1258-7e5c-b469-c8c0-affc85278b89-a23d3631" className="af-class-business-catalog__favorites__item__title">250Г</div>
                                  <div id="w-node-a26f1258-7e5c-b469-c8c0-affc85278b8b-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-a26f1258-7e5c-b469-c8c0-affc85278b8d-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-_5a73d98a-cb03-eda1-3b17-0c9725d54509-a23d3631" className="af-class-business-catalog__favorites__item__title">Brazil/Uganda</div>
                                  <div id="w-node-_5a73d98a-cb03-eda1-3b17-0c9725d5450b-a23d3631" className="af-class-business-catalog__favorites__item__title">250Г</div>
                                  <div id="w-node-_5a73d98a-cb03-eda1-3b17-0c9725d5450d-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-_5a73d98a-cb03-eda1-3b17-0c9725d5450f-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                                <div className="w-layout-grid af-class-business-catalog__cart__item">
                                  <div id="w-node-_42a9c213-2208-bd42-a952-9bc797f66a01-a23d3631" className="af-class-business-catalog__favorites__item__title">ethiopia anasora anaerobic</div>
                                  <div id="w-node-_42a9c213-2208-bd42-a952-9bc797f66a03-a23d3631" className="af-class-business-catalog__favorites__item__title">2кг</div>
                                  <div id="w-node-_42a9c213-2208-bd42-a952-9bc797f66a05-a23d3631" className="af-class-business-catalog__favorites__item__title">2150 ₽</div><img id="w-node-_42a9c213-2208-bd42-a952-9bc797f66a07-a23d3631" loading="lazy" src="images/icon--delete-order.svg" alt="" className="af-class-business-catalog__cart__item__close-icon" />
                                </div>
                              </div>
                            </div>
                            <div id="w-node-_8e651e69-7671-f79b-2a3c-e21360ae2e01-a23d3631" className="w-layout-grid af-class-business-catalog__cart__summary">
                              <div id="w-node-_9232d6da-3231-75aa-936a-758ee53e565d-a23d3631" className="w-layout-grid af-class-business-catalog__cart__discount">
                                <div id="w-node-_827587c8-8eac-71f6-c696-60f26f979305-a23d3631" className="af-class-business-catalog__cart__text--1">Скидка</div>
                                <div id="w-node-d1fc86ca-4609-3a7a-388b-4945b4f45ac3-a23d3631" className="af-class-business-catalog__cart__text--1">30%</div>
                              </div>
                              <div id="w-node-_90658ca4-add7-8957-213b-30af633e9685-a23d3631" className="w-layout-grid af-class-business-catalog__cart__discount">
                                <div id="w-node-_40209d2a-a3ff-4f6f-a4b5-3468e0926d65-a23d3631" className="af-class-business-catalog__cart__text--2">Общая сумма</div>
                                <div id="w-node-c37368b2-515e-52c7-38f3-70ec40bf5876-a23d3631" className="af-class-business-catalog__cart__price">19 353 ₽</div>
                              </div>
                              <a id="w-node-_1dcb1178-bde0-4778-f8c2-f3df3278eb02-a23d3631" href="#" className="af-class-button--primary w-button">Оформить заказ</a>
                            </div>
                            <div className="w-layout-grid af-class-business-catalog__cart__block--2 af-class-_2">
                              <div id="w-node-cb6e5f98-6d75-4343-18b5-d29612caa8cb-a23d3631" className="w-layout-grid af-class-business-catalog__cart__discount-system">
                                <div id="w-node-_1bd4656e-1e71-80cf-7d5e-21120af5ddb1-a23d3631" className="af-class-business-catalog__cart__text--3">Система скидок</div>
                                <div className="af-class-question_btn af-class-control-p_catalog">
                                  <div className="af-class-availability__tooltip_block">
                                    <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                  </div>
                                </div>
                              </div>
                              <a id="w-node-_171521a1-37c9-9947-7031-563f2b2a836c-a23d3631" href="#" className="af-class-button--secondry w-inline-block">
                                <div id="w-node-_171521a1-37c9-9947-7031-563f2b2a836d-a23d3631" className="af-class-cart__button--secondry__text af-class-grey">Скачать прайс</div>
                                <div id="w-node-_171521a1-37c9-9947-7031-563f2b2a836f-a23d3631" className="af-class-cart__button--secondry__underline af-class-grey" />
                              </a>
                            </div>
                          </div>
                          <div className="af-class-business-catalog__cart__block af-class-empty">
                            <div className="w-layout-grid af-class-business-catalog__cart__head af-class-empty">
                              <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a3f6-a23d3631" className="af-class-business-catalog__cart__title">Ваш заказ</div>
                            </div>
                            <div className="af-class-text-block-74">Ваша корзина пуста</div>
                            <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a441-a23d3631" className="w-layout-grid af-class-business-catalog__cart__summary">
                              <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a442-a23d3631" className="w-layout-grid af-class-business-catalog__cart__discount">
                                <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a443-a23d3631" className="af-class-business-catalog__cart__text--1">Скидка</div>
                                <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a445-a23d3631" className="af-class-business-catalog__cart__text--1">0%</div>
                              </div>
                              <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a447-a23d3631" className="w-layout-grid af-class-business-catalog__cart__discount">
                                <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a448-a23d3631" className="af-class-business-catalog__cart__text--2">Общая сумма</div>
                                <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a44a-a23d3631" className="af-class-business-catalog__cart__price">0 ₽</div>
                              </div>
                              <a id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a44c-a23d3631" href="#" className="af-class-button--primary w-button">Оформить заказ</a>
                            </div>
                            <div className="w-layout-grid af-class-business-catalog__cart__block--2 af-class-_2">
                              <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a44f-a23d3631" className="w-layout-grid af-class-business-catalog__cart__discount-system">
                                <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a450-a23d3631" className="af-class-business-catalog__cart__text--3">Система скидок</div>
                                <div className="af-class-question_btn af-class-control-p_catalog">
                                  <div className="af-class-availability__tooltip_block">
                                    <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                  </div>
                                </div>
                              </div>
                              <a id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a456-a23d3631" href="#" className="af-class-button--secondry w-inline-block">
                                <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a457-a23d3631" className="af-class-cart__button--secondry__text af-class-grey">Скачать прайс</div>
                                <div id="w-node-c60dcbf8-8870-d502-1f50-d7c80965a459-a23d3631" className="af-class-cart__button--secondry__underline af-class-grey" />
                              </a>
                            </div>
                          </div>
                          <div className="w-layout-grid af-class-business-catalog__cart__block--2">
                            <div id="w-node-_112a50f0-8323-7fbe-e1e6-097e45610934-a23d3631" className="w-layout-grid af-class-business-catalog__cart__discount-system">
                              <div id="w-node-_112a50f0-8323-7fbe-e1e6-097e45610935-a23d3631" className="af-class-business-catalog__cart__text--3">Система скидок</div>
                              <div className="af-class-question_btn af-class-control-p_catalog">
                                <div className="af-class-availability__tooltip_block af-class-business-catalog">
                                  <div className="af-class-text-block-75">Система скидок</div>
                                  <div className="af-class-div-block-45">
                                    <div className="w-layout-grid af-class-grid-83">
                                      <div id="w-node-e6ebdf4f-a911-03a4-0d2c-63cc79e99c48-a23d3631" className="af-class-text-block-76">Сумма заказа</div>
                                      <div id="w-node-d4f24125-f5de-6136-426a-f0384aa60bcf-a23d3631" className="af-class-text-block-76">Кофе</div>
                                      <div id="w-node-_199ea0b5-0d37-a619-0813-7cbd14c62954-a23d3631" className="af-class-text-block-76">Кофе + оборудование</div>
                                    </div>
                                    <div className="w-layout-grid af-class-grid-83 af-class-_2">
                                      <div id="w-node-f9daf74b-7ae6-7374-edd1-eaec3c1d4ecb-a23d3631" className="af-class-text-block-77">от 10 000 руб</div>
                                      <div id="w-node-_6e0dbef4-1010-56ce-2c1c-ced8b1b42f0c-a23d3631" className="af-class-text-block-77">25%</div>
                                      <div id="w-node-_0f45f0fe-a562-129b-e319-87482f0ee4f9-a23d3631" className="af-class-text-block-77">25% + 550 руб</div>
                                    </div>
                                    <div className="w-layout-grid af-class-grid-83 af-class-_2">
                                      <div id="w-node-_94681b7f-ed46-00ef-bd8e-325404fbe671-a23d3631" className="af-class-text-block-77">от 15 000 руб</div>
                                      <div id="w-node-_94681b7f-ed46-00ef-bd8e-325404fbe673-a23d3631" className="af-class-text-block-77">30%</div>
                                      <div id="w-node-_94681b7f-ed46-00ef-bd8e-325404fbe675-a23d3631" className="af-class-text-block-77">30% + 450 руб</div>
                                    </div>
                                    <div className="w-layout-grid af-class-grid-83 af-class-_2">
                                      <div id="w-node-_38c64947-916d-f1cf-d7d1-5557255144fe-a23d3631" className="af-class-text-block-77">от 30 000 руб</div>
                                      <div id="w-node-_38c64947-916d-f1cf-d7d1-555725514500-a23d3631" className="af-class-text-block-77">35%</div>
                                      <div id="w-node-_38c64947-916d-f1cf-d7d1-555725514502-a23d3631" className="af-class-text-block-77">35% + 350 руб</div>
                                    </div>
                                    <div className="w-layout-grid af-class-grid-83 af-class-_2">
                                      <div id="w-node-_021879fb-216d-6d58-8a66-82c0bfe6e75e-a23d3631" className="af-class-text-block-77">от 50 000 руб</div>
                                      <div id="w-node-_021879fb-216d-6d58-8a66-82c0bfe6e760-a23d3631" className="af-class-text-block-77">40%</div>
                                      <div id="w-node-_021879fb-216d-6d58-8a66-82c0bfe6e762-a23d3631" className="af-class-text-block-77">40% + 250 руб</div>
                                    </div>
                                  </div>
                                  <div className="af-class-div-block-45">
                                    <div className="w-layout-grid af-class-grid-83">
                                      <div id="w-node-e4062e15-160f-4fb5-84a6-865d76f0ba4c-a23d3631" className="af-class-text-block-76">Сумма заказа</div>
                                      <div id="w-node-e4062e15-160f-4fb5-84a6-865d76f0ba4e-a23d3631" className="af-class-text-block-76">Чай</div>
                                    </div>
                                    <div className="w-layout-grid af-class-grid-83 af-class-_2">
                                      <div id="w-node-e4062e15-160f-4fb5-84a6-865d76f0ba53-a23d3631" className="af-class-text-block-77">от 10 000 руб</div>
                                      <div id="w-node-e4062e15-160f-4fb5-84a6-865d76f0ba55-a23d3631" className="af-class-text-block-77">20%</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <a id="w-node-_112a50f0-8323-7fbe-e1e6-097e4561093b-a23d3631" href="#" className="af-class-button--secondry w-inline-block">
                              <div id="w-node-_112a50f0-8323-7fbe-e1e6-097e4561093c-a23d3631" className="af-class-cart__button--secondry__text af-class-grey">Скачать прайс</div>
                              <div id="w-node-_112a50f0-8323-7fbe-e1e6-097e4561093e-a23d3631" className="af-class-cart__button--secondry__underline af-class-grey" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-w-tab="Tab 2" className="w-tab-pane" />
                    <div data-w-tab="Tab 3" className="w-tab-pane" />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default CatalogBusinessView

/* eslint-enable */