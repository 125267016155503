import React, { useState, useEffect, useContext, useRef } from 'react'
import CartFieldText from '../CartFieldText'
import { AddressSuggestions } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import { CartFormContext } from "../../../contexts/CartFormContext";
import { fetchAddressSuggestions } from '../../../fetch/fetchWPItems'

const CartFormAddressForm = () => {
  const cartFormContext = useContext(CartFormContext)

  const suggestionsRef = useRef(null)

  const handleFullAddress = (suggestion) => {
    if (suggestion?.data) {
      cartFormContext.setAddressData({
        ...cartFormContext.addressData,
        full: suggestion.value,
        city: suggestion.data.city,
        country: suggestion.data.country,
        cityPostalCode: suggestion.data.postal_code,
        cityFiasId: suggestion.data.city_fias_id,
        cityKladrId: suggestion.data.city_kladr_id,
      });
    }
  }

  // This is needed to derive the city from the given address; when the address changes (e.g. selected from the addresses popup with a different city), the city is also updated
  useEffect(() => {
    suggestionsRef?.current?.setInputValue(cartFormContext.addressData.full)
    suggestionsRef?.current?.setState({query: cartFormContext.addressData.full})
    fetchAddressSuggestions(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`, {query: cartFormContext.addressData.full, count: 10})
      .then(result => {
        suggestionsRef?.current?.setState({ suggestions: result.suggestions })
        if (cartFormContext.addressData.cityPostalCode != result?.suggestions[0]?.data?.postal_code) {
          handleFullAddress(result.suggestions[0]);
        }
      })
  }, [cartFormContext.addressData.full]);

  const [fullAddressFilled, setFullAddressFilled] = useState(false);
  const [fullAddressValid, setFullAddressValid] = useState(true);

  const handleFullAddressFill = (value) => {
    if (value.length > 0) {
      setFullAddressFilled(true);
    } else {
      setFullAddressFilled(false);
    }
  }

  const handleFullAddressValidate = (value) => {
    if (value.length > 0) {
      setFullAddressValid(true);
      cartFormContext.setAddressError(null);
    } else {
      setFullAddressValid(false);
    }
  }

  return <>
    <div className="af-class-cart__user-field-wrapper">
      <div className={`
        af-class-cart__user-field-label
        ${(fullAddressFilled === true || cartFormContext.addressData.full.length > 0) && ' af-class-cart__user-field-label-active'}
      `}>
        Улица, Дом, Корпус/Строение
      </div>
      <AddressSuggestions
        ref={suggestionsRef}
        filterToBound='house'
        filterLocations={{fias_id: cartFormContext.city.cityFias}}
        token={process.env.REACT_APP_DADATA_API_KEY}
        value={{value: cartFormContext.addressData.full}}
        onChange={(suggestion) => handleFullAddress(suggestion)}
        inputProps={{
          name: 'full_address',
          placeholder: 'Улица, Дом, Корпус/Строение',
          className: `af-class-cart__user-field w-input ${fullAddressValid === false ? 'user-input-invalid' : ''} ${cartFormContext.addressError ? 'user-input-invalid' : ''}`,
          onKeyUp: (e) => {
            handleFullAddressFill(e.target.value);
            handleFullAddressValidate(e.target.value);
          },
          // onBlur: (e) => handleFullAddressValidate(e.target.value),
        }} />
    </div>
    <div className="w-layout-grid af-class-cart__user-adress-group">
      <CartFieldText
        value={cartFormContext.addressData.entrance}
        handleChange={(e) => cartFormContext.setAddressData({ ...cartFormContext.addressData, [e.target.name]: e.target.value })}
        name="entrance"
        placeholder="Подъезд"
      />
      <CartFieldText
        value={cartFormContext.addressData.floor}
        handleChange={(e) => cartFormContext.setAddressData({ ...cartFormContext.addressData, [e.target.name]: e.target.value })}
        name="floor"
        placeholder="Этаж"
      />
      <CartFieldText
        value={cartFormContext.addressData.flat}
        handleChange={(e) => cartFormContext.setAddressData({ ...cartFormContext.addressData, [e.target.name]: e.target.value })}
        name="flat"
        placeholder="Квартира"
      />
      <CartFieldText
        value={cartFormContext.addressData.homephone}
        handleChange={(e) => cartFormContext.setAddressData({ ...cartFormContext.addressData, [e.target.name]: e.target.value })}
        name="homephone"
        placeholder="Домофон"
      />
    </div>
  </>
}

export default CartFormAddressForm