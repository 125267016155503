import { useState } from 'react';
import CatalogPage from '../components/Catalog/CatalogPage';

export default function CatalogViewDripsNew() {
  const [categoryFiltersQuick, setCategoryFiltersQuick] = useState([
    {
      id: 312,
      option: 'Новинки',
    },
    {
      id: 463,
      option: 'sale',
      text: 'Со скидкой',
    },
  ]);

  const categoryFilters = [];

  const optionsAliases = [];

  const productsFilter = (products) => {
    return products.filter(elem => (elem.categories.find(elem => elem.id == 416)));
  }

  return <CatalogPage
    categoryId={17}
    categoryFiltersQuick={categoryFiltersQuick}
    setCategoryFiltersQuick={setCategoryFiltersQuick}
    categoryFilters={categoryFilters}
    optionsAliases={optionsAliases}
    filterByAttributes={[1, 2, 3, 20, 21, 26]}
    productsFilter={productsFilter}
  />
}