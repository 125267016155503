import { useContext } from "react";
import { CartFormContext } from "../../../contexts/CartFormContext";
import CartFormFieldText from "./CartFormFieldText";

const CartFormGroupFieldPaymentTypes = () => {
  const cartFormContext = useContext(CartFormContext);

  return (
    <>
      <div className="w-layout-grid af-class-cart__user-fields"> {/* af-class-cart__user-fields-condensed */}
        <label
          className="af-class-cart__user-radio-button w-radio"
          onClick={() => cartFormContext.setPaymentType("card")}
        >
          <div
            className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input${
              cartFormContext.paymentType == "card" ? " w--redirected-checked" : ""
            }`}
          />
          <input
            type="radio"
            name="Payment-method"
            id="Credit-card"
            defaultValue="Credit card"
            data-name="Payment method"
            style={{ opacity: 0, position: "absolute", zIndex: -1 }}
          />
          <span
            className="af-class-cart__user-radio-button-text w-form-label"
            htmlFor="Credit-card"
          >
            Картой
          </span>
          <div className="af-class-cart__user-radio-button-images">
            <img src="../images/card-visa.svg" alt="" />
            <img src="../images/card-master.svg" alt="" />
            <img src="../images/card-mir.svg" alt="" />
          </div>
        </label>
        {/* <label
          className="af-class-cart__user-radio-button w-radio"
          onClick={() => cartFormContext.setPaymentType("sbp")}
        >
          <div
            className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input${
              cartFormContext.paymentType == "sbp" ? " w--redirected-checked" : ""
            }`}
          />
          <input
            type="radio"
            style={{ opacity: 0, position: "absolute", zIndex: -1 }}
          />
          <span
            className="af-class-cart__user-radio-button-text w-form-label"
          >
            СБП
          </span>
          <div className="af-class-cart__user-radio-button-images">
            <img src="../images/card-sbp.svg" alt="" />
          </div>
        </label>
        <label
          className="af-class-cart__user-radio-button w-radio"
          onClick={() => cartFormContext.setPaymentType("yandex")}
        >
          <div
            className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input${
              cartFormContext.paymentType == "yandex" ? " w--redirected-checked" : ""
            }`}
          />
          <input
            type="radio"
            style={{ opacity: 0, position: "absolute", zIndex: -1 }}
          />
          <span
            className="af-class-cart__user-radio-button-text w-form-label"
          >
            Yandex Pay
          </span>
          <div className="af-class-cart__user-radio-button-images">
            <img src="../images/card-yandex.svg" alt="" />
          </div>
        </label> */}

        {/* { (formDeliveryCompany == 'СДЭК' && formDeliveryCountry == 'Россия') && <label className="af-class-cart__user-radio-button w-radio" onClick={() => cartFormContext.setPaymentType('cash')}>
              <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input${cartFormContext.paymentType == 'cash' ? ' w--redirected-checked' : ''}`} />
              <input type="radio" name="Payment-method" id="Payment-upon-receipt-2" defaultValue="Payment upon receipt" data-name="Payment method" style={{opacity: 0, position: 'absolute', zIndex: -1}} />
              <span className="af-class-cart__user-radio-button-text w-form-label" htmlFor="Payment-upon-receipt-2">Оплата при получении</span>
          </label> } */}

        {/* <label className="af-class-cart__user-radio-button w-radio">
              <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input" />
              <input type="radio" name="Payment-method" id="Apple-Pay" defaultValue="Apple Pay" data-name="Payment method" style={{opacity: 0, position: 'absolute', zIndex: -1}} />
              <span className="af-class-cart__user-radio-button-text w-form-label" htmlFor="Apple-Pay">Оплата через Apple Pay</span>
          </label>
          <label className="af-class-cart__user-radio-button w-radio">
              <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input" />
              <input type="radio" name="Payment-method" id="Samsung-Pay" defaultValue="Samsung Pay" data-name="Payment method" style={{opacity: 0, position: 'absolute', zIndex: -1}} />
              <span className="af-class-cart__user-radio-button-text w-form-label" htmlFor="Samsung-Pay">Оплата через Samsung Pay</span>
          </label> */}
      </div>
      <div className="w-layout-grid af-class-cart__user-fields">
        <span style={{ fontWeight: 400, fontSize: '12px', color: '#999999', marginBottom: '-11px' }}>Для юридических лиц</span>
        <label
          className="af-class-cart__user-radio-button w-radio"
          onClick={() => cartFormContext.setPaymentType("wire")}
        >
          <div
            className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input${
              cartFormContext.paymentType == "wire" ? " w--redirected-checked" : ""
            }`}
          />
          <input
            type="radio"
            style={{ opacity: 0, position: "absolute", zIndex: -1 }}
          />
          <span
            className="af-class-cart__user-radio-button-text w-form-label"
          >
            Банковский перевод
          </span>
        </label>

        { cartFormContext.paymentType == "wire" && <>
          <CartFormFieldText
            name="payment_wire_org"
            value={cartFormContext.paymentWireOrg}
            placeholder="Наименование организации"
            onChange={(e) => cartFormContext.setPaymentWireOrg(e.target.value)}
            validate={(value) => value?.length > 0}
            valid={cartFormContext.paymentWireOrgValid}
            setValid={(value) => cartFormContext.setPaymentWireOrgValid(value)}
            error={cartFormContext.paymentWireOrgError}
            setError={(value) => cartFormContext.setPaymentWireOrgError(value)}
          />
          <CartFormFieldText
            name="payment_wire_inn"
            value={cartFormContext.paymentWireInn}
            placeholder="ИНН"
            onChange={(e) => cartFormContext.setPaymentWireInn(e.target.value)}
            validate={(value) => value?.length > 0}
            valid={cartFormContext.paymentWireInnValid}
            setValid={(value) => cartFormContext.setPaymentWireInnValid(value)}
            error={cartFormContext.paymentWireInnError}
            setError={(value) => cartFormContext.setPaymentWireInnError(value)}
          />
          <CartFormFieldText
            name="payment_wire_kpp"
            value={cartFormContext.paymentWireKpp}
            placeholder="КПП"
            onChange={(e) => cartFormContext.setPaymentWireKpp(e.target.value)}
            validate={(value) => value?.length > 0}
            valid={cartFormContext.paymentWireKppValid}
            setValid={(value) => cartFormContext.setPaymentWireKppValid(value)}
            error={cartFormContext.paymentWireKppError}
            setError={(value) => cartFormContext.setPaymentWireKppError(value)}
          />
          <CartFormFieldText
            name="payment_wire_acc"
            value={cartFormContext.paymentWireAcc}
            placeholder="Расчётный счёт"
            onChange={(e) => cartFormContext.setPaymentWireAcc(e.target.value)}
            validate={(value) => value?.length > 0}
            valid={cartFormContext.paymentWireAccValid}
            setValid={(value) => cartFormContext.setPaymentWireAccValid(value)}
            error={cartFormContext.paymentWireAccError}
            setError={(value) => cartFormContext.setPaymentWireAccError(value)}
          />
          <CartFormFieldText
            name="payment_wire_corr_acc"
            value={cartFormContext.paymentWireCorrAcc}
            placeholder="Корр. счёт"
            onChange={(e) => cartFormContext.setPaymentWireCorrAcc(e.target.value)}
            validate={(value) => value?.length > 0}
            valid={cartFormContext.paymentWireCorrAccValid}
            setValid={(value) => cartFormContext.setPaymentWireCorrAccValid(value)}
            error={cartFormContext.paymentWireCorrAccError}
            setError={(value) => cartFormContext.setPaymentWireCorrAccError(value)}
          />
          <CartFormFieldText
            name="payment_wire_bik"
            value={cartFormContext.paymentWireBik}
            placeholder="БИК"
            onChange={(e) => cartFormContext.setPaymentWireBik(e.target.value)}
            validate={(value) => value?.length > 0}
            valid={cartFormContext.paymentWireBikValid}
            setValid={(value) => cartFormContext.setPaymentWireBikValid(value)}
            error={cartFormContext.paymentWireBikError}
            setError={(value) => cartFormContext.setPaymentWireBikError(value)}
          />
        </>}
      </div>
    </>
  );
};

export default CartFormGroupFieldPaymentTypes;
