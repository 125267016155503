import { useState, useEffect, useContext } from "react";
import useToken from "../../../hooks/useToken.js";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import {
  wooAddToCart,
  wooCartApplyCoupon,
  wooCartRemoveCoupon,
  wooClearCart,
} from "../../../fetch/WPAPI";

const CartFormFieldPromocode = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);
  const { token, setToken, nonce, setNonce } = useToken();

  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);

  useEffect(() => {
    setFailed(false);
    setFailedMessage(null);
    
    cartFormContext.setPromoCodeApplied(false);
    cartFormContext.setPromoCodeDiscount(0);
  }, [mainContext.cartItems]);

  const wooAddToCartQueued = () => {
    return new Promise((resolve, reject) => {
      const tasks = [];
      mainContext.cartItems.forEach((elem) => {
        tasks.push({ id: elem.variationId, quantity: elem.cartQty });
      });

      const results = [];
      let currentTask = 0;
      let busy = false;
      let interval = setInterval(() => {
        if (busy === false) {
          busy = true;
          wooAddToCart(tasks[currentTask], token.token, nonce)
            .then((result) => result.json())
            .then((result) => {
              results.push(result);
              currentTask++;
              busy = false;
              if (currentTask >= tasks.length) {
                clearInterval(interval);
                resolve(results);
              }
            });
        }
      }, 100);
    });
  };

  const applyPromocode = () => {
    if (cartFormContext.promoCode.length > 0) {
      setFailed(false);
      setFailedMessage(null);

      if (token?.token && nonce) {
        wooClearCart(token?.token, nonce).then((result) => {
          wooAddToCartQueued().then((results) => {
            wooCartRemoveCoupon(
              cartFormContext.promoCodeApplied?.code,
              token?.token,
              nonce
            ).then((result) => {
              wooCartApplyCoupon(
                cartFormContext.promoCode,
                token?.token,
                nonce
              ).then(
                (result) => {
                  if (result?.totals?.total_discount) {
                    cartFormContext.setPromoCodeDiscount(result?.totals?.total_discount);
                    cartFormContext.setPromoCodeApplied(result?.coupons[0]);
                  } else {
                    cartFormContext.setPromoCodeDiscount(0);
                    cartFormContext.setPromoCodeApplied(false);
                    setFailed(true);
                    setFailedMessage(result?.message);
                  }
                }
              );
            });
          });
        });
      }
    }
  };

  return (
    <>
      {token?.token && nonce && (
        <div>
          <div
            className={`
              af-class-cart__user-promocode-wrapper
              ${failed !== false ? " user-input-invalid" : ""}
              ${cartFormContext.promoCodeApplied !== false ? " user-input-valid" : ""}
            `}
          >
            <div className={`
              af-class-cart__user-field-label
              ${(cartFormContext.promoCode && cartFormContext.promoCode.length) && " af-class-cart__user-field-label-active"}
            `}>Промокод</div>
            <input
              value={cartFormContext.promoCode ? cartFormContext.promoCode : ''}
              onChange={(e) => cartFormContext.setPromoCode(e.target.value)}
              type="text"
              className={`
                af-class-cart__user-promocode-field w-input
                ${failed !== false ? " user-input-invalid" : ""}
                ${cartFormContext.promoCodeApplied !== false ? " user-input-valid" : ""}
              `}
              maxLength={256}
              name="Promocode"
              data-name="Promocode"
              placeholder="Промокод"
              id="field"
              required
            />
            <a
              onClick={applyPromocode}
              className="af-class-cart__user-promocode-button w-button"
            />
          </div>
          {failedMessage && (
            <div style={{ color: "red", fontSize: "13px" }}>
              {failedMessage}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CartFormFieldPromocode