import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import css from './Product.module.css'
import useProduct from '../../hooks/useProduct'
import Identificator from './Identificator'
import { Link } from 'react-router-dom'

const Product = (props) => {
  const navigate = useNavigate()
  
  const productHandle = useProduct(props)

  const isNew = (props.categories?.filter(c => c.name === 'Новинки')[0] !== undefined) || false

  const ExtraContentCart = () => {
    // 107 - Кофе в капсулах, 416 - Кофе в дрипах
    if (props.categories?.find(elem => elem.id == 17) && props.categories?.filter(value => [107, 416].includes(value.id)).length == 0) {
      return <>
        <div className="af-class-specifications-card-p_catalog">
          <div className="af-class-specifications__block">
            <div className="af-class-product-card__specifications__lable">Горечь</div>
            <div className="af-class-specifications__indicators">
              <Identificator count={Number(props.attributes.find(a => a.id === 9)?.options[0]) || 5} />
            </div>
          </div>
          <div className="af-class-specifications__block">
            <div className="af-class-product-card__specifications__lable">Сладость</div>
            <div className="af-class-specifications__indicators">
              <Identificator count={Number(props.attributes.find(a => a.id === 10)?.options[0]) || 5} />
            </div>
          </div>
          <div className="af-class-specifications__block">
            <div className="af-class-product-card__specifications__lable">Кислотность</div>
            <div className="af-class-specifications__indicators">
              <Identificator count={Number(props.attributes.find(a => a.id === 11)?.options[0]) || 5} />
            </div>
          </div>
        </div>

        { !props.categories?.find(elem => elem.id == 404) ? <div className="af-class-options-wrapper-card-p_catalog">
          {(productHandle.weightsVariations && productHandle.weightsVariations.length > 0) && <>
            <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
              <div className="af-class-dropdown-toggle w-dropdown-toggle" onClick={() => productHandle.setWeightDropdown(!productHandle.weightDropdown)}>
                <div className="af-class-text-button-card-p_catalog">{productHandle.selectedWeight}</div>
                <img src="/images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
              </div>
              <nav className="w-dropdown-list" style={{ display: productHandle.weightDropdown === false ? 'none' : 'block' }}>
                <div className="af-class-dropdown-list">
                  { productHandle.weightsVariations.map((value, index) => {
                    return <a key={index} className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => productHandle.handleChangeWeight(value)}>{value}</a>
                  }) }
                </div>
              </nav>
            </div>
          </>}

          {(productHandle.grindsVariations && productHandle.grindsVariations.length > 0) && <>
            <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
              <div className="af-class-dropdown-toggle w-dropdown-toggle" onClick={() => productHandle.setTypeDropdown(!productHandle.typeDropdown)}>
                <div className="af-class-text-button-card-p_catalog">{productHandle.selectedType}</div>
                <img src="/images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
              </div>
              <nav className="w-dropdown-list" style={{ display: productHandle.typeDropdown === false ? 'none' : 'block' }}>
                <div className="af-class-dropdown-list af-class-type">
                  { productHandle.grindsVariations.map((value, index) => {
                    return <a key={index} className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => productHandle.handleChangeType(value)}>{value}</a>
                  }) }
                </div>
              </nav>
            </div>
          </>}

          <div className="af-class-button-options-card-p_catalog af-class-quantity">
            <div className="af-class-cart__summary-item-quantity-button--minus" onClick={productHandle.handleQtyLower}>–</div>
            <div className="af-class-text-button-option_add-card">{productHandle.selectedQty}</div>
            <div className="af-class-plus-buttonoption-card" onClick={productHandle.handleQtyRaise}>+</div>
          </div>
        </div> : <></> }
      </>
    } else if (props?.variations?.length > 0) {
      return <>
        { !props.categories?.find(elem => elem.id == 404) ? <div className="af-class-options-wrapper-card-p_catalog">
          {(productHandle.optionsVariations && productHandle.optionsVariations.length > 0) && <>
            <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
              <div className="af-class-dropdown-toggle w-dropdown-toggle" onClick={() => productHandle.setOptionDropdown(!productHandle.optionDropdown)}>
                <div className="af-class-text-button-card-p_catalog">{productHandle.selectedOption}</div>
                <img src="/images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
              </div>
              <nav className="w-dropdown-list" style={{ display: productHandle.optionDropdown === false ? 'none' : 'block' }}>
                <div className="af-class-dropdown-list">
                  { productHandle.optionsVariations.map((value, index) => {
                    return <a key={index} className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => productHandle.handleChangeOption(value)}>{value}</a>
                  }) }
                </div>
              </nav>
            </div>
          </>}

          <div className="af-class-button-options-card-p_catalog af-class-quantity">
            <div className="af-class-cart__summary-item-quantity-button--minus" onClick={productHandle.handleQtyLower}>–</div>
            <div className="af-class-text-button-option_add-card">{productHandle.selectedQty}</div>
            <div className="af-class-plus-buttonoption-card" onClick={productHandle.handleQtyRaise}>+</div>
          </div>
        </div> : <></> }
      </>
    } else {
      return <>{ !props.categories?.find(elem => elem.id == 404) ? <div className="af-class-options-wrapper-card-p_catalog">
        <div className="af-class-button-options-card-p_catalog af-class-quantity">
          <div className="af-class-cart__summary-item-quantity-button--minus" onClick={productHandle.handleQtyLower}>–</div>
          <div className="af-class-text-button-option_add-card">{productHandle.selectedQty}</div>
          <div className="af-class-plus-buttonoption-card" onClick={productHandle.handleQtyRaise}>+</div>
        </div>
      </div> : <></> }</>
    }
  }

  return (
    <div style={{ cursor: 'pointer', ...props.styleContainer }} role="list" className={"" + ` ${props.isMe ? css.full : ''}`}>
      <div style={props.style} role="listitem" className="w-dyn-item">
        <div style={props.styleContent} className="af-class-card-catalog">

          <div className="af-class-products-card__topbar">
            {isNew && <div className="af-class-badge-card-p_catalog">New</div>}
            {(productHandle.variationPriceOld && productHandle.variationPriceOld > 0) && <div className="af-class-badge-card-p_catalog" style={{backgroundColor: '#EE2A2A'}}>{(productHandle.variationPrice / productHandle.variationPriceOld - 1).toFixed(2) * 100}%</div>}
            {props.categories?.find(elem => elem.id == 543) && <div className="af-class-badge-card-p_catalog" style={{backgroundColor: '#000'}}>Black Friday</div>}
            <div className="af-class-products-card__favorites-icon-wrapper">
              { (productHandle.favoriteItems && productHandle.favoriteItems.find(elem => elem == props.id)) ? <img onClick={() => productHandle.removeFavoriteItem(props.id)} src="/images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /> : <img onClick={() => productHandle.addFavoriteItem(props.id)} src="/images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /> }
            </div>
          </div>

          <Link to={`/products/${props.slug}`} className="af-class-products-card__image-wrapper">
            <img src={props.images && props.images[0]?.src} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 93vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, 28vw" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" />
          </Link>
          <Link to={`/products/${props.slug}`} className="af-class-products-card__name">
            {props.name}
          </Link>
          <Link to={`/products/${props.slug}`} className="af-class-discription-card-p_catalog" dangerouslySetInnerHTML={{ __html: props.short_description }}></Link>

          <ExtraContentCart />

          {/* { !props.categories?.find(elem => elem.id == 404) ? <>
          { productHandle.variationPriceOld ? <a onClick={productHandle.handleAddToCart} className="af-class-button-card-p_catalog af-class-with_old_price w-inline-block">
            <div className="af-class-pc__btn-text">{(productHandle.addedToCart === false) ? <>Купить за <span className="af-class-pc__btn-price">{productHandle.variationPrice}₽</span><span className="af-class-price-old-text-button-card">{productHandle.variationPriceOld}₽</span></> : (productHandle.updatedCartItem === false) ? <>В корзине</> : <>Обновить</>}</div>
          </a> : <a onClick={productHandle.handleAddToCart} className="af-class-button-card-p_catalog w-inline-block">
            <div className="af-class-pc__btn-text">{(productHandle.addedToCart === false) ? <>Купить за <span className="af-class-pc__btn-price">{productHandle.variationPrice}₽</span></> : (productHandle.updatedCartItem === false) ? <>В корзине</> : <>Обновить</>}</div>
          </a>} </> : <></> } */}

          { !props.categories?.find(elem => elem.id == 404) ? <>
          { productHandle.variationPriceOld ? <a onClick={productHandle.handleAddToCart} className="af-class-button-card-p_catalog af-class-with_old_price w-inline-block js-interactive-button-text">
            <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">{productHandle.variationPrice}₽</span><span className="af-class-price-old-text-button-card">{productHandle.variationPriceOld}₽</span></div>
          </a> : <a onClick={productHandle.handleAddToCart} className="af-class-button-card-p_catalog w-inline-block js-interactive-button-text">
            <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">{productHandle.variationPrice}₽</span></div>
          </a>} </> : <></> }

          { (props.fromAccount && props.categories?.find(elem => elem.id == 404)) ? <a className="af-class-button-card-p_catalog af-class-with_old_price w-inline-block" style={{cursor: 'default', backgroundColor: '#000'}}>
            <div className="af-class-pc__btn-text">Нет в наличии</div>
          </a> : <></> }

        </div>
      </div>
    </div>
  )
}

export default Product