import React from 'react'

const CoffeeShopsItem = (props) => {
    
    return <>
        <div className="w-layout-grid af-class-cofee-shop__item af-class-an-scroll-into-view">
            <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef42-628eef41" className="af-class-coffee-shop__image-wrapper">
                <img src={props.images?.find(elem => elem.id == props.foto)?.source_url} loading="lazy" alt="" className="af-class-coffee-shop__image af-class-an-image--scroll-into-view" />
            </div>
            <h2 id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef44-628eef41" className="af-class-coffee-shop__titile">{props.zag}</h2>
            <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef46-628eef41" className="w-layout-grid af-class-coffee-shop__instagram">
                <div dangerouslySetInnerHTML={{__html: props.opiss}} />
            </div>
            {/* <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef46-628eef41" className="w-layout-grid af-class-coffee-shop__instagram">
                <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef47-628eef41" className="af-class-coffee-shop__instagram-label">Инстаграм</div>
                <a id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef49-628eef41" href="#" target="_blank" className="af-class-coffee-shop__instagram-link">{instagram}</a>
            </div> */}
            {/* <a id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef4b-628eef41" href="#" className="af-class-coffee-shop__availability-button w-inline-block">
                <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef4c-628eef41" className="af-class-coffee-shop__availability-button-text">Наличие товара в кофейне</div>
                <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef4e-628eef41" className="af-class-coffee-shop__availability-button-underline" />
            </a> */}
            <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef4f-628eef41" className="w-layout-grid af-class-cofee-shop__info">
                <div dangerouslySetInnerHTML={{__html: props.adresvremia}} />
            </div>
            {/* <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef4f-628eef41" className="w-layout-grid af-class-cofee-shop__info">
                <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef50-628eef41" className="af-class-cofee-shop__adress">{address}</div>
                <div id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef52-628eef41" className="w-layout-grid af-class-cofee-shop__work-time-wrapper">
                    {workHours.map((hours, index) => (
                        <div key={index} id="w-node-_48eca20a-160a-47b8-1519-d5ec628eef53-628eef41" className="af-class-cofee-shop__work-time">{hours}</div>
                    ))}
                </div>
            </div> */}
        </div>
    </>
}

export default CoffeeShopsItem