import { useContext } from "react";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import CartFormAddressForm from "./CartFormAddressForm";

const CartFormGroupFieldDeliveryAddress = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);

  return (
    <div className="w-layout-grid af-class-cart__user-info">
      <div className="af-class-cart__user-title-wrapper">
        <h3 className="af-class-cart__user-title">Адрес доставки</h3>
        {mainContext.userAddresses.length > 0 && (
          <a
            onClick={() => cartFormContext.setPopup("addresses")}
            className="af-class-button--secondry w-inline-block"
          >
            <div className="af-class-cart__button--secondry__text">
              Изменить адрес доставки
            </div>
            <div className="af-class-cart__button--secondry__underline" />
          </a>
        )}
      </div>
      <div className="w-layout-grid af-class-cart__user-fields">
        <CartFormAddressForm />
      </div>
    </div>
  );
};

export default CartFormGroupFieldDeliveryAddress;
