// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// https://wordpress.stackexchange.com/questions/311825/how-to-login-via-wordpress-api-and-get-user-details

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken.js";
import useCart from "../../hooks/useCart.js";
import { MainContext } from "../../contexts/MainContext";
import CartFieldText from "../Cart/CartFieldText";
import "./style.css";
import {
  fetchCurrentUser,
  fetchCurrentUserData,
  authorizeUser,
  fetchCurrentUserNonce,
  authMCRM,
  recoverMCRM,
} from "../../fetch/WPAPI";

async function loginUser(credentials) {
  return await authorizeUser(credentials.email, credentials.password);
}

const LoginForm = (props) => {
  const { token, setToken, nonce, setNonce } = useToken();
  const { setCart } = useCart();
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);

  useEffect(() => {
    if (token && token.token) {
      setCart([]);
      navigate("/me");
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFail, setLoginFail] = useState(false);
  const [phone, setPhone] = useState("");
  const [formPhoneCode, setFormPhoneCode] = useState("+7");
  const [formPhoneFlag, setFormPhoneFlag] = useState("ru");

  const [isCmc, setIsCmc] = useState(false);
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const verifyPhone = async () => {
    if (phone) {
      let result = await recoverMCRM(formPhoneCode + ' ' + phone);
      if (result.error === "ERROR") {
        alert(result.user_message || "Произошла неизвестная ошибка");
      }
    }
  };

  const userauth = async () => {
    if (localStorage.getItem("phone")) {
      let sid = null;
      for (let i = 0; i < document.cookie.split(";").length; i++) {
        if (document.cookie.split(";")[i].includes("mcrm_sid")) {
          sid = document.cookie.split(";")[i].split("=")[1];
        }
      }
      const data = {
        login: formPhoneCode + ' ' + phone,
        sid: sid,
        password: localStorage.getItem("phone"),
      };
      const resultCRM = await authMCRM(data);
      if (resultCRM.error === "ERROR") {
        alert(resultCRM.user_message);
      } else {
        localStorage.setItem("access", resultCRM.access_token);
        const result = await loginUser({
          email,
          password,
        });
        if (!result) {
          setIsLoading(false);
          setIsCmc(false);
          setLoginFail(true);
        }

        if (result.data?.status == 403) {
          setLoginFail(true);
        } else {
          setToken(result);
          if (!mainContext.userData.id) {
            fetchCurrentUser(result.token).then((result) => {
              if (result.id) {
                fetchCurrentUserData(result.id).then((resultWoo) => {
                  if (resultWoo.id) {
                    mainContext.setUserData(resultWoo);
                    mainContext.setUserWpData(result);
                    setCart([]);
                    localStorage.removeItem("recentlySeen")
                    navigate("/me");
                  } else {
                    setToken(null);
                    setLoginFail(true);
                  }
                });
              } else {
                setToken(null);
                setLoginFail(true);
              }
            });
          }
          fetchCurrentUserNonce(result.token).then((result) => {
            setNonce(result.nonce);
          });
        }
      }
    }
  };
  const changePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isCmc === false) {
      setIsCmc(true);
      await verifyPhone();
    } else {
      setIsLoading(true);
      localStorage.setItem("phone", code);
      localStorage.setItem("phoneNumber", formPhoneCode + ' ' + phone);
      await userauth();
    }
  };
  if (isLoading === true) {
    return (
      <div className="authLoading">
        <img src="/images/loading-rocket.svg" alt="" />
      </div>
    );
  }

  return (
    <div className="af-class-log-in__form-block w-form">
      <form
        onSubmit={handleSubmit}
        id="wf-form-Log-in-form"
        className="af-class-log-in__form"
      >
        {(loginFail && !isCmc) && (
          <div style={{ color: "red" }}>Неверная пара логин/пароль</div>
        )}

        <div className="w-layout-grid af-class-log-in__form-grid">
          {!isCmc && (
            // <CartFieldText
            //   value={phone}
            //   handleChange={changePhone}
            //   name="phone_login"
            //   placeholder="Телефон"
            // />
            <CartFieldText
              value={phone}
              handleChange={changePhone}
              name="phone"
              placeholder="Телефон"
              code={formPhoneCode}
              setCode={setFormPhoneCode}
              flag={formPhoneFlag}
              setFlag={setFormPhoneFlag}
            />
          )}

          {isCmc && (
            <div className="af-class-cart__user-field-wrapper">
              <div className={`af-class-cart__user-field-label ${ code.length && 'af-class-cart__user-field-label-active' }`}>СМС код</div>
              <input
                type="text"
                className="af-class-cart__user-field w-input"
                placeholder="СМС код"
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          )}
          {!isCmc && (
            <div className="af-class-cart__user-field-wrapper">
              <div className={`af-class-cart__user-field-label ${ email.length && 'af-class-cart__user-field-label-active' }`}>Эл. почта</div>
              <input
                type="email"
                className="af-class-cart__user-field w-input"
                maxLength={256}
                placeholder="Эл. почта"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          )}
          {!isCmc && (
            <div className="af-class-cart__user-field-wrapper">
              <div className={`af-class-cart__user-field-label ${ password.length && 'af-class-cart__user-field-label-active' }`}>Пароль</div>
              <input
                type="password"
                className="af-class-cart__user-field w-input"
                maxLength={256}
                placeholder="Пароль"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}

          <a
            id="w-node-_28c615f2-5964-0115-9cb4-05aeb7d195d2-b23d362e"
            href="#"
            className="af-class-button--secondry w-inline-block"
            onClick={props.openRecoveryPopup}
          >
            <div className="af-class-cart__button--secondry__text">
              Забыли пароль?
            </div>
            <div className="af-class-cart__button--secondry__underline" />
          </a>
        </div>
        <input
          type="submit"
          value={!isCmc ? "Получить СМС" : "Вход"}
          className="af-class-button--primary af-class-log-in w-button"
        />
      </form>
    </div>
  );
};

export default LoginForm;
