import { useContext, useEffect, useRef } from "react";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import {
  getCDEKPoints,
  getYandexPoints,
  getCDEKPrice,
  getDalliPrice,
  getRussianPostPrice,
  getYandexPriceCourier,
  getYandexPricePvz,
} from "../../../fetch/WPAPI";
import {
  getMinDeliveryDateDalli,
  getOffsetDeliveryDateCdek,
} from "./functions";
import useCart from "../../../hooks/useCart.js";
import citiesDalli from "../../../data/dalli-cities.json";
import { AddressSuggestions } from "react-dadata";

const CartFormPopupCity = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);
  const citySuggestionsRef = useRef(null);
  const { customerData, setCustomerData, setCart, setCartOrderId } = useCart();

  const setDynamicPvzValues = (pvzCity, cityData) => {
    if (pvzCity?.points && pvzCity.points?.length) {
      let random = pvzCity.points[0];
      getCDEKPrice(
        136,
        cityData.data.city,
        cityData.data.postal_code,
        random.location.address_full,
        mainContext.cartTotalWeight,
        cityData.data.country
      ).then((result) => {
        if (result.total_sum) {
          cartFormContext.setDynamicDeliveryDataPvz({
            datetime: `${new Date(new Date().setDate(new Date().getDate() + getOffsetDeliveryDateCdek() + result.calendar_min)).toLocaleDateString("ru-RU")}-${new Date(new Date().setDate(new Date().getDate() + getOffsetDeliveryDateCdek() + result.calendar_max)).toLocaleDateString("ru-RU")}`,
            cost: result.total_sum,
          });
        }
      });
    }
  }

  const setDynamicCourierValues = (cityData) => {
    if (citiesDalli.includes(cityData.data.city)) {
      getDalliPrice(cityData.data.city, mainContext.cartTotalWeight).then((result) => {
        if (result["@attributes"].price) {
          cartFormContext.setDynamicDeliveryDataCourier({
            datetime: getMinDeliveryDateDalli().toLocaleDateString("ru-RU"),
            cost: parseInt(result["@attributes"].price),
          });
        }
      });
    } else {
      // СДЭК CDEK hidden for now
      getCDEKPrice(
        137,
        cityData.data.city,
        cityData.data.postal_code,
        cityData.data.city, // addressData.full,
        mainContext.cartTotalWeight,
        cityData.data.country
      ).then((result) => {
        if (result.total_sum) {
          cartFormContext.setDynamicDeliveryDataCourier({
            datetime: `${new Date(new Date().setDate(new Date().getDate() + getOffsetDeliveryDateCdek() + result.calendar_min)).toLocaleDateString("ru-RU")}-${new Date(new Date().setDate(new Date().getDate() + getOffsetDeliveryDateCdek() + result.calendar_max)).toLocaleDateString("ru-RU")}`,
            cost: result.total_sum,
          });
        }
      });
    }
  }

  const setDynamicRussianPostValues = (cityData) => {
    // Weight has to be greater than zero; if it hasn't been calculated yet in the context - set it to 1 gram
    getRussianPostPrice(cityData.data.fias_id, mainContext.cartTotalWeight > 0 ? mainContext.cartTotalWeight : 1).then((result) => {
      if (result.price && result.price[0]["@attributes"].price) {
        cartFormContext.setDynamicDeliveryDataRussianPost({
          datetime: `${new Date(new Date().setDate(new Date().getDate() + parseInt(result.price[0]["@attributes"].delivery_period) + 3)).toLocaleDateString("ru-RU")}`,
          cost: parseInt(result.price[0]["@attributes"].price),
        });
      }
    });
  }

  const setDynamicYandexCourierValues = (cityData) => {
    getYandexPriceCourier(cityData.data.city, mainContext.cartTotalWeight).then((result) => {
      if (result.cost) {
        cartFormContext.setDynamicDeliveryDataYandexCourier({
          datetime: `${new Date(new Date().setDate(new Date().getDate() + 3)).toLocaleDateString("ru-RU")}`,
          cost: parseInt(result.cost),
        });
      }
    });
  }

  const setDynamicYandexPvzValues = (pvzId) => {
    getYandexPricePvz(pvzId, mainContext.cartTotalWeight).then((result) => {
      if (result.cost) {
        cartFormContext.setDynamicDeliveryDataYandexPvz({
          datetime: `${new Date(new Date().setDate(new Date().getDate() + 3)).toLocaleDateString("ru-RU")}`,
          cost: parseInt(result.cost),
        });
      }
    });
  }

  const handleChangeDeliveryCity = (value) => {
    if (value.data) {
      console.log(value)
      citySuggestionsRef.current.setInputValue(value.data.city);

      /**
       * Only make the following changes if the city has been really changed
       * This may not be the case if the user has chosen an address with the same city that has been previously selected (but this event still triggers this function)
       * Postal code has been chosen as an anchor to distinguish between cities with the same name
       * Or if the postal_code is null (happens for some cities, and if we change from one such city to another, the city is not changed)
       **/
      if (cartFormContext.city.cityPostalCode != value.data.postal_code || value.data.postal_code == null) {
        cartFormContext.setCity({
          country: value.data.country,
          city: value.data.city,
          cityFias: value.data.fias_id,
          cityPostalCode: value.data.postal_code,
        });
        
        let deliveryTypeVariants = [];
        if (value.data.country == "Россия") {
          if (value.data.city == "Москва") {
            deliveryTypeVariants = [
              "Курьером",
              "Яндекс Доставка",
              "Самовывоз из кофейни",
              "Яндекс ПВЗ",
              "Самовывоз из ПВЗ", // СДЭК CDEK hidden for now
              "Почтой России",
            ];
          } else if (value.data.city == "Санкт-Петербург") {
            deliveryTypeVariants = [
              "Курьером",
              "Яндекс Доставка",
              "Самовывоз из ПВЗ", // СДЭК CDEK hidden for now
              "Яндекс ПВЗ",
              "Почтой России",
            ];
          } else {
            if (citiesDalli.includes(value.data.city)) {
              deliveryTypeVariants = [
                "Курьером",
                "Яндекс Доставка",
                "Самовывоз из ПВЗ", // СДЭК CDEK hidden for now
                "Яндекс ПВЗ",
                "Почтой России",
              ];
            } else {
              deliveryTypeVariants = [
                "Курьером", // СДЭК CDEK hidden for now
                "Яндекс Доставка",
                "Самовывоз из ПВЗ", // СДЭК CDEK hidden for now
                "Яндекс ПВЗ",
                "Почтой России",
              ];
            }
          }

          if (value.data.city == "Москва" || value.data.city == "Санкт-Петербург" || value.data?.region == "Московская") {
            cartFormContext.setDeliveryTime({
              selected: "Выберите время доставки",
              options: [
                "09:00 - 18:00",
                "18:00 - 22:00",
                "09:00 - 22:00",
              ],
            });
          } else {
            cartFormContext.setDeliveryTime({
              selected: "Выберите время доставки",
              options: [
                "09:00 - 13:00",
                "10:00 - 22:00",
                "10:00 - 17:00",
                "18:00 - 22:00",
              ],
            });
          }
        } else {
          deliveryTypeVariants = [
            "Самовывоз из ПВЗ", // СДЭК CDEK hidden for now
            // "Почтой России",
          ];
        }
        cartFormContext.setDeliveryType({
          selected: "Выберите тип доставки",
          options: deliveryTypeVariants,
        });

        // If the city changes, delivery type is reset and delivery cost is cleared and invalidated (set to false)
        // Also the previously selected delivery details are cleared
        cartFormContext.setDeliveryCost(false);
        cartFormContext.setDeliveryDeadline('');

        cartFormContext.setDeliveryCompany({
          selected: "Выберите компанию",
          options: [],
        });
        
        cartFormContext.setAddressData({
          country: value.data.country,
          city: value.data.city,
          cityFiasId: value.data.fias_id,
          cityKladrId: value.data.kladr_id,
          cityPostalCode: value.data.postal_code,
          full: '',
          entrance: '',
          floor: '',
          flat: '',
          homephone: '',
        });
  
        cartFormContext.setDeliveryCoffeeshop({
          selected: "Выберите кофейню",
        });
  
        cartFormContext.setDeliveryPvz({
          selected: "Выберите пункт самовывоза",
          options: [],
        });
  
        let pvzCity = mainContext.pvzAll.find((elem) => elem.company == "cdek" && elem.cityName == value.data.city && elem.cityPostalCode == value.data.postal_code);
        if (pvzCity) {
          cartFormContext.setCityPvz(pvzCity);
          setDynamicPvzValues(pvzCity, value);
        } else {
          getCDEKPoints(value.data.city, value.data.country, value.data.postal_code).then(
            (result) => {
              pvzCity = {
                company: "cdek",
                cityName: value.data.city,
                cityPostalCode: value.data.postal_code,
                cityCode: result.city.code,
                city: result.city,
                points: result.points,
              };
  
              mainContext.setPvzAll([...mainContext.pvzAll, pvzCity]);
              cartFormContext.setCityPvz(pvzCity);
              setDynamicPvzValues(pvzCity, value);
            }
          );
        }

        // if (value.data.city == "Москва" || value.data.city == "Санкт-Петербург") {
          let pvzCityYandex = mainContext.pvzAll.find((elem) => elem.company == "yandex" && elem.cityName == value.data.city && elem.cityPostalCode == value.data.postal_code);
          if (pvzCityYandex) {
            cartFormContext.setCityPvzYandex(pvzCityYandex);
          } else {
            getYandexPoints(value.data.city).then(
              (result) => {
                pvzCityYandex = {
                  company: "yandex",
                  cityName: value.data.city,
                  cityPostalCode: value.data.postal_code,
                  cityCode: result.city.code,
                  city: result.city,
                  points: result.points,
                };

                console.log(result.points)

                if (result.points.length && result.points[0]?.id) {
                  setDynamicYandexPvzValues(result.points[0].id);
                }
    
                mainContext.setPvzAll([...mainContext.pvzAll, pvzCityYandex]);
                cartFormContext.setCityPvzYandex(pvzCityYandex);
              }
            );
          }
        // }
  
        setDynamicCourierValues(value);
        setDynamicRussianPostValues(value);
        setDynamicYandexCourierValues(value);
      }
    }
  }

  // Change city when the user selects an address from their address list
  useEffect(() => {
    if (cartFormContext.addressData.city) {
      handleChangeDeliveryCity({
        data: {
          country: cartFormContext.addressData.country,
          city: cartFormContext.addressData.city,
          fias_id: cartFormContext.addressData.cityFiasId,
          postal_code: cartFormContext.addressData.cityPostalCode,
        },
      });
    }
  }, [cartFormContext.addressData.city]);
  
  // When the component mounts - check if there is a saved address in the local storage and prepopulate the form if there is
  useEffect(() => {
    if (customerData.formDeliveryCountry && customerData.formDeliveryCity && customerData.formDeliveryCityFias && customerData.formDeliveryCityPostalCode) {
      handleChangeDeliveryCity({
        data: {
          country: customerData.formDeliveryCountry,
          city: customerData.formDeliveryCity,
          fias_id: customerData.formDeliveryCityFias,
          postal_code: customerData.formDeliveryCityPostalCode,
        },
      });
    }
  }, []);

  return (
    <div
      style={{
        display: cartFormContext.popup == "city" ? "flex" : "none",
        zIndex: "2147483647",
      }}
      className="af-class-cart-popup-wrapper"
    >
      <div className="af-class-cart-popup-form w-form">
        <a
          href="#"
          className="af-class-cart-popup-close-button w-inline-block"
          onClick={() => cartFormContext.setPopup(null)}
        >
          <img
            src="images/icon--close-popup.svg"
            loading="lazy"
            alt=""
            className="af-class-cart-popup-close-button-icon"
          />
        </a>
        <div
          className="af-class-cart-popup"
          style={{ paddingTop: "60px", paddingBottom: "60px" }}
        >
          <div className="af-class-cart-popup__title">Выберите ваш город</div>

          <AddressSuggestions
            ref={citySuggestionsRef}
            filterFromBound="country"
            filterToBound="settlement"
            filterLocations={[
              { country_iso_code: "ru" },
              { country_iso_code: "by" },
              { country_iso_code: "az" },
              { country_iso_code: "am" },
              { country_iso_code: "kz" },
              { country_iso_code: "kg" },
              { country_iso_code: "md" },
              { country_iso_code: "tj" },
              { country_iso_code: "tm" },
              { country_iso_code: "uz" },
              { country_iso_code: "ge" },
            ]}
            token={process.env.REACT_APP_DADATA_API_KEY}
            value={{ value: cartFormContext.city.city ? cartFormContext.city.city : '' }}
            onChange={(suggestion) => handleChangeDeliveryCity(suggestion)}
            inputProps={{
              className: `af-class-cart__user-field w-input${
                // mainContext.cartFormData.isValidDeliveryCity === false ? " user-input-invalid" : ""
                ""
              }`,
              placeholder: "Начните вводить ваш город и выберите его из списка",
            }}
          />

          <div className="af-class-cart-popup__submit-button-wrapper">
            <input
              onClick={() => cartFormContext.setPopup(null)}
              type="button"
              value="Подтвердить"
              className="af-class-g-submit-button w-button"
            />
          </div>
        </div>
      </div>
      <div
        className="af-class-cart-popup__close-area"
        onClick={() => cartFormContext.setPopup(null)}
      />
    </div>
  );
};

export default CartFormPopupCity;

{/* <div
  style={{
    display: cityPopupOpened === true ? "flex" : "none",
    zIndex: "2147483647",
  }}
  className="af-class-cart-popup-wrapper"
>
  <div className="af-class-cart-popup-form w-form">
    <a
      href="#"
      className="af-class-cart-popup-close-button w-inline-block"
      onClick={() => setCityPopupOpened(false)}
    >
      <img
        src="images/icon--close-popup.svg"
        loading="lazy"
        alt=""
        className="af-class-cart-popup-close-button-icon"
      />
    </a>
    <div
      className="af-class-cart-popup"
      style={{ paddingTop: "60px", paddingBottom: "60px" }}
    >
      <div className="af-class-cart-popup__title">Выберите ваш город</div>

      <AddressSuggestions
        ref={citySuggestionsRef}
        filterFromBound="country"
        filterToBound="settlement"
        filterLocations={[
          { country_iso_code: "ru" },
          { country_iso_code: "by" },
          { country_iso_code: "az" },
          { country_iso_code: "am" },
          { country_iso_code: "kz" },
          { country_iso_code: "kg" },
          { country_iso_code: "md" },
          { country_iso_code: "tj" },
          { country_iso_code: "tm" },
          { country_iso_code: "uz" },
          { country_iso_code: "ge" },
        ]}
        token={process.env.REACT_APP_DADATA_API_KEY}
        value={{ value: formDeliveryCity }}
        onChange={(suggestion) => handleChangeDeliveryCity(suggestion)}
        inputProps={{
          className: `af-class-cart__user-field w-input${
            formDeliveryCityValid === false ? " user-input-invalid" : ""
          }`,
          placeholder: "Начните вводить ваш город и выберите его из списка",
        }}
      />

      <div className="af-class-cart-popup__submit-button-wrapper">
        <input
          onClick={() => setCityPopupOpened(false)}
          type="button"
          value="Подтвердить"
          className="af-class-g-submit-button w-button"
        />
      </div>
    </div>
  </div>
  <div
    className="af-class-cart-popup__close-area"
    onClick={() => setCityPopupOpened(false)}
  />
</div> */}