/* eslint-disable */

import React, { createRef } from 'react'
import { useLocation } from 'react-router-dom'
import Product from '../components/Product/Product'
import CatalogSortDesktop from '../components/Catalog/CatalogSortDesktop'
import CatalogSortMobile from '../components/Catalog/CatalogSortMobile'
import CatalogTabNav from '../components/Catalog/CatalogTabNav'

import { fetchProducts, fetchFibosearch, fetchProductCategory } from '../fetch/WPAPI'
import { MainContext } from '../contexts/MainContext'
import { createScope, map, transformProxies } from './helpers'

const withLocation = (Component) => {
  return () => {
    const { pathname } = useLocation()
    return <Component pathname={pathname} />
  }
}

class CatalogView extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      pageData: {},
      isFiltersOpen: false,
      allProducts: [],
      products: [],
      filters: [],
      variations: [],
      searchString: '',
      searchProducts: [],
      categorySlug: '',
      sort: 0,
      filtersTooltipOpened: {},
      isHovering: '',
      mobileFiltersHidden: [],
      productsLoading: true,
      filtersOpened: false,
    }
    this.filtersMobileToggle = this.filtersMobileToggle.bind(this)
    this.categoryId = 17
    this.setSort = this.setSort.bind(this)
    this.handleMobileFiltersHidden = this.handleMobileFiltersHidden.bind(this)
  }

  setHover = (filter) => this.setState({ isHovering: filter })

  checkHover = e => {
    let dataFilter = e.target?.dataset?.filter
    if (typeof(dataFilter) == 'undefined') {
      dataFilter = ''
    }

    if (dataFilter != this.state.isHovering) {
      this.setHover(dataFilter)
    }
  }

  handleTooltipClick = filter => {
    this.state.isHovering == JSON.stringify(filter) ? this.setHover('') : this.setHover(JSON.stringify(filter))
  }

  loadProducts = (params = {}) => {
    let catalog = this.context.catalogItems.find(elem => elem.categoryId == this.categoryId)
    console.log(catalog)
    if (catalog) {
      catalog.items = catalog.items.filter(elem => !elem.categories.find(elem => elem.id == 404))
      this.setState({ allProducts: catalog.items, products: catalog.items?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0) ) })
      this.setState({productsLoading: false})
    } else {
      fetchProducts(this.categoryId)
        .then(result => {
          result = result.filter(elem => !elem.categories.find(elem => elem.id == 404))
          this.setState({ allProducts: result, products: result?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0) ) })
          this.context.setCatalogItems([...this.context.catalogItems, { categoryId: this.categoryId, items: result }])
          this.setState({productsLoading: false})
          console.log(result)
        })
    }
  }

  /*handleScroll = () => {
    let scrolled = window.innerHeight + document.documentElement.scrollTop;
    let catalog = document.querySelector('.af-class-grid-cards-p_catalog');
    let catalogBottom = catalog.offsetTop + catalog.offsetHeight;

    if (scrolled < catalogBottom) return;
    if (this.isFetching === false) {
      this.loadProducts()
    }
  }*/

  componentDidMount() {
    this.loadProducts()

    fetchProductCategory(this.categoryId)
      .then(res => {
        this.setState({ pageData: res })
       })

    //window.addEventListener("scroll", this.handleScroll)

    window.addEventListener("mouseover", this.checkHover, true)
  }

  componentDidUpdate(prevProps, prevState) {
    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0] == 'search') {
        if (this.state.searchString != pair[1]) {
          this.setState({searchString: pair[1]})
        }
      }
      if (pair[0] == 'category') {
        if (this.state.categorySlug != pair[1]) {
          this.setState({categorySlug: pair[1]})
        }
      }
    }

    if (this.state.searchString !== prevState.searchString) {
      if (this.state.searchString != '') {
        this.filterBySearchString()
      }
    }

    if (this.state.searchProducts !== prevState.searchProducts || this.state.allProducts !== prevState.allProducts) {
      // if (this.state.searchProducts.length > 0) {
      if (this.state.searchString != '') {
        this.setState({ products: this.state.allProducts?.filter(p => this.state.searchProducts.includes(p.id)) })
      }
      // }
    }

    if (this.state.categorySlug !== prevState.categorySlug || this.state.allProducts !== prevState.allProducts) {
      if (this.state.categorySlug.length > 0) {
        let category = this.context.headerMenuCategories.find(elem => elem.slug == this.state.categorySlug)
        if (category) {
          this.setState({ products: this.state.allProducts?.filter(p => p.categories.find(elem => elem.id == category.id)) })
        }
      } else {
        this.setState({ products: this.state.allProducts })
      }
    }

    if (this.state.sort !== prevState.sort) {
      if (this.state.sort == 0) {
        this.setState({ products: this.state.products?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0) ) })
      } else if (this.state.sort == 3) {
        this.setState({ products: this.state.products?.sort((a, b) => (a.date_created > b.date_created) ? -1 : ((b.date_created > a.date_created) ? 1 : 0) ) })
      } else if (this.state.sort == 4) {
        this.setState({ products: this.state.products?.sort((a, b) => (a.date_created > b.date_created) ? 1 : ((b.date_created > a.date_created) ? -1 : 0) ) })
      } else if (this.state.sort == 1) {
        this.setState({ products: this.state.products?.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? 1 : ((parseInt(b.price) > parseInt(a.price)) ? -1 : 0) ) })
      } else if (this.state.sort == 2) {
        this.setState({ products: this.state.products?.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? -1 : ((parseInt(b.price) > parseInt(a.price)) ? 1 : 0) ) })
      }
    }

    if (this.state.pageData != prevState.pageData) {
      document.title = this.state.pageData?.yoast_head_json?.title
      document.querySelector('meta[name="description"]').setAttribute("content", this.state.pageData?.yoast_head_json?.description)
    }
  }

  setSort(value) {
    this.setState({ sort: value })
  }

  handleMobileFiltersHidden = (id) => {
    if (this.state.mobileFiltersHidden.includes(id)) {
      this.setState({mobileFiltersHidden: this.state.mobileFiltersHidden.filter(elem => elem != id)})
    } else {
      this.setState({mobileFiltersHidden: [...this.state.mobileFiltersHidden, id]})
    }
  }

  filterBySearchString() {
    console.log(decodeURIComponent(this.state.searchString))
    fetchFibosearch(decodeURIComponent(this.state.searchString))
      .then(result => {
        this.setState({ searchProducts: result.suggestions.filter(elem => elem.type == 'product').map(elem => elem.post_id) })
      })
  }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("mouseover", this.checkHover, true)
  }

  filtersMobileToggle() {
    this.setState(prevState => ({ ...prevState, isFiltersOpen: !prevState.isFiltersOpen }))
  }

  render() {
    //let categoryProducts = this.state.products?.filter(p => p.categories.find(c => c.id === this.categoryId) !== undefined)
    const products = this.state.products?.map((p, index) => <Product key={p.id} {...p} />)

    // const allcategories = {cats:[]}
    // this.state.products?.map((elem, index) => allcategories.cats = [...allcategories.cats, ...elem.categories])
    // const uniqueIds = [...new Set(allcategories.cats.map(item => item.id))]
    // console.log(uniqueIds.map(id => allcategories.cats.find(elem => elem.id == id)))

    // const handleFilter = () => {
    //   let products = this.state.allProducts?.filter(p => p.categories.find(c => c.id === this.categoryId) !== undefined)
    //   let filtredItems = products.filter(p => this.state.filters.every(f => {
    //     let currentAttributeIndex = p.attributes.findIndex(a => a.id === f.id)
    //     return p.attributes[currentAttributeIndex]?.options.includes(f.option)
    //   }))
    //   this.setState({
    //     products: filtredItems
    //   })
    // }

    const handleFilter = () => {
      let products = this.state.allProducts?.filter(p => p.categories.find(c => c.id === this.categoryId) !== undefined)

      let sortedFilters = []
      this.state.filters.map((elem, index) => {
        if (!sortedFilters[elem.id]) {
          sortedFilters[elem.id] = []
        }
        sortedFilters[elem.id].push(elem.option)
      })
      console.log(sortedFilters)

      let filteredItems = products.filter(product => sortedFilters.every((filter, index) => {
        if (filter.includes('Мытая (washed process)')) {
          filter.push('Washed (мытая)')
          filter.push('Мытая')
        }
        if (filter.includes('Натуральная (natural process)')) {
          filter.push('Full Natural (сухая)')
          filter.push('Натуральная')
        }
        if (filter.includes('Капельная кофеварка')) {
          filter.push('Кофеварка капельная')
        }
        if (filter.includes('Без кофеина')) {
          filter.push('Кофе без кофеина')
        }

        if (index != 9991) {
          let currentAttributeIndex = product.attributes.findIndex(a => a.id === index)
          return product.attributes[currentAttributeIndex]?.options.filter(value => filter.includes(value)).length > 0
        } else {
          return product.categories.filter(value => filter.includes(value.name)).length > 0
        }
      }))
      this.setState({
        products: filteredItems
      })
    }

    // const handleNewFilterItem = (newItem) => {
    //   let prevState = [...this.state.filters]
    //   if (prevState.some(i => i.id === newItem.id)) {
    //     let index = prevState.findIndex(i => i.id === newItem.id)
    //     prevState[index] = newItem
    //   } else {
    //     prevState.push(newItem)
    //   }
    //   let filtredFilters = prevState.map(i => i.id === newItem.id ? newItem : i)
    //   this.setState({
    //     filters: filtredFilters
    //   })
    // }

    const handleNewFilterItem = (newItem) => {
      let prevState = [...this.state.filters]
      if (prevState.some(i => i.id === newItem.id && i.option == newItem.option)) {
        let index = prevState.findIndex(i => i.id === newItem.id && i.option == newItem.option)
        prevState.splice(index, 1)
      } else {
        prevState.push(newItem)
      }
      // console.log(prevState)
      this.setState({
        filters: prevState
      })
    }
    
    const handleFiltersClear = () => {
      this.setState({
        filters: [],
        products: this.state.allProducts
      })
    }

    const FilterField = ({ filter }) => {
      return <label className="w-checkbox af-class-checkbox_field" onClick={() => handleNewFilterItem(filter)}>
        <div className={`w-checkbox-input w-checkbox-input--inputType-custom af-class-checkbox ${this.state.filters.some(f => f.option === filter.option) ? 'w--redirected-checked w--redirected-focus' : ''}`} />
        <input type="checkbox" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
        <span className="af-class-label-checkbox w-form-label">{filter.option}</span>
      </label>
    }

    const FilterFieldTooltip = ({ filter }) => {
      return <div onClick={() => handleNewFilterItem(filter)} className="af-class-checkbox-wrapper">
        <label className="w-checkbox af-class-checkbox_field">
          <div className={`w-checkbox-input w-checkbox-input--inputType-custom af-class-checkbox ${this.state.filters.some(f => f.option === filter.option) ? 'w--redirected-checked' : ''}`} />
          <input type="checkbox" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
          <span className="af-class-label-checkbox w-form-label">{filter.option}</span>
        </label>
        {/* <div className="af-class-question_btn af-class-control-p_catalog" data-filter={JSON.stringify(filter)} onClick={() => this.handleTooltipClick(filter)}>
          <div className="af-class-availability__tooltip_block" style={{ display: (this.state.isHovering == JSON.stringify(filter)) ? 'block' : 'none' }}>
            <div className="af-class-tooltip__text">{filter.option}</div>
          </div>
        </div> */}
      </div>
    }

    const FilterFieldPellet = ({ filter }) => {
      const active = this.state.filters.some(f => f.option === filter.option);

      return <div
        style={{
          display: 'flex',
          gap: '10px',
          padding: '8px 16px',
          backgroundColor: '#fff',
          borderRadius: '50px',
          cursor: 'pointer',
          border: active ? '1px #1D71B8 solid' : '1px #E0E0E0 solid',
          color: active ? '#1D71B8' : '#000',
        }}
        onClick={() => handleNewFilterItem(filter)}
      >
        <div>{filter.option}</div>
        {active && <div style={{ display: 'flex', padding: '4px', width: '15px', height: '15px', borderRadius: '15px', backgroundColor: '#1D71B8', marginTop: '3px' }}><img src="/images/icon--close-popup.svg" style={{ width: '7px', height: '7px', filter: 'invert(1)' }} /></div>}
      </div>
    }

    const filters = [
      {
        id: 1,
        name: 'Способ заваривания',
        options: ['Капельная кофеварка', 'Аэропресс', 'Воронка', 'Гейзер', 'Иммерсия', 'Кофемашина', 'Турка', 'Френч-пресс', 'Чашка', 'Дрип', 'Капсула']
      },
      {
        id: 9991,
        name: 'Тип обжарки',
        options: ['Эспрессо', 'Фильтр']
      },
      {
        id: 2,
        name: 'Тип кофе',
        options: ['Арабика', 'Без кофеина', 'Смесь арабики и робусты']
      },
      {
        id: 3,
        name: 'Уровень кислотности',
        options: ['Высокая', 'Средняя', 'Низкая']
      },
      {
        id: 20,
        name: 'Регион',
        options: ['Азия и Океания', 'Африка', 'Центральная Америка', 'Южная Америка']
      },
      {
        id: 21,
        name: 'Обработка',
        options: ['Натуральная (natural process)', 'Мытая (washed process)', 'Экспериментальная']
      },
    ]

    return (
      <>
            { ( this.state.categorySlug != 'kofe-v-kapsulah' && this.state.categorySlug != 'drips') && <div className="af-class-filter-mobile" style={this.state.isFiltersOpen ? { display: 'block' } : undefined}>
              <div className="af-class-title-filter-wrapper">
                <div className="af-class-title_and_counter-wrapper-filter">
                  <div>Фильтры</div>
                  {/* <div className="af-class-counter-button-filter">2</div> */}
                </div>
                <img src="/images/icon-close-filter_1icon-close-filter.png" loading="lazy" width={30} onClick={() => this.filtersMobileToggle()} alt="" className="af-class-filter__close-button" />
              </div>

              {/* { ( this.state.categorySlug != 'kofe-v-kapsulah' && this.state.categorySlug != 'drips') && <div style={{ borderTop: '1px #d4d4d4 solid', paddingTop: '30px' }}>
                {filters.map((filter, index) => {
                  return <React.Fragment key={index}>
                    <div style={{ borderBottom: '1px #ddd solid', paddingBottom: '30px', marginBottom: '30px' }}>
                      <div style={{ marginBottom: '22px', fontFamily: 'Gotham, sans-serif', fontSize: '14px', lineHeight: '1.2', fontWeight: 700, textTransform: 'uppercase' }}>{filter.name} {this.state.filters.filter(f => f.id == filter.id).length > 0 && <span style={{ color: '#1D71B8' }}>({this.state.filters.filter(f => f.id == filter.id).length})</span>}</div>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {filter.options.map((option, index) => <FilterFieldPellet filter={{ id: filter.id, option: option }} />)}
                      </div>
                    </div>
                  </React.Fragment>
                })}
              </div> } */}

              <div className="af-class-block-filter-p_catalog-mobile">
                <div className="af-class-title_and_icon-filter-mobile-wrapper" onClick={() => this.handleMobileFiltersHidden(9991)}>
                  <div className="af-class-title_and_icon-filter-mobile">
                    <div className="af-class-title-black-filter-p_catalog--mobile">Тип кофе</div>
                    <img src="/images/arrow-down.svg" style={{transform: this.state.mobileFiltersHidden.includes(9991) ? 'rotate(0deg)' : 'rotate(180deg)'}} loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
                  </div>
                </div>
                <div className="af-class-filter--mobile__form-wrapper" style={{display: this.state.mobileFiltersHidden.includes(9991) ? 'none' : 'block'}}>
                  <div className="af-class-form-filter w-form">
                    <div className="af-class-form-two_columns">
                      <FilterField filter={{ id: 9991, option: 'Эспрессо' }} />
                      <FilterField filter={{ id: 9991, option: 'Фильтр' }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="af-class-block-filter-p_catalog-mobile">
                <div className="af-class-title_and_icon-filter-mobile-wrapper" onClick={() => this.handleMobileFiltersHidden(1)}>
                  <div className="af-class-title_and_icon-filter-mobile">
                    <div className="af-class-title-black-filter-p_catalog--mobile">Способ заваривания</div>
                    <img src="/images/arrow-down.svg" style={{transform: this.state.mobileFiltersHidden.includes(1) ? 'rotate(0deg)' : 'rotate(180deg)'}} loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
                  </div>
                </div>
                <div className="af-class-filter--mobile__form-wrapper" style={{display: this.state.mobileFiltersHidden.includes(1) ? 'none' : 'block'}}>
                  <div className="af-class-form-filter w-form">
                    <div className="af-class-form-two_columns">
                      <FilterFieldTooltip filter={{ id: 1, option: 'Капельная кофеварка' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Аэропресс' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Воронка' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Гейзер' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Иммерсия' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Кофемашина' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Турка' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Френч-пресс' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Чашка' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Дрип' }} />
                      <FilterFieldTooltip filter={{ id: 1, option: 'Капсула' }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="af-class-block-filter-p_catalog-mobile">
                <div className="af-class-title_and_icon-filter-mobile-wrapper" onClick={() => this.handleMobileFiltersHidden(2)}>
                  <div className="af-class-title_and_icon-filter-mobile">
                    <div className="af-class-title-black-filter-p_catalog--mobile">Тип кофе</div>
                    <img src="/images/arrow-down.svg" style={{transform: this.state.mobileFiltersHidden.includes(2) ? 'rotate(0deg)' : 'rotate(180deg)'}} loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
                  </div>
                </div>
                <div className="af-class-filter--mobile__form-wrapper" style={{display: this.state.mobileFiltersHidden.includes(2) ? 'none' : 'block'}}>
                  <div className="af-class-form-filter w-form">
                    <div className="af-class-form-two_columns">
                      <FilterField filter={{ id: 2, option: 'Арабика' }} />
                      <FilterField filter={{ id: 2, option: 'Без кофеина' }} />
                      <FilterField filter={{ id: 2, option: 'Смесь арабики и робусты' }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="af-class-block-filter-p_catalog-mobile">
                <div className="af-class-title_and_icon-filter-mobile-wrapper" onClick={() => this.handleMobileFiltersHidden(3)}>
                  <div className="af-class-title_and_icon-filter-mobile">
                    <div className="af-class-title-black-filter-p_catalog--mobile">Уровень кислотности</div>
                    <img src="/images/arrow-down.svg" style={{transform: this.state.mobileFiltersHidden.includes(3) ? 'rotate(0deg)' : 'rotate(180deg)'}} loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
                  </div>
                </div>
                <div className="af-class-filter--mobile__form-wrapper" style={{display: this.state.mobileFiltersHidden.includes(3) ? 'none' : 'block'}}>
                  <div className="af-class-form-filter w-form">
                    <div className="af-class-form-two_columns">
                      <FilterField filter={{ id: 3, option: 'Высокая' }} />
                      <FilterField filter={{ id: 3, option: 'Средняя' }} />
                      <FilterField filter={{ id: 3, option: 'Низкая' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="af-class-block-filter-p_catalog-mobile">
                <div className="af-class-title_and_icon-filter-mobile-wrapper" onClick={() => this.handleMobileFiltersHidden(20)}>
                  <div className="af-class-title_and_icon-filter-mobile">
                    <div className="af-class-title-black-filter-p_catalog--mobile">Регион</div>
                    <img src="/images/arrow-down.svg" style={{transform: this.state.mobileFiltersHidden.includes(20) ? 'rotate(0deg)' : 'rotate(180deg)'}} loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
                  </div>
                </div>
                <div className="af-class-filter--mobile__form-wrapper" style={{display: this.state.mobileFiltersHidden.includes(20) ? 'none' : 'block'}}>
                  <div className="af-class-form-filter w-form">
                    <div className="af-class-form-two_columns">
                      <FilterField filter={{ id: 20, option: 'Азия и Океания' }} />
                      <FilterField filter={{ id: 20, option: 'Африка' }} />
                      <FilterField filter={{ id: 20, option: 'Центральная Америка' }} />
                      <FilterField filter={{ id: 20, option: 'Южная Америка' }} />
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="af-class-block-filter-p_catalog-mobile">
                <div className="af-class-title_and_icon-filter-mobile-wrapper" onClick={() => this.handleMobileFiltersHidden(7)}>
                  <div className="af-class-title_and_icon-filter-mobile">
                    <div className="af-class-title-black-filter-p_catalog--mobile">Обработка</div>
                    <img src="/images/arrow-down.svg" style={{transform: this.state.mobileFiltersHidden.includes(7) ? 'rotate(0deg)' : 'rotate(180deg)'}} loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
                  </div>
                </div>
                <div className="af-class-filter--mobile__form-wrapper" style={{display: this.state.mobileFiltersHidden.includes(7) ? 'none' : 'block'}}>
                  <div className="af-class-form-filter w-form">
                    <div className="af-class-form-two_columns">
                      <FilterField filter={{ id: 21, option: 'Натуральная (natural process)' }} />
                      <FilterField filter={{ id: 21, option: 'Мытая (washed process)' }} />
                      <FilterField filter={{ id: 21, option: 'Экспериментальная' }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-layout-grid af-class-filter_and_show-buttons">
                <div className="af-class-button-filled" onClick={() => {handleFilter(); this.filtersMobileToggle();}}>
                  <div className="af-class-text-button-filter">Применить</div>
                </div>
                <div className="af-class-button-outlined-text_grey">
                  <div onClick={handleFiltersClear} style={{ color: `${this.state.filters.length !== 0  ? '#1d71b8' : '#bcbcbc'}` }} className="af-class-button-reset-filter">Сбросить все фильтры</div>
                </div>
              </div>
            </div> }

            <div className="af-class-section af-class-control af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="af-class-bar-filter">
                  <div data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs w-tabs">
                    <CatalogTabNav pathname={this.props.pathname} />

                    <div className="af-class-tabs-content w-tab-content">
                      <div className="w-tab-pane w--tab-active">
                        
                      {/* { ( this.state.categorySlug != 'kofe-v-kapsulah' && this.state.categorySlug != 'drips') && <div className="catalog-filters-container-desktop" style={{ border: '1px #d4d4d4 solid', backgroundColor: '#fff', padding: '30px' }}>
                        {(() => this.state.filtersOpened ? filters : filters.slice(0, 1))().map((filter, index) => {
                          return <React.Fragment key={index}>
                            <div style={{ borderBottom: '1px #ddd solid', paddingBottom: '30px', marginBottom: '30px' }}>
                              <div style={{ marginBottom: '22px', fontFamily: 'Gotham, sans-serif', fontSize: '14px', lineHeight: '1.2', fontWeight: 700, textTransform: 'uppercase' }}>{filter.name} {this.state.filters.filter(f => f.id == filter.id).length > 0 && <span style={{ color: '#1D71B8' }}>({this.state.filters.filter(f => f.id == filter.id).length})</span>}</div>
                              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {filter.options.map((option, index) => <FilterFieldPellet filter={{ id: filter.id, option: option }} />)}
                              </div>
                            </div>
                            {(this.state.filtersOpened && index === 0) ? <div onClick={() => this.setState({ filtersOpened: !this.state.filtersOpened })} style={{ color: '#bcbcbc', fontSize: '14px', lineHeight: '1.3', marginBottom: '30px' }} className="af-class-button-reset-filter">{this.state.filtersOpened ? 'Скрыть расширенный фильтр' : 'Показать расширенный фильтр'}</div> : <div></div>}
                          </React.Fragment>
                        })}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {!this.state.filtersOpened ? <div onClick={() => this.setState({ filtersOpened: !this.state.filtersOpened })} style={{ color: '#bcbcbc', fontSize: '14px', lineHeight: '1.3' }} className="af-class-button-reset-filter">{this.state.filtersOpened ? 'Скрыть расширенный фильтр' : 'Показать расширенный фильтр'}</div> : <div></div>}
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <div onClick={handleFiltersClear} style={{ color: this.state.filters.length !== 0  ? '#1d71b8' : '#bcbcbc', fontSize: '14px', lineHeight: '1.3' }} className="af-class-button-reset-filter">Сбросить все фильтры</div>
                            <div onClick={handleFilter} className="af-class-button-accept-filter">Применить</div>
                          </div>
                        </div>
                      </div> } */}
                        
                        { ( this.state.categorySlug != 'kofe-v-kapsulah' && this.state.categorySlug != 'drips') && <><div className="w-layout-grid af-class-filter-p_catalog">
                          <div className="af-class-block-filter-p_catalog">
                            <div className="af-class-title-black-filter-p_catalog">Тип обжарки</div>
                            <div className="w-form">
                              <div className="af-class-form-one_column">
                                <FilterField filter={{ id: 9991, option: 'Эспрессо' }} />
                                <FilterField filter={{ id: 9991, option: 'Фильтр' }} />
                              </div>
                            </div>
                          </div>
                          <div className="af-class-block-filter-p_catalog">
                            <div className="af-class-title-black-filter-p_catalog">Способ заваривания</div>
                            <div className="af-class-form-filter w-form">
                              <div className="af-class-form-two_columns">
                                <FilterFieldTooltip filter={{ id: 1, option: 'Капельная кофеварка' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Аэропресс' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Воронка' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Гейзер' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Иммерсия' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Кофемашина' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Турка' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Френч-пресс' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Чашка' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Дрип' }} />
                                <FilterFieldTooltip filter={{ id: 1, option: 'Капсула' }} />
                              </div>
                            </div>
                          </div>
                          <div className="af-class-block-filter-p_catalog">
                            <div className="af-class-title-black-filter-p_catalog">Тип кофе</div>
                            <div className="w-form">
                              <div className="af-class-form-one_column">
                                <FilterField filter={{ id: 2, option: 'Арабика' }} />
                                <FilterField filter={{ id: 2, option: 'Без кофеина' }} />
                                <FilterField filter={{ id: 2, option: 'Смесь арабики и робусты' }} />
                              </div>
                            </div>
                          </div>
                          <div className="af-class-block-filter-p_catalog">
                            <div className="af-class-title-black-filter-p_catalog">Уровень кислотности</div>
                            <div className="w-form">
                              <div className="af-class-form-one_column">
                                <FilterField filter={{ id: 3, option: 'Высокая' }} />
                                <FilterField filter={{ id: 3, option: 'Средняя' }} />
                                <FilterField filter={{ id: 3, option: 'Низкая' }} />
                              </div>
                            </div>
                          </div>

                          {/* <div className="af-class-block-filter-p_catalog">
                            <div className="af-class-title-black-filter-p_catalog">Регион</div>
                            <div className="w-form">
                              <div className="af-class-form-one_column">
                                <FilterField filter={{ id: 20, option: 'Азия и Океания' }} />
                                <FilterField filter={{ id: 20, option: 'Африка' }} />
                                <FilterField filter={{ id: 20, option: 'Центральная Америка' }} />
                                <FilterField filter={{ id: 20, option: 'Южная Америка' }} />
                              </div>
                            </div>
                          </div> */}

                          <div className="af-class-block-filter-p_catalog">
                            <div className="af-class-title-black-filter-p_catalog">Обработка</div>
                            <div className="w-form">
                              <div className="af-class-form-one_column">
                                <FilterField filter={{ id: 21, option: 'Натуральная (natural process)' }} />
                                <FilterField filter={{ id: 21, option: 'Мытая (washed process)' }} />
                                <FilterField filter={{ id: 21, option: 'Экспериментальная' }} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="af-class-buttons-filter-wrapper">
                          <div onClick={handleFilter} className="af-class-button-accept-filter">Применить</div>
                          <div onClick={handleFiltersClear} style={{ color: `${this.state.filters.length !== 0  ? '#1d71b8' : '#bcbcbc'}` }} className="af-class-button-reset-filter">Сбросить все фильтры</div>
                        </div></> }

                        <CatalogSortMobile setParentSort={this.setSort} showFiltersButton={( this.state.categorySlug != 'kofe-v-kapsulah' && this.state.categorySlug != 'drips')} filtersMobileToggle={this.filtersMobileToggle} />

                        <div className="w-dyn-list af-class-grid-cards-p_catalog">
                          {products}
                          {products.length === 0 && <div className="w-dyn-empty" style={{background: 'none', color: '#1d71b8', fontWeight: 'bold'}}>
                            <div>{this.state.productsLoading == true ? 'Каталог загружается...' : 'Ничего не найдено.'}</div>
                          </div>}
                        </div>

                        {/* <div className="af-class-button-outlined af-class-button_load_more-p_catalog">
                          <div className="af-class-text-button-filter">Показать еще</div>
                        </div> */}
                      </div>
                      
                    </div>
                  </div>

                  <CatalogSortDesktop setParentSort={this.setSort} />

                </div>
              </div>
            </div>
          </>
    )
  }
}

export default withLocation(CatalogView)

/* eslint-enable */