import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../contexts/MainContext'
import useProduct from '../../hooks/useProduct'
import useCart from '../../hooks/useCart.js'

const CartItem = (props) => {
    const navigate = useNavigate()

    const mainContext = useContext(MainContext)

    const { setCart } = useCart()

    const productHandle = useProduct(props)

    const handleRemoval = () => {
        setCart(mainContext.cartItems.filter(elem => !(elem.id == props.id && elem.variationId == props.variationId)))
    }

    const SummaryItemButtons = () => {
        return <>
            {/* <a href="#" className="af-class-cart__summary-item-delete-button af-class-_2" onClick={handleRemoval}>Удалить</a> */}

            { (props.categories.find(elem => elem.id == 17) && !props.categories.find(elem => elem.id == 416)) ? <>
                        
                { (productHandle.weightsVariations && productHandle.weightsVariations.length > 0) && <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" className="af-class-g-select--small af-class-coffee-weight w-dropdown" style={{ zIndex: productHandle.weightDropdown === false ? 0 : '1000' }}>
                    <div className="af-class-g-select--small__toggle w-dropdown-toggle" onClick={() => productHandle.setWeightDropdown(!productHandle.weightDropdown)}>
                        <div className="af-class-g-select--small__text">{productHandle.currentProduct.cartWeight}</div>
                        {/* <img loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" /> */}
                    </div>
                    {/* <nav className="af-class-g-select--small__list w-dropdown-list" style={{ display: productHandle.weightDropdown === false ? 'none' : 'block' }}>
                        { productHandle.weightsVariations.map((value, index) => {
                        return <a key={index} className="af-class-g-select--small__link w-dropdown-link" onClick={() => productHandle.handleChangeWeight(value)}>{value}</a>
                        }) }
                    </nav> */}
                </div> }
                
                { (productHandle.grindsVariations && productHandle.grindsVariations.length > 0) && <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" className="af-class-g-select--small af-class-coffee-type w-dropdown" style={{ zIndex: productHandle.typeDropdown === false ? 0 : '1000' }}>
                    <div className="af-class-g-select--small__toggle w-dropdown-toggle" onClick={() => productHandle.setTypeDropdown(!productHandle.typeDropdown)}>
                        <div className="af-class-g-select--small__text">{productHandle.currentProduct.cartType}</div>
                        {/* <div className="af-class-g-select--small__icon-wrapper">
                            <img loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                        </div> */}
                    </div>
                    {/* <nav className="af-class-g-select--small__list w-dropdown-list" style={{ display: productHandle.typeDropdown === false ? 'none' : 'block' }}>
                        { productHandle.grindsVariations.map((value, index) => {
                        return <a key={index} className="af-class-g-select--small__link w-dropdown-link" onClick={() => productHandle.handleChangeType(value)}>{value}</a>
                        }) }
                    </nav> */}
                </div> }

                </> : (props?.variations?.length > 0) && <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" className="af-class-g-select--small af-class-coffee-type w-dropdown" style={{ zIndex: productHandle.optionDropdown === false ? 0 : '1000' }}>
                    <div className="af-class-g-select--small__toggle w-dropdown-toggle" onClick={() => productHandle.setOptionDropdown(!productHandle.optionDropdown)}>
                        <div className="af-class-g-select--small__text">{productHandle.currentProduct.cartOption}</div>
                        {/* <div className="af-class-g-select--small__icon-wrapper">
                            <img loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                        </div> */}
                    </div>
                    {/* <nav className="af-class-g-select--small__list w-dropdown-list" style={{ display: productHandle.optionDropdown === false ? 'none' : 'block' }}>
                        { productHandle.grindsVariations.map((value, index) => {
                        return <a key={index} className="af-class-g-select--small__link w-dropdown-link" onClick={() => productHandle.handleChangeType(value)}>{value}</a>
                        }) }
                    </nav> */}
                </div>
            }

            <div className="af-class-cart__summary-item-quantity-button">
                { props.cartQty > 0 ? <>
                <div className="af-class-cart__summary-item-quantity-button--minus" onClick={() => productHandle.handleQtyLowerVariation(props.variationId)}>–</div>
                <div className="af-class-text-button-option_add-card">{props.cartQty}</div>
                <div className="af-class-cart__summary-item-quantity-button--plus" onClick={() => productHandle.handleQtyRaiseVariation(props.variationId)}>+</div>
                </> : <div className="af-class-text-button-option_add-card">1</div> }
            </div>
        </>
    }

    return (<div className="af-class-cart__summary-item">
        <div className="w-layout-grid af-class-cart__summary-item-info">
            {/* <label className="w-checkbox af-class-cart__summary-checkbox">
                <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-cart__summary-checkbox-icon" />
                <input type="checkbox" id="checkbox-2" name="checkbox-2" data-name="Checkbox 2" style={{opacity: 0, position: 'absolute', zIndex: -1}} />
                <span className="af-class-cart__summary-checkbox-label w-form-label" htmlFor="checkbox-2">Checkbox 2</span>
            </label> */}
            <div className="af-class-cart__summary-item-close-button" onClick={handleRemoval}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.87034 16.013L8.94141 8.94194M8.94141 8.94194L16.0125 1.87087M8.94141 8.94194L16.0125 16.013M8.94141 8.94194L1.87034 1.87087" stroke="#999999" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </div>
            <div className="w-layout-grid af-class-cart__summary-item-content">
                <div className="af-class-cart__summary-item-image-wrapper">
                    <img src={props.images[0]?.src} loading="lazy" alt="" className="af-class-cart__summary-item-image" />
                </div>
                <div className="w-layout-grid af-class-cart__summary-item-discription">
                    <div className="af-class-cart__summary-item-title" onClick={() => navigate(`/products/${props.slug}`)} style={{cursor: 'pointer'}}>
                        {props.name}
                    </div>

                    <div className="af-class-cart__summary-item-buttons">
                        <SummaryItemButtons />
                    </div>

                    {/* <a className="af-class-cart__summary-item-delete-button af-class-_4" onClick={handleRemoval}>Удалить</a> */}
                    {/* <div className="af-class-cart__summary-item-ingredients af-class-_1" dangerouslySetInnerHTML={{__html: props.short_description}} /> */}
                </div>
            </div>
            <div className="af-class-cart__summary-item-close-button-mobile" onClick={handleRemoval}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.87034 16.013L8.94141 8.94194M8.94141 8.94194L16.0125 1.87087M8.94141 8.94194L16.0125 16.013M8.94141 8.94194L1.87034 1.87087" stroke="#999999" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </div>
            <div className="w-layout-grid af-class-cart__summary-item-price-wrapper">
                { props.cartPriceOld && <div className="af-class-cart_summary-item-price-value-old">
                    {`${props.cartPriceOld} ₽`}
                </div> }
                <div className="af-class-cart_summary-item-price-value">
                    {`${props.cartPrice} ₽`}
                </div>
            </div>
            {/* <a className="af-class-cart__summary-item-delete-button af-class-_3" onClick={handleRemoval}>Удалить</a> */}
        </div>
        {/* <div className="af-class-cart__summary-item-ingredients af-class-_2" dangerouslySetInnerHTML={{__html: props.short_description}} /> */}
        
        {/* <a className="af-class-cart__summary-item-delete-button" onClick={handleRemoval}>Удалить</a> */}

        <div className="af-class-cart__summary-item-buttons-mobile">
            <SummaryItemButtons />
        </div>

        <div className="w-layout-grid af-class-cart__summary-item-price-wrapper af-class-_2">
            <div className="af-class-cart_summary-item-price-value">
                {`${props.cartPrice} ₽`}
            </div>
            { props.cartPriceOld && <div className="af-class-cart_summary-item-price-value-old">
                {`${props.cartPriceOld} ₽`}
            </div> }
        </div>
    </div>);
}

export default CartItem