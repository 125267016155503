import { useState, useContext } from 'react'
import { MainContext } from '../contexts/MainContext'
import { wooClearCart } from '../fetch/WPAPI'
import useToken from './useToken.js'

export default function useCart() {
  const { token, setToken, nonce, setNonce } = useToken()
  const mainContext = useContext(MainContext)

  const getData = () => {
    const dataString = localStorage.getItem('cartItems')
    const data = JSON.parse(dataString)
    if (data) {
      return data
    } else {
      return []
    }
  }

  const [data, setData] = useState(getData())

  const saveData = data => {
    mainContext.setCartItems(data)
    localStorage.setItem('cartItems', JSON.stringify(data))
    setData(data)

    if (data.length == 0 && token?.token && nonce) {
      wooClearCart(token?.token, nonce)
    }
  }

  const getCustomerData = () => {
    const dataString = localStorage.getItem('cartCustomer')
    const data = JSON.parse(dataString)
    if (data) {
      return data
    } else {
      return {}
    }
  }

  const [customerData, setCustomerData] = useState(getCustomerData())

  const saveCustomerData = data => {
    // mainContext.setCartItems(data)
    localStorage.setItem('cartCustomer', JSON.stringify(data))
    // setCustomerData(data)
  }

  // Created order ID but not paid yet
  const getCartOrderId = () => {
    const dataString = localStorage.getItem('cartOrderId')
    try {
      const data = JSON.parse(dataString)
      return data
    } catch (e) {
      // console.log(dataString)
    }
  }

  const [cartOrderId, setCartOrderId] = useState(getCartOrderId())

  const saveCartOrderId = data => {
    localStorage.setItem('cartOrderId', JSON.stringify(data))
    setCartOrderId(data)
  }

  // Created order ID from the bank
  const getCartOrderBankId = () => {
    const dataString = localStorage.getItem('cartOrderBankId')
    const data = JSON.parse(dataString)
    return data
  }

  const [cartOrderBankId, setCartOrderBankId] = useState(getCartOrderBankId())

  const saveCartOrderBankId = data => {
    localStorage.setItem('cartOrderBankId', JSON.stringify(data))
    setCartOrderBankId(data)
  }

  return {
    cart: data,
    getCart: getData,
    setCart: saveData,
    customerData: customerData,
    setCustomerData: saveCustomerData,
    cartOrderId: cartOrderId,
    setCartOrderId: saveCartOrderId,
    cartOrderBankId: cartOrderBankId,
    setCartOrderBankId: saveCartOrderBankId,
  }
}