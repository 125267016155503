/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Product from '../components/Product/Product'
import { Link } from 'react-router-dom';
import { fetchProducts, fetchProductsMultipleCategories, fetchProductsById } from '../fetch/WPAPI'
import { MainContext } from '../contexts/MainContext'
import { fetchWPItems } from '../fetch/fetchWPItems'
import { useNavigate } from 'react-router-dom';
import { countryCategoriesCollation } from '../scripts/countryCategoriesCollation'

/* 
const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6261fc674c12d792773d3625").then(body => body.text()), isAsync: true },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: true },
  { loading: fetch("https://cdn.jsdelivr.net/npm/@splidejs/splide@3.2.2/dist/js/splide.min.js").then(body => body.text()), isAsync: true },
  { loading: Promise.resolve("function heroSlider(){var p=$(\".p-home--hero-slider\");for(let e=0,r=p.length;e<r;e++)new Splide(p[e],{perPage:1,perMove:1,focus:0,type:\"slide\",gap:\"80px\",arrows:!1,pagination:\"slider\",speed:550,dragAngleThreshold:80,autoWidth:!1,rewind:!1,rewindSpeed:800,waitForTransition:!1,updateOnMove:!0,trimSpace:!1,breakpoints:{991:{perPage:1,gap:\"15px\"},767:{perPage:1,gap:\"15px\"},479:{perPage:1,gap:\"10px\"}}}).mount()}heroSlider();"), isAsync: true },
  { loading: Promise.resolve("function coffeeOfTheWeek(){var a=$(\".coffee-of-the-week\");for(let e=0,p=a.length;e<p;e++)new Splide(a[e],{perPage:3,perMove:1,focus:0,type:\"slide\",gap:\"20px\",arrows:!1,pagination:!1,speed:550,dragAngleThreshold:80,autoWidth:!1,rewind:!1,rewindSpeed:800,waitForTransition:!1,updateOnMove:!0,trimSpace:!1,breakpoints:{991:{perPage:2,gap:\"15px\"},767:{perPage:1,gap:\"15px\"},479:{perPage:1,gap:\"10px\"}}}).mount()}coffeeOfTheWeek();"), isAsync: true },
  { loading: Promise.resolve("function rocketsChoice(){var r=$(\".rockets-choice\");for(let e=0,p=r.length;e<p;e++)new Splide(r[e],{perPage:3,perMove:1,focus:0,type:\"slide\",gap:\"20px\",arrows:!1,pagination:!1,speed:550,dragAngleThreshold:80,autoWidth:!1,rewind:!1,rewindSpeed:800,waitForTransition:!1,updateOnMove:!0,trimSpace:!1,breakpoints:{991:{perPage:2,gap:\"15px\"},767:{perPage:1,gap:\"15px\"},479:{perPage:1,gap:\"10px\"}}}).mount()}rocketsChoice();"), isAsync: true },
] */

const tabsActiveNames = {
  date: 'date',
  country: 'country',
  cooking: 'cooking',
  taste: 'taste',
  height: 'height',
  differentiation: 'differentiation',
  processOfMaking: 'processOfMaking'
}

const withRouter = (Component) => {
  return () => {
    const navigate = useNavigate()
    return <Component navigate={navigate} />
  }
}

class IndexView extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)
    this.state = {
      isSearching: false,
      pageData: {},
      products: [],
      featuredTab: 1,
      hiddenCharsSlider: [],
      subscriptionFormEmail: '',
      subscribed: false,
      schedulePopupOpened: false,
      schedulePopupTab: 1,
      acfLoaded: false,
      productsLoaded: false,
      cookiesAccepted: false,
    }
  }

  handleSubscriptionFormSubmit = (e) => {
    e.preventDefault()

    if (this.state.subscribed === false) {
      if (this.state.subscriptionFormEmail.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)) {
        this.setState({ subscribed: true })

        _rc('send', 'order', {
          'email': this.state.subscriptionFormEmail
        });
      }
    }
  }

  loadProducts = (params = {}) => {
    let catalog = this.context.catalogItems.find(elem => elem.categoryId == 17)
    if (catalog) {
      let newProducts = [...this.state.products, ...catalog.items]
      let uniqueProducts = newProducts.filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i)
      this.setState({ products: uniqueProducts, productsLoaded: true })
    } else {
      fetchProductsMultipleCategories([17, 22])
        .then(result => {
          let newProducts = [...this.state.products, ...result]
          let uniqueProducts = newProducts.filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i)
          this.setState({ products: uniqueProducts, productsLoaded: true })
          this.context.setCatalogItems([
            ...this.context.catalogItems,
            { categoryId: 17, items: result.filter(e => e.categories.find(c => c.id == 17)) },
            { categoryId: 22, items: result.filter(e => e.categories.find(c => c.id == 22)) },
          ])
        })
    }
  }

  componentDidMount() {
    this.loadProducts()

    if (this.context.homePageData?.acf) {
      this.setState({ pageData: this.context.homePageData })

      let product_ids = this.context.homePageData?.acf?.sliderimg?.map(elem => elem?.товар?.ID)
      fetchProductsById(product_ids)
        .then(result => {
          let newProducts = [...this.state.products, ...result]
          let uniqueProducts = newProducts.filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i)
          this.setState({ products: uniqueProducts, acfLoaded: true })
        })
    } else {
      fetchWPItems('https://panel.rockets.coffee/wp-json/wp/v2/pages/2858?acf_format=standard')
        .then(res => {
          this.setState({ pageData: res })
          this.context.setHomePageData(res)

          let product_ids = res?.acf?.sliderimg?.map(elem => elem?.товар?.ID)
          fetchProductsById(product_ids)
            .then(result => {
              let newProducts = [...this.state.products, ...result]
              let uniqueProducts = newProducts.filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i)
              this.setState({ products: uniqueProducts, acfLoaded: true })
            })
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let origin = null
    let orderId = null

    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0] == 'origin') {
        origin = pair[1]
      }
      if (pair[0] == 'orderId') {
        orderId = pair[1]
      }
    }

    if (origin != null && orderId != null) {
      this.props.navigate(`/thank-you?orderBankId=${orderId}`)
    }

    if (this.state.pageData != prevState.pageData) {
      document.title = this.state.pageData?.yoast_head_json?.title
      document.querySelector('meta[name="description"]').setAttribute("content", this.state.pageData?.yoast_head_json?.description)
    }
  }

  onSearchClick() {
    this.setState(prevState => ({ ...prevState, isSearching: true }))
  }

  setActiveTab(activeTab) {
    if (activeTab == this.state.activeTab) {
      this.setState(prevState => ({ ...prevState, activeTab: undefined }))
      return
    }

    this.setState(prevState => ({ ...prevState, activeTab }))
  }

  handleHiddenCharsSlider(char) {
    if (!this.state.hiddenCharsSlider.includes(char)) {
      this.setState({ hiddenCharsSlider: [...this.state.hiddenCharsSlider, char] })
    } else {
      this.setState({ hiddenCharsSlider: this.state.hiddenCharsSlider.filter(elem => elem != char) })
    }
  }

  handleAcceptCookies() {
    this.setState({cookiesAccepted: true});
    localStorage.setItem('cookiesAccepted', 1);
  }

  render() {
    let acf = this.state.pageData?.acf

    const featuredSliderIds = acf?.sliderimg?.map(elem => elem.товар.ID)
    const featuredWeekIds = acf?.товары_на_главной?.find(elem => elem.заголовок_вкладки == 'Кофе недели').товар
    const featuredChosenIds = acf?.товары_на_главной?.find(elem => elem.заголовок_вкладки == 'Выбор Rockets').товар
    const featuredSlider = this.state.products?.filter(p => featuredSliderIds && featuredSliderIds.indexOf(p.id) !== -1)
    const featuredWeek = this.state.products?.filter(p => featuredWeekIds && featuredWeekIds.indexOf(p.id) !== -1)
    const featuredChosen = this.state.products?.filter(p => featuredChosenIds && featuredChosenIds.indexOf(p.id) !== -1)

    let SlideItems = acf?.sliderimg.map((elem, index) => <SplideSlide key={index} className="af-class-splide__slide af-class-p-home--hero-slider">
      <div className="cta-block">
        <div className="cta-text-content">
          <div className="cta-date-text">{elem.дата_акции}</div>
          <div className="cta-heading">{elem.slidezag}</div>
          <div className="cta-product-name">{elem.заголовок_товара}</div>
          <div className="cta-product-price" dangerouslySetInnerHTML={{ __html: elem.tsena }}></div>
          <Link to={`/products/${elem.товар.post_name}`} className="af-class-button cta-banner w-button">Купить</Link>
        </div>
        <img src={featuredSlider.find(p => p.id == elem.товар.ID)?.images[0]?.src} loading="lazy" alt="" className="cta-product-img" />
      </div>
      <div className="slide-r-side">
        <div className="product-info-block">

          {elem.характеристики.map((elem, index) => <div key={index} data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
            <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.handleHiddenCharsSlider(elem.характеристика)}>
              <div className="dropdown-line"></div>
              <div className="dropdown-heading">{elem.характеристика}</div>
            </div>
            <nav className="dropdown-info-block w-dropdown-list" style={{ display: this.state.hiddenCharsSlider.includes(elem.характеристика) ? 'none' : 'block', width: '80%', minWidth: 0 }}>
              <p className="dropdown-info-text">{elem.описание_характеристики}</p>
            </nav>
          </div>)}

        </div>
      </div>
    </SplideSlide>)

    // let SlideItems = acf?.sliderimg?.map(img => {
    //   return <SplideSlide className="af-class-splide__slide af-class-p-home--hero-slider">
    //     <img style={{height: '100%'}} src={img.img.link} alt="" />
    //   </SplideSlide>
    // })

    setTimeout(() => {
      let karta = document.getElementById('w-node-_6980d3cd-4033-b726-8b14-8cee211be66a-683d3628')
      if (karta) {
        karta.className = 'af-class-content-block__img-container--p-country af-class-images_2 af-class-map content-block__img-container--p-country images_2 map'
  
        // karta.getElementsByClassName('content-block__img-wrapper')[0].className = 'af-class-content-block__img-wrapper'
        // karta.getElementsByClassName('content-block__img--p-country')[0].className = 'af-class-content-block__img--p-country'
  
        let countries = karta?.getElementsByClassName('map__label') || []
        let countriesArray = [...countries]
        countriesArray?.forEach((c, index) => {
          // const countryClasses = ['af-class-gonduras', 'af-class-columbia', 'af-class-brazilia', 'af-class-peru', 'af-class-efiopia', 'af-class-uganda', 'af-class-ruanda', 'af-class-kenia', 'af-class-indonesia']
  
          let collated = countryCategoriesCollation.find(e => e.name == c.innerText)
          if (collated && collated.mapClass != '') {
            let classes = ['map__label', 'af-class-map__label-wrapper', collated.mapClass]
            c.classList.add(...classes)
  
            c.addEventListener('click', () => {
              this.props.navigate(`/country/${collated.page}`)
            })
          }
        })
      }
    }, 100)

    return (
      <>
        { (this.state.acfLoaded === false || this.state.productsLoaded === false) ? <div className="loading-animation" style={{marginTop: '100px', marginBottom: '100px'}}><img src="/images/loading-rocket.svg" alt="" /></div> : <>
        {/* <div className="af-class-section af-class-p-home__s-hero af-class-an-load--2 af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="af-class-splide af-class-p-home--hero-slider">
                  <div id="w-node-_7dfb0784-b141-b06b-f0b7-6887bec64e5b-683d3628" className="af-class-splide__track">
                    <Splide>
                      {SlideItems}
                    </Splide>
                  </div>
                  <div bind="7dfb0784-b141-b06b-f0b7-6887bec64e5e" className="af-class-splide__embed w-embed">
                    <style dangerouslySetInnerHTML={{ __html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                  </div>
                </div>
              </div>
            </div> */}

        {/* <div className="sticky-newyear-schedule">
          <div style={{fontSize: '16px', fontWeight: '500', fontFamily: 'Gotham', textTransform: 'uppercase', borderBottom: '1px #D9D9D9 solid', paddingBottom: '5px', marginBottom: '16px'}}>
            <table><tbody><tr><td><img src="/images/calendar_icon.png" /></td><td style={{paddingLeft: '10px', paddingTop: '2px'}}>График работы в праздники</td></tr></tbody></table></div>
          <a style={{color: '#1d71b8', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.setState({schedulePopupOpened: true})}>Посмотреть</a>
        </div> */}
        
        { (this.state.cookiesAccepted !== true && localStorage.getItem('cookiesAccepted') !== '1') && <div className="sticky-newyear-schedule" style={{maxWidth: '550px'}}>
          <div style={{fontSize: '16px', fontWeight: '500', fontFamily: 'Gotham', textTransform: 'uppercase', borderBottom: '1px #D9D9D9 solid', paddingBottom: '5px', marginBottom: '16px'}}>
            <table><tbody><tr><td>Мы используем файлы cookies</td></tr></tbody></table>
          </div>
          <div style={{marginBottom: '22px'}}>Мы&nbsp;используем файлы cookies, чтобы сделать сайт удобным для вас. Продолжая использование сайта, вы&nbsp;соглашаетесь с&nbsp;этим.<br />Подробности&nbsp;&mdash; в&nbsp;<a onClick={() => this.props.navigate(`/user-agreement`)} style={{ color: "#1d71b8", textDecoration: "underline", cursor: "pointer" }}>пользовательском соглашении</a>.</div>
          <div style={{ padding: '20px 40px', background: '#1D71B8', borderRadius: '99px', color: '#fff', textTransform: 'uppercase', textAlign: 'center', fontFamily: 'Gotham', fontWeight: 700, fontSize: '14px', cursor: 'pointer' }} onClick={() => this.handleAcceptCookies()}>Понятно</div>
        </div> }

        <div style={{ display: this.state.schedulePopupOpened === true ? 'flex' : 'none', zIndex: '2147483647' }} className="af-class-popup--p-reviews">
          <div data-w-id="a727d6a9-0d07-a7b0-0c61-5f2c1a7caed1" className="af-class-div-block-13" />
          <div className="af-class-popup--p-reviews__content" style={{ padding: '30px', overflowY: 'scroll', maxHeight: '100%' }}>
            <div className="af-class-popup--p-reviews__title">График работы в праздники</div>
            {/* <div style={{ display: 'flex', fontFamily: 'Gotham', fontSize: '18px', fontWeight: 500, marginBottom: '20px' }}>
              <div style={{ paddingRight: '15px', color: this.state.schedulePopupTab == 1 ? '#1D71B8' : '#999', borderBottom: this.state.schedulePopupTab == 1 ? '2px #1D71B8 solid' : '2px #999 solid', cursor: 'pointer', paddingBottom: '3px' }} onClick={() => this.setState({ schedulePopupTab: 1 })}>Доставка курьером и СДЭК</div>
              <div style={{ paddingLeft: '15px', color: this.state.schedulePopupTab == 2 ? '#1D71B8' : '#999', borderBottom: this.state.schedulePopupTab == 2 ? '2px #1D71B8 solid' : '2px #999 solid', cursor: 'pointer', paddingBottom: '3px' }} onClick={() => this.setState({ schedulePopupTab: 2 })}>Самовывоз из кофеен</div>
            </div> */}
            <div className="schedule-popup-content">
              <table>
                <thead>
                  <tr><td>Заказ</td><td align="right">Доставка</td></tr>
                </thead>
                {this.state.schedulePopupTab == 1 ? <tbody>
                  <tr><td>27 апреля до 14:00</td><td align="right">28 апреля - 1 мая</td></tr>
                  <tr><td>27 апреля после 14:00 - 2 мая до 14:00</td><td align="right">с 3 мая</td></tr>
                  <tr><td>3 мая до 14:00</td><td align="right">4-6 мая</td></tr>
                  <tr><td>3 мая после 14:00 - 6 мая до 14:00</td><td align="right">7 мая</td></tr>
                  <tr><td>8 мая до 14:00</td><td align="right">9-13 мая</td></tr>
                  <tr><td>8 мая после 14:00 - 13 мая до 14:00</td><td align="right">14 мая</td></tr>
                </tbody> : <tbody>
                  <tr><td>28 апреля до 14:00</td><td align="right">с 2 мая</td></tr>
                  <tr><td>28 апреля после 14:00 - 2 мая до 14:00</td><td align="right">с 3 мая</td></tr>
                  <tr><td>5 мая до 14:00</td><td align="right">с 10 мая</td></tr>
                  <tr><td>5 мая после 14:00 - 10 мая до 14:00</td><td align="right">с 11 мая</td></tr>
                </tbody>}
              </table>
              {/* <div style={{ fontFamily: 'Gotham', fontWeight: 500, fontSize: '16px', color: '#1D71B8', marginTop: '20px' }}>с 11 марта работаем в обычном режиме</div> */}
              <div style={{ padding: '20px 40px', background: '#1D71B8', borderRadius: '99px', color: '#fff', textTransform: 'uppercase', textAlign: 'center', marginTop: '30px', fontFamily: 'Gotham', fontWeight: 700, fontSize: '14px', cursor: 'pointer' }} onClick={() => this.setState({ schedulePopupOpened: false })}>Понятно</div>
            </div>
            <div onClick={() => this.setState({ schedulePopupOpened: false })} data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf3522" className="af-class-popup--p-reviews__close-icon-wrapper">
              <img src="/images/icon--close-popup.svg" loading="lazy" alt="" className="af-class-image-7" />
            </div>
          </div>
        </div>


        <div className="af-class-section af-class-p-home__s-hero af-class-an-load--2 af-class-wf-section">
          <div className="af-class-container w-container">
            <div className="af-class-splide af-class-p-home--hero-slider">
              <div id="w-node-_7dfb0784-b141-b06b-f0b7-6887bec64e5b-683d3628" className="af-class-splide__track index-page-splide-track">
                <Splide options={{ type: 'loop', arrows: false, autoplay: true, interval: 5000, pauseOnHover: false }}>

                  {SlideItems ? SlideItems : <SplideSlide />}

                  {/* <SplideSlide className="af-class-splide__slide af-class-p-home--hero-slider">
                          <div className="cta-block">
                            <div className="cta-text-content">
                              <div className="cta-date-text">С 11  по 20 ноября</div>
                              <div className="cta-heading">Успей купить со скидкой 25%</div>
                              <div className="cta-product-name">Кофе Espresso #1</div>
                              <div className="cta-product-price">1 кг — 1 339 руб <span className="cta-product-price-compare">1673 руб</span></div>
                              <Link to="/catalog-item" className="af-class-button cta-banner w-button">КУпить</Link>
                            </div><img src="images/rocket-coffe-pack02.png" loading="lazy" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" sizes="100vw" alt="" className="cta-product-img" />
                          </div>
                        <div className="slide-r-side">
                          <div className="product-info-block">
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.date)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Дата обжарки</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.date ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.country)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Страна производства</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.country ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.cooking)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Обжарка</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.cooking ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.taste)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Описание вкуса</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.taste ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.height)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Высота</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.height ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.differentiation)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Разновидность</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.differentiation ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.processOfMaking)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Обработка</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.processOfMaking ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </SplideSlide>
                      <SplideSlide className="af-class-splide__slide af-class-p-home--hero-slider">
                          <div className="cta-block">
                            <div className="cta-text-content">
                              <div className="cta-date-text">С 11  по 20 ноября</div>
                              <div className="cta-heading">Успей купить со скидкой 25%</div>
                              <div className="cta-product-name">Кофе Espresso #1</div>
                              <div className="cta-product-price">1 кг — 1 339 руб <span className="cta-product-price-compare">1673 руб</span></div>
                              <Link to="/catalog-item" className="af-class-button cta-banner w-button">КУпить</Link>
                            </div><img src="images/rocket-coffe-pack02.png" loading="lazy" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" sizes="100vw" alt="" className="cta-product-img" />
                          </div>
                        <div className="slide-r-side">
                          <div className="product-info-block">
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.date)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Дата обжарки</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.date ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.country)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Страна производства</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.country ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.cooking)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Обжарка</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.cooking ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.taste)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Описание вкуса</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.taste ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.height)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Высота</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.height ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.differentiation)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Разновидность</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.differentiation ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.processOfMaking)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Обработка</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.processOfMaking ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </SplideSlide>
                      <SplideSlide className="af-class-splide__slide af-class-p-home--hero-slider">
                          <div className="cta-block">
                            <div className="cta-text-content">
                              <div className="cta-date-text">С 11  по 20 ноября</div>
                              <div className="cta-heading">Успей купить со скидкой 25%</div>
                              <div className="cta-product-name">Кофе Espresso #1</div>
                              <div className="cta-product-price">1 кг — 1 339 руб <span className="cta-product-price-compare">1673 руб</span></div>
                              <Link to="/catalog-item" className="af-class-button cta-banner w-button">КУпить</Link>
                            </div><img src="images/rocket-coffe-pack02.png" loading="lazy" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" sizes="100vw" alt="" className="cta-product-img" />
                          </div>
                        <div className="slide-r-side">
                          <div className="product-info-block">
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.date)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Дата обжарки</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.date ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.country)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Страна производства</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.country ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.cooking)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Обжарка</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.cooking ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.taste)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Описание вкуса</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.taste ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.height)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Высота</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.height ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.differentiation)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Разновидность</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.differentiation ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
                              <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.processOfMaking)}>
                                <div className="dropdown-line"></div>
                                <div className="dropdown-heading">Обработка</div>
                              </div>
                              <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.processOfMaking ? {display: 'block'}: {display: 'none'}}>
                                <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </SplideSlide> */}
                </Splide>
              </div>
              <div bind="7dfb0784-b141-b06b-f0b7-6887bec64e5e" className="af-class-splide__embed w-embed">
                <style dangerouslySetInnerHTML={{ __html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
              </div>
            </div>
          </div>
        </div>



        <div className="af-class-section af-class-an-load--3 af-class-wf-section" style={{ marginBottom: '50px' }}>
          <div className="af-class-container w-container">
            {/* <div className="w-layout-grid af-class-subtitle-wrapper--p-home">
                  <div id="w-node-a210c03e-5b3e-9f44-bc25-487ce7b3de54-683d3628" className="af-class-subtitle--p-home">{acf?.textpodslider || ''}</div>
                </div> */}
            <div data-current="Tab 2" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-cofee-slider w-tabs">
              <div className="af-class-cofee-slider__menu w-tab-menu">
                <a data-w-tab="Tab 1" onClick={() => this.setState({ featuredTab: 1 })} className={`af-class-cofee-slider__tab-link w-inline-block w-tab-link${this.state.featuredTab == 1 ? ' w--current' : ''}`}>
                  <div>Выбор недели</div>
                </a>
                <a data-w-tab="Tab 2" onClick={() => this.setState({ featuredTab: 2 })} className={`af-class-cofee-slider__tab-link w-inline-block w-tab-link${this.state.featuredTab == 2 ? ' w--current' : ''}`}>
                  <div>Новинки</div>
                </a>
              </div>
              <div className="af-class-cofee-slider__tabs af-class-coffee-of-the-week w-tab-content">
                <div data-w-tab="Tab 1" className={`w-tab-pane${this.state.featuredTab == 1 ? ' w--tab-active' : ''}`} style={{ display: this.state.featuredTab == 1 ? 'grid' : 'none', gridColumnGap: '15px', gridRowGap: '15px', gridTemplateColumns: '1fr 1fr 1fr' }}>

                  <div style={{ gridColumn: '1 / -1' }}>
                    <Splide className="af-class-splide af-class-splide__country-page splide-desktop-arrows-hidden" options={{ perPage: 3, perMove: 1, gap: '15px', breakpoints: { 991: { perPage: 1 } } }}>
                      {featuredWeek.map((elem, index) => <SplideSlide className="af-class-splide__slide" key={index}><Product key={index} {...elem} style={{ width: '100%' }} styleContainer={{ width: '100%', height: '100%' }} /></SplideSlide>)}
                    </Splide>
                  </div>

                  {/* <div className="af-class-slider-wrapper af-class-coffee-of-the-week"> */}
                  {/* <div className="af-class-splide af-class-coffee-of-the-week"> */}
                  {/* <div id="w-node-_11006dec-526d-6893-6ab0-e10ed29cdab0-683d3628" className="af-class-coffee-of-the-week"> */}
                  {/* <div className="af-class-coffee-of-the-week"> */}
                  {/* { featuredWeek.map((elem, index) => <Product key={elem.id} {...elem} />)} */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
                <div data-w-tab="Tab 2" className={`w-tab-pane${this.state.featuredTab == 2 ? ' w--tab-active' : ''}`} style={{ display: this.state.featuredTab == 2 ? 'grid' : 'none', gridColumnGap: '15px', gridRowGap: '15px', gridTemplateColumns: '1fr 1fr 1fr' }}>

                  <div style={{ gridColumn: '1 / -1' }}>
                    <Splide className="af-class-splide af-class-splide__country-page splide-desktop-arrows-hidden" options={{ perPage: 3, perMove: 1, gap: '15px', breakpoints: { 991: { perPage: 1 } } }}>
                      {featuredChosen.map((elem, index) => <SplideSlide className="af-class-splide__slide" key={index}><Product key={index} {...elem} style={{ width: '100%' }} styleContainer={{ width: '100%', height: '100%' }} /></SplideSlide>)}
                    </Splide>
                  </div>

                  {/* <div className="af-class-slider-wrapper af-class-rockets-choice"> */}
                  {/* <div className="af-class-splide af-class-rockets-choice"> */}
                  {/* <div bind="a6f07ecf-1ef7-2df1-37bc-607e6b0a6c64" className="af-class-splide__track af-class-rockets-choice w-dyn-list"> */}
                  {/* <div bind="a6f07ecf-1ef7-2df1-37bc-607e6b0a6c65" role="list" className="af-class-splide__list af-class-rockets-choice w-dyn-items"> */}
                  {/* <div bind="a6f07ecf-1ef7-2df1-37bc-607e6b0a6c66" role="listitem" className="af-class-splide__slide af-class-p-product w-dyn-item"> */}
                  {/* { featuredChosen.map((elem, index) => <Product key={elem.id} {...elem} />)} */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="af-class-section af-class-wf-section">
          <div className="af-class-container w-container">
            <div className="w-layout-grid af-class-home-content">
              <div id="w-node-e9d2c326-54f4-6588-609e-efd3a9648a98-683d3628" className="w-layout-grid af-class-home-content af-class-about">
                <h1 id="w-node-_5f843d07-28e0-1b04-fc05-01e8eb19276e-683d3628" className="af-class-home-content__title">{acf?.zagolovok1 || ''}</h1>
                {/* <div id="w-node-_36e58dc7-2c77-5124-d16e-25459bada423-683d3628" className="w-layout-grid af-class-home-content__discription-wrapper af-class-an-scroll-into-view">
                      <h3 className="af-class-home-content__discription-title">{acf?.opisanie1.split('\n')[0] || ''}</h3>
                      <div className="af-class-home-content__discription-text">{acf?.opisanie1.split('\n').slice(1)}</div>
                    </div>
                    <div className='af-class-home-content__karta' id='w-node-_6980d3cd-4033-b726-8b14-8cee211be66a-683d3628' dangerouslySetInnerHTML={{ __html: acf?.karta1 }}></div> */}
              </div>
              <div id="w-node-ab59ba5d-24ca-0cde-6f71-5021e7788676-683d3628" className="w-layout-grid af-class-home-content af-class-rosters">
                <h3 id="w-node-ab59ba5d-24ca-0cde-6f71-5021e7788677-683d3628" className="af-class-home-content__discription-title af-class-an-scroll-into-view" dangerouslySetInnerHTML={{ __html: acf?.zag01 || '' }}></h3>
                <div id="w-node-ab59ba5d-24ca-0cde-6f71-5021e7788679-683d3628" className="af-class-home-content__discription-text af-class-small af-class-an-scroll-into-view">{acf?.opisanie01 || ''}</div>
                <div id="w-node-_203fbf50-e6f1-8ac6-2485-512c9b0a8f34-683d3628" className="w-layout-grid af-class-home-content">
                  <div id="w-node-_945e9873-aa8f-5616-2f6c-28d32aaa057e-683d3628" className="af-class-home-content__img-wrapper"><img src={acf?.imgimg1[0]?.img?.link} loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" alt="" className="af-class-home-content__img af-class-an-image--scroll-into-view" /></div>
                  <div className="af-class-home-content__img-wrapper af-class-_2"><img src={acf?.imgimg1[1]?.img?.link} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" alt="" className="af-class-home-content__img af-class-an-image--scroll-into-view" /></div>
                </div>
              </div>
              <div id="w-node-_7fd96e32-1d09-9dc6-7b4d-43095332b1c6-683d3628" className="w-layout-grid af-class-home-content af-class-with-pictures af-class-an-scroll-into-view">
                <h3 id="w-node-_7fd96e32-1d09-9dc6-7b4d-43095332b1c7-683d3628" className="af-class-home-content__discription-title">{acf?.zag02 || ''}</h3>
                <div id="w-node-_7fd96e32-1d09-9dc6-7b4d-43095332b1c9-683d3628" className="af-class-home-content__discription-text af-class-small">{acf?.opisanie02 || ''}</div>
                <div id="w-node-_7fd96e32-1d09-9dc6-7b4d-43095332b1cb-683d3628" className="w-layout-grid af-class-home-content">
                  <div id="w-node-_7fd96e32-1d09-9dc6-7b4d-43095332b1cc-683d3628" className="af-class-home-content__img-wrapper af-class-big"><img src={acf?.imgimg2[0]?.img?.link} loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" alt="" className="af-class-home-content__img af-class-an-image--scroll-into-view" /></div>
                </div>
              </div>
              <div id="w-node-abf24263-b906-52b5-3192-69daf79f986f-683d3628" className="w-layout-grid af-class-home-content af-class-with-pictures af-class-an-scroll-into-view">
                <h3 style={{ marginTop: '90px' }} id="w-node-abf24263-b906-52b5-3192-69daf79f9870-683d3628" className="af-class-home-content__discription-title">{acf?.zag03 || ''}</h3>
                <div style={{ marginBottom: '80px' }} id="w-node-abf24263-b906-52b5-3192-69daf79f9872-683d3628" className="af-class-home-content__discription-text af-class-small">{acf?.opisanie03 || ''}</div>
                <div id="w-node-cdc27a84-3a07-6630-8e90-52e512b83b12-683d3628" className="w-layout-grid af-class-home-content">
                  <div id="w-node-cdc27a84-3a07-6630-8e90-52e512b83b13-683d3628" className="af-class-home-content__img-wrapper"><img src={acf?.imgimg3[0]?.img?.link} loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" alt="" className="af-class-home-content__img af-class-an-image--scroll-into-view" /></div>
                  <div className="af-class-home-content__img-wrapper af-class-_2"><img src={acf?.imgimg3[1]?.img?.link} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" alt="" className="af-class-home-content__img af-class-an-image--scroll-into-view" /></div>
                </div>
              </div>
              <div id="w-node-ef02c7ee-591e-e12e-369f-b9dc5fd0f36d-683d3628" className="w-layout-grid af-class-home-content af-class-with-pictures af-class-an-scroll-into-view">
                <h3 style={{ marginTop: '90px' }} id="w-node-ef02c7ee-591e-e12e-369f-b9dc5fd0f36e-683d3628" className="af-class-home-content__discription-title">{acf?.zag04 || ''}</h3>
                <div style={{ marginBottom: '40px' }} id="w-node-ef02c7ee-591e-e12e-369f-b9dc5fd0f370-683d3628" className="af-class-home-content__discription-text af-class-small">{acf?.opisanie04 || ''}</div>
              </div>

              <div id="w-node-e9d2c326-54f4-6588-609e-efd3a9648a98-683d3628" className="w-layout-grid af-class-home-content af-class-about">
                {/* <h1 id="w-node-_5f843d07-28e0-1b04-fc05-01e8eb19276e-683d3628" className="af-class-home-content__title">{acf?.zagolovok1 || ''}</h1> */}
                {/* <div id="w-node-_36e58dc7-2c77-5124-d16e-25459bada423-683d3628" className="w-layout-grid af-class-home-content__discription-wrapper af-class-an-scroll-into-view">
                      <h3 className="af-class-home-content__discription-title">{acf?.opisanie1.split('\n')[0] || ''}</h3>
                      <div className="af-class-home-content__discription-text">{acf?.opisanie1.split('\n').slice(1)}</div>
                    </div> */}
                <div className='af-class-content-block__img-container--p-country af-class-images_2 af-class-map' id='w-node-_6980d3cd-4033-b726-8b14-8cee211be66a-683d3628'>
                  <div className="af-class-content-block__img-wrapper" style={{ paddingTop: 0 }} dangerouslySetInnerHTML={{ __html: acf?.karta1 }} />
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="af-class-wf-section">
          <div id="w-node-fe06b389-281f-5e6a-6c59-11c03baaff30-683d3628" className="w-layout-grid af-class-home-content af-class-big-coffee">
            <div id="w-node-_051dd4b2-a2a9-1c9f-71ed-e8c3de77e08b-683d3628" className="af-class-white-background" />
            <div id="w-node-d2259409-2fdb-de34-134b-619853d90460-683d3628" className="af-class-big-image-wrapper product-example-index-page-desktop">
              <img src={acf?.imgimg4[0]?.img?.link} loading="lazy" alt="" className="af-class-big-image af-class-an-image--scroll-into-view" />
            </div>
            <div id="w-node-d2259409-2fdb-de34-134b-619853d90460-683d3628" className="af-class-big-image-wrapper product-example-index-page-mobile">
              <img src="/images/product-example.png" loading="lazy" alt="" className="af-class-big-image af-class-an-image--scroll-into-view" />
            </div>
          </div>
        </div>
        <div className="af-class-section af-class-background-white af-class-wf-section">
          <div className="af-class-container w-container">
            <div className="w-layout-grid af-class-home-content">
              <div id="w-node-_81c84b29-b6b1-1b40-6908-f32835c15dd2-683d3628" className="w-layout-grid af-class-home-content af-class-with-pictures af-class-an-scroll-into-view">
                <h3 id="w-node-_81c84b29-b6b1-1b40-6908-f32835c15dd3-683d3628" className="af-class-home-content__discription-title">{acf?.zag05 || ''}</h3>
                <div style={{ marginBottom: '40px' }} id="w-node-_81c84b29-b6b1-1b40-6908-f32835c15dd5-683d3628" className="af-class-home-content__discription-text af-class-small">{acf?.opisanie05 || ''}</div>
              </div>
              <div id="w-node-_81c84b29-b6b1-1b40-6908-f32835c15dd7-683d3628" className="w-layout-grid af-class-home-content">
                <div id="w-node-_81c84b29-b6b1-1b40-6908-f32835c15dd8-683d3628" className="af-class-home-content__img-wrapper"><img src={acf?.imgimg5[0]?.img?.link} loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" alt="" className="af-class-home-content__img af-class-an-image--scroll-into-view" /></div>
                <div className="af-class-home-content__img-wrapper af-class-_2"><img src={acf?.imgimg5[1]?.img?.link} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" alt="" className="af-class-home-content__img af-class-an-image--scroll-into-view" /></div>
              </div>
              <div id="w-node-b2525f31-1414-a6f1-73b7-40311073aa5e-683d3628" className="w-layout-grid af-class-home-form af-class-an-scroll-into-view" style={{ marginBottom: '50px' }}>
                <h3 id="w-node-_7a89c366-c1d4-f304-b034-ac6e0951109b-683d3628" className="af-class-home-form__title">Узнавайте о наших новостях первыми!</h3>
                {this.state.subscribed === false ? <div id="w-node-_09cc0749-6307-baea-bea5-4284c6b9281b-683d3628" className="af-class-home-form__form-block w-form">
                  <form onSubmit={this.handleSubscriptionFormSubmit} action="#" className="af-class-home-form__form">
                    <input type="email" className="af-class-home-form__text-field w-input" maxLength={256} name="email" data-name="Email" placeholder="Адрес электронной почты" id="email" required value={this.state.subscriptionFormEmail} onChange={(e) => this.setState({ subscriptionFormEmail: e.target.value })} />
                    <input type="submit" value="" className="af-class-submit-button af-class-jome-form__submit-button w-button" />
                  </form>
                </div> : <div>Спасибо за подписку!</div>}
                <div id="w-node-_27b6556e-7d2c-568a-f16f-471b5258aeec-683d3628" className="af-class-home-form__agreement-text">Я даю согласие на получение материалов о продуктах, новостях и акциях, на условии <a href="#" onClick={() => this.props.navigate(`/user-agreement`)} style={{ color: '#1d71b8', textDecoration: 'underline' }}>политики конфиденциальности</a></div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="af-class-section af-class-background-white af-class-s-instagram af-class-wf-section">
              <div className="af-class-container w-container">
                <div bind="fe6a81d4-0a4d-733f-eaea-52094aa78d19" className="af-class-insta__content-wrap">
                  <h2 className="af-class-h2">
                    <a href="#" className="af-class-link">@rockets.roasters</a>
                  </h2>
                  <div className="w-layout-grid af-class-insta-blocks__wrap">
                    <div className="af-class-insta-block"><img src="images/ista-post03_1ista-post03.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post03_1-p-500.jpeg 500w, images/ista-post03_1ista-post03.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                    <div className="af-class-insta-block"><img src="images/ista-post01.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post01-p-500.jpeg 500w, images/ista-post01.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                    <div className="af-class-insta-block"><img src="images/ista-post04_1ista-post04.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post04_1-p-500.jpeg 500w, images/ista-post04_1ista-post04.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                    <div className="af-class-insta-block"><img src="images/ista-post02_1ista-post02.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post02_1-p-500.jpeg 500w, images/ista-post02_1ista-post02.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                  </div>
                </div>
              </div>
            </div> */}
        </> }
      </>
    )
  }
}

export default withRouter(IndexView)

/* eslint-enable */

//slide item
{/* <div className="cta-block">
<div className="cta-text-content">
  <div className="cta-date-text">{s.date}</div>
  <div className="cta-heading">{s.heading}</div>
  <div className="cta-product-name">{s.name}</div>
  <div className="cta-product-price">{s.kiloPrice} <span className="cta-product-price-compare">{s.oldPrice}</span></div>
  <Link to="/catalog-item" className="af-class-button cta-banner w-button">{s.buy}</Link>
</div><img src="images/rocket-coffe-pack02.png" loading="lazy" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" sizes="100vw" alt="" className="cta-product-img" />
</div>
<div className="slide-r-side">
<div className="product-info-block">
  <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
    <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.date)}>
      <div className="dropdown-line"></div>
      <div className="dropdown-heading">Дата обжарки</div>
    </div>
    <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.date ? { display: 'block' } : { display: 'none' }}>
      <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
    </nav>
  </div>
  <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
    <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.country)}>
      <div className="dropdown-line"></div>
      <div className="dropdown-heading">Страна производства</div>
    </div>
    <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.country ? { display: 'block' } : { display: 'none' }}>
      <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
    </nav>
  </div>
  <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
    <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.cooking)}>
      <div className="dropdown-line"></div>
      <div className="dropdown-heading">Обжарка</div>
    </div>
    <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.cooking ? { display: 'block' } : { display: 'none' }}>
      <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
    </nav>
  </div>
  <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
    <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.taste)}>
      <div className="dropdown-line"></div>
      <div className="dropdown-heading">Описание вкуса</div>
    </div>
    <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.taste ? { display: 'block' } : { display: 'none' }}>
      <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
    </nav>
  </div>
  <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
    <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.height)}>
      <div className="dropdown-line"></div>
      <div className="dropdown-heading">Высота</div>
    </div>
    <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.height ? { display: 'block' } : { display: 'none' }}>
      <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
    </nav>
  </div>
  <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
    <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.differentiation)}>
      <div className="dropdown-line"></div>
      <div className="dropdown-heading">Разновидность</div>
    </div>
    <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.differentiation ? { display: 'block' } : { display: 'none' }}>
      <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
    </nav>
  </div>
  <div data-hover="false" data-delay="0" className="product-dropdown w-dropdown">
    <div className="product-dropdown-toggle w-dropdown-toggle" onClick={() => this.setActiveTab(tabsActiveNames.processOfMaking)}>
      <div className="dropdown-line"></div>
      <div className="dropdown-heading">Обработка</div>
    </div>
    <nav className="dropdown-info-block w-dropdown-list" style={this.state.activeTab == tabsActiveNames.processOfMaking ? { display: 'block' } : { display: 'none' }}>
      <p className="dropdown-info-text">Дата обжарки очень важна, вы всегда узнаете свежесть зерна мы рекомендуем заваривать зерно не старше полутора месяцев, чтобы максимально раскрыть вкус кофе.</p>
    </nav>
  </div>
</div>
</div> */}