import React from 'react'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidCatch(e) {
        console.log(e)
    }

    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}