import { useState } from "react"

const CatalogSortDesktop = (props) => {
  const [ dropdown, setDropdown ] = useState(false)
  const [ sort, setSort ] = useState({ id: 0, name: 'По умолчанию' })

  const handleFilterChange = (value) => {
    setDropdown(false)
    setSort(value)
    props.setParentSort(value.id)
  }
  
  return <div className="af-class-sort-p_catalog">
    {/* <div className="af-class-title-sort">Сортировать по:</div> */}
    <div data-hover="false" data-delay={0} className="af-class-dropdown w-dropdown">
        <div className="af-class-dropdown-toggle-2 w-dropdown-toggle" onClick={() => setDropdown(!dropdown)}>
          <div className="af-class-text-dropdown">{sort.name}</div>
          <img src="/images/arrow-down.svg" loading="lazy" alt="" className="af-class-icon-dropdown" />
        </div>
        <nav className="w-dropdown-list" style={{display: dropdown === true ? ' block' : 'none'}}>
          <div className="af-class-dropdown-list" style={{ right: 0, left: 'auto' }}>
            <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 0, name: 'По умолчанию' })}>По умолчанию</a>
            <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 1, name: 'Цена: по возрастанию' })}>Цена: по возрастанию</a>
            <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 2, name: 'Цена: по убыванию' })}>Цена: по убыванию</a>
            <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 3, name: 'Сначала новые' })}>Сначала новые</a>
            <a href="#" className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleFilterChange({ id: 4, name: 'Сначала старые' })}>Сначала старые</a>
          </div>
        </nav>
    </div>
  </div>
}

export default CatalogSortDesktop