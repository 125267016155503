/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { createScope, map, transformProxies } from './helpers'
import { useNavigate } from 'react-router-dom'
import { fetchCustomPosts, fetchPosts } from '../fetch/WPAPI'

const EducationAllView = (props) => {

  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()

  const [ posts, setPosts ] = useState([])

  const [ searchQuery, setSearchQuery ] = useState('')

  useEffect(() => {
    document.title = 'Обучение'
    document.querySelector('meta[name="description"]').setAttribute("content", 'Обучение')
  }, [])

  useEffect(() => {
    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0] == 'search') {
        if (searchQuery != pair[1]) {
          setSearchQuery(pair[1])
        }
      }
    }
  }, [window.location.search])

  useEffect(() => {
    console.log(searchQuery)
    fetchCustomPosts('obuchenie', searchQuery)
      .then(result => {
        console.log(result)
        setPosts(result)

        setIsLoading(false)
      })
  }, [searchQuery])

  return (
    
          <div className="af-class-section af-class-an-load--2 af-class-wf-section">
            <div className="af-class-container w-container">
              <div className="w-layout-grid af-class-education--all__content">
                <h1 className="af-class-page-title">Курсы и мастер-классы</h1>

                <div className="w-layout-grid af-class-education--all__block">
                  <div id="w-node-_24d71a14-69ca-300b-ee6a-f6b97b645082-5f3d3636" className="af-class-education--all__subtitle">Курсы</div>
                  
                  { isLoading === true && <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div> }

                  <div id="w-node-c1efe102-cf1a-8427-568f-bd199e5679e3-5f3d3636" className="w-layout-grid af-class-education--all__list">
                    { posts.map((elem, index) => elem.kategori.indexOf(417) !== -1 ? <div key={index} id="w-node-_7da3d911-4d66-63d3-4ee0-11506f260ff5-5f3d3636" className="w-layout-grid af-class-education--all__item">
                      <div id="w-node-_60a2feab-56ff-81ee-be4b-43a032238de8-5f3d3636" className="af-class-education--all__item__title" dangerouslySetInnerHTML={{ __html: elem?.title?.rendered }}></div>
                      <div id="w-node-_7f5880d2-e6aa-5192-3d42-6ef6d0f545b4-5f3d3636" className="af-class-education--all__item__text">{elem?.acf?.dlitelnost}</div>
                      <div id="w-node-_982e90c9-8b43-e219-372b-5dbd6524e5d1-5f3d3636" className="af-class-education--all__item__text">{elem?.acf?.stoimost}</div>
                      <div id="w-node-_3650c135-ff90-3c85-e142-a42fe71bdd8a-5f3d3636" className="af-class-education--all__item__discription" dangerouslySetInnerHTML={{ __html: elem?.content?.rendered }}></div>
                      <div id="w-node-f002c4ee-c6a9-e7da-7d5b-f6b7800fdf85-5f3d3636" className="af-class-g-link--no-underscore" onClick={() => navigate(`/education-all/${elem.slug}`)} style={{cursor: 'pointer'}}>Записаться</div>
                    </div> : ''
                    )}
                  </div>
                </div>

                {/* <div className="w-layout-grid af-class-education--all__block">
                  <div id="w-node-_6c4d02a2-614d-5fcf-c8cc-65870ad32dd7-5f3d3636" className="af-class-education--all__subtitle">Мастер-классы</div>
                  <div id="w-node-_6c4d02a2-614d-5fcf-c8cc-65870ad32dd9-5f3d3636" className="w-layout-grid af-class-education--all__list">
                    { posts.map((elem, index) => elem.kategori.indexOf(418) !== -1 ? <div key={index} id="w-node-_7da3d911-4d66-63d3-4ee0-11506f260ff5-5f3d3636" className="w-layout-grid af-class-education--all__item">
                      <div id="w-node-_60a2feab-56ff-81ee-be4b-43a032238de8-5f3d3636" className="af-class-education--all__item__title" dangerouslySetInnerHTML={{ __html: elem?.title?.rendered }}></div>
                      <div id="w-node-_7f5880d2-e6aa-5192-3d42-6ef6d0f545b4-5f3d3636" className="af-class-education--all__item__text">{elem?.acf?.dlitelnost}</div>
                      <div id="w-node-_982e90c9-8b43-e219-372b-5dbd6524e5d1-5f3d3636" className="af-class-education--all__item__text">{elem?.acf?.stoimost}</div>
                      <div id="w-node-_3650c135-ff90-3c85-e142-a42fe71bdd8a-5f3d3636" className="af-class-education--all__item__discription" dangerouslySetInnerHTML={{ __html: elem?.acf?.dopopisanie }}></div>
                      <div id="w-node-f002c4ee-c6a9-e7da-7d5b-f6b7800fdf85-5f3d3636" className="af-class-g-link--no-underscore" onClick={() => navigate(`/education-all/${elem.slug}`)} style={{cursor: 'pointer'}}>Записаться</div>
                    </div> : ''
                    )}
                  </div>
                </div> */}

              </div>
            </div>
          </div>
  )
  
}

export default EducationAllView

/* eslint-enable */