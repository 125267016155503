import { useState, useEffect, useContext } from "react";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import { Map, Placemark, Clusterer, YMaps, useYMaps } from "@pbe/react-yandex-maps";
import {
  getYandexPricePvz,
} from "../../../fetch/WPAPI";

const CartFormPopupPvzYandex = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);
  const ymaps = useYMaps(['templateLayoutFactory']);

  // The whole Updating thing is to fix some weird bug with Clusterer
  const [ updating, setUpdating ] = useState(false);
  useEffect(() => {
    setUpdating(true);
  }, [cartFormContext.cityPvzYandex]);
  useEffect(() => {
    if (updating === true) {
      setTimeout(() => setUpdating(false), 100)
    }
  }, [updating]);

  const handleChange = (address, details) => {
    cartFormContext.setDeliveryPvz({
      selected: address,
      details: details,
    });
  };

  useEffect(() => {
    if (cartFormContext.deliveryPvz.details?.address?.full_address) {
      // If PVZ or cart contents changes, delivery cost is cleared and invalidated (set to false) and has to be recalculated
      cartFormContext.setDeliveryCost(false);
      cartFormContext.setDeliveryDeadline('');

      getYandexPricePvz(cartFormContext.deliveryPvz.details.id, mainContext.cartTotalWeight).then((result) => {
        if (result.cost) {
          cartFormContext.setDeliveryCost(parseInt(result.cost));
          cartFormContext.setDeliveryDeadline(`${new Date(new Date().setDate(new Date().getDate() + 2)).toLocaleDateString("ru-RU")}`);
        }
      });
    }
  }, [cartFormContext.deliveryPvz.details, mainContext.cartTotalWeight]);

  return (
    <>
      { (ymaps && ymaps.templateLayoutFactory) && <div
        style={{
          display: cartFormContext.popup == "pvzYandex" ? "flex" : "none",
          zIndex: "2147483647",
        }}
        className="af-class-cart-popup-wrapper af-class-an-pickup-points-on-the-map"
      >
        <div className="af-class-cart-popup-form w-form">
          <a
            href="#"
            className="af-class-cart-popup-close-button w-inline-block"
            onClick={() => cartFormContext.setPopup(null)}
          >
            <img
              src="images/icon--close-popup.svg"
              loading="lazy"
              alt=""
              className="af-class-cart-popup-close-button-icon"
            />
          </a>
          <div
            className="af-class-cart-popup af-class-pickup-points"
            style={{ paddingTop: "60px", paddingBottom: "60px" }}
          >
            <div className="af-class-cart-popup__title">Пункты самовывоза</div>
            {cartFormContext.cityPvzYandex?.points?.length > 0 ? (
              <div className="cart-pvz-map" style={{ width: "100%", height: "500px" }}>
                <Map
                  state={{
                    center: [
                      cartFormContext.cityPvzYandex.points[0].position.latitude,
                      cartFormContext.cityPvzYandex.points[0].position.longitude,
                    ],
                    zoom: 10,
                  }}
                  width="100%"
                  height="500px"
                >
                  { (updating === false) && <Clusterer
                    options={{
                      groupByCoordinates: false,
                      clusterIconLayout: ymaps.templateLayoutFactory.createClass(
                        '<div style="background-color: #1D71B8; border-radius: 50%; width: 54px; height: 54px; text-align: center; line-height: 54px; color: #fff; cursor: pointer;">{{ properties.geoObjects.length }}</div>'
                      ),
                      clusterIconShape: {
                        type: 'Rectangle',
                        coordinates: [[0, 0], [54, 54]],
                      },
                    }}
                  >
                    {cartFormContext.cityPvzYandex.points.map((elem, index) => (
                      <Placemark
                        key={index}
                        geometry={[
                          elem.position.latitude,
                          elem.position.longitude,
                        ]}
                        properties={{
                          data: elem,
                        }}
                        options={{
                          hasBalloon: true,
                          hideIconOnBalloonOpen: false,
                          iconLayout: ymaps.templateLayoutFactory.createClass(
                            '<div style="background-color: #1D71B8; border-radius: 50%; width: 19px; height: 19px;"></div>'
                          ),
                          iconShape: {
                            type: 'Rectangle',
                            coordinates: [[0, 0], [19, 19]]
                          },
                          balloonLayout: ymaps.templateLayoutFactory.createClass(
                            '<div class="cart-pvz-map-balloon">$[[options.contentLayout observeSize minWidth=230 maxWidth=230]]</div>',
                            {
                              build: function () {
                                this.constructor.superclass.build.call(this);
                                this._$element = this.getParentElement().querySelector('.cart-pvz-map-balloon');
                                this.applyElementOffset();
                                this._$element.querySelector('.cart-pvz-map-balloon-link').addEventListener('click', this.selectLinkClick.bind(this));
                              },
                              selectLinkClick: function () {
                                handleChange(this._data.properties._data.data.address.full_address, this._data.properties._data.data);
                                cartFormContext.setPopup(null);
                              },
                              clear: function () {
                                this.constructor.superclass.clear.call(this);
                              },
                              onSublayoutSizeChange: function () {
                                this.applyElementOffset();
                              },
                              applyElementOffset: function () {
                                this._$element.style.left = `${-15}px`;
                                this._$element.style.top = `${-(this._$element.offsetHeight + 10)}px`;
                              },
                            }
                          ),
                          balloonContentLayout: ymaps.templateLayoutFactory.createClass(
                            '<div class="cart-pvz-map-balloon-header">{{ properties.data.name }}</div>' +
                            '<div class="cart-pvz-map-balloon-line">{{ properties.data.address.full_address }}</div>' +
                            // '<div class="cart-pvz-map-balloon-line">{{ properties.data.work_time }}</div>' +
                            '<a class="cart-pvz-map-balloon-link">Выбрать пункт самовывоза</a>'
                          ),
                        }}
                        modules={['geoObject.addon.balloon']}
                        // onClick={() => {
                        //   handleChange(elem.location.address, elem);
                        //   cartFormContext.setPopup(null);
                        // }}
                      />
                    ))}
                  </Clusterer> }
                </Map>
              </div>
            ) : cartFormContext.cityPvzYandex?.points && cartFormContext.cityPvzYandex.points?.length == 0 ? (
              <div>В вашем городе нет пунктов самовывоза</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className="af-class-cart-popup__close-area"
          onClick={() => cartFormContext.setPopup(null)}
        />
      </div> }
    </>
  );
};

export default CartFormPopupPvzYandex;

// { (ymaps && ymaps.templateLayoutFactory) && <div
//   style={{
//     display: pvzPopupOpened === true ? "flex" : "none",
//     zIndex: "2147483647",
//   }}
//   className="af-class-cart-popup-wrapper af-class-an-pickup-points-on-the-map"
// >
//   <div className="af-class-cart-popup-form w-form">
//     <a
//       href="#"
//       className="af-class-cart-popup-close-button w-inline-block"
//       onClick={() => setPvzPopupOpened(false)}
//     >
//       <img
//         src="images/icon--close-popup.svg"
//         loading="lazy"
//         alt=""
//         className="af-class-cart-popup-close-button-icon"
//       />
//     </a>
//     <div
//       className="af-class-cart-popup af-class-pickup-points"
//       style={{ paddingTop: "60px", paddingBottom: "60px" }}
//     >
//       <div className="af-class-cart-popup__title">Пункты самовывоза</div>
//       {currentPvz?.city?.latitude &&
//       currentPvz?.city?.longitude &&
//       currentPvz.points?.length > 0 ? (
//         <div className="cart-pvz-map" style={{ width: "100%", height: "500px" }}>
//           <Map
//             state={{
//               center: [
//                 currentPvz.city.latitude,
//                 currentPvz.city.longitude,
//               ],
//               zoom: 10,
//             }}
//             width="100%"
//             height="500px"
//           >
//             { (pvzUpdating === false) && <Clusterer
//               options={{
//                 groupByCoordinates: false,
//                 clusterIconLayout: ymaps.templateLayoutFactory.createClass(
//                   '<div style="background-color: #1D71B8; border-radius: 50%; width: 54px; height: 54px; text-align: center; line-height: 54px; color: #fff; cursor: pointer;">{{ properties.geoObjects.length }}</div>'
//                 ),
//                 clusterIconShape: {
//                   type: 'Rectangle',
//                   coordinates: [[0, 0], [54, 54]],
//                 },
//               }}
//             >
//               {currentPvz.points.map((elem, index) => (
//                 <Placemark
//                   key={index}
//                   geometry={[
//                     elem.location.latitude,
//                     elem.location.longitude,
//                   ]}
//                   properties={{
//                     data: elem,
//                   }}
//                   options={{
//                     hasBalloon: true,
//                     hideIconOnBalloonOpen: false,
//                     iconLayout: ymaps.templateLayoutFactory.createClass(
//                       '<div style="background-color: #1D71B8; border-radius: 50%; width: 19px; height: 19px;"></div>'
//                     ),
//                     iconShape: {
//                       type: 'Rectangle',
//                       coordinates: [[0, 0], [19, 19]]
//                     },
//                     balloonLayout: ymaps.templateLayoutFactory.createClass(
//                       '<div class="cart-pvz-map-balloon">$[[options.contentLayout observeSize minWidth=230 maxWidth=230]]</div>',
//                       {
//                         build: function () {
//                           this.constructor.superclass.build.call(this);
//                           this._$element = this.getParentElement().querySelector('.cart-pvz-map-balloon');
//                           this.applyElementOffset();
//                           this._$element.querySelector('.cart-pvz-map-balloon-link').addEventListener('click', this.selectLinkClick.bind(this));
//                         },
//                         selectLinkClick: function () {
//                           handleChangeCurrentPvz(this._data.properties._data.data.location.address);
//                           handleChangeCurrentPvzDetails(this._data.properties._data.data);
//                           setPvzPopupOpened(false);
//                         },
//                         clear: function () {
//                           this.constructor.superclass.clear.call(this);
//                         },
//                         onSublayoutSizeChange: function () {
//                           this.applyElementOffset();
//                         },
//                         applyElementOffset: function () {
//                           this._$element.style.left = `${-15}px`;
//                           this._$element.style.top = `${-(this._$element.offsetHeight + 10)}px`;
//                         },
//                       }
//                     ),
//                     balloonContentLayout: ymaps.templateLayoutFactory.createClass(
//                       '<div class="cart-pvz-map-balloon-header">{{ properties.data.name }}</div>' +
//                       '<div class="cart-pvz-map-balloon-line">{{ properties.data.location.address }}</div>' +
//                       '<div class="cart-pvz-map-balloon-line">{{ properties.data.work_time }}</div>' +
//                       '<a class="cart-pvz-map-balloon-link">Выбрать пункт самовывоза</a>'
//                     ),
//                   }}
//                   modules={['geoObject.addon.balloon']}
//                   // onClick={() => {
//                   //   handleChangeCurrentPvz(elem.location.address);
//                   //   handleChangeCurrentPvzDetails(elem);
//                   //   setPvzPopupOpened(false);
//                   // }}
//                 />
//               ))}
//             </Clusterer> }
//           </Map>
//         </div>
//       ) : currentPvz?.points && currentPvz.points?.length == 0 ? (
//         <div>В вашем городе нет пунктов самовывоза</div>
//       ) : (
//         <></>
//       )}

//       {/* <div className="af-class-cart-popup__map af-class-pickup-points-on-the-map"> */}
//       {/* <div className="af-class-map__location-wrapper">
//         <div className="w-layout-grid af-class-map__location-discription">
//           <div className="af-class-map__location-title">Кинотеатр «Звезда»</div>
//           <div className="w-layout-grid af-class-map__location-info">
//             <div className="af-class-map__location-adress">Ул. Земляной вал, 18-22, стр 1</div>
//             <div className="af-class-map__location-work-time">Пн-Вс 9.00-21.00</div>
//           </div>
//           <a href="#" className="af-class-button--secondry w-inline-block">
//             <div className="af-class-cart__button--secondry__text">Выбрать пункт самовывоза</div>
//             <div className="af-class-cart__button--secondry__underline" />
//           </a>
//         </div>
//         <div className="af-class-map__location-icon" />
//       </div> */}
//       {/* </div> */}
//     </div>
//   </div>
//   <div
//     className="af-class-cart-popup__close-area"
//     onClick={() => setPvzPopupOpened(false)}
//   />
// </div> }