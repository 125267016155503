import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import ErrorBoundary from './error-boundary/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import IndexContext from './IndexContext';
import { MainContext } from './contexts/MainContext'
import { CartFormContext } from './contexts/CartFormContext'
import { getMinDeliveryDateDalli } from './components/Cart/CartForm/functions'

const App = () => {
  
  const loadScript = function(src, inner) {
    const tag = document.createElement('script')
    tag.type = 'text/javascript'
    tag.async = 'async'
    tag.defer = 'defer'
    tag.setAttribute('data-timepad-customized', '157366')
    tag.setAttribute('data-timepad-widget-v2', 'event_register')
    tag.src = src
    // tag.text = inner
    tag.appendChild(document.createTextNode(inner))
    // console.log(tag)
    document.body.appendChild(tag)
  }
  const loadScriptHandler = () => {
    const tag = document.createElement('script')
    tag.setAttribute('type', 'text/javascript')
    tag.setAttribute('defer', 'true')
    tag.setAttribute('hid', 'cp')
    tag.setAttribute('src', 'https://widget.cloudpayments.ru/bundles/paymentblocks.js')
    document.head.appendChild(tag)
  }
  const loadScriptSimple = function(inner) {
    const tag = document.createElement('script')
    tag.type = 'text/javascript'
    tag.async = 'async'
    tag.defer = 'defer'
    // tag.src = src
    // tag.text = inner
    tag.appendChild(document.createTextNode(inner))
    // console.log(tag)
    document.body.appendChild(tag)
  }
  useEffect(() => {
    loadScript('https://timepad.ru/js/tpwf/loader/min/loader.js', '(function(){return {"event" : {"id" : 1943761 }, "hidePreloading" : true, "display" : "popup", "popup" : {"triggerSelector" : "#timepad_twf_register_1943761"}}})();')
    loadScript('https://timepad.ru/js/tpwf/loader/min/loader.js', '(function(){return {"event" : {"id" : 2134568 }, "hidePreloading" : true, "display" : "popup", "popup" : {"triggerSelector" : "#timepad_twf_register_2134568"}}})();')
    loadScript('https://timepad.ru/js/tpwf/loader/min/loader.js', '(function(){return {"event" : {"id" : 2283059 }, "hidePreloading" : true, "display" : "popup", "popup" : {"triggerSelector" : "#timepad_twf_register_2283059"}}})();')
    loadScript('https://timepad.ru/js/tpwf/loader/min/loader.js', '(function(){return {"event" : {"id" : 2551656 }, "hidePreloading" : true, "display" : "popup", "popup" : {"triggerSelector" : "#timepad_twf_register_2551656"}}})();')
    loadScriptHandler()
    // loadScriptSimple('(function(w,d,n,c){w.CalltouchDataObject=n;w[n]=function(){w[n]["callbacks"].push(arguments)};if(!w[n]["callbacks"]){w[n]["callbacks"]=[]}w[n]["loaded"]=false;if(typeof c!=="object"){c=[c]}w[n]["counters"]=c;for(var i=0;i<c.length;i+=1){p(c[i])}function p(cId){var a=d.getElementsByTagName("script")[0],s=d.createElement("script"),i=function(){a.parentNode.insertBefore(s,a)},m=typeof Array.prototype.find === \'function\',n=m?"init-min.js":"init.js";s.async=true;s.src="https://mod.calltouch.ru/"+n+"?id="+cId;if(w.opera=="[object Opera]"){d.addEventListener("DOMContentLoaded",i,false)}else{i()}}})(window,document,"ct","10qg9fdf");')
  }, [])

  const [cartItems, setCartItems] = useState([])
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
  const [cartTotalWeight, setCartTotalWeight] = useState(0)
  const [catalogItems, setCatalogItems] = useState([])
  const [catalogItemsVariations, setCatalogItemsVariations] = useState([])
  const [userData, setUserData] = useState({})
  const [userWpData, setUserWpData] = useState({})
  const [recentlySeen, setRecentlySeen] = useState([])
  const [homePageData, setHomePageData] = useState({})
  const [pvzAll, setPvzAll] = useState([])
  const [headerMenuCategories, setHeaderMenuCategories] = useState([])
  const [brewingMethods, setBrewingMethods] = useState([])
  const [priceListPageSlug, setPriceListPageSlug] = useState('')
  const [ymClientID, setYmClientID] = useState('')
  const [userAddresses, setUserAddresses] = useState([]);

  const [popup, setPopup] = useState(null);
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [phoneCode, setPhoneCode] = useState("+7");
  const [phoneFlag, setPhoneFlag] = useState("ru");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState({
    country: '',
    city: '',
    cityFias: '',
    cityPostalCode: '',
  });
  const [dynamicDeliveryDataPvz, setDynamicDeliveryDataPvz] = useState({
    datetime: '',
    cost: 0,
  });
  const [dynamicDeliveryDataCourier, setDynamicDeliveryDataCourier] = useState({
    datetime: `${getMinDeliveryDateDalli().toLocaleDateString("ru-RU")}`,
    cost: 100,
  });
  const [dynamicDeliveryDataRussianPost, setDynamicDeliveryDataRussianPost] = useState({
    datetime: '',
    cost: 0,
  });
  const [dynamicDeliveryDataYandexCourier, setDynamicDeliveryDataYandexCourier] = useState({
    datetime: '',
    cost: 0,
  });
  const [dynamicDeliveryDataYandexPvz, setDynamicDeliveryDataYandexPvz] = useState({
    datetime: '',
    cost: 0,
  });
  /**
   * Delivery cost is valid (set to numeric value and not false) if:
   * 1. For coffee shops: coffee shop is selected
   * 2. For PVZs: price has been calculated for the given PVZ
   * - if cart contents has been changed, it has to be recalculated
   * 3. For couriers: price has been calculated for the given address
   * - if cart contents or address has been changed, it has to be recalculated
   */
  const [deliveryCost, setDeliveryCost] = useState(false);
  const [deliveryCostDiscount, setDeliveryCostDiscount] = useState(0);
  const [deliveryCostDiscounted, setDeliveryCostDiscounted] = useState(0);
  const [deliveryCostDiscounts, setDeliveryCostDiscounts] = useState({
    courier: 0,
    pvz: 0,
    russianPost: 0,
  });
  const [deliveryDeadline, setDeliveryDeadline] = useState('');
  const [cityPvz, setCityPvz] = useState({});
  const [cityPvzYandex, setCityPvzYandex] = useState({});
  const [deliveryType, setDeliveryType] = useState({
    selected: "Выберите тип доставки",
    options: [],
  });
  const [deliveryCompany, setDeliveryCompany] = useState({
    selected: "Выберите компанию",
    options: [], // not used now, but maybe will be in future
  });
  const [deliveryCoffeeshop, setDeliveryCoffeeshop] = useState({
    selected: "Выберите кофейню",
  });
  const [deliveryPvz, setDeliveryPvz] = useState({
    selected: "Выберите пункт самовывоза",
    details: {},
  });
  const [deliveryDate, setDeliveryDate] = useState(getMinDeliveryDateDalli());
  const [deliveryTime, setDeliveryTime] = useState({
    selected: "Выберите время доставки",
    options: [
      "09:00 - 13:00",
      "10:00 - 22:00",
      "10:00 - 17:00",
      "18:00 - 22:00",
    ],
  });
  const [paymentType, setPaymentType] = useState("card");
  const [addressData, setAddressData] = useState({
    isValid: false,

    name: '',
    full: '',
    entrance: '',
    floor: '',
    flat: '',
    homephone: '',

    city: '',
    country: '',
    cityPostalCode: '',
    cityFiasId: '',
    cityKladrId: '',
  });
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
  const [certificate, setCertificate] = useState("");
  const [certificateApplied, setCertificateApplied] = useState(false);
  const [certificateDiscount, setCertificateDiscount] = useState(0);
  const [bonusesUserBalance, setBonusesUserBalance] = useState(0);
  const [bonusesDiscount, setBonusesDiscount] = useState(0);
  const [comments, setComments] = useState("");
  const [confirmationCall, setConfirmationCall] = useState(false);
  const [userAgreement, setUserAgreement] = useState(true);
  const [paymentWireOrg, setPaymentWireOrg] = useState("");
  const [paymentWireInn, setPaymentWireInn] = useState("");
  const [paymentWireKpp, setPaymentWireKpp] = useState("");
  const [paymentWireAcc, setPaymentWireAcc] = useState("");
  const [paymentWireCorrAcc, setPaymentWireCorrAcc] = useState("");
  const [paymentWireBik, setPaymentWireBik] = useState("");

  const [surnameValid, setSurnameValid] = useState(null);
  const [nameValid, setNameValid] = useState(null);
  const [phoneValid, setPhoneValid] = useState(null);
  const [emailValid, setEmailValid] = useState(null);
  const [paymentWireOrgValid, setPaymentWireOrgValid] = useState(null);
  const [paymentWireInnValid, setPaymentWireInnValid] = useState(null);
  const [paymentWireKppValid, setPaymentWireKppValid] = useState(null);
  const [paymentWireAccValid, setPaymentWireAccValid] = useState(null);
  const [paymentWireCorrAccValid, setPaymentWireCorrAccValid] = useState(null);
  const [paymentWireBikValid, setPaymentWireBikValid] = useState(null);

  const [surnameError, setSurnameError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [paymentWireOrgError, setPaymentWireOrgError] = useState(null);
  const [paymentWireInnError, setPaymentWireInnError] = useState(null);
  const [paymentWireKppError, setPaymentWireKppError] = useState(null);
  const [paymentWireAccError, setPaymentWireAccError] = useState(null);
  const [paymentWireCorrAccError, setPaymentWireCorrAccError] = useState(null);
  const [paymentWireBikError, setPaymentWireBikError] = useState(null);
  const [addressError, setAddressError] = useState(null);

  return (
    <MainContext.Provider value={{
      cartItems,
      setCartItems,
      cartTotalPrice,
      setCartTotalPrice,
      cartTotalWeight,
      setCartTotalWeight,
      catalogItems,
      setCatalogItems,
      catalogItemsVariations,
      setCatalogItemsVariations,
      userData,
      setUserData,
      userWpData,
      setUserWpData,
      recentlySeen,
      setRecentlySeen,
      homePageData,
      setHomePageData,
      pvzAll,
      setPvzAll,
      headerMenuCategories,
      setHeaderMenuCategories,
      brewingMethods,
      setBrewingMethods,
      priceListPageSlug,
      setPriceListPageSlug,
      ymClientID,
      setYmClientID,
      userAddresses,
      setUserAddresses,
    }}>
      <CartFormContext.Provider value={{
        popup, setPopup,
        surname, setSurname,
        name, setName,
        phoneCode, setPhoneCode,
        phoneFlag, setPhoneFlag,
        phone, setPhone,
        email, setEmail,
        city, setCity,
        dynamicDeliveryDataPvz, setDynamicDeliveryDataPvz,
        dynamicDeliveryDataCourier, setDynamicDeliveryDataCourier,
        dynamicDeliveryDataRussianPost, setDynamicDeliveryDataRussianPost,
        dynamicDeliveryDataYandexCourier, setDynamicDeliveryDataYandexCourier,
        dynamicDeliveryDataYandexPvz, setDynamicDeliveryDataYandexPvz,
        deliveryCost, setDeliveryCost, // Delivery cost "as is" - as it has been received from a carrier
        deliveryCostDiscount, setDeliveryCostDiscount, // Delivery cost discount - calculated by hardcoded rules
        deliveryCostDiscounted, setDeliveryCostDiscounted, // Delivery cost discounted - cost minus discount - explicitly set to 0 if delivery cost is less than discount
        deliveryCostDiscounts, setDeliveryCostDiscounts, // Delivery cost discounts - calculated by hardcoded rules for both courier and pvz
        deliveryDeadline, setDeliveryDeadline,
        cityPvz, setCityPvz,
        cityPvzYandex, setCityPvzYandex,
        deliveryType, setDeliveryType,
        deliveryCompany, setDeliveryCompany,
        deliveryCoffeeshop, setDeliveryCoffeeshop,
        deliveryPvz, setDeliveryPvz,
        deliveryDate, setDeliveryDate,
        deliveryTime, setDeliveryTime,
        paymentType, setPaymentType,
        addressData, setAddressData,
        promoCode, setPromoCode,
        promoCodeApplied, setPromoCodeApplied,
        promoCodeDiscount, setPromoCodeDiscount,
        certificate, setCertificate,
        certificateApplied, setCertificateApplied,
        certificateDiscount, setCertificateDiscount,
        bonusesUserBalance, setBonusesUserBalance,
        bonusesDiscount, setBonusesDiscount,
        comments, setComments,
        confirmationCall, setConfirmationCall,
        userAgreement, setUserAgreement,
        paymentWireOrg, setPaymentWireOrg,
        paymentWireInn, setPaymentWireInn,
        paymentWireKpp, setPaymentWireKpp,
        paymentWireAcc, setPaymentWireAcc,
        paymentWireCorrAcc, setPaymentWireCorrAcc,
        paymentWireBik, setPaymentWireBik,
        surnameValid, setSurnameValid,
        nameValid, setNameValid,
        phoneValid, setPhoneValid,
        emailValid, setEmailValid,
        paymentWireOrgValid, setPaymentWireOrgValid,
        paymentWireInnValid, setPaymentWireInnValid,
        paymentWireKppValid, setPaymentWireKppValid,
        paymentWireAccValid, setPaymentWireAccValid,
        paymentWireCorrAccValid, setPaymentWireCorrAccValid,
        paymentWireBikValid, setPaymentWireBikValid,
        surnameError, setSurnameError,
        nameError, setNameError,
        phoneError, setPhoneError,
        emailError, setEmailError,
        paymentWireOrgError, setPaymentWireOrgError,
        paymentWireInnError, setPaymentWireInnError,
        paymentWireKppError, setPaymentWireKppError,
        paymentWireAccError, setPaymentWireAccError,
        paymentWireCorrAccError, setPaymentWireCorrAccError,
        paymentWireBikError, setPaymentWireBikError,
        addressError, setAddressError,
      }}>
        <ErrorBoundary>
          <BrowserRouter>
            <IndexContext />
          </BrowserRouter>
        </ErrorBoundary>
      </CartFormContext.Provider>
    </MainContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
