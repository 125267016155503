/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { fetchPage } from '../fetch/WPAPI'

const LoyaltySystemView = () => {

  const [ isLoading, setIsLoading ] = useState(true)

  const [ pageContent, setPageContent ] = useState({})

  useEffect(() => {
    fetchPage(54)
      .then(result => {
        setPageContent(result)

        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    document.title = pageContent?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", pageContent?.yoast_head_json?.description)
  }, [pageContent])

  if (isLoading === true) {
    return <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div>
  }

  return <div dangerouslySetInnerHTML={{__html: pageContent?.content?.rendered}} />
  
  // return <div className="af-class-section af-class-p_loyalty_section af-class-an-load--2 af-class-wf-section">
  //             <div className="af-class-container w-container">
  //               <h1 className="af-class-title-main-p_loyalty_system">Накопительная система скидок</h1>
  //               <div className="af-class-content-p_loyalty_system">
  //                 <div id="w-node-_6e52b986-0d49-6cc0-f2d1-d90cc1e2ef62-343d363f" className="w-layout-grid af-class-grid-p_loyalty_system">
  //                   <div className="af-class-card-p_loyalty_system">
  //                     <div className="af-class-lable-card-p_loyalty_system">Статус</div>
  //                     <div className="af-class-data-card-p_loyalty_system">5%</div>
  //                     <div className="af-class-caption-card-p_loyalty_system-copy">при заказе от 10 000 руб.</div>
  //                   </div>
  //                   <div className="af-class-card-p_loyalty_system">
  //                     <div className="af-class-lable-card-p_loyalty_system">Статус</div>
  //                     <div className="af-class-data-card-p_loyalty_system">7%</div>
  //                     <div className="af-class-caption-card-p_loyalty_system-copy">при заказе от 30 000 руб.</div>
  //                   </div>
  //                   <div className="af-class-card-p_loyalty_system">
  //                     <div className="af-class-lable-card-p_loyalty_system">Статус</div>
  //                     <div className="af-class-data-card-p_loyalty_system">10%</div>
  //                     <div className="af-class-caption-card-p_loyalty_system-copy">при заказе от 45 000 руб.</div>
  //                   </div>
  //                   <div className="af-class-card-p_loyalty_system">
  //                     <div className="af-class-lable-card-p_loyalty_system">Статус</div>
  //                     <div className="af-class-data-card-p_loyalty_system">12%</div>
  //                     <div className="af-class-caption-card-p_loyalty_system-copy">при заказе от 90 000 руб.</div>
  //                   </div>
  //                   <div className="af-class-card-p_loyalty_system">
  //                     <div className="af-class-lable-card-p_loyalty_system">Статус</div>
  //                     <div className="af-class-data-card-p_loyalty_system">15%</div>
  //                     <div className="af-class-caption-card-p_loyalty_system-copy">при заказе от 180 000 руб.</div>
  //                   </div>
  //                 </div>
  //                 <div id="w-node-_30373b25-266b-7712-6831-c8a6e5b62a05-343d363f" className="af-class-text-wrapper-p_loyalty_system">
  //                   <h2 className="af-class-title-secondary-p_loyalty_system">После регистрации на сайте вы становитесь участником накопительной системы скидок Rockets.</h2>
  //                   <div className="af-class-subtitle-p_loyalty_system">Персональная скидка не распространяется на товары со скидкой.</div>
  //                   <div className="af-class-subtitle-p_loyalty_system">Максимальный размер скидки – 15%.</div>
  //                   <div className="w-layout-grid af-class-discription-wrapper-p_loyalty_system">
  //                     <div className="af-class-discription-p_loyalty_system">Размер скидки зависит от общей суммы оформленных заказов. Для получения скидки необходимо авторизоваться в личном кабинете и оформить заказ. Текущий размер скидки отображается в личном кабинете.</div>
  //                     <div id="w-node-_132c4f47-2bfd-5d56-2050-d62e3371f59e-343d363f" className="af-class-discription_and_phone-p_loyalty_system">
  //                       <div className="af-class-discription-p_loyalty_system">Информация для постоянных клиентов: для уточнения размера вашей текущей скидки зарегистрируйтесь на сайте и позвоните по номеру</div>
  //                       <div className="af-class-phone-p_loyalty_system">8 (495) 108-11-44</div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
}

export default LoyaltySystemView

/* eslint-enable */