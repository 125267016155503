import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchProducts, fetchFibosearch, fetchProductCategory } from '../../fetch/WPAPI';
import { MainContext } from '../../contexts/MainContext';
import Product from '../Product/Product';
import CatalogSortDesktop from '../Catalog/CatalogSortDesktop';
import CatalogSortMobile from '../Catalog/CatalogSortMobile';
import CatalogTabNav from '../Catalog/CatalogTabNav';
import CatalogFiltersMobile from '../Catalog/CatalogFiltersMobile';
import CatalogFilters from '../Catalog/CatalogFilters';

export default function CatalogPage({ categoryId, categoryFilters, categoryFiltersQuick, setCategoryFiltersQuick, optionsAliases = [], filterByAttributes = [], productsFilter = null }) {
  const mainContext = useContext(MainContext);
  const { pathname } = useLocation();

  const [ productsLoading, setProductsLoading ] = useState(true);
  const [ allProducts, setAllProducts ] = useState([]);
  const [ products, setProducts ] = useState([]);
  const [ variations, setVariations ] = useState([]);

  const [ filters, setFilters ] = useState([]);
  const [ isFiltersOpen, setIsFiltersOpen ] = useState(false);
  
  const [ sort, setSort ] = useState(0);
  
  const [ searchString, setSearchString ] = useState('');
  const [ searchProducts, setSearchProducts ] = useState([]);
  
  const [ categorySlug, setCategorySlug ] = useState('');

  const processItems = (items) => {
    let allProducts;
    if (categoryId != 404) {
      allProducts = items.filter(elem => !elem.categories.find(elem => elem.id == 404));
    } else {
      allProducts = items;
    }
      
    let filtered;
    if (productsFilter) {
      filtered = productsFilter(allProducts);
    } else {
      filtered = allProducts;
    }

    setAllProducts(filtered);
    setProducts(filtered?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0) ));
    setProductsLoading(false);
  }

  useEffect(() => {
    // loadProducts
    let catalog = mainContext.catalogItems.find(elem => elem.categoryId == categoryId);
    if (catalog) {
      processItems(catalog.items);
    } else {
      fetchProducts(categoryId)
        .then(result => {
          processItems(result);

          mainContext.setCatalogItems([...mainContext.catalogItems, { categoryId: categoryId, items: result }]);
        })
    }

    fetchProductCategory(categoryId)
      .then(result => {
        // setPageData(result);
        document.title = result?.yoast_head_json?.title;
        document.querySelector('meta[name="description"]').setAttribute("content", result?.yoast_head_json?.description);
       });
  }, []);

  useEffect(() => {
    let query = window.location.search.substring(1);
    let getParams = query.split('&');
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=');
      if (pair[0] == 'search') {
        if (searchString != pair[1]) {
          setSearchString(pair[1]);
        }
      }
      if (pair[0] == 'category') {
        if (categorySlug != pair[1]) {
          setCategorySlug(pair[1]);
        }
      }
    }
  });

  useEffect(() => {
    if (searchString != '') {
      fetchFibosearch(decodeURIComponent(searchString))
        .then(result => {
          setSearchProducts(result.suggestions.filter(elem => elem.type == 'product').map(elem => elem.post_id));
        });
    }
  }, [searchString]);

  useEffect(() => {
    if (searchString != '') {
      setProducts(allProducts?.filter(p => searchProducts.includes(p.id)));
    }
  }, [searchProducts, allProducts]);

  useEffect(() => {
    if (categorySlug.length > 0) {
      let category = mainContext.headerMenuCategories.find(elem => elem.slug == categorySlug);
      if (category) {
        setProducts(allProducts?.filter(p => p.categories.find(elem => elem.id == category.id)));
      }
    } else {
      setProducts(allProducts);
    }
  }, [categorySlug, allProducts]);

  useEffect(() => {
    if (sort == 0) {
      setProducts(products?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0)));
    } else if (sort == 1) {
      setProducts(products?.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? 1 : ((parseInt(b.price) > parseInt(a.price)) ? -1 : 0)));
    } else if (sort == 2) {
      setProducts(products?.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? -1 : ((parseInt(b.price) > parseInt(a.price)) ? 1 : 0)));
    } else if (sort == 3) {
      setProducts(products?.sort((a, b) => (a.date_created > b.date_created) ? -1 : ((b.date_created > a.date_created) ? 1 : 0)));
    } else if (sort == 4) {
      setProducts(products?.sort((a, b) => (a.date_created > b.date_created) ? 1 : ((b.date_created > a.date_created) ? -1 : 0)));
    }
  }, [sort]);

  const handleFilter = () => {
    let products = allProducts?.filter(p => p.categories.find(c => c.id === categoryId) !== undefined);

    let sortedFilters = [];
    filters.map((elem, index) => {
      if (!sortedFilters[elem.id]) {
        sortedFilters[elem.id] = [];
      }
      sortedFilters[elem.id].push(elem.option);
    })

    let filteredItems = products.filter(product => sortedFilters.every((filter, index) => {
      optionsAliases.forEach(option => {
        if (filter.includes(option.name)) {
          filter.push(...option.aliases);
        }
      });

      if (filterByAttributes.includes(index)) {
        let currentAttributeIndex = product.attributes.findIndex(a => a.id === index);
        return product.attributes[currentAttributeIndex]?.options.filter(value => filter.includes(value)).length > 0;
      } else {
        return product.categories.filter(value => filter.includes(value.name)).length > 0;
      }
    }));
    
    setProducts(filteredItems);
  }

  const handleNewFilterItem = (newItem) => {
    let prevState = [...filters];
    if (prevState.some(i => i.id === newItem.id && i.option == newItem.option)) {
      let index = prevState.findIndex(i => i.id === newItem.id && i.option == newItem.option)
      prevState.splice(index, 1)
    } else {
      prevState.push(newItem)
    }
    
    setFilters(prevState);
  }

  const handleFiltersClear = () => {
    setFilters([]);
    console.log(allProducts)
    // setProducts(allProducts);
  }

  useEffect(() => {
    // check if categoryFiltersQuick is undefined
    if (!categoryFiltersQuick) return;
    
    const quickFiltersOptions = allProducts.map(p => p?.attributes?.find(a => a.id == 26)?.options || []).reduce((acc, val) => acc.concat(val), []).filter((v, i, a) => a.indexOf(v) === i);
    const quickFilters = quickFiltersOptions.map(option => {
      return {
        id: 26,
        option: option,
      };
    });
    let newQuickFilters = [...categoryFiltersQuick, ...quickFilters];
    newQuickFilters = newQuickFilters.filter((v, i, a) => a.findIndex(t => (t.option === v.option)) === i); // leave only unique values in newQuickFilters
    newQuickFilters = newQuickFilters.filter(value => value.option != 'Кофе в зёрнах');
    setCategoryFiltersQuick(newQuickFilters);
  }, [allProducts]);

  useEffect(() => {
    handleFilter();
  }, [filters]);

  return (
    <>
      {categoryFilters && <CatalogFiltersMobile
        filters={filters}
        categoryFilters={categoryFilters}
        categoryFiltersQuick={categoryFiltersQuick}
        toggleFilter={handleNewFilterItem}
        apply={handleFilter}
        clear={handleFiltersClear}
        isFiltersOpen={isFiltersOpen}
        toggle={() => setIsFiltersOpen(!isFiltersOpen)}
        displayGlobal={categorySlug != 'kofe-v-kapsulah' && categorySlug != 'drips' && categoryFilters?.length > 0}
      />}

      <div className="af-class-section af-class-control af-class-wf-section">
        <div className="af-class-container w-container">
          <div className="af-class-bar-filter">
            <div data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs w-tabs">
              
              <CatalogTabNav pathname={pathname} />

              <div className="af-class-tabs-content w-tab-content">
                <div className="w-tab-pane w--tab-active">
                  
                  {categoryFilters && <CatalogFilters
                    filters={filters}
                    categoryFilters={categoryFilters}
                    categoryFiltersQuick={categoryFiltersQuick}
                    toggleFilter={handleNewFilterItem}
                    apply={handleFilter}
                    clear={handleFiltersClear}
                    displayGlobal={categorySlug != 'kofe-v-kapsulah' && categorySlug != 'drips'}
                  />}

                  <CatalogSortMobile
                    filters={filters}
                    categoryFiltersQuick={categoryFiltersQuick}
                    toggleFilter={handleNewFilterItem}
                    setParentSort={setSort}
                    showFiltersButton={categorySlug != 'kofe-v-kapsulah' && categorySlug != 'drips' && categoryFilters?.length > 0}
                    filtersMobileToggle={() => setIsFiltersOpen(!isFiltersOpen)}
                  />

                  <div className="w-dyn-list af-class-grid-cards-p_catalog">
                    {products?.map((p, index) => <Product key={p.id} {...p} />)}
                    {products.length === 0 && <div className="w-dyn-empty" style={{background: 'none', color: '#1d71b8', fontWeight: 'bold'}}>
                      <div>{productsLoading == true ? 'Каталог загружается...' : 'По выбранным параметрам ничего не найдено, измените фильтры и попробуйте еще раз.'}</div>
                    </div>}
                  </div>

                </div>
              </div>
            </div>

            <CatalogSortDesktop setParentSort={setSort} />

          </div>
        </div>
      </div>
    </>
  );
}