import { useState } from 'react';
import CatalogPage from '../components/Catalog/CatalogPage';

export default function CatalogViewNew() {
  const [categoryFiltersQuick, setCategoryFiltersQuick] = useState([]);

  const categoryFilters = [
    {
      id: 9992,
      name: 'Вид чая',
      options: [
        'Черный',
        // 'Красный',
        'Зеленый',
        // 'Травяной',
        // 'Фруктовый',
        'Улуны',
        'Пуэр',
        'Тизаны',
      ]
    },
  ];

  const optionsAliases = [
    {
      name: 'Черный',
      aliases: ['Черный чай'],
    },
    {
      name: 'Красный',
      aliases: ['Красный чай'],
    },
    {
      name: 'Зеленый',
      aliases: ['Зелёный чай'],
    },
    {
      name: 'Травяной',
      aliases: ['Травяной чай'],
    },
    {
      name: 'Фруктовый',
      aliases: ['Фруктовый чай'],
    },
  ];

  return <CatalogPage
    categoryId={22}
    categoryFiltersQuick={categoryFiltersQuick}
    setCategoryFiltersQuick={setCategoryFiltersQuick}
    categoryFilters={categoryFilters}
    optionsAliases={optionsAliases}
    filterByAttributes={[26]}
  />
}