/* eslint-disable */

import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  // { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6261fc674c12d792773d3625").then(body => body.text()), isAsync: false },
  // { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class AccountBusinessView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AccountBusinessController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AccountBusinessView

        return Controller
      }

      throw e
    }
  }

  

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6261fc674c12d778c53d362b'
    htmlEl.dataset['wfSite'] = '6261fc674c12d792773d3625'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = AccountBusinessView.Controller !== AccountBusinessView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rockets-coffee-export.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <Header />
            <div style={{ display: 'none' }} className="af-class-popup--subscription">
              <div data-w-id="5f12339a-1879-1577-b70f-1e7ae84ce642" className="af-class-div-block-13" />
              <div className="af-class-div-block-10">
                <div data-w-id="5f12339a-1879-1577-b70f-1e7ae84ce644" className="af-class-div-block-11"><img src="images/icon--close-popup.svg" loading="lazy" alt="" /></div>
                <div className="af-class-text-block-21">Оформить подписку на заказ №51937593</div>
                <div className="af-class-cart__form w-form">
                  <form action="#" id="wf-form-User-shipping-info" name="wf-form-User-shipping-info" data-name="User shipping info" method="get" className="af-class-cart__user">
                    <div>
                      <div className="af-class-div-block-12">
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce64c-c53d362b" className="af-class-text-block-22">Honduras Luis Castillo</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce64e-c53d362b">250 г</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce650-c53d362b">В зернах</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce652-c53d362b">1 пачка</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce654-c53d362b" className="af-class-text-block-23">670 ₽</div>
                      </div>
                      <div className="af-class-div-block-12">
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce657-c53d362b" className="af-class-text-block-22">Colombia Sanjuanero Decaf</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce659-c53d362b">250 г</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce65b-c53d362b">В зернах</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce65d-c53d362b">1 пачка</div>
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce65f-c53d362b" className="af-class-text-block-23">670 ₽</div>
                      </div>
                    </div>
                    <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce661-c53d362b" className="w-layout-grid af-class-cart__user-info">
                      <h3 id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce662-c53d362b" className="af-class-cart__user-title">Периодичность</h3>
                      <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce664-c53d362b" className="w-layout-grid af-class-cart__user-fields">
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce665-c53d362b" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Периодичность</div><img loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="Payment-method-2" name="Payment-method-2" data-name="Payment Method 2" required className="af-class-g-select__content w-select">
                            <option value>Периодичность</option>
                            <option value="once-a-month">1 раз в месяц</option>
                            <option value="twice-a-month">2 раза в месяц</option>
                          </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce670-c53d362b" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce673-c53d362b" className="w-layout-grid af-class-cart__user-info">
                      <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce674-c53d362b" className="af-class-cart__user-title-wrapper">
                        <h3 id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce675-c53d362b" className="af-class-cart__user-title">Доставка</h3>
                        <a data-w-id="5f12339a-1879-1577-b70f-1e7ae84ce677" href="#" className="af-class-button--secondry w-inline-block">
                          <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce678-c53d362b" className="af-class-cart__button--secondry__text">Изменить</div>
                          <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce67a-c53d362b" className="af-class-cart__button--secondry__underline" />
                        </a>
                      </div>
                      <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce67b-c53d362b" className="w-layout-grid af-class-cart__user-fields"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="City" data-name="City" placeholder="Город" id="City" required />
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce67d-c53d362b" data-w-id="5f12339a-1879-1577-b70f-1e7ae84ce67d" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Выберите тип доставки</div><img style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)' }} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="Shipping-Type-2" name="Shipping-Type-2" data-name="Shipping Type 2" required className="af-class-g-select__content w-select">
                            <option value>Выберите тип доставки</option>
                            <option value="First">Курьерская служба</option>
                            <option value="Second">Транспортная компания</option>
                            <option value="pickup">Самовывоз</option>
                          </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce688-c53d362b" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce68b-c53d362b" data-w-id="5f12339a-1879-1577-b70f-1e7ae84ce68b" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Выберите компанию</div><img style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)' }} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="transport-company-2" name="transport-company-2" data-name="Transport Company 2" required className="af-class-g-select__content w-select">
                            <option value>Выберите компанию</option>
                            <option value="СДЭК МСК 1">СДЭК МСК 1</option>
                            <option value="СДЭК МСК 2">СДЭК МСК 2</option>
                            <option value="СДЭК МСК 3">СДЭК МСК 1</option>
                          </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce696-c53d362b" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Street" data-name="Street" placeholder="Улица" id="Street" required />
                        <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce69a-c53d362b" className="w-layout-grid af-class-cart__user-adress-group"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="House-number" data-name="House number" placeholder="Дом" id="House-number" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Building" data-name="Building" placeholder="Корпус" id="Building" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Entrance" data-name="Entrance" placeholder="Подъезд" id="Entrance" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Floor" data-name="Floor" placeholder="Этаж" id="Floor" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Apartment" data-name="Apartment" placeholder="Квартира" id="Apartment" required /><input type="number" className="af-class-cart__user-field w-input" maxLength={256} name="postcode-2" data-name="postcode 2" placeholder="Индекс" id="postcode-2" required /></div>
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6a1-c53d362b" data-w-id="5f12339a-1879-1577-b70f-1e7ae84ce6a1" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Время доставки</div><img style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)' }} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="shipping-date-and-time" name="shipping-date-and-time" data-name="shipping-date-and-time" required className="af-class-g-select__content w-select">
                            <option value>Выберите дату и время доставки</option>
                            <option value="23-12-22-15-00-20-00">23 декабря с 15:00 до 20:00</option>
                            <option value="23-12-22-20-00-23-00">23 декабря с 20:00 до 23:00</option>
                          </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6ac-c53d362b" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6af-c53d362b" className="w-layout-grid af-class-cart__user-info">
                      <h3 id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6b0-c53d362b" className="af-class-cart__user-title">Оплата</h3>
                      <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6b2-c53d362b" className="w-layout-grid af-class-cart__user-fields">
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6b3-c53d362b" data-w-id="5f12339a-1879-1577-b70f-1e7ae84ce6b3" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Способ оплаты</div><img style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)' }} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="Payment-method" name="Payment-method" data-name="Payment method" required className="af-class-g-select__content w-select">
                            <option value>Выберите способ оплаты</option>
                            <option value="with-card-online">Оплата банковской картой на сайте</option>
                            <option value="cash">Наличными</option>
                          </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6be-c53d362b" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6c1-c53d362b" className="w-layout-grid af-class-cart__user-info">
                      <h3 id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6c2-c53d362b" className="af-class-cart__user-title">Комментарии</h3>
                      <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6c4-c53d362b" className="w-layout-grid af-class-cart__user-fields"><textarea placeholder="Оставьте комментарий к заказу" maxLength={5000} id="Comments" name="Comments" data-name="field" className="af-class-cart__user-text-area w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6c5-c53d362b w-input" defaultValue={""} /></div>
                    </div>
                    <div id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6c6-c53d362b" className="af-class-cart__form-submit-button-wrapper-copy"><input type="submit" defaultValue="Оформить подписку" data-wait="Оформляется..." id="w-node-_5f12339a-1879-1577-b70f-1e7ae84ce6c7-c53d362b" className="af-class-g-submit-button w-button" /></div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-p-business-account af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="w-layout-grid af-class-title-and-content">
                  <h1 id="w-node-_522c973d-4503-5714-d129-7d5db7e71a32-c53d362b" className="af-class-page-title af-class-p-business-account">Личный кабинет</h1>
                  <div id="w-node-_243a1225-ca82-5133-4bed-f5fac7ea4e4f-c53d362b" data-current="Tab 3" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs--p-business-account w-tabs">
                    <div id="w-node-_243a1225-ca82-5133-4bed-f5fac7ea4e50-c53d362b" className="af-class-tabs-menu--p-business-account w-tab-menu">
                      <a data-w-tab="Tab 1" id="w-node-_243a1225-ca82-5133-4bed-f5fac7ea4e51-c53d362b" className="af-class-tab--primary w-inline-block w-tab-link">
                        <div>Профиль</div>
                      </a>
                      <a data-w-tab="Tab 2" id="w-node-_243a1225-ca82-5133-4bed-f5fac7ea4e54-c53d362b" className="af-class-tab--primary w-inline-block w-tab-link">
                        <div>Aдреса</div>
                      </a>
                      <a data-w-tab="Tab 3" id="w-node-_243a1225-ca82-5133-4bed-f5fac7ea4e57-c53d362b" className="af-class-tab--primary w-inline-block w-tab-link w--current">
                        <div>История заказов</div>
                      </a>
                      <a data-w-tab="Tab 4" className="af-class-tab--primary w-inline-block w-tab-link">
                        <div>Оборудование</div>
                      </a>
                      <a data-w-tab="Tab 5" className="af-class-tab--primary w-inline-block w-tab-link">
                        <div>Система скидок</div>
                      </a>
                      <a data-w-tab="Tab 6" className="af-class-tab--primary w-inline-block w-tab-link">
                        <div>Новости</div>
                      </a>
                    </div>
                    <div className="af-class-tabs-content--p-business-account w-tab-content">
                      <div data-w-tab="Tab 1" className="w-tab-pane">
                        <div style={{ display: 'grid' }} className="w-layout-grid af-class-tab-wrapper--profile-empty">
                          <div className="af-class-tab-title">Профиль</div>
                          <div id="w-node-a8a15f1e-5187-9fd2-51ad-e91f6c980ac7-c53d362b" className="w-layout-grid af-class-tab-content--empty-profile">
                            <div id="w-node-_9ce7dff1-1d24-5d1b-b95a-e6e4d764866c-c53d362b" className="af-class-tab-subtitle">Юридическое лицо</div>
                            <div id="w-node-_6176c502-817e-899d-cb71-63b2606f8212-c53d362b" className="af-class-tab-text">Нет данных</div>
                            <a id="w-node-_8802e1a4-b96c-4a71-d116-18caa6b3624d-c53d362b" data-w-id="8802e1a4-b96c-4a71-d116-18caa6b3624d" href="#" className="af-class-button--secondry w-inline-block">
                              <div id="w-node-_2d4811d8-22d0-f6f3-7c0e-021f62a00b02-c53d362b" className="af-class-cart__button--secondry__text">Изменить</div>
                              <div id="w-node-_2d4811d8-22d0-f6f3-7c0e-021f62a00b04-c53d362b" className="af-class-cart__button--secondry__underline" />
                            </a>
                          </div>
                          <a id="w-node-b76a851b-74b5-5bf7-8654-c1a37339c376-c53d362b" href="#" className="af-class-button--text">Выйти из аккаунта</a>
                        </div>
                        <div style={{ display: 'none' }} className="w-layout-grid af-class-tab-wrapper--profile-edit">
                          <div className="af-class-tab-title">Профиль</div>
                          <div id="w-node-_0f6a70be-25dd-5e61-7034-765004b55d04-c53d362b" className="w-layout-grid af-class-tab-content--empty-profile">
                            <div id="w-node-_0f6a70be-25dd-5e61-7034-765004b55d05-c53d362b" className="af-class-tab-subtitle">Юридическое лицо</div>
                            <div id="w-node-_1b2a5a02-6dc5-9a2e-6525-93cccd181bdf-c53d362b" className="af-class-tab-form w-form">
                              <form action="#" id="wf-form-Business-Profile" name="wf-form-Business-Profile" data-name="Business Profile" method="get" className="af-class-tab-fields"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Last-Name" data-name="Last Name" placeholder="Фамилия" id="Last-Name" /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="First-Name" data-name="First-Name" placeholder="Имя" id="First-Name" /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Patronymic-Name" data-name="Patronymic Name" placeholder="Отчество" id="Patronymic-Name" /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Company-Name" data-name="Company Name" placeholder="Название компании" id="Company-Name" /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Company-Adress" data-name="Company Adress" placeholder="Адрес компании" id="Company-Adress" /><input type="number" className="af-class-cart__user-field w-input" maxLength={256} name="INN" data-name="INN" placeholder="ИНН" id="INN" />
                                <div id="w-node-aea9765f-ecdc-d9dc-b0d7-85189086259e-c53d362b" className="af-class-form-field-wrapper"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="KPP" data-name="KPP" placeholder="КПП" id="KPP" />
                                  <div className="af-class-question_btn af-class-in-field">
                                    <div className="af-class-availability__tooltip_block">
                                      <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                    </div>
                                  </div>
                                </div>
                                <div id="w-node-_98ffc2ff-7dd0-62ef-ed00-e9396a2328e7-c53d362b" className="af-class-form-field-wrapper"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="RS" data-name="RS" placeholder="Р/С" id="RS" />
                                  <div className="af-class-question_btn af-class-in-field">
                                    <div className="af-class-availability__tooltip_block">
                                      <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                    </div>
                                  </div>
                                </div>
                                <div id="w-node-_70061606-8112-3783-a0e7-edbe6ab1df38-c53d362b" className="af-class-form-field-wrapper"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="BIC" data-name="BIC" placeholder="БИК" id="BIC" />
                                  <div className="af-class-question_btn af-class-in-field">
                                    <div className="af-class-availability__tooltip_block">
                                      <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                    </div>
                                  </div>
                                </div><input type="tel" className="af-class-cart__user-field w-input" maxLength={256} name="Phone" data-name="Phone" placeholder="Телефон" id="Phone" /><input type="submit" defaultValue="Сохранить изменения" data-wait="Сохраняется..." id="w-node-_1b2a5a02-6dc5-9a2e-6525-93cccd181be7-c53d362b" data-w-id="1b2a5a02-6dc5-9a2e-6525-93cccd181be7" className="af-class-button--primary af-class-adress af-class-business-account__profile w-button" />
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: 'none' }} className="w-layout-grid af-class-tab-wrapper--profile">
                          <div className="af-class-tab-title">Личные данные</div>
                          <div id="w-node-cb903794-3e27-5710-aa2b-c50cca759c07-c53d362b" className="w-layout-grid af-class-tab-content--profile">
                            <div id="w-node-cb903794-3e27-5710-aa2b-c50cca759c08-c53d362b" className="af-class-tab-subtitle">Юридическое лицо</div>
                            <div id="w-node-_98e7e7de-fb07-8085-6bc3-16cdaa5ae97f-c53d362b" className="w-layout-grid af-class-tab-content-2--profile">
                              <div id="w-node-b6fc4b3c-c149-9e56-16a7-8a44c15aaef2-c53d362b" className="w-layout-grid af-class-tab-content-3--profile">
                                <div id="w-node-fdf5d968-26b9-79a3-00b9-136e6c84c3fe-c53d362b" className="w-layout-grid af-class-tab-content-4--profile">
                                  <div id="w-node-e115a062-78ab-3d18-747a-25808c8a5baa-c53d362b" className="af-class-tab-content__text--profile">Иванов Дмитрий Анатольевич</div>
                                  <div id="w-node-eff4c2a3-59f9-6890-a091-275f6f77f1cc-c53d362b" className="af-class-tab-content__text--profile">ООО «Весна»</div>
                                  <div className="af-class-tab-content__text--profile"><span>ИНН</span> 7712345678</div>
                                  <div id="w-node-_17302fb2-6dd5-e500-0ea0-717ec393f87b-c53d362b" className="af-class-tab-content__text--profile"><span>КПП</span> 779101001</div>
                                  <div id="w-node-_0c4243b7-1663-aeb7-37e0-8354b2f45cc9-c53d362b" className="af-class-tab-content__text--profile">123456, г. Москва, ул. Подвойского, д. 14, стр. 7</div>
                                  <div id="w-node-acce1f43-c8f4-18f0-2f25-edc20c1ad695-c53d362b" className="af-class-tab-content__text--profile"><span>Р/С</span> 40702810123450101230 в Московский банк ПАО Сбербанк г. Москва</div>
                                </div>
                                <a id="w-node-dda9ac3d-2053-94ee-a84a-75e26910447f-c53d362b" data-w-id="dda9ac3d-2053-94ee-a84a-75e26910447f" href="#" className="af-class-button--secondry w-inline-block">
                                  <div id="w-node-dda9ac3d-2053-94ee-a84a-75e269104480-c53d362b" className="af-class-cart__button--secondry__text">Изменить</div>
                                  <div id="w-node-dda9ac3d-2053-94ee-a84a-75e269104482-c53d362b" className="af-class-cart__button--secondry__underline" />
                                </a>
                              </div>
                              <a id="w-node-fdb98afa-b050-7d3c-0934-2d45910388b8-c53d362b" href="#" className="af-class-button--text">Выйти из аккаунта</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-w-tab="Tab 2" className="w-tab-pane">
                        <div style={{ display: 'block' }} className="af-class-tab-wrapper--adress-empty">
                          <div className="af-class-tab-title af-class-tab-adress--empty">Адреса</div>
                          <div id="w-node-_404a31db-6080-663c-d0b0-97fa9c32c7db-c53d362b" className="af-class-tab-divider af-class-tab-adress-empty--1" />
                          <div id="w-node-ca11b4fe-8f83-6d18-ef33-9a522403e86f-c53d362b" className="af-class-tab-adress__text">Нет даннных</div>
                          <div id="w-node-d4eed75a-e59c-5058-4f75-5310f9ba46bd-c53d362b" className="af-class-tab-divider af-class-tab-adress-empty--2" />
                          <a id="w-node-d943466a-82ef-7d6a-ef6e-b824cd88321a-c53d362b" data-w-id="d943466a-82ef-7d6a-ef6e-b824cd88321a" href="#" className="af-class-button--secondry w-inline-block">
                            <div id="w-node-d943466a-82ef-7d6a-ef6e-b824cd88321b-c53d362b" className="af-class-cart__button--secondry__text">Добавить новый</div>
                            <div id="w-node-d943466a-82ef-7d6a-ef6e-b824cd88321d-c53d362b" className="af-class-cart__button--secondry__underline" />
                          </a>
                        </div>
                        <div style={{ display: 'none' }} className="af-class-tab-wrapper--adress-edit">
                          <div className="af-class-tab-title af-class-tab-adress--edit">Адреса</div>
                          <div className="af-class-tab-form w-form">
                            <form action="#" id="wf-form-Business-Profile-Adress" name="wf-form-Business-Profile-Adress" data-name="Business Profile Adress" method="get" className="af-class-tab-fields"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="City" data-name="City" placeholder="Населенный пункт" id="City" />
                              <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-_467694d1-f501-2a19-50cb-0278fc89c26e-c53d362b" data-w-id="467694d1-f501-2a19-50cb-0278fc89c26e" className="af-class-g-select w-dropdown">
                                <div className="af-class-g-select__toggle w-dropdown-toggle">
                                  <div className="af-class-g-select__text">Район</div><img style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)' }} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                                </div>
                                <nav className="af-class-g-select__list w-dropdown-list"><select id="Area" name="Area" data-name="Area" required className="af-class-g-select__content w-select">
                                  <option value>Район</option>
                                  <option value="First">Район 1</option>
                                  <option value="Second">Район 2</option>
                                  <option value="Third">Район 3</option>
                                </select>
                                  <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                                    <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                                    <div id="w-node-_467694d1-f501-2a19-50cb-0278fc89c279-c53d362b" className="af-class-g-select__reset-divider" />
                                  </a>
                                  <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                                </nav>
                              </div><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Adress" data-name="Adress" placeholder="Улица, дом, корпус/строение" id="Adress" />
                              <div id="w-node-_79a7abaa-2f48-7960-7f7c-8a3d3bc4cad9-c53d362b" className="w-layout-grid af-class-tab-fields-group"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Entrance" data-name="Entrance" placeholder="Подъезд" id="Entrance" /><input type="text" className="af-class-cart__user-field w-node-b1c6cf4f-c6ac-b039-d63d-db038b5c11b0-c53d362b w-input" maxLength={256} name="Floor" data-name="Floor" placeholder="Этаж" id="Floor" /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Apartment" data-name="Apartment" placeholder="Квартира" id="Apartment" /><input type="text" className="af-class-cart__user-field w-node-b1c6cf4f-c6ac-b039-d63d-db038b5c11b2-c53d362b w-input" maxLength={256} name="Intercom" data-name="Intercom" placeholder="Домофон" id="Intercom" /></div><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Postcode" data-name="Postcode" placeholder="Почтовый индекс" id="Postcode" />
                              <div id="w-node-b1fdaf3e-a929-ee99-25d6-cef0802b5343-c53d362b" className="w-layout-grid af-class-tab-buttons-group"><input type="submit" defaultValue="Сохранить адрес" data-wait="Сохраняется..." id="w-node-b1c6cf4f-c6ac-b039-d63d-db038b5c11c6-c53d362b" data-w-id="b1c6cf4f-c6ac-b039-d63d-db038b5c11c6" className="af-class-button--primary af-class-adress w-button" />
                                <a id="w-node-_56667997-85ff-4609-dae7-b569b55c402f-c53d362b" href="#" className="af-class-button-2 af-class-adress w-button">Отменить</a>
                              </div>
                            </form>
                            <div className="w-form-done">
                              <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                              <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: 'none' }} className="af-class-tab-wrapper--adress">
                          <div className="af-class-tab-title af-class-tab-adress--empty">Адреса</div>
                          <div id="w-node-_68a93504-8a11-54c1-9820-6a55248e02d9-c53d362b" className="af-class-tab-divider af-class-tab-adress-empty--1" />
                          <div className="af-class-tab-form-__adress-wrapper w-form">
                            <form action="#" id="email-form" name="email-form" data-name="Email Form" method="get" className="af-class-tab-form-__adress-list">
                              <div id="w-node-_1a9ccc57-aeec-63da-30f2-991c2148d4ab-c53d362b" className="w-layout-grid af-class-tab-form-__adress-item"><label id="w-node-b4bf352f-288e-e00d-43fe-e0393a3827d6-c53d362b" className="af-class-g-radio-button--no-text af-class-adress w-radio"><input type="radio" name="radio" id="radio" defaultValue="Radio" data-name="Radio" className="w-form-formradioinput af-class-g-radio-button-icon--no-text w-radio-input" /><span className="af-class-g-radio-button-text--no-text w-form-label" htmlFor="radio">Radio</span></label>
                                <div id="w-node-ce936d56-2db6-bdd1-e9c9-7bef9b1ebb3c-c53d362b" className="w-layout-grid af-class-tab-form__adress-content">
                                  <div id="w-node-d862199c-2939-9103-3b79-94e0272c1bce-c53d362b" className="w-layout-grid af-class-tab-form__adress-head">
                                    <div id="w-node-_2a658d8d-0420-8160-6613-f2813be9fb5e-c53d362b" className="w-layout-grid af-class-tab-form__adress-title">
                                      <div id="w-node-e131afe9-20ff-1dac-4926-b4e925a9e0cd-c53d362b" className="af-class-tab-form__adress-title-text">Магазин на Садовом</div><img width={14} id="w-node-e8b6adc3-d4ce-bd7e-be19-7017b1dfa1fe-c53d362b" src="images/pencil_1pencil.png" loading="lazy" alt="" className="af-class-tab-form__adress-title-icon" />
                                    </div>
                                    <div id="w-node-_151e9585-feea-dc5b-88fe-0a74a0edb177-c53d362b" className="w-layout-grid af-class-tab-form__adress-lable">
                                      <div id="w-node-_46c256d5-3390-8039-c64b-32e01767bee9-c53d362b" className="af-class-tab-form__adress-lable-text">По умолчанию</div>
                                      <div className="af-class-question_btn af-class-control-p_catalog">
                                        <div className="af-class-availability__tooltip_block">
                                          <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="w-node-c1bd3231-a00c-60f6-79f0-79f1d766c638-c53d362b" className="w-layout-grid af-class-tab-form__adress-body">
                                    <div id="w-node-_5916fac3-b108-ff65-af14-7a429e85266a-c53d362b" className="af-class-tab-form__adress-text">Москва</div>
                                    <div id="w-node-b6a0acd3-10a3-b3e8-5f83-be34a56daac5-c53d362b" className="af-class-tab-form__adress-text">127521</div>
                                    <div id="w-node-ff87226e-2c95-c316-225b-e0d3df2bef6e-c53d362b" className="w-layout-grid af-class-tab-form__adress-street">
                                      <div id="w-node-d6ca5ade-33c8-fd81-6a04-3029b44c7a88-c53d362b" className="af-class-tab-form__adress-text">Ул.</div>
                                      <div id="w-node-_773e5379-0605-a6e6-3405-096759baf6d2-c53d362b" className="af-class-tab-form__adress-text">Октябрьская</div>
                                    </div>
                                    <div className="af-class-tab-form__adress-building">
                                      <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73066-c53d362b" className="af-class-tab-form__adress-floor-wrapper">
                                        <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73067-c53d362b" className="w-layout-grid af-class-tab-form__adress-floor">
                                          <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73068-c53d362b" className="af-class-tab-form__adress-text">Этаж </div>
                                          <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d7306a-c53d362b" className="af-class-tab-form__adress-text">14</div>
                                        </div>
                                        <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d7306c-c53d362b" className="af-class-tab-form__adress-text">,</div>
                                      </div>
                                      <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d7306e-c53d362b" className="af-class-tab-form__adress-apartment-wrapper">
                                        <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d7306f-c53d362b" className="w-layout-grid af-class-tab-form__adress-apartment">
                                          <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73070-c53d362b" className="af-class-tab-form__adress-text">квартира</div>
                                          <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73072-c53d362b" className="af-class-tab-form__adress-text">88</div>
                                        </div>
                                        <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73074-c53d362b" className="af-class-tab-form__adress-text">,</div>
                                      </div>
                                      <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73076-c53d362b" className="w-layout-grid af-class-tab-form__adress-intercom">
                                        <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73077-c53d362b" className="af-class-tab-form__adress-text">домофоy</div>
                                        <div id="w-node-_70abff6c-e130-2a50-bc1a-42ea54d73079-c53d362b" className="af-class-tab-form__adress-text">88 к</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="w-node-_388399c9-a0dd-328f-9799-26cbedefe194-c53d362b" className="w-layout-grid af-class-tab-form__adress-footer">
                                    <a id="w-node-_23197089-e32f-bfff-ca06-c8ac52d95100-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                                      <div id="w-node-_23197089-e32f-bfff-ca06-c8ac52d95101-c53d362b" className="af-class-cart__button--secondry__text">Изменить</div>
                                      <div id="w-node-_23197089-e32f-bfff-ca06-c8ac52d95103-c53d362b" className="af-class-cart__button--secondry__underline" />
                                    </a>
                                    <a id="w-node-dd126e2c-f4d5-a1b8-9c63-8a57d954bce8-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                                      <div id="w-node-dd126e2c-f4d5-a1b8-9c63-8a57d954bce9-c53d362b" className="af-class-cart__button--secondry__text">Удалить адрес</div>
                                      <div id="w-node-dd126e2c-f4d5-a1b8-9c63-8a57d954bceb-c53d362b" className="af-class-cart__button--secondry__underline" />
                                    </a>
                                  </div>
                                </div>
                                <div id="w-node-_68a93504-8a11-54c1-9820-6a55248e02dc-c53d362b" className="af-class-tab-divider" />
                              </div>
                              <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd133d-c53d362b" className="w-layout-grid af-class-tab-form-__adress-item"><label id="w-node-_97aec348-825f-7551-1ace-b5445fbd133e-c53d362b" className="af-class-g-radio-button--no-text af-class-adress w-radio"><input type="radio" name="radio" id="radio-2" defaultValue="Radio" data-name="Radio 2" className="w-form-formradioinput af-class-g-radio-button-icon--no-text w-radio-input" /><span className="af-class-g-radio-button-text--no-text w-form-label" htmlFor="radio-2">Radio</span></label>
                                <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1342-c53d362b" className="w-layout-grid af-class-tab-form__adress-content">
                                  <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1343-c53d362b" className="w-layout-grid af-class-tab-form__adress-head">
                                    <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1344-c53d362b" className="w-layout-grid af-class-tab-form__adress-title">
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1345-c53d362b" className="af-class-tab-form__adress-title-text">Магазин на Арбате</div><img width={14} id="w-node-_97aec348-825f-7551-1ace-b5445fbd1347-c53d362b" src="images/pencil_1pencil.png" loading="lazy" alt="" className="af-class-tab-form__adress-title-icon" />
                                    </div>
                                    <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1348-c53d362b" className="w-layout-grid af-class-tab-form__adress-lable af-class-display-none">
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1349-c53d362b" className="af-class-tab-form__adress-lable-text">По умолчанию</div>
                                      <div className="af-class-question_btn af-class-control-p_catalog">
                                        <div className="af-class-availability__tooltip_block">
                                          <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd134f-c53d362b" className="w-layout-grid af-class-tab-form__adress-body">
                                    <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1350-c53d362b" className="af-class-tab-form__adress-text">Москва</div>
                                    <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1352-c53d362b" className="af-class-tab-form__adress-text">127521</div>
                                    <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1354-c53d362b" className="w-layout-grid af-class-tab-form__adress-street">
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1355-c53d362b" className="af-class-tab-form__adress-text">Ул.</div>
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1357-c53d362b" className="af-class-tab-form__adress-text">Октябрьская</div>
                                    </div>
                                    <div className="af-class-tab-form__adress-building">
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd135a-c53d362b" className="af-class-tab-form__adress-floor-wrapper">
                                        <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd135b-c53d362b" className="w-layout-grid af-class-tab-form__adress-floor">
                                          <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd135c-c53d362b" className="af-class-tab-form__adress-text">Этаж </div>
                                          <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd135e-c53d362b" className="af-class-tab-form__adress-text">14</div>
                                        </div>
                                        <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1360-c53d362b" className="af-class-tab-form__adress-text">,</div>
                                      </div>
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1362-c53d362b" className="af-class-tab-form__adress-apartment-wrapper">
                                        <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1363-c53d362b" className="w-layout-grid af-class-tab-form__adress-apartment">
                                          <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1364-c53d362b" className="af-class-tab-form__adress-text">квартира</div>
                                          <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1366-c53d362b" className="af-class-tab-form__adress-text">88</div>
                                        </div>
                                        <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1368-c53d362b" className="af-class-tab-form__adress-text">,</div>
                                      </div>
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd136a-c53d362b" className="w-layout-grid af-class-tab-form__adress-intercom">
                                        <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd136b-c53d362b" className="af-class-tab-form__adress-text">домофон</div>
                                        <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd136d-c53d362b" className="af-class-tab-form__adress-text">88 к</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd136f-c53d362b" className="w-layout-grid af-class-tab-form__adress-footer">
                                    <a id="w-node-_97aec348-825f-7551-1ace-b5445fbd1370-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1371-c53d362b" className="af-class-cart__button--secondry__text">Изменить</div>
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1373-c53d362b" className="af-class-cart__button--secondry__underline" />
                                    </a>
                                    <a id="w-node-_97aec348-825f-7551-1ace-b5445fbd1374-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1375-c53d362b" className="af-class-cart__button--secondry__text">Удалить адрес</div>
                                      <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1377-c53d362b" className="af-class-cart__button--secondry__underline" />
                                    </a>
                                  </div>
                                </div>
                                <div id="w-node-_97aec348-825f-7551-1ace-b5445fbd1378-c53d362b" className="af-class-tab-divider" />
                              </div>
                            </form>
                            <div className="w-form-done">
                              <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                              <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                          </div>
                          <a id="w-node-_68a93504-8a11-54c1-9820-6a55248e02dd-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                            <div id="w-node-_68a93504-8a11-54c1-9820-6a55248e02de-c53d362b" className="af-class-cart__button--secondry__text">Добавить новый</div>
                            <div id="w-node-_68a93504-8a11-54c1-9820-6a55248e02e0-c53d362b" className="af-class-cart__button--secondry__underline" />
                          </a>
                        </div>
                      </div>
                      <div data-w-tab="Tab 3" className="w-tab-pane w--tab-active">
                        <div className="af-class-tab-wrapper--order-history">
                          <div className="af-class-tab-title af-class-tab-adress--empty">История заказов</div>
                          <div className="af-class-tab__filter-title">Адреса</div>
                          <div className="af-class-tab__order-history-filter w-form">
                            <form action="#" id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" className="af-class-tab__filter-content">
                              <div id="w-node-d407538c-7972-97f8-f413-282631eaedc2-c53d362b" className="w-layout-grid af-class-tab__checkbox-group"><label className="w-checkbox af-class-g-checkbox">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox" id="checkbox" data-name="Checkbox" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox">Магазин на Садовом</span>
                              </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-3" id="checkbox-3" data-name="Checkbox 3" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-3">Магазин на Арбате</span>
                                </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-2" id="checkbox-2" data-name="Checkbox 2" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-2">Магазин на ТТК</span>
                                </label></div>
                              <div id="w-node-_585a5985-af73-5de0-4c8d-d2d43b3f9c46-c53d362b" className="w-layout-grid af-class-tab__checkbox-group"><label className="w-checkbox af-class-g-checkbox">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-6" id="checkbox-6" data-name="Checkbox 6" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-6">Магазин на Садовом</span>
                              </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-3" id="checkbox-3" data-name="Checkbox 3" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-3">Магазин на Арбате</span>
                                </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-2" id="checkbox-2" data-name="Checkbox 2" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-2">Магазин на ТТК</span>
                                </label></div>
                              <div id="w-node-_5bbe913e-d61a-a907-1533-94c5c857f156-c53d362b" className="w-layout-grid af-class-tab__checkbox-group"><label className="w-checkbox af-class-g-checkbox">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-5" id="checkbox-5" data-name="Checkbox 5" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-5">Магазин на Садовом</span>
                              </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-3" id="checkbox-3" data-name="Checkbox 3" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-3">Магазин на Арбате</span>
                                </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-2" id="checkbox-2" data-name="Checkbox 2" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-2">Магазин на ТТК</span>
                                </label></div>
                              <div id="w-node-bec24745-e603-fb2d-1d04-d8270039627d-c53d362b" className="w-layout-grid af-class-tab__checkbox-group"><label className="w-checkbox af-class-g-checkbox">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-4" id="checkbox-4" data-name="Checkbox 4" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-4">Магазин на Садовом</span>
                              </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-3" id="checkbox-3" data-name="Checkbox 3" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-3">Магазин на Арбате</span>
                                </label><label className="w-checkbox af-class-g-checkbox">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon" /><input type="checkbox" name="checkbox-2" id="checkbox-2" data-name="Checkbox 2" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-2">Магазин на ТТК</span>
                                </label></div><input type="email" className="af-class-display-none w-node-_49e987fd-f88a-970d-2921-1532d1f45d09-c53d362b w-input" maxLength={256} name="field-3" data-name="Field 3" placeholder="Example Text" id="field-3" />
                            </form>
                            <div className="w-form-done">
                              <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                              <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                          </div>
                          <div id="w-node-e72eaff3-a106-f598-1d9e-156ac6dddb90-c53d362b" className="af-class-tab-divider af-class-tab-adress-empty--3" />
                          <div className="w-layout-grid af-class-order-history__column-titles">
                            <div id="w-node-_79147cc7-df42-7903-1c4e-a3e6f974edef-c53d362b" className="af-class-order-history__column-title">Номер</div>
                            <div id="w-node-_91c0af48-be67-8218-2ea9-f5aaf2e6e7ae-c53d362b" className="af-class-order-history__column-title">Дата</div>
                            <div id="w-node-ec8e990a-38e8-27cf-4b83-27233701ef27-c53d362b" className="af-class-order-history__column-title">Скидка</div>
                            <div id="w-node-_62c3811d-28ed-5774-7685-35f43cf26978-c53d362b" className="af-class-order-history__column-title">Сумма</div>
                            <div id="w-node-_3b98310d-6831-4bf7-da2b-87332daeeee4-c53d362b" className="af-class-order-history__column-title">Статус</div>
                          </div>
                          <div className="af-class-order-history__wrapper">
                            <div className="af-class-order-history__list">
                              <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf943-c53d362b" className="w-layout-grid af-class-order-history__title">
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf944-c53d362b" className="af-class-text-block-14">№51935965</div>
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf946-c53d362b" className="af-class-text-block-14-copy-copy-copy">Скидка</div>
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf948-c53d362b" className="af-class-text-block-14-copy-copy-copy">Сумма</div>
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf94a-c53d362b" className="af-class-text-block-14">23.12.21</div>
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf94c-c53d362b" className="af-class-text-block-14-copy">100<span> ₽</span></div>
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf950-c53d362b" className="af-class-text-block-14-copy">2300<span> ₽</span></div>
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf954-c53d362b" className="af-class-text-block-14-copy-copy">Собираем заказ</div>
                              </div><img data-w-id="efce9ca1-4d33-4000-8c9a-8972353cf956" id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf956-c53d362b" src="images/order-history__item__plus-icon.svg" loading="lazy" alt="" className="af-class-order__action-icon" />
                              <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf957-c53d362b" style={{ height: 0 }} className="af-class-order__wrapper">
                                <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf958-c53d362b" className="af-class-order__list">
                                  <div className="w-layout-grid af-class-order__item">
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf95a-c53d362b"><img id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf95b-c53d362b" loading="lazy" src="images/coffee--cart_1coffee--cart.png" alt="" /></div>
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf95c-c53d362b" className="w-layout-grid af-class-order__discription">
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf95d-c53d362b" className="af-class-order__title">Honduras Luis Castillo</div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf95f-c53d362b" className="af-class-order__composition">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                    </div>
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf961-c53d362b" className="w-layout-grid af-class-order__options">
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf962-c53d362b" className="af-class-order__options-text">250 г</div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf964-c53d362b" className="af-class-order__options-text">В зернах</div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf966-c53d362b" className="af-class-order__options-text">1 пачка</div>
                                    </div>
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf968-c53d362b" className="af-class-order__price">670<span> ₽</span></div>
                                  </div>
                                  <div className="w-layout-grid af-class-order__item">
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf96d-c53d362b"><img id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf96e-c53d362b" loading="lazy" src="images/coffee--cart_1coffee--cart.png" alt="" /></div>
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf96f-c53d362b" className="w-layout-grid af-class-order__discription">
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf970-c53d362b" className="af-class-order__title">Colombia Sanjuanero Decaf</div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf972-c53d362b" className="af-class-order__composition">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                    </div>
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf974-c53d362b" className="w-layout-grid af-class-grid-17-copy">
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf975-c53d362b" className="af-class-order__options-text">250 г</div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf977-c53d362b" className="af-class-order__options-text">В зернах</div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf979-c53d362b" className="af-class-order__options-text">1 пачка</div>
                                    </div>
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf97b-c53d362b" className="af-class-order__price">825<span> ₽</span></div>
                                  </div>
                                  <div className="w-layout-grid af-class-order__summary">
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf980-c53d362b" className="w-layout-grid af-class-order__summary-wrapper">
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf981-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf982-c53d362b" className="af-class-order__summary-text">Способ доставки:</div>
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf984-c53d362b" className="af-class-order__summary-text">Способ оплаты:</div>
                                      </div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf986-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf987-c53d362b" className="af-class-order__summary-text--2">СДЭК</div>
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf989-c53d362b" className="af-class-order__summary-text--2">При получении</div>
                                      </div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf98b-c53d362b" className="af-class-tab-divider" />
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf98c-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf98d-c53d362b" className="af-class-order__summary-text">Сумма заказа</div>
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf98f-c53d362b" className="af-class-order__summary-text">Скидка</div>
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf991-c53d362b" className="af-class-order__summary-text">Скидка</div>
                                      </div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf993-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf994-c53d362b" className="af-class-order__summary-text--2">2330<span> ₽</span></div>
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf998-c53d362b" className="af-class-order__summary-text--2">100<span> ₽</span></div>
                                        <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf99c-c53d362b" className="af-class-order__summary-text--2">2230<span> ₽</span></div>
                                      </div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a0-c53d362b" className="w-layout-grid af-class-order__summary-buttons-wrapper">
                                        <a id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a1-c53d362b" data-w-id="efce9ca1-4d33-4000-8c9a-8972353cf9a1" href="#" className="af-class-button--secondry w-inline-block">
                                          <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a2-c53d362b" className="af-class-cart__button--secondry__text">Оставить отзыв</div>
                                          <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a4-c53d362b" className="af-class-cart__button--secondry__underline" />
                                        </a>
                                        <a id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a5-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                                          <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a6-c53d362b" className="af-class-cart__button--secondry__text">Оформить подписку на заказ</div>
                                          <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a8-c53d362b" className="af-class-cart__button--secondry__underline" />
                                        </a>
                                      </div>
                                      <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9a9-c53d362b" className="af-class-text-block-20 af-class-_1">Магазин на садовом</div>
                                    </div>
                                    <div id="w-node-efce9ca1-4d33-4000-8c9a-8972353cf9ad-c53d362b" className="af-class-text-block-20 af-class-_2">Магазин на садовом</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-order-history__list">
                              <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfcc-c53d362b" className="w-layout-grid af-class-order-history__title">
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfcd-c53d362b" className="af-class-text-block-14">№51935965</div>
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfcf-c53d362b" className="af-class-text-block-14-copy-copy-copy">Скидка</div>
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfd1-c53d362b" className="af-class-text-block-14-copy-copy-copy">Сумма</div>
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfd3-c53d362b" className="af-class-text-block-14">23.12.21</div>
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfd5-c53d362b" className="af-class-text-block-14-copy">100<span> ₽</span></div>
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfd9-c53d362b" className="af-class-text-block-14-copy">2300<span> ₽</span></div>
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfdd-c53d362b" className="af-class-text-block-14-copy-copy af-class-delivered">Доставлен</div>
                              </div><img data-w-id="fcab5e62-d971-a7da-70d6-6a6fb8c0bfdf" id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfdf-c53d362b" src="images/order-history__item__plus-icon.svg" loading="lazy" alt="" className="af-class-order__action-icon" />
                              <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfe0-c53d362b" style={{ height: 0 }} className="af-class-order__wrapper">
                                <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfe1-c53d362b" className="af-class-order__list">
                                  <div className="w-layout-grid af-class-order__item">
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfe3-c53d362b"><img id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfe4-c53d362b" loading="lazy" src="images/coffee--cart_1coffee--cart.png" alt="" /></div>
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfe5-c53d362b" className="w-layout-grid af-class-order__discription">
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfe6-c53d362b" className="af-class-order__title">Honduras Luis Castillo</div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfe8-c53d362b" className="af-class-order__composition">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                    </div>
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfea-c53d362b" className="w-layout-grid af-class-order__options">
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfeb-c53d362b" className="af-class-order__options-text">250 г</div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfed-c53d362b" className="af-class-order__options-text">В зернах</div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bfef-c53d362b" className="af-class-order__options-text">1 пачка</div>
                                    </div>
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bff1-c53d362b" className="af-class-order__price">670<span> ₽</span></div>
                                  </div>
                                  <div className="w-layout-grid af-class-order__item">
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bff6-c53d362b"><img id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bff7-c53d362b" loading="lazy" src="images/coffee--cart_1coffee--cart.png" alt="" /></div>
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bff8-c53d362b" className="w-layout-grid af-class-order__discription">
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bff9-c53d362b" className="af-class-order__title">Colombia Sanjuanero Decaf</div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bffb-c53d362b" className="af-class-order__composition">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                                    </div>
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bffd-c53d362b" className="w-layout-grid af-class-grid-17-copy">
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0bffe-c53d362b" className="af-class-order__options-text">250 г</div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c000-c53d362b" className="af-class-order__options-text">В зернах</div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c002-c53d362b" className="af-class-order__options-text">1 пачка</div>
                                    </div>
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c004-c53d362b" className="af-class-order__price">825<span> ₽</span></div>
                                  </div>
                                  <div className="w-layout-grid af-class-order__summary">
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c009-c53d362b" className="w-layout-grid af-class-order__summary-wrapper">
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c00a-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c00b-c53d362b" className="af-class-order__summary-text">Способ доставки:</div>
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c00d-c53d362b" className="af-class-order__summary-text">Способ оплаты:</div>
                                      </div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c00f-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c010-c53d362b" className="af-class-order__summary-text--2">СДЭК</div>
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c012-c53d362b" className="af-class-order__summary-text--2">При получении</div>
                                      </div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c014-c53d362b" className="af-class-tab-divider" />
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c015-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c016-c53d362b" className="af-class-order__summary-text">Сумма заказа</div>
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c018-c53d362b" className="af-class-order__summary-text">Скидка</div>
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c01a-c53d362b" className="af-class-order__summary-text">Скидка</div>
                                      </div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c01c-c53d362b" className="w-layout-grid af-class-order__summary-group">
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c01d-c53d362b" className="af-class-order__summary-text--2">2330<span> ₽</span></div>
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c021-c53d362b" className="af-class-order__summary-text--2">100<span> ₽</span></div>
                                        <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c025-c53d362b" className="af-class-order__summary-text--2">2230<span> ₽</span></div>
                                      </div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c029-c53d362b" className="w-layout-grid af-class-order__summary-buttons-wrapper">
                                        <a id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c02a-c53d362b" data-w-id="fcab5e62-d971-a7da-70d6-6a6fb8c0c02a" href="#" className="af-class-button--secondry w-inline-block">
                                          <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c02b-c53d362b" className="af-class-cart__button--secondry__text">Оставить отзыв</div>
                                          <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c02d-c53d362b" className="af-class-cart__button--secondry__underline" />
                                        </a>
                                        <a id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c02e-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                                          <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c02f-c53d362b" className="af-class-cart__button--secondry__text">Оформить подписку на заказ</div>
                                          <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c031-c53d362b" className="af-class-cart__button--secondry__underline" />
                                        </a>
                                      </div>
                                      <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c032-c53d362b" className="af-class-text-block-20 af-class-_1">Магазин на садовом</div>
                                      <a id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c034-c53d362b" href="#" className="af-class-button--primary af-class-order-history w-button">Повторить заказ</a>
                                    </div>
                                    <div id="w-node-fcab5e62-d971-a7da-70d6-6a6fb8c0c036-c53d362b" className="af-class-text-block-20 af-class-_2">Магазин на садовом</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-w-tab="Tab 4" className="w-tab-pane">
                        <div className="w-layout-grid af-class-equipment__content">
                          <div className="w-layout-grid af-class-equipment__block">
                            <div className="af-class-tab__filter-title af-class-equipment">Адреса</div>
                            <div id="w-node-_9befd58a-be8e-0751-29d2-d853a1e590e2-c53d362b" className="w-layout-grid af-class-equipment__list">
                              <div id="w-node-_802ed5b3-5ef5-291f-56fa-b52f8bf3cf99-c53d362b" className="w-layout-grid af-class-equipment__item">
                                <div id="w-node-c2119898-e65f-87af-a368-a221f939489e-c53d362b" className="af-class-equipment__text">Кофемашина Nuova Simonelli Appia II 2 GR S</div>
                                <div id="w-node-_79b490e4-7c5a-e699-dd26-544c3a3e429d-c53d362b" className="af-class-equipment__text">48 999.00 ₽</div>
                              </div>
                              <div id="w-node-b0e2477b-b5ab-5c11-bf62-3ba6376a45b9-c53d362b" className="w-layout-grid af-class-equipment__item">
                                <div id="w-node-b0e2477b-b5ab-5c11-bf62-3ba6376a45ba-c53d362b" className="af-class-equipment__text">Кофемолка Fiorenzato F64 E Белый</div>
                                <div id="w-node-b0e2477b-b5ab-5c11-bf62-3ba6376a45bc-c53d362b" className="af-class-equipment__text">48 999.00 ₽</div>
                              </div>
                            </div>
                          </div>
                          <div className="w-layout-grid af-class-equipment__block">
                            <div className="af-class-tab__filter-title af-class-equipment">Фильтр</div>
                            <div id="w-node-_8cfdcb7f-44b2-1014-1d1d-c96ce69158b4-c53d362b" className="w-layout-grid af-class-equipment__list">
                              <div id="w-node-_8cfdcb7f-44b2-1014-1d1d-c96ce69158b5-c53d362b" className="w-layout-grid af-class-equipment__item">
                                <div id="w-node-_8cfdcb7f-44b2-1014-1d1d-c96ce69158b6-c53d362b" className="af-class-equipment__text">Кофемашина Nuova Simonelli Appia II 2 GR S</div>
                                <div id="w-node-_8cfdcb7f-44b2-1014-1d1d-c96ce69158b8-c53d362b" className="af-class-equipment__text">48 999.00 ₽</div>
                                <div id="w-node-_6e0b7997-a2f3-c494-52a1-c6cb4ad9871e-c53d362b" className="af-class-text-block-25">до смены картриджа осталось <span className="af-class-text-span-3">95</span> <span>дней</span></div>
                              </div>
                              <div id="w-node-_495f85fb-a34b-1e65-d28d-4d479222db75-c53d362b" className="af-class-equipment__form-block w-form">
                                <form action="#" id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="af-class-equipment__form"><label className="w-checkbox af-class-g-checkbox af-class-equipment-2">
                                  <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon af-class-_2" /><input type="checkbox" name="checkbox-7" id="checkbox-7" data-name="Checkbox 7" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text af-class-grey w-form-label" htmlFor="checkbox-7">Получать уведомления о сроке смены картриджа</span>
                                </label><input type="email" className="af-class-display-none w-input" maxLength={256} name="field-3" data-name="Field 3" placeholder="Example Text" id="field-3" /></form>
                                <div className="w-form-done">
                                  <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail">
                                  <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-layout-grid af-class-equipment__block af-class-form">
                            <div className="af-class-tab__filter-title af-class-equipment">Заявка на обслуживание</div>
                            <div id="w-node-b103c5f6-4c6d-ac3e-fbc1-fa6411a0a22b-c53d362b" className="af-class-equipment__text">Оставьте заявку на обслуживание оборудования. Наш менеджер свяжется с вами чтобы уточнить детали и согласовать дату.</div>
                            <div id="w-node-e451fe2c-ec27-7552-2daf-3d8952cf3c47-c53d362b" className="af-class-equipment__request-form-block w-form">
                              <form action="#" id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="af-class-equipment__request-form"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="First-Name-2" data-name="First Name 2" placeholder="Имя" id="First-Name-2" /><input type="tel" className="af-class-cart__user-field w-input" maxLength={256} name="Phone-2" data-name="Phone 2" placeholder="Телефон" id="Phone-2" /><label className="w-checkbox af-class-g-checkbox af-class-eqipment">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-g-checkbox-icon af-class-_2" /><input type="checkbox" name="checkbox-7" id="checkbox-7" data-name="Checkbox 7" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} /><span className="af-class-g-checkbox-text w-form-label" htmlFor="checkbox-7">Я&nbsp;принимаю условия <a href="#" className="af-class-link-2">Политики&nbsp;конфиденциальности</a></span>
                              </label><input type="submit" defaultValue="Отправить заявку" data-wait="Заявка отправляется..." id="w-node-fd3b7d17-f71e-161d-8978-3616826f677b-c53d362b" data-w-id="fd3b7d17-f71e-161d-8978-3616826f677b" className="af-class-button--primary af-class-eqipment w-button" /><input type="email" className="af-class-display-none w-node-_6b6d025a-dc99-12b8-03df-7de18e763a74-c53d362b w-input" maxLength={256} name="field-3" data-name="Field 3" placeholder="Example Text" id="field-3" /></form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-w-tab="Tab 5" className="w-tab-pane">
                        <div className="w-layout-grid af-class-loyalty-system__content">
                          <div className="af-class-tab-title">Система скидок</div>
                          <div id="w-node-ef850ca5-796a-1e86-99e0-a08e64bc4183-c53d362b" className="w-layout-grid af-class-discount-system__discription">
                            <div id="w-node-_4a75e7bd-2884-391a-85d3-8910c554fbaa-c53d362b" className="af-class-text-block-27">Максимальный размер скидки — 40%</div>
                            <div id="w-node-ccb99431-f96e-b226-e229-9d387fb275da-c53d362b" className="af-class-text-block-28">Размер скидки зависит от суммы единоразового заказа. Для получения скидки необходимо начать оформление заказа, сумма скидки рассчитается автоматически.</div>
                          </div>
                          <div id="w-node-_1c18077e-ff62-e0dc-87e7-8c3823776b36-c53d362b" className="af-class-discount-system__list">
                            <div id="w-node-fa23d235-ff53-5f54-bc62-180b25714054-c53d362b" className="w-layout-grid af-class-discount-system__item af-class-title">
                              <div id="w-node-_7d6f462e-7551-5410-bfc9-ab9e7e9df945-c53d362b" className="af-class-discount-system__title">Сумма заказа</div>
                              <div id="w-node-_6c5d1690-b786-85ae-2e6b-6338e861a081-c53d362b" className="af-class-discount-system__title">Кофе</div>
                              <div id="w-node-_0016b2b3-372c-2b2d-5813-7a078a7125a2-c53d362b" className="af-class-discount-system__title">Кофе +&nbsp;оборудование</div>
                            </div>
                            <div className="w-layout-grid af-class-discount-system__item af-class-item">
                              <div id="w-node-_9e10c990-e502-c120-88b7-91b5741066f1-c53d362b" className="af-class-discount-system__text">от 10 000 руб</div>
                              <div id="w-node-_6185ea11-b963-d6d3-7e36-61f746d6cf9b-c53d362b" className="af-class-discount-system__text">25%</div>
                              <div id="w-node-_56572850-23f2-6930-ef35-5a81b7085b8c-c53d362b" className="af-class-discount-system__text">25% + 550 руб</div>
                            </div>
                            <div className="w-layout-grid af-class-discount-system__item af-class-item">
                              <div id="w-node-b6191461-7a98-5eb3-fa76-f04a4e85f783-c53d362b" className="af-class-discount-system__text">от 15 000 руб</div>
                              <div id="w-node-b6191461-7a98-5eb3-fa76-f04a4e85f785-c53d362b" className="af-class-discount-system__text">30%</div>
                              <div id="w-node-b6191461-7a98-5eb3-fa76-f04a4e85f787-c53d362b" className="af-class-discount-system__text">30% + 450 руб</div>
                            </div>
                            <div className="w-layout-grid af-class-discount-system__item af-class-item">
                              <div id="w-node-_45eea68a-04ea-cb8f-5129-c74960cc5af9-c53d362b" className="af-class-discount-system__text">от 30 000 руб</div>
                              <div id="w-node-_45eea68a-04ea-cb8f-5129-c74960cc5afb-c53d362b" className="af-class-discount-system__text">35%</div>
                              <div id="w-node-_45eea68a-04ea-cb8f-5129-c74960cc5afd-c53d362b" className="af-class-discount-system__text">35% + 350 руб</div>
                            </div>
                            <div className="w-layout-grid af-class-discount-system__item af-class-item">
                              <div id="w-node-_5d7dacb7-6307-cbe9-2338-8ee4c6e7556f-c53d362b" className="af-class-discount-system__text">от 50 000 руб</div>
                              <div id="w-node-_5d7dacb7-6307-cbe9-2338-8ee4c6e75571-c53d362b" className="af-class-discount-system__text">40%</div>
                              <div id="w-node-_5d7dacb7-6307-cbe9-2338-8ee4c6e75573-c53d362b" className="af-class-discount-system__text">40% + 250 руб</div>
                            </div>
                          </div>
                          <div id="w-node-_82c0a4e0-978b-3fea-b629-1c1e5455effa-c53d362b" className="af-class-discount-system__list">
                            <div id="w-node-_82c0a4e0-978b-3fea-b629-1c1e5455effb-c53d362b" className="w-layout-grid af-class-discount-system__item af-class-title">
                              <div id="w-node-_82c0a4e0-978b-3fea-b629-1c1e5455effc-c53d362b" className="af-class-discount-system__title">Сумма заказа</div>
                              <div id="w-node-_82c0a4e0-978b-3fea-b629-1c1e5455effe-c53d362b" className="af-class-discount-system__title">Чай</div>
                            </div>
                            <div className="w-layout-grid af-class-discount-system__item af-class-item">
                              <div id="w-node-_82c0a4e0-978b-3fea-b629-1c1e5455f003-c53d362b" className="af-class-discount-system__text">от 10 000 руб</div>
                              <div id="w-node-_82c0a4e0-978b-3fea-b629-1c1e5455f005-c53d362b" className="af-class-discount-system__text">20%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-w-tab="Tab 6" className="w-tab-pane">
                        <div className="w-layout-grid af-class-news__header">
                          <div className="af-class-tab-title">Новости</div>
                          <a id="w-node-_5f9cb2ff-2833-e0b5-0d8c-33ea8d80dbf3-c53d362b" href="#" className="af-class-button--secondry w-inline-block">
                            <div id="w-node-_5f9cb2ff-2833-e0b5-0d8c-33ea8d80dbf4-c53d362b" className="af-class-cart__button--secondry__text af-class-grey">Отметить все как прочитанное</div>
                            <div id="w-node-_5f9cb2ff-2833-e0b5-0d8c-33ea8d80dbf6-c53d362b" className="af-class-cart__button--secondry__underline af-class-grey" />
                          </a>
                        </div>
                        <div className="w-layout-grid af-class-news__list">
                          <div className="af-class-news__item">
                            <div id="w-node-_97fc8bf7-e97b-cdc9-a61d-42181e5ed3c7-c53d362b" className="w-layout-grid af-class-news__wrapper">
                              <div id="w-node-_1fd9b18a-63f7-0aca-af0b-7050a8ee1ed0-c53d362b" className="af-class-news__text af-class-title">Обновление ассортимента</div>
                              <div id="w-node-d3a40d58-c8ff-2be0-958c-ae6e2ece1733-c53d362b" className="af-class-news__text">25.01.2022</div>
                              <div id="w-node-cf3a4ed0-25dd-75f5-3659-3a6cfe023ada-c53d362b" data-w-id="cf3a4ed0-25dd-75f5-3659-3a6cfe023ada" className="af-class-news__icon-wrapper"><img loading="lazy" src="images/order-history__item__plus-icon.svg" alt="" className="af-class-news__icon-image" /></div>
                              <div id="w-node-_7bde0fcd-e683-b351-3e51-561aab8b37ac-c53d362b" style={{ height: 0 }} className="af-class-news__content">
                                <div className="w-layout-grid af-class-news__body">
                                  <div id="w-node-a0a8a044-fbd4-9a8c-d93c-f71b38470d69-c53d362b" className="af-class-news__text-2">Способы приготовления кофе можно разделить на две категории: эспрессо (т.е. с использованием эспрессо-машины) и все остальные способы, которые обобщенно называются альтернативными. Зерно для различных способов приготовления кофе жарится по-разному.<br />Под эспрессо подходит темная обжарка. Это связано с тем, что кофе в эспрессо-машине готовится при высоким давлением и высокой температуре воды за короткое время. Таким образом, зерну необходимо передать свой вкус быстрее, а процесс передачи происходит интенсивнее. За счет особенностей строения и состава зерна именно более темная обжарка позволяет вкусу раскрыться.<br />При альтернативных же способах заваривания зерно дольше контактирует с водой и не подвергается такому давлению, как в эспрессо-машине, что позволяет зерну раскрыть более тонкие оттенки вкуса. Именно поэтому кофе обжаривают светлее.</div>
                                  <div id="w-node-_9fedfa64-3852-8231-c763-0303bd4b00b5-c53d362b" className="af-class-news__image-wrapper"><img width={458} sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 1279px) 33vw, (max-width: 1439px) 34vw, 30vw" src="images/image-280.jpg" loading="lazy" srcSet="images/image-280-p-500.jpeg 500w, images/image-280-p-800.jpeg 800w, images/image-280.jpg 916w" alt="" className="af-class-news__image" /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="af-class-news__item">
                            <div id="w-node-ed4e25e7-9a9a-435b-4d67-d008edb6906a-c53d362b" className="w-layout-grid af-class-news__wrapper">
                              <div id="w-node-ed4e25e7-9a9a-435b-4d67-d008edb6906b-c53d362b" className="af-class-news__text af-class-title af-class-read">Три новых вкуса</div>
                              <div id="w-node-ed4e25e7-9a9a-435b-4d67-d008edb6906d-c53d362b" className="af-class-news__text">25.01.2022</div>
                              <div id="w-node-ed4e25e7-9a9a-435b-4d67-d008edb6906f-c53d362b" data-w-id="ed4e25e7-9a9a-435b-4d67-d008edb6906f" className="af-class-news__icon-wrapper"><img loading="lazy" src="images/order-history__item__plus-icon.svg" alt="" /></div>
                              <div id="w-node-ed4e25e7-9a9a-435b-4d67-d008edb69071-c53d362b" style={{ height: 0 }} className="af-class-news__content">
                                <div className="w-layout-grid af-class-news__body">
                                  <div id="w-node-ed4e25e7-9a9a-435b-4d67-d008edb69073-c53d362b" className="af-class-news__text-2">Способы приготовления кофе можно разделить на две категории: эспрессо (т.е. с использованием эспрессо-машины) и все остальные способы, которые обобщенно называются альтернативными. Зерно для различных способов приготовления кофе жарится по-разному.<br />Под эспрессо подходит темная обжарка. Это связано с тем, что кофе в эспрессо-машине готовится при высоким давлением и высокой температуре воды за короткое время. Таким образом, зерну необходимо передать свой вкус быстрее, а процесс передачи происходит интенсивнее. За счет особенностей строения и состава зерна именно более темная обжарка позволяет вкусу раскрыться.<br />При альтернативных же способах заваривания зерно дольше контактирует с водой и не подвергается такому давлению, как в эспрессо-машине, что позволяет зерну раскрыть более тонкие оттенки вкуса. Именно поэтому кофе обжаривают светлее.</div>
                                  <div id="w-node-ed4e25e7-9a9a-435b-4d67-d008edb69079-c53d362b" className="af-class-news__image-wrapper"><img width={458} sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 1279px) 33vw, (max-width: 1439px) 34vw, 30vw" src="images/image-280.jpg" loading="lazy" srcSet="images/image-280-p-500.jpeg 500w, images/image-280-p-800.jpeg 800w, images/image-280.jpg 916w" alt="" className="af-class-news__image" /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="af-class-news__item">
                            <div id="w-node-_936f9bec-4f51-a47d-9ca2-40f41b4d453c-c53d362b" className="w-layout-grid af-class-news__wrapper">
                              <div id="w-node-_936f9bec-4f51-a47d-9ca2-40f41b4d453d-c53d362b" className="af-class-news__text af-class-title af-class-read">Новые сорта</div>
                              <div id="w-node-_936f9bec-4f51-a47d-9ca2-40f41b4d453f-c53d362b" className="af-class-news__text">25.01.2022</div>
                              <div id="w-node-_936f9bec-4f51-a47d-9ca2-40f41b4d4541-c53d362b" data-w-id="936f9bec-4f51-a47d-9ca2-40f41b4d4541" className="af-class-news__icon-wrapper"><img loading="lazy" src="images/order-history__item__plus-icon.svg" alt="" /></div>
                              <div id="w-node-_936f9bec-4f51-a47d-9ca2-40f41b4d4543-c53d362b" style={{ height: 0 }} className="af-class-news__content">
                                <div className="w-layout-grid af-class-news__body">
                                  <div id="w-node-_936f9bec-4f51-a47d-9ca2-40f41b4d4545-c53d362b" className="af-class-news__text-2">Способы приготовления кофе можно разделить на две категории: эспрессо (т.е. с использованием эспрессо-машины) и все остальные способы, которые обобщенно называются альтернативными. Зерно для различных способов приготовления кофе жарится по-разному.<br />Под эспрессо подходит темная обжарка. Это связано с тем, что кофе в эспрессо-машине готовится при высоким давлением и высокой температуре воды за короткое время. Таким образом, зерну необходимо передать свой вкус быстрее, а процесс передачи происходит интенсивнее. За счет особенностей строения и состава зерна именно более темная обжарка позволяет вкусу раскрыться.<br />При альтернативных же способах заваривания зерно дольше контактирует с водой и не подвергается такому давлению, как в эспрессо-машине, что позволяет зерну раскрыть более тонкие оттенки вкуса. Именно поэтому кофе обжаривают светлее.</div>
                                  <div id="w-node-_936f9bec-4f51-a47d-9ca2-40f41b4d454b-c53d362b" className="af-class-news__image-wrapper"><img width={458} sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 1279px) 33vw, (max-width: 1439px) 34vw, 30vw" src="images/image-280.jpg" loading="lazy" srcSet="images/image-280-p-500.jpeg 500w, images/image-280-p-800.jpeg 800w, images/image-280.jpg 916w" alt="" className="af-class-news__image" /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="af-class-news__item">
                            <div id="w-node-_14229a61-3ae8-797b-6a23-ad7d97a7ab3a-c53d362b" className="w-layout-grid af-class-news__wrapper">
                              <div id="w-node-_14229a61-3ae8-797b-6a23-ad7d97a7ab3b-c53d362b" className="af-class-news__text af-class-title af-class-read">Новое поступление Колумбия ла Прадера</div>
                              <div id="w-node-_14229a61-3ae8-797b-6a23-ad7d97a7ab3d-c53d362b" className="af-class-news__text">25.01.2022</div>
                              <div id="w-node-_14229a61-3ae8-797b-6a23-ad7d97a7ab3f-c53d362b" data-w-id="14229a61-3ae8-797b-6a23-ad7d97a7ab3f" className="af-class-news__icon-wrapper"><img loading="lazy" src="images/order-history__item__plus-icon.svg" alt="" /></div>
                              <div id="w-node-_14229a61-3ae8-797b-6a23-ad7d97a7ab41-c53d362b" style={{ height: 0 }} className="af-class-news__content">
                                <div className="w-layout-grid af-class-news__body">
                                  <div id="w-node-_14229a61-3ae8-797b-6a23-ad7d97a7ab43-c53d362b" className="af-class-news__text-2">Способы приготовления кофе можно разделить на две категории: эспрессо (т.е. с использованием эспрессо-машины) и все остальные способы, которые обобщенно называются альтернативными. Зерно для различных способов приготовления кофе жарится по-разному.<br />Под эспрессо подходит темная обжарка. Это связано с тем, что кофе в эспрессо-машине готовится при высоким давлением и высокой температуре воды за короткое время. Таким образом, зерну необходимо передать свой вкус быстрее, а процесс передачи происходит интенсивнее. За счет особенностей строения и состава зерна именно более темная обжарка позволяет вкусу раскрыться.<br />При альтернативных же способах заваривания зерно дольше контактирует с водой и не подвергается такому давлению, как в эспрессо-машине, что позволяет зерну раскрыть более тонкие оттенки вкуса. Именно поэтому кофе обжаривают светлее.</div>
                                  <div id="w-node-_14229a61-3ae8-797b-6a23-ad7d97a7ab49-c53d362b" className="af-class-news__image-wrapper"><img width={458} sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 1279px) 33vw, (max-width: 1439px) 34vw, 30vw" src="images/image-280.jpg" loading="lazy" srcSet="images/image-280-p-500.jpeg 500w, images/image-280-p-800.jpeg 800w, images/image-280.jpg 916w" alt="" className="af-class-news__image" /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            {/* [if lte IE 9]><![endif] */}
            {/*  Comment  */}
          </div>
        </span>
      </span>
    )
  }
}

export default AccountBusinessView

/* eslint-enable */