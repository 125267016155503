/* eslint-disable */

import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Identificator from "../components/Product/Identificator";
import Product from "../components/Product/Product";
import ProductImage from "../components/ProductPage/ProductImage";
import Review from '../components/Reviews/Review';
import ProductReviewForm from '../components/ProductPage/ProductReviewForm';
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import {
  fetchProductsById,
  fetchProductsBySlug,
  fetchProductReviews,
  fetchCustomReviews,
  fetchCustomPosts,
  createProductReview,
  fetchMedias,
  fetchCurrentUserOrders,
  fetchUsers,
  fetchUsersMeta,
  fetchUsersIdsByEmails,
} from "../fetch/WPAPI";
import { MainContext } from "../contexts/MainContext";
import useProduct from "../hooks/useProduct";

import { createScope, map, transformProxies } from "./helpers";

const ProductPageView = () => {
  const params = useParams();
  const mainContext = useContext(MainContext);
  const navigate = useNavigate();

  const [activeTabHowTo, setActiveTabHowTo] = useState(0);
  const [activeTabCulture, setActiveTabCulture] = useState(1);

  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [allResponses, setAllResponses] = useState([]);

  const [users, setUsers] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);

  const [posts, setPosts] = useState([]);
  const [medias, setMedias] = useState([]);
  const [featuredPosts1, setFeaturedPosts1] = useState([]);
  const [featuredPosts2, setFeaturedPosts2] = useState([]);
  const [featuredPostsAll, setFeaturedPostsAll] = useState([]);

  const [item, setItem] = useState({});
  const [brewingPictures, setBrewingPictures] = useState([]);

  const [userOrders, setUserOrders] = useState([]);

  // Review Popup Form
  const [reviewPopupOpened, setReviewPopupOpened] = useState(false);

  useEffect(() => {
    fetchCustomPosts("znania").then((result) => {
      setPosts(result);
      setFeaturedPosts1(
        result
          .filter((elem) => elem.kategory.indexOf(429) !== -1)
          .sort((a, b) => 0.5 - Math.random())
          ?.slice(0, 4)
      );
      setFeaturedPosts2(
        result
          .filter((elem) => elem.kategory.indexOf(430) !== -1)
          .sort((a, b) => 0.5 - Math.random())
          ?.slice(0, 4)
      );
      setFeaturedPostsAll(
        result
          .filter(
            (elem) =>
              elem.kategory.indexOf(429) !== -1 ||
              elem.kategory.indexOf(430) !== -1
          )
          .sort((a, b) => 0.5 - Math.random())
          ?.slice(0, 4)
      );

      let media_ids = result.map((elem) => elem.featured_media);
      fetchMedias(media_ids).then((result) => {
        setMedias(result);
      });
    });
  }, []);

  useEffect(() => {
    if (mainContext.userData.id) {
      fetchCurrentUserOrders(mainContext.userData.id).then((result) => {
        setUserOrders(result);
      });
    }
  }, [mainContext.userData]);

  const productHandle = useProduct({});

  useEffect(() => {
    let item = null;

    mainContext.catalogItems.forEach((category) => {
      let itemSearch = category.items.find(
        (elem) => elem.slug == encodeURIComponent(params.slug).toLowerCase()
      );
      if (itemSearch) {
        item = itemSearch;
        setItem(item);
      }
    });

    if (item) {
      productHandle.setCurrentProduct(item);
    } else {
      fetchProductsBySlug([params.slug]).then((result) => {
        if (result.length > 0) {
          productHandle.setCurrentProduct(result[0]);
          setItem(result[0]);
        }
      });
    }
  }, [params.slug]);

  useEffect(() => {
    if (item.id) {
      fetchProductReviews(item.id).then((result) => {
        console.log(result);
        handleReviews(result);
      });

      // Responses
    fetchCustomReviews(521)
      .then(result => {
        setAllResponses({data: result.map(elem => { return {
          id: elem.id,
          response_to: elem.acf.response_to,
          date: elem.date,
          label: null,
          link: null,
          rating: null,
          text: elem.content.rendered,
          user_id: elem.author,
          reviewer: null,
          reviewer_avatar_urls: null,
        }})})
      })

      document.title = item?.yoast_head_json?.title;
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", item?.yoast_head_json?.description);
    }
  }, [item]);

  const handleReviews = (result) => {
    // setReviews(result);

    setReviews(result.map(elem => { return {
      id: elem.id,
      date: elem.date ? elem.date : elem.date_created,
      label: elem.label ? elem.label : elem.product_name,
      link: elem.link ? elem.link : (elem.product_permalink ? elem.product_permalink.split('/')[elem.product_permalink.split('/').length - 2] : null),
      rating: elem.rating,
      text: elem.text ? elem.text : elem.review,
      user_id: null,
      reviewer: elem.reviewer,
      reviewer_email: elem.reviewer_email,
      reviewer_avatar_urls: elem.reviewer_avatar_urls,
    }}));

    let user_emails = [...new Set(result.map((elem) => elem.reviewer_email))];
    if (user_emails.length > 0) {
      fetchUsersIdsByEmails(user_emails).then((result) => {
        if (result.length > 0) {
          result.forEach((elem) => {
            setReviews((prevState) =>
              prevState.map((review) => {
                if (review.reviewer_email != elem.email) {
                  return review;
                } else {
                  return { ...review, user_id: elem.id };
                }
              })
            );
          });

          let user_ids = result.map((elem) => elem.id);

          fetchUsers(user_ids).then((result) => {
            console.log(result);
            setUsers((prevState) => [...prevState, ...result]);
          });

          fetchUsersMeta(user_ids).then((result) => {
            console.log(result);
            setUsersMeta((prevState) => [...prevState, ...result]);
          });
        }
      });
    }
  };

  useEffect(() => {
    if (item.id && mainContext.brewingMethods.length > 0) {
      let brewingOptions = item?.attributes?.find(
        (elem) => elem.id == 1
      )?.options;
      if (brewingOptions) {
        let brewingPicturesIds = [].concat(
          ...brewingOptions.map((elem) => {
            if (elem == "Кофеварка капельная") {
              elem = "Капельная кофеварка";
            }
            let methods = mainContext.brewingMethods.find(
              (method) => method.title.rendered == elem
            )?.acf?.способы_заваривания;
            if (methods) {
              return methods.map((method) => method.изображение);
            } else {
              return [];
            }
          })
        );

        if (brewingPicturesIds.length > 0) {
          fetchMedias(brewingPicturesIds).then((result) => {
            setBrewingPictures(result);
          });
        }
      }
    }
  }, [item, mainContext.brewingMethods]);

  useEffect(() => {
    setRating(
      parseFloat(
        reviews.reduce((accumulator, elem) => accumulator + elem.rating, 0) /
          reviews.length
      ).toFixed(1)
    );
  }, [reviews]);

  const [recentlyProducts, setRecentlyProducts] = useState([]);

  useEffect(() => {
    console.log(productHandle)
    if (productHandle.currentProduct.id) {
      // setRecentlyProducts(JSON.parse(localStorage.getItem("recentlySeen")));
      console.log(recentlyProducts);
      let storageItems = JSON.parse(localStorage.getItem("recentlySeen"));
      let newRecentItems = [];
      if (storageItems) {
        newRecentItems = storageItems.filter(
          (elem) => elem.id != productHandle.currentProduct.id
        );
      }
      newRecentItems.unshift(productHandle.currentProduct);
      //console.log(newRecentItems);

      //mainContext.setRecentlySeen(newRecentItems);
      setRecentlyProducts(newRecentItems);
      localStorage.setItem("recentlySeen", JSON.stringify(newRecentItems));
    }
  }, [productHandle.currentProduct]);

  let howtobrewTarget;
  let howtobrewPos = { top: 0, left: 0, x: 0, y: 0 };

  const mouseDownHandlerHowtobrew = (e) => {
    e.preventDefault();

    howtobrewTarget = e.target;
    while (!howtobrewTarget.classList.contains("af-class-splide")) {
      howtobrewTarget = howtobrewTarget.parentElement;
    }

    howtobrewPos = {
      // The current scroll
      left: howtobrewTarget.scrollLeft,
      // Get the current mouse position
      x: e.clientX,
    };

    document.addEventListener("mousemove", mouseMoveHandlerHowtobrew);
    document.addEventListener("mouseup", mouseUpHandlerHowtobrew);
  };

  const mouseMoveHandlerHowtobrew = (e) => {
    const dx = e.clientX - howtobrewPos.x;
    const scrollPos = howtobrewPos.left - dx;
    howtobrewTarget.scrollLeft = scrollPos;
  };

  const mouseUpHandlerHowtobrew = (e) => {
    document.removeEventListener("mousemove", mouseMoveHandlerHowtobrew);
    document.removeEventListener("mouseup", mouseUpHandlerHowtobrew);
  };

  const ExtraContentCart = () => {
    console.log(productHandle.currentProduct);
    if (!productHandle.currentProduct.categories) {
      return <></>;
    } else {
      return (productHandle.currentProduct.categories.find((elem) => elem.id == 17) && !productHandle.currentProduct.categories.find((elem) => elem.id == 416)) ? (
        <>
          {productHandle.weightsVariations &&
            productHandle.weightsVariations.length > 0 && (
              <>
                <div
                  data-hover="false"
                  data-delay={0}
                  className="af-class-button-options-card-p_catalog w-dropdown"
                >
                  <div
                    className="af-class-dropdown-toggle w-dropdown-toggle"
                    onClick={() =>
                      productHandle.setWeightDropdown(
                        !productHandle.weightDropdown
                      )
                    }
                  >
                    <div className="af-class-text-button-card-p_catalog">
                      {productHandle.selectedWeight}
                    </div>
                    <img
                      src="/images/arrow-down.svg"
                      loading="lazy"
                      width={7}
                      alt=""
                      className="af-class-icon-button-card-p_catalog"
                    />
                  </div>
                  <nav
                    className="w-dropdown-list"
                    style={{
                      display:
                        productHandle.weightDropdown === false
                          ? "none"
                          : "block",
                    }}
                  >
                    <div className="af-class-dropdown-list af-class-type">
                      {productHandle.weightsVariations.map((value, index) => {
                        return (
                          <a
                            key={index}
                            className="af-class-text-dropdown-grey w-dropdown-link"
                            onClick={() =>
                              productHandle.handleChangeWeight(value)
                            }
                          >
                            {value}
                          </a>
                        );
                      })}
                    </div>
                  </nav>
                </div>
              </>
            )}

          {productHandle.grindsVariations &&
            productHandle.grindsVariations.length > 0 && (
              <>
                <div
                  data-hover="false"
                  data-delay={0}
                  className="af-class-button-options-card-p_catalog w-dropdown"
                >
                  <div
                    className="af-class-dropdown-toggle w-dropdown-toggle"
                    onClick={() =>
                      productHandle.setTypeDropdown(!productHandle.typeDropdown)
                    }
                  >
                    <div className="af-class-text-button-card-p_catalog">
                      {productHandle.selectedType}
                    </div>
                    <img
                      src="/images/arrow-down.svg"
                      loading="lazy"
                      width={7}
                      alt=""
                      className="af-class-icon-button-card-p_catalog"
                    />
                  </div>
                  <nav
                    className="w-dropdown-list"
                    style={{
                      display:
                        productHandle.typeDropdown === false ? "none" : "block",
                    }}
                  >
                    <div className="af-class-dropdown-list af-class-type">
                      {productHandle.grindsVariations.map((value, index) => {
                        return (
                          <a
                            key={index}
                            className="af-class-text-dropdown-grey w-dropdown-link"
                            onClick={() =>
                              productHandle.handleChangeType(value)
                            }
                          >
                            {value}
                          </a>
                        );
                      })}
                    </div>
                  </nav>
                </div>
              </>
            )}

          <div
            id="w-node-_0af0d67f-383d-9e71-75b0-9b427c18f25f-5f3d3641"
            className="af-class-product__options-block af-class-qty af-class-last"
          >
            <div
              className="af-class-option_text af-class-minus"
              onClick={productHandle.handleQtyLower}
            >
              –
            </div>
            <div className="af-class-option_text af-class-qty">
              {productHandle.selectedQty}
            </div>
            <div
              className="af-class-option_text af-class-plus"
              onClick={productHandle.handleQtyRaise}
            >
              +
            </div>
          </div>
        </>
      ) : productHandle.currentProduct?.variations?.length > 0 ? (
        <>
          {productHandle.optionsVariations &&
            productHandle.optionsVariations.length > 0 && (
              <>
                <div
                  data-hover="false"
                  data-delay={0}
                  className="af-class-button-options-card-p_catalog w-dropdown"
                >
                  <div
                    className="af-class-dropdown-toggle w-dropdown-toggle"
                    onClick={() =>
                      productHandle.setOptionDropdown(
                        !productHandle.optionDropdown
                      )
                    }
                  >
                    <div className="af-class-text-button-card-p_catalog">
                      {productHandle.selectedOption}
                    </div>
                    <img
                      src="/images/arrow-down.svg"
                      loading="lazy"
                      width={7}
                      alt=""
                      className="af-class-icon-button-card-p_catalog"
                    />
                  </div>
                  <nav
                    className="w-dropdown-list"
                    style={{
                      display:
                        productHandle.optionDropdown === false
                          ? "none"
                          : "block",
                    }}
                  >
                    <div className="af-class-dropdown-list">
                      {productHandle.optionsVariations.map((value, index) => {
                        return (
                          <a
                            key={index}
                            className="af-class-text-dropdown-grey w-dropdown-link"
                            onClick={() =>
                              productHandle.handleChangeOption(value)
                            }
                          >
                            {value}
                          </a>
                        );
                      })}
                    </div>
                  </nav>
                </div>
              </>
            )}

          <div
            id="w-node-_0af0d67f-383d-9e71-75b0-9b427c18f25f-5f3d3641"
            className="af-class-product__options-block af-class-qty af-class-last"
          >
            <div
              className="af-class-option_text af-class-minus"
              onClick={productHandle.handleQtyLower}
            >
              –
            </div>
            <div className="af-class-option_text af-class-qty">
              {productHandle.selectedQty}
            </div>
            <div
              className="af-class-option_text af-class-plus"
              onClick={productHandle.handleQtyRaise}
            >
              +
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="w-node-_0af0d67f-383d-9e71-75b0-9b427c18f25f-5f3d3641"
            className="af-class-product__options-block af-class-qty af-class-last"
          >
            <div
              className="af-class-option_text af-class-minus"
              onClick={productHandle.handleQtyLower}
            >
              –
            </div>
            <div className="af-class-option_text af-class-qty">
              {productHandle.selectedQty}
            </div>
            <div
              className="af-class-option_text af-class-plus"
              onClick={productHandle.handleQtyRaise}
            >
              +
            </div>
          </div>
        </>
      );
    }
  };

  const ExtraContentDescription = () => {
    return productHandle.currentProduct?.categories?.find(
      (elem) => elem.id == 17
    ) &&
      productHandle.currentProduct.categories?.filter((value) =>
        [107, 416].includes(value.id)
      ).length == 0 ? (
      <>
        {/* <div className="af-class-product__tags">
          <div className="af-class-product__tags_ingredients">Яблочный&nbsp;сидр</div>
          <div className="af-class-product__tags_ingredients">Малиновый&nbsp;джем</div>
          <div className="af-class-product__tags_ingredients">Апельсин</div>
        </div> */}

        <div className="af-class-product__specifications">
          <div className="af-class-specifications__block">
            <div className="af-class-indicator_text_wrap">
              <div className="af-class-specifications__lable">Горечь</div>
              {/* <div className="af-class-question_btn">
                <div className="af-class-availability__tooltip_block">
                  <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                </div>
              </div> */}
            </div>
            <div className="af-class-specifications__indicators">
              <Identificator
                size="big"
                count={
                  Number(
                    productHandle.currentProduct.attributes &&
                      productHandle.currentProduct.attributes.find(
                        (a) => a.id === 9
                      )?.options[0]
                  ) || 5
                }
              />
            </div>
          </div>
          <div className="af-class-specifications__block">
            <div className="af-class-indicator_text_wrap">
              <div className="af-class-specifications__lable">Сладость</div>
              {/* <div className="af-class-question_btn">
                <div className="af-class-availability__tooltip_block">
                  <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                </div>
              </div> */}
            </div>
            <div className="af-class-specifications__indicators">
              <Identificator
                size="big"
                count={
                  Number(
                    productHandle.currentProduct.attributes &&
                      productHandle.currentProduct.attributes.find(
                        (a) => a.id === 10
                      )?.options[0]
                  ) || 5
                }
              />
            </div>
          </div>
          <div className="af-class-specifications__block">
            <div className="af-class-indicator_text_wrap">
              <div className="af-class-specifications__lable">Кислотность</div>
              {/* <div className="af-class-question_btn">
                <div className="af-class-availability__tooltip_block">
                  <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                </div>
              </div> */}
            </div>
            <div className="af-class-specifications__indicators">
              <Identificator
                size="big"
                count={
                  Number(
                    productHandle.currentProduct.attributes &&
                      productHandle.currentProduct.attributes.find(
                        (a) => a.id === 11
                      )?.options[0]
                  ) || 5
                }
              />
            </div>
          </div>
        </div>

        <div className="af-class-product__methods">
          <div className="af-class-specifications__lable">
            Способы заваривания
          </div>
          <div className="af-class-product__methods-cards">
            {productHandle.currentProduct?.attributes
              ?.find((elem) => elem.id == 1)
              ?.options.map((elem, index) => {
                if (elem == "Кофеварка капельная") {
                  return <></>;
                }
                return (
                  <div key={index} className="af-class-method_card-wrapper">
                    <div className="af-class-method_card">
                      {/* <img src={`/images/${elem == 'американ пресс' ? 'voronka.svg' : elem == 'аэропресс' ? 'voronka.svg' : elem == 'воронка' ? 'voronka.svg' : elem == 'Гейзер' ? 'geizer.svg' : elem == 'Калита' ? 'voronka.svg' : elem == 'Кемекс' ? 'voronka.svg' : elem == 'Кофеварка капельная' ? 'voronka.svg' : elem == 'Кофемашина' ? 'voronka.svg' : elem == 'Турка' ? 'turka.svg' : elem == 'Френч-пресс' ? 'voronka.svg' : elem == 'Чашка' ? 'voronka.svg' : elem == 'Капсула' ? 'voronka.svg' : 'voronka.svg'}`} loading="lazy" width={19} height={25} alt="" className="af-class-method_img" /> */}
                      <img
                        src={`/images/icons_brew/${elem.toLowerCase()}.png`}
                        loading="lazy"
                        height={35}
                        alt=""
                        className="af-class-method_img"
                      />
                      <div className="af-class-method_card__lable">{elem}</div>
                      {/* <div className="af-class-question_btn af-class-question_method">
                    <div className="af-class-availability__tooltip_block">
                      <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* <div className="w-layout-grid af-class-product__more-info-wrapper">
          <div className="af-class-indicator_text_wrap af-class-more-info">
            <div className="af-class-specifications__lable">Горечь</div>
            <div className="af-class-question_btn">
              <div className="af-class-availability__tooltip_block">
                <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
              </div>
            </div>
          </div>
          <div className="af-class-indicator_text_wrap af-class-more-info">
            <div className="af-class-specifications__lable">Горечь</div>
            <div className="af-class-question_btn">
              <div className="af-class-availability__tooltip_block">
                <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
              </div>
            </div>
          </div>
          <div className="af-class-indicator_text_wrap af-class-more-info">
            <div className="af-class-specifications__lable">Горечь</div>
            <div className="af-class-question_btn">
              <div className="af-class-availability__tooltip_block">
                <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="af-class-product__score"><span className="af-class-text-span-2">Оценка SCA:</span>&nbsp;86,5 баллов</div> */}
      </>
    ) : (
      <></>
    );
  };

  const Breadcrumbs = () => {
    const rootCategories = [ 'Кофе', 'Не кофе', 'Чай', 'Аксессуары', 'Архив' ];
    const productCategories = productHandle.currentProduct?.categories;

    const links = [
      { name: 'rockets.coffee', url: '/' },
    ];

    if (productCategories) {
      const productRootCategories = productCategories.filter(value => rootCategories.includes(value.name));
      const productSubCategories = productCategories.filter(value => !rootCategories.includes(value.name));
  
      const productRootCategory = productRootCategories.pop();
  
      if (productRootCategory) {
        links.push({ name: productRootCategory.name, url: `/catalog/${productRootCategory.slug}` });
    
        productSubCategories.forEach(elem => {
          links.push({ name: elem.name, url: `/catalog/${productRootCategory.slug}?category=${elem.slug}` });
        });
      }
    }

    return (
      <div className="af-class-product__breadcrumbs">
        {links.map((elem, index) => <React.Fragment key={index}><Link to={`${elem.url}`}>{elem.name}</Link> / </React.Fragment>)}
        {productHandle.currentProduct.name}
      </div>
    );
  }

  return (
    <>
      <ProductReviewForm
        item={item}
        reviews={reviews}
        handleReviews={handleReviews}
        reviewPopupOpened={reviewPopupOpened}
        setReviewPopupOpened={setReviewPopupOpened}
      />

      <div className="af-class-section af-class-hero af-class-an-load--2 af-class-wf-section">
        <div className="af-class-container w-container">
          <div className="af-class-content">
            <div className="af-class-product__preview-block">
              <div className="af-class-product__tags_wrap">
                <div className="af-class-left_tags_block">
                  {productHandle.variationPriceOld && (
                    <div className="af-class-sale">
                      <div className="af-class-text_tag1">SALE</div>
                    </div>
                  )}

                  {productHandle.currentProduct?.categories?.find(
                    (elem) => elem.id == 327
                  ) && (
                    <div className="af-class-rockets">
                      <div className="af-class-text_tag2">Выбор Rockets</div>
                    </div>
                  )}
                </div>
                <div className="af-class-products-card__favorites-icon-wrapper af-class-p-product">
                  {productHandle.favoriteItems &&
                  productHandle.favoriteItems.find(
                    (elem) => elem == productHandle.currentProduct.id
                  ) ? (
                    <img
                      onClick={() =>
                        productHandle.removeFavoriteItem(
                          productHandle.currentProduct.id
                        )
                      }
                      src="/images/heart-filled.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-products-card__favorites-icon--checked"
                    />
                  ) : (
                    <img
                      onClick={() =>
                        productHandle.addFavoriteItem(
                          productHandle.currentProduct.id
                        )
                      }
                      src="/images/heart-empty.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-products-card__favorites-icon--unchecked"
                    />
                  )}
                </div>
              </div>

              <ProductImage product={productHandle.currentProduct} />
            </div>

            <div className="af-class-product__info-block">
              <Breadcrumbs />
              <h1 className="af-class-product__title">
                {productHandle.currentProduct.name}
              </h1>
              <div
                className="af-class-product__discription"
                dangerouslySetInnerHTML={{
                  __html: productHandle.currentProduct.short_description,
                }}
              />

              <ExtraContentDescription />

              {productHandle.currentProduct?.categories?.find(
                (elem) => elem.id == 404
              ) ? (
                <></>
              ) : (
                <div className="af-class-product__options-wrapper">
                  <div className="af-class-product__buttons">
                    <ExtraContentCart />
                  </div>
                  <div className="af-class-product__options-price-block">
                    {productHandle.variationPriceOld ? (
                      <div className="af-class-product__price-regular">
                        {productHandle.variationPriceOld}₽
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="af-class-product__price">
                      {productHandle.variationPrice}₽
                    </div>
                  </div>
                </div>
              )}

              {/* { productHandle.currentProduct?.categories?.find(elem => elem.id == 404) ? <></> : <a onClick={productHandle.handleAddToCart} className="af-class-button af-class-buy-now w-button">{(productHandle.addedToCart === false) ? <>Купить</> : (productHandle.updatedCartItem === false) ? <>В корзине</> : <>Обновить</>}</a> } */}

              {productHandle.currentProduct?.categories?.find(
                (elem) => elem.id == 404
              ) ? (
                <></>
              ) : (
                <a
                  onClick={productHandle.handleAddToCart}
                  className="af-class-button af-class-buy-now w-button js-interactive-button-text af-class-buy-button-product-page"
                >
                  Купить
                </a>
              )}

              {/* <div className="af-class-product__additional_info-wrap">
                      <div className="af-class-product__additional_block">
                        <div className="af-class-product__additional_text"><span className="af-class-text-span">Экспресс-доставка</span> за 2 часа сегодня.</div>
                        <div className="af-class-question_btn">
                          <div className="af-class-availability__tooltip_block">
                            <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-product__additional_block">
                        <div className="af-class-product__additional_text"><span className="af-class-text-span">Доставка завтра</span> бесплатно.</div>
                        <div className="af-class-question_btn">
                          <div className="af-class-availability__tooltip_block">
                            <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-product__additional_block">
                        <div className="af-class-product__additional_text"><span className="af-class-text-span">Доступно сейчас</span> в 3-х кофейнях.</div>
                        <div className="af-class-question_btn">
                          <div className="af-class-availability__tooltip_block">
                            <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                          </div>
                        </div>
                      </div>
                    </div> */}
            </div>
          </div>
        </div>
      </div>

      {productHandle.currentProduct?.description && (
        <div
          style={{ marginTop: "60px" }}
          className="af-class-section af-class-an-scroll-into-view af-class-wf-section"
        >
          <div className="af-class-container w-container">
            <div
              className="af-class-content"
              style={{ flexDirection: "column" }}
              dangerouslySetInnerHTML={{
                __html: productHandle.currentProduct?.description,
              }}
            >
              {/* <div className="af-class-content__left-block">
                    <h2 className="af-class-h2">Особый способ выращивания</h2>
                  </div>
                  <div className="af-class-content__right-block af-class-special-way__section">
                    <p className="af-class-paragraph_2size">Мчана (Mchana Estate) – одна из крупнейших кофейных плантаций Kofinaf Co LTD. Это в 35 км к северу от города Найроби и в 10 км к западу от города Руиру. Кения один из самых узнаваемых кофейных регионов в мире. Кофе из этого региона обладает ярким, сложным ягодным и&nbsp;фруктовым вкусом с&nbsp;высокой и&nbsp;интенсивной сладостью и кислотностью.</p>
                    <div className="af-class-special-way__paragraph-wrap">
                      <p className="af-class-paragraph">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.</p>
                      <p className="af-class-paragraph">Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</p>
                    </div>
                  </div> */}
            </div>
            {/* <div className="af-class-special-way__image-wrap">
                  <img src="/images/Mchana-Estate.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/Mchana-Estate-p-500.jpeg 500w, images/Mchana-Estate-p-800.jpeg 800w, images/Mchana-Estate-p-1080.jpeg 1080w, images/Mchana-Estate.jpg 1501w" alt="" className="af-class-special-way__image af-class-an-image--scroll-into-view" />
                </div> */}
          </div>
        </div>
      )}

      {productHandle.currentProduct?.categories?.find(
        (elem) => elem.id == 17
      ) && (
        <div
          style={{ marginTop: "60px" }}
          className="af-class-section af-class-an-scroll-into-view af-class-wf-section"
        >
          <div className="af-class-container w-container">
            <div className="af-class-content">
              <div className="af-class-content__left-block product-page-how-to-brew">
                <h2 className="af-class-h2">Как правильно готовить кофе</h2>
                <div className="af-class-step__tabs-links_wrap">
                  {productHandle.currentProduct?.attributes
                    ?.find((elem) => elem.id == 1)
                    ?.options.map((elem, index) => (
                      <a
                        key={index}
                        onClick={() => setActiveTabHowTo(index)}
                        className={`af-class-step__tab-link af-class-an-tabs__tab--1${
                          activeTabHowTo == index &&
                          " af-class-step__tab-link_current"
                        }`}
                      >
                        {elem == "Кофеварка капельная"
                          ? "Капельная кофеварка"
                          : elem}
                      </a>
                    ))}
                </div>
              </div>
              <div
                className="af-class-content__right-block howtobrewcontainer"
                onMouseDown={(e) => mouseDownHandlerHowtobrew(e)}
              >
                {productHandle.currentProduct?.attributes
                  ?.find((elem) => elem.id == 1)
                  ?.options.map((elem, index) => (
                    <div
                      key={index}
                      style={{
                        display: activeTabHowTo == index ? "block" : "none",
                      }}
                      className="af-class-an-tabs__content--1"
                    >
                      <div
                        className="af-class-splide af-class-slider2 af-class-p-product"
                        style={{ display: "grid", overflow: "scroll" }}
                      >
                        <div
                          id="w-node-f0f4546f-1605-8063-210d-93d523db14ac-5f3d3641"
                          className="af-class-splide__track"
                        >
                          <div
                            className="af-class-splide__list"
                            style={{ columnGap: "15px" }}
                          >
                            {mainContext.brewingMethods
                              .find((method) => {
                                if (elem == "Кофеварка капельная") {
                                  elem = "Капельная кофеварка";
                                }
                                return method.title.rendered == elem;
                              })
                              ?.acf?.способы_заваривания?.map((elem, index) => (
                                <div
                                  key={index}
                                  className="af-class-splide__slide af-class-p-products"
                                  style={{
                                    minHeight: "1px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  <div
                                    className="af-class-step_block"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="af-class-step__image-wrap">
                                      <img
                                        src={
                                          brewingPictures.find(
                                            (pic) => pic.id == elem.изображение
                                          )?.media_details?.sizes?.medium
                                            ?.source_url
                                        }
                                        loading="lazy"
                                        alt=""
                                        className="af-class-step__image af-class-an-image--scroll-into-view"
                                      />
                                    </div>
                                    <div className="af-class-step__name">
                                      {elem.заголовок}
                                    </div>
                                    <div className="af-class-step__description">
                                      {elem.описание}
                                    </div>
                                  </div>
                                </div>
                              ))}

                            {/* <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.1</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div
                          bind="f0f4546f-1605-8063-210d-93d523db14ce"
                          className="af-class-splide__embed w-embed"
                        >
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                {/* <div style={{display: activeTabHowTo == 1 ? 'block' : 'none'}} className="af-class-an-tabs__content--1">
                      <div className="af-class-splide af-class-slider2 af-class-p-product" style={{overflow: 'scroll'}}>
                        <div id="w-node-f0f4546f-1605-8063-210d-93d523db14ac-5f3d3641" className="af-class-splide__track">
                          <div className="af-class-splide__list" style={{columnGap: '15px'}}>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.1</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="f0f4546f-1605-8063-210d-93d523db14ce" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                        </div>
                      </div>
                    </div>

                    <div style={{display: activeTabHowTo == 2 ? 'block' : 'none'}} className="af-class-an-tabs__content--2">
                      <div className="af-class-splide af-class-slider2 af-class-p-product">
                        <div id="w-node-_9c994628-e9e3-0d2e-bec6-43bf81ccc1b6-5f3d3641" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.2</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="9c994628-e9e3-0d2e-bec6-43bf81ccc1d8" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                        </div>
                      </div>
                    </div>

                    <div style={{display: activeTabHowTo == 3 ? 'block' : 'none'}} className="af-class-an-tabs__content--3">
                      <div className="af-class-splide af-class-slider2 af-class-p-product">
                        <div id="w-node-_028c3721-4b18-d46a-acdc-02aec0cecf3b-5f3d3641" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.3</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="028c3721-4b18-d46a-acdc-02aec0cecf5d" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                        </div>
                      </div>
                    </div>

                    <div style={{display: activeTabHowTo == 4 ? 'block' : 'none'}} className="af-class-an-tabs__content--4">
                      <div className="af-class-splide af-class-slider2 af-class-p-product">
                        <div id="w-node-df8ace73-fc27-e648-67d7-e242f1b56ebb-5f3d3641" className="af-class-splide__track">
                          <div className="af-class-splide__list">
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                            <div className="af-class-splide__slide af-class-p-products">
                              <div className="af-class-step_block">
                                <div className="af-class-step__image-wrap"><img src="/images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                <div className="af-class-step__name">Шаг 1.4</div>
                                <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div bind="df8ace73-fc27-e648-67d7-e242f1b56edd" className="af-class-splide__embed w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                        </div>
                      </div>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="af-class-content">
                  <div className="af-class-content__left-block">
                    <h2 className="af-class-h2">Наличие кофе в&nbsp;собственных кофейнях</h2>
                  </div>
                  <div className="af-class-content__right-block">
                    <div className="w-layout-grid af-class-availability__rows af-class-availability__lable-rows">
                      <div className="af-class-availability__lable af-class-name">Кофейня</div>
                      <div id="w-node-_7a9871fd-a5df-8f9f-a6a5-b83f7ff0064c-5f3d3641" className="af-class-availability__lable af-class-adress">адресс</div>
                      <div id="w-node-b0e4b16f-43ca-655f-287b-b55c7be3f7b3-5f3d3641" className="af-class-availability__lable af-class-availability">Наличие</div>
                      <div id="w-node-_387c4039-3313-e0ae-c912-508dafe173e1-5f3d3641" className="af-class-availability__lable af-class-work_hours">Часы работы</div>
                    </div>
                    <div className="w-layout-grid af-class-availability__rows">
                      <div id="w-node-fac476ec-a29a-860e-4fbe-bbbab406c478-5f3d3641" className="af-class-coffee-house__name-block">
                        <div className="af-class-coffee-house__name">Rockets Concept&nbsp;Store</div>
                        <div className="af-class-question_btn">
                          <div className="af-class-availability__tooltip_block">
                            <div className="af-class-availability__info-text af-class-tooltip__availability-adress">ул. Земляной Вал, 18/22, Москва</div>
                            <div className="af-class-availability__info-text af-class-tooltip__availability-time">Пн-Вс <span className="af-class-availability__info-time">9.00</span>&nbsp;до&nbsp;<span className="af-class-availability__info-time">21.00</span></div>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-e2bddee8-ae48-c59c-8375-9e9bcf5d36f2-5f3d3641">
                        <div id="w-node-_5c54ac7b-9883-d3ff-30b8-5843cc7e74e8-5f3d3641" className="af-class-availability__count">10 шт</div>
                      </div>
                      <div id="w-node-ba7acc5f-4c9e-442b-0ec2-1b292fc2fcdf-5f3d3641">
                        <a id="w-node-d138eb9a-f9ce-9946-3be6-6f110279f555-5f3d3641" href="#" className="af-class-availability__reserve-btn">Забронировать</a>
                      </div>
                      <div id="w-node-f57d9a91-f740-19eb-551c-7f6beab1315d-5f3d3641">
                        <div id="w-node-_0692f10f-a056-93c8-3dfa-6b924410677e-5f3d3641" className="af-class-availability__info-text">ул. Земляной Вал, 18/22, Москва</div>
                      </div>
                      <div id="w-node-_4669377d-7e32-b59a-c357-187dbda8bebd-5f3d3641">
                        <div id="w-node-_0b867736-e774-7c28-0bb9-10c1ba03eede-5f3d3641" className="af-class-availability__info-text">Пн-Вс <span className="af-class-availability__info-time">9.00</span>&nbsp;-&nbsp;<span className="af-class-availability__info-time">21.00</span></div>
                      </div>
                    </div>
                    <div className="w-layout-grid af-class-availability__rows">
                      <div id="w-node-_952a806a-2376-694f-1ec6-9d701a296b8f-5f3d3641" className="af-class-coffee-house__name-block-copy">
                        <div className="af-class-coffee-house__name">Cappuccino Kids</div>
                        <div className="af-class-question_btn" />
                      </div>
                      <div id="w-node-eb81be60-3159-6554-1ca1-ac0be4e982e8-5f3d3641">
                        <div id="w-node-_952a806a-2376-694f-1ec6-9d701a296b97-5f3d3641" className="af-class-availability__info-text-copy">ул. Валовая, 35, Москва</div>
                      </div>
                      <div id="w-node-ac03f392-7f01-86fb-02c8-664004836b71-5f3d3641">
                        <div id="w-node-_952a806a-2376-694f-1ec6-9d701a296b93-5f3d3641" className="af-class-availability__count-copy">10 шт</div>
                      </div>
                      <div id="w-node-_957db8aa-d36b-e374-505b-cc4cd7b5cb68-5f3d3641">
                        <div id="w-node-_952a806a-2376-694f-1ec6-9d701a296b99-5f3d3641" className="af-class-availability__info-text-copy">Пн-Вс <span className="af-class-availability__info-time">9.00</span>&nbsp;до&nbsp;<span className="af-class-availability__info-time">21.00</span></div>
                      </div>
                      <div id="w-node-ed877fd2-c381-b77d-833a-965e72849bc8-5f3d3641">
                        <a id="w-node-_952a806a-2376-694f-1ec6-9d701a296b95-5f3d3641" href="#" className="af-class-availability__reserve-btn-copy">Забронировать</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

      <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
        <div className="af-class-container w-container">
          <div className="af-class-content" style={{ justifyContent: "start" }}>
            <div
              id="w-node-_8b4e9b19-ecc1-76e6-8bdb-07b0e4207319-5f3d3641"
              className="af-class-content__left-block"
            >
              <h2 className="af-class-h2">Отзывы</h2>
            </div>
            {reviews.length ? (
              <div
                id="w-node-_8b4e9b19-ecc1-76e6-8bdb-07b0e420731c-5f3d3641"
                className="af-class-content__right-block"
              >
                <div className="af-class-rating__bock">
                  <div className="af-class-rating__stars-bock">
                    {[...Array(5)].map((i, index) => {
                      if (index + 1 <= rating) {
                        return (
                          <img
                            key={index + 1}
                            loading="lazy"
                            src="/images/Star-blue.svg"
                            alt=""
                            className="af-class-rating__star-image"
                          />
                        );
                      } else {
                        return (
                          <img
                            key={index + 1}
                            loading="lazy"
                            src="/images/Star-gray.svg"
                            alt=""
                            className="af-class-rating__star-image"
                          />
                        );
                      }
                    })}
                  </div>
                  <div className="af-class-rating__result">{rating}</div>
                </div>
                <div className="af-class-comments__wrap">
                  {reviews.map((elem, index) => <Review
                    key={elem.id}
                    review={elem}
                    userMeta={usersMeta.find(user => user.id == elem.user_id)}
                    user={users.find(user => user.id == elem.user_id)}
                    productLink={false}
                    allResponses={allResponses}
                  />)}
                </div>
                {mainContext.userData.id && userOrders.length ? (
                  <a
                    onClick={() => setReviewPopupOpened(true)}
                    className="af-class-button w-button"
                  >
                    Оставить отзыв
                  </a>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: "15px" }}>Отзывов пока нет</div>
                {mainContext.userData.id && userOrders.length ? (
                  <a
                    onClick={() => setReviewPopupOpened(true)}
                    className="af-class-button w-button"
                  >
                    Оставить отзыв
                  </a>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {productHandle.currentProduct?.categories?.find(
        (elem) => elem.id == 17
      ) && (
        <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
          <div className="af-class-container w-container">
            <div className="af-class-content">
              <div className="af-class-content__left-block">
                <h2 className="af-class-h2">Культура кофе</h2>
                {/* <div className="af-class-step__tabs-links_wrap">
                      <a onClick={() => setActiveTabCulture(1)} className={`af-class-step__tab-link af-class-an-tabs--2__tab--1${activeTabCulture == 1 && ' af-class-step__tab-link_current'}`}>Начинающим</a>
                      <a onClick={() => setActiveTabCulture(2)} className={`af-class-step__tab-link af-class-an-tabs--2__tab--2${activeTabCulture == 2 && ' af-class-step__tab-link_current'}`}>Профессионалам</a>
                    </div> */}
              </div>
              <div className="af-class-content__right-block af-class-culture">
                <div
                  style={{ display: activeTabCulture == 1 ? "block" : "none" }}
                  className="af-class-an-tabs--2__content--1"
                >
                  <div className="af-class-coffee-culture__container">
                    <div className="af-class-coffee-culure__wrapper">
                      {featuredPostsAll.map((elem, index) => (
                        <a
                          href="#"
                          className="af-class-culture-card w-inline-block"
                          key={elem.id}
                          onClick={() => navigate(`/knowledge/${elem.slug}`)}
                        >
                          <div className="af-class-culture-card__image-wrapper">
                            <img
                              src={
                                medias.find(
                                  (media) => media.id == elem.featured_media
                                )?.source_url
                              }
                              loading="lazy"
                              alt=""
                              className="af-class-culture-card__image af-class-an-image--scroll-into-view"
                            />
                          </div>
                          <div className="af-class-text-block">
                            {elem?.title?.rendered}
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: activeTabCulture == 2 ? "block" : "none" }}
                  className="af-class-an-tabs--2__content--2"
                >
                  <div className="af-class-coffee-culture__container">
                    <div className="af-class-coffee-culure__wrapper">
                      {featuredPosts2.map((elem, index) => (
                        <a
                          href="#"
                          className="af-class-culture-card w-inline-block"
                          key={elem.id}
                          onClick={() => navigate(`/knowledge/${elem.slug}`)}
                        >
                          <div className="af-class-culture-card__image-wrapper">
                            <img
                              src={
                                medias.find(
                                  (media) => media.id == elem.featured_media
                                )?.source_url
                              }
                              loading="lazy"
                              alt=""
                              className="af-class-culture-card__image af-class-an-image--scroll-into-view"
                            />
                          </div>
                          <div className="af-class-text-block">
                            {elem?.title?.rendered}
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="af-class-section af-class-recently_viewed af-class-an-scroll-into-view af-class-wf-section">
        <div className="af-class-container w-container">
          <div className="af-class-recently__content-wrap">
            <h2 className="af-class-recently__title">Недавно просмотренное</h2>
            <div className="af-class-splide af-class-slider3">
              {/* <div id="w-node-_25fe84fe-972e-0fcc-3cfb-ead5ea5877f0-5f3d3641" className="af-class-splide__arrows af-class-p-product">
                      <div bind="25fe84fe-972e-0fcc-3cfb-ead5ea5877f1" id="w-node-_25fe84fe-972e-0fcc-3cfb-ead5ea5877f1-5f3d3641" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                      <div bind="25fe84fe-972e-0fcc-3cfb-ead5ea5877f2" id="w-node-_25fe84fe-972e-0fcc-3cfb-ead5ea5877f2-5f3d3641" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                    </div> */}

              <div style={{ gridColumn: "1 / -1" }}>
                <Splide
                  className="af-class-splide af-class-splide__country-page"
                  hasTrack={false}
                  options={{
                    perPage: 3,
                    perMove: 1,
                    gap: "15px",
                    breakpoints: { 991: { perPage: 1, fixedWidth: '90%' } },
                    pagination: false,
                  }}
                >
                  <div className="splide__arrows af-class-splide__arrows af-class-p-product">
                    {/* <div className="splide__arrow splide__arrow--prev af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev"></button></div>
                          <div className="splide__arrow splide__arrow--next af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next"></button></div> */}
                    <button
                      style={{ display: "flex", position: "static" }}
                      className="splide__arrow splide__arrow--prev w-embed af-class-splide__arrow af-class-splide__arrow--prev"
                    ></button>
                    <button
                      style={{ display: "flex", position: "static" }}
                      className="splide__arrow splide__arrow--next w-embed af-class-splide__arrow af-class-splide__arrow--next"
                    ></button>
                  </div>
                  <SplideTrack>
                    {recentlyProducts.map((elem, index) => (
                      <SplideSlide
                        className="af-class-splide__slide"
                        key={elem.id}
                      >
                        <Product
                          key={elem.id}
                          {...elem}
                          style={{ width: "100%" }}
                          styleContainer={{ width: "100%", height: "100%" }}
                          styleContent={{ background: "#f5f5f5" }}
                        />
                      </SplideSlide>
                    ))}
                  </SplideTrack>
                </Splide>
              </div>

              <div
                bind="25fe84fe-972e-0fcc-3cfb-ead5ea587879"
                className="af-class-splide__embed w-embed"
              >
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="af-class-section af-class-insta-section af-class-an-scroll-into-view af-class-wf-section">
              <div className="af-class-container w-container">
                <div bind="fe6a81d4-0a4d-733f-eaea-52094aa78d19" className="af-class-insta__content-wrap">
                  <h2 className="af-class-h2">
                    <a href="#" className="af-class-link">@rockets.roasters</a>
                  </h2>
                  <div className="w-layout-grid af-class-insta-blocks__wrap">
                    <div className="af-class-insta-block"><img src="/images/ista-post03_1ista-post03.jpg" loading="lazy" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                    <div className="af-class-insta-block"><img src="/images/ista-post01.jpg" loading="lazy" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                    <div className="af-class-insta-block"><img src="/images/ista-post04_1ista-post04.jpg" loading="lazy" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                    <div className="af-class-insta-block"><img src="/images/ista-post02_1ista-post02.jpg" loading="lazy" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                  </div>
                </div>
              </div>
            </div> */}
    </>
  );
};

export default ProductPageView;

/* eslint-enable */
