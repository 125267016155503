import { useState, useContext, useEffect } from 'react'
import { fetchWPItems } from '../fetch/fetchWPItems'
import { wooAddToCart, wooEditCartItem, wooDeleteCartItem } from '../fetch/WPAPI'
import { MainContext } from '../contexts/MainContext'
import useFavorites from './useFavorites'
import useCart from './useCart'
import useToken from './useToken.js'

export default function useProduct(props) {
  const { token, setToken, nonce, setNonce } = useToken()

  const [ currentProduct, setCurrentProduct ] = useState(props)

  const mainContext = useContext(MainContext)

  const { setCart } = useCart()

  const { favoriteItems, addFavoriteItem, removeFavoriteItem } = useFavorites()

  const [ selectedWeight, setSelectedWeight ] = useState(null)
  const [ selectedType, setSelectedType ] = useState(null)
  const [ selectedOption, setSelectedOption ] = useState(null)
  const [ selectedQty, setSelectedQty ] = useState(null)

  const [ variationId, setVariationId ] = useState(currentProduct.id)
  const [ variationPrice, setVariationPrice ] = useState(currentProduct.price)
  const [ variationPriceOld, setVariationPriceOld ] = useState(null)

  const [ weightDropdown, setWeightDropdown ] = useState(false)
  const [ typeDropdown, setTypeDropdown ] = useState(false)
  const [ optionDropdown, setOptionDropdown ] = useState(false)

  const [ variations, setVariations ] = useState(false)
  const [ weightsVariations, setWeightsVariations ] = useState(false)
  const [ grindsVariations, setGrindsVariations ] = useState(false)
  const [ optionsVariations, setOptionsVariations ] = useState(false)

  const handleChangeWeight = (weight) => {
    // Getting all the variations for the current product
    const itemVariations = mainContext.catalogItemsVariations.find(elem => elem.id == currentProduct.id)

    // If there are variations present...
    if (itemVariations?.data) {
      // Handling the dropdown
      setWeightDropdown(false)
      setSelectedWeight(weight)
      
      // Getting all the grinds for the selected weight from the available variations and put them in the dropdown
      let grinds = [...new Set(itemVariations.data.map(elem => {
        let attribute_weight = elem.attributes.find(elem => elem.name == 'Вес')
        let attribute_grind = elem.attributes.find(elem => elem.name == 'Помол')
        if (attribute_grind && attribute_weight && attribute_weight.option == weight) {
          return attribute_grind.option
        }
      }))].filter(elem => typeof(elem) != 'undefined')
      setGrindsVariations(grinds)
      
      // If there is already a grind selected and it's compatible with the selected weight - set this variation as current one; if not - select the first available grind compatible with the selected weight
      if (selectedType && grinds.includes(selectedType)) {
        handleChangeVariation(weight, selectedType, itemVariations.data)
      } else {
        let resetType
        if (grinds.includes('в зернах')) {
          resetType = 'в зернах'
        } else if (grinds.includes('в зёрнах')) {
          resetType = 'в зёрнах'
        } else {
          resetType = grinds[0]
        }
        setSelectedType(resetType)
        handleChangeVariation(weight, resetType, itemVariations.data)
      }
    }
  }

  const handleChangeType = (type) => {
    // Getting all the variations for the current product
    const itemVariations = mainContext.catalogItemsVariations.find(elem => elem.id == currentProduct.id)

    // If there are variations present...
    if (itemVariations?.data) {
      // Handling the dropdown
      setTypeDropdown(false)
      setSelectedType(type)

      // Getting all the weights for the selected grind from the available variations and put them in the dropdown
      let weights = [...new Set(itemVariations.data.map(elem => {
        let attribute_weight = elem.attributes.find(elem => elem.name == 'Вес')
        let attribute_grind = elem.attributes.find(elem => elem.name == 'Помол')
        if (attribute_weight && attribute_grind && attribute_grind.option == type) {
          return attribute_weight.option
        }
      }))].filter(elem => typeof(elem) != 'undefined')
      setWeightsVariations(weights)
      
      // If there is already a weight selected and it's compatible with the selected grind - set this variation as current one; if not - select the first available weight compatible with the selected grind
      if (selectedWeight && weights.includes(selectedWeight)) {
        handleChangeVariation(selectedWeight, type, itemVariations.data)
      } else {
        let resetWeight
        if (weights.includes('250 г')) {
          resetWeight = '250 г'
        } else {
          resetWeight = weights[0]
        }
        setSelectedWeight(resetWeight)
        handleChangeVariation(resetWeight, type, itemVariations.data)
      }
    }
  }

  const handleChangeOption = (option) => {
    setOptionDropdown(false)
    setSelectedOption(option)

    let optionSplit = option.split(': ')
    handleChangeVariationSimple(optionSplit[0], optionSplit[1])
  }

  const findVariation = (weight, type, variations) => {
    if (variations) {
      return variations?.find(elem => {
        let weightChecked = weight ? elem.attributes.find(attribute => attribute.name == 'Вес' && attribute.option == weight) : true
        let typeChecked = type ? elem.attributes.find(attribute => attribute.name == 'Помол' && attribute.option == type) : true
        return weightChecked && typeChecked
      })
    }
  }

  const findVariationSimple = (optionName, optionValue, variations) => {
    if (variations) {
      return variations?.find(elem => {
        return elem.attributes.find(attribute => attribute.name == optionName && attribute.option == optionValue)
      })
    }
  }

  const handleChangeVariation = (weight, type, data = null) => {
    if (!data) {
      data = variations
    }
    let variation = findVariation(weight, type, data)

    if (variation) {
      setVariationId(variation.id)

      if (variation.sale_price) {
        setVariationPrice(variation.sale_price)
        setVariationPriceOld(variation.regular_price)
      } else {
        setVariationPrice(variation.price)
        setVariationPriceOld(null)
      }
    }
  }

  const handleChangeVariationSimple = (optionName, optionValue, data = null) => {
    if (!data) {
      data = variations
    }
    let variation = findVariationSimple(optionName, optionValue, data)

    if (variation) {
      setVariationId(variation.id)

      if (variation.sale_price) {
        setVariationPrice(variation.sale_price)
        setVariationPriceOld(variation.regular_price)
      } else {
        setVariationPrice(variation.price)
        setVariationPriceOld(null)
      }
    }
  }

  const handleQtyRaise = () => {
    setSelectedQty(selectedQty + 1)
  }

  const handleQtyLower = () => {
    if (selectedQty > 1) {
      setSelectedQty(selectedQty - 1)
    }
  }

  const handleQtyRaiseVariation = (variationId) => {
    setCart(mainContext.cartItems.map(elem => elem.id == currentProduct.id && elem.variationId == variationId ? {...elem, cartQty: elem.cartQty + 1} : elem))
  }

  const handleQtyLowerVariation = (variationId) => {
    if (mainContext.cartItems.find(elem => elem.id == currentProduct.id && elem.variationId == variationId)?.cartQty > 1) {
      setCart(mainContext.cartItems.map(elem => elem.id == currentProduct.id && elem.variationId == variationId ? {...elem, cartQty: elem.cartQty - 1} : elem))
    }
  }

  const handleAddToCart = (e) => {
    let targetButton = e.target
    while (!targetButton.classList.contains('js-interactive-button-text')) {
      targetButton = targetButton.parentNode
    }
    const oldButtonText = targetButton.innerHTML
    targetButton.innerHTML = 'Добавлено'
    setTimeout(() => targetButton.innerHTML = oldButtonText, 1000)

    let newItem = {
      ...currentProduct,
      variationId: variationId,
      cartWeight: selectedWeight,
      cartType: selectedType,
      cartOption: selectedOption,
      cartQty: selectedQty,
      cartPrice: variationPrice,
      cartPriceOld: variationPriceOld,
    }

    if (mainContext.cartItems.find(elem => elem.id == currentProduct.id && elem.variationId == variationId)) {
      setCart(mainContext.cartItems.map(elem => elem.id == currentProduct.id && elem.variationId == variationId ? {...elem, cartQty: elem.cartQty + selectedQty} : elem))
    } else {
      setCart([newItem, ...mainContext.cartItems])
    }

    // eslint-disable-next-line
    _tmr.push({ type: 'reachGoal', id: 3419693, goal: 'basket'});
  }

  const setParametersVariations = (data) => {
    // let itemVariations = mainContext.catalogItemsVariations.find(elem => elem.id == currentProduct.id)
    // if (!itemVariations) {
    //   mainContext.setCatalogItemsVariations((prev) => [...prev, { categoryId: 17, id: currentProduct.id, data: data }]) // TODO check category
    // }

    let weights = [...new Set(data.map(elem => {
      let attribute = elem.attributes.find(elem => elem.name == 'Вес')
      if (attribute) {
        return attribute.option
      }
    }))].filter(elem => typeof(elem) != 'undefined')
    setWeightsVariations(weights)

    let updatedWeight = null
    let attributesWeights = currentProduct.attributes.find(elem => elem.name == 'Вес')
    if (attributesWeights && attributesWeights.options && attributesWeights.options.length > 0) {
      if (attributesWeights.options.includes('250 г')) {
        updatedWeight = '250 г'
      } else {
        updatedWeight = attributesWeights.options[0]
      }
      handleChangeWeight(updatedWeight)
      // setSelectedWeight(updatedWeight)
    }

    // let grinds = [...new Set(data.map(elem => {
    //   let attribute = elem.attributes.find(elem => elem.name == 'Помол')
    //   let attribute_weight = elem.attributes.find(elem => elem.name == 'Вес')
    //   if (attribute && attribute_weight.option == updatedWeight) {
    //     return attribute.option
    //   }
    // }))].filter(elem => typeof(elem) != 'undefined')
    // setGrindsVariations(grinds)

    // let updatedGrind = null
    // let attributesGrinds = currentProduct.attributes.find(elem => elem.name == 'Помол')
    // if (attributesGrinds && attributesGrinds.options && attributesGrinds.options.length > 0) {
    //   updatedGrind = attributesGrinds.options[0]
    //   setSelectedType(updatedGrind)
    // }

    // handleChangeVariation(updatedWeight, updatedGrind, data)
  }

  const setSimpleVariations = (data) => {
    // let itemVariations = mainContext.catalogItemsVariations.find(elem => elem.id == currentProduct.id)
    // if (!itemVariations) {
    //   mainContext.setCatalogItemsVariations((prev) => [...prev, { categoryId: null, id: currentProduct.id, data: data }])
    // }

    let options = [...new Set(data.map(elem => {
      if (elem.attributes[0]) {
        let attr_name = elem.attributes[0].name
        return attr_name + ': ' + elem.attributes[0].option
      }
    }))].filter(elem => typeof(elem) != 'undefined')
    setOptionsVariations(options)

    if (options[0]) {
      let optionSplit = options[0].split(': ')

      setSelectedOption(options[0])
      handleChangeVariationSimple(optionSplit[0], optionSplit[1], data)
    }
  }

  useEffect(() => {
    if (currentProduct.sale_price) {
      setVariationPrice(currentProduct.sale_price)
      setVariationPriceOld(currentProduct.regular_price)
    } else {
      setVariationPrice(currentProduct.price)
      setVariationPriceOld(null)
    }

    if ((currentProduct.categories && currentProduct.categories.find(elem => elem.id == 17)) || (currentProduct?.variations?.length > 0)) {
      let itemVariations = mainContext.catalogItemsVariations.find(elem => elem.id == currentProduct.id)
      console.log(itemVariations)
      if (itemVariations && itemVariations.data) {
        setVariations(itemVariations.data)
        if (currentProduct.categories.find(elem => elem.id == 17) && !currentProduct.categories.find(elem => elem.id == 416)) {
          setParametersVariations(itemVariations.data)
        } else {
          setSimpleVariations(itemVariations.data)
        }
      } else {
        // if (currentProduct.id) {
        //   let apiUrl = `https://panel.rockets.coffee/wp-json/public-woo/v1/products/${currentProduct.id}/variations?per_page=100`
        //   fetchWPItems(apiUrl)
        //     .then(result => {
        //       setVariations(result)
        //       if (currentProduct.categories.find(elem => elem.id == 17)) {
        //         setParametersVariations(result)
        //       } else {
        //         setSimpleVariations(result)
        //       }
        //     })
        // }
      }
    }

    setSelectedQty(1)
  }, [currentProduct, mainContext.catalogItemsVariations])

  return {
    currentProduct: currentProduct,
    setCurrentProduct: setCurrentProduct,
    favoriteItems: favoriteItems,
    addFavoriteItem: addFavoriteItem,
    removeFavoriteItem: removeFavoriteItem,
    selectedWeight: selectedWeight,
    selectedType: selectedType,
    selectedOption: selectedOption,
    selectedQty: selectedQty,
    variationId: variationId,
    variationPrice: variationPrice,
    variationPriceOld: variationPriceOld,
    weightDropdown: weightDropdown,
    setWeightDropdown: setWeightDropdown,
    typeDropdown: typeDropdown,
    setTypeDropdown: setTypeDropdown,
    optionDropdown: optionDropdown,
    setOptionDropdown: setOptionDropdown,
    weightsVariations: weightsVariations,
    grindsVariations: grindsVariations,
    optionsVariations: optionsVariations,
    handleChangeWeight: handleChangeWeight,
    handleChangeType: handleChangeType,
    handleChangeOption: handleChangeOption,
    handleQtyRaise: handleQtyRaise,
    handleQtyLower: handleQtyLower,
    handleQtyLowerVariation: handleQtyLowerVariation,
    handleQtyRaiseVariation: handleQtyRaiseVariation,
    handleAddToCart: handleAddToCart,
  }
}