/* eslint-disable */

import React, { useState, useEffect, useContext, useRef, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "../hooks/useToken.js";
import useFavorites from "../hooks/useFavorites";
import useCart from "../hooks/useCart.js";
import useProduct from "../hooks/useProduct";
import { MainContext } from "../contexts/MainContext";
import Product from "../components/Product/Product";
import ReviewForm from "../components/ReviewForm/ReviewForm";
import {
  fetchCurrentUser,
  updateCurrentUser,
  updateCurrentWpUser,
  uploadWpMedia,
  fetchCurrentUserOrders,
  fetchProductsById,
  fetchCurrentUserReviews,
  createCustomReview,
  fetchCurrentUserCustomReviews,
  getCurrentUserLoyalty,
  getBonusesMCRM,
  getUserMCRM,
  getSummMCRM,
} from "../fetch/WPAPI";
import AddressForm from "../components/Cart/AddressForm";
import "react-dadata/dist/react-dadata.css";
import MaskInput from "react-maskinput";
import { fetchWPItems } from "../fetch/fetchWPItems";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createScope, map, transformProxies } from "./helpers";
import img1 from "../img/1.png";
import img2 from "../img/2.png";
import { tastes } from "../data/tastes";
import Taste from "../components/ProductPage/Taste";

const AccountPersonalView = () => {
  const mainContext = useContext(MainContext);
  const { token, setToken, nonce, setNonce } = useToken();
  const { setCart } = useCart();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  const [user, setUser] = useState(null);
  const [userOrders, setUserOrders] = useState([]);
  const [userOrdersProducts, setUserOrdersProducts] = useState([]);
  const [userReviews, setUserReviews] = useState([]);

  const handleNewUserReview = (review) => {
    setUserReviews((prevState) => [...prevState, review]);
  };

  const reviewCategories = [
    { id: 435, name: "О доставке" },
    { id: 436, name: "О магазине" },
    { id: 437, name: "Об оплате" },
    { id: 438, name: "О команде" },
  ];

  const orderStatuses = [
    { code: 'pending', name: 'Ожидает оплаты' },
    { code: 'processing', name: 'Новый' },
    { code: 'on-hold', name: 'На удержании' },
    { code: 'completed', name: 'Выполнен' },
    { code: 'cancelled', name: 'Отменен' },
    { code: 'refunded', name: 'Возврат' },
    { code: 'failed', name: 'Не удался' },
    { code: 'pay_au', name: 'Платеж авторизован' },
    { code: 'pay_delivered', name: 'Доставлен' },
    { code: 'sent-to-complect', name: 'Передано в комплектацию' },
    { code: 'peredan-v-tk', name: 'Передан в доставку' },
    { code: 'self-delivery-com', name: 'Доставлен в пункт самовывоза' },
    { code: 'send-to-delivery', name: 'Передан в доставку' },
    { code: 'assembling-comple', name: 'Собран' },
    { code: 'send-to-assemblin', name: 'Собираем заказ' },
    { code: 'need-confirmed', name: 'Cогласование' },
    { code: 'client-confirmed', name: 'Согласован' },
    { code: 'checkout-draft', name: 'Черновик' },
  ];

  const [formAddressData, setFormAddressData] = useState({ isValid: false });

  const { favoriteItems, setFavoriteItems } = useFavorites();
  const [favoriteItemsFull, setFavoriteItemsFull] = useState([]);

  const [personalDataEdit, setPersonalDataEdit] = useState(false);
  const [personalDataEditing, setPersonalDataEditing] = useState(false);

  const [formPersonalAvatarFile, setFormPersonalAvatarFile] = useState(null);
  const [formPersonalAvatarPreview, setFormPersonalAvatarPreview] = useState(null);
  const [formPersonalDataSurname, setFormPersonalDataSurname] = useState("");
  const [formPersonalDataName, setFormPersonalDataName] = useState("");
  const [formPersonalDataPatronymic, setFormPersonalDataPatronymic] =
    useState("");
  const [formPersonalDataPhone, setFormPersonalDataPhone] = useState("");
  const [formPersonalDataBirthday, setFormPersonalDataBirthday] = useState("");
  const [formPersonalDataBirthdayFull, setFormPersonalDataBirthdayFull] =
    useState(null);
  // const [ formPersonalDataEmail, setFormPersonalDataEmail ] = useState('')
  const [ formPersonalDataNewPassword, setFormPersonalDataNewPassword ] = useState('')

  const [formPersonalDataSurnameValid, setFormPersonalDataSurnameValid] =
    useState(null);
  const [formPersonalDataNameValid, setFormPersonalDataNameValid] =
    useState(null);
  const [formPersonalDataPatronymicValid, setFormPersonalDataPatronymicValid] =
    useState(null);
  const [formPersonalDataPhoneValid, setFormPersonalDataPhoneValid] =
    useState(null);
  const [formPersonalDataBirthdayValid, setFormPersonalDataBirthdayValid] =
    useState(null);

  const [formPersonalDataIsValid, setFormPersonalDataIsValid] = useState(false);

  const validateFormPersonalData = () => {
    setFormPersonalDataIsValid(
      formPersonalDataSurnameValid === true &&
        formPersonalDataNameValid === true &&
        // && formPersonalDataPatronymicValid === true
        formPersonalDataPhoneValid === true
      // && formPersonalDataBirthdayValid === true
    );
  };

  useEffect(() => {
    if (
      localStorage.getItem("phone") &&
      JSON.parse(localStorage.getItem("user")) &&
      token && token.token
    ) {
      updatePersonalData();
    }
  }, []);

  const updatePersonalData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let data = {
      billing: {
        first_name: user.name,
        last_name: user.surname,
        phone: user.phone,
      },
      shipping: {
        first_name: user.name,
        last_name: user.surname,
        phone: user.phone,
      },
    };

    fetchCurrentUser(token.token).then((result) => {
      if (result.id) {
        updateCurrentUser(result.id, data).then((result) => {
          if (result.id) {
            mainContext.setUserData({
              ...mainContext.userData,
              billing: {
                ...mainContext.userData,
                first_name: user.name,
                last_name: user.surname,
                phone: user.phone,
              },
            });
          }
        });
      }
    });

    let wpData = {
      acf: {
        дата_рождения: user.birthdate || "01.01.1970",
      },
      first_name: user.name,
      last_name: user.surname,
    };

    fetchCurrentUser(token.token).then((result) => {
      if (result.id) {
        updateCurrentWpUser(result.id, wpData, token.token).then((result) => {
          if (result.id) {
            mainContext.setUserWpData({
              ...mainContext.userWpData,
              acf: {
                ...mainContext.userWpData.acf,
                дата_рождения: user.birthdate,
              },
            });
          }
        });
      }
    });
  };

  const validateField = (name, value) => {
    switch (name) {
      case "surname":
        value.length > 1
          ? setFormPersonalDataSurnameValid(true)
          : setFormPersonalDataSurnameValid(false);
        break;
      case "name":
        value.length > 1
          ? setFormPersonalDataNameValid(true)
          : setFormPersonalDataNameValid(false);
        break;
      // case 'patronymic':
      //   value.length > 1 ? setFormPersonalDataPatronymicValid(true) : setFormPersonalDataPatronymicValid(false)
      //   break
      case "phone":
        value.match(/\+[0-9] [0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}/) ||
        value.match(/\+[0-9]{11}/)
          ? setFormPersonalDataPhoneValid(true)
          : setFormPersonalDataPhoneValid(false);
        break;
      // case 'birthday':
      //   value.length > 1 ? setFormPersonalDataBirthdayValid(true) : setFormPersonalDataBirthdayValid(false)
      //   break
    }
  };

  const handleChangeInputText = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validateField(name, value);
    switch (name) {
      case "surname":
        setFormPersonalDataSurname(value);
        break;
      case "name":
        setFormPersonalDataName(value);
        break;
      case "patronymic":
        setFormPersonalDataPatronymic(value);
        break;
      case "phone":
        setFormPersonalDataPhone(value);
        break;
      case "birthday":
        setFormPersonalDataBirthday(value);
        break;
      case "new_password":
        setFormPersonalDataNewPassword(value);
        break;
    }
  };

  const handleChangeAvatar = (e) => {
    setFormPersonalAvatarFile(e.target.files[0]);
    var reader = new FileReader();
    reader.onload = function (e) {
      setFormPersonalAvatarPreview(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  const handleFormPersonalDataBirthdayFull = (date) => {
    setFormPersonalDataBirthdayFull(date);

    const formatted = [
      ("0" + date.getDate()).slice(-2),
      ("0" + (date.getMonth() + 1)).slice(-2),
      date.getFullYear(),
    ].join(".");
    setFormPersonalDataBirthday(formatted);
  };

  useEffect(() => {
    validateFormPersonalData();
  });

  const [ordersActive, setOrdersActive] = useState([]);

  const personalDataFormHeader = useRef(null);

  const scrollToPersonalDataForm = () => {
    window.scrollTo({
      top: personalDataFormHeader.current.getBoundingClientRect().top - 50,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    if (personalDataEdit) {
      scrollToPersonalDataForm();
    }
  }, [personalDataEdit]);

  const [addressEdit, setAddressEdit] = useState(false);
  const [prepopulatedAddress, setPrepopulatedAddress] = useState(false);

  const addressFormHeader = useRef(null);

  const scrollToAddressForm = () => {
    window.scrollTo({
      top: addressFormHeader.current.getBoundingClientRect().top - 50,
      left: 0,
      behavior: "smooth",
    });
  }

  const [userAddresses, setUserAddresses] = useState([]);

  const [reviewPopupOpened, setReviewPopupOpened] = useState(false);

  const [loyalty, setLoyalty] = useState({ bonuses: 0, level: "Stage 0" });

  useEffect(() => {
    document.title = "Личный кабинет";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Личный кабинет");

    getCurrentUserLoyalty(token?.token).then((result) => {
      setLoyalty(result);
    });
  }, []);

  const loyaltyPrograms = [
    { name: "Stage 0", discount: "3", threshold: 0 },
    { name: "Stage 1", discount: "5", threshold: "5 000" },
    { name: "Stage 2", discount: "10", threshold: "10 000" },
    { name: "Stage 3", discount: "15", threshold: "15 000" },
    { name: "Stage Pro", discount: "20", threshold: "40 000" },
  ];

  const handleAddressDelete = (id) => {
    let addresses = userAddresses.filter((elem) => elem.id != id);
    setUserAddresses(addresses);
    handleAddressServer(addresses);
  };

  const handleAddressSetDefault = (id) => {
    let addresses = userAddresses.map((elem) =>
      elem.id != id ? { ...elem, default: false } : { ...elem, default: true }
    );
    setUserAddresses(addresses);
    handleAddressServer(addresses);
  };

  const handleAddressSave = () => {
    let addresses;
    let setDefault;

    if (prepopulatedAddress === false || prepopulatedAddress === null) {
      let address_id;
      if (userAddresses.length > 0) {
        address_id = Math.max(...userAddresses.map((elem) => elem.id)) + 1;
        setDefault = false;
      } else {
        address_id = 1;
        setDefault = true;
      }
      console.log(address_id);

      addresses = [
        ...userAddresses,
        {
          id: address_id,
          default: setDefault,
          name: formAddressData.name,
          full: formAddressData.full,
          entrance: formAddressData.entrance,
          floor: formAddressData.floor,
          flat: formAddressData.flat,
          homephone: formAddressData.homephone,
        },
      ];
    } else {
      addresses = userAddresses.map((elem) =>
        elem.id != prepopulatedAddress.id
          ? elem
          : {
              ...elem,
              name: formAddressData.name,
              full: formAddressData.full,
              entrance: formAddressData.entrance,
              floor: formAddressData.floor,
              flat: formAddressData.flat,
              homephone: formAddressData.homephone,
            }
      );
    }

    setAddressEdit(false);
    setPrepopulatedAddress(prepopulatedAddress === false ? null : false);

    setUserAddresses(addresses);
    handleAddressServer(addresses);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleAddressServer = (data) => {
    fetchCurrentUser(token.token).then((result) => {
      console.log(result);
      if (result.id) {
        updateCurrentWpUser(
          result.id,
          { acf: { адреса: JSON.stringify(data) } },
          token.token
        ).then((result) => {
          if (result.id) {
            mainContext.setUserWpData({
              ...mainContext.userWpData,
              acf: {
                ...mainContext.userWpData.acf,
                адреса: JSON.stringify(data),
              },
            });
          }
        });
      }
    });
  };

  const createDateObject = (dateStr) => {
    const dateParts = dateStr.split(".");
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
  };

  useEffect(() => {
    if (!token || !token.token) {
      navigate("/login");
      return;
    }

    if (mainContext.userData.id && mainContext.userWpData.id) {
      console.log(mainContext.userData);
      //console.log(mainContext.userWpData);

      setUser(mainContext.userData);

      if (mainContext.userWpData?.acf?.avatar) {
        setFormPersonalAvatarPreview(mainContext.userWpData.acf.avatar);
      }

      setFormPersonalDataSurname(mainContext.userData?.billing?.last_name);
      validateField("surname", mainContext.userData?.billing?.last_name);

      setFormPersonalDataName(mainContext.userData?.billing?.first_name);
      validateField("name", mainContext.userData?.billing?.first_name);

      setFormPersonalDataPatronymic(mainContext.userWpData?.acf?.отчество);
      validateField("patronymic", mainContext.userWpData?.acf?.отчество);

      setFormPersonalDataPhone(mainContext.userData?.billing?.phone);
      validateField("phone", mainContext.userData?.billing?.phone);

      setFormPersonalDataBirthday(mainContext.userWpData?.acf?.дата_рождения);
      validateField("birthday", mainContext.userWpData?.acf?.дата_рождения);

      if (mainContext.userWpData?.acf?.дата_рождения) {
        setFormPersonalDataBirthdayFull(
          createDateObject(mainContext.userWpData.acf.дата_рождения)
        );
      }

      try {
        setUserAddresses(JSON.parse(mainContext.userWpData?.acf?.адреса));
      } catch {
        setUserAddresses([]);
      }

      fetchCurrentUserOrders(mainContext.userData.id).then((result) => {
        //console.log(result);
        setUserOrders(result);

        let product_ids = [];
        result.forEach((order) => {
          product_ids = [
            ...product_ids,
            ...order.line_items.map((item) => item.product_id),
          ];
        });

        fetchProductsById(product_ids).then((result) => {
          //console.log(result);
          setUserOrdersProducts(result);
        });
      });

      fetchProductsById(favoriteItems).then((result) => {
        setFavoriteItemsFull(result);
      });

      fetchCurrentUserReviews(mainContext.userData.email).then((result) => {
        //console.log(result);
        setUserReviews((prevState) => [
          ...prevState,
          ...result.map((elem) => {
            return {
              id: elem.id,
              date: elem.date_created,
              label: elem.product_name,
              link: elem.product_permalink.split("/")[
                elem.product_permalink.split("/").length - 2
              ],
              rating: elem.rating,
              text: elem.review,
            };
          }),
        ]);
      });

      fetchCurrentUserCustomReviews(mainContext.userData.id).then((result) => {
        //console.log(result);
        setUserReviews((prevState) => [
          ...prevState,
          ...result.map((elem) => {
            return {
              id: elem.id,
              date: elem.date,
              label: reviewCategories.find(
                (cat) => elem.kategoriya_otzivov.indexOf(cat.id) !== -1
              )?.name,
              link: null,
              rating: elem.acf.оценка,
              text: elem.content.rendered,
            };
          }),
        ]);
      });
    }
  }, [mainContext.userData, mainContext.userWpData]);

  // useEffect(() => {
  //   //console.log(userReviews);
  // }, [userReviews]);

  const handleFormPersonalDataSubmit = (e) => {
    e.preventDefault();
    if (formPersonalDataIsValid) {
      handleUpdatePersonalData();
    }
  };

  const handleUpdatePersonalData = async () => {
    setPersonalDataEditing(true);

    let avatar_url = null; // default avatar: https://secure.gravatar.com/avatar/629a230556682567305e5b23e4aaea4d?s=96&d=mm&r=g
    if (formPersonalAvatarFile != null) {
      const formData = new FormData();
      formData.append('file', formPersonalAvatarFile);
      formData.append('title', 'avatar');
      formData.append('caption', 'avatar');

      const result = await uploadWpMedia(formData, token.token, { 'Content-Disposition': `form-data; filename="${formPersonalAvatarFile.name}"` });
      if (result?.media_details?.sizes?.thumbnail?.source_url) {
        avatar_url = result.media_details.sizes.thumbnail.source_url;
      }
    }

    let data = {
      billing: {
        first_name: formPersonalDataName,
        last_name: formPersonalDataSurname,
        phone: formPersonalDataPhone,
      },
      shipping: {
        first_name: formPersonalDataName,
        last_name: formPersonalDataSurname,
        phone: formPersonalDataPhone,
      },
    };

    fetchCurrentUser(token.token).then((result) => {
      console.log(result);
      if (result.id) {
        updateCurrentUser(result.id, data).then((result) => {
          console.log(result);
          if (result.id) {
            mainContext.setUserData({
              ...mainContext.userData,
              billing: {
                ...mainContext.userData,
                first_name: formPersonalDataName,
                last_name: formPersonalDataSurname,
                phone: formPersonalDataPhone,
              },
            });
            setPersonalDataEdit(false);
          }
          setPersonalDataEditing(false);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
      }
    });

    let wpData = {
      acf: {
        отчество: formPersonalDataPatronymic,
        дата_рождения: formPersonalDataBirthday,
      },
      first_name: formPersonalDataName,
      last_name: formPersonalDataSurname,
    };

    if (avatar_url != null) {
      wpData.acf.avatar = avatar_url;
    }

    if (formPersonalDataNewPassword && formPersonalDataNewPassword.length) {
      wpData.password = formPersonalDataNewPassword;
    }

    fetchCurrentUser(token.token).then((result) => {
      console.log(result);
      if (result.id) {
        updateCurrentWpUser(result.id, wpData, token.token).then((result) => {
          if (result.id) {
            const acf = {
              ...mainContext.userWpData.acf,
              отчество: formPersonalDataPatronymic,
              дата_рождения: formPersonalDataBirthday,
            };
            if (avatar_url != null) {
              acf.avatar = avatar_url;
            }

            mainContext.setUserWpData({
              ...mainContext.userWpData,
              acf: acf,
            });
          }
        });
      }
    });
  };

  const userStages = [
    { name: "Stage 0", discount: "3", threshold: 0 },
    { name: "Stage 1", discount: "5", threshold: "5 000" },
    { name: "Stage 2", discount: "10", threshold: "10 000" },
    { name: "Stage 3", discount: "15", threshold: "15 000" },
    { name: "Stage Pro", discount: "20", threshold: "40 000" },
  ];

  const [userBonuses, setUserBonuses] = useState(0);
  const [cardLink, setCardLink] = useState("");
  const [userStage, setUserStage] = useState(userStages[0]);

  const getBonuses = async () => {
    const result = await getBonusesMCRM();
    if (result.error === "OK") {
      setUserBonuses(result.summ);
    } else {
      alert(result.system_message);
    }
  };

  // const getSumm = async () => {
  //   const phone = localStorage.getItem("phoneNumber");
  //   const result = await getSummMCRM(phone);
  //   if (result.status !== "error") {
  //     for (let i = 0; i < userStages.length; i++) {
  //       let threshold = userStages[i].threshold;
  //       if (result.check_summ > Number(threshold)) {
  //         setUserStage(userStages[i]);
  //       } else {
  //         break;
  //       }
  //     }
  //   } else {
  //     alert(result.message);
  //   }
  // };

  const getLink = async () => {
    const result = await getUserMCRM();
    console.log(result);
    setCardLink(result.osmi_link);
    for (let i = 0; i < userStages.length; i++) {
      let name = userStages[i].name;
      for (let j = 0; j < result.segments.length; j++) {
        if (result.segments[j] === name) {
          setUserStage(userStages[i]);
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access")) {
      getBonuses();
      getLink();
      //getSumm();
    }
  }, []);

  const handleLogout = () => {
    setToken(null);
    setNonce(null);
    setCart([]);
    localStorage.removeItem("access");
    localStorage.removeItem("phone");
    localStorage.removeItem("user");
    localStorage.removeItem("recentlySeen");
    mainContext.setUserData({});
    navigate("/login");
  };

  const handleOrderToggle = (index) => {
    if (ordersActive.find((value) => value == index)) {
      setOrdersActive((prev) => prev.filter((elem) => elem != index));
    } else {
      setOrdersActive((prev) => [...prev, index]);
    }
  };

  const [loadingRepeat, setloadingRepeat] = useState(0);
  const [loadingArchived, setloadingArchived] = useState(0);

  const handleRepeatOrder = (order) => {
    console.log(order);

    setloadingRepeat(order.id);

    let product_ids = order.line_items?.map((elem) => elem.product_id);
    console.log(product_ids);

    if (product_ids) {
      fetchProductsById(product_ids).then((result) => {
        setCart([]);

        let cartItems = [];

        let promises = [];

        result.forEach((elem) => {
          if (!elem.categories.find((cat) => cat.id == 404)) {
            let apiUrl = `https://panel.rockets.coffee/wp-json/public-woo/v1/products/${elem.id}/variations?per_page=100`;
            promises = [...promises, fetchWPItems(apiUrl)];

            cartItems = [
              {
                ...elem,
                variationId: order.line_items.find(
                  (item) => item.product_id == elem.id
                ).variation_id,
                // cartWeight: cartWeight,
                // cartType: cartType,
                cartQty: order.line_items.find(
                  (item) => item.product_id == elem.id
                ).quantity,
                cartPrice: order.line_items.find(
                  (item) => item.product_id == elem.id
                ).variation_id
                  ? null
                  : elem.price,
                // cartPriceOld: variationPriceOld,
              },
              ...cartItems,
            ];
          }
        });

        if (promises.length > 0) {
          Promise.all(promises).then((values) => {
            console.log(values);

            cartItems.forEach((elem) => {
              values.forEach((variations) => {
                let variation = variations.find(
                  (variation) => variation.id == elem.variationId
                );
                if (variation) {
                  cartItems.find(
                    (item) => item.variationId == variation.id
                  ).cartWeight = variation.attributes.find(
                    (attr) => attr.id == 15
                  ).option;
                  cartItems.find(
                    (item) => item.variationId == variation.id
                  ).cartType = variation.attributes.find(
                    (attr) => attr.id == 16
                  ).option;
                  cartItems.find(
                    (item) => item.variationId == variation.id
                  ).cartPrice = variation.price;
                  cartItems.find(
                    (item) => item.variationId == variation.id
                  ).cartPriceOld = variation.regular_price;
                }
              });
            });

            console.log(cartItems);
            setCart(cartItems);

            navigate(`/cart`);
          });
        } else {
          setloadingArchived(order.id);
        }
      });
    }
  };

  const FormPersonalDataBirthdayInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className="af-class-g-select w-dropdown" onClick={onClick} ref={ref}>
        <div className="af-class-g-select__toggle w-dropdown-toggle">
          <div className="af-class-g-select__text" style={{ fontSize: "14px" }}>
            {value ? (
              value
            ) : (
              <span style={{ color: "#797979" }}>Дата рождения</span>
            )}
          </div>
          <img
            style={{
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
              msTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            }}
            loading="lazy"
            src="/images/select-arrow.svg"
            alt=""
            className="af-class-g-select__icon"
          />
        </div>
      </div>
    )
  );

  const ReviewText = (review) => {
    console.log(review)
    try {
      let reviewText = review.review.text?.replaceAll('<p>', '')?.replaceAll('</p>', '');
      let reviewParsed = JSON.parse(reviewText);
      return <>
        {reviewParsed.tastes.length > 0 && <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px'}}>
          {tastes
            .filter(taste => reviewParsed.tastes.includes(taste.id))
            .map(taste => <Taste
                key={taste.id}
                name={taste.name}
                color={taste.color}
            />)}
        </div>}

        <div dangerouslySetInnerHTML={{ __html: `<p>${reviewParsed.text}</p>` }} />
      </>
    } catch (e) {
      return <div dangerouslySetInnerHTML={{ __html: review.review.text }} />
    }
  }

  return (
    <>
      <ReviewForm
        reviewPopupOpened={reviewPopupOpened}
        setReviewPopupOpened={setReviewPopupOpened}
        handleNewUserReview={handleNewUserReview}
      />

      {/* <div style={{display: 'none'}} className="af-class-popup--subscription">
              <div data-w-id="f04d8cc5-d9ba-0265-741f-a5d56c432004" className="af-class-div-block-13" />
              <div className="af-class-div-block-10">
                <div data-w-id="f04d8cc5-d9ba-0265-741f-a5d56c432006" className="af-class-div-block-11"><img src="images/icon--close-popup.svg" loading="lazy" alt="" /></div>
                <div className="af-class-text-block-21">Оформить подписку на заказ №51937593</div>
                <div className="af-class-cart__form w-form">
                  <form action="#" id="wf-form-User-shipping-info" name="wf-form-User-shipping-info" data-name="User shipping info" method="get" className="af-class-cart__user">
                    <div>
                      <div className="af-class-div-block-12">
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43200e-5d3d362c" className="af-class-text-block-22">Honduras Luis Castillo</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432010-5d3d362c">250 г</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432012-5d3d362c">В зернах</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432014-5d3d362c">1 пачка</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432016-5d3d362c" className="af-class-text-block-23">670 ₽</div>
                      </div>
                      <div className="af-class-div-block-12">
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432019-5d3d362c" className="af-class-text-block-22">Colombia Sanjuanero Decaf</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43201b-5d3d362c">250 г</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43201d-5d3d362c">В зернах</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43201f-5d3d362c">1 пачка</div>
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432021-5d3d362c" className="af-class-text-block-23">670 ₽</div>
                      </div>
                    </div>
                    <div id="w-node-ec8cc25a-5643-9799-08e4-e0f003cfbd0a-5d3d362c" className="w-layout-grid af-class-cart__user-info">
                      <h3 id="w-node-ec8cc25a-5643-9799-08e4-e0f003cfbd0b-5d3d362c" className="af-class-cart__user-title">Периодичность</h3>
                      <div id="w-node-ec8cc25a-5643-9799-08e4-e0f003cfbd0d-5d3d362c" className="w-layout-grid af-class-cart__user-fields">
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-ec8cc25a-5643-9799-08e4-e0f003cfbd0e-5d3d362c" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Периодичность</div><img loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="Payment-method-2" name="Payment-method-2" data-name="Payment Method 2" required className="af-class-g-select__content w-select">
                              <option value>Периодичность</option>
                              <option value="once-a-month">1 раз в месяц</option>
                              <option value="twice-a-month">2 раза в месяц</option>
                            </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-ec8cc25a-5643-9799-08e4-e0f003cfbd19-5d3d362c" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432023-5d3d362c" className="w-layout-grid af-class-cart__user-info">
                      <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432024-5d3d362c" className="af-class-cart__user-title-wrapper">
                        <h3 id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432025-5d3d362c" className="af-class-cart__user-title">Доставка</h3>
                        <a data-w-id="f04d8cc5-d9ba-0265-741f-a5d56c432027" href="#" className="af-class-button--secondry w-inline-block">
                          <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432028-5d3d362c" className="af-class-cart__button--secondry__text">Изменить</div>
                          <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43202a-5d3d362c" className="af-class-cart__button--secondry__underline" />
                        </a>
                      </div>
                      <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43202b-5d3d362c" className="w-layout-grid af-class-cart__user-fields"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="City" data-name="City" placeholder="Город" id="City" required />
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43202d-5d3d362c" data-w-id="f04d8cc5-d9ba-0265-741f-a5d56c43202d" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Выберите тип доставки</div><img style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="Shipping-Type-2" name="Shipping-Type-2" data-name="Shipping Type 2" required className="af-class-g-select__content w-select">
                              <option value>Выберите тип доставки</option>
                              <option value="First">Курьерская служба</option>
                              <option value="Second">Транспортная компания</option>
                              <option value="pickup">Самовывоз</option>
                            </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432038-5d3d362c" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43203b-5d3d362c" data-w-id="f04d8cc5-d9ba-0265-741f-a5d56c43203b" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Выберите компанию</div><img style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="transport-company-2" name="transport-company-2" data-name="Transport Company 2" required className="af-class-g-select__content w-select">
                              <option value>Выберите компанию</option>
                              <option value="СДЭК МСК 1">СДЭК МСК 1</option>
                              <option value="СДЭК МСК 2">СДЭК МСК 2</option>
                              <option value="СДЭК МСК 3">СДЭК МСК 1</option>
                            </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432046-5d3d362c" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Street" data-name="Street" placeholder="Улица" id="Street" required />
                        <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43204a-5d3d362c" className="w-layout-grid af-class-cart__user-adress-group"><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="House-number" data-name="House number" placeholder="Дом" id="House-number" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Building" data-name="Building" placeholder="Корпус" id="Building" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Entrance" data-name="Entrance" placeholder="Подъезд" id="Entrance" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Floor" data-name="Floor" placeholder="Этаж" id="Floor" required /><input type="text" className="af-class-cart__user-field w-input" maxLength={256} name="Apartment" data-name="Apartment" placeholder="Квартира" id="Apartment" required /><input type="number" className="af-class-cart__user-field w-input" maxLength={256} name="postcode-2" data-name="postcode 2" placeholder="Индекс" id="postcode-2" required /></div>
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432051-5d3d362c" data-w-id="f04d8cc5-d9ba-0265-741f-a5d56c432051" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Время доставки</div><img style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="shipping-date-and-time" name="shipping-date-and-time" data-name="shipping-date-and-time" required className="af-class-g-select__content w-select">
                              <option value>Выберите дату и время доставки</option>
                              <option value="23-12-22-15-00-20-00">23 декабря с 15:00 до 20:00</option>
                              <option value="23-12-22-20-00-23-00">23 декабря с 20:00 до 23:00</option>
                            </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43205c-5d3d362c" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43205f-5d3d362c" className="w-layout-grid af-class-cart__user-info">
                      <h3 id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432060-5d3d362c" className="af-class-cart__user-title">Оплата</h3>
                      <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432062-5d3d362c" className="w-layout-grid af-class-cart__user-fields">
                        <div data-delay={0} data-hover="false" fs-selectcustom-element="dropdown" fs-selectcustom-hideinitial="true" id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432063-5d3d362c" data-w-id="f04d8cc5-d9ba-0265-741f-a5d56c432063" className="af-class-g-select w-dropdown">
                          <div className="af-class-g-select__toggle w-dropdown-toggle">
                            <div className="af-class-g-select__text">Способ оплаты</div><img style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-g-select__icon" />
                          </div>
                          <nav className="af-class-g-select__list w-dropdown-list"><select id="Payment-method" name="Payment-method" data-name="Payment method" required className="af-class-g-select__content w-select">
                              <option value>Выберите способ оплаты</option>
                              <option value="with-card-online">Оплата банковской картой на сайте</option>
                              <option value="cash">Наличными</option>
                            </select>
                            <a fs-selectcustom-element="option-reset" href="#" className="af-class-g-select__reset w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/6202eb5aabfa701b52129070/6202eb5aabfa7018cc1290a2_close-black.svg" alt="" className="af-class-g-select__reset-icon" />
                              <div className="af-class-g-select__reset-text">Сбросить выбор</div>
                              <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c43206e-5d3d362c" className="af-class-g-select__reset-divider" />
                            </a>
                            <a href="#" className="af-class-g-select__link w-dropdown-link">Link 1</a>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432071-5d3d362c" className="w-layout-grid af-class-cart__user-info">
                      <h3 id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432072-5d3d362c" className="af-class-cart__user-title">Комментарии</h3>
                      <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432074-5d3d362c" className="w-layout-grid af-class-cart__user-fields"><textarea placeholder="Оставьте комментарий к заказу" maxLength={5000} id="Comments" name="Comments" data-name="field" className="af-class-cart__user-text-area w-node-f04d8cc5-d9ba-0265-741f-a5d56c432075-5d3d362c w-input" defaultValue={""} /></div>
                    </div>
                    <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432076-5d3d362c" className="af-class-cart__form-submit-button-wrapper-copy"><input type="submit" defaultValue="Оформить подписку" data-wait="Оформляется..." id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432077-5d3d362c" className="af-class-g-submit-button w-button" /></div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div> */}

      {/* <div style={{display: 'none'}} className="af-class-popup--points">
              <div data-w-id="2cae4137-c271-17c6-adb1-90eaeb6db075" className="af-class-div-block-13-copy" />
              <div className="af-class-div-block-10-copy">
                <div data-w-id="2cae4137-c271-17c6-adb1-90eaeb6db077" className="af-class-div-block-11"><img src="images/icon--close-popup.svg" loading="lazy" alt="" /></div>
                <div className="af-class-text-block-21-copy">Как получить баллы?</div>
                <div className="w-layout-grid af-class-grid-42">
                  <div id="w-node-_71451185-4c9c-634a-988c-6bef95aeeef9-5d3d362c" className="af-class-text-block-45">• Подпишитесь на рассылку</div>
                  <div id="w-node-_5b9c79f8-580d-5e37-55b7-54b2bfdcc956-5d3d362c" className="af-class-text-block-45">• Баллы на день рождения</div>
                  <div id="w-node-b533d42a-a747-a534-0f97-cf893f8a1c32-5d3d362c" className="af-class-text-block-45">• Пригласите друга</div>
                  <div id="w-node-ee035a26-4ccc-afdd-01d5-dcb4ca3ea384-5d3d362c" className="af-class-text-block-45">• Получайте баллы за покупки</div>
                  <div id="w-node-cab6ef08-997a-eb79-53c8-81cc85832c4a-5d3d362c" className="af-class-text-block-45">• Персональная реферальная ссылка, по которой новые пользователи (зарегестрированные) получают баллы и "приносят" баллы</div>
                </div>
              </div>
            </div> */}

      {/* <div style={{display: 'none'}} className="af-class-popup--tastes">
              <div data-w-id="165f40d2-aa4b-5d32-345a-4019555efb42" className="af-class-div-block-13-copy" />
              <div className="af-class-div-block-10-copy-copy">
                <div data-w-id="165f40d2-aa4b-5d32-345a-4019555efb44" className="af-class-div-block-11"><img src="images/icon--close-popup.svg" loading="lazy" alt="" /></div>
                <div className="af-class-text-block-21-copy-copy">Выберите вкусы</div>
                <div data-current="Tab 1" data-easing="ease" data-duration-in={300} data-duration-out={100} className="w-tabs">
                  <div className="af-class-tabs-menu-3 w-tab-menu">
                    <a data-w-tab="Tab 1" className="af-class-tab--tastes w-inline-block w-tab-link w--current">
                      <div>Обжарка</div>
                    </a>
                    <a data-w-tab="Tab 2" className="af-class-tab--tastes w-inline-block w-tab-link">
                      <div>Обработка</div>
                    </a>
                    <a data-w-tab="Tab 3" className="af-class-tab--tastes w-inline-block w-tab-link">
                      <div>Терруар</div>
                    </a>
                  </div>
                  <div className="w-tab-content">
                    <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                      <div className="w-layout-grid af-class-grid-51">
                        <div className="w-layout-grid af-class-grid-46">
                          <div id="w-node-_30aba0dd-56a6-0215-834c-3f8f2de16240-5d3d362c" className="w-layout-grid af-class-grid-47">
                            <div id="w-node-a868e2fd-fb55-6a55-ec9b-7dd29bd53f0c-5d3d362c" className="w-layout-grid af-class-grid-48">
                              <div id="w-node-_40dc337b-ad6e-cc5c-4bbc-63186dd70656-5d3d362c" className="w-layout-grid af-class-tastes-accordeon">
                                <div id="w-node-e3976838-b839-a20f-ba72-89948132f45f-5d3d362c" className="af-class-tastes-accordeon__text">Сладкие ароматы</div><img src="images/select-arrow.svg" loading="lazy" id="w-node-_50e9bed1-8bdc-cc2f-a92b-83e024ff46d6-5d3d362c" alt="" className="af-class-tastes-accordeon__icon" />
                              </div>
                              <div id="w-node-_60b6a052-9f43-1a51-88b2-aca4fffbc85f-5d3d362c" className="af-class-div-block-27">
                                <div id="w-node-_889c41ff-962d-c542-d546-0f77d426ac98-5d3d362c" className="af-class-div-block-27-copy">
                                  <div className="w-layout-grid af-class-taste-button af-class-cane-sugar">
                                    <div id="w-node-_84346b07-1be6-0c26-bbb7-13ab67928a04-5d3d362c" className="af-class-taste-button__text">Тростниковый сахар</div>
                                    <div id="w-node-_20e181c7-9f72-f7a2-92c5-12fc866fe3e2-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--remove-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-caramel">
                                    <div id="w-node-b9af3c26-8915-f365-36cc-1ecb2ebb8d9b-5d3d362c" className="af-class-taste-button__text">Карамель</div>
                                    <div id="w-node-b9af3c26-8915-f365-36cc-1ecb2ebb8d9d-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-honey">
                                    <div id="w-node-_07bd9252-88cc-5a45-1a12-8353cd96e91c-5d3d362c" className="af-class-taste-button__text">Мед</div>
                                    <div id="w-node-_07bd9252-88cc-5a45-1a12-8353cd96e91e-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-vanilla">
                                    <div id="w-node-e78b6ba8-d4d9-b398-619b-ef382d8a73f2-5d3d362c" className="af-class-taste-button__text af-class-dark">Ваниль</div>
                                    <div id="w-node-e78b6ba8-d4d9-b398-619b-ef382d8a73f4-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-muple-syrup">
                                    <div id="w-node-_58c6c813-8d98-e4a2-b0e9-eb04b492ae7a-5d3d362c" className="af-class-taste-button__text">Кленовый сироп</div>
                                    <div id="w-node-_58c6c813-8d98-e4a2-b0e9-eb04b492ae7c-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-bakery">
                                    <div id="w-node-_42f97f9a-106d-6ab9-a65f-205011197e33-5d3d362c" className="af-class-taste-button__text">Выпечка</div>
                                    <div id="w-node-_42f97f9a-106d-6ab9-a65f-205011197e35-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-nougat">
                                    <div id="w-node-_40b13edd-153b-c2c4-2efb-710ac3f75cc1-5d3d362c" className="af-class-taste-button__text">Нуга</div>
                                    <div id="w-node-_40b13edd-153b-c2c4-2efb-710ac3f75cc3-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2d6-5d3d362c" className="w-layout-grid af-class-grid-48">
                              <div id="w-node-_343ba73b-9ca4-daf2-21c1-d587f0e02874-5d3d362c" className="w-layout-grid af-class-tastes-accordeon">
                                <div id="w-node-_343ba73b-9ca4-daf2-21c1-d587f0e02875-5d3d362c" className="af-class-tastes-accordeon__text">Сухофрукты</div><img src="images/select-arrow.svg" loading="lazy" id="w-node-_343ba73b-9ca4-daf2-21c1-d587f0e02877-5d3d362c" alt="" className="af-class-tastes-accordeon__icon" />
                              </div>
                              <div id="w-node-a25b26c5-913a-209f-377b-fbe4624a430b-5d3d362c" className="af-class-div-block-27">
                                <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2d9-5d3d362c" className="af-class-div-block-27-copy">
                                  <div className="w-layout-grid af-class-taste-button af-class-raisin">
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2db-5d3d362c" className="af-class-taste-button__text">Изюм</div>
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2dd-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-prunes">
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2e0-5d3d362c" className="af-class-taste-button__text">Чернослив</div>
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2e2-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--remove-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-dates">
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2e5-5d3d362c" className="af-class-taste-button__text">Финики</div>
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2e7-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-figs">
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2ea-5d3d362c" className="af-class-taste-button__text">Инжир</div>
                                    <div id="w-node-a0fb8c6b-05b6-b860-a73f-ce7f54b2a2ec-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f04-5d3d362c" className="w-layout-grid af-class-grid-48">
                              <div id="w-node-_56379cb8-a50a-2344-ecf3-833557574641-5d3d362c" className="w-layout-grid af-class-tastes-accordeon">
                                <div id="w-node-_56379cb8-a50a-2344-ecf3-833557574642-5d3d362c" className="af-class-tastes-accordeon__text">Орехи и семечки</div><img src="images/select-arrow.svg" loading="lazy" id="w-node-_56379cb8-a50a-2344-ecf3-833557574644-5d3d362c" alt="" className="af-class-tastes-accordeon__icon" />
                              </div>
                              <div id="w-node-_296c19f4-20ad-dc9c-c120-60a9b90c47a4-5d3d362c" className="af-class-div-block-27">
                                <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f07-5d3d362c" className="af-class-div-block-27-copy">
                                  <div className="w-layout-grid af-class-taste-button af-class-almond">
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f09-5d3d362c" className="af-class-taste-button__text">Миндаль</div>
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f0b-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-hazelnut">
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f0e-5d3d362c" className="af-class-taste-button__text">Фундук / Лесной орех</div>
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f10-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-walnut">
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f13-5d3d362c" className="af-class-taste-button__text">Грецкий орех</div>
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f15-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-cashew">
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f18-5d3d362c" className="af-class-taste-button__text">Кешью</div>
                                    <div id="w-node-_1a4b7fb3-7061-36f2-f9ee-529272766f1a-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-peanut">
                                    <div id="w-node-_62e769ce-8f16-ea81-0085-5dd5a6c67fd7-5d3d362c" className="af-class-taste-button__text">Арахис</div>
                                    <div id="w-node-_62e769ce-8f16-ea81-0085-5dd5a6c67fd9-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-pecan">
                                    <div id="w-node-cb881417-8c90-cda3-cd15-5270f4333da3-5d3d362c" className="af-class-taste-button__text">Пекан</div>
                                    <div id="w-node-cb881417-8c90-cda3-cd15-5270f4333da5-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-sesame">
                                    <div id="w-node-def7fcbd-f202-487f-82f2-e30899c1757e-5d3d362c" className="af-class-taste-button__text">Кунжут</div>
                                    <div id="w-node-def7fcbd-f202-487f-82f2-e30899c17580-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c5204-5d3d362c" className="w-layout-grid af-class-grid-48">
                              <div id="w-node-bcdc956d-d932-81cf-1302-44e00f605f72-5d3d362c" className="w-layout-grid af-class-tastes-accordeon">
                                <div id="w-node-bcdc956d-d932-81cf-1302-44e00f605f73-5d3d362c" className="af-class-tastes-accordeon__text">Шоколад</div><img src="images/select-arrow.svg" loading="lazy" id="w-node-bcdc956d-d932-81cf-1302-44e00f605f75-5d3d362c" alt="" className="af-class-tastes-accordeon__icon" />
                              </div>
                              <div id="w-node-ae5ff037-a028-a47b-b000-1e0e3c43544f-5d3d362c" className="af-class-div-block-27">
                                <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c5207-5d3d362c" className="af-class-div-block-27-copy">
                                  <div className="w-layout-grid af-class-taste-button af-class-dark-chocolate">
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c5209-5d3d362c" className="af-class-taste-button__text">Темный шоколад</div>
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c520b-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-milk-chocolate">
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c520e-5d3d362c" className="af-class-taste-button__text">Молочный шоколад</div>
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c5210-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-cocoa">
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c5213-5d3d362c" className="af-class-taste-button__text">Какао</div>
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c5215-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-chocolate-cake">
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c5218-5d3d362c" className="af-class-taste-button__text">Шоколадная выпечка</div>
                                    <div id="w-node-e027512c-991d-03c2-ce47-27c69b5c521a-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd50-5d3d362c" className="w-layout-grid af-class-grid-48">
                              <div id="w-node-_11d6d596-fb2c-b4e3-2821-cf0153869200-5d3d362c" className="w-layout-grid af-class-tastes-accordeon">
                                <div id="w-node-_11d6d596-fb2c-b4e3-2821-cf0153869201-5d3d362c" className="af-class-tastes-accordeon__text">Специи</div><img src="images/select-arrow.svg" loading="lazy" id="w-node-_11d6d596-fb2c-b4e3-2821-cf0153869203-5d3d362c" alt="" className="af-class-tastes-accordeon__icon" />
                              </div>
                              <div id="w-node-_67663f0e-7244-7af3-966a-293f4ee4f270-5d3d362c" className="af-class-div-block-27">
                                <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd53-5d3d362c" className="af-class-div-block-27-copy">
                                  <div className="w-layout-grid af-class-taste-button af-class-cardamon">
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd55-5d3d362c" className="af-class-taste-button__text">Кардамон</div>
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd57-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-cloves">
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd5a-5d3d362c" className="af-class-taste-button__text">Гвоздика</div>
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd5c-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-coriander">
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd5f-5d3d362c" className="af-class-taste-button__text">Кориандор</div>
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd61-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-anise">
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd64-5d3d362c" className="af-class-taste-button__text">Анис /Бадьян</div>
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd66-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-cinnamon">
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd69-5d3d362c" className="af-class-taste-button__text">Корица</div>
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd6b-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-nutmeg">
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd6e-5d3d362c" className="af-class-taste-button__text">Мускатный орех</div>
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd70-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-allspice">
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd73-5d3d362c" className="af-class-taste-button__text">Душистый перец</div>
                                    <div id="w-node-_8c54455a-e476-cc09-6d44-1f9b329fcd75-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                  <div className="w-layout-grid af-class-taste-button af-class-paprika">
                                    <div id="w-node-_41d9d11e-a45f-2556-21b1-6df937d5b333-5d3d362c" className="af-class-taste-button__text">Паприка</div>
                                    <div id="w-node-_41d9d11e-a45f-2556-21b1-6df937d5b335-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--add-taste.svg" loading="lazy" alt="" /></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="w-node-_56206904-f8e1-5fe6-a8e7-3225e76bdcc0-5d3d362c" className="af-class-div-block-28" />
                          <div id="w-node-bf85c484-fd6f-01ce-e02c-6c7b144d20fe-5d3d362c" className="w-layout-grid af-class-grid-49">
                            <div id="w-node-d35de102-c41a-fa78-169e-cf2ca3d6350d-5d3d362c" className="w-layout-grid af-class-grid-50 af-class-_1">
                              <div id="w-node-_54039555-2e5f-66f0-990c-da388034714e-5d3d362c" className="af-class-text-block-50 af-class-_1">Ваш выбор</div>
                              <a id="w-node-bbfe2e79-75c8-460e-febe-21347f21d537-5d3d362c" href="#" className="af-class-button--secondry w-inline-block">
                                <div id="w-node-bbfe2e79-75c8-460e-febe-21347f21d538-5d3d362c" className="af-class-cart__button--secondry__text">Сбросить все фильтры</div>
                                <div id="w-node-bbfe2e79-75c8-460e-febe-21347f21d53a-5d3d362c" className="af-class-cart__button--secondry__underline" />
                              </a>
                            </div>
                            <div id="w-node-_767af58c-4d1a-df50-52d0-67a2038ed458-5d3d362c" className="w-layout-grid af-class-grid-50">
                              <div id="w-node-_767af58c-4d1a-df50-52d0-67a2038ed459-5d3d362c" className="af-class-text-block-50 af-class-_2">Обжарка:</div>
                              <div id="w-node-bf3853ba-d726-121a-94f0-f62cf58b2023-5d3d362c" className="af-class-div-block-27 af-class-_2">
                                <div className="w-layout-grid af-class-taste-button af-class-cane-sugar">
                                  <div id="w-node-bf3853ba-d726-121a-94f0-f62cf58b2025-5d3d362c" className="af-class-taste-button__text">Тростниковый сахар</div>
                                  <div id="w-node-bf3853ba-d726-121a-94f0-f62cf58b2027-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--remove-taste.svg" loading="lazy" alt="" /></div>
                                </div>
                                <div className="w-layout-grid af-class-taste-button af-class-prunes">
                                  <div id="w-node-_745f4c9c-2761-0c12-6894-6a689a2c6df8-5d3d362c" className="af-class-taste-button__text">Чернослив</div>
                                  <div id="w-node-_745f4c9c-2761-0c12-6894-6a689a2c6dfa-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--remove-taste.svg" loading="lazy" alt="" /></div>
                                </div>
                              </div>
                            </div>
                            <div id="w-node-_54146765-8831-504d-7eac-4af3a3fbd413-5d3d362c" className="w-layout-grid af-class-grid-50">
                              <div id="w-node-_54146765-8831-504d-7eac-4af3a3fbd414-5d3d362c" className="af-class-text-block-50 af-class-_2">Обработка:</div>
                              <div id="w-node-_54146765-8831-504d-7eac-4af3a3fbd416-5d3d362c" className="af-class-div-block-27 af-class-_2" />
                            </div>
                            <div id="w-node-bd213b1c-9676-d83d-1ea3-15bc02eaedf3-5d3d362c" className="w-layout-grid af-class-grid-50">
                              <div id="w-node-bd213b1c-9676-d83d-1ea3-15bc02eaedf4-5d3d362c" className="af-class-text-block-50 af-class-_2">Терруар:</div>
                              <div id="w-node-bd213b1c-9676-d83d-1ea3-15bc02eaedf6-5d3d362c" className="af-class-div-block-27 af-class-_2">
                                <div className="w-layout-grid af-class-taste-button af-class-lime">
                                  <div id="w-node-bd213b1c-9676-d83d-1ea3-15bc02eaedf8-5d3d362c" className="af-class-taste-button__text">Лайм</div>
                                  <div id="w-node-bd213b1c-9676-d83d-1ea3-15bc02eaedfa-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--remove-taste.svg" loading="lazy" alt="" /></div>
                                </div>
                                <div className="w-layout-grid af-class-taste-button af-class-ruspberry">
                                  <div id="w-node-bd213b1c-9676-d83d-1ea3-15bc02eaedfd-5d3d362c" className="af-class-taste-button__text">Малина</div>
                                  <div id="w-node-bd213b1c-9676-d83d-1ea3-15bc02eaedff-5d3d362c" className="af-class-taste-button__icon-wrapper"><img src="images/icon--remove-taste.svg" loading="lazy" alt="" /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a id="w-node-_9f6970a6-6a3f-471e-5cd2-32fcbb1b418e-5d3d362c" href="#" className="af-class-button--primary af-class-width--auto w-button">Сохранить изменения</a>
                      </div>
                    </div>
                    <div data-w-tab="Tab 2" className="w-tab-pane" />
                    <div data-w-tab="Tab 3" className="w-tab-pane" />
                  </div>
                </div>
              </div>
            </div> */}

      <div className="af-class-section af-class-p-business-account af-class-wf-section">
        <div className="af-class-container w-container">
          <div className="w-layout-grid af-class-title-and-content">
            <h1
              id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4bcb-5d3d362c"
              className="af-class-page-title af-class-p-business-account"
            >
              Личный кабинет
            </h1>
            <div
              id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4bcd-5d3d362c"
              data-current="Tab 1"
              data-easing="ease"
              data-duration-in={300}
              data-duration-out={100}
              className="af-class-tabs--p-business-account w-tabs"
            >
              <div
                id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4bce-5d3d362c"
                className="af-class-tabs-menu--p-business-account w-tab-menu"
              >
                <a
                  onClick={() => setActiveTab(1)}
                  className={`af-class-tab--primary w-inline-block w-tab-link${
                    activeTab == 1 ? " w--current" : ""
                  }`}
                >
                  <div>Личные данные</div>
                </a>
                <a
                  onClick={() => setActiveTab(2)}
                  className={`af-class-tab--primary w-inline-block w-tab-link${
                    activeTab == 2 ? " w--current" : ""
                  }`}
                >
                  <div>Aдреса</div>
                </a>
                <a
                  onClick={() => setActiveTab(3)}
                  className={`af-class-tab--primary w-inline-block w-tab-link${
                    activeTab == 3 ? " w--current" : ""
                  }`}
                >
                  <div>История заказов</div>
                </a>
                <a
                  onClick={() => setActiveTab(4)}
                  className={`af-class-tab--primary w-inline-block w-tab-link${
                    activeTab == 4 ? " w--current" : ""
                  }`}
                >
                  <div>Избранные товары</div>
                </a>
                <a
                  onClick={() => setActiveTab(5)}
                  className={`af-class-tab--primary w-inline-block w-tab-link${
                    activeTab == 5 ? " w--current" : ""
                  }`}
                >
                  <div>Бонусная система</div>
                </a>
                {/* <a onClick={() => setActiveTab(6)} className={`af-class-tab--primary w-inline-block w-tab-link${activeTab == 6 ? ' w--current' : ''}`}>
                        <div>Выбор вкуса</div>
                      </a> */}
                <a
                  onClick={() => setActiveTab(7)}
                  className={`af-class-tab--primary w-inline-block w-tab-link${
                    activeTab == 7 ? " w--current" : ""
                  }`}
                >
                  <div>Отзывы</div>
                </a>
              </div>
              <div className="af-class-tabs-content--p-business-account w-tab-content">
                <div
                  data-w-tab="Tab 1"
                  className={`w-tab-pane${
                    activeTab == 1 ? " w--tab-active" : ""
                  }`}
                >
                  <div className="w-layout-grid af-class-tab-wrapper--profile af-class-personal-profile">
                    <div className="af-class-tab-title">Личные данные</div>
                    <div
                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c1f-5d3d362c"
                      className="w-layout-grid af-class-tab-content-2--profile"
                    >
                      <div
                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c20-5d3d362c"
                        className="w-layout-grid af-class-tab-content-3--profile"
                      >
                        <div
                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c21-5d3d362c"
                          className="w-layout-grid af-class-tab-content-4--profile"
                        >
                          <div
                            style={{
                              width: '100px',
                              height: '100px',
                              borderRadius: '100px',
                              backgroundColor: '#ececec',
                              backgroundImage: `url("${mainContext.userWpData?.acf?.avatar ? mainContext.userWpData.acf.avatar : '/images/user-outline.svg'}")`,
                              backgroundSize: mainContext.userWpData?.acf?.avatar ? 'cover' : 'auto',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              marginBottom: '20px',
                            }}
                          />
                          <div className="af-class-tab-content__text--profile">
                            {mainContext.userData?.billing?.last_name}{" "}
                            {mainContext.userData?.billing?.first_name}{" "}
                            {mainContext.userWpData?.acf?.отчество}
                          </div>
                          <div className="af-class-tab-content__text--profile">
                            {user != null && user.email && user.email}
                          </div>
                          <div className="af-class-tab-content__text--profile">
                            {user != null &&
                              user.billing?.phone &&
                              user.billing?.phone}
                          </div>
                          <div className="af-class-tab-content__text--profile">
                            {mainContext.userWpData?.acf?.дата_рождения}
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      onClick={() => setPersonalDataEdit(!personalDataEdit)}
                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c34-5d3d362c"
                      data-w-id="15cd42c0-deeb-630b-9e87-e3666eae4c34"
                      href="#"
                      className="af-class-button--secondry w-inline-block"
                    >
                      <div
                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c35-5d3d362c"
                        className="af-class-cart__button--secondry__text"
                      >
                        Изменить
                      </div>
                      <div
                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c37-5d3d362c"
                        className="af-class-cart__button--secondry__underline"
                      />
                    </a>
                    <a
                      onClick={handleLogout}
                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c38-5d3d362c"
                      href="#"
                      className="af-class-button--text"
                    >
                      Выйти из аккаунта
                    </a>
                  </div>
                  <div ref={personalDataFormHeader} />
                  <div
                    className="w-layout-grid af-class-tab-wrapper--profile-edit af-class-personal-account"
                    style={{
                      marginTop: "50px",
                      display: personalDataEdit ? "grid" : "none",
                    }}
                  >
                    <div className="af-class-tab-title">Личные данные</div>
                    <div
                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4bf7-5d3d362c"
                      className="af-class-tab-form w-form"
                    >
                      <form
                        action="#"
                        onSubmit={(e) => handleFormPersonalDataSubmit(e)}
                        id="wf-form-Personal-Profile"
                        name="wf-form-Personal-Profile"
                        data-name="Personal Profile"
                        method="get"
                        className="af-class-tab-fields"
                      >
                        <div style={{width: '100px', height: '100px', position: 'relative'}}>
                          <label
                            htmlFor="form-personal-avatar-input"
                            style={{
                              width: '100px',
                              height: '100px',
                              borderRadius: '100px',
                              backgroundColor: '#ececec',
                              backgroundImage: `url("${formPersonalAvatarPreview ? formPersonalAvatarPreview : '/images/user-outline.svg'}")`,
                              backgroundSize: formPersonalAvatarPreview ? 'cover' : 'auto',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              cursor: 'pointer',
                            }}
                          />
                          <input
                            id="form-personal-avatar-input"
                            type="file"
                            onChange={handleChangeAvatar}
                            style={{display: 'none'}}
                          />
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '24px',
                              backgroundColor: '#1D71B8',
                              position: 'absolute',
                              bottom: 0,
                              right: '8px',
                              backgroundImage: 'url("/images/edit-outline.svg")',
                              backgroundSize: 'auto',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                          />
                        </div>
                        <input
                          type="text"
                          value={formPersonalDataSurname}
                          onChange={handleChangeInputText}
                          name="surname"
                          className={`af-class-cart__user-field w-input${
                            formPersonalDataSurnameValid === false
                              ? " user-input-invalid"
                              : ""
                          }`}
                          maxLength={256}
                          placeholder="Фамилия"
                        />
                        <input
                          type="text"
                          value={formPersonalDataName}
                          onChange={handleChangeInputText}
                          name="name"
                          className={`af-class-cart__user-field w-input${
                            formPersonalDataNameValid === false
                              ? " user-input-invalid"
                              : ""
                          }`}
                          maxLength={256}
                          placeholder="Имя"
                        />
                        <input
                          type="text"
                          value={formPersonalDataPatronymic}
                          onChange={handleChangeInputText}
                          name="patronymic"
                          className={`af-class-cart__user-field w-input${
                            formPersonalDataPatronymicValid === false
                              ? " user-input-invalid"
                              : ""
                          }`}
                          maxLength={256}
                          placeholder="Отчество"
                        />
                        {/* <input type="tel" value={formPersonalDataPhone} onChange={handleChangeInputText} name="phone" className={`af-class-cart__user-field w-input${formPersonalDataPhoneValid === false ? ' user-input-invalid' : ''}`} maxLength={256} placeholder="Телефон" /> */}
                        <MaskInput
                          alwaysShowMask
                          mask={"+0 000 000 00 00"}
                          size={20}
                          showMask
                          maskChar="_"
                          value={formPersonalDataPhone}
                          onChange={handleChangeInputText}
                          name="phone"
                          className={`af-class-cart__user-field w-input${
                            formPersonalDataPhoneValid === false
                              ? " user-input-invalid"
                              : ""
                          }`}
                          placeholder="Телефон"
                        />
                        {/* <input type="email" value={formPersonalDataEmail} onChange={(e) => setFormPersonalDataEmail(e.target.value)} className="af-class-cart__user-field w-input" maxLength={256} placeholder="Эл. почта" /> */}

                        <DatePicker
                          selected={formPersonalDataBirthdayFull}
                          onChange={(date) =>
                            handleFormPersonalDataBirthdayFull(date)
                          }
                          customInput={<FormPersonalDataBirthdayInput />}
                          dateFormat="dd.MM.yyyy"
                          showPopperArrow={false}
                          calendarStartDay={1}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />

                        <input
                          type="password"
                          value={formPersonalDataNewPassword}
                          onChange={handleChangeInputText}
                          name="new_password"
                          className={`af-class-cart__user-field w-input`}
                          maxLength={256}
                          placeholder="Новый пароль"
                        />

                        {/* <div
                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c05-5d3d362c"
                          className="af-class-form-field-wrapper"
                        >
                          <MaskInput
                            alwaysShowMask
                            mask="00.00.0000"
                            size={10}
                            showMask
                            maskChar="_"
                            type="text"
                            value={formPersonalDataBirthday}
                            onChange={handleChangeInputText}
                            name="birthday"
                            className={`af-class-cart__user-field w-input${
                              formPersonalDataBirthdayValid === false
                                ? " user-input-invalid"
                                : ""
                            }`}
                            maxLength={256}
                            placeholder="Дата рождения"
                          /> */}

                        {/* <div className="af-class-question_btn af-class-in-field">
                                  <div className="af-class-availability__tooltip_block">
                                    <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                  </div>
                                </div> */}
                        {/* </div> */}

                        <div className="w-layout-grid af-class-tab-buttons-group">
                          <input
                            type="submit"
                            value={personalDataEditing ? "Сохраняется..." : "Сохранить изменения"}
                            id="w-node-_03883b3a-c223-2ee8-2f0b-b983d853197c-5d3d362c"
                            className={`af-class-button--primary af-class-adress w-button${
                              (formPersonalDataIsValid === false || personalDataEditing === true)
                                ? " button-disabled"
                                : ""
                            }`}
                          />
                          <a
                            onClick={() =>
                              setPersonalDataEdit(!personalDataEdit)
                            }
                            id="w-node-_03883b3a-c223-2ee8-2f0b-b983d853197d-5d3d362c"
                            href="#"
                            className="af-class-button-2 af-class-adress w-button"
                          >
                            Отменить
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div
                  data-w-tab="Tab 2"
                  className={`w-tab-pane${
                    activeTab == 2 ? " w--tab-active" : ""
                  }`}
                >
                  <div className="af-class-tab-wrapper--adress">
                    <div className="af-class-tab-title af-class-tab-adress--empty">
                      Адреса
                    </div>
                    <div
                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c6e-5d3d362c"
                      className="af-class-tab-divider af-class-tab-adress-empty--1"
                    />
                    <div className="af-class-tab-form-__adress-wrapper w-form">
                      <div className="af-class-tab-form-__adress-list">
                        {userAddresses.map((elem, index) => (
                          <div
                            key={index}
                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c71-5d3d362c"
                            className="w-layout-grid af-class-tab-form-__adress-item"
                          >
                            <div
                              id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c76-5d3d362c"
                              className="w-layout-grid af-class-tab-form__adress-content"
                            >
                              <div
                                id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c77-5d3d362c"
                                className="w-layout-grid af-class-tab-form__adress-head"
                              >
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c78-5d3d362c"
                                  className="w-layout-grid af-class-tab-form__adress-title"
                                >
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c79-5d3d362c"
                                    className="af-class-tab-form__adress-title-text"
                                  >
                                    { elem.name ? elem.name : `Адрес ${elem.id}` }
                                  </div>
                                  <img
                                    onClick={() => {
                                      setPrepopulatedAddress(elem);
                                      setAddressEdit(true);
                                      scrollToAddressForm();
                                    }}
                                    style={{ cursor: "pointer" }}
                                    width={14}
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c7b-5d3d362c"
                                    src="/images/pencil_1pencil.png"
                                    loading="lazy"
                                    alt=""
                                    className="af-class-tab-form__adress-title-icon"
                                  />
                                </div>
                                {elem.default === true ? (
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c7c-5d3d362c"
                                    className="w-layout-grid af-class-tab-form__adress-lable"
                                  >
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c7d-5d3d362c"
                                      className="af-class-tab-form__adress-lable-text"
                                    >
                                      По умолчанию
                                    </div>
                                  </div>
                                ) : (
                                  <a
                                    onClick={() =>
                                      handleAddressSetDefault(elem.id)
                                    }
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca8-5d3d362c"
                                    style={{ cursor: "pointer" }}
                                    className="af-class-button--secondry w-inline-block"
                                  >
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca9-5d3d362c"
                                      className="af-class-cart__button--secondry__text"
                                    >
                                      Сделать по умолчанию
                                    </div>
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cab-5d3d362c"
                                      className="af-class-cart__button--secondry__underline"
                                    />
                                  </a>
                                )}
                              </div>
                              <div
                                id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c83-5d3d362c"
                                className="w-layout-grid af-class-tab-form__adress-body"
                              >
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c84-5d3d362c"
                                  className="af-class-tab-form__adress-text"
                                >
                                  {elem.full}
                                </div>
                                <div className="af-class-tab-form__adress-building">
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c8e-5d3d362c"
                                    className="af-class-tab-form__adress-floor-wrapper"
                                  >
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c8f-5d3d362c"
                                      className="w-layout-grid af-class-tab-form__adress-floor"
                                    >
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c90-5d3d362c"
                                        className="af-class-tab-form__adress-text"
                                      >
                                        Подъезд
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c92-5d3d362c"
                                        className="af-class-tab-form__adress-text"
                                      >
                                        {elem.entrance}
                                      </div>
                                    </div>
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c94-5d3d362c"
                                      className="af-class-tab-form__adress-text"
                                    >
                                      ,
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c96-5d3d362c"
                                    className="af-class-tab-form__adress-apartment-wrapper"
                                  >
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c97-5d3d362c"
                                      className="w-layout-grid af-class-tab-form__adress-apartment"
                                    >
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c98-5d3d362c"
                                        className="af-class-tab-form__adress-text"
                                      >
                                        Этаж
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9a-5d3d362c"
                                        className="af-class-tab-form__adress-text"
                                      >
                                        {elem.floor}
                                      </div>
                                    </div>
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9c-5d3d362c"
                                      className="af-class-tab-form__adress-text"
                                    >
                                      ,
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c96-5d3d362c"
                                    className="af-class-tab-form__adress-apartment-wrapper"
                                  >
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c97-5d3d362c"
                                      className="w-layout-grid af-class-tab-form__adress-apartment"
                                    >
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c98-5d3d362c"
                                        className="af-class-tab-form__adress-text"
                                      >
                                        Квартира
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9a-5d3d362c"
                                        className="af-class-tab-form__adress-text"
                                      >
                                        {elem.flat}
                                      </div>
                                    </div>
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9c-5d3d362c"
                                      className="af-class-tab-form__adress-text"
                                    >
                                      ,
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9e-5d3d362c"
                                    className="w-layout-grid af-class-tab-form__adress-intercom"
                                  >
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9f-5d3d362c"
                                      className="af-class-tab-form__adress-text"
                                    >
                                      Домофон
                                    </div>
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca1-5d3d362c"
                                      className="af-class-tab-form__adress-text"
                                    >
                                      {elem.homephone}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca3-5d3d362c"
                                className="w-layout-grid af-class-tab-form__adress-footer"
                              >
                                <a
                                  onClick={() => {
                                    setPrepopulatedAddress(elem);
                                    setAddressEdit(true);
                                    scrollToAddressForm();
                                  }}
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca4-5d3d362c"
                                  style={{ cursor: "pointer" }}
                                  className="af-class-button--secondry w-inline-block"
                                >
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca5-5d3d362c"
                                    className="af-class-cart__button--secondry__text"
                                  >
                                    Изменить
                                  </div>
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca7-5d3d362c"
                                    className="af-class-cart__button--secondry__underline"
                                  />
                                </a>
                                <a
                                  onClick={() => handleAddressDelete(elem.id)}
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca8-5d3d362c"
                                  style={{ cursor: "pointer" }}
                                  className="af-class-button--secondry w-inline-block"
                                >
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca9-5d3d362c"
                                    className="af-class-cart__button--secondry__text"
                                  >
                                    Удалить адрес
                                  </div>
                                  <div
                                    id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cab-5d3d362c"
                                    className="af-class-cart__button--secondry__underline"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cac-5d3d362c"
                              className="af-class-tab-divider"
                            />
                          </div>
                        ))}

                        {/* <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c71-5d3d362c" className="w-layout-grid af-class-tab-form-__adress-item">
                                <label id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c72-5d3d362c" className="af-class-g-radio-button--no-text af-class-adress w-radio"><input type="radio" name="radio" id="radio" defaultValue="Radio" data-name="Radio" className="w-form-formradioinput af-class-g-radio-button-icon--no-text w-radio-input" /><span className="af-class-g-radio-button-text--no-text w-form-label" htmlFor="radio">Radio</span></label>
                                <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c76-5d3d362c" className="w-layout-grid af-class-tab-form__adress-content">
                                  <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c77-5d3d362c" className="w-layout-grid af-class-tab-form__adress-head">
                                    <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c78-5d3d362c" className="w-layout-grid af-class-tab-form__adress-title">
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c79-5d3d362c" className="af-class-tab-form__adress-title-text">Адрес 1</div><img width={14} id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c7b-5d3d362c" src="images/pencil_1pencil.png" loading="lazy" alt="" className="af-class-tab-form__adress-title-icon" />
                                    </div>
                                    <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c7c-5d3d362c" className="w-layout-grid af-class-tab-form__adress-lable">
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c7d-5d3d362c" className="af-class-tab-form__adress-lable-text">По умолчанию</div>
                                      <div className="af-class-question_btn af-class-control-p_catalog">
                                        <div className="af-class-availability__tooltip_block">
                                          <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c83-5d3d362c" className="w-layout-grid af-class-tab-form__adress-body">
                                    <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c84-5d3d362c" className="af-class-tab-form__adress-text">Москва</div>
                                    <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c86-5d3d362c" className="af-class-tab-form__adress-text">127521</div>
                                    <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c88-5d3d362c" className="w-layout-grid af-class-tab-form__adress-street">
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c89-5d3d362c" className="af-class-tab-form__adress-text">Ул.</div>
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c8b-5d3d362c" className="af-class-tab-form__adress-text">Октябрьская</div>
                                    </div>
                                    <div className="af-class-tab-form__adress-building">
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c8e-5d3d362c" className="af-class-tab-form__adress-floor-wrapper">
                                        <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c8f-5d3d362c" className="w-layout-grid af-class-tab-form__adress-floor">
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c90-5d3d362c" className="af-class-tab-form__adress-text">Этаж </div>
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c92-5d3d362c" className="af-class-tab-form__adress-text">14</div>
                                        </div>
                                        <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c94-5d3d362c" className="af-class-tab-form__adress-text">,</div>
                                      </div>
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c96-5d3d362c" className="af-class-tab-form__adress-apartment-wrapper">
                                        <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c97-5d3d362c" className="w-layout-grid af-class-tab-form__adress-apartment">
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c98-5d3d362c" className="af-class-tab-form__adress-text">квартира</div>
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9a-5d3d362c" className="af-class-tab-form__adress-text">88</div>
                                        </div>
                                        <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9c-5d3d362c" className="af-class-tab-form__adress-text">,</div>
                                      </div>
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9e-5d3d362c" className="w-layout-grid af-class-tab-form__adress-intercom">
                                        <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c9f-5d3d362c" className="af-class-tab-form__adress-text">домофоy</div>
                                        <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca1-5d3d362c" className="af-class-tab-form__adress-text">88 к</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca3-5d3d362c" className="w-layout-grid af-class-tab-form__adress-footer">
                                    <a id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca4-5d3d362c" href="#" className="af-class-button--secondry w-inline-block">
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca5-5d3d362c" className="af-class-cart__button--secondry__text">Изменить</div>
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca7-5d3d362c" className="af-class-cart__button--secondry__underline" />
                                    </a>
                                    <a id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca8-5d3d362c" href="#" className="af-class-button--secondry w-inline-block">
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4ca9-5d3d362c" className="af-class-cart__button--secondry__text">Удалить адрес</div>
                                      <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cab-5d3d362c" className="af-class-cart__button--secondry__underline" />
                                    </a>
                                  </div>
                                </div>
                                <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cac-5d3d362c" className="af-class-tab-divider" />
                              </div> */}
                      </div>
                    </div>
                    <a
                      onClick={() => {
                        setAddressEdit(!addressEdit);
                        setFormAddressData({isValid: false});
                        setPrepopulatedAddress(
                          prepopulatedAddress === false ? null : false
                        );
                      }}
                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cef-5d3d362c"
                      style={{ cursor: "pointer" }}
                      className="af-class-button--secondry w-inline-block"
                      ref={addressFormHeader}
                    >
                      <div
                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cf0-5d3d362c"
                        className="af-class-cart__button--secondry__text"
                      >
                        Добавить новый
                      </div>
                      <div
                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4cf2-5d3d362c"
                        className="af-class-cart__button--secondry__underline"
                      />
                    </a>
                  </div>

                  <div
                    className="af-class-tab-wrapper--adress-edit"
                    style={{
                      marginTop: "50px",
                      display: addressEdit ? "grid" : "none",
                    }}
                  >
                    {userAddresses.length >= 5 ? (
                      <div>Вы можете добавить не более 5 адресов</div>
                    ) : (
                      <>
                        <div className="af-class-tab-title af-class-tab-adress--edit">
                          Адреса
                        </div>
                        <div className="af-class-tab-form w-form">
                          <div className="w-layout-grid af-class-cart__user-fields">
                            <AddressForm
                              getFormData={setFormAddressData}
                              fromCabinet={true}
                              prepopulated={prepopulatedAddress}
                              showAddressName={true}
                            />
                          </div>
                          <div className="w-layout-grid af-class-tab-buttons-group">
                            <input
                              onClick={handleAddressSave}
                              type="submit"
                              value="Сохранить адрес"
                              className={`af-class-button--primary af-class-adress w-button${
                                formAddressData.isValid === false
                                  ? " button-disabled"
                                  : ""
                              }`}
                            />
                            <a
                              onClick={() => setAddressEdit(!addressEdit)}
                              id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4c63-5d3d362c"
                              href="#"
                              className="af-class-button-2 af-class-adress w-button"
                            >
                              Отменить
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  data-w-tab="Tab 3"
                  className={`w-tab-pane${
                    activeTab == 3 ? " w--tab-active" : ""
                  }`}
                >
                  <div className="af-class-tab-wrapper--order-history">
                    <div className="af-class-tab-title af-class-tab-adress--empty">
                      История заказов
                    </div>

                    {userOrders.length > 0 ? (
                      <>
                        <div className="w-layout-grid af-class-order-history__column-titles">
                          <div
                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d37-5d3d362c"
                            className="af-class-order-history__column-title"
                          >
                            Номер
                          </div>
                          <div
                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d39-5d3d362c"
                            className="af-class-order-history__column-title"
                          >
                            Дата
                          </div>
                          <div
                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d3b-5d3d362c"
                            className="af-class-order-history__column-title"
                          >
                            Скидка
                          </div>
                          <div
                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d3d-5d3d362c"
                            className="af-class-order-history__column-title"
                          >
                            Сумма
                          </div>
                          <div
                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d3f-5d3d362c"
                            className="af-class-order-history__column-title"
                          >
                            Статус
                          </div>
                        </div>
                        <div className="af-class-order-history__wrapper">
                          {userOrders.map((elem, index) => (
                            <div
                              key={index}
                              className="af-class-order-history__list"
                            >
                              <div
                                id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d43-5d3d362c"
                                className="w-layout-grid af-class-order-history__title"
                              >
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d44-5d3d362c"
                                  className="af-class-text-block-14"
                                >
                                  №{elem.number}
                                </div>
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d46-5d3d362c"
                                  className="af-class-text-block-14-copy-copy-copy"
                                >
                                  Скидка
                                </div>
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d48-5d3d362c"
                                  className="af-class-text-block-14-copy-copy-copy"
                                >
                                  Сумма
                                </div>
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d4a-5d3d362c"
                                  className="af-class-text-block-14"
                                >
                                  {new Date(
                                    elem.date_created
                                  ).toLocaleDateString("ru-RU")}
                                </div>
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d4c-5d3d362c"
                                  className="af-class-text-block-14-copy"
                                >
                                  {elem.discount_total}
                                  <span> ₽</span>
                                </div>
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d50-5d3d362c"
                                  className="af-class-text-block-14-copy"
                                >
                                  {elem.total}
                                  <span> ₽</span>
                                </div>
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d54-5d3d362c"
                                  className={`af-class-text-block-14-copy-copy${
                                    elem.status == "completed"
                                      ? " af-class-delivered"
                                      : ""
                                  }`}
                                >
                                  {orderStatuses.find(status => status.code == elem.status) ? orderStatuses.find(status => status.code == elem.status).name : elem.status}
                                </div>
                              </div>

                              <img
                                onClick={() => handleOrderToggle(index + 1)}
                                data-w-id="15cd42c0-deeb-630b-9e87-e3666eae4d56"
                                id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d56-5d3d362c"
                                src="images/order-history__item__plus-icon.svg"
                                loading="lazy"
                                alt=""
                                className="af-class-order__action-icon"
                              />

                              <div
                                style={{
                                  height: ordersActive.find(
                                    (value) => value == index + 1
                                  )
                                    ? "auto"
                                    : 0,
                                }}
                                id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d57-5d3d362c"
                                className="af-class-order__wrapper"
                              >
                                <div
                                  id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d58-5d3d362c"
                                  className="af-class-order__list"
                                >
                                  {elem.line_items.map((elem, index) => (
                                    <div
                                      key={index}
                                      className={`w-layout-grid af-class-order__item${
                                        userOrdersProducts
                                          .find(
                                            (product) =>
                                              product.id == elem.product_id
                                          )
                                          ?.categories?.find(
                                            (cat) => cat.id == 404
                                          )
                                          ? " grayscaled-item"
                                          : ""
                                      }`}
                                    >
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d5a-5d3d362c"
                                        className="af-class-order__image-wrapper"
                                      >
                                        <img
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d5b-5d3d362c"
                                          loading="lazy"
                                          src={elem.image?.src}
                                          alt=""
                                          className="af-class-order__image"
                                          style={{ maxHeight: "100px" }}
                                        />
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d5c-5d3d362c"
                                        className="w-layout-grid af-class-order__discription"
                                      >
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d5d-5d3d362c"
                                          className="af-class-order__title"
                                        >
                                          {elem.name}
                                        </div>
                                        {/* <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d5f-5d3d362c" className="af-class-order__composition">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div> */}
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d61-5d3d362c"
                                        className="w-layout-grid af-class-order__options"
                                      >
                                        {elem.meta_data.map((elem, index) => (
                                          <div
                                            key={index}
                                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d62-5d3d362c"
                                            className="af-class-order__options-text"
                                          >
                                            {elem.value}
                                          </div>
                                        ))}
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d62-5d3d362c"
                                          className="af-class-order__options-text"
                                        >
                                          {elem.quantity} шт.
                                        </div>
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d68-5d3d362c"
                                        className="af-class-order__price"
                                      >
                                        {elem.price}
                                        <span> ₽</span>
                                      </div>
                                    </div>
                                  ))}

                                  <div className="af-class-order__summary--old">
                                    <div
                                      id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d80-5d3d362c"
                                      className="w-layout-grid af-class-order__summary-wrapper"
                                    >
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d81-5d3d362c"
                                        className="w-layout-grid af-class-order__summary-group"
                                      >
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d82-5d3d362c"
                                          className="af-class-order__summary-text"
                                        >
                                          Способ доставки:
                                        </div>
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d84-5d3d362c"
                                          className="af-class-order__summary-text"
                                        >
                                          Способ оплаты:
                                        </div>
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d86-5d3d362c"
                                        className="w-layout-grid af-class-order__summary-group"
                                      >
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d87-5d3d362c"
                                          className="af-class-order__summary-text--2"
                                        >
                                          {elem.customer_note.split("\r\n")[0]}
                                        </div>
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d89-5d3d362c"
                                          className="af-class-order__summary-text--2"
                                        >
                                          {elem.payment_method_title}
                                        </div>
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d8b-5d3d362c"
                                        className="af-class-tab-divider"
                                      />
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d8c-5d3d362c"
                                        className="w-layout-grid af-class-order__summary-group"
                                      >
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d8d-5d3d362c"
                                          className="af-class-order__summary-text"
                                        >
                                          Сумма заказа
                                        </div>
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d8f-5d3d362c"
                                          className="af-class-order__summary-text"
                                        >
                                          Скидка
                                        </div>
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d91-5d3d362c"
                                          className="af-class-order__summary-text"
                                        >
                                          Итого
                                        </div>
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d93-5d3d362c"
                                        className="w-layout-grid af-class-order__summary-group"
                                      >
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d94-5d3d362c"
                                          className="af-class-order__summary-text--2"
                                        >
                                          {parseFloat(elem.total) +
                                            parseFloat(elem.discount_total)}
                                          <span> ₽</span>
                                        </div>
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d98-5d3d362c"
                                          className="af-class-order__summary-text--2"
                                        >
                                          {elem.discount_total}
                                          <span> ₽</span>
                                        </div>
                                        <div
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4d9c-5d3d362c"
                                          className="af-class-order__summary-text--2"
                                        >
                                          {elem.total}
                                          <span> ₽</span>
                                        </div>
                                      </div>
                                      <div
                                        id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da0-5d3d362c"
                                        className="w-layout-grid af-class-order__summary-buttons-wrapper"
                                      >
                                        <a
                                          onClick={() =>
                                            handleRepeatOrder(elem)
                                          }
                                          id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da1-5d3d362c"
                                          style={{ cursor: "pointer" }}
                                          className="af-class-button--secondry w-inline-block"
                                        >
                                          <div
                                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da2-5d3d362c"
                                            className="af-class-cart__button--secondry__text"
                                          >
                                            {loadingArchived == elem.id
                                              ? "Нет в наличии"
                                              : loadingRepeat == elem.id
                                              ? "Загрузка..."
                                              : "Повторить заказ"}
                                          </div>
                                          <div
                                            id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da4-5d3d362c"
                                            className="af-class-cart__button--secondry__underline"
                                          />
                                        </a>
                                      </div>
                                      {/* <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da0-5d3d362c" className="w-layout-grid af-class-order__summary-buttons-wrapper">
                                        <a id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da1-5d3d362c" href="#" className="af-class-button--secondry w-inline-block">
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da2-5d3d362c" className="af-class-cart__button--secondry__text">Оставить отзыв</div>
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da4-5d3d362c" className="af-class-cart__button--secondry__underline" />
                                        </a>
                                        <a id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da5-5d3d362c" data-w-id="15cd42c0-deeb-630b-9e87-e3666eae4da5" href="#" className="af-class-button--secondry w-inline-block">
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da6-5d3d362c" className="af-class-cart__button--secondry__text">Оформить подписку на заказ</div>
                                          <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4da8-5d3d362c" className="af-class-cart__button--secondry__underline" />
                                        </a>
                                      </div> */}
                                    </div>
                                    {/* <div id="w-node-_15cd42c0-deeb-630b-9e87-e3666eae4dad-5d3d362c" className="af-class-text-block-20 af-class-_2">Магазин на садовом</div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div>Вы пока не сделали ни одного заказа</div>
                    )}
                  </div>
                </div>

                <div
                  data-w-tab="Tab 4"
                  className={`w-tab-pane${
                    activeTab == 4 ? " w--tab-active" : ""
                  }`}
                >
                  <div className="w-layout-grid af-class-grid-35">
                    <div className="af-class-tab-title">Избранные товары</div>
                    <div className="af-class-favorites__wrapper">
                      <div className="w-layout-grid af-class-favorites__list">
                        {favoriteItems.length > 0 ? (
                          favoriteItemsFull.map((elem, index) => (
                            <Product key={index} fromAccount={true} {...elem} />
                          ))
                        ) : (
                          <div>У вас пока нет избранных товаров</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  data-w-tab="Tab 5"
                  className={`w-tab-pane${
                    activeTab == 5 ? " w--tab-active" : ""
                  }`}
                >
                  <div className="w-layout-grid af-class-loyalty-system__content">
                    <div className="af-class-tab-title">Бонусная система</div>
                    <div
                      id="w-node-_06314d43-b718-b08a-74c9-57baddb303d9-5d3d362c"
                      className="w-layout-grid af-class-loyalty-system__block-1"
                    >
                      {/* <div id="w-node-_07a2b9ac-bd57-95a0-7ba5-195805a828f8-5d3d362c" className="af-class-loyalty-system__subtitle">После регистрации на сайте вы становитесь участником бонусной системы Rockets</div> */}
                      <div
                        id="w-node-b38afd5a-b430-e2c2-d0d6-73f9171ad98c-5d3d362c"
                        className="w-layout-grid af-class-loyalty-system__points-counter"
                      >
                        <div
                          id="w-node-_172473ea-28d2-ae24-60d5-d2e1d08bc486-5d3d362c"
                          className="af-class-loyalty-system__points-title"
                        >
                          Ваш статус
                        </div>
                        <div
                          id="w-node-df11312d-417d-4084-9feb-d2f0a55fe587-5d3d362c"
                          className="af-class-loyalty-system__points-text"
                        >
                          <span
                            className="af-class-loyalty-system__points-value"
                            style={{ fontWeight: 700 }}
                          >
                            {userStage.name}
                          </span>
                        </div>
                      </div>
                      <div
                        id="w-node-b38afd5a-b430-e2c2-d0d6-73f9171ad98c-5d3d362c"
                        className="w-layout-grid af-class-loyalty-system__points-counter"
                      >
                        <div
                          id="w-node-_172473ea-28d2-ae24-60d5-d2e1d08bc486-5d3d362c"
                          className="af-class-loyalty-system__points-title"
                        >
                          На вашем счете
                        </div>
                        <div
                          id="w-node-df11312d-417d-4084-9feb-d2f0a55fe587-5d3d362c"
                          className="af-class-loyalty-system__points-text"
                        >
                          <span className="af-class-loyalty-system__points-value">
                            {userBonuses}
                          </span>{" "}
                          {userBonuses >= 11 && userBonuses <= 14
                            ? "баллов"
                            : userBonuses % 10 == 1
                            ? "балл"
                            : userBonuses % 10 == 2 ||
                              userBonuses % 10 == 3 ||
                              userBonuses % 10 == 4
                            ? "балла"
                            : "баллов"}
                        </div>
                      </div>
                      {/* <a id="w-node-_2cd6e173-ebaf-69ad-3437-b50d5740e9f4-5d3d362c" data-w-id="2cd6e173-ebaf-69ad-3437-b50d5740e9f4" href="#" className="af-class-button--primary af-class-width--auto w-button">Получить баллы</a> */}
                    </div>
                    <div
                      id="w-node-f7c33d48-a63e-10f4-96b6-e226d6228230-5d3d362c"
                      className="w-layout-grid af-class-loyalty-system__cards-block"
                    >
                      <div
                        id="w-node-_100225d0-ef03-18f7-dda7-f707417326bd-5d3d362c"
                        className="af-class-loyalty-system__big-card"
                        style={{
                          position: "relative",
                        }}
                      >
                        <div className="af-class-loyalty-system__big-card-info">
                          {cardLink && (
                            <div
                              style={{
                                position: "absolute",
                                top: "30px",
                                right: "30px",
                              }}
                            >
                              <a
                                href={cardLink}
                                target="_blank"
                                style={{
                                  display: "block",
                                  width: "120px",
                                  marginBottom: "16px",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                  }}
                                  src={img1}
                                  alt=""
                                />
                              </a>
                              <a
                                href={cardLink}
                                target="_blank"
                                style={{
                                  display: "block",
                                  width: "120px",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                  }}
                                  src={img2}
                                  alt=""
                                />
                              </a>
                            </div>
                          )}

                          <div
                            className="af-class-loyalty-system__big-card-title"
                            style={{ fontWeight: 700 }}
                          >
                            Cashback
                          </div>
                          <div className="af-class-loyalty-system__big-card-value">
                            {userStage.discount}%
                          </div>
                          {/* <div className="af-class-loyalty-system__big-card-caption">{loyaltyPrograms.find(elem => elem.name == loyalty.level)?.threshold == 0  ? `сразу после регистрации` : `при сумме заказов от ${loyaltyPrograms.find(elem => elem.name == loyalty.level)?.threshold} руб.` }</div> */}
                          <div
                            className="af-class-loyalty-system__big-card-title"
                            style={{
                              fontWeight: 400,
                              fontFamily: "'Gotham book', sans-serif",
                            }}
                          >
                            от суммы выполненного заказа
                          </div>
                        </div>
                        {/* <div className="af-class-lloyalty-system__big-cardoyalty-system__big-card-buttons"><img width={127} id="w-node-_08a7c568-7b16-625a-8dcc-09736e455181-5d3d362c" src="images/get-on-google-play.png" loading="lazy" alt="" className="af-class-loyalty-system__big-card-button" /><img width={127} id="w-node-b5d1a205-474c-0459-0e6f-c5a0f8e145ff-5d3d362c" src="images/add-to-Apple-Wallet.png" loading="lazy" alt="" className="af-class-loyalty-system__big-card-button" /></div> */}
                      </div>
                      {loyaltyPrograms
                        .filter((elem) => elem.name != userStage.name)
                        .map((elem) => (
                          <div
                            key={elem.name}
                            id="w-node-c120b7a9-64ba-9a41-c198-9d1f841fa650-841fa650"
                            className="af-class-loyalty-system__small-card"
                          >
                            <div className="af-class-loyalty-system__small-card-titile">
                              {elem.name}
                            </div>
                            <div className="af-class-loyalty-system__small-card-value">
                              {elem.discount}%
                            </div>
                            <div className="af-class-loyalty-system__small-card-caption">
                              {elem.threshold == 0
                                ? `сразу после регистрации`
                                : `при сумме заказов от ${elem.threshold} руб.`}
                            </div>
                          </div>
                        ))}
                    </div>
                    {/* <div id="w-node-f0b18c73-8c2c-4c2f-ce69-1ec493dff2c2-5d3d362c" className="w-layout-grid af-class-loyalty-system__points-list">
                            <div className="af-class-loyalty-system__points-titile">Зачисление и списание бонусов</div>
                            <div id="w-node-dec007cd-a463-8f2d-9320-2ac0cfd2be93-5d3d362c" className="w-layout-grid af-class-loyalty-system__points-item">
                              <div id="w-node-e0877fc9-6104-73ec-29c5-34a7b17d6e69-5d3d362c" className="af-class-loyalty-system__points-date">15.12.21</div>
                              <div id="w-node-ccece1ff-5034-4c33-67c6-b3f83eae1d88-5d3d362c" className="af-class-loyalty-system__points-subtitle">Списание бонусов</div>
                              <div id="w-node-_756b8c4c-711f-0966-1068-5e707b971a50-5d3d362c" className="af-class-loyalty-system__points-number">На заказ №51935965</div>
                              <div id="w-node-c4d4ce91-3580-36b8-1af3-a2a193f2debd-5d3d362c" className="af-class-loyalty-system__points-amount">50 бонусов</div>
                            </div>
                            <div id="w-node-_77e88602-eaa2-403a-9229-6792608ae858-5d3d362c" className="w-layout-grid af-class-loyalty-system__points-item">
                              <div id="w-node-_77e88602-eaa2-403a-9229-6792608ae859-5d3d362c" className="af-class-loyalty-system__points-date">15.12.21</div>
                              <div id="w-node-_77e88602-eaa2-403a-9229-6792608ae85b-5d3d362c" className="af-class-loyalty-system__points-subtitle">Списание бонусов</div>
                              <div id="w-node-_77e88602-eaa2-403a-9229-6792608ae85d-5d3d362c" className="af-class-loyalty-system__points-number">На заказ №51935965</div>
                              <div id="w-node-_77e88602-eaa2-403a-9229-6792608ae85f-5d3d362c" className="af-class-loyalty-system__points-amount">50 бонусов</div>
                            </div>
                          </div> */}
                  </div>
                </div>

                {/* <div data-w-tab="Tab 6" className={`w-tab-pane${activeTab == 6 ? ' w--tab-active' : ''}`}>
                        <div className="w-layout-grid af-class-choice-of-taste__content">
                          <div className="af-class-tab-title">Выбор вкуса</div>
                          <div id="w-node-_4d65e06a-fbcd-3908-e7ba-f5707b8ff11f-5d3d362c" className="af-class-choice-of-taste__wrapper">
                            <div className="w-layout-grid af-class-choice-of-taste__list">
                              <div id="w-node-beab59b5-3708-718a-1382-b53973430d0e-5d3d362c" className="af-class-choice-of-taste__item">
                                <div id="w-node-_2103b2e6-c5f1-c535-0a4b-240a09072db0-5d3d362c" className="af-class-choice-of-taste__image-wrapper"><img width={70} sizes="(max-width: 767px) 100vw, (max-width: 991px) 38vw, (max-width: 1439px) 37vw, 34vw" src="images/coffe-image--choice-of-taste.jpg" loading="lazy" srcSet="images/coffe-image--choice-of-taste-p-500.jpeg 500w, images/coffe-image--choice-of-taste.jpg 550w" alt="" className="af-class-choice-of-taste__image" /></div>
                                <div id="w-node-da60c7fa-06fd-9a37-e986-d6f7e70bec34-5d3d362c" className="af-class-choice-of-taste__title">Colombia Sanjuanero Decaf</div>
                                <div id="w-node-e41da806-d864-399b-4a05-d1d0e12760b4-5d3d362c" className="af-class-choice-of-taste__tags">
                                  <div className="af-class-choice-of-taste__tag af-class-apricot">Абрикос</div>
                                  <div className="af-class-choice-of-taste__tag af-class-raisin">Изюм</div>
                                  <div className="af-class-choice-of-taste__tag af-class-cinnamon">Корица</div>
                                  <div className="af-class-choice-of-taste__tag af-class-nougat">Нуга</div>
                                  <div className="af-class-choice-of-taste__tag af-class-chocolate-pastry">Шоколадная выпечка</div>
                                </div>
                                <div className="w-layout-grid af-class-choice-of-taste__buttons">
                                  <div id="w-node-d4ff878f-3311-3e79-42cd-0832fefc3b2a-5d3d362c" className="w-layout-grid af-class-choice-of-taste__button">
                                    <div id="w-node-b22ac2af-0acc-fe65-d89c-8e48efdfdfd1-5d3d362c" className="af-class-choice-of-taste__button-text">Все вкусы</div><img id="w-node-a8b41fcf-a1d7-b2c3-b012-e8a63c6156f5-5d3d362c" loading="lazy" src="images/select-arrow.svg" alt="" className="af-class-choice-of-taste__button-image" />
                                  </div>
                                  <a id="w-node-_1959abd4-0c15-eda7-db6a-7509d65a77a1-5d3d362c" data-w-id="1959abd4-0c15-eda7-db6a-7509d65a77a1" href="#" className="af-class-button--primary w-button">Добавить вкусы</a>
                                </div>
                              </div>
                              <div id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8a8-5d3d362c" className="af-class-choice-of-taste__item">
                                <div id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8a9-5d3d362c" className="af-class-choice-of-taste__image-wrapper"><img width={70} sizes="(max-width: 767px) 100vw, (max-width: 991px) 38vw, (max-width: 1439px) 37vw, 34vw" src="images/coffe-image--choice-of-taste.jpg" loading="lazy" srcSet="images/coffe-image--choice-of-taste-p-500.jpeg 500w, images/coffe-image--choice-of-taste.jpg 550w" alt="" className="af-class-choice-of-taste__image" /></div>
                                <div id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8ab-5d3d362c" className="af-class-choice-of-taste__title">Colombia Sanjuanero Decaf</div>
                                <div id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8ad-5d3d362c" className="af-class-choice-of-taste__tags">
                                  <div className="af-class-choice-of-taste__tag af-class-apricot">Абрикос</div>
                                  <div className="af-class-choice-of-taste__tag af-class-raisin">Изюм</div>
                                  <div className="af-class-choice-of-taste__tag af-class-cinnamon">Корица</div>
                                  <div className="af-class-choice-of-taste__tag af-class-nougat">Нуга</div>
                                  <div className="af-class-choice-of-taste__tag af-class-chocolate-pastry">Шоколадная выпечка</div>
                                </div>
                                <div className="w-layout-grid af-class-choice-of-taste__buttons">
                                  <div id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8b9-5d3d362c" className="w-layout-grid af-class-choice-of-taste__button">
                                    <div id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8ba-5d3d362c" className="af-class-choice-of-taste__button-text">Все вкусы</div><img id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8bc-5d3d362c" loading="lazy" src="images/select-arrow.svg" alt="" />
                                  </div>
                                  <a id="w-node-facaf835-45f7-11f1-ad03-90a6e707d8bd-5d3d362c" href="#" className="af-class-button--primary w-button">Добавить вкусы</a>
                                </div>
                              </div>
                              <div id="w-node-b0973e17-379c-915a-4bf3-d20a03d278ce-5d3d362c" className="af-class-choice-of-taste__item">
                                <div id="w-node-b0973e17-379c-915a-4bf3-d20a03d278cf-5d3d362c" className="af-class-choice-of-taste__image-wrapper"><img width={70} sizes="(max-width: 767px) 100vw, (max-width: 991px) 38vw, (max-width: 1439px) 37vw, 34vw" src="images/coffe-image--choice-of-taste.jpg" loading="lazy" srcSet="images/coffe-image--choice-of-taste-p-500.jpeg 500w, images/coffe-image--choice-of-taste.jpg 550w" alt="" className="af-class-choice-of-taste__image" /></div>
                                <div id="w-node-b0973e17-379c-915a-4bf3-d20a03d278d1-5d3d362c" className="af-class-choice-of-taste__title">Colombia Sanjuanero Decaf</div>
                                <div id="w-node-b0973e17-379c-915a-4bf3-d20a03d278d3-5d3d362c" className="af-class-choice-of-taste__tags">
                                  <div className="af-class-choice-of-taste__tag af-class-apricot">Абрикос</div>
                                  <div className="af-class-choice-of-taste__tag af-class-raisin">Изюм</div>
                                  <div className="af-class-choice-of-taste__tag af-class-cinnamon">Корица</div>
                                  <div className="af-class-choice-of-taste__tag af-class-nougat">Нуга</div>
                                  <div className="af-class-choice-of-taste__tag af-class-chocolate-pastry">Шоколадная выпечка</div>
                                </div>
                                <div className="w-layout-grid af-class-choice-of-taste__buttons">
                                  <div id="w-node-b0973e17-379c-915a-4bf3-d20a03d278df-5d3d362c" className="w-layout-grid af-class-choice-of-taste__button">
                                    <div id="w-node-b0973e17-379c-915a-4bf3-d20a03d278e0-5d3d362c" className="af-class-choice-of-taste__button-text">Все вкусы</div><img id="w-node-b0973e17-379c-915a-4bf3-d20a03d278e2-5d3d362c" loading="lazy" src="images/select-arrow.svg" alt="" />
                                  </div>
                                  <a id="w-node-b0973e17-379c-915a-4bf3-d20a03d278e3-5d3d362c" href="#" className="af-class-button--primary w-button">Добавить вкусы</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                <div
                  data-w-tab="Tab 7"
                  className={`w-tab-pane${
                    activeTab == 7 ? " w--tab-active" : ""
                  }`}
                >
                  <div className="w-layout-grid af-class-reviews__content">
                    <div className="af-class-tab-title">Ваши отзывы</div>
                    <div className="w-layout-grid af-class-reviews__list">
                      {userReviews
                        .sort((a, b) =>
                          a.date < b.date ? 1 : a.date > b.date ? -1 : 0
                        )
                        .map((elem, index) => (
                          <div
                            key={index}
                            id="w-node-_7b581eeb-8d46-e167-f16d-9050a8b4309c-5d3d362c"
                            className="w-layout-grid af-class-reviews__item"
                          >
                            <div
                              id="w-node-acfaa752-de30-9d12-b25e-47e8255b0ca2-5d3d362c"
                              className="af-class-reviews__header"
                            >
                              <div className="w-layout-grid af-class-reviews__tag-and-date">
                                {elem.link ? (
                                  <a
                                    onClick={() =>
                                      navigate(`/products/${elem.link}`)
                                    }
                                    id="w-node-e107d53b-901b-be63-5cab-db5631a61712-5d3d362c"
                                    href="#"
                                    className="af-class-flavor__tag af-class-p-personal-account"
                                  >
                                    {elem.label}
                                  </a>
                                ) : (
                                  <span className="af-class-flavor__tag af-class-p-personal-account">
                                    {elem.label}
                                  </span>
                                )}
                                <div
                                  id="w-node-e107d53b-901b-be63-5cab-db5631a6170a-5d3d362c"
                                  className="af-class-user__info-text af-class-date"
                                >
                                  {new Date(elem.date).toLocaleDateString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div className="af-class-rating__stars-bock">
                                {[...Array(5)].map((i, index) => {
                                  if (index + 1 <= elem.rating) {
                                    return (
                                      <img
                                        key={index + 1}
                                        loading="lazy"
                                        src="/images/Star-blue.svg"
                                        alt=""
                                        className="af-class-user__rating-star-image"
                                      />
                                    );
                                  } else {
                                    return (
                                      <img
                                        key={index + 1}
                                        loading="lazy"
                                        src="/images/Star-gray.svg"
                                        alt=""
                                        className="af-class-user__rating-star-image"
                                      />
                                    );
                                  }
                                })}
                              </div>
                            </div>
                            <div
                              className="af-class-user__comment--p-reviews"
                            >
                              <ReviewText review={elem} />
                            </div>
                          </div>
                        ))}

                      {userOrders.length > 0 ? (
                        <a
                          onClick={() => setReviewPopupOpened(true)}
                          style={{ cursor: "pointer" }}
                          id="w-node-_2dbeaa44-6864-4bfa-c36b-17dae31c388b-5d3d362c"
                          data-w-id="2dbeaa44-6864-4bfa-c36b-17dae31c388b"
                          className="af-class-button--primary af-class-width--auto w-button"
                        >
                          Оставить отзыв
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPersonalView;

/* eslint-enable */
