/* eslint-disable */

import React, { useState, useEffect, Fragment } from 'react'
import { fetchPage, sendContactsForm } from '../fetch/WPAPI'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import { useNavigate } from 'react-router-dom'
import MaskInput from 'react-maskinput'

const ContactsView = () => {

  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()

  const [ pageContent, setPageContent ] = useState({})
  const [ activeTab, setActiveTab ] = useState(0)
  const [ contactFormOpened, setContactFormOpened ] = useState(false)

  const [ formName, setFormName ] = useState('')
  const [ formPhone, setFormPhone ] = useState('')
  const [ formEmail, setFormEmail ] = useState('')
  const [ formComments, setFormComments ] = useState('')
  const [ formUserAgreement, setFormUserAgreement ] = useState(true)

  const [ formNameValid, setFormNameValid ] = useState(null)
  const [ formPhoneValid, setFormPhoneValid ] = useState(null)
  const [ formEmailValid, setFormEmailValid ] = useState(null)
  const [ formCommentsValid, setFormCommentsValid ] = useState(null)

  const [ formIsValid, setFormIsValid ] = useState(false)
  const [ formSent, setFormSent ] = useState(false)

  useEffect(() => {
    validateForm()
  })
  
  const validateForm = () => {
    setFormIsValid(formNameValid === true
      && formPhoneValid === true
      && formEmailValid === true
      // && formCommentsValid === true
      && formUserAgreement === true)
  }

  const handleChangeInputText = (e) => {
    const name = e.target.name
    const value = e.target.value
    validateField(name, value)
    switch (name) {
      case 'name':
        setFormName(value)
        break
      case 'phone':
        setFormPhone(value)
        break
      case 'email':
        setFormEmail(value)
        break
      case 'comments':
        setFormComments(value)
        break
    }
  }

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        value.length > 1 ? setFormNameValid(true) : setFormNameValid(false)
        break
      case 'phone':
        value.match(/\+[0-9] [0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}/) ? setFormPhoneValid(true) : setFormPhoneValid(false)
        break
      case 'email':
        value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i) ? setFormEmailValid(true) : setFormEmailValid(false)
        break
      // case 'comments':
      //   value.length > 10 ? setFormCommentsValid(true) : setFormCommentsValid(false)
      //   break
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (formIsValid) {
      let utms = localStorage.getItem('utms')
      if (utms == null) {
        utms = []
      } else {
        utms = JSON.parse(utms)
      }

      sendContactsForm({ name: formName, phone: formPhone, email: formEmail, comments: formComments, utms: utms })
        .then(result => {
          console.log(result)
          setFormSent(true)
        })
    }
  }

  useEffect(() => {
    fetchPage(17)
      .then(result => {
        // console.log(result)
        setPageContent(result)

        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    document.title = pageContent?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", pageContent?.yoast_head_json?.description)
  }, [pageContent])

  return <>
      <YMaps>
            <div style={{display: contactFormOpened ? 'grid' : 'none', zIndex: '2147483647'}} className="af-class-popup__wrapper--p-contacts">
              <div className="af-class-popup">
                <div className="af-class-popup__content">
                  <div className="af-class-popup__title">Остались вопросы?</div>
                  { formSent === false ? <div className="af-class-popup__form w-form">
                    <form onSubmit={handleFormSubmit} id="wf-form-Contacts-Page" name="wf-form-Contacts-Page" method="get">
                      <div className="af-class-popup__text-field-wrapper">
                        <input value={formName} onChange={(e) => handleChangeInputText(e)} onBlur={(e) => validateField(e.target.name, e.target.value)} type="text" className={`af-class-popup__text-field w-input${formNameValid === false ? ' user-input-invalid' : ''}`} maxLength={256} name="name" placeholder="Имя" id="name" />
                        <MaskInput alwaysShowMask mask={'+0 000 000 00 00'} size={20} showMask maskChar="_" value={formPhone} onChange={(e) => handleChangeInputText(e)} onBlur={(e) => validateField(e.target.name, e.target.value)} type="text" className={`af-class-popup__text-field w-input${formPhoneValid === false ? ' user-input-invalid' : ''}`} maxLength={256} name="phone" data-name="phone" placeholder="Телефон" id="phone" />
                        <input value={formEmail} onChange={(e) => handleChangeInputText(e)} onBlur={(e) => validateField(e.target.name, e.target.value)} type="email" className={`af-class-popup__text-field w-input${formEmailValid === false ? ' user-input-invalid' : ''}`} maxLength={256} name="email" data-name="Email" placeholder="Электронная почта" id="email" required />
                        <textarea value={formComments} onChange={(e) => handleChangeInputText(e)} onBlur={(e) => validateField(e.target.name, e.target.value)} placeholder="Комментарий" maxLength={5000} name="comments" className={`af-class-popup__text-area w-input${formCommentsValid === false ? ' user-input-invalid' : ''}`} />
                      </div>
                      <label className="w-checkbox af-class-popup__checkbox-field" onClick={() => setFormUserAgreement(!formUserAgreement)}>
                        <div className={`w-checkbox-input w-checkbox-input--inputType-custom af-class-popup__checkbox${formUserAgreement === false ? '' : ' w--redirected-checked'}`} />
                        <span className="af-class-popup__checkbox-label w-form-label" htmlFor="checkbox">Я принимаю условия <a href="#" onClick={() => navigate(`/user-agreement`)} style={{ color: '#1d71b8', textDecoration: 'underline' }}>Политики конфиденциальности</a></span>
                      </label>
                      <input type="submit" value="Отправить" className={`af-class-popup__button w-button${formIsValid === false ? ' button-disabled' : ''}`} />
                    </form>
                  </div> : <div>Спасибо, ваш запрос отправлен!</div> }
                </div>
                <div onClick={() => setContactFormOpened(false)} className="af-class-popup__close-icon-wrapper">
                  <img src="images/icon--close-popup.svg" loading="lazy" alt="" className="af-class-popup__close-icon" />
                </div>
              </div>
            </div>

            <div className="af-class-section af-class-contacts af-class-wf-section">
              <div className="af-class-container w-container">
                <h1 className="af-class-title-main-p_contacts af-class-an-load--2">Контакты</h1>
                
                { isLoading === true && <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div> }

                <div data-current="Tab 1" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs-p_contacts w-tabs">
                  <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401047-cc3d3633" className="af-class-tabs_menu-p_contacts af-class-an-load--2 w-tab-menu">
                    { pageContent?.acf?.contactss.map((elem, index) => <a key={index} onClick={() => setActiveTab(index)} className={`af-class-tab-p_faq w-inline-block w-tab-link${activeTab == index ? ' w--current' : ''}`}>
                      <div className="af-class-text-block-4">{elem.region}</div>
                    </a>)}
                  </div>
                  <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401051-cc3d3633" className="af-class-tabs-content-2 w-tab-content">
                    { pageContent?.acf?.contactss.map((elem, index) => <div key={index} className={`w-tab-pane${activeTab == index ? ' w--tab-active' : ''}`}>
                      <div className="w-layout-grid af-class-grid-3">
                        <div id="w-node-a6392ae2-8cf4-3846-9f6f-cacdbbd9d057-cc3d3633" className="w-layout-grid af-class-grid-2 af-class-an-load--2">
                          <div className="contacts-from-admin-part" dangerouslySetInnerHTML={{__html: elem.contacti}} />
                          {/* <Fragment>{elem.contacti}</Fragment> */}

                          <div className="af-class-contacts_info-block">
                            <div className="af-class-text-contacts">Если у вас есть вопросы или вы хотите предложить сотрудничество воспользуйтесь формой <span className="af-class-text_link-contacts" data-w-id="4ed8c54f-52c1-2648-84c3-b26dc7606001" onClick={() => setContactFormOpened(true)}>обратной связи</span>
                            </div>
                          </div>

                          {elem.region == 'Москва' && <div className="af-class-contacts_info-block af-class-link">
                            <a className="af-class-contacts-info__link" href="#" onClick={() => setContactFormOpened(true)}><span className="af-class-text_link-contacts">Стать дистрибьютером</span></a>
                          </div> }
                        </div>

                        <div id="w-node-_3c8fd7cf-1d31-4ae2-3559-6706cee76962-cc3d3633" className="af-class-map_wrapper-p_contacts af-class-an-load--3">
                          <div className="af-class-map_container-p_contacts">
                            <div bind="954214d6-3463-1e08-512e-2f88cdb38463" className="af-class-html-embed w-embed w-script">
                            <Map defaultState={{center: JSON.parse(elem.coordinates), zoom: 9}} width="100%" height="100%">
                              <Placemark defaultGeometry={JSON.parse(elem.coordinates)} />
                            </Map>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)}

                    {/* <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                      <div className="w-layout-grid af-class-grid-3">
                        <div id="w-node-a6392ae2-8cf4-3846-9f6f-cacdbbd9d057-cc3d3633" className="w-layout-grid af-class-grid-2 af-class-an-load--2">
                          <div className="af-class-contacts_info-block">
                            <div className="af-class-title-contacts">Для звонков по России</div>
                            <a href="tel:+88007770414" className="af-class-phone-contacts">8 800 777-04-14</a>
                            <a href="tel:+88007770414" className="af-class-whatsapp-contacts">Написать в What’s App</a>
                          </div>
                          <div className="af-class-contacts_info-block">
                            <div className="af-class-title-contacts">Для звонков по Москве</div>
                            <a href="tel:+84951081144" className="af-class-phone-contacts">8 495 108-11-44</a>
                          </div>
                          <div className="af-class-contacts_info-block">
                            <div className="af-class-title-contacts">Почта для общих вопросов</div>
                            <a href="mailto:info@rockets.coffee" className="af-class-phone-contacts">info@rockets.coffee</a>
                          </div>
                          <div className="af-class-contacts_info-block">
                            <div className="af-class-text-contacts">Если у вас есть вопросы или вы хотите предложить сотрудничество воспользуйтесь формой <span data-w-id="4ed8c54f-52c1-2648-84c3-b26dc7606001" className="af-class-text_link-contacts">обратной связи</span></div>
                          </div>
                          <div className="af-class-contacts_info-block af-class-link">
                            <a href="#" className="af-class-contacts-info__link">Стать дистрибьютером</a>
                            <div className="af-class-contacts-info__link-underline" />
                          </div>
                        </div>
                        <div id="w-node-_3c8fd7cf-1d31-4ae2-3559-6706cee76962-cc3d3633" className="af-class-map_wrapper-p_contacts af-class-an-load--3">
                          <div className="af-class-map_container-p_contacts">
                            <div bind="954214d6-3463-1e08-512e-2f88cdb38463" className="af-class-html-embed w-embed w-script">
                            {/* <Map defaultState={{center: [55.729366, 37.626241], zoom: 9}} width="100%" height="100%">
                              <Placemark defaultGeometry={[55.729366, 37.626241]} />
                            </Map> */}
                            { /*</div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
      </YMaps>
      </>
}

export default ContactsView

/* eslint-enable */