import React, { useState, useEffect } from 'react'
import { fetchPage } from '../fetch/WPAPI'

const ShippingAndPaymentView = () => {

  const [ isLoading, setIsLoading ] = useState(true)

  const [ pageContent, setPageContent ] = useState({})
  const [ activeQuestion, setActiveQuestion ] = useState(null)

  const [ categories, setCategories ] = useState([])
  const [ activeTab, setActiveTab ] = useState(0)

  useEffect(() => {
    fetchPage(14)
      .then(result => {
        console.log(result)
        setPageContent(result)

        let categories = result?.acf?.deliveryandpayment.map((elem => elem.question_cat)).filter((v, i, a) => a.indexOf(v) === i)
        setCategories(categories)
        setActiveTab(categories.length > 0 ? categories[0] : 0)

        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    document.title = pageContent?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", pageContent?.yoast_head_json?.description)
  }, [pageContent])

  return <div className="af-class-section af-class-faq af-class-an-load--2 af-class-wf-section">
    <div className="af-class-container w-container">
      <h1 className="af-class-title-main-p_faq">Доставка и оплата</h1>
      {/* <div dangerouslySetInnerHTML={{__html: pageContent?.content?.rendered}} /> */}

      { isLoading === true && <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div> }

      <div data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs-2 w-tabs">
        <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401047-f93d3638" className="af-class-tabs-menu-2 w-tab-menu">
          { categories.map((elem, index) => <a onClick={() => setActiveTab(elem)} key={index} className={`af-class-tab-p_faq w-inline-block w-tab-link${activeTab == elem ? ' w--current' : ''}`}>
            <div className="af-class-text-block-4">{elem}</div>
          </a>)}
        </div>

        <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401051-f93d3638" className="w-tab-content">

          { categories.map((elem, catIndex) => <div key={elem} className={`w-tab-pane${activeTab == elem ? ' w--tab-active' : ''}`}>
            <div className="af-class-faq-wrapper">
              { pageContent?.acf?.deliveryandpayment.map((elem, index) => activeTab != elem.question_cat ? '' : <div key={index} className="af-class-faq-item" onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}>
                <div className="af-class-faq-title">{elem.вопрос}</div>
                
                <img src="/images/faq-icon.svg" loading="lazy" style={activeQuestion === index ? {} : {WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />

                <div style={{height: activeQuestion === index ? 'auto' : '0px'}} className="af-class-rich_text-wrapper-faq">
                  <div className="af-class-rich_text-faq w-richtext" style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: elem.ответ}} />
                </div>
              </div> ) }
            </div>
          </div> ) }

        </div>
      </div>

    </div>
  </div>
}

export default ShippingAndPaymentView
