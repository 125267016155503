import { useState, useRef } from "react";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const ProductImage = ({ product }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const mainRef = useRef(null);

    const handleThumbnailClick = (id) => {
        setCurrentSlide(id);
        mainRef.current.go(id);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px'}}>
            <div className="af-class-product__img_wrap" style={{overflow: 'hidden'}}>
                <div style={{ gridColumn: "1 / -1" }}>
                    <Splide
                        ref={mainRef}
                        onMove={(splide, index, prev, dest) => setCurrentSlide(index)}
                        className="af-class-splide af-class-splide__country-page"
                        hasTrack={false}
                        options={{
                            perPage: 1,
                            perMove: 1,
                            pagination: true,
                        }}
                        >
                        <SplideTrack>
                            {product.images?.map((elem, index) => (
                                <SplideSlide
                                    className="af-class-splide__slide"
                                    key={elem.id}
                                >
                                    <img
                                        src={elem.src}
                                        alt=""
                                        className="af-class-product__preview_img af-class-an-image--scroll-into-view"
                                    />
                                </SplideSlide>
                            ))}
                        </SplideTrack>
                    </Splide>
                </div>

                {/* <img
                    src={
                    product.images
                        ? product.images[0]?.src
                        : ""
                    }
                    loading="lazy"
                    width={508}
                    alt=""
                    className="af-class-product__preview_img af-class-an-image--scroll-into-view"
                /> */}
            </div>
            <div style={{display: 'flex', columnGap: '10px'}}>
                {product.images?.map((elem, index) => (
                    <div
                        key={index}
                        alt=""
                        style={{width: '80px', height: '80px', borderRadius: '8px', backgroundColor: '#fff', cursor: 'pointer', border: index === currentSlide ? '1px solid rgba(0, 0, 0, 0.20)' : 'none', backgroundImage: 'url(' + elem.src + ')', backgroundSize: 'cover', backgroundPosition: 'center'}}
                        onClick={() => handleThumbnailClick(index)}
                    />
                ))}
            </div>
        </div>
    );
}

export default ProductImage;