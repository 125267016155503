export const fetchWPItems = (url, tries = 1) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        })
        if (!response.ok) {
            console.log('Retrying...')
            if (tries >= 5) {
                return false
            } else {
                tries = tries + 1
                return fetchWPItems(url, tries)
            }
        }
        return await response.json()
    }
    return callback()
}

export const getRequestBearer = (url, token, tries = 1) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
        if (!response.ok) {
            console.log('Retrying...')
            if (tries >= 5) {
                return false
            } else {
                tries = tries + 1
                return getRequestBearer(url, token, tries)
            }
        }
        return await response.json()
    }
    return callback()
}

export const postRequestBearer = (url, data, token, tries = 1) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
        if (!response.ok) {
            console.log('Retrying...')
            if (tries >= 5) {
                return false
            } else {
                tries = tries + 1
                return postRequestBearer(url, data, token, tries)
            }
        }
        return await response.json()
    }
    return callback()
}

export const postRequestBearerFile = (url, data, token, headers = {}, tries = 1) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: { 'Authorization': `Bearer ${token}`, ...headers },
        })
        if (!response.ok) {
            console.log('Retrying...')
            if (tries >= 5) {
                return false
            } else {
                tries = tries + 1
                return postRequestBearerFile(url, data, token, headers, tries)
            }
        }
        return await response.json()
    }
    return callback()
}

export const putRequestAuth = (url, data, tries = 1) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'PUT',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        if (!response.ok) {
            console.log('Retrying...')
            if (tries >= 5) {
                return false
            } else {
                tries = tries + 1
                return putRequestAuth(url, data, tries)
            }
        }
        return await response.json()
    }
    return callback()
}

export const postRequestAuth = (url, data, tries = 1) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        if (!response.ok) {
            console.log('Retrying...')
            if (tries >= 5) {
                return false
            } else {
                tries = tries + 1
                return postRequestAuth(url, data, tries)
            }
        }
        return await response.json()
    }
    return callback()
}

export const getRequestSingle = (url, data) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        })
        return await response.json()
    }
    return callback()
}

export const getRequestSingleBearer = (url, token) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
        return await response.json()
    }
    return callback()
}

export const postRequestSingle = (url, data) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        return await response.json()
    }
    return callback()
}

export const postRequestSingleCookies = (url, data) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        return await response.json()
    }
    return callback()
}

export const postRequestSingleBearer = (url, data, token) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(data),
        })
        return await response.json()
    }
    return callback()
}

export const getRequestSingleBearerStoreNonce = (url, token, nonce) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'Nonce': `${nonce}` },
        })
        return await response.json()
    }
    return callback()
}

export const postRequestSingleBearerStoreNonce = (url, data, token, nonce) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'Nonce': `${nonce}` },
            body: JSON.stringify(data),
        })
        return await response.json()
    }
    return callback()
}

export const postRequestSingleBearerStoreNoncePromise = (url, data, token, nonce) => {
    return fetch(url, {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'Nonce': `${nonce}` },
        body: JSON.stringify(data),
    })
}

export const putRequestSingleBearerStoreNonce = (url, token, nonce) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'PUT',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'Nonce': `${nonce}` },
        })
        return await response.json()
    }
    return callback()
}

export const deleteRequestSingleBearerStoreNonce = (url, token, nonce) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'DELETE',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'Nonce': `${nonce}` },
        })
        return await response.json()
    }
    return callback()
}

export const createInvoiceRequest = (url, tries = 1) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
        if (!response.ok) {
            console.log('Retrying...')
            if (tries >= 5) {
                return false
            } else {
                tries = tries + 1
                return fetchWPItems(url, tries)
            }
        }
        return await response.json()
    }
    return callback()
}

export const fetchAddressSuggestions = (url, data) => {
    let callback = async () => {
        let response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Token f0c3ff3bfc27c1a86c85a6e30b23bae310583b51' },
            body: JSON.stringify(data),
        })
        return await response.json()
    }
    return callback()
}
