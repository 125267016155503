import { useState, useEffect, useContext } from "react";
import {
  createProductReview,
} from "../../fetch/WPAPI";
import { MainContext } from "../../contexts/MainContext";
import { tasteTabs, tasteGroups, tastes } from "../../data/tastes";
import Taste from "./Taste";
import './style.css';

const ProductReviewForm = ({ item, reviews, handleReviews, reviewPopupOpened, setReviewPopupOpened }) => {
  const mainContext = useContext(MainContext);

  const [reviewFormText, setReviewFormText] = useState("");
  const [proposedRating, setProposedRating] = useState(0);
  const [selectedRating, setSelectedRating] = useState(0);
  const [tasteView, setTasteView] = useState(false);
  const [tasteTab, setTasteTab] = useState(1);
  const [selectedTastes, setSelectedTastes] = useState([]);
  const [reviewFormIsValid, setReviewFormIsValid] = useState(false);

  useEffect(() => {
    if (reviewFormText.length > 10 && selectedRating > 0) {
      setReviewFormIsValid(true);
    }
  }, [reviewFormText, selectedRating]);

  const handleReviewSubmit = (e) => {
    e.preventDefault();

    if (reviewFormIsValid) {
      setProposedRating(0);
      setSelectedRating(0);

      let data = {
        product_id: item.id,
        reviewer: mainContext.userData.username,
        reviewer_email: mainContext.userData.email,
        review: JSON.stringify({
          tastes: selectedTastes,
          text: reviewFormText,
        }),
        rating: selectedRating,
      };

      createProductReview(data).then((result) => {
        console.log(result);
        let newReviews = [result, ...reviews];
        handleReviews(newReviews);
        // setReviews((prevState) => [result, ...prevState])

        // setUserReviews((prevState) => [...prevState, {
        //   id: result.id,
        //   date: result.date,
        //   label: reviewCategories.find(cat => result.kategoriya_otzivov.indexOf(cat.id) !== -1)?.name,
        //   link: null,
        //   rating: result.acf.оценка,
        //   text: result.content.rendered,
        // }])
      });

      setReviewPopupOpened(false);
    }
  };

  return (
    <div
      style={{
        display: reviewPopupOpened === true ? "flex" : "none",
        zIndex: "2147483647",
      }}
      bind="67a8d3a2-971f-876c-e99f-4a9b0598c186"
      className={`af-class-popup--p-reviews ${tasteView ? 'taste-popup-root' : ''}`}
    >
      <div
        className={`af-class-popup--p-reviews__content ${tasteView ? 'taste-popup-wrapper' : ''}`}
      >
        
        <div style={{display: tasteView ? 'block' : 'none', width: '100%'}}>
          <div className="af-class-popup--p-reviews__title">Выберите вкусы</div>
          <div className="taste-popup-tabs" style={{ display: 'flex', fontFamily: 'Gotham', fontWeight: 500, marginBottom: '30px', borderBottom: '1px #bcbcbc solid' }}>
            {tasteTabs.map(elem => <div key={elem.id} style={{ color: tasteTab == elem.id ? '#1D71B8' : '#999', borderBottom: tasteTab == elem.id ? '1px #1D71B8 solid' : '1px #bcbcbc solid', cursor: 'pointer', marginBottom: '-1px', paddingBottom: '10px' }} onClick={() => setTasteTab(elem.id)}>{elem.name}</div>)}
          </div>
          <div className="taste-groups-wrapper">
            {tasteGroups.filter(elem => elem.tabId == tasteTab).map(elem => <div key={elem.id} className="taste-group-wrapper">
              <div style={{marginBottom: '20px', fontFamily: 'Gotham', fontWeight: 500}}>{elem.name}</div>
              <div className="taste-group">
                {tastes
                  .filter(taste => taste.groupId == elem.id)
                  .map(taste => <Taste
                    key={taste.id}
                    name={taste.name}
                    color={taste.color}
                    addable={true}
                    onClick={() => setSelectedTastes([...selectedTastes, taste.id])}
                  />)}
              </div>
            </div>)}
          </div>
          <div style={{display: 'flex', flexDirection: 'column', rowGap: '22px', marginTop: '30px', paddingTop: '30px', borderTop: '1px #bcbcbc solid'}}>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
              <div style={{color: '#000', fontFamily: 'Gotham', fontWeight: 500}}>Ваш выбор</div>
              <div style={{color: '#1D71B8', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setSelectedTastes([])}>Сбросить</div>
            </div>
            {tasteTabs.map(tab => <div key={tab.id} style={{display: 'flex', columnGap: '30px', alignItems: 'center'}}>
              <div style={{color: '#bcbcbc', fontFamily: 'Gotham', fontWeight: 500, minWidth: '100px'}}>{tab.name}:</div>
              <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
                {tastes
                  .filter(taste => selectedTastes.includes(taste.id) && tasteGroups.filter(group => group.tabId == tab.id).map(group => group.id).includes(taste.groupId))
                  .map(taste => <Taste
                    key={taste.id}
                    name={taste.name}
                    color={taste.color}
                    removable={true}
                    onClick={() => setSelectedTastes(selectedTastes.filter(id => id != taste.id))}
                  />)}
              </div>
            </div>)}
          </div>
          <input
            type="button"
            value="Сохранить изменения"
            className={`af-class-popup--p-reviews__submit-button w-button`}
            onClick={() => setTasteView(false)}
            style={{marginTop: '45px'}}
          />
        </div>
        
        <div style={{display: tasteView ? 'none' : 'block', width: '100%'}}>
          <div className="af-class-popup--p-reviews__title">Напишите отзыв</div>

          {item?.categories?.find((elem) => elem.id == 17) && <input
            type="button"
            value="Выберите вкусы"
            className={`af-class-popup--p-reviews__submit-button w-button`}
            style={{marginBottom: '30px'}}
            onClick={() => setTasteView(true)}
          />}

          {selectedTastes.length > 0 && <div style={{display: 'flex', maxWidth: '500px', gap: '10px', flexWrap: 'wrap', marginBottom: '30px'}}>
            {tastes
              .filter(taste => selectedTastes.includes(taste.id))
              .map(taste => <Taste
                key={taste.id}
                name={taste.name}
                color={taste.color}
              />)}
          </div>}

          <div className="w-layout-grid af-class-popup--p-reviews__form-wrapper">
            <div className="af-class-popup--p-reviews__form-block w-form">
              <form
                onSubmit={handleReviewSubmit}
                action="#"
                id="email-form"
                className="af-class-popup--p-reviews__form"
              >
                <textarea
                  value={reviewFormText}
                  onChange={(e) => setReviewFormText(e.target.value)}
                  placeholder="Комментарий"
                  maxLength={5000}
                  className="af-class-popup--p-reviews__text-area w-input"
                />
                <div className="af-class-popup--p-reviews__raiting-wrapper">
                  <div className="af-class-popup--p-reviews__raiting-title">
                    Ваша оценка
                  </div>
                  <div
                    onMouseLeave={() => setProposedRating(0)}
                    className="w-layout-grid af-class-popup--p-reviews__raiting-stars"
                    style={{ cursor: "pointer" }}
                  >
                    {[1, 2, 3, 4, 5].map(i => <div
                      key={i}
                      onClick={() => setSelectedRating(i)}
                      onMouseOver={() => setProposedRating(i)}
                      data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf350c"
                      className="af-class-review-mark-wrapper af-class-_1"
                    >
                      <img
                        style={{
                          display:
                            proposedRating < i && selectedRating < i
                              ? "block"
                              : "none",
                        }}
                        src="/images/icon__review-star.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-review-star--unchecked"
                      />
                      <img
                        style={{
                          display:
                            proposedRating < i && selectedRating < i
                              ? "none"
                              : "block",
                        }}
                        src="/images/review-star--checked.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-review-star--checked af-class-_1"
                      />
                    </div>)}
                  </div>
                </div>
                <input
                  type="submit"
                  value="Отправить"
                  className={`af-class-popup--p-reviews__submit-button w-button${
                    reviewFormIsValid === false ? " button-disabled" : ""
                  }`}
                />
              </form>
            </div>
          </div>
        </div>

        <div
          onClick={() => setReviewPopupOpened(false)}
          data-w-id="67a8d3a2-971f-876c-e99f-4a9b0598c1ac"
          className="af-class-popup--p-reviews__close-icon-wrapper"
        >
          <img src="/images/icon--close-popup.svg" loading="lazy" alt="" />
        </div>
      </div>
    </div>
  );
}

export default ProductReviewForm;