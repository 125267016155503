import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '../../hooks/useToken.js';
import { MainContext } from "../../contexts/MainContext";
import { createCustomReview } from '../../fetch/WPAPI';
import { tastes } from "../../data/tastes";
import Taste from "../ProductPage/Taste";

const Review = ({ review, userMeta, user, allResponses, productLink = true }) => {
    const mainContext = useContext(MainContext);
    const navigate = useNavigate();
    const { token, setToken, nonce, setNonce } = useToken();

    const [selectedTastes, setSelectedTastes] = useState([]);
    const [response, setResponse] = useState(null);
    const [showResponseForm, setShowResponseForm] = useState(false);
    const [responseText, setResponseText] = useState('');
    const [responseUser, setResponseUser] = useState(null);

    const [reviewText, setReviewText] = useState('');
    useEffect(() => {
        try {
            let reviewText = review.text?.replaceAll('<p>', '')?.replaceAll('</p>', '');
            let reviewParsed = JSON.parse(reviewText);
            setReviewText(`<p>${reviewParsed.text}</p>`);
            setSelectedTastes(reviewParsed.tastes);
        } catch (e) {
            setReviewText(review.text);
        }
    }, [review?.text]);

    useEffect(() => {
        setResponseUser(mainContext.userData);
    }, [mainContext.userData]);

    useEffect(() => {
        setResponse(allResponses?.data?.find(response => response.response_to === review.id));
    }, [allResponses]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            acf: { response_to: review.id },
            kategoriya_otzivov: [ 521 ],
            author: responseUser.id,
            status: 'publish',
            content: responseText,
        };

        createCustomReview(data, token.token)
            .then(result => {
                setResponse({
                    id: result.id,
                    response_to: review.id,
                    date: result.date,
                    label: null,
                    link: null,
                    rating: null,
                    text: result.content.rendered,
                    category: 521,
                });
        })
    };

    return (
        <div className="af-class-comment__block">
            <div className="af-class-comment__user_block--p-reviews">
                <div className="af-class-user__info-block">
                    {/* { review?.reviewer_avatar_urls ? <img src={review?.reviewer_avatar_urls[48]} loading="lazy" width={50} alt="" className="af-class-user__avatar--p-reviews" /> : (review.user_id && user?.avatar_urls) && <img src={user?.avatar_urls[48]} loading="lazy" width={50} alt="" className="af-class-user__avatar--p-reviews" /> } */}

                    <img
                        src={userMeta?.avatar || user?.acf?.avatar || 'https://secure.gravatar.com/avatar/629a230556682567305e5b23e4aaea4d?s=96&d=mm&r=g'}
                        loading="lazy"
                        width={50}
                        alt=""
                        className="af-class-user__avatar--p-reviews"
                    />

                    <div className="af-class-user__info-text__block--p-reviews">
                        <div className="af-class-user__info-text">
                            <span className="af-class-user__name">
                                {/* {(userMeta?.billing_first_name?.length > 0 && userMeta?.billing_last_name?.length > 0) ? `${userMeta?.billing_first_name[0]} ${userMeta?.billing_last_name[0]}` : review.reviewer || user?.name} */}
                                {(userMeta?.billing_first_name?.length > 0) ? `${userMeta?.billing_first_name[0]}` : review.reviewer || user?.name}
                            </span>
                            {/* {user?.acf?.адреса?.length > 0 ? <span style={{fontWeight: 400, marginLeft: '10px', fontFamily: "'Gotham book', sans-serif"}}>{JSON.parse(user?.acf?.адреса).find(address => address.default == true)?.full?.split(',')[0]}</span> : <></> } */}
                            {/* {userMeta?.bonus_level ? <span style={{fontWeight: 400, marginLeft: '10px', fontFamily: "'Gotham book', sans-serif"}}>({userMeta?.bonus_level})</span> : <></> } */}
                        </div>
                        <div className="af-class-rating__stars-bock" id="w-node-_3bfafad8-6829-caf1-e6f0-17f84bf40eb5-ca3d3642">
                            { [...Array(5)].map((i, index) => {
                                if (index + 1 <= review.rating) {
                                return <img key={index + 1} loading="lazy" src="/images/Star-blue.svg" alt="" className="af-class-user__rating-star-image" />
                                } else {
                                return <img key={index + 1} loading="lazy" src="/images/Star-gray.svg" alt="" className="af-class-user__rating-star-image" />
                                }
                            }) }
                        </div>
                        <div className="af-class-user__info-text af-class-date">{new Date(review.date).toLocaleDateString('ru-RU')}</div>
                    </div>
                </div>
            </div>
            {productLink === true && <div className="af-class-user__flavor-block--p-reviews">
                { review.link ? <div className="af-class-flavors__wrap" style={{cursor: 'pointer'}} onClick={() => navigate(`/products/${review.link}`)}>
                <a className="af-class-flavor__tag">{review.label}</a>
                </div> : <div className="af-class-flavors__wrap">
                <a className="af-class-flavor__tag">{review.label}</a>
                </div> }
            </div>}
            {/* <div className="af-class-user__flavor-block--p-reviews">
                <div className="af-class-flavor-lable--p-reviews">Профиль вкуса</div>
                <div className="af-class-flavors__wrap">
                <a href="#" className="af-class-flavor__tag">Яблоко</a>
                <a href="#" className="af-class-flavor__tag">Малина</a>
                </div>
            </div> */}
            <div className="af-class-user__comment--p-reviews">
                {selectedTastes.length > 0 && <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px'}}>
                    {tastes
                        .filter(taste => selectedTastes.includes(taste.id))
                        .map(taste => <Taste
                            key={taste.id}
                            name={taste.name}
                            color={taste.color}
                        />)}
                </div>}

                <div dangerouslySetInnerHTML={{ __html: reviewText }} />

                {response?.text && <div style={{background: '#f5f5f5', padding: '25px 30px 5px 30px', borderRadius: '15px'}}>
                    <div style={{display: 'flex', columnGap: '10px', alignItems: 'center', marginBottom: '15px'}}>
                        <div style={{background: '#fff', width: '30px', height: '30px', borderRadius: '30px', backgroundImage: 'url("/images/reply-logo-2.png")', backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
                        <div style={{fontFamily: 'Gotham, sans-serif', fontWeight: '500', color: '#000'}}>rockets.coffee</div>
                        <div className="af-class-user__info-text af-class-date">{new Date(response.date).toLocaleDateString('ru-RU')}</div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: response.text }} />
                </div>}
                
                {(['administrator', 'shop_manager'].includes(mainContext.userData?.role) && !response?.text) && <>
                    {showResponseForm === false && <div style={{display: 'flex', cursor: 'pointer', columnGap: '10px', alignItems: 'center'}} onClick={() => setShowResponseForm(true)}>
                        <div style={{width: '33px', height: '33px', borderRadius: '33px', backgroundColor: '#f5f5f5', backgroundImage: 'url("/images/reply-outline.svg")', backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
                        <div style={{color: '#1D71B8'}}>Ответить</div>
                    </div>}
                    {showResponseForm === true && <form onSubmit={handleSubmit} style={{position: 'relative'}}>
                        <input
                            type="text"
                            value={responseText}
                            onChange={(e) => setResponseText(e.target.value)}
                            style={{padding: '10px 45px 10px 15px', width: '100%', borderRadius: '10px', border: '1px solid #1D71B8'}}
                        />
                        <input type="submit" value=" " style={{width: '25px', height: '25px', borderRadius: '25px', backgroundColor: '#1D71B8', border: 'none', cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', marginTop: '-13px', backgroundImage: 'url("/images/reply-outline-white.svg")', backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} />
                    </form>}
                </>}
            </div>
        </div>
    );
}

export default Review;