import { useState, Fragment } from 'react';
import FilterField from './Filter/FilterField';
import FilterFieldTooltip from './Filter/FilterFieldTooltip';
import FilterFieldPellet from './Filter/FilterFieldPellet';

export default function CatalogFiltersMobile({ filters, categoryFilters, toggleFilter, apply, clear, isFiltersOpen, toggle, displayGlobal = true }) {
  const [ mobileFiltersHidden, setMobileFiltersHidden ] = useState([]);

  const handleMobileFiltersHidden = (id) => {
    if (mobileFiltersHidden.includes(id)) {
      setMobileFiltersHidden(mobileFiltersHidden.filter(elem => elem != id));
    } else {
      setMobileFiltersHidden([...mobileFiltersHidden, id]);
    }
  }

  const handleApply = () => {
    apply();
    toggle();
  }

  if (displayGlobal === false) return <></>;

  return <div className="af-class-filter-mobile" style={{ display: isFiltersOpen ? 'block' : 'none', paddingBottom: '130px' }}>
    <div className="af-class-title-filter-wrapper">
      <div className="af-class-title_and_counter-wrapper-filter">
        <div>Фильтры</div>
        {/* <div className="af-class-counter-button-filter">2</div> */}
      </div>
      <img src="/images/icon-close-filter_1icon-close-filter.png" loading="lazy" width={30} onClick={toggle} alt="" className="af-class-filter__close-button" />
    </div>

    <div style={{ borderTop: '1px #d4d4d4 solid', paddingTop: '30px' }}>
      {categoryFilters.map((filter, index) => {
        return <Fragment key={index}>
          <div style={{ borderBottom: '1px #ddd solid', paddingBottom: '30px', marginBottom: '30px' }}>
            <div style={{ marginBottom: '22px', fontFamily: 'Gotham, sans-serif', fontSize: '14px', lineHeight: '1.2', fontWeight: 700, textTransform: 'uppercase' }}>{filter.name} {filters.filter(f => f.id == filter.id).length > 0 && <span style={{ color: '#1D71B8' }}>({filters.filter(f => f.id == filter.id).length})</span>}</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {filter.options.map((option, index) => <FilterFieldPellet filters={filters} filter={{ id: filter.id, option: option }} toggleFilter={toggleFilter} />)}
            </div>
          </div>
        </Fragment>
      })}
    </div>

    {/* {categoryFilters.map((filter, index) => <div className="af-class-block-filter-p_catalog-mobile">
      <div className="af-class-title_and_icon-filter-mobile-wrapper" onClick={() => handleMobileFiltersHidden(filter.id)}>
        <div className="af-class-title_and_icon-filter-mobile">
          <div className="af-class-title-black-filter-p_catalog--mobile">{filter.name}</div>
          <img src="/images/arrow-down.svg" style={{transform: mobileFiltersHidden.includes(filter.id) ? 'rotate(0deg)' : 'rotate(180deg)'}} loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
        </div>
      </div>
      <div className="af-class-filter--mobile__form-wrapper" style={{display: mobileFiltersHidden.includes(filter.id) ? 'none' : 'block'}}>
        <div className="af-class-form-filter w-form">
          <div className="af-class-form-two_columns">
            {filter.options.map((option, index) => <FilterField filters={filters} filter={{ id: filter.id, option: option }} toggleFilter={toggleFilter} />)}
          </div>
        </div>
      </div>
    </div>)} */}

    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      padding: '15px',
      width: '100%',
      background: '#fff',
      boxShadow: '0px 0px 14px 0px #00000012',
    }}>
      <div className="w-layout-grid af-class-filter_and_show-buttons" style={{ marginTop: 0, marginBottom: 0 }}>
        <div className="af-class-button-outlined-text_grey">
          <div onClick={clear} style={{ color: `${filters.length !== 0  ? '#1d71b8' : '#bcbcbc'}` }} className="af-class-button-reset-filter">Сбросить все фильтры</div>
        </div>
        <div className="af-class-button-filled" onClick={handleApply}>
          <div className="af-class-text-button-filter">Применить</div>
        </div>
      </div>
    </div>
  </div>;
}