import React, { useState, useEffect, useContext, useRef } from 'react'
import CartFieldText from './CartFieldText'
import { AddressSuggestions } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import useCart from '../../hooks/useCart.js'
import { fetchAddressSuggestions } from '../../fetch/fetchWPItems'

const AddressForm = ({ prepopulated, cityFiasId, fromCabinet, showAddressName, getFormData }) => {
  const { customerData, setCustomerData } = useCart()

  const [ formAddressName, setFormAddressName ] = useState('')

  const [ fullAddress, setFullAddress ] = useState('')
  const [ fullAddressValid, setFullAddressValid ] = useState(null)
  const [ fullAddressFilled, setFullAddressFilled ] = useState(false)

  const [ formAddressEntrance, setFormAddressEntrance ] = useState('')
  const [ formAddressEntranceValid, setFormAddressEntranceValid ] = useState(null)

  const [ formAddressFloor, setFormAddressFloor ] = useState('')
  const [ formAddressFloorValid, setFormAddressFloorValid ] = useState(null)

  const [ formAddressFlat, setFormAddressFlat ] = useState('')
  const [ formAddressFlatValid, setFormAddressFlatValid ] = useState(null)

  const [ formAddressHomephone, setFormAddressHomephone ] = useState('')
  const [ formAddressHomephoneValid, setFormAddressHomephoneValid ] = useState(null)

  const [ derivedCity, setDerivedCity ] = useState(null)
  const [ derivedCountry, setDerivedCountry ] = useState(null)
  const [ derivedCityPostalCode, setDerivedCityPostalCode ] = useState(null)
  const [ derivedCityFiasId, setDerivedCityFiasId ] = useState(null)
  const [ derivedCityKladrId, setDerivedCityKladrId ] = useState(null)

  const handleFullAddressFilled = (value) => {
    if (value && value.length) {
      setFullAddressFilled(true)
    } else {
      setFullAddressFilled(false)
    }
  }

  const suggestionsRef = useRef(null)

  // This is needed to derive the city from the given address
  const prefetchAddressSuggestions = (address) => {
    suggestionsRef?.current?.setInputValue(address)
    suggestionsRef?.current?.setState({query: address})
    fetchAddressSuggestions(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`, {query: address, count: 10})
      .then(result => {
        suggestionsRef?.current?.setState({ suggestions: result.suggestions })
        handleFullAddress(result?.suggestions[0])
      })
  }

  useEffect(() => {
    if (prepopulated !== false && prepopulated !== null) {
      if (prepopulated?.name) {
        setFormAddressName(prepopulated.name)
      }

      // setFullAddress(prepopulated.full)
      prefetchAddressSuggestions(prepopulated.full)
      if (prepopulated?.full) {
        validateField('full_address', prepopulated.full)
      } else {
        setFullAddressValid(null)
      }

      setFormAddressEntrance(prepopulated.entrance)
      if (prepopulated?.entrance) {
        validateField('entrance', prepopulated.entrance)
      } else {
        setFormAddressEntranceValid(null)
      }

      setFormAddressFloor(prepopulated.floor)
      if (prepopulated?.floor) {
        validateField('floor', prepopulated.floor)
      } else {
        setFormAddressFloorValid(null)
      }

      setFormAddressFlat(prepopulated.flat)
      if (prepopulated?.flat) {
        validateField('flat', prepopulated.flat)
      } else {
        setFormAddressFlatValid(null)
      }

      setFormAddressHomephone(prepopulated.homephone)
      if (prepopulated?.homephone) {
        validateField('homephone', prepopulated.homephone)
      } else {
        setFormAddressHomephoneValid(null)
      }
    } else {
      if (fromCabinet) {
        setFormAddressName('')
        setFullAddress('')
        setFormAddressEntrance('')
        setFormAddressFloor('')
        setFormAddressFlat('')
        setFormAddressHomephone('')
        
        setFullAddressValid(null)
        setFormAddressEntranceValid(null)
        setFormAddressFloorValid(null)
        setFormAddressFlatValid(null)
        setFormAddressHomephoneValid(null)
      }
    }
  }, [prepopulated])

  useEffect(() => {
    // let formAddressIsValid = (formAddressEntranceValid === true
    //   && formAddressFloorValid === true
    //   && formAddressHomephoneValid === true
    //   && fullAddressValid === true)
    
    let formAddressIsValid = fullAddressValid
     
    let formAddressData = {
      name: formAddressName,
      full: fullAddress,
      entrance: formAddressEntrance,
      floor: formAddressFloor,
      flat: formAddressFlat,
      homephone: formAddressHomephone,
      isValid: formAddressIsValid,

      city: derivedCity,
      country: derivedCountry,
      cityPostalCode: derivedCityPostalCode,
      cityFiasId: derivedCityFiasId,
      cityKladrId: derivedCityKladrId,
    }
    
    if (getFormData) {
      getFormData(formAddressData)
    }
  }, [ formAddressName, fullAddress, derivedCity, formAddressEntrance, formAddressFloor, formAddressFlat, formAddressHomephone, fullAddressValid, formAddressEntranceValid, formAddressFloorValid, formAddressHomephoneValid ])

  const handleFullAddress = (suggestion) => {
    if (suggestion?.data) {
      setDerivedCity(suggestion.data.city)
      setDerivedCountry(suggestion.data.country)
      setDerivedCityPostalCode(suggestion.data.postal_code)
      setDerivedCityFiasId(suggestion.data.city_fias_id)
      setDerivedCityKladrId(suggestion.data.city_kladr_id)
      
      setFullAddress(suggestion.value)
      setFullAddressFilled(true)
      validateField('full_address', suggestion.value)
    }
  }

  const handleChangeInputText = (e) => {
    const name = e.target.name
    const value = e.target.value
    validateField(name, value)
    switch (name) {
      case 'name':
        setFormAddressName(value)
        break
      case 'entrance':
        setFormAddressEntrance(value)
        break
      case 'floor':
        setFormAddressFloor(value)
        break
      case 'flat':
        setFormAddressFlat(value)
        break
      case 'homephone':
        setFormAddressHomephone(value)
        break
    }
  }

  const validateField = (name, value) => {
    switch (name) {
      case 'full_address':
        value.length > 1 ? setFullAddressValid(true) : setFullAddressValid(false)
        break
      case 'entrance':
        // value.length > 0 ? setFormAddressEntranceValid(true) : setFormAddressEntranceValid(false)
        setFormAddressEntranceValid(null)
        break
      case 'floor':
        // value.length > 0 ? setFormAddressFloorValid(true) : setFormAddressFloorValid(false)
        setFormAddressFloorValid(null)
        break
      case 'flat':
        // value.length > 0 ? setFormAddressFlatValid(true) : setFormAddressFlatValid(false)
        setFormAddressFlatValid(null)
        break
      case 'homephone':
        // value.length > 0 ? setFormAddressHomephoneValid(true) : setFormAddressHomephoneValid(false)
        setFormAddressHomephoneValid(null)
        break
    }
  }

  return <>
    { (showAddressName && showAddressName === true) && <CartFieldText value={formAddressName} handleChange={handleChangeInputText} name="name" placeholder="Название" /> }
    <div className="af-class-cart__user-field-wrapper">
      <div className={`af-class-cart__user-field-label ${ fullAddressFilled && 'af-class-cart__user-field-label-active' }`}>Улица, Дом, Корпус/Строение</div>
      <AddressSuggestions
        ref={suggestionsRef}
        filterToBound='house'
        filterLocations={{fias_id: cityFiasId}}
        token={process.env.REACT_APP_DADATA_API_KEY}
        value={{value: fullAddress}}
        onChange={(suggestion) => handleFullAddress(suggestion)}
        inputProps={{
          name: 'full_address',
          placeholder: 'Улица, Дом, Корпус/Строение',
          className: `af-class-cart__user-field w-input${fullAddressValid === false ? ' user-input-invalid' : ''}`,
          onKeyUp: (e) => handleFullAddressFilled(e.target.value),
          onBlur: (e) => validateField(e.target.name, e.target.value),
        }} />
    </div>
    <div className="w-layout-grid af-class-cart__user-adress-group">
      <CartFieldText value={formAddressEntrance} isValid={formAddressEntranceValid} handleChange={handleChangeInputText} validateField={validateField} name="entrance" placeholder="Подъезд" />
      <CartFieldText value={formAddressFloor} isValid={formAddressFloorValid} handleChange={handleChangeInputText} validateField={validateField} name="floor" placeholder="Этаж" />
      <CartFieldText value={formAddressFlat} isValid={formAddressFlatValid} handleChange={handleChangeInputText} validateField={validateField} name="flat" placeholder="Квартира" />
      <CartFieldText value={formAddressHomephone} isValid={formAddressHomephoneValid} handleChange={handleChangeInputText} validateField={validateField} name="homephone" placeholder="Домофон" />
    </div>
  </>
}

export default AddressForm