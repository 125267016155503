import React from 'react';

export const MainContext = React.createContext({
    // cartItems: [],
    // setCartItems: () => {},
    // cartOrderId: null,
    // setCartOrderId: () => {},
    // cartTotalWeight: 0,
    // setCartTotalWeight: () => {},
    // catalogItems: [],
    // setCatalogItems: () => {},
    // catalogItemsVariations: [],
    // setCatalogItemsVariations: () => {},
    // userData: {},
    // setUserData: () => {},
    // userWpData: {},
    // setUserWpData: () => {},
    // recentlySeen: [],
    // setRecentlySeen: () => {},
    // homePageData: {},
    // setHomePageData: () => {},
    // pvzAll: [],
    // setPvzAll: () => {},
    // headerMenuCategories: [],
    // setHeaderMenuCategories: () => {},
    // brewingMethods: [],
    // setBrewingMethods: () => {},
    // priceListPageSlug: '',
    // setPriceListPageSlug: () => {},
    // cartFormData: {},
    // setCartFormData: () => {},
});