// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// https://wordpress.stackexchange.com/questions/311825/how-to-login-via-wordpress-api-and-get-user-details

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken.js";
import MaskInput from "react-maskinput";
import { MainContext } from "../../contexts/MainContext";
import CartFieldText from "../Cart/CartFieldText";
import {
  fetchCurrentUserData,
  createUser,
  authorizeUser,
  verifyMCRM,
  registerMCRM,
  authMCRM,
  fetchCurrentUser,
  updateCurrentUser,
} from "../../fetch/WPAPI";

async function loginUser(credentials) {
  return await authorizeUser(credentials.email, credentials.password);
}

const RegistrationForm = (props) => {
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);

  useEffect(() => {
    if (token && token.token) {
      navigate("/me");
    }
  }, []);

  const [registrationFail, setRegistrationFail] = useState(false);

  // Step 0 - Registration form
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [formPhoneFlag, setFormPhoneFlag] = useState("ru");
  const [formPhoneCode, setFormPhoneCode] = useState("+7");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [birthdate, setBirthdate] = useState(""); // not used
  const [sex, setSex] = useState("male"); // not used

  const changePhone = (e) => {
    setPhone(e.target.value);
    console.log(phone);
  };
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Step 1 - Showing the phone number input to confirm the phone number
  const [isCode, setIsCode] = useState(false);

  // Step 2 - Requesting the confirmation code and displaying the input for it
  const [isCodeSend, setIsCodeSend] = useState(false);
  const [code, setCode] = useState("");

  const verifyPhone = async () => {
    if (phone) {
      let result = await verifyMCRM(formPhoneCode + ' ' + phone);
      console.log(result);
      if (result.error !== "ERROR") {
        setIsCodeSend(true);
      } else {
        alert(result.user_message || "Произошла неизвестная ошибка");
      }
    }
  };

  // Step 3 - gathering all the user data and registering the user in MCRM and at the Woo backend after that (registerUserMCRM and the registerUser is called inside of handleSubmit which is called on form submit)
  const auth = async (mCRMPassword) => {
    let sid = null;
    for (let i = 0; i < document.cookie.split(";").length; i++) {
      if (document.cookie.split(";")[i].includes("mcrm_sid")) {
        sid = document.cookie.split(";")[i].split("=")[1];
      }
    }
    const data = {
      login: formPhoneCode + ' ' + phone,
      sid: sid,
      password: mCRMPassword,
    };
    let response = await authMCRM(data);
    if (response.access_token && response.access_token !== "") {
      localStorage.setItem("access", response.access_token);
    }
  };

  const registerUserMCRM = async (mCRMPassword) => {
    let sid = null;
    for (let i = 0; i < document.cookie.split(";").length; i++) {
      if (document.cookie.split(";")[i].includes("mcrm_sid")) {
        sid = document.cookie.split(";")[i].split("=")[1];
      }
    }
    let birthdateValid = birthdate.split(".");
    birthdateValid = birthdateValid.reverse();
    birthdateValid = birthdateValid.join("-");

    let phoneValid = formPhoneCode + ' ' + phone;
    phoneValid = phoneValid.split(" ").join("");

    const user = {
      firstname: surname,
      lastname: name,
      phone: phoneValid,
      sid: sid,
      // birthdate: birthdateValid,
      // gender: sex,
      email: email,
      code: mCRMPassword,
      password: mCRMPassword,
    };

    let utms = localStorage.getItem('utms');
    if (utms) {
      utms = JSON.parse(utms);
      let utm_campaign = utms?.find(utm => utm.tag == 'utm_campaign');
      if (utm_campaign) {
        user.promocode = utm_campaign.value;
      }
    }

    localStorage.setItem("phone", mCRMPassword);
    let result = await registerMCRM(user);

    let authInterval;
    authInterval = setInterval(() => {
      if (!localStorage.getItem("access")) {
        auth(mCRMPassword);
      } else {
        alert("Пользователь создан в бонусной системе.");
        // window.location.reload();
        clearInterval(authInterval);
      }
    }, 15000);

    if (result.register_status === "OK") {
      alert(
        "Ваш личный кабинет зарегистрирован. Ваша карта лояльности сейчас создаётся, в течение нескольких минут вы сможете начать ей пользоваться. Вам придет уведомление на сайте"
      );
    }
  };

  async function registerUser(credentials) {
    let data = {
      phone: formPhoneCode + ' ' + phone,
      name: name,
      surname: surname,
      // birthdate: birthdate,
    };
    localStorage.setItem("user", JSON.stringify(data));
    return await createUser(
      credentials.email,
      credentials.password,
      credentials.username,
      formPhoneCode + ' ' + phone,
      name,
      surname
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // generate random 5-digit number
    let mCRMPassword = Math.floor(10000 + Math.random() * 90000).toString();
    setCode(mCRMPassword);
    console.log(mCRMPassword);
    
    setRegistrationFail(false);

    await registerUserMCRM(mCRMPassword);

    // let username = email.substring(0, email.indexOf('@'))

    const result = await registerUser({
      email: email,
      password: password,
      username: email,
    });

    //console.log(result)

    if (result?.code != 200) {
      setRegistrationFail(result?.message);
      setIsSubmitting(false);
    } else {
      // Perform Login
      const result = await loginUser({
        email,
        password,
      });
      //console.log(result)
      if (result.data?.status == 403) {
        // setLoginFail(true)
        setIsSubmitting(false);
      } else {
        setToken(result);
        if (!mainContext.userData.id) {
          fetchCurrentUser(result.token).then((result) => {
            console.log(result)
            if (result.id) {
              fetchCurrentUserData(result.id).then((resultWoo) => {
                console.log(resultWoo)
                if (resultWoo.id) {
                  mainContext.setUserData(resultWoo);
                  mainContext.setUserWpData(result);
                  navigate("/me");
                } else {
                  setToken(null);
                  // setLoginFail(true)
                }
              });
            } else {
              setToken(null);
              // setLoginFail(true)
            }
          });
        }
      }
    }
    //handleUpdatePersonalData();
  };

  return (
    <div className="af-class-log-in__form-block w-form">
      <form
        onSubmit={handleSubmit}
        id="wf-form-Log-in-form"
        className="af-class-log-in__form"
      >
        {registrationFail && (
          <div style={{ color: "red" }}>{registrationFail}</div>
        )}
        {!isCode ? (
          <>
            <div className="w-layout-grid af-class-log-in__form-grid">
              <div className="af-class-cart__user-field-wrapper">
                <div className={`af-class-cart__user-field-label ${ surname.length && 'af-class-cart__user-field-label-active' }`}>Фамилия</div>
                <input
                  type="surname"
                  className="af-class-cart__user-field w-input"
                  maxLength={256}
                  placeholder="Фамилия"
                  required
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <div className="af-class-cart__user-field-wrapper">
                <div className={`af-class-cart__user-field-label ${ name.length && 'af-class-cart__user-field-label-active' }`}>Имя</div>
                <input
                  type="name"
                  className="af-class-cart__user-field w-input"
                  maxLength={256}
                  placeholder="Имя"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {/* <input
                type="text"
                className="af-class-cart__user-field w-input"
                placeholder="Номер телефона (+7XXXXXXXXXX)"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              /> */}
              {/* <CartFieldText
                value={phone}
                handleChange={changePhone}
                name="phone_login"
                placeholder="Телефон"
              /> */}
              <CartFieldText
                value={phone}
                handleChange={changePhone}
                name="phone"
                placeholder="Телефон"
                code={formPhoneCode}
                setCode={setFormPhoneCode}
                flag={formPhoneFlag}
                setFlag={setFormPhoneFlag}
              />
              {/* <div className="af-class-cart__user-field-wrapper">
                <div className={`af-class-cart__user-field-label ${ birthdate.length && 'af-class-cart__user-field-label-active' }`}>Дата рождения</div>
                <MaskInput
                  alwaysShowMask
                  mask="00.00.0000"
                  size={10}
                  showMask
                  maskChar="_"
                  value={birthdate}
                  onChange={(e) => setBirthdate(e.target.value)}
                  required
                  placeholder="Дата рождения"
                  type="text"
                  className="af-class-cart__user-field w-input"
                />
              </div> */}
              <div className="af-class-cart__user-field-wrapper">
                <div className={`af-class-cart__user-field-label ${ email.length && 'af-class-cart__user-field-label-active' }`}>Эл. почта</div>
                <input
                  type="email"
                  className="af-class-cart__user-field w-input"
                  maxLength={256}
                  placeholder="Эл. почта"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="af-class-cart__user-field-wrapper">
                <div className={`af-class-cart__user-field-label ${ password.length && 'af-class-cart__user-field-label-active' }`}>Пароль</div>
                <input
                  type="password"
                  className="af-class-cart__user-field w-input"
                  maxLength={256}
                  placeholder="Пароль"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="af-class-cart__user-field-wrapper">
                <div className={`af-class-cart__user-field-label ${ repeatPassword.length && 'af-class-cart__user-field-label-active' }`}>Повторите пароль</div>
                <input
                  type="password"
                  className="af-class-cart__user-field w-input"
                  maxLength={256}
                  placeholder="Повторите пароль"
                  required
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </div>
              {/* <div>
                <label
                  className="af-class-cart__user-radio-button w-radio"
                  onClick={() => setSex("male")}
                >
                  <div
                    className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input ${
                      sex == "male" ? " w--redirected-checked" : ""
                    }`}
                  />
                  <input
                    type="radio"
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    id="sex"
                    value={"male"}
                  />
                  <span
                    className="af-class-cart__user-radio-button-text w-form-label"
                    htmlFor="sex"
                  >
                    Мужчина
                  </span>
                </label>
              </div>
              <div>
                <label
                  className="af-class-cart__user-radio-button w-radio"
                  onClick={() => setSex("female")}
                >
                  <div
                    className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-cart__user-radio-button-icon w-radio-input ${
                      sex == "female" ? " w--redirected-checked" : ""
                    }`}
                  />
                  <input
                    type="radio"
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    id="sex"
                    value={"female"}
                  />
                  <span
                    className="af-class-cart__user-radio-button-text w-form-label"
                    htmlFor="sex"
                  >
                    Женщина
                  </span>
                </label>
              </div> */}
            </div>
            <input
              value={isSubmitting ? 'Регистрация...' : 'Регистрация'}
              // onClick={() => setIsCode(true)}
              type="submit"
              readOnly
              className={`af-class-button--primary af-class-log-in w-button${
                surname &&
                name &&
                phone &&
                // birthdate &&
                email &&
                password &&
                repeatPassword &&
                password === repeatPassword &&
                !isSubmitting
                  ? ""
                  : " button-disabled noClick"
              }`}
            />
          </>
        ) : (
          <>
            <div className="w-layout-grid af-class-log-in__form-grid">
              <div
                onClick={() => setIsCode(false)}
                className="dropdown-heading pointer"
              >
                Вернуться назад
              </div>
              {/* <div className="af-class-cart__user-field-wrapper">
                <div className={`af-class-cart__user-field-label ${ phone.length && 'af-class-cart__user-field-label-active' }`}>Номер телефона</div>
                <input
                  type="text"
                  className="af-class-cart__user-field w-input"
                  placeholder="Номер телефона"
                  required
                  readOnly
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div> */}
              <CartFieldText
                value={phone}
                handleChange={changePhone}
                name="phone"
                placeholder="Телефон"
                code={formPhoneCode}
                setCode={setFormPhoneCode}
                flag={formPhoneFlag}
                setFlag={setFormPhoneFlag}
              />
              {isCodeSend && (
                <div className="af-class-cart__user-field-wrapper">
                  <div className={`af-class-cart__user-field-label ${ code.length && 'af-class-cart__user-field-label-active' }`}>СМС код</div>
                  <input
                    type="text"
                    className="af-class-cart__user-field w-input"
                    placeholder="СМС код"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              )}
            </div>
            {isCodeSend ? (
              <input
                value="Подтвердить"
                readOnly
                id="w-node-e7a94754-e4e9-6a82-3200-ab774fd248cc-b23d362e"
                type="submit"
                className={`af-class-button--primary af-class-log-in w-button${
                  code ? "" : " button-disabled noClick"
                }`}
              />
            ) : (
              <input
                value="Получить код"
                onClick={verifyPhone}
                readOnly
                //id="w-node-e7a94754-e4e9-6a82-3200-ab774fd248cc-b23d362e"
                className={`af-class-button--primary af-class-log-in w-button${
                  phone ? "" : " button-disabled noClick"
                }`}
              />
            )}
          </>
        )}

        <div className="af-class-log-in__caption">
          Регистрируясь на сайте Rockets coffee вы принимаете{" "}
          <a
            onClick={() => navigate(`/user-agreement`)}
            href="#"
            className="af-class-log-in__caption-link"
          >
            пользовательское соглашение.
          </a>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
