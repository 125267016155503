import { useState, useEffect, useContext, forwardRef } from "react";
import { CartFormContext } from "../../../contexts/CartFormContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMinDeliveryDateDalli } from "./functions";

const CartFormFieldDeliveryDate = () => {
  const cartFormContext = useContext(CartFormContext);

  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    if (dropdown === false) {
      return;
    }

    const handleMouseUp = (e) => {
      setDropdown(false);
    }

    document.addEventListener("mouseup", handleMouseUp);

    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, [dropdown]);

  const DeliveryDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="af-class-g-select w-dropdown" onClick={onClick} ref={ref}>
      <div className="af-class-g-select__toggle w-dropdown-toggle">
        <div className="af-class-g-select__text">{value}</div>
        <img
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
          }}
          loading="lazy"
          src="/images/select-arrow.svg"
          alt=""
          className="af-class-g-select__icon"
        />
      </div>
    </div>
  ));

  const formatDate = (date) => {
    if (date === null) {
      return "";
    }

    const today = new Date();
    if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
      return "Сегодня";
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (date.getDate() === tomorrow.getDate() && date.getMonth() === tomorrow.getMonth() && date.getFullYear() === tomorrow.getFullYear()) {
      return "Завтра";
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const dayOfWeek = date.toLocaleDateString("ru-RU", { weekday: "short" }).charAt(0).toUpperCase() + date.toLocaleDateString("ru-RU", { weekday: "short" }).slice(1);

    return `${dayOfWeek}, ${day}.${month}`;
  }

  const restrictedDates = [];

  restrictedDates.push(new Date(2024, 1, 23));
  restrictedDates.push(new Date(2024, 2, 8));

  const today = new Date();
  if ((today.getMonth() == 2 && ((today.getDate() == 7 && today.getHours() >= 14) || today.getDate() > 7))) {
    restrictedDates.push(new Date(2024, 2, 9));
    restrictedDates.push(new Date(2024, 2, 10));
    restrictedDates.push(new Date(2024, 2, 11));
  }

  restrictedDates.push(new Date(2024, 0, 1));
  restrictedDates.push(new Date(2024, 0, 2));
  restrictedDates.push(new Date(2024, 0, 3));

  if ((today.getMonth() == 11 && ((today.getDate() == 29 && today.getHours() >= 14) || today.getDate() > 29))
   || (today.getMonth() == 0 && ((today.getDate() == 3 && today.getHours() < 14) || today.getDate() < 3))) {
    restrictedDates.push(new Date(2023, 11, 29));
    restrictedDates.push(new Date(2023, 11, 30));
    restrictedDates.push(new Date(2023, 11, 31));
    restrictedDates.push(new Date(2024, 0, 1));
    restrictedDates.push(new Date(2024, 0, 2));
    restrictedDates.push(new Date(2024, 0, 3));
  }
  if (today.getMonth() == 0 && ((today.getDate() == 3 && today.getHours() >= 14) || today.getDate() == 4 || today.getDate() == 5)) {
    restrictedDates.push(new Date(2024, 0, 3));
    restrictedDates.push(new Date(2024, 0, 4));
    restrictedDates.push(new Date(2024, 0, 5));
  }

  // May holidays
  if (((today.getMonth() == 3 && ((today.getDate() == 27 && today.getHours() >= 14) || today.getDate() > 27)))
  || ((today.getMonth() == 4 && today.getDate() <= 2))) {
   restrictedDates.push(new Date(2024, 3, 27));
   restrictedDates.push(new Date(2024, 3, 28));
   restrictedDates.push(new Date(2024, 3, 29));
   restrictedDates.push(new Date(2024, 3, 30));
   restrictedDates.push(new Date(2024, 4, 1));
   restrictedDates.push(new Date(2024, 4, 2));
 }

 if ((today.getMonth() == 4 && ((today.getDate() == 3 && today.getHours() >= 14) || (today.getDate() >=4 && today.getDate() <= 5)))) {
   restrictedDates.push(new Date(2024, 4, 3));
   restrictedDates.push(new Date(2024, 4, 4));
   restrictedDates.push(new Date(2024, 4, 5));
   restrictedDates.push(new Date(2024, 4, 6));
 }

 if ((today.getMonth() == 4 && ((today.getDate() == 8 && today.getHours() >= 14) || (today.getDate() >= 9 && today.getDate() <= 12)))) {
   restrictedDates.push(new Date(2024, 4, 8));
   restrictedDates.push(new Date(2024, 4, 9));
   restrictedDates.push(new Date(2024, 4, 10));
   restrictedDates.push(new Date(2024, 4, 11));
   restrictedDates.push(new Date(2024, 4, 12));
   restrictedDates.push(new Date(2024, 4, 13));
 }

 restrictedDates.push(new Date(2024, 5, 12));
 if ((today.getMonth() == 5 && ((today.getDate() == 11 && today.getHours() >= 14) || (today.getDate() >= 12 && today.getDate() <= 13)))) {
  restrictedDates.push(new Date(2024, 5, 13));
 }

  const dates = [];
  let i = 0;
  while (dates.length < 6) {
    const date = getMinDeliveryDateDalli();
    date.setDate(date.getDate() + i);
    if (!restrictedDates.some((elem) => elem.toLocaleDateString() == date.toLocaleDateString())) {
      dates.push(date);
    }
    i++;
  }

  useEffect(() => {
    if (cartFormContext.deliveryTime.options.length > 0) {
      let defaultDeliveryTime;
      if (cartFormContext.deliveryTime.options.includes('10:00 - 22:00')) {
        defaultDeliveryTime = '10:00 - 22:00';
      } else if (cartFormContext.deliveryTime.options.includes('09:00 - 22:00')) {
        defaultDeliveryTime = '09:00 - 22:00';
      } else {
        defaultDeliveryTime = cartFormContext.deliveryTime.options[0];
      }

      cartFormContext.setDeliveryTime({
        ...cartFormContext.deliveryTime,
        selected: defaultDeliveryTime,
      });
    }
  }, [cartFormContext.deliveryTime.options]);

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {dates.map((elem, index) => <div
          key={index}
          style={{
            padding: '8px 16px',
            backgroundColor: '#fff',
            borderRadius: '50px',
            cursor: 'pointer',
            border: (elem.toLocaleDateString() != cartFormContext.deliveryDate.toLocaleDateString()) ? '1px #E0E0E0 solid' : '1px #1D71B8 solid',
            color: (elem.toLocaleDateString() != cartFormContext.deliveryDate.toLocaleDateString()) ? '#000' : '#1D71B8',
          }}
          onClick={() => cartFormContext.setDeliveryDate(elem)}
        >{formatDate(elem)}</div>)}
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {cartFormContext.deliveryTime.options.map((elem, index) => <div
          key={index}
          style={{
            padding: '8px 16px',
            backgroundColor: '#fff',
            borderRadius: '50px',
            cursor: 'pointer',
            border: (elem != cartFormContext.deliveryTime.selected) ? '1px #E0E0E0 solid' : '1px #1D71B8 solid',
            color: (elem != cartFormContext.deliveryTime.selected) ? '#000' : '#1D71B8',
          }}
          onClick={() =>
            cartFormContext.setDeliveryTime({
              ...cartFormContext.deliveryTime,
              selected: elem,
            })
          }
        >{elem}</div>)}
      </div>

      {/* <DatePicker
        selected={cartFormContext.deliveryDate}
        onChange={(date) => cartFormContext.setDeliveryDate(date)}
        customInput={<DeliveryDateInput />}
        dateFormat="dd.MM.yyyy"
        excludeDateIntervals={[
          {
            start: new Date(2023, 0, 1),
            end: new Date(2023, 0, 3),
          },
          {
            start: new Date(2023, 1, 24),
            end: new Date(2023, 1, 26),
          },
          {
            start: new Date(2023, 4, 1),
            end: new Date(2023, 4, 2),
          },
          {
            start: new Date(2023, 4, 8),
            end: new Date(2023, 4, 9),
          },
          {
            start: new Date(2023, 5, 12),
            end: new Date(2023, 5, 13),
          },
        ]}
        minDate={getMinDeliveryDateDalli()}
        showPopperArrow={false}
        calendarStartDay={1}
      />

      <div
        data-delay={0}
        data-hover="false"
        fs-selectcustom-element="dropdown"
        fs-selectcustom-hideinitial="true"
        className="af-class-g-select w-dropdown"
        style={{
          zIndex: dropdown === true ? 1000 : 900,
        }}
      >
        <div
          className="af-class-g-select__toggle w-dropdown-toggle"
          onClick={() =>
            setDropdown(!dropdown)
          }
        >
          <div className="af-class-g-select__text">
            {cartFormContext.deliveryTime.selected}
          </div>
          <img
            style={{
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
              msTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)",
            }}
            loading="lazy"
            src="images/select-arrow.svg"
            alt=""
            className="af-class-g-select__icon"
          />
        </div>
        <nav
          className={`
            af-class-g-select__list w-dropdown-list
            ${dropdown === false ? "" : " w--open"}
          `}
        >
          {cartFormContext.deliveryTime.options.map((elem, index) => <a
            key={index}
            className="af-class-g-select__link w-dropdown-link"
            onClick={() =>
              cartFormContext.setDeliveryTime({
                ...cartFormContext.deliveryTime,
                selected: elem,
              })
            }
          >
            {elem}
          </a>)}
        </nav>
      </div> */}
    </>
  );
};

export default CartFormFieldDeliveryDate;
