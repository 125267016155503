import MaskInput from 'react-maskinput'
import { useState } from 'react'

const CartFieldText = (props) => {
  const [phoneDropdown, setPhoneDropdown] = useState(false);
  const [phoneMask, setPhoneMask] = useState('000 000 00 00');

  const handlePhoneCode = (code, flag) => {
    switch (code) {
      case '+7':
        setPhoneMask('000 000 00 00')
        break
      case '+375':
        setPhoneMask('00 000 00 00')
        break
      case '+374':
        setPhoneMask('00 000 00 00')
        break
      case '+996':
        setPhoneMask('000 00 00 00')
        break
    }

    props.setCode(code);
    props.setFlag(flag);
    setPhoneDropdown(false);

    props.handleChange({target: {
      name: 'phone',
      value: '',
    }})
  }

  if (props.name == 'phone') {
    return <div className="af-class-cart__user-field-wrapper af-class-cart__user-field-wrapper-phone">
      <div className={`af-class-cart__user-field-label ${ props.value.length && 'af-class-cart__user-field-label-active' }`}>{props.placeholder}</div>
      <div className={`af-class-cart__user-field-phone-country-selected${props.isValid === false ? ' user-input-invalid' : ''}`} onClick={() => setPhoneDropdown(!phoneDropdown)}>
        <img src={`/images/flags/${props.flag}.svg`} width="16" alt="" />
        <div>{props.code}</div>
        <img src="/images/arrow-down.svg" width="9" alt="" className="af-class-header__dropdown-icon--options" />
      </div>
      <div className={`af-class-cart__user-field-phone-country-dropdown${phoneDropdown === true ? ' af-class-cart__user-field-phone-country-dropdown-active' : ''}`}>
        <div className="af-class-cart__user-field-phone-country-option" onClick={() => handlePhoneCode("+7", "ru")}>
          <img src={`/images/flags/ru.svg`} width="16" alt="" />
          <div>+7</div>
        </div>
        <div className="af-class-cart__user-field-phone-country-option" onClick={() => handlePhoneCode("+375", "by")}>
          <img src={`/images/flags/by.svg`} width="16" alt="" />
          <div>+375</div>
        </div>
        <div className="af-class-cart__user-field-phone-country-option" onClick={() => handlePhoneCode("+7", "kz")}>
          <img src={`/images/flags/kz.svg`} width="16" alt="" />
          <div>+7</div>
        </div>
        <div className="af-class-cart__user-field-phone-country-option" onClick={() => handlePhoneCode("+374", "am")}>
          <img src={`/images/flags/am.svg`} width="16" alt="" />
          <div>+374</div>
        </div>
        <div className="af-class-cart__user-field-phone-country-option" onClick={() => handlePhoneCode("+996", "kg")}>
          <img src={`/images/flags/kg.svg`} width="16" alt="" />
          <div>+996</div>
        </div>
        {/* <div className="af-class-cart__user-field-phone-country-option" onClick={() => handlePhoneCode("+995", "ge")}>
          <img src={`/images/flags/ge.svg`} width="16" alt="" />
          <div>+995</div>
        </div> */}
      </div>
      <MaskInput
        alwaysShowMask
        mask={phoneMask}
        size={20}
        showMask
        maskChar="_"
        value={props.value}
        onChange={(e) => props.handleChange(e)}
        onBlur={(e) => props.validateField(e.target.name, e.target.value)}
        onClick={(e) => {
          let currentValue = props.value.replaceAll('_', '').trim();
          e.target.setSelectionRange(currentValue.length, currentValue.length);
        }}
        name={props.name}
        placeholder={props.placeholder}
        type="text"
        className={`af-class-cart__user-field af-class-cart__user-field-phone w-input${props.isValid === false ? ' user-input-invalid' : ''}`}
      />
    </div>
  } else if (props.name == 'phone_login') {
    return <div className="af-class-cart__user-field-wrapper">
      <div className={`af-class-cart__user-field-label ${ props.value.length && 'af-class-cart__user-field-label-active' }`}>{props.placeholder}</div>
      <MaskInput
        alwaysShowMask
        mask={'+0 000 000 00 00'}
        size={20}
        showMask
        maskChar="_"
        value={props.value}
        onChange={(e) => props.handleChange(e)}
        onBlur={(e) => props.validateField(e.target.name, e.target.value)}
        name={props.name}
        placeholder={props.placeholder}
        type="text"
        className={`af-class-cart__user-field w-input${props.isValid === false ? ' user-input-invalid' : ''}`}
      />
    </div>
  } else {
    return <div className="af-class-cart__user-field-wrapper">
      <div className={`af-class-cart__user-field-label ${ props.value.length && 'af-class-cart__user-field-label-active' }`}>{props.placeholder}</div>
      <input
        value={props.value}
        onChange={(e) => props.handleChange(e)}
        onBlur={(e) => props.validateField(e.target.name, e.target.value)}
        name={props.name}
        placeholder={props.placeholder}
        type="text"
        className={`af-class-cart__user-field w-input${props.isValid === false ? ' user-input-invalid' : ''}`}
        maxLength={256}
      />
    </div>
  }
}

export default CartFieldText