/* eslint-disable */

import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  // { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6261fc674c12d792773d3625").then(body => body.text()), isAsync: false },
  // { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class DetailProductView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/DetailProductController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = DetailProductView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6261feae8909984ea11ddd02'
    htmlEl.dataset['wfSite'] = '6261fc674c12d792773d3625'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = DetailProductView.Controller !== DetailProductView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rockets-coffee-export.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div bind="7df4a36e-c636-8af6-7fee-e569bead05b9" className="af-class-embed-for-wordpress w-embed">
              <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-method_img {\n  \theight: 30px !important;\n  }\n  \t.af-view .af-class-button.af-class-buy-now {\n  \tfont-size: 14px !important;\n  \tfont-weight: 700 !important;\n    line-height: 120% !important;\n    padding: 21px 50px 19px !important;\n    display: flex !important;\n    justify-content: center !important;\n    border-radius: 50px !important;\n    background-color: #1d71b8 !important;\n    color: #fff;\n  }\n" }} />
            </div>
            <div>
              <Header />
              <div bind="67a8d3a2-971f-876c-e99f-4a9b0598c186" className="af-class-popup--p-reviews">
                <div className="af-class-popup--p-reviews__content">
                  <div className="af-class-popup--p-reviews__title">Напишите отзыв</div>
                  <div className="w-layout-grid af-class-popup--p-reviews__form-wrapper">
                    <div className="af-class-popup--p-reviews__button-wrapper">
                      <div data-hover="false" data-delay={0} className="af-class-popup--p-reviews__button w-dropdown">
                        <div className="af-class-dropdown-toggle w-dropdown-toggle">
                          <div className="af-class-text-button-card-p_catalog">О доставке</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                        </div>
                        <nav className="w-dropdown-list">
                          <div className="af-class-dropdown-list af-class-review-popup">
                            <a href="#" id="w-node-_67a8d3a2-971f-876c-e99f-4a9b0598c193-0598c186" className="af-class-text-dropdown-grey w-dropdown-link">О&nbsp;магазине</a>
                            <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Об оплате</a>
                            <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">О команде</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div className="af-class-popup--p-reviews__form-block w-form">
                      <form action="#" id="email-form" name="email-form" data-name="Email Form" method="get" className="af-class-popup--p-reviews__form"><textarea placeholder="Комментарий" maxLength={5000} id="field-2" name="field-2" data-name="Field 2" className="af-class-popup--p-reviews__text-area w-input" defaultValue={""} />
                        <div className="af-class-popup--p-reviews__raiting-wrapper">
                          <div className="af-class-popup--p-reviews__raiting-title">Ваша оценка</div>
                          <div className="w-layout-grid af-class-popup--p-reviews__raiting-stars">
                            <div data-w-id="2b478cab-ddb7-8d93-9344-eae0ee483e3b" className="af-class-review-mark-wrapper af-class-_1"><img src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_1" /></div>
                            <div data-w-id="9e6e988c-4bd1-9c3f-476c-78be2fe46cf7" className="af-class-review-mark-wrapper af-class-_2"><img src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_2" /></div>
                            <div data-w-id="7a964ee5-8e2c-2985-b872-67150ed30744" className="af-class-review-mark-wrapper af-class-_3"><img src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_3" /></div>
                            <div data-w-id="2cea3a31-6fdc-9612-f46b-478ed1d39691" className="af-class-review-mark-wrapper af-class-_4"><img src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_4" /></div>
                            <div data-w-id="df462ae3-dc01-cd52-e99f-04d7e96c2ecd" className="af-class-review-mark-wrapper af-class-_5"><img src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" /><img src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_5" /></div>
                          </div>
                        </div><input type="submit" defaultValue="Отправить" data-wait="Please wait..." className="af-class-popup--p-reviews__submit-button w-button" />
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div data-w-id="67a8d3a2-971f-876c-e99f-4a9b0598c1ac" className="af-class-popup--p-reviews__close-icon-wrapper"><img src="images/icon--close-popup.svg" loading="lazy" alt="" /></div>
                </div>
              </div>
              <div className="af-class-section af-class-hero af-class-an-load--2 af-class-wf-section">
                <div className="af-class-container w-container">
                  <div className="af-class-content">
                    <div className="af-class-product__preview-block">
                      <div className="af-class-product__tags_wrap">
                        <div className="af-class-left_tags_block">
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a514" className="af-class-sale">
                            <div className="af-class-text_tag1">SALE</div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a517" className="af-class-rockets">
                            <div className="af-class-text_tag2">Выбор Rockets</div>
                          </div>
                        </div>
                        <div className="af-class-products-card__favorites-icon-wrapper af-class-p-product"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                      </div>
                      <div className="af-class-product__img_wrap"><img src="images/Frame-738001215-1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 48vw, 47vw" width={508} srcSet="images/Frame-738001215-1-p-500.png 500w, images/Frame-738001215-1-p-800.png 800w, images/Frame-738001215-1.png 1016w" alt="" className="af-class-product__preview_img af-class-an-image--scroll-into-view" /></div>
                    </div>
                    <div className="af-class-product__info-block">
                      <h1 bind="4bc03ed7-310c-1251-2dda-ba348eb2a520" className="af-class-product__title" />
                      <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a522" className="af-class-product__discription" />
                      <div bind="a43d3d8b-d7f2-e227-d040-a0f3081fd3ce" className="w-dyn-list">
                        <div bind="a43d3d8b-d7f2-e227-d040-a0f3081fd3cf" role="list" className="af-class-product__tags w-dyn-items">
                          <div bind="a43d3d8b-d7f2-e227-d040-a0f3081fd3d0" role="listitem" className="w-dyn-item">
                            <div bind="85fe110b-414d-5190-8599-9d5ffebd838d" className="af-class-product__tags_ingredients" />
                          </div>
                        </div>
                        <div bind="a43d3d8b-d7f2-e227-d040-a0f3081fd3d1" className="w-dyn-empty">
                          <div>No items found.</div>
                        </div>
                      </div>
                      <div className="af-class-product__specifications">
                        <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a52c" className="af-class-specifications__block">
                          <div className="af-class-indicator_text_wrap">
                            <div className="af-class-specifications__lable">Горечь</div>
                            <div className="af-class-question_btn">
                              <div className="af-class-availability__tooltip_block">
                                <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                              </div>
                            </div>
                          </div>
                          <div id="w-node-_445cfce1-9e46-04bb-af01-bc3a85844c7e-a11ddd02" className="af-class-specifications__indicators-wrapper">
                            <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a534" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="1ec0b456-a594-f51d-6855-07b9f936811c" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="822a619e-362e-8215-02a6-6c75fe501087" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="9ffb25b4-fdc5-af8b-a350-7239a7cd9ee4" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="91e51c43-50bb-ddb4-0b8a-6f8ee524f1b1" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                            </div>
                          </div>
                        </div>
                        <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a53a" className="af-class-specifications__block">
                          <div className="af-class-indicator_text_wrap">
                            <div className="af-class-specifications__lable">Сладость</div>
                            <div className="af-class-question_btn">
                              <div className="af-class-availability__tooltip_block">
                                <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                              </div>
                            </div>
                          </div>
                          <div className="af-class-specifications__indicators-wrapper">
                            <div bind="acd2eef3-f0ab-b323-4997-176e2313c599" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="acd2eef3-f0ab-b323-4997-176e2313c59f" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="acd2eef3-f0ab-b323-4997-176e2313c5a5" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="acd2eef3-f0ab-b323-4997-176e2313c5ab" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="acd2eef3-f0ab-b323-4997-176e2313c5b1" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                            </div>
                          </div>
                        </div>
                        <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a548" className="af-class-specifications__block">
                          <div className="af-class-indicator_text_wrap">
                            <div className="af-class-specifications__lable">Кислотность</div>
                            <div className="af-class-question_btn">
                              <div className="af-class-availability__tooltip_block">
                                <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                              </div>
                            </div>
                          </div>
                          <div className="af-class-specifications__indicators-wrapper">
                            <div bind="0c55d1d4-0cc4-3a82-0b06-412d1e3209bd" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="0c55d1d4-0cc4-3a82-0b06-412d1e3209c3" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="0c55d1d4-0cc4-3a82-0b06-412d1e3209c9" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="0c55d1d4-0cc4-3a82-0b06-412d1e3209cf" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator" />
                            </div>
                            <div bind="0c55d1d4-0cc4-3a82-0b06-412d1e3209d5" className="af-class-specifications__indicators">
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                              <div className="af-class-specifications__indicator af-class-indicator__active" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-product__methods">
                        <div className="af-class-specifications__lable">Способы заваривания</div>
                        <div className="af-class-product__methods-cards">
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a55a" className="af-class-method_card-wrapper">
                            <div className="af-class-method_card"><img src="images/voronka.svg" loading="lazy" width={19} height={25} alt="" className="af-class-method_img" />
                              <div className="af-class-method_card__lable">Воронка</div>
                              <div className="af-class-question_btn af-class-question_method">
                                <div className="af-class-availability__tooltip_block">
                                  <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a563" className="af-class-method_card-wrapper">
                            <div className="af-class-method_card"><img src="images/geizer.svg" loading="lazy" width={22} height={25} alt="" className="af-class-method_img" />
                              <div className="af-class-method_card__lable">ГЕЙЗЕР</div>
                              <div className="af-class-question_btn af-class-question_method">
                                <div className="af-class-availability__tooltip_block">
                                  <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a56c" className="af-class-method_card-wrapper">
                            <div className="af-class-method_card"><img src="images/turka.svg" loading="lazy" width={25} height={25} alt="" className="af-class-method_img af-class-tur" />
                              <div className="af-class-method_card__lable">ТУРКА</div>
                              <div className="af-class-question_btn af-class-question_method">
                                <div className="af-class-availability__tooltip_block">
                                  <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a575" className="af-class-method_card-wrapper">
                            <div className="af-class-method_card"><img src="images/chainik.svg" loading="lazy" width={24} height={25} alt="" className="af-class-method_img" />
                              <div className="af-class-method_card__lable">Воронка</div>
                              <div className="af-class-question_btn af-class-question_method">
                                <div className="af-class-availability__tooltip_block">
                                  <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-layout-grid af-class-product__more-info-wrapper">
                        <div className="af-class-indicator_text_wrap af-class-more-info">
                          <div className="af-class-specifications__lable">Горечь</div>
                          <div className="af-class-question_btn">
                            <div className="af-class-availability__tooltip_block">
                              <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                            </div>
                          </div>
                        </div>
                        <div className="af-class-indicator_text_wrap af-class-more-info">
                          <div className="af-class-specifications__lable">Горечь</div>
                          <div className="af-class-question_btn">
                            <div className="af-class-availability__tooltip_block">
                              <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                            </div>
                          </div>
                        </div>
                        <div className="af-class-indicator_text_wrap af-class-more-info">
                          <div className="af-class-specifications__lable">Горечь</div>
                          <div className="af-class-question_btn">
                            <div className="af-class-availability__tooltip_block">
                              <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-product__score-wrapper">
                        <div className="af-class-product__score"><span className="af-class-text-span-2">Оценка SCA:</span></div>
                        <div bind="24d26806-bbb6-b3b8-1894-24e348a03724" className="af-class-product__score" />
                        <div className="af-class-product__score">баллов</div>
                      </div>
                      <div className="af-class-product__options-wrapper af-class-display-none">
                        <div className="af-class-product__buttons">
                          <div data-hover="false" data-delay={0} id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a59a-a11ddd02" className="af-class-button-options-card-p_catalog w-dropdown">
                            <div className="af-class-dropdown-toggle w-dropdown-toggle">
                              <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                            </div>
                            <nav className="w-dropdown-list">
                              <div className="af-class-dropdown-list">
                                <a href="#" id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a5a1-a11ddd02" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                              </div>
                            </nav>
                          </div>
                          <div data-hover="false" data-delay={0} id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a5a5-a11ddd02" className="af-class-button-options-card-p_catalog w-dropdown">
                            <div className="af-class-dropdown-toggle w-dropdown-toggle">
                              <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                            </div>
                            <nav className="w-dropdown-list">
                              <div className="af-class-dropdown-list af-class-type">
                                <a href="#" id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a5ac-a11ddd02" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                              </div>
                            </nav>
                          </div>
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a5be-a11ddd02" className="af-class-product__options-block af-class-qty af-class-last">
                            <div className="af-class-option_text af-class-qty">–</div>
                            <div className="af-class-option_text af-class-qty">1</div>
                            <div className="af-class-option_text af-class-plus">+</div>
                          </div>
                        </div>
                        <div className="af-class-product__options-price-block">
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a5c8" data-commerce-type="variation-price" className="af-class-product__price" />
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a5c6" className="af-class-product__price-regular" />
                        </div>
                      </div>
                      <div bind="9f3145fc-0065-7cb8-1a12-2d6099f5e4f7">
                        <form action="#" data-node-type="commerce-add-to-cart-form" bind="9f3145fc-0065-7cb8-1a12-2d6099f5e4f8" template-bind="9f3145fc-0065-7cb8-1a12-2d6099f5e4f8" position-bind-position="prepend" className="w-commerce-commerceaddtocartform"><label htmlFor="quantity-bec4348044589597786937fe0f888038" position-id="9f3145fc-0065-7cb8-1a12-2d6099f5e500" bind="9f3145fc-0065-7cb8-1a12-2d6099f5e500" className="af-class-display-none">Quantity</label><input type="number" pattern="^[0-9]+$" inputMode="numeric" id="quantity-bec4348044589597786937fe0f888038" name="commerce-add-to-cart-quantity-input" min={1} position-id="9f3145fc-0065-7cb8-1a12-2d6099f5e502" bind="9f3145fc-0065-7cb8-1a12-2d6099f5e502" className="w-commerce-commerceaddtocartquantityinput af-class-quantity-2" defaultValue={1} />
                          <div position-id="1ed1627a-6792-32f8-5537-9d01d2c76bfc" className="af-class-product__options-price-block">
                            <div bind="1ed1627a-6792-32f8-5537-9d01d2c76bfd" data-commerce-type="variation-price" className="af-class-product__price" />
                            <div bind="1ed1627a-6792-32f8-5537-9d01d2c76bfe" className="af-class-product__price-regular" />
                          </div><input type="submit" position-id="9f3145fc-0065-7cb8-1a12-2d6099f5e503" data-node-type="commerce-add-to-cart-button" data-loading-text="Adding to cart..." defaultValue="Добавить в корзину" aria-busy="false" aria-haspopup="dialog" className="w-commerce-commerceaddtocartbutton af-class-button af-class-buy-now" />
                          <a position-id="9f3145fc-0065-7cb8-1a12-2d6099f5e504" data-node-type="commerce-buy-now-button" data-default-text="Купить сейчас" data-subscription-text="Subscribe now" aria-busy="false" aria-haspopup="false" className="w-commerce-commercebuynowbutton af-class-button af-class-buy-now" href="checkout.html">Купить сейчас</a>
                        </form>
                        <div tabIndex={0} bind="9f3145fc-0065-7cb8-1a12-2d6099f5e505" style={{display: 'none'}} className="w-commerce-commerceaddtocartoutofstock">
                          <div>This product is out of stock.</div>
                        </div>
                        <div aria-live="assertive" bind="9f3145fc-0065-7cb8-1a12-2d6099f5e508" data-node-type="commerce-add-to-cart-error" style={{display: 'none'}} className="w-commerce-commerceaddtocarterror">
                          <div bind="9f3145fc-0065-7cb8-1a12-2d6099f5e509" data-node-type="commerce-add-to-cart-error" data-w-add-to-cart-quantity-error="Product is not available in this quantity." data-w-add-to-cart-general-error="Something went wrong when adding this item to the cart." data-w-add-to-cart-mixed-cart-error="You can’t purchase another product with a subscription." data-w-add-to-cart-buy-now-error="Something went wrong when trying to purchase this item." data-w-add-to-cart-checkout-disabled-error="Checkout is disabled on this site." data-w-add-to-cart-select-all-options-error="Please select an option in each set.">Product is not available in this quantity.</div>
                        </div>
                      </div>
                      <div className="af-class-product__additional_info-wrap">
                        <div className="af-class-product__additional_block">
                          <div className="af-class-product__additional_text"><span className="af-class-text-span">Экспресс-доставка</span> за 2 часа сегодня.</div>
                          <div className="af-class-question_btn">
                            <div className="af-class-availability__tooltip_block">
                              <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                            </div>
                          </div>
                        </div>
                        <div className="af-class-product__additional_block">
                          <div className="af-class-product__additional_text"><span className="af-class-text-span">Доставка завтра</span> бесплатно.</div>
                          <div className="af-class-question_btn">
                            <div className="af-class-availability__tooltip_block">
                              <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                            </div>
                          </div>
                        </div>
                        <div className="af-class-product__additional_block">
                          <div className="af-class-product__additional_text"><span className="af-class-text-span">Доступно сейчас</span> в 3-х кофейнях.</div>
                          <div className="af-class-question_btn">
                            <div className="af-class-availability__tooltip_block">
                              <div className="af-class-tooltip__text">На интенсивность горечи во вкусе кофе влияет концентрация фенольных соединений в нем.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div bind="e3581a72-13ad-5297-dc34-13da6a662f38">
                        <form action="#" data-node-type="commerce-add-to-cart-form" bind="e3581a72-13ad-5297-dc34-13da6a662f39" template-bind="e3581a72-13ad-5297-dc34-13da6a662f39" position-bind-position="prepend" className="w-commerce-commerceaddtocartform"><label htmlFor="quantity-bec4348044589597786937fe0f888038" position-id="e3581a72-13ad-5297-dc34-13da6a662f41" bind="e3581a72-13ad-5297-dc34-13da6a662f41">Quantity</label><input type="number" pattern="^[0-9]+$" inputMode="numeric" id="quantity-bec4348044589597786937fe0f888038" name="commerce-add-to-cart-quantity-input" min={1} position-id="e3581a72-13ad-5297-dc34-13da6a662f43" bind="e3581a72-13ad-5297-dc34-13da6a662f43" className="w-commerce-commerceaddtocartquantityinput" defaultValue={1} /><input type="submit" position-id="e3581a72-13ad-5297-dc34-13da6a662f44" data-node-type="commerce-add-to-cart-button" data-loading-text="Adding to cart..." defaultValue="Add to Cart" aria-busy="false" aria-haspopup="dialog" className="w-commerce-commerceaddtocartbutton" />
                          <a data-node-type="commerce-buy-now-button" data-default-text="Buy now" data-subscription-text="Subscribe now" aria-busy="false" aria-haspopup="false" className="w-commerce-commercebuynowbutton" href="checkout.html">Buy now</a>
                        </form>
                        <div tabIndex={0} bind="e3581a72-13ad-5297-dc34-13da6a662f46" style={{display: 'none'}} className="w-commerce-commerceaddtocartoutofstock">
                          <div>This product is out of stock.</div>
                        </div>
                        <div aria-live="assertive" bind="e3581a72-13ad-5297-dc34-13da6a662f49" data-node-type="commerce-add-to-cart-error" style={{display: 'none'}} className="w-commerce-commerceaddtocarterror">
                          <div bind="e3581a72-13ad-5297-dc34-13da6a662f4a" data-node-type="commerce-add-to-cart-error" data-w-add-to-cart-quantity-error="Product is not available in this quantity." data-w-add-to-cart-general-error="Something went wrong when adding this item to the cart." data-w-add-to-cart-mixed-cart-error="You can’t purchase another product with a subscription." data-w-add-to-cart-buy-now-error="Something went wrong when trying to purchase this item." data-w-add-to-cart-checkout-disabled-error="Checkout is disabled on this site." data-w-add-to-cart-select-all-options-error="Please select an option in each set.">Product is not available in this quantity.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
                <div className="af-class-container w-container">
                  <div className="af-class-content">
                    <div className="af-class-content__left-block">
                      <h2 className="af-class-h2">Особый способ выращивания</h2>
                    </div>
                    <div className="af-class-content__right-block af-class-special-way__section">
                      <p bind="4bc03ed7-310c-1251-2dda-ba348eb2a5ef" className="af-class-paragraph_2size" />
                      <div className="af-class-special-way__paragraph-wrap">
                        <p className="af-class-paragraph">Основные разновидности кофе из Кении это &nbsp;SL-34, SL-28.Это экспериментальные сорта выведенные лабораторией Scott Laboratories (SL) Регионы выращивания и приходится на центральную часть страны, где сосредоточены красные нитисоловые почвы.</p>
                        <p className="af-class-paragraph">Средняя высота произрастания кофе варьируется от 1200-2300 м над уровнем моря. Сбор урожая делится на 2 части: основной урожай (октябрь – декабрь) и дополнительный урожай (июнь-август).</p>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-special-way__image-wrap"><img src loading="lazy" bind="4bc03ed7-310c-1251-2dda-ba348eb2a5f7" alt="" className="af-class-special-way__image af-class-an-image--scroll-into-view" /></div>
                </div>
              </div>
              <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
                <div className="af-class-container w-container">
                  <div className="af-class-content">
                    <div className="af-class-content__left-block">
                      <h2 className="af-class-h2">Как правильно готовить кофе</h2>
                      <div className="af-class-step__tabs-links_wrap">
                        <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--1">Воронка</a>
                        <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--2">Турка</a>
                        <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--3">Чашка</a>
                        <a href="#" className="af-class-step__tab-link af-class-an-tabs__tab--4">Кофеварка</a>
                      </div>
                    </div>
                    <div className="af-class-content__right-block">
                      <div className="af-class-an-tabs__content--1">
                        <div className="af-class-splide af-class-slider2 af-class-p-product">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a60a-a11ddd02" className="af-class-splide__track">
                            <div className="af-class-splide__list">
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.1</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a62c" className="af-class-splide__embed w-embed">
                            <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-an-tabs__content--2">
                        <div className="af-class-splide af-class-slider2 af-class-p-product">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a62f-a11ddd02" className="af-class-splide__track">
                            <div className="af-class-splide__list">
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.2</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a651" className="af-class-splide__embed w-embed">
                            <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-an-tabs__content--3">
                        <div className="af-class-splide af-class-slider2 af-class-p-product">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a654-a11ddd02" className="af-class-splide__track">
                            <div className="af-class-splide__list">
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.3</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a676" className="af-class-splide__embed w-embed">
                            <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-an-tabs__content--4">
                        <div className="af-class-splide af-class-slider2 af-class-p-product">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a679-a11ddd02" className="af-class-splide__track">
                            <div className="af-class-splide__list">
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                              <div className="af-class-splide__slide af-class-p-products">
                                <div className="af-class-step_block">
                                  <div className="af-class-step__image-wrap"><img src="images/coffe-cap_1coffe-cap.jpg" loading="lazy" alt="" className="af-class-step__image af-class-an-image--scroll-into-view" /></div>
                                  <div className="af-class-step__name">Шаг 1.4</div>
                                  <div className="af-class-step__description">Смачиваем фильтр горячей водой, тем самым прогревая посуду и далее сливаем воду.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a69b" className="af-class-splide__embed w-embed">
                            <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0;\n" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
                <div className="af-class-container w-container">
                  <div className="af-class-content">
                    <div className="af-class-content__left-block">
                      <h2 className="af-class-h2">Наличие кофе в&nbsp;собственных кофейнях</h2>
                    </div>
                    <div className="af-class-content__right-block">
                      <div className="w-layout-grid af-class-availability__rows af-class-availability__lable-rows">
                        <div className="af-class-availability__lable af-class-name">Кофейня</div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6a6-a11ddd02" className="af-class-availability__lable af-class-adress">адресс</div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6a8-a11ddd02" className="af-class-availability__lable af-class-availability">Наличие</div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6aa-a11ddd02" className="af-class-availability__lable af-class-work_hours">Часы работы</div>
                      </div>
                      <div className="w-layout-grid af-class-availability__rows">
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6ad-a11ddd02" className="af-class-coffee-house__name-block">
                          <div className="af-class-coffee-house__name">Rockets Concept&nbsp;Store</div>
                          <div className="af-class-question_btn">
                            <div className="af-class-availability__tooltip_block">
                              <div className="af-class-availability__info-text af-class-tooltip__availability-adress">ул. Земляной Вал, 18/22, Москва</div>
                              <div className="af-class-availability__info-text af-class-tooltip__availability-time">Пн-Вс <span className="af-class-availability__info-time">9.00</span>&nbsp;до&nbsp;<span className="af-class-availability__info-time">21.00</span></div>
                            </div>
                          </div>
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6bb-a11ddd02">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6bc-a11ddd02" className="af-class-availability__count">10 шт</div>
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6be-a11ddd02">
                          <a id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6bf-a11ddd02" href="#" className="af-class-availability__reserve-btn">Забронировать</a>
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6c1-a11ddd02">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6c2-a11ddd02" className="af-class-availability__info-text">ул. Земляной Вал, 18/22, Москва</div>
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6c4-a11ddd02">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6c5-a11ddd02" className="af-class-availability__info-text">Пн-Вс <span className="af-class-availability__info-time">9.00</span>&nbsp;-&nbsp;<span className="af-class-availability__info-time">21.00</span></div>
                        </div>
                      </div>
                      <div className="w-layout-grid af-class-availability__rows">
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6cd-a11ddd02" className="af-class-coffee-house__name-block-copy">
                          <div className="af-class-coffee-house__name">Cappuccino Kids</div>
                          <div className="af-class-question_btn" />
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6d1-a11ddd02">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6d2-a11ddd02" className="af-class-availability__info-text-copy">ул. Валовая, 35, Москва</div>
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6d4-a11ddd02">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6d5-a11ddd02" className="af-class-availability__count-copy">10 шт</div>
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6d7-a11ddd02">
                          <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6d8-a11ddd02" className="af-class-availability__info-text-copy">Пн-Вс <span className="af-class-availability__info-time">9.00</span>&nbsp;до&nbsp;<span className="af-class-availability__info-time">21.00</span></div>
                        </div>
                        <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6df-a11ddd02">
                          <a id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6e0-a11ddd02" href="#" className="af-class-availability__reserve-btn-copy">Забронировать</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
                <div className="af-class-container w-container">
                  <div className="af-class-content">
                    <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6e5-a11ddd02" className="af-class-content__left-block">
                      <h2 className="af-class-h2">Отзывы</h2>
                    </div>
                    <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6e8-a11ddd02" className="af-class-content__right-block">
                      <div className="af-class-rating__bock">
                        <div className="af-class-rating__stars-bock"><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-rating__star-image" /></div>
                        <div className="af-class-rating__result">4.5</div>
                      </div>
                      <div className="af-class-comments__wrap">
                        <div className="af-class-comment__block">
                          <div className="af-class-comment__user_block">
                            <div className="af-class-user__info-block"><img src="images/avatar_1avatar.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                              <div className="af-class-user__info-text__block">
                                <div className="af-class-user__info-text"><span className="af-class-user__name">Дмитрий Иванов</span> (<span className="af-class-user-city">Москва</span>)</div>
                                <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a6ff-a11ddd02" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a701-a11ddd02" className="af-class-rating__stars-bock"><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-blue.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                              </div>
                            </div>
                          </div>
                          <div className="af-class-user__flavor-block">
                            <div className="af-class-flavor-lable">Профиль вкуса</div>
                            <div className="af-class-flavors__wrap">
                              <a href="#" className="af-class-flavor__tag">Яблоко</a>
                              <a href="#" className="af-class-flavor__tag">Малина</a>
                            </div>
                          </div>
                          <div className="af-class-user__comment">По первым двум заказам магазин производит очень приятное впечатление - удобный и понятный выбор кофе на сайте, без волокиты передача в доставку, большое спасибо.</div>
                        </div>
                        <div className="af-class-comment__block">
                          <div className="af-class-comment__user_block">
                            <div className="af-class-user__info-block"><img src="images/avatar-1_1avatar-1.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                              <div className="af-class-user__info-text__block">
                                <div className="af-class-user__info-text"><span className="af-class-user__name">Мария Смирнова</span> (<span className="af-class-user-city">Москва</span>)</div>
                                <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a71d-a11ddd02" className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a723-a11ddd02" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                              </div>
                            </div>
                          </div>
                          <div className="af-class-user__flavor-block">
                            <div className="af-class-flavor-lable">Профиль вкуса</div>
                            <div className="af-class-flavors__wrap">
                              <a href="#" className="af-class-flavor__tag">Апельсин</a>
                              <a href="#" className="af-class-flavor__tag">Яблоко</a>
                              <a href="#" className="af-class-flavor__tag">Малина</a>
                            </div>
                          </div>
                          <div className="af-class-user__comment">Лучший кофе в моей жизни! Теперь я постоянный покупатель. Также хочу отметить удобный сайт, все понятно и информативно.</div>
                        </div>
                      </div>
                      <a data-w-id="4bc03ed7-310c-1251-2dda-ba348eb2a731" href="#" className="af-class-button w-button">Оставить отзыв</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-an-scroll-into-view af-class-wf-section">
                <div className="af-class-container w-container">
                  <div className="af-class-content">
                    <div className="af-class-content__left-block">
                      <h2 className="af-class-h2">Культура кофе</h2>
                      <div className="af-class-step__tabs-links_wrap">
                        <a data-w-id="4bc03ed7-310c-1251-2dda-ba348eb2a73a" href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--1">Начинающим</a>
                        <a data-w-id="4bc03ed7-310c-1251-2dda-ba348eb2a73c" href="#" className="af-class-step__tab-link af-class-an-tabs--2__tab--2">Профессионалам</a>
                      </div>
                    </div>
                    <div className="af-class-content__right-block af-class-culture">
                      <div className="af-class-an-tabs--2__content--1">
                        <div className="af-class-coffee-culture__container">
                          <div className="af-class-coffee-culure__wrapper">
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture04_1coffe-culture04.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Строение кофейной ягоды</div>
                            </a>
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture02_1coffe-culture02.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Кофейный словарь</div>
                            </a>
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture01_1coffe-culture01.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Что значит мытая или сухая обработка в описании кофе</div>
                            </a>
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture03_1coffe-culture03.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Арабика и робуста</div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-an-tabs--2__content--2">
                        <div className="af-class-coffee-culture__container">
                          <div className="af-class-coffee-culure__wrapper">
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture03_1coffe-culture03.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Арабика и робуста</div>
                            </a>
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture02_1coffe-culture02.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Кофейный словарь</div>
                            </a>
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture04_1coffe-culture04.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Строение кофейной ягоды</div>
                            </a>
                            <a href="#" className="af-class-culture-card w-inline-block">
                              <div className="af-class-culture-card__image-wrapper"><img src="images/coffe-culture01_1coffe-culture01.jpg" loading="lazy" alt="" className="af-class-culture-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-text-block">Что значит мытая или сухая обработка в описании кофе</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-recently_viewed af-class-an-scroll-into-view af-class-wf-section">
                <div className="af-class-container w-container">
                  <div className="af-class-recently__content-wrap">
                    <h2 className="af-class-recently__title">недавно просмотренное</h2>
                    <div className="af-class-splide af-class-slider3">
                      <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a773-a11ddd02" className="af-class-splide__arrows af-class-p-product">
                        <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a774" id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a774-a11ddd02" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--prev" /></div>
                        <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a775" id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a775-a11ddd02" className="af-class-splide__embed__arrow w-embed"><button className="af-class-splide__arrow af-class-splide__arrow--next" /></div>
                      </div>
                      <div id="w-node-_4bc03ed7-310c-1251-2dda-ba348eb2a776-a11ddd02" className="af-class-splide__track">
                        <div className="af-class-splide__list">
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                          <div className="af-class-splide__slide af-class-p-product">
                            <div bind="ae40be60-5fc6-e445-46f3-5c7c2005a94a" className="af-class-card-catalog af-class-div-block-6">
                              <div className="af-class-products-card__topbar">
                                <div className="af-class-badge-card-p_catalog">New</div>
                                <div className="af-class-products-card__favorites-icon-wrapper"><img src="images/heart-empty.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--unchecked" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-products-card__favorites-icon--checked" /></div>
                              </div>
                              <div className="af-class-products-card__image-wrapper"><img src="images/rocket-coffe-pack02.png" loading="lazy" sizes="(max-width: 479px) 230px, (max-width: 767px) 240px, (max-width: 991px) 30vw, (max-width: 1439px) 29vw, 28vw" srcSet="images/rocket-coffe-pack02-p-500.png 500w, images/rocket-coffe-pack02.png 596w" alt="" className="af-class-products-card__image af-class-an-image--scroll-into-view" /></div>
                              <div className="af-class-products-card__name">Honduras Luis Castillo</div>
                              <div className="af-class-discription-card-p_catalog">Cушеный банан, кокос, зеленое яблоко, ваниль, карамель, цедра апельсина.</div>
                              <div className="af-class-specifications-card-p_catalog">
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Горечь</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Сладость</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                                <div className="af-class-specifications__block">
                                  <div className="af-class-product-card__specifications__lable">Кислотность</div>
                                  <div className="af-class-specifications__indicators">
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator af-class-indicator__active" />
                                    <div className="af-class-product-card__specifications-indicator" />
                                  </div>
                                </div>
                              </div>
                              <div className="af-class-options-wrapper-card-p_catalog">
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">250 г</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a978-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">500 г</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">1 кг</a>
                                    </div>
                                  </nav>
                                </div>
                                <div data-hover="false" data-delay={0} className="af-class-button-options-card-p_catalog w-dropdown">
                                  <div className="af-class-dropdown-toggle w-dropdown-toggle">
                                    <div className="af-class-text-button-card-p_catalog">В зернах</div><img src="images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
                                  </div>
                                  <nav className="w-dropdown-list">
                                    <div className="af-class-dropdown-list af-class-type">
                                      <a href="#" id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a983-2005a94a" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под американ экспресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под аэропресс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под воронку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под гейзер</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под кемекс</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под турку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под фильтр</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под френч пресс чашку</a>
                                      <a href="#" className="af-class-text-dropdown-grey w-dropdown-link">Молотый под эспрессо</a>
                                    </div>
                                  </nav>
                                </div>
                                <div id="w-node-ae40be60-5fc6-e445-46f3-5c7c2005a995-2005a94a" className="af-class-button-options-card-p_catalog af-class-quantity">
                                  <div className="af-class-cart__summary-item-quantity-button--minus">–</div>
                                  <div className="af-class-text-button-option_add-card">1</div>
                                  <div className="af-class-plus-buttonoption-card">+</div>
                                </div>
                              </div>
                              <a href="#" className="af-class-button-card-p_catalog w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                              </a>
                              <a href="#" className="af-class-button-card-p_catalog af-class-with_old_price af-class-display-none w-inline-block">
                                <div className="af-class-pc__btn-text">Купить за <span className="af-class-pc__btn-price">670 ₽</span> </div>
                                <div className="af-class-price-old-text-button-card">800₽</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div bind="4bc03ed7-310c-1251-2dda-ba348eb2a788" className="af-class-splide__embed w-embed">
                        <style dangerouslySetInnerHTML={{__html: "\n\t.af-view .af-class-splide button:disabled {\n  \topacity: 0.15;\n" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-insta-section af-class-an-scroll-into-view af-class-wf-section">
                <div className="af-class-container w-container">
                  <div bind="fe6a81d4-0a4d-733f-eaea-52094aa78d19" className="af-class-insta__content-wrap">
                    <h2 className="af-class-h2">
                      <a href="#" className="af-class-link">@rockets.roasters</a>
                    </h2>
                    <div className="w-layout-grid af-class-insta-blocks__wrap">
                      <div className="af-class-insta-block"><img src="images/ista-post03_1ista-post03.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post03_1-p-500.jpeg 500w, images/ista-post03_1ista-post03.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                      <div className="af-class-insta-block"><img src="images/ista-post01.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post01-p-500.jpeg 500w, images/ista-post01.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                      <div className="af-class-insta-block"><img src="images/ista-post04_1ista-post04.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post04_1-p-500.jpeg 500w, images/ista-post04_1ista-post04.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                      <div className="af-class-insta-block"><img src="images/ista-post02_1ista-post02.jpg" loading="lazy" sizes="(max-width: 767px) 96vw, (max-width: 991px) 98vw, (max-width: 1279px) 95vw, (max-width: 1439px) 94vw, 93vw" srcSet="images/ista-post02_1-p-500.jpeg 500w, images/ista-post02_1ista-post02.jpg 720w" alt="" className="af-class-insta-block__img af-class-an-image--scroll-into-view" /></div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default DetailProductView

/* eslint-enable */