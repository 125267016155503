import { useState, useContext, useEffect } from "react";
import { CartFormContext } from "../../../contexts/CartFormContext";
import { Map, Placemark, Clusterer, YMaps, useYMaps } from "@pbe/react-yandex-maps";

const CartFormPopupPvzCoffeeShops = () => {
  const cartFormContext = useContext(CartFormContext);
  const ymaps = useYMaps(['templateLayoutFactory']);

  const [options, setOptions] = useState([
    {
      name: 'Rockets concept store (ул. Валовая, 35Б, м. Добрынинская)',
      location: {
        latitude: 55.729037,
        longitude: 37.626547,
      },
    },
    {
      name: 'Cappuccino Kids, «Москино Салют» (ул. Кедрова, 14к3, м. Академическая)',
      location: {
        latitude: 55.682818,
        longitude: 37.571309,
      }
    },
    {
      name: 'Кофейня Cappuccino Kids, «РТИ» (ул. Электрозаводская, 27с9, м. Электрозаводская)',
      location: {
        latitude: 55.791349,
        longitude: 37.707036,
      }
    },
    {
      name: 'rockets.coffee shop (ул. Авиаконструктора Миля, 3А, м. Жулебино)',
      location: {
        latitude: 55.686787,
        longitude: 37.851943,
      }
    }
  ]);

  useEffect(() => {
    const today = new Date();
    if ((today.getMonth() == 11 && today.getDate() >= 30) || (today.getMonth() == 0 && today.getDate() <= 10)) {
      setOptions(options.filter((elem) => elem.name !== 'Кофейня Cappuccino Kids, «РТИ» (ул. Электрозаводская, 27с9, м. Электрозаводская)'));
    }
  }, []);

  const handleChange = (value) => {
    cartFormContext.setDeliveryCoffeeshop({
      'selected': value,
    });
    
    // When coffee shop is selected, delivery cost is set to zero
    cartFormContext.setDeliveryCost(0);
    cartFormContext.setDeliveryDeadline('');
  };

  return (
    <>
      { (ymaps && ymaps.templateLayoutFactory) && <div
        style={{
          display: cartFormContext.popup == "coffeeshopspvz" ? "flex" : "none",
          zIndex: "2147483647",
        }}
        className="af-class-cart-popup-wrapper af-class-an-pickup-points-on-the-map"
      >
        <div className="af-class-cart-popup-form w-form">
          <a
            href="#"
            className="af-class-cart-popup-close-button w-inline-block"
            onClick={() => cartFormContext.setPopup(null)}
          >
            <img
              src="images/icon--close-popup.svg"
              loading="lazy"
              alt=""
              className="af-class-cart-popup-close-button-icon"
            />
          </a>
          <div
            className="af-class-cart-popup af-class-pickup-points"
            style={{ paddingTop: "60px", paddingBottom: "60px" }}
          >
            <div className="af-class-cart-popup__title">Кофейни</div>
            <div className="cart-pvz-map" style={{ width: "100%", height: "500px" }}>
              <Map
                state={{
                  center: [
                    55.75322,
                    37.622513,
                  ],
                  zoom: 10,
                }}
                width="100%"
                height="500px"
              >
                <Clusterer
                  options={{
                    groupByCoordinates: false,
                    clusterIconLayout: ymaps.templateLayoutFactory.createClass(
                      '<div style="background-color: #1D71B8; border-radius: 50%; width: 54px; height: 54px; text-align: center; line-height: 54px; color: #fff; cursor: pointer;">{{ properties.geoObjects.length }}</div>'
                    ),
                    clusterIconShape: {
                      type: 'Rectangle',
                      coordinates: [[0, 0], [54, 54]],
                    },
                  }}
                >
                  {options.map((elem, index) => (
                    <Placemark
                      key={index}
                      geometry={[
                        elem.location.latitude,
                        elem.location.longitude,
                      ]}
                      properties={{
                        data: elem,
                      }}
                      options={{
                        hasBalloon: true,
                        hideIconOnBalloonOpen: false,
                        iconLayout: ymaps.templateLayoutFactory.createClass(
                          '<div style="background-color: #1D71B8; border-radius: 50%; width: 19px; height: 19px;"></div>'
                        ),
                        iconShape: {
                          type: 'Rectangle',
                          coordinates: [[0, 0], [19, 19]]
                        },
                        balloonLayout: ymaps.templateLayoutFactory.createClass(
                          '<div class="cart-pvz-map-balloon">$[[options.contentLayout observeSize minWidth=230 maxWidth=230]]</div>',
                          {
                            build: function () {
                              this.constructor.superclass.build.call(this);
                              this._$element = this.getParentElement().querySelector('.cart-pvz-map-balloon');
                              this.applyElementOffset();
                              this._$element.querySelector('.cart-pvz-map-balloon-link').addEventListener('click', this.selectLinkClick.bind(this));
                            },
                            selectLinkClick: function () {
                              handleChange(this._data.properties._data.data.name);
                              cartFormContext.setPopup(null);
                            },
                            clear: function () {
                              this.constructor.superclass.clear.call(this);
                            },
                            onSublayoutSizeChange: function () {
                              this.applyElementOffset();
                            },
                            applyElementOffset: function () {
                              this._$element.style.left = `${-15}px`;
                              this._$element.style.top = `${-(this._$element.offsetHeight + 10)}px`;
                            },
                          }
                        ),
                        balloonContentLayout: ymaps.templateLayoutFactory.createClass(
                          '<div class="cart-pvz-map-balloon-header">{{ properties.data.name }}</div>' +
                          '<div class="cart-pvz-map-balloon-line">{{ properties.data.location.address }}</div>' +
                          '<div class="cart-pvz-map-balloon-line">{{ properties.data.work_time }}</div>' +
                          '<a class="cart-pvz-map-balloon-link">Выбрать пункт самовывоза</a>'
                        ),
                      }}
                      modules={['geoObject.addon.balloon']}
                    />
                  ))}
                </Clusterer>
              </Map>
            </div>
          </div>
        </div>
        <div
          className="af-class-cart-popup__close-area"
          onClick={() => cartFormContext.setPopup(null)}
        />
      </div> }
    </>
  );
};

export default CartFormPopupPvzCoffeeShops;

// { (ymaps && ymaps.templateLayoutFactory) && <div
//   style={{
//     display: coffeeshopsPvzPopupOpened === true ? "flex" : "none",
//     zIndex: "2147483647",
//   }}
//   className="af-class-cart-popup-wrapper af-class-an-pickup-points-on-the-map"
// >
//   <div className="af-class-cart-popup-form w-form">
//     <a
//       href="#"
//       className="af-class-cart-popup-close-button w-inline-block"
//       onClick={() => setCoffeeshopsPvzPopupOpened(false)}
//     >
//       <img
//         src="images/icon--close-popup.svg"
//         loading="lazy"
//         alt=""
//         className="af-class-cart-popup-close-button-icon"
//       />
//     </a>
//     <div
//       className="af-class-cart-popup af-class-pickup-points"
//       style={{ paddingTop: "60px", paddingBottom: "60px" }}
//     >
//       <div className="af-class-cart-popup__title">Кофейни</div>
//       <div className="cart-pvz-map" style={{ width: "100%", height: "500px" }}>
//         <Map
//           state={{
//             center: [
//               55.75322,
//               37.622513,
//             ],
//             zoom: 10,
//           }}
//           width="100%"
//           height="500px"
//         >
//           <Clusterer
//             options={{
//               groupByCoordinates: false,
//               clusterIconLayout: ymaps.templateLayoutFactory.createClass(
//                 '<div style="background-color: #1D71B8; border-radius: 50%; width: 54px; height: 54px; text-align: center; line-height: 54px; color: #fff; cursor: pointer;">{{ properties.geoObjects.length }}</div>'
//               ),
//               clusterIconShape: {
//                 type: 'Rectangle',
//                 coordinates: [[0, 0], [54, 54]],
//               },
//             }}
//           >
//             {coffeeshops.map((elem, index) => (
//               <Placemark
//                 key={index}
//                 geometry={[
//                   elem.location.latitude,
//                   elem.location.longitude,
//                 ]}
//                 properties={{
//                   data: elem,
//                 }}
//                 options={{
//                   hasBalloon: true,
//                   hideIconOnBalloonOpen: false,
//                   iconLayout: ymaps.templateLayoutFactory.createClass(
//                     '<div style="background-color: #1D71B8; border-radius: 50%; width: 19px; height: 19px;"></div>'
//                   ),
//                   iconShape: {
//                     type: 'Rectangle',
//                     coordinates: [[0, 0], [19, 19]]
//                   },
//                   balloonLayout: ymaps.templateLayoutFactory.createClass(
//                     '<div class="cart-pvz-map-balloon">$[[options.contentLayout observeSize minWidth=230 maxWidth=230]]</div>',
//                     {
//                       build: function () {
//                         this.constructor.superclass.build.call(this);
//                         this._$element = this.getParentElement().querySelector('.cart-pvz-map-balloon');
//                         this.applyElementOffset();
//                         this._$element.querySelector('.cart-pvz-map-balloon-link').addEventListener('click', this.selectLinkClick.bind(this));
//                       },
//                       selectLinkClick: function () {
//                         handleChangeCoffeeShop(this._data.properties._data.data.name);
//                         setCoffeeshopsPvzPopupOpened(false);
//                       },
//                       clear: function () {
//                         this.constructor.superclass.clear.call(this);
//                       },
//                       onSublayoutSizeChange: function () {
//                         this.applyElementOffset();
//                       },
//                       applyElementOffset: function () {
//                         this._$element.style.left = `${-15}px`;
//                         this._$element.style.top = `${-(this._$element.offsetHeight + 10)}px`;
//                       },
//                     }
//                   ),
//                   balloonContentLayout: ymaps.templateLayoutFactory.createClass(
//                     '<div class="cart-pvz-map-balloon-header">{{ properties.data.name }}</div>' +
//                     '<div class="cart-pvz-map-balloon-line">{{ properties.data.location.address }}</div>' +
//                     '<div class="cart-pvz-map-balloon-line">{{ properties.data.work_time }}</div>' +
//                     '<a class="cart-pvz-map-balloon-link">Выбрать пункт самовывоза</a>'
//                   ),
//                 }}
//                 modules={['geoObject.addon.balloon']}
//                 // onClick={() => {
//                 //   handleChangeCurrentPvz(elem.location.address);
//                 //   handleChangeCurrentPvzDetails(elem);
//                 //   setPvzPopupOpened(false);
//                 // }}
//               />
//             ))}
//           </Clusterer>
//         </Map>
//       </div>
//     </div>
//   </div>
//   <div
//     className="af-class-cart-popup__close-area"
//     onClick={() => setCoffeeshopsPvzPopupOpened(false)}
//   />
// </div> }