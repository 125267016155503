import { useState, useEffect, useContext } from 'react';
import MaskInput from 'react-maskinput';
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import useCart from "../../../hooks/useCart.js";

const CartFormFieldPhone = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);
  const { customerData, setCustomerData, setCart, setCartOrderId } = useCart();

  const [dropdown, setDropdown] = useState(false);
  const [mask, setMask] = useState('000 000 00 00');
  const [regExp, setRegExp] = useState(/[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}/);

  const options = [
    {
      code: '+7',
      flag: 'ru',
      mask: '000 000 00 00',
      regExp: /[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+375',
      flag: 'by',
      mask: '00 000 00 00',
      regExp: /[0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+7',
      flag: 'kz',
      mask: '000 000 00 00',
      regExp: /[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+374',
      flag: 'am',
      mask: '00 000 00 00',
      regExp: /[0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+996',
      flag: 'kg',
      mask: '000 00 00 00',
      regExp: /[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{2}/,
    },
    // {
    //   code: '+995',
    //   flag: 'ge',
    //   mask: '000 00 00 00',
    //   regExp: /[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{2}/,
    // }
  ];

  const handlePhoneCode = (code, flag) => {
    setDropdown(false);
    const option = options.find(option => option.code === code);
    if (option) {
      setMask(option.mask);
      setRegExp(option.regExp);
    }
    cartFormContext.setPhone('');
    cartFormContext.setPhoneCode(code);
    cartFormContext.setPhoneFlag(flag);
  }

  const handleValidate = (e) => {
    if (!e.target.value?.match(regExp)) {
      cartFormContext.setPhoneError('error');
      cartFormContext.setPhoneValid(false);
    } else {
      cartFormContext.setPhoneError(null);
      cartFormContext.setPhoneValid(true);
    }
  };

  // This is for when the component mounts and gets populated with the values from the localStorage
  useEffect(() => {
    let option, splitPhoneCode, splitPhoneRest = null;
    if (mainContext.userData?.billing?.phone) {
      [splitPhoneCode, ...splitPhoneRest] = mainContext.userData.billing.phone.split(' ');
      option = options.find(option => option.code === splitPhoneCode);
    }
    
    if (option) {
      cartFormContext.setPhoneCode(option.code);
      cartFormContext.setPhoneFlag(option.flag);
      cartFormContext.setPhone(splitPhoneRest.join(' '));

      setMask(option.mask);
      setRegExp(option.regExp);
    } else {
      cartFormContext.setPhone(customerData.formPhone);

      if (customerData.formPhoneCode && customerData.formPhoneFlag) {
        cartFormContext.setPhoneCode(customerData.formPhoneCode);
        cartFormContext.setPhoneFlag(customerData.formPhoneFlag);
      } else {
        cartFormContext.setPhoneCode('+7');
        cartFormContext.setPhoneFlag('ru');
      }

      option = options.find(option => option.code === customerData.formPhoneCode);
      if (option) {
        setMask(option.mask);
        setRegExp(option.regExp);
      }
    }
  }, [mainContext.userData]);

  useEffect(() => {
    handleValidate({ target: { value: cartFormContext.phone } });
  }, [regExp, cartFormContext.phone]);

  const handleChange = (e) => {
    cartFormContext.setPhone(e.target.value);
    // handleValidate(e);
  };

  return (
    <div className="af-class-cart__user-field-wrapper af-class-cart__user-field-wrapper-phone">
      <div className={`
        af-class-cart__user-field-label
        ${cartFormContext.phone?.length && ' af-class-cart__user-field-label-active'}
      `}>
        Телефон
      </div>
      <div
        className={`
          af-class-cart__user-field-phone-country-selected
          ${cartFormContext.phoneError ? ' user-input-invalid' : ''}
        `}
        onClick={() => setDropdown(!dropdown)}
      >
        <img src={`/images/flags/${cartFormContext.phoneFlag}.svg`} width="16" alt="" />
        <div>{cartFormContext.phoneCode}</div>
        <img src="/images/arrow-down.svg" width="9" alt="" className="af-class-header__dropdown-icon--options" />
      </div>
      <div className={`
        af-class-cart__user-field-phone-country-dropdown
        ${dropdown === true ? ' af-class-cart__user-field-phone-country-dropdown-active' : ''}
      `}>
        {options.map((option, index) => (
          <div className="af-class-cart__user-field-phone-country-option" onClick={() => handlePhoneCode(option.code, option.flag)} key={index}>
            <img src={`/images/flags/${option.flag}.svg`} width="16" alt="" />
            <div>{option.code}</div>
          </div>
        ))}
      </div>
      <MaskInput
        showMask
        alwaysShowMask
        mask={mask}
        size={20}
        maskChar="_"
        value={cartFormContext.phone ? cartFormContext.phone : ''}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleValidate(e)}
        // onClick={(e) => {
        //   let currentValue = cartFormContext.phone ? cartFormContext.phone.replaceAll('_', '').trim() : '';
        //   e.target.setSelectionRange(currentValue.length, currentValue.length);
        // }}
        placeholder="Телефон"
        type="text"
        className={`
          af-class-cart__user-field af-class-cart__user-field-phone w-input
          ${cartFormContext.phoneError ? ' user-input-invalid' : ''}
        `}
      />
    </div>
  );
}

export default CartFormFieldPhone