/* eslint-disable */

import React from 'react'
import { useNavigate } from 'react-router-dom'

export const NotFoundView = () => {

  const navigate = useNavigate()

  return <div className="af-class-section af-class-four-oh-four af-class-wf-section">
      <div className="af-class-container w-container">
        <div className="af-class-four-oh-four-wrapper">
          <div className="af-class-four-oh-four-title">404</div>
          <div className="af-class-four-oh-four-text">Кажется, страницы которую вы ищете не существует</div>
          <a href="#" onClick={() => navigate(`/`)} className="af-class-button--primary af-class-four-oh-four w-button">Перейти на главную страницу</a>
        </div>
      </div>
    </div>
}

export default NotFoundView

/* eslint-enable */