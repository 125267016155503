export default function FilterFieldPellet({ filters, filter, toggleFilter }) {
  const active = filters.some(f => f.option === filter.option);

  return <div
    onClick={(e) => {
      e.preventDefault();
      toggleFilter(filter);
    }}
    style={{
      display: 'flex',
      gap: '10px',
      padding: '8px 16px',
      backgroundColor: '#fff',
      borderRadius: '50px',
      cursor: 'pointer',
      border: active ? '1px #1D71B8 solid' : '1px #E0E0E0 solid',
      color: active ? '#1D71B8' : '#000',
    }}
  >
    <div>{filter?.text ? filter.text : filter.option}</div>
    {active && <div style={{ display: 'flex', padding: '4px', width: '15px', height: '15px', borderRadius: '15px', backgroundColor: '#1D71B8', marginTop: '3px' }}><img src="/images/icon--close-popup.svg" style={{ width: '7px', height: '7px', filter: 'invert(1)' }} /></div>}
  </div>;
}