/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { fetchPage, fetchMedias } from '../fetch/WPAPI'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import CoffeeShopsFiltered from '../components/CoffeeShopsFiltered/CoffeeShopsFiltered'
import { shops } from '../store/store'

const CoffeeShopsView = () => {
  
  const [ activeTab, setActiveTab ] = useState('all')
  const [ presenseOpen, setPresenseOpen ] = useState(false)

  const [ pageContent, setPageContent ] = useState({})
  const [ pageImages, setPageImages ] = useState([])

  useEffect(() => {
    fetchPage(16079)
      .then(result => {
        console.log(result)
        setPageContent(result)

        fetchMedias(result?.acf?.koffeini?.map(elem => elem.foto))
          .then(result => {
            console.log(result)
            setPageImages(result)
          })
      })
  }, [])

  useEffect(() => {
    document.title = pageContent?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", pageContent?.yoast_head_json?.description)
  }, [pageContent])

  const changeActiveShops = (activeTab) => {
    setActiveTab(prevState => activeTab)
  }

  const togglePresenseOpen = () => {
    setPresenseOpen(prevState => !prevState)
  }

  return <div className="af-class-section af-class-p-coffee-shops af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="w-layout-grid af-class-content--p-coffee-shops">
                  <div id="w-node-_51c9ffb8-6172-021a-5690-7c26113bd6ba-343d3632" className="w-layout-grid af-class-filter--p-coffee-shops af-class-an-load--2">
                    <h1 id="w-node-_3e615c57-f6b0-a8b4-98a1-be33fcebe486-343d3632" className="af-class-main-title--p-coffee-shops">Кофейни</h1>
                    <div id="w-node-ffb2302b-6670-1d02-ad2d-0b4d5daa02f6-343d3632" className="af-class-filter__coffee-shop-type w-form">
                      <span className={`af-class-coffee-shop__radio-button-label w-form-label ${activeTab == 'concept' ? "active": undefined}`} onClick={() => changeActiveShops('concept')}>Rockets Concept Store</span>
                      <span className={`af-class-coffee-shop__radio-button-label w-form-label ${activeTab == 'all' ? "active": undefined}`} onClick={() => changeActiveShops('all')}>Все кофейни</span>
                      <span className={`af-class-coffee-shop__radio-button-label w-form-label ${activeTab == 'withFood' ? "active": undefined}`} onClick={() => changeActiveShops('withFood')}>Кофе с едой</span>
                      <span className={`af-class-coffee-shop__radio-button-label w-form-label ${activeTab == 'kids' ? "active": undefined}`} onClick={() => changeActiveShops('kids')}>Cappuccino Kids</span>
                    </div>
                    {/* <div id="w-node-_962d9419-6481-aeed-0f07-d09bf15397ab-343d3632" className="w-layout-grid af-class-filter__cofee-type">
                      <div id="w-node-afe5cb7e-280d-442c-1f68-63e8de088f5c-343d3632" data-w-id="afe5cb7e-280d-442c-1f68-63e8de088f5c" className="w-layout-grid af-class-cofee-type__button" onClick={() => togglePresenseOpen()} style={{cursor: 'pointer'}}>
                        <div id="w-node-_69af6f98-6fd7-d436-c395-9a9d429aca47-343d3632" className={`af-class-cofee-type__button-text ${presenseOpen ? 'active': undefined}`}>Наличие кофе</div><img src="images/arrow--p-coffee-shops.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} id="w-node-_510cfc99-26c2-a39b-2c72-6348a4245241-343d3632" alt="" className="af-class-cofee-type__button-icon" />
                      </div>
                      <div id="w-node-a17fc716-c71a-26c2-5669-b528d1bc13e0-343d3632" style={presenseOpen ? {height: '100%'}: {height: '0%'}} className="af-class-coffee-type__wrapper w-form">
                        <form action="#" id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" className="af-class-coffee-type__list"><label id="w-node-_664cc889-4e9c-e56d-c6dc-29d2abcee7a3-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Honduras Luis Castillo</span>
                          </label><label id="w-node-_3c325b23-ed29-ac2e-37d0-92bdd94a69cc-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Colombia La Pradera</span>
                          </label><label id="w-node-_086b822f-69ee-c0c4-3ffe-c16e7cfcf067-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Colombia Sanjuanero Decaf</span>
                          </label><label id="w-node-_00152cd0-cba6-459b-d440-b321cffe87ee-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Ethiopia Guji Adola</span>
                          </label><label id="w-node-_092bfb14-909b-6ee1-8257-14ef883c35c2-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Colombia La Pradera</span>
                          </label><label id="w-node-_7715e6ed-4e61-f708-6382-22be431bac60-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Honduras Luis Castillo</span>
                          </label><label id="w-node-_0df4ade2-15c4-7fe7-aa65-5d8c65444c1c-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Espresso Ethiopia Haro Welabu</span>
                          </label><label id="w-node-_0de97d3b-9e87-1f69-4fec-1346bf7cd366-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Ethiopia Guji Adola</span>
                          </label><label id="w-node-_9867c814-0fa8-4aef-662d-4a272e5ec81a-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Espresso #3</span>
                          </label><label id="w-node-_2dd08567-70a2-9eaa-2a57-81cd18e08a14-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Honduras Luis Castillo</span>
                          </label><label id="w-node-_8cc999cb-e07c-4728-7088-7f50deda3aa1-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Ethiopia Guji Adola</span>
                          </label><label id="w-node-_972e2e4e-722f-84d3-bbc1-ceae318f4a3f-343d3632" className="af-class-coffee-type__radio-button w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom af-class-coffee-type__radio-button-icon w-radio-input" /><input type="radio" data-name="Radio 4" id="radio-4" name="radio-4" defaultValue="Radio 4" style={{opacity: 0, position: 'absolute', zIndex: -1}} /><span className="af-class-coffee-type__radio-button-label w-form-label" htmlFor="radio-4">Colombia La Pradera</span>
                          </label>
                        </form>
                      </div>
                    </div> */}
                  </div>
                  <CoffeeShopsFiltered activeTab={activeTab} shops={pageContent?.acf?.koffeini} images={pageImages} />
                </div>
              </div>
            </div>

}

export default CoffeeShopsView

/* eslint-enable */