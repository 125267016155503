import { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";

const CartFormPopupAddresses = ({ opened, setOpened }) => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);

  const [userAddressSelected, setUserAddressSelected] = useState(0);

  useEffect(() => {
    try {
      let userAddresses = false;
      try {
        userAddresses = JSON.parse(mainContext.userWpData?.acf?.адреса);
      } catch {}

      if (userAddresses !== false) {
        mainContext.setUserAddresses(userAddresses);
      }
    } catch {}
  }, [mainContext.userWpData]);

  const handleUserAddressSelect = () => {
    let address = mainContext.userAddresses.find((elem) => elem.id == userAddressSelected);
    cartFormContext.setAddressData({
      ...cartFormContext.addressData,
      ...address,
    });
    cartFormContext.setPopup(null);
  };

  return (
    <div
      style={{
        display: cartFormContext.popup == "addresses" ? "flex" : "none",
        zIndex: "2147483647",
      }}
      className="af-class-cart-popup-wrapper af-class-an-change-shipping-adress"
    >
      <div className="af-class-cart-popup-form w-form">
        <a
          href="#"
          className="af-class-cart-popup-close-button w-inline-block"
          onClick={() => cartFormContext.setPopup(null)}
        >
          <img
            src="images/icon--close-popup.svg"
            loading="lazy"
            alt=""
            className="af-class-cart-popup-close-button-icon"
          />
        </a>
        <div className="af-class-cart-popup">
          <div className="af-class-cart-popup__title">
            Выберите адрес доставки
          </div>
          <div className="w-layout-grid af-class-cart-popup__adress-list">
            {mainContext.userAddresses.map((elem, index) => (
              <div
                key={elem.id}
                onClick={() => setUserAddressSelected(elem.id)}
                className="w-layout-grid af-class-cart-popup__adress-item"
                style={{ cursor: "pointer" }}
              >
                <label className="af-class-g-radio-button--no-text w-radio">
                  <div
                    className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-g-radio-button-icon--no-text w-radio-input${
                      userAddressSelected == elem.id
                        ? " w--redirected-checked"
                        : ""
                    }`}
                  />
                </label>
                <div className="w-layout-grid af-class-cart-popup__adress-content">
                  <div className="w-layout-grid af-class-cart-popup__adress-head">
                    <div className="af-class-cart-popup__adress-title">
                      { elem.name ? elem.name : `Адрес ${elem.id}` }
                    </div>
                    <div className="af-class-cart-popup__adress-default-wrapper">
                      {elem.default === true && (
                        <div className="af-class-cart-popup__adress-default-text">
                          По умолчанию
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-layout-grid af-class-cart-popup__adress-body">
                    <div className="af-class-cart-popup__adress-text">
                      {elem.full}
                    </div>
                    <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--3">
                      <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                        <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                          <div className="af-class-cart-popup__adress-text">
                            Подъезд
                          </div>
                          <div className="af-class-cart-popup__adress-text">
                            {elem.entrance}
                          </div>
                        </div>
                        <div className="af-class-cart-popup__adress-text">
                          ,
                        </div>
                      </div>
                      <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                        <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                          <div className="af-class-cart-popup__adress-text">
                            Этаж
                          </div>
                          <div className="af-class-cart-popup__adress-text">
                            {elem.floor}
                          </div>
                        </div>
                        <div className="af-class-cart-popup__adress-text">
                          ,
                        </div>
                      </div>
                      <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                        <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                          <div className="af-class-cart-popup__adress-text">
                            Квартира
                          </div>
                          <div className="af-class-cart-popup__adress-text">
                            {elem.flat}
                          </div>
                        </div>
                        <div className="af-class-cart-popup__adress-text">
                          ,
                        </div>
                      </div>
                      <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                        <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                          <div className="af-class-cart-popup__adress-text">
                            Домофон
                          </div>
                          <div className="af-class-cart-popup__adress-text">
                            {elem.homephone}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="af-class-cart-popup__submit-button-wrapper">
            <input
              onClick={handleUserAddressSelect}
              type="button"
              value="Выбрать"
              className="af-class-g-submit-button w-button"
            />
          </div>
        </div>
      </div>
      <div
        className="af-class-cart-popup__close-area"
        onClick={() => cartFormContext.setPopup(null)}
      />
    </div>
  );
};

export default CartFormPopupAddresses;

{/* <div
  style={{
    display: addressPopupOpened === true ? "flex" : "none",
    zIndex: "2147483647",
  }}
  className="af-class-cart-popup-wrapper af-class-an-change-shipping-adress"
>
  <div className="af-class-cart-popup-form w-form">
    <a
      href="#"
      className="af-class-cart-popup-close-button w-inline-block"
      onClick={() => setAddressPopupOpened(false)}
    >
      <img
        src="images/icon--close-popup.svg"
        loading="lazy"
        alt=""
        className="af-class-cart-popup-close-button-icon"
      />
    </a>
    <div className="af-class-cart-popup">
      <div className="af-class-cart-popup__title">
        Выберите адрес доставки
      </div>
      <div className="w-layout-grid af-class-cart-popup__adress-list">
        {userAddresses.map((elem, index) => (
          <div
            key={elem.id}
            onClick={() => setUserAddressSelected(elem.id)}
            className="w-layout-grid af-class-cart-popup__adress-item"
            style={{ cursor: "pointer" }}
          >
            <label className="af-class-g-radio-button--no-text w-radio">
              <div
                className={`w-form-formradioinput w-form-formradioinput--inputType-custom af-class-g-radio-button-icon--no-text w-radio-input${
                  userAddressSelected == elem.id
                    ? " w--redirected-checked"
                    : ""
                }`}
              />
            </label>
            <div className="w-layout-grid af-class-cart-popup__adress-content">
              <div className="w-layout-grid af-class-cart-popup__adress-head">
                <div className="af-class-cart-popup__adress-title">
                  { elem.name ? elem.name : `Адрес ${elem.id}` }
                </div>
                <div className="af-class-cart-popup__adress-default-wrapper">
                  {elem.default === true && (
                    <div className="af-class-cart-popup__adress-default-text">
                      По умолчанию
                    </div>
                  )}
                </div>
              </div>
              <div className="w-layout-grid af-class-cart-popup__adress-body">
                <div className="af-class-cart-popup__adress-text">
                  {elem.full}
                </div>
                <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--3">
                  <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                    <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                      <div className="af-class-cart-popup__adress-text">
                        Подъезд
                      </div>
                      <div className="af-class-cart-popup__adress-text">
                        {elem.entrance}
                      </div>
                    </div>
                    <div className="af-class-cart-popup__adress-text">
                      ,
                    </div>
                  </div>
                  <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                    <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                      <div className="af-class-cart-popup__adress-text">
                        Этаж
                      </div>
                      <div className="af-class-cart-popup__adress-text">
                        {elem.floor}
                      </div>
                    </div>
                    <div className="af-class-cart-popup__adress-text">
                      ,
                    </div>
                  </div>
                  <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                    <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                      <div className="af-class-cart-popup__adress-text">
                        Квартира
                      </div>
                      <div className="af-class-cart-popup__adress-text">
                        {elem.flat}
                      </div>
                    </div>
                    <div className="af-class-cart-popup__adress-text">
                      ,
                    </div>
                  </div>
                  <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper--2">
                    <div className="w-layout-grid af-class-cart-popup__adress-text-wrapper">
                      <div className="af-class-cart-popup__adress-text">
                        Домофон
                      </div>
                      <div className="af-class-cart-popup__adress-text">
                        {elem.homephone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="af-class-cart-popup__submit-button-wrapper">
        <input
          onClick={handleUserAddressSelect}
          type="button"
          value="Выбрать"
          className="af-class-g-submit-button w-button"
        />
      </div>
    </div>
  </div>
  <div
    className="af-class-cart-popup__close-area"
    onClick={() => setAddressPopupOpened(false)}
  />
</div> */}