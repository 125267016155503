/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { fetchPage } from '../fetch/WPAPI'

const FaqView = () => {

  const [ isLoading, setIsLoading ] = useState(true)

  const [ pageContent, setPageContent ] = useState({})
  const [ activeQuestion, setActiveQuestion ] = useState(null)

  const [ categories, setCategories ] = useState([])
  const [ activeTab, setActiveTab ] = useState(0)

  useEffect(() => {
    fetchPage(52)
      .then(result => {
        console.log(result)
        setPageContent(result)

        let categories = result?.acf?.faq.map((elem => elem.question_cat)).filter((v, i, a) => a.indexOf(v) === i)
        setCategories(categories)
        setActiveTab(categories.length > 0 ? categories[0] : 0)

        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    document.title = pageContent?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", pageContent?.yoast_head_json?.description)
  }, [pageContent])

  return <div className="af-class-section af-class-faq af-class-an-load--2 af-class-wf-section">
    <div className="af-class-container w-container">
      <h1 className="af-class-title-main-p_faq">Faq</h1>
      {/* <div dangerouslySetInnerHTML={{__html: pageContent?.content?.rendered}} /> */}

      { isLoading === true && <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div> }

      <div data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs-2 w-tabs">
        <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401047-f93d3638" className="af-class-tabs-menu-2 w-tab-menu">
          { categories.map((elem, index) => <a onClick={() => setActiveTab(elem)} key={index} className={`af-class-tab-p_faq w-inline-block w-tab-link${activeTab == elem ? ' w--current' : ''}`}>
            <div className="af-class-text-block-4">{elem}</div>
          </a>)}
        </div>

        <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401051-f93d3638" className="w-tab-content">

          { categories.map((elem, catIndex) => <div key={elem} className={`w-tab-pane${activeTab == elem ? ' w--tab-active' : ''}`}>
            <div className="af-class-faq-wrapper">
              { pageContent?.acf?.faq.map((elem, index) => activeTab != elem.question_cat ? '' : <div key={index} className="af-class-faq-item" onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}>
                <div className="af-class-faq-title">{elem.вопрос}</div>
                
                <img src="/images/faq-icon.svg" loading="lazy" style={activeQuestion === index ? {} : {WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />

                <div style={{height: activeQuestion === index ? 'auto' : '0px'}} className="af-class-rich_text-wrapper-faq">
                  <div className="af-class-rich_text-faq w-richtext">
                    {elem.ответ}
                  </div>
                </div>
              </div> ) }
            </div>
          </div> ) }

        </div>
      </div>

    </div>
  </div>

  // return <div className="af-class-section af-class-faq af-class-an-load--2 af-class-wf-section">
  //             <div className="af-class-container w-container">
  //               <h1 className="af-class-title-main-p_faq">Faq</h1>
  //               <div data-current="Tab 3" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs-2 w-tabs">
  //                 <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401047-f93d3638" className="af-class-tabs-menu-2 w-tab-menu">
  //                   <a data-w-tab="Tab 1" className="af-class-tab-p_faq w-inline-block w-tab-link">
  //                     <div className="af-class-text-block-4">Выбор кофе</div>
  //                   </a>
  //                   <a data-w-tab="Tab 2" className="af-class-tab-p_faq w-inline-block w-tab-link">
  //                     <div>Заказ</div>
  //                   </a>
  //                   <a data-w-tab="Tab 3" className="af-class-tab-p_faq w-inline-block w-tab-link w--current">
  //                     <div>О Rockets</div>
  //                   </a>
  //                 </div>

  //                 <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401051-f93d3638" className="w-tab-content">

  //                   <div data-w-tab="Tab 1" className="w-tab-pane">
  //                     <div className="af-class-faq-wrapper">
  //                       <div data-w-id="f8f3f16b-449f-09c2-e677-f531a612d557" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как заварить кофе в домашних условиях?</div>
  //                         <div id="w-node-_2e768a1e-e637-8ac7-dba2-246eb29627e4-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="c3dc2e3d-9d34-9f79-da1d-75f0d3a3c831" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как заварить кофе в домашних условиях?</div>
  //                         <div id="w-node-c3dc2e3d-9d34-9f79-da1d-75f0d3a3c834-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="94b8f80a-b0c8-c894-b232-7d7685b4db2f" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как рекомендуете хранить кофе?</div>
  //                         <div id="w-node-_94b8f80a-b0c8-c894-b232-7d7685b4db32-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="331dfa1c-fd18-1d3d-e868-26bf0f19a4df" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как рекомендуете хранить кофе?</div>
  //                         <div id="w-node-_331dfa1c-fd18-1d3d-e868-26bf0f19a4e2-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                     </div>
  //                   </div>

  //                   <div data-w-tab="Tab 2" className="w-tab-pane">
  //                     <div className="af-class-faq-wrapper">
  //                       <div data-w-id="e7f35ebb-f6ee-e69f-e786-1bfd2787dcbd" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как заварить кофе в домашних условиях?</div>
  //                         <div id="w-node-e7f35ebb-f6ee-e69f-e786-1bfd2787dcc0-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="e7f35ebb-f6ee-e69f-e786-1bfd2787dcd0" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как заварить кофе в домашних условиях?</div>
  //                         <div id="w-node-e7f35ebb-f6ee-e69f-e786-1bfd2787dcd3-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="e7f35ebb-f6ee-e69f-e786-1bfd2787dce3" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как рекомендуете хранить кофе?</div>
  //                         <div id="w-node-e7f35ebb-f6ee-e69f-e786-1bfd2787dce6-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="e7f35ebb-f6ee-e69f-e786-1bfd2787dcf6" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как рекомендуете хранить кофе?</div>
  //                         <div id="w-node-e7f35ebb-f6ee-e69f-e786-1bfd2787dcf9-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                     </div>
  //                   </div>

  //                   <div data-w-tab="Tab 3" className="w-tab-pane w--tab-active">
  //                     <div className="af-class-faq-wrapper">
  //                       <div data-w-id="adf7b4ff-92d9-ac01-a3e0-d5d24bd735b3" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как заварить кофе в домашних условиях?</div>
  //                         <div id="w-node-adf7b4ff-92d9-ac01-a3e0-d5d24bd735b6-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="adf7b4ff-92d9-ac01-a3e0-d5d24bd735c6" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как заварить кофе в домашних условиях?</div>
  //                         <div id="w-node-adf7b4ff-92d9-ac01-a3e0-d5d24bd735c9-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="adf7b4ff-92d9-ac01-a3e0-d5d24bd735d9" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как рекомендуете хранить кофе?</div>
  //                         <div id="w-node-adf7b4ff-92d9-ac01-a3e0-d5d24bd735dc-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                       <div data-w-id="adf7b4ff-92d9-ac01-a3e0-d5d24bd735ec" className="af-class-faq-item">
  //                         <div className="af-class-faq-title">Как рекомендуете хранить кофе?</div>
  //                         <div id="w-node-adf7b4ff-92d9-ac01-a3e0-d5d24bd735ef-f93d3638" style={{opacity: 0, height: 0}} className="af-class-rich_text-wrapper-faq">
  //                           <div className="af-class-rich_text-faq w-richtext">
  //                             <h4>Для начала стоит задать себе два вопроса:</h4>
  //                             <ul role="list">
  //                               <li>Какой метод заваривания я буду использовать?</li>
  //                               <li>Какой вкус я хочу получить?</li>
  //                             </ul>
  //                             <p>Для легкого выбора кофе, подходящего вашему методу заваривания, – &nbsp;воспользуйтесь фильтром на нашем сайте. Как правило, для альтернативных методов рекомендуется линейка моносортов, а для полупрофессионального оборудования и эспрессо-машин – линейка эспрессо-смесей.<br />‍<br />Для определения подходящего кофе по вкусовым параметрам – обращайте внимание на шкалу вкусов – кислотности, сладости и горечи.</p>
  //                           </div>
  //                         </div><img src="images/faq-icon.svg" loading="lazy" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} alt="" className="af-class-faq-icon" />
  //                       </div>
  //                     </div>
  //                   </div>

  //                 </div>
  //               </div>
  //             </div>
  //           </div>
}

export default FaqView

/* eslint-enable */