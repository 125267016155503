/* eslint-disable */

const styles = [
  {
    type: 'href',
    body: '/css/normalize.css',
  },
  {
    type: 'href',
    body: '/css/webflow.css',
  },
  {
    type: 'href',
    body: '/css/rockets-coffee-export.webflow.css',
  },
  {
    type: 'href',
    body: 'https://cdn.jsdelivr.net/npm/@splidejs/splide@3.2.2/dist/css/splide-core.min.css',
  },
  {
    type: 'sheet',
    body: '.af-view .af-class-splide__track{overflow:visible}',
  },
  {
    type: 'sheet',
    body: '@media (max-width:991px) and (min-width:768px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="1bf97789-6d43-8063-7cbf-fb9861530033"]{height:0}}@media (max-width:767px) and (min-width:480px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="1bf97789-6d43-8063-7cbf-fb9861530033"]{height:0}}@media (max-width:479px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="1bf97789-6d43-8063-7cbf-fb9861530033"]{height:0}}',
  },
  {
    type: 'sheet',
    body: '@media (max-width:991px) and (min-width:768px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id=a7b27637-4100-feb6-337f-6f1dfdbc3ac1]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="0c8c5548-2061-312b-6801-96116b534690"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="75e4ef52-cbf1-6e3a-bdad-0f5e1cbf0f86"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="0da966d3-5b79-b92c-e304-62e4fa280626"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="392c0fe6-fa55-99f2-1689-97444c994225"]{height:0}}@media (max-width:767px) and (min-width:480px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id=a7b27637-4100-feb6-337f-6f1dfdbc3ac1]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="0c8c5548-2061-312b-6801-96116b534690"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="75e4ef52-cbf1-6e3a-bdad-0f5e1cbf0f86"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="0da966d3-5b79-b92c-e304-62e4fa280626"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="392c0fe6-fa55-99f2-1689-97444c994225"]{height:0}}@media (max-width:479px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id=a7b27637-4100-feb6-337f-6f1dfdbc3ac1]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="0c8c5548-2061-312b-6801-96116b534690"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="75e4ef52-cbf1-6e3a-bdad-0f5e1cbf0f86"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="0da966d3-5b79-b92c-e304-62e4fa280626"]{height:0}.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="392c0fe6-fa55-99f2-1689-97444c994225"]{height:0}}',
  },
]

const loadingStyles = styles.map((style) => {
  let styleEl
  let loading

  if (style.type == 'href') {
    styleEl = document.createElement('link')

    loading = new Promise((resolve, reject) => {
      styleEl.onload = resolve
      styleEl.onerror = reject
    })

    styleEl.rel = 'stylesheet'
    styleEl.type = 'text/css'
    styleEl.href = style.body
  }
  else {
    styleEl = document.createElement('style')
    styleEl.type = 'text/css'
    styleEl.innerHTML = style.body

    loading = Promise.resolve()
  }

  document.head.appendChild(styleEl)

  return loading
})

export default Promise.all(loadingStyles).then(() => {
  const styleSheets = Array.from(document.styleSheets).filter((styleSheet) => {
    return styleSheet.href && styles.some((style) => {
      return style.type == 'href' && styleSheet.href.match(style.body)
    })
  })
  styleSheets.forEach((styleSheet) => {
    try {
      Array.from(styleSheet.rules).forEach((rule) => {
        if (rule.selectorText) {
          rule.selectorText = rule.selectorText
            .replace(/\.([\w_-]+)/g, '.af-class-$1')
            .replace(/\[class(.?)="( ?)([^"]+)( ?)"\]/g, '[class$1="$2af-class-$3$4"]')
            .replace(/([^\s][^,]*)(\s*,?)/g, '.af-view $1$2')
            .replace(/\.af-view html/g, '.af-view')
            .replace(/\.af-view body/g, '.af-view')
            .replace(/af-class-w-/g, 'w-')
            .replace(/af-class-anima-/g, 'anima-')
            .replace(/af-class-([\w_-]+)an-animation([\w_-]+)/g, '$1an-animation$2')
        }
      })
    } catch(e) {
      console.log();
    }

  })
})

/* eslint-enable */