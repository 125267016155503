/* eslint-disable */

import React from 'react'
import LoginForm from '../components/Authorization/LoginForm'
import RegistrationForm from '../components/Authorization/RegistrationForm'
import { fetchRecoveryKey, processRecoveryKey } from '../fetch/WPAPI'
import { createScope, map, transformProxies } from './helpers'
import { Link } from 'react-router-dom';

class AuthorizationView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 1,
      recoveryPopupOpened: false,
      recoveryPopupStage: 1,
      recoveryPopupEmail: '',
      recoveryPopupKey: '',
      stage1Error: '',
      stage1Success: '',
      stage2Error: '',
      stage2Success: '',
    }
    this.setActiveTab = this.setActiveTab.bind(this)
    this.openRecoveryPopup = this.openRecoveryPopup.bind(this)
    this.recoveryPopupGetKey = this.recoveryPopupGetKey.bind(this)
    this.recoveryPopupSendKey = this.recoveryPopupSendKey.bind(this)
  }

  componentDidMount() {
    document.title = 'Авторизация'
    document.querySelector('meta[name="description"]').setAttribute("content", 'Авторизация')

    let action = null
    let email = null
    let reset_key = null

    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0] == 'action') {
        action = pair[1]
      }
      if (pair[0] == 'email') {
        email = pair[1]
      }
      if (pair[0] == 'reset_key') {
        reset_key = pair[1]
      }
    }

    if (action == 'reset' && email != null && reset_key != null) {
      this.setState({ recoveryPopupOpened: true, recoveryPopupStage: 2, recoveryPopupEmail: email, recoveryPopupKey: reset_key })
      processRecoveryKey(email, reset_key)
        .then(result => {
          if (result.error === true) {
            this.setState({ stage2Error: result.message })
          } else {
            this.setState({ stage2Success: result.message })
            this.setState({ recoveryPopupStage: 3 })
          }
        })
    }
  }

  setActiveTab(tab) {
    this.setState({ tab: tab })
  }

  openRecoveryPopup() {
    this.setState({ recoveryPopupStage: 1, recoveryPopupOpened: true })
  }

  recoveryPopupGetKey(e) {
    e.preventDefault()

    fetchRecoveryKey(this.state.recoveryPopupEmail)
      .then(result => {
        if (result.error === true) {
          this.setState({ stage1Error: result.message })
        } else {
          this.setState({ stage1Success: result.message })
          this.setState({ recoveryPopupStage: 2 })
        }
      })
  }

  recoveryPopupSendKey(e) {
    e.preventDefault()

    processRecoveryKey(this.state.recoveryPopupEmail, this.state.recoveryPopupKey)
      .then(result => {
        if (result.error === true) {
          this.setState({ stage2Error: result.message })
        } else {
          this.setState({ stage2Success: result.message })
          this.setState({ recoveryPopupStage: 3 })
        }
      })
  }

  render() {

    return (
      // <span>
      //   <style dangerouslySetInnerHTML={{ __html: `
      //     @import url(/css/normalize.css);
      //     @import url(/css/webflow.css);
      //     @import url(/css/rockets-coffee-export.webflow.css);
      //   ` }} />
      //   <span className="af-view">

      <div className="af-class-body--p-authorization">
        <div style={{ display: this.state.recoveryPopupOpened === true ? 'flex' : 'none', zIndex: '2147483647' }} className="af-class-password-recovery__popup">
          <div className="af-class-password-recovery__content">
            <div className="af-class-log-in__form-block w-form">
              {this.state.recoveryPopupStage == 1 ? <form onSubmit={this.recoveryPopupGetKey} id="wf-form-Log-in-form" name="wf-form-Log-in-form" data-name="Log in form" method="get" className="af-class-log-in__form">
                <h1 className="af-class-password-recovery__title">Восстановление пароля</h1>
                {this.state.stage1Error !== '' && <div style={{ color: 'red' }}>{this.state.stage1Error}</div>}
                <div className="w-layout-grid af-class-log-in__form-grid">
                  <input type="email" className="af-class-cart__user-field w-input" maxLength={256} placeholder="Эл. почта" required value={this.state.recoveryPopupEmail} onChange={(e) => this.setState({ recoveryPopupEmail: e.target.value })} />
                </div>
                <input type="submit" value="Получить ключ" id="w-node-_3c82be28-2bbd-3573-aa34-8128ede8282e-b23d362e" className="af-class-button--primary af-class-log-in af-class-_2 w-button" />
              </form> : this.state.recoveryPopupStage == 2 ? <form onSubmit={this.recoveryPopupSendKey} id="wf-form-Log-in-form" name="wf-form-Log-in-form" data-name="Log in form" method="get" className="af-class-log-in__form">
                <h1 className="af-class-password-recovery__title">Восстановление пароля</h1>
                {this.state.stage1Success !== '' && <div style={{ color: 'green' }}>{this.state.stage1Success}</div>}
                {this.state.stage2Error !== '' && <div style={{ color: 'red' }}>{this.state.stage2Error}</div>}
                <div className="w-layout-grid af-class-log-in__form-grid">
                  <input type="text" className="af-class-cart__user-field w-input" maxLength={256} placeholder="Ключ" required value={this.state.recoveryPopupKey} onChange={(e) => this.setState({ recoveryPopupKey: e.target.value })} />
                </div>
                <input type="submit" value="Получить новый пароль" id="w-node-_3c82be28-2bbd-3573-aa34-8128ede8282e-b23d362e" className="af-class-button--primary af-class-log-in af-class-_2 w-button" />
              </form> : <div id="wf-form-Log-in-form" className="af-class-log-in__form">
                <h1 className="af-class-password-recovery__title">Восстановление пароля</h1>
                {this.state.stage2Success !== '' && <div style={{ color: 'green' }}>Ваш новый пароль: {this.state.stage2Success}</div>}
              </div>}
            </div>
            <div onClick={() => this.setState({ recoveryPopupOpened: false })} data-w-id="201e1604-74cd-f15c-bc4f-85a27e73cd54" className="af-class-password-recovery__close-button-wrapper">
              <img src="images/icon--close-popup.svg" loading="lazy" alt="" className="af-class-password-recovery__close-button" />
            </div>
          </div>
          <div onClick={() => this.setState({ recoveryPopupOpened: false })} data-w-id="c7381d52-5762-f7e7-aaec-7fd4f1939111" className="af-class-password-recovery__close-area" />
        </div>

        <div className="af-class-log-in__popup">
          <div className="af-class-password-recovery__content">
            <div className="af-class-password-recovery__close-button-wrapper"><img src="images/icon--close-popup.svg" loading="lazy" alt="" /></div>
            <div className="af-class-log-in__content">
              <h1 className="af-class-password-recovery__title">Вход на сайт</h1>
              <div className="w-layout-grid af-class-log-in__buttons">
                <a href="#" className="af-class-button--primary af-class-loginpopup w-button">для оптовых клиентов</a>
                <a href="#" className="af-class-button--primary af-class-loginpopup w-button">для розничных клиентов</a>
              </div>
            </div>
          </div>
          <div className="af-class-log-in__close-area" />
        </div>

        <div className="af-class-content--p-authorization-wrapper">
          <div className="w-layout-grid af-class-content--p-authorization">
            <div id="w-node-_70d0b173-f788-cc05-1157-734201683bdf-b23d362e">
              {/* <Link to="/">
                    <img src="images/logo_rockets_rgb_blue_and_black.png" loading="lazy" alt="" className="af-class-logo--p-authorization" />
                  </Link> */}
              <div className="af-class-log-in__wrapper">
                <h1 className="af-class-log-in__title">Личный кабинет <span className="color-branded-blue">rockets.coffee</span></h1>
                {/* <div className="af-class-log-in__message">Уважаемые пользователи! Мы заботимся о сохранности ваших данных и поэтому обновили систему безопасности на нашем сайте. Если вы делали покупки или регистрировались на сайте до 1 февраля, вам необходимо пройти процедуру восстановления пароля. Спасибо!</div> */}
                <div data-current="Tab 2" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-log-in__tabs w-tabs">
                  <div className="af-class-log-in__tabs-menu w-tab-menu">
                    <span className={`af-class-log-in__tab w-inline-block loginLink w--current`}>
                      <div>Вход</div>
                    </span>
                    <Link to='/register' className={`af-class-log-in__tab w-inline-block w-tab-link`}>
                      <div>Регистрация</div>
                    </Link>
                  </div>
                  <div className="af-class-log-in__tabs-content w-tab-content">

                    <div data-w-tab="Tab 1" className="af-class-log-in__tab-pane--1 w-tab-pane w--tab-active">
                      <LoginForm navigateOnLogin={true} openRecoveryPopup={this.openRecoveryPopup} />
                    </div>
                  </div>
                </div>
              </div>
            </div><img src="/images/P1087120.webp" loading="lazy" id="w-node-_6ee26210-2446-2249-d33c-09e7f954d227-b23d362e" sizes="100vw" alt="" className="af-class-image--p-authorization" />
          </div>
        </div>
        {/* [if lte IE 9]><![endif] */}
      </div>
      //   </span>
      // </span>
    )
  }
}

export default AuthorizationView

/* eslint-enable */