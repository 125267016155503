/* eslint-disable */

import React from 'react'
import { useLocation } from 'react-router-dom'
import Product from '../components/Product/Product'
import CatalogSortDesktop from '../components/Catalog/CatalogSortDesktop'
import CatalogSortMobile from '../components/Catalog/CatalogSortMobile'
import CatalogTabNav from '../components/Catalog/CatalogTabNav'

import { fetchProducts, fetchFibosearch, fetchProductCategory } from '../fetch/WPAPI'
import { MainContext } from '../contexts/MainContext'
import { createScope, map, transformProxies } from './helpers'

const withLocation = (Component) => {
  return () => {
    const { pathname } = useLocation()
    return <Component pathname={pathname} />
  }
}

class CatalogView extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      pageData: {},
      isFiltersOpen: false,
      allProducts: [],
      products: [],
      filters: [],
      searchString: '',
      searchProducts: [],
      categorySlug: '',
      sort: 0,
      productsLoading: true,
    }
    this.filtersMobileToggle = this.filtersMobileToggle.bind(this)
    this.categoryId = 460 // 16 - uncategorized
    this.setSort = this.setSort.bind(this)
  }

  loadProducts = (params = {}) => {
    let catalog = this.context.catalogItems.find(elem => elem.categoryId == this.categoryId)
    if (catalog) {
      catalog.items = catalog.items.filter(elem => !elem.categories.find(elem => elem.id == 404))
      this.setState({ allProducts: catalog.items, products: catalog.items?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0) ) })
      this.setState({productsLoading: false})
    } else {
      fetchProducts(this.categoryId)
        .then(result => {
          result = result.filter(elem => !elem.categories.find(elem => elem.id == 404))
          this.setState({ allProducts: result, products: result?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0) ) })
          this.context.setCatalogItems([...this.context.catalogItems, { categoryId: this.categoryId, items: result }])
          this.setState({productsLoading: false})
          console.log(result)
        })
    }
  }

  componentDidMount() {
    this.loadProducts()

    fetchProductCategory(this.categoryId)
      .then(res => {
        this.setState({ pageData: res })
       })
  }
  
  componentDidUpdate(prevProps, prevState) {
    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0] == 'search') {
        if (this.state.searchString != pair[1]) {
          this.setState({searchString: pair[1]})
        }
      }
      if (pair[0] == 'category') {
        if (this.state.categorySlug != pair[1]) {
          this.setState({categorySlug: pair[1]})
        }
      }
    }

    if (this.state.searchString !== prevState.searchString) {
      if (this.state.searchString != '') {
        this.filterBySearchString()
      }
    }

    if (this.state.searchProducts !== prevState.searchProducts || this.state.allProducts !== prevState.allProducts) {
      // if (this.state.searchProducts.length > 0) {
      if (this.state.searchString != '') {
        this.setState({ products: this.state.allProducts?.filter(p => this.state.searchProducts.includes(p.id)) })
      }
      // }
    }

    if (this.state.categorySlug !== prevState.categorySlug || this.state.allProducts !== prevState.allProducts) {
      if (this.state.categorySlug.length > 0) {
        let category = this.context.headerMenuCategories.find(elem => elem.slug == this.state.categorySlug)
        if (category) {
          this.setState({ products: this.state.allProducts?.filter(p => p.categories.find(elem => elem.id == category.id)) })
        }
      }
    }

    if (this.state.sort !== prevState.sort) {
      if (this.state.sort == 0) {
        this.setState({ products: this.state.products?.sort((a, b) => (a.menu_order < b.menu_order) ? -1 : ((b.menu_order < a.menu_order) ? 1 : 0) ) })
      } else if (this.state.sort == 3) {
        this.setState({ products: this.state.products?.sort((a, b) => (a.date_created > b.date_created) ? -1 : ((b.date_created > a.date_created) ? 1 : 0) ) })
      } else if (this.state.sort == 4) {
        this.setState({ products: this.state.products?.sort((a, b) => (a.date_created > b.date_created) ? 1 : ((b.date_created > a.date_created) ? -1 : 0) ) })
      } else if (this.state.sort == 1) {
        this.setState({ products: this.state.products?.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? 1 : ((parseInt(b.price) > parseInt(a.price)) ? -1 : 0) ) })
      } else if (this.state.sort == 2) {
        this.setState({ products: this.state.products?.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? -1 : ((parseInt(b.price) > parseInt(a.price)) ? 1 : 0) ) })
      }
    }

    if (this.state.pageData != prevState.pageData) {
      document.title = this.state.pageData?.yoast_head_json?.title
      document.querySelector('meta[name="description"]').setAttribute("content", this.state.pageData?.yoast_head_json?.description)
    }
  }

  setSort(value) {
    this.setState({ sort: value })
  }

  filterBySearchString() {
    console.log(this.state.searchString)
    fetchFibosearch(this.state.searchString)
      .then(result => {
        this.setState({ searchProducts: result.suggestions.filter(elem => elem.type == 'product').map(elem => elem.post_id) })
      })
  }

  filtersMobileToggle() {
    this.setState(prevState => ({ ...prevState, isFiltersOpen: !prevState.isFiltersOpen }))
  }

  render() {
    //let categoryProducts = this.state.products?.filter(p => p.categories.find(c => c.id === this.categoryId) !== undefined)
    const products = this.state.products?.map((p, index) => <Product variant='tea' key={p.id} {...p} />)

    const handleFilter = () => {
      let products = this.state.allProducts?.filter(p => p.categories.find(c => c.id === this.categoryId) !== undefined)
      let filtredItems = products.filter(p => this.state.filters.every(f => {
        let currentAttributeIndex = p.attributes.findIndex(a => a.id === f.id)
        return p.attributes[currentAttributeIndex]?.options.includes(f.option)
      }))
      this.setState({
        products: filtredItems
      })
    }

    const handleNewFilterItem = (newItem) => {
      let prevState = [...this.state.filters]
      if (prevState.some(i => i.id === newItem.id)) {
        let index = prevState.findIndex(i => i.id === newItem.id)
        prevState[index] = newItem
      } else {
        prevState.push(newItem)
      }
      let filtredFilters = prevState.map(i => i.id === newItem.id ? newItem : i)
      this.setState({
        filters: filtredFilters
      })
    }

    const handleFiltersClear = () => {
      this.setState({
        filters: [],
        products: this.state.allProducts
      })
    }

    const FilterField = ({ filter }) => {
      return <label className="w-checkbox af-class-checkbox_field">
        <div onClick={() => handleNewFilterItem(filter)} className={`w-checkbox-input w-checkbox-input--inputType-custom af-class-checkbox ${this.state.filters.some(f => f.option === filter.option) ? 'w--redirected-checked w--redirected-focus' : ''}`} />
        <input type="checkbox" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
        <span onClick={() => handleNewFilterItem(filter)} className="af-class-label-checkbox w-form-label">{filter.option}</span>
      </label>
    }

    const getUniqueItems = (id) => {
      let allItems = []
      this.state.products.forEach(i => {
        let itemsIndex = i.attributes.findIndex(a => a.id === id)
        i.attributes[itemsIndex]?.options.forEach(o => {
          allItems.push(o)
        })
      })
      return allItems.filter((value, index, self) => index === self.findIndex((t) => t === value))
    }
    let weightItems = getUniqueItems(15).map((i, index) => <FilterField key={index} filter={{ id: 15, option: i }} />)

    return (
      <>
            {/* <div className="af-class-filter-mobile" style={this.state.isFiltersOpen ? { display: 'block' } : undefined}>
              <div className="af-class-title-filter-wrapper">
                <div className="af-class-title_and_counter-wrapper-filter">
                  <div>Фильтры</div>
                  <div className="af-class-counter-button-filter">2</div>
                </div>
                <img src="images/icon-close-filter_1icon-close-filter.png" loading="lazy" width={30} onClick={() => this.filtersMobileToggle()} alt="" className="af-class-filter__close-button" />
              </div>
              <div className="af-class-block-filter-p_catalog-mobile">
                <div className="af-class-title_and_icon-filter-mobile-wrapper">
                  <div className="af-class-title_and_icon-filter-mobile">
                    <div className="af-class-title-black-filter-p_catalog--mobile">Вес</div>
                    <img src="images/arrow-down.svg" loading="lazy" alt="" className="af-class-icon-title-filter-mobile af-class-opened" />
                  </div>
                </div>
                <div className="af-class-filter--mobile__form-wrapper">
                  <div className="af-class-form-filter w-form">
                    <div className="af-class-form-two_columns">
                      {weightItems}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-grid af-class-filter_and_show-buttons">
                <div className="af-class-button-outlined-text_grey">
                  <div onClick={handleFiltersClear} style={{ color: `${this.state.filters.length !== 0 ? '#1d71b8' : '#bcbcbc'}` }} className="af-class-text-button-filter">Сбросить все фильтры</div>
                </div>
                <div className="af-class-button-filled">
                  <div className="af-class-text-button-filter">Показать 20 фильтров</div>
                </div>
              </div>
            </div> */}

            <div className="af-class-section af-class-control af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="af-class-bar-filter">
                  <div data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs w-tabs">
                    <CatalogTabNav pathname={this.props.pathname} />

                    <div className="af-class-tabs-content w-tab-content">
                      <div className="w-tab-pane w--tab-active">
                        {/* <div className="w-layout-grid af-class-filter-p_catalog">
                          <div className="af-class-block-filter-p_catalog">
                            <div className="af-class-title-black-filter-p_catalog">Вес</div>
                            <div className="w-form">
                              <div className="af-class-form-one_column">
                                {weightItems}
                              </div>

                              <div className="af-class-buttons-filter-wrapper">
                                <div onClick={handleFilter} className="af-class-button-accept-filter">Применить</div>
                                <div onClick={handleFiltersClear} style={{ color: `${this.state.filters.length !== 0  ? '#1d71b8' : '#bcbcbc'}` }} className="af-class-button-reset-filter">Сбросить все фильтры</div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <CatalogSortMobile setParentSort={this.setSort} filtersMobileToggle={this.filtersMobileToggle} />

                        <div className="w-dyn-list af-class-grid-cards-p_catalog">
                          {products}
                          {products.length === 0 && <div className="w-dyn-empty" style={{background: 'none', color: '#1d71b8', fontWeight: 'bold'}}>
                            <div>{this.state.productsLoading == true ? 'Каталог загружается...' : 'Ничего не найдено.'}</div>
                          </div>}
                        </div>

                        {/* <div className="af-class-button-outlined af-class-button_load_more-p_catalog">
                          <div className="af-class-text-button-filter">Показать еще</div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <CatalogSortDesktop setParentSort={this.setSort} />

                </div>
              </div>
            </div>
          </>
    )
  }
}

export default withLocation(CatalogView)

/* eslint-enable */