import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const CatalogTabLink = ({ pathname, path, text, addStyle }) => {
  return (
    <Link
      to={`/${path}`}
      className={`${
        pathname === `/${path}`
          ? "w--current af-class-text-block-2 "
          : ""
      }af-class-tab-p_catalog w-inline-block w-tab-link`}
      style={addStyle ? addStyle : {}}
    >
      {text}
    </Link>
  );
}

const CatalogTabNav = ({ pathname }) => {
  const navContent = useRef(null);

  // make the nav content scrollable with drag and drop
  useEffect(() => {
    const content = navContent.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const sMouseDown = (e) => {
      isDown = true;
      content.classList.add("active");
      content.style.cursor = "grabbing";
      startX = e.pageX - content.offsetLeft;
      scrollLeft = content.scrollLeft;
    };

    const sMouseLeave = () => {
      isDown = false;
      content.classList.remove("active");
      content.style.cursor = "pointer";
    };

    const sMouseUp = () => {
      isDown = false;
      content.classList.remove("active");
      content.style.cursor = "pointer";
    };

    const sMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - content.offsetLeft;
      const walk = (x - startX);
      content.scrollLeft = scrollLeft - walk;
    };

    content.addEventListener("mousedown", sMouseDown);
    content.addEventListener("mouseleave", sMouseLeave);
    content.addEventListener("mouseup", sMouseUp);
    content.addEventListener("mousemove", sMouseMove);

    return () => {
      content.removeEventListener("mousedown", sMouseDown);
      content.removeEventListener("mouseleave", sMouseLeave);
      content.removeEventListener("mouseup", sMouseUp);
      content.removeEventListener("mousemove", sMouseMove);
    };
  }, []);

  return (
    <div
      style={{
        marginBottom: "15px",
        borderBottom: "1px rgba(0, 0, 0, 0.3) solid",
      }}
      className="catalog-tab-nav-wrapper"
    >
      <div className="catalog-tab-nav-container" ref={navContent}>
        <div className="catalog-tab-nav-content">
          <CatalogTabLink pathname={pathname} path="catalog/coffee" text="Кофе в зёрнах" />
          <CatalogTabLink pathname={pathname} path="catalog/coffee-drips" text="Кофе в дрип-пакетах" />
          <CatalogTabLink pathname={pathname} path="catalog/coffee-capsules" text="Кофе в капсулах" />
          <CatalogTabLink pathname={pathname} path="catalog/tea" text={<img src="/images/logo-keen-tiger.svg" alt="" style={{height: '40px'}} />} addStyle={{ paddingTop: '8px', paddingBottom: '8px' }} />
          <CatalogTabLink pathname={pathname} path="catalog/notcoffee" text="Не кофе" />
          <CatalogTabLink pathname={pathname} path="catalog/accessories" text="Аксессуары" />
          {/* <CatalogTabLink pathname={pathname} path="catalog/black-friday" text="Black Friday" /> */}
          {/* <CatalogTabLink pathname={pathname} path="catalog/merch" text="Мерч" /> */}
        </div>
      </div>
    </div>
  );
};

export default CatalogTabNav;
