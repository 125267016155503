import { useState, Fragment } from 'react';
import FilterField from './Filter/FilterField';
import FilterFieldTooltip from './Filter/FilterFieldTooltip';
import FilterFieldPellet from './Filter/FilterFieldPellet';

export default function CatalogFilters({ filters, categoryFilters, categoryFiltersQuick, toggleFilter, apply, clear, displayGlobal = true }) {
  const [ filtersOpened, setFiltersOpened ] = useState(false);

  const [ filtersTooltipOpened, setFiltersTooltipOpened ] = useState({});
  const [ isHovering, setIsHovering ] = useState('');

  if (displayGlobal === false) return <></>;

  return <>
    <div className="catalog-filters-container-desktop" style={{ border: '1px #d4d4d4 solid', backgroundColor: '#fff', padding: '30px' }}>
      {categoryFiltersQuick.length > 0 && <div style={{ borderBottom: '1px #ddd solid', paddingBottom: '30px', marginBottom: '30px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {categoryFiltersQuick.map((filter, index) => <FilterFieldPellet filters={filters} filter={{ id: filter.id, option: filter.option, text: filter?.text }} toggleFilter={toggleFilter} />)}
        </div>
      </div>}

      {categoryFilters?.length > 0 ? (filtersOpened ? <div onClick={() => setFiltersOpened(!filtersOpened)} style={{ color: '#1d71b8', fontSize: '14px', lineHeight: '1.3', marginBottom: '30px' }} className="af-class-button-reset-filter">{filtersOpened ? 'Скрыть расширенный фильтр' : 'Показать расширенный фильтр'}</div> : <div></div>) : <div></div>}

      {filtersOpened && categoryFilters.map((filter, index) => {
        return <Fragment key={index}>
          <div style={{ borderBottom: '1px #ddd solid', paddingBottom: '30px', marginBottom: '30px' }}>
            <div style={{ marginBottom: '22px', fontFamily: 'Gotham, sans-serif', fontSize: '14px', lineHeight: '1.2', fontWeight: 700, textTransform: 'uppercase' }}>{filter.name} {filters.filter(f => f.id == filter.id).length > 0 && <span style={{ color: '#1D71B8' }}>({filters.filter(f => f.id == filter.id).length})</span>}</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {filter.options.map((option, index) => <FilterFieldPellet filters={filters} filter={{ id: filter.id, option: option }} toggleFilter={toggleFilter} />)}
            </div>
          </div>
          
        </Fragment>
      })}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {categoryFilters?.length > 0 ? (!filtersOpened ? <div onClick={() => setFiltersOpened(!filtersOpened)} style={{ color: '#1d71b8', fontSize: '14px', lineHeight: '1.3' }} className="af-class-button-reset-filter">{filtersOpened ? 'Скрыть расширенный фильтр' : 'Показать расширенный фильтр'}</div> : <div></div>) : <div></div>}
        <div style={{ display: 'flex', gap: '20px' }}>
          <div onClick={clear} style={{ color: filters.length !== 0  ? '#1d71b8' : '#bcbcbc', fontSize: '14px', lineHeight: '1.3' }} className="af-class-button-reset-filter">Сбросить все фильтры</div>
          {/* <div onClick={apply} className="af-class-button-accept-filter">Применить</div> */}
        </div>
      </div>
    </div>
    
    {/* <div className="w-layout-grid af-class-filter-p_catalog">
      {categoryFilters.map((filter, index) => <div className="af-class-block-filter-p_catalog">
        <div className="af-class-title-black-filter-p_catalog">{filter.name}</div>
        <div className="w-form">
          <div className="af-class-form-one_column">
            {filter.options.map((option, index) => <FilterField filters={filters} filter={{ id: filter.id, option: option }} toggleFilter={toggleFilter} />)}
          </div>
        </div>
      </div>)}
    </div>
    <div className="af-class-buttons-filter-wrapper">
      <div onClick={apply} className="af-class-button-accept-filter">Применить</div>
      <div onClick={clear} style={{ color: `${filters.length !== 0  ? '#1d71b8' : '#bcbcbc'}` }} className="af-class-button-reset-filter">Сбросить все фильтры</div>
    </div> */}
  </>;
}