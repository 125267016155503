/* eslint-disable */

import React, { useState, useEffect, useContext } from 'react'
import { fetchReviews, fetchCustomReviews, fetchUsers, fetchCustomers, fetchCurrentUserData, fetchCurrentUserOrders, fetchUsersMeta, fetchUsersIdsByEmails } from '../fetch/WPAPI'
import { useParams, useNavigate } from 'react-router-dom'
import { MainContext } from '../contexts/MainContext'
import ReviewForm from '../components/ReviewForm/ReviewForm'
import Review from '../components/Reviews/Review';

const ReviewsView = () => {

  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()
  const mainContext = useContext(MainContext)

  const [ reviews, setReviews ] = useState([])
  const [ ratings, setRatings ] = useState([])
  const [ users, setUsers ] = useState([])
  const [ usersMeta, setUsersMeta ] = useState([])
  const [ activeTab, setActiveTab ] = useState(1)
  const [ userOrders, setUserOrders ] = useState([])
  const [ reviewPopupOpened, setReviewPopupOpened ] = useState(false)

  const reviewCategories = [
    {id: 1, name: 'О продукции'},
    {id: 435, name: 'О доставке'},
    {id: 436, name: 'О магазине'},
    {id: 437, name: 'Об оплате'},
    {id: 438, name: 'О команде'},
    // {id: 521, name: 'Ответы'},
  ]

  useEffect(() => {
    fetchReviews()
      .then(result => {
        console.log(result)
        setReviews(prevState => [...prevState, { id: 1, data: result.map(elem => { return {
          id: elem.id,
          date: elem.date_created,
          label: elem.product_name,
          link: elem.product_permalink.split('/')[elem.product_permalink.split('/').length - 2],
          rating: elem.rating,
          text: elem.review,
          user_id: null,
          reviewer: elem.reviewer,
          reviewer_email: elem.reviewer_email,
          reviewer_avatar_urls: elem.reviewer_avatar_urls,
        }}) }])
        setRatings(prevState => [...prevState, { id: 1, rating: parseFloat(result.reduce((accumulator, elem) => accumulator + elem.rating, 0) / result.length).toFixed(1) }])

        let user_emails = [...new Set(result.map(elem => elem.reviewer_email))]
        if (user_emails.length > 0) {
          fetchUsersIdsByEmails(user_emails)
            .then(result => {
              if (result.length > 0) {

                result.forEach(elem => {
                  setReviews(prevState => prevState.map(cat => {
                    if (cat.id != 1) {
                      return cat
                    } else {
                      cat.data = cat.data.map(review => {
                        if (review.reviewer_email != elem.email) {
                          return review
                        } else {
                          return {...review, user_id: elem.id}
                        }
                      })
                      return cat
                    }
                  }))
                })

                let user_ids = result.map(elem => elem.id)

                fetchUsers(user_ids)
                  .then(result => {
                    console.log(result)
                    setUsers(prevState => [...prevState, ...result])
                  })
                
                fetchUsersMeta(user_ids)
                  .then(result => {
                    console.log(result)
                    setUsersMeta(prevState => [...prevState, ...result])
                  })
              }
            })
        }

        setIsLoading(false)
      })
    
    reviewCategories.forEach(category => {
      if (category.id != 1) {
        fetchCustomReviews(category.id)
          .then(result => {
            console.log(result)
            setReviews(prevState => [...prevState, { id: category.id, data: result.map(elem => { return {
              id: elem.id,
              date: elem.date,
              label: reviewCategories.find(cat => elem.kategoriya_otzivov.indexOf(cat.id) !== -1)?.name,
              link: null,
              rating: elem.acf.оценка,
              text: elem.content.rendered,
              user_id: elem.author,
              reviewer: null,
              reviewer_avatar_urls: null,
            }}) }])
            setRatings(prevState => [...prevState, { id: category.id, rating: parseFloat(result.reduce((accumulator, elem) => accumulator + elem.acf.оценка, 0) / result.length).toFixed(1) }])

            let user_ids = [...new Set(result.map(elem => elem.author))]
            if (user_ids.length > 0) {
              fetchUsers(user_ids)
                .then(result => {
                  console.log(result)
                  setUsers(prevState => [...prevState, ...result])
                })
              
              fetchUsersMeta(user_ids)
                .then(result => {
                  console.log(result)
                  setUsersMeta(prevState => [...prevState, ...result])
                })
            }
          })
      }
    })

    // Responses
    fetchCustomReviews(521)
      .then(result => {
        console.log(result)
        setReviews(prevState => [...prevState, { id: 521, data: result.map(elem => { return {
          id: elem.id,
          response_to: elem.acf.response_to,
          date: elem.date,
          label: null,
          link: null,
          rating: null,
          text: elem.content.rendered,
          user_id: elem.author,
          reviewer: null,
          reviewer_avatar_urls: null,
        }}) }])
      })
    
    document.title = 'Отзывы'
    document.querySelector('meta[name="description"]').setAttribute("content", 'Отзывы')
  }, [])

  useEffect(() => {
    if (mainContext.userData.id) {
      fetchCurrentUserOrders(mainContext.userData.id)
        .then(result => {
          setUserOrders(result)
        })
    }
  }, [mainContext.userData])

  const handleNewUserReview = (review) => {
    setReviews(prevState => {
      let currentCategory = prevState.find(elem => elem.id == review.category)
      let newState = prevState.filter(elem => elem.id != review.category)
      let newData = [review, ...currentCategory.data]
      currentCategory = { id: currentCategory.id, data: newData }
      newState.push(currentCategory)

      setRatings(prevState => {
        let currentCategoryRating = prevState.find(elem => elem.id == review.category)
        let newStateRating = prevState.filter(elem => elem.id != review.category)
        currentCategoryRating = { id: currentCategoryRating.id, rating: parseFloat(newData.reduce((accumulator, elem) => accumulator + elem.rating, 0) / newData.length).toFixed(1) }
        newStateRating.push(currentCategoryRating)
        return newStateRating
      })

      return newState
    })

    
  }

    return <>
            <ReviewForm reviewPopupOpened={reviewPopupOpened} setReviewPopupOpened={setReviewPopupOpened} handleNewUserReview={handleNewUserReview} />

            <div className="af-class-section af-class-p-previews af-class-an-load--2 af-class-wf-section">
              <div className="af-class-container w-container">
                <h1 className="af-class-main-title--p-sap">Отзывы</h1>
                <div data-current="Tab 1" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-main-grid--p-reviews w-tabs">
                  <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401047-ca3d3642" className="af-class-tabs__tabs-wrapper--p-reviews w-tab-menu">
                    { reviewCategories.map(elem => <a key={elem.id} onClick={() => setActiveTab(elem.id)} className={`af-class-tab-p_faq w-inline-block w-tab-link${activeTab == elem.id ? ' w--current' : ''}`}>
                      <div className="af-class-text-block-4">{elem.name}</div>
                    </a> ) }
                    {/* <a data-w-tab="Tab 1" className="af-class-tab-p_faq w-inline-block w-tab-link w--current">
                      <div className="af-class-text-block-4">О доставке</div>
                    </a>
                    <a data-w-tab="Tab 2" id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c540104b-ca3d3642" className="af-class-tab-p_faq w-inline-block w-tab-link">
                      <div>О магазине</div>
                    </a>
                    <a data-w-tab="Tab 3" className="af-class-tab-p_faq w-inline-block w-tab-link">
                      <div>Об оплате</div>
                    </a>
                    <a data-w-tab="Tab 4" className="af-class-tab-p_faq w-inline-block w-tab-link">
                      <div>О&nbsp;команде</div>
                    </a> */}
                  </div>

                  <div id="w-node-_7398cd83-ae56-4a1e-0f5f-f591c5401051-ca3d3642" className="af-class-tabs__content-wrapper w-tab-content">

                    { isLoading === true && <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div> }
                    
                    { reviews.map(reviewsCategory => <div key={reviewsCategory.id} className={`w-tab-pane${activeTab == reviewsCategory.id ? ' w--tab-active' : ''}`}>
                      <div className="af-class-comments__wrapper">
                        
                        <div className="af-class-comment__raiting">
                          { isNaN(ratings.find(elem => elem.id == reviewsCategory.id)?.rating) ? <div className="af-class-rating__result">Нет отзывов</div> : <>
                            <div className="af-class-rating__stars-bock">
                              { [...Array(5)].map((i, index) => {
                                if (index + 1 <= ratings.find(elem => elem.id == reviewsCategory.id)?.rating) {
                                  return <img key={index + 1} loading="lazy" src="/images/Star-blue.svg" alt="" className="af-class-rating__star-image" />
                                } else {
                                  return <img key={index + 1} loading="lazy" src="/images/Star-gray.svg" alt="" className="af-class-rating__star-image" />
                                }
                              }) }
                            </div>
                            <div className="af-class-rating__result">{ratings.find(elem => elem.id == reviewsCategory.id)?.rating}</div>
                          </> }
                        </div>

                        <div className="af-class-comments__wrap">
                          { reviewsCategory.data?.map((elem, index) => <Review
                            key={elem.id}
                            review={elem}
                            userMeta={usersMeta.find(user => user.id == elem.user_id)}
                            user={users.find(user => user.id == elem.user_id)}
                            allResponses={reviews.find(c => c.id == 521)}
                          /> ) }
                        </div>

                        { userOrders.length > 0 ? <a onClick={() => setReviewPopupOpened(true)} style={{cursor: 'pointer'}} data-w-id="87cdb798-ea8c-698b-e650-28e07deb3794" href="#" className="af-class-comment__button w-button">Оставить отзыв</a> : <></> }
                      </div>
                    </div> ) }

                    {/* <div data-w-tab="Tab 2" className="w-tab-pane">
                      <div className="af-class-comments__wrapper">
                        <div className="af-class-rating__bock">
                          <div className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-rating__star-image" /></div>
                          <div className="af-class-rating__result">4.0</div>
                        </div>
                        <div className="af-class-comments__wrap">
                          <div className="af-class-comment__block">
                            <div className="af-class-comment__user_block">
                              <div className="af-class-user__info-block"><img src="images/avatar_1avatar.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                                <div className="af-class-user__info-text__block">
                                  <div className="af-class-user__info-text"><span className="af-class-user__name">Дмитрий Иванов</span> (<span className="af-class-user-city">Москва</span>)</div>
                                  <div id="w-node-c2f7fdf8-9671-b138-028e-feb76e0c0d0c-ca3d3642" className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                  <div id="w-node-c2f7fdf8-9671-b138-028e-feb76e0c0d12-ca3d3642" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-user__flavor-block">
                              <div className="af-class-flavor-lable">Профиль вкуса</div>
                              <div className="af-class-flavors__wrap">
                                <a href="#" className="af-class-flavor__tag">Яблоко</a>
                                <a href="#" className="af-class-flavor__tag">Малина</a>
                              </div>
                            </div>
                            <div className="af-class-user__comment">По первым двум заказам магазин производит очень приятное впечатление - удобный и понятный выбор кофе на сайте, без волокиты передача в доставку, большое спасибо.</div>
                          </div>
                          <div className="af-class-comment__block af-class-second--p-reviews">
                            <div className="af-class-comment__user_block">
                              <div className="af-class-user__info-block"><img src="images/avatar-1_1avatar-1.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                                <div className="af-class-user__info-text__block">
                                  <div className="af-class-user__info-text"><span className="af-class-user__name">Мария Смирнова</span> (<span className="af-class-user-city">Москва</span>)</div>
                                  <div id="w-node-_31ef540e-4d46-0bba-85d6-847f3854063f-ca3d3642" className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                  <div id="w-node-_31ef540e-4d46-0bba-85d6-847f38540645-ca3d3642" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-user__flavor-block">
                              <div className="af-class-flavor-lable">Профиль вкуса</div>
                              <div className="af-class-flavors__wrap">
                                <a href="#" className="af-class-flavor__tag">Апельсин</a>
                                <a href="#" className="af-class-flavor__tag">Яблоко</a>
                                <a href="#" className="af-class-flavor__tag">Малина</a>
                              </div>
                            </div>
                            <div className="af-class-user__comment">Лучший кофе в моей жизни! Теперь я постоянный покупатель. Также хочу отметить удобный сайт, все понятно и информативно.</div>
                          </div>
                        </div>
                        <a href="#" className="af-class-button w-button">Оставить отзыв</a>
                      </div>
                    </div>
                    <div data-w-tab="Tab 3" className="w-tab-pane">
                      <div className="af-class-comments__wrapper">
                        <div className="af-class-rating__bock">
                          <div className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-rating__star-image" /></div>
                          <div className="af-class-rating__result">4.5</div>
                        </div>
                        <div className="af-class-comments__wrap">
                          <div className="af-class-comment__block">
                            <div className="af-class-comment__user_block">
                              <div className="af-class-user__info-block"><img src="images/avatar_1avatar.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                                <div className="af-class-user__info-text__block">
                                  <div className="af-class-user__info-text"><span className="af-class-user__name">Дмитрий Иванов</span> (<span className="af-class-user-city">Москва</span>)</div>
                                  <div id="w-node-_6eb7a205-b096-a213-b73a-22a617726d9f-ca3d3642" className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                  <div id="w-node-_6eb7a205-b096-a213-b73a-22a617726da5-ca3d3642" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-user__flavor-block">
                              <div className="af-class-flavor-lable">Профиль вкуса</div>
                              <div className="af-class-flavors__wrap">
                                <a href="#" className="af-class-flavor__tag">Яблоко</a>
                                <a href="#" className="af-class-flavor__tag">Малина</a>
                              </div>
                            </div>
                            <div className="af-class-user__comment">По первым двум заказам магазин производит очень приятное впечатление - удобный и понятный выбор кофе на сайте, без волокиты передача в доставку, большое спасибо.</div>
                          </div>
                          <div className="af-class-comment__block af-class-second--p-reviews">
                            <div className="af-class-comment__user_block">
                              <div className="af-class-user__info-block"><img src="images/avatar-1_1avatar-1.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                                <div className="af-class-user__info-text__block">
                                  <div className="af-class-user__info-text"><span className="af-class-user__name">Мария Смирнова</span> (<span className="af-class-user-city">Москва</span>)</div>
                                  <div id="w-node-b5332d44-ab80-45ab-c52f-8fae8f6b8caf-ca3d3642" className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                  <div id="w-node-b5332d44-ab80-45ab-c52f-8fae8f6b8cb5-ca3d3642" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-user__flavor-block">
                              <div className="af-class-flavor-lable">Профиль вкуса</div>
                              <div className="af-class-flavors__wrap">
                                <a href="#" className="af-class-flavor__tag">Апельсин</a>
                                <a href="#" className="af-class-flavor__tag">Яблоко</a>
                                <a href="#" className="af-class-flavor__tag">Малина</a>
                              </div>
                            </div>
                            <div className="af-class-user__comment">Лучший кофе в моей жизни! Теперь я постоянный покупатель. Также хочу отметить удобный сайт, все понятно и информативно.</div>
                          </div>
                        </div>
                        <a href="#" className="af-class-button w-button">Оставить отзыв</a>
                      </div>
                    </div>
                    <div data-w-tab="Tab 4" className="w-tab-pane">
                      <div className="af-class-comments__wrapper">
                        <div className="af-class-rating__bock">
                          <div className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-rating__star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-rating__star-image" /></div>
                          <div className="af-class-rating__result">4.5</div>
                        </div>
                        <div className="af-class-comments__wrap">
                          <div className="af-class-comment__block">
                            <div className="af-class-comment__user_block">
                              <div className="af-class-user__info-block"><img src="images/avatar_1avatar.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                                <div className="af-class-user__info-text__block">
                                  <div className="af-class-user__info-text"><span className="af-class-user__name">Дмитрий Иванов</span> (<span className="af-class-user-city">Москва</span>)</div>
                                  <div id="w-node-_6e8d65cf-830b-cf23-c86a-6424413b68ec-ca3d3642" className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                  <div id="w-node-_6e8d65cf-830b-cf23-c86a-6424413b68f2-ca3d3642" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-user__flavor-block">
                              <div className="af-class-flavor-lable">Профиль вкуса</div>
                              <div className="af-class-flavors__wrap">
                                <a href="#" className="af-class-flavor__tag">Яблоко</a>
                                <a href="#" className="af-class-flavor__tag">Малина</a>
                              </div>
                            </div>
                            <div className="af-class-user__comment">По первым двум заказам магазин производит очень приятное впечатление - удобный и понятный выбор кофе на сайте, без волокиты передача в доставку, большое спасибо.</div>
                          </div>
                          <div className="af-class-comment__block af-class-second--p-reviews">
                            <div className="af-class-comment__user_block">
                              <div className="af-class-user__info-block"><img src="images/avatar-1_1avatar-1.jpg" loading="lazy" width={50} alt="" className="af-class-user__avatar" />
                                <div className="af-class-user__info-text__block">
                                  <div className="af-class-user__info-text"><span className="af-class-user__name">Мария Смирнова</span> (<span className="af-class-user-city">Москва</span>)</div>
                                  <div id="w-node-_25ab4452-fed3-c7c7-3c90-9cb4e3dcac23-ca3d3642" className="af-class-rating__stars-bock"><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/heart-filled.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /><img src="images/Star-gray.svg" loading="lazy" alt="" className="af-class-user__rating-star-image" /></div>
                                  <div id="w-node-_25ab4452-fed3-c7c7-3c90-9cb4e3dcac29-ca3d3642" className="af-class-user__info-text af-class-date">1.11.2021г</div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-user__flavor-block">
                              <div className="af-class-flavor-lable">Профиль вкуса</div>
                              <div className="af-class-flavors__wrap">
                                <a href="#" className="af-class-flavor__tag">Апельсин</a>
                                <a href="#" className="af-class-flavor__tag">Яблоко</a>
                                <a href="#" className="af-class-flavor__tag">Малина</a>
                              </div>
                            </div>
                            <div className="af-class-user__comment">Лучший кофе в моей жизни! Теперь я постоянный покупатель. Также хочу отметить удобный сайт, все понятно и информативно.</div>
                          </div>
                        </div>
                        <a href="#" className="af-class-button w-button">Оставить отзыв</a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
}

export default ReviewsView

/* eslint-enable */