import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom'
import './index.css';
import './styles'
import './scripts'
import Content from './views/IndexView';
import KnowledgeView from './views/KnowledgeView'
import KnowledgeViewOne from './views/KnowledgeViewOne'
import LoyaltySystemView from './views/LoyaltySystemView'
import NotFound from './views/NotFoundView'
import RegistrationView from './views/RegistrationView'
import OrderConfirmation from './views/OrderConfirmationView'
import Reviews from './views/ReviewsView'
import StyleGuide from './views/StyleGuideView'
import Vocabulary from './views/VocabularyView'
import Faq from './views/FaqView'
import EducationAll from './views/EducationAllView'
import CoffeeShops from './views/CoffeeShopsView'
import Contacts from './views/ContactsView'
import { AboutUsView, AccountPersonalView, AuthorizationView, CartView, CatalogView, CountryView, EducationOneView, ProductPageView, ShippingAndPaymentView, VocabularyView } from './views';

import CatalogViewNew from './views/CatalogViewNew';
import CatalogViewDripsNew from './views/CatalogViewDripsNew';
import CatalogViewCapsulesNew from './views/CatalogViewCapsulesNew';
import CatalogNotCoffeeViewNew from './views/CatalogNotCoffeeViewNew';
import CatalogTeaViewNew from './views/CatalogTeaViewNew';
import CatalogAccessoriesViewNew from './views/CatalogAccessoriesViewNew';
import CatalogArchiveViewNew from './views/CatalogArchiveViewNew';
import CatalogBlackFridayViewNew from './views/CatalogBlackFridayViewNew';

import CatalogTeaView from './views/CatalogTeaView';
import CatalogNotCoffeeView from './views/CatalogNotCoffeeView';
import CatalogNewyearView from './views/CatalogNewyearView';
import CatalogAccessoriesView from './views/CatalogAccessoriesView';
import CatalogMerchView from './views/CatalogMerchView';
import CatalogArchiveView from './views/CatalogArchiveView';
import UserAgreementView from './views/UserAgreementView';
import ThankYouPageView from './views/ThankYouPageView';
import PriceListView from './views/PriceListView';
import PostView from './views/PostView';
import Footer from './components/Footer/Footer'
import Header from './components/Header'
import { MainContext } from './contexts/MainContext'
import useToken from './hooks/useToken.js'
import useCart from './hooks/useCart.js'
import { fetchWPItems } from './fetch/fetchWPItems'
import { fetchCurrentUser, fetchCurrentUserData, fetchProductCategories, fetchCustomPosts, fetchPost, fetchProducts, fetchFullVariations } from './fetch/WPAPI'
import ScrollToTop from './hooks/scrollToTop'

const IndexContext = () => {

  const mainContext = useContext(MainContext)

  const { token, setToken, nonce, setNonce } = useToken()
  const { cart, setCart } = useCart()

  const location = useLocation()

  useEffect(() => {
    // eslint-disable-next-line
    ym(45440976, "hit", location.pathname);
  }, [location.pathname])

  useEffect(() => {
    // eslint-disable-next-line
    ym(45440976, 'getClientID', function(clientID) {
      mainContext.setYmClientID(clientID)
    });

    mainContext.setCartItems(cart)

    fetchFullVariations()
      .then(result => {
        mainContext.setCatalogItemsVariations(result)
      })

    fetchProducts(459)
      .then(result => {
        result = result.filter(elem => !elem.categories.find(elem => elem.id == 404))
        mainContext.setCatalogItems([...mainContext.catalogItems, { categoryId: 459, items: result }])
      })

    fetchWPItems('https://panel.rockets.coffee/wp-json/wp/v2/pages/2858?acf_format=standard')
      .then(res => {
        mainContext.setHomePageData(res)
      })

    fetchProductCategories()
      .then(result => {
        mainContext.setHeaderMenuCategories(result)
      })

    fetchCustomPosts('sposobi_zavarivaniya')
      .then(result => {
        mainContext.setBrewingMethods(result)
      })

    fetchPost(13097)
      .then(result => {
        mainContext.setPriceListPageSlug(result.slug)
      })

    // UTM
    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    let utms = []
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0].indexOf('utm_') == 0) {
        utms.push({ tag: pair[0], value: pair[1] })
      }
    }
    if (utms.length > 0) {
      localStorage.setItem('utms', JSON.stringify(utms))
    }
  }, [])


  useEffect(() => {
    if (!localStorage.getItem("phone")) {
      setToken(null);
      setNonce(null);
      // setCart([]); // this shouldn't be here, it clears cart with every page reload
      // mainContext.setUserData({}); // and this flushes saved data for unauthorized users
      return
    }
    if (!token || !token.token) {
      return
    }
    if (!mainContext.userData.id) {
      fetchCurrentUser(token.token)
        .then(result => {
          if (result.id) {
            fetchCurrentUserData(result.id)
              .then(resultWoo => {
                if (resultWoo.id) {
                  mainContext.setUserData(resultWoo)
                  mainContext.setUserWpData(result)
                } else {
                  setToken(null)
                }
              })
          } else {
            setToken(null)
          }
        })
    }
  }, [token])

  // useEffect(() => {
  //   if (!localStorage.getItem("phone")) {
  //     setToken(null);
  //     setNonce(null);
  //     setCart([]);
  //     mainContext.setUserData({});

  //   }
  // }, [])

  // comment for test github push

  return (
    <>
        <ScrollToTop />

        <span>
          <style dangerouslySetInnerHTML={{
            __html: `
            @import url(/css/normalize.css);
            @import url(/css/webflow.css);
            @import url(/css/rockets-coffee-export.webflow.css);
            ` }} />
          {/* import { useLocation } from 'react-router';
            @media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a7b27637-4100-feb6-337f-6f1dfdbc3ac1\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"0c8c5548-2061-312b-6801-96116b534690\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"75e4ef52-cbf1-6e3a-bdad-0f5e1cbf0f86\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"0da966d3-5b79-b92c-e304-62e4fa280626\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"392c0fe6-fa55-99f2-1689-97444c994225\"] {height:0px;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a7b27637-4100-feb6-337f-6f1dfdbc3ac1\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"0c8c5548-2061-312b-6801-96116b534690\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"75e4ef52-cbf1-6e3a-bdad-0f5e1cbf0f86\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"0da966d3-5b79-b92c-e304-62e4fa280626\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"392c0fe6-fa55-99f2-1689-97444c994225\"] {height:0px;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a7b27637-4100-feb6-337f-6f1dfdbc3ac1\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"0c8c5548-2061-312b-6801-96116b534690\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"75e4ef52-cbf1-6e3a-bdad-0f5e1cbf0f86\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"0da966d3-5b79-b92c-e304-62e4fa280626\"] {height:0px;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"392c0fe6-fa55-99f2-1689-97444c994225\"] {height:0px;}} */}

          <span className="af-view">
            <div>
              <Header />

              <Routes>
                <Route path="/" element={<Content />} />
                <Route exact path="/catalog/coffee" element={<CatalogViewNew />} />
                <Route exact path="/catalog/coffee-drips" element={<CatalogViewDripsNew />} />
                <Route exact path="/catalog/coffee-capsules" element={<CatalogViewCapsulesNew />} />
                <Route exact path="/catalog/notcoffee" element={<CatalogNotCoffeeViewNew />} />
                <Route exact path="/catalog/tea" element={<CatalogTeaViewNew />} />
                <Route exact path="/catalog/accessories" element={<CatalogAccessoriesViewNew />} />
                <Route exact path="/catalog/merch" element={<CatalogMerchView />} />
                <Route exact path="/catalog/archive" element={<CatalogArchiveViewNew />} />
                <Route exact path="/catalog/newyear" element={<CatalogNewyearView />} />
                <Route exact path="/catalog/black-friday" element={<CatalogBlackFridayViewNew />} />
                <Route path="/products/:slug" element={<ProductPageView />} />
                <Route path="/me" element={<AccountPersonalView />} />
                <Route path="/knowledge" element={<KnowledgeView />} />
                <Route path="/knowledge/:slug" element={<KnowledgeViewOne />} />
                {/* <Route path="/loyalty" element={<LoyaltySystem/>}/> */}
                {/* <Route path="/notfound" element={<NotFound/>}/> */}
                {/* <Route path="/order-confirmation" element={<OrderConfirmation/>}/> */}
                <Route path="/reviews" element={<Reviews />} />
                {/* <Route path="/style-guide" element={<StyleGuide/>}/> */}
                {/* <Route path="/vocabulary" element={<Vocabulary/>}/> */}
                <Route path="/faq" element={<Faq />} />
                <Route path="/education-all" element={<EducationAll />} />
                <Route path="/education-all/:slug" element={<EducationOneView />} />
                <Route path="/coffee-shops" element={<CoffeeShops />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/loyalty-system" element={<LoyaltySystemView />} />
                <Route path="/cart" element={<CartView />} />
                <Route path="/about-us" element={<AboutUsView />} />
                <Route path="/shipping-and-payment" element={<ShippingAndPaymentView />} />
                <Route path="/login" element={<AuthorizationView />} />
                <Route path="/register" element={<RegistrationView />} />
                <Route path="/country/:slug" element={<CountryView />} />
                <Route path="/coffee-dictionary" element={<VocabularyView />} />
                <Route path="/post/:id" element={<PostView />} />
                <Route path="/user-agreement" element={<UserAgreementView />} />
                <Route path="/thank-you" element={<ThankYouPageView />} />
                {/* <Route path={`/${mainContext.priceListPageSlug}`} element={<PriceListView />} /> */}
                {/* <Route path={`/y8ipgm`} element={<PriceListView slug="y8ipgm" />} />
                <Route path={`/crcyd3`} element={<PriceListView slug="crcyd3" />} />
                <Route path={`/price-distributor`} element={<PriceListView slug="price-distributor" />} />
                <Route path={`/price-south-distribution`} element={<PriceListView slug="price-south-distribution" />} /> */}
                <Route path="*" element={<NotFound />} />
              </Routes>

              <Footer />
              {/* [if lte IE 9]><![endif] */}
            </div>
          </span>
        </span>

    </>
  )
}

export default IndexContext
