/* eslint-disable */

import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CartItem from "../components/CartItem/CartItem";

import CartForm from "../components/Cart/CartForm";
import Product from "../components/Product/Product";
import { MainContext } from "../contexts/MainContext";
import { CartFormContext } from "../contexts/CartFormContext";
import useCart from "../hooks/useCart.js";
import { fetchProductsById, fetchFullVariations } from "../fetch/WPAPI";
import { YMaps } from "@pbe/react-yandex-maps";
import LoginForm from "../components/Authorization/LoginForm";
import { createScope, map, transformProxies } from "./helpers";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "../styles/buttons.css";

let Controller;

const CartView = (props) => {
  const navigate = useNavigate();

  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);

  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);

  const cartSummaryBodyRef = useRef();
  const cartSummaryFooterRef = useRef();

  const onScroll = () => {
    let headerHeight = document.querySelector('header').getBoundingClientRect().height;
    let cartSummaryBodyBottom = cartSummaryBodyRef.current.getBoundingClientRect().top + cartSummaryBodyRef.current.getBoundingClientRect().height;

    let agreementBottom = document.querySelector('.cart-user-agreement-checkbox')?.getBoundingClientRect()?.top + document.querySelector('.cart-user-agreement-checkbox')?.getBoundingClientRect()?.height;

    let formSubmitButton = document.querySelector('.cart-order-button-container');
    let formInvalidAlert = document.querySelector('.cart-form-invalid-alert');

    let buttonAndAlertHeight;
    if (!formInvalidAlert) {
      buttonAndAlertHeight = formSubmitButton?.getBoundingClientRect()?.height + 20;
    } else {
      buttonAndAlertHeight = formSubmitButton?.getBoundingClientRect()?.height + formInvalidAlert?.getBoundingClientRect()?.height + 40;
    }
    
    if (cartSummaryFooterRef.current) {
      if (cartSummaryBodyBottom < headerHeight) { // If the cart summary body bottom is above the header, we need to fix the cart summary footer
        if (!cartSummaryFooterRef.current.classList.contains('cart__summary-footer-fixed')) { // If the cart summary footer is not fixed yet, we need to fix it
          cartSummaryFooterRef.current.classList.add('cart__summary-footer-fixed');
          cartSummaryFooterRef.current.style.width = (cartSummaryBodyRef.current.getBoundingClientRect().width + 60 + 2) + 'px';
          cartSummaryFooterRef.current.style.top = headerHeight + 'px';
        } else { // If the cart summary footer is already fixed, we need to check if it's not overlapping the recommended products section
          if (agreementBottom < (cartSummaryFooterRef.current.getBoundingClientRect().height + buttonAndAlertHeight + headerHeight)) {
            cartSummaryFooterRef.current.style.top = (agreementBottom - cartSummaryFooterRef.current.getBoundingClientRect().height - buttonAndAlertHeight) + 'px';
          } else {
            cartSummaryFooterRef.current.style.top = headerHeight + 'px';
          }
        }
      } else { // If the cart summary body bottom is below the header, we need to unfix the cart summary footer
        cartSummaryFooterRef.current.classList.remove('cart__summary-footer-fixed')
        cartSummaryFooterRef.current.style.width = 'auto'
      }
    }
  }

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const { cart, setCart, getCart } = useCart();

  const handleClear = () => {
    setCart([]);
  };

  const [cartItemsRefreshing, setCartItemsRefreshing] = useState(false);
  const [cartViewFormIsValid, setCartViewFormIsValid] = useState(false);
  const [cartViewFormSent, setCartViewFormSent] = useState(false);
  const [cartViewFormSubmit, setCartViewFormSubmit] = useState(false);
  const [regularDiscount, setRegularDiscount] = useState(0);
  const [orderSuccessful, setOrderSuccessful] = useState(false);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  
  const handleCartViewFormSubmit = () => {
    setCartViewFormSubmit(true);

    _tmr.push({ type: 'reachGoal', id: 3419693, goal: 'place-order'});

    const ct_site_id = '62708';

    const ct_data = {             
      fio: `${cartFormContext.surname} ${cartFormContext.name}`,
      phoneNumber: `${cartFormContext.phoneCode} ${cartFormContext.phone}`,
      email: cartFormContext.email,
      subject: 'Заявка с сайта',
      // tags: 'Mercedes,Белый',
      // comment: 'Тест-драйв',
      // requestUrl: location.href,
      sessionId: window.ct('calltracking_params','10qg9fdf').sessionId 
    };
    fetch('https://api.calltouch.ru/calls-service/RestAPI/requests/' + ct_site_id + '/register/', {
      method: 'POST',
      // body: JSON.stringify(ct_data),
      body: Object.keys(ct_data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(ct_data[key])).join('&'),
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    }).then(response => {
      console.log(response);
    });
  }

  useEffect(() => {
    let randomProducts = mainContext.catalogItems
      .find((elem) => (elem.categoryId == 17 && elem.categoryId != 404))
      ?.items?.filter(item => !item?.categories?.find(c => c.id == 404))
      ?.sort((a, b) => 0.5 - Math.random())
      ?.slice(0, 10);
    
    if (randomProducts) {
      setSuggestedProducts(randomProducts);
    }

    document.title = "Корзина";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Корзина");
  }, []);

  useEffect(() => {
    let cartTotalPrice = mainContext.cartItems.reduce((accumulator, elem) => {
      let qty;
      if (elem.cartQty === false) { // this is needed for disabling the qty input for some products (fixed 1 piece)
        qty = 1;
      } else {
        qty = elem.cartQty;
      }
      return accumulator + parseInt(elem.cartPrice * qty);
    }, 0);

    mainContext.setCartTotalPrice(cartTotalPrice);

    let totalWeight = mainContext.cartItems.reduce((accumulator, elem) => {
      if (
        elem.cartWeight &&
        (elem.cartWeight.indexOf("кг") !== -1 ||
          elem.cartWeight.indexOf("г") !== -1)
      ) {
        return elem.cartWeight.indexOf("кг") !== -1
          ? accumulator + parseInt(elem.cartWeight) * 1000 * elem.cartQty
          : accumulator + parseInt(elem.cartWeight) * elem.cartQty;
      } else if (elem.weight != "") {
        return accumulator + parseInt(elem.weight) * elem.cartQty;
      } else {
        return accumulator;
      }
    }, 0);
    mainContext.setCartTotalWeight(totalWeight);

    setRegularDiscount(
      mainContext.cartItems.reduce(
        (accumulator, elem) =>
          accumulator + (elem.cartPriceOld != null
            ? parseInt((elem.cartPriceOld - elem.cartPrice) * elem.cartQty)
            : 0),
        0
      )
    );

    const drips = mainContext.cartItems.filter(
      (elem) =>
        elem.categories.find((cat) => cat.id == 416) &&
        elem.name.indexOf("1 шт") !== -1
    );
    const dripsQty = drips.reduce(
      (accumulator, elem) => accumulator + elem.cartQty,
      0
    );
    const bonusBag = mainContext.catalogItems
      .find((category) => category.categoryId == 459)
      ?.items?.find((product) => product.id == 18286);
    const bonusBagAdded = mainContext.cartItems.find(
      (product) => product.id == 18286
    );

    if (bonusBag) {
      if (dripsQty >= 15) {
        if (!bonusBagAdded) {
          let newItem = {
            ...bonusBag,
            cartQty: false,
            cartPrice: 1,
          };

          setCart([newItem, ...mainContext.cartItems]);
        }
      } else {
        if (bonusBagAdded) {
          setCart(mainContext.cartItems.filter((elem) => elem.id != 18286));
        }
      }
    }
  }, [mainContext.cartItems]);

  const handleCartItemsRefreshing = (value) => {
    setCartItemsRefreshing(value);
  }

  const refreshCartItems = () => {
    let cart = getCart(); // getting useCart hook's state as usual causes problems here since its value gets bound to this function on component mounting stage, so refetching the data here

    if (cart && cart.length) {
      handleCartItemsRefreshing(true);

      fetchFullVariations()
        .then(fullVariations => {
          mainContext.setCatalogItemsVariations(fullVariations);

          let product_ids = cart.map((elem) => elem.id);
          
          fetchProductsById(product_ids).then((newItems) => {
            let oldItems = cart;
            
            let idsToRemove = [];

            oldItems.forEach((oldItem, oldItemIndex) => {
              let newItem = newItems.find((elem) => elem.id == oldItem.id);
              if (!newItem || newItem.categories.find(category => category.id == 404)) {
                idsToRemove.push(oldItem.id);
              } else {
                if (newItem.variations && newItem.variations.length && oldItem.variationId) {
                  let itemVariations = fullVariations.find(elem => elem.id == oldItem.id);
                  if (itemVariations && itemVariations.data && itemVariations.data.length) {
                    let selectedVariation = itemVariations.data.find(elem => elem.id == oldItem.variationId);
                    if (selectedVariation) {
                      if (selectedVariation.sale_price) {
                        oldItems[oldItemIndex].cartPrice = selectedVariation.sale_price;
                        oldItems[oldItemIndex].cartPriceOld = selectedVariation.regular_price;
                      } else {
                        oldItems[oldItemIndex].cartPrice = selectedVariation.price;
                        oldItems[oldItemIndex].cartPriceOld = null;
                      }
                    }
                  }
                } else {
                  if (newItem.sale_price) {
                    oldItems[oldItemIndex].cartPrice = newItem.sale_price;
                    oldItems[oldItemIndex].cartPriceOld = newItem.regular_price;
                  } else {
                    oldItems[oldItemIndex].cartPrice = newItem.price;
                    oldItems[oldItemIndex].cartPriceOld = null;
                  }
                }
              }
            });

            oldItems = oldItems.filter((elem) => !idsToRemove.find(id => id == elem.id));
      
            setCart(oldItems);

            // forceUpdate();
            
            handleCartItemsRefreshing(false);
          });
        });
    }
  }

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  const onFocus = () => {
    refreshCartItems();
  };

  useEffect(() => {
    refreshCartItems();
  }, []);

  const validateForm = () => {
    if (cartFormContext.surnameValid !== true) {
      cartFormContext.setSurnameValid(false);
      cartFormContext.setSurnameError('error');
    }
    if (cartFormContext.nameValid !== true) {
      cartFormContext.setNameValid(false);
      cartFormContext.setNameError('error');
    }
    if (cartFormContext.phoneValid !== true) {
      cartFormContext.setPhoneValid(false);
      cartFormContext.setPhoneError('error');
    }
    if (cartFormContext.emailValid !== true) {
      cartFormContext.setEmailValid(false);
      cartFormContext.setEmailError('error');
    }
    if (["Курьером", "Почтой России", "Яндекс Доставка"].includes(cartFormContext.deliveryType.selected) && cartFormContext.addressData?.full?.length === 0) {
      // cartFormContext.setAddressValid(false);
      cartFormContext.setAddressError('error');
    }
  }

  const [formInvalidAlert, setFormInvalidAlert] = useState(false);

  useEffect(() => {
    onScroll();
  }, [formInvalidAlert]);

  const OrderButton = () => {
    return <div
      className="af-class-cart__form-submit-button-wrapper"
      style={{ width: '100%', left: 0 }}
      onClick={() => {
        validateForm()
        if (cartViewFormIsValid === true && cartViewFormSent === false) {
          setFormInvalidAlert(false)
          handleCartViewFormSubmit()
        }
        if (cartViewFormIsValid === false) {
          setFormInvalidAlert(true)
        }
      }}
    >
      <input
        type="submit"
        value={cartViewFormSent ? "Оформляем..." : "Оформить заказ"}
        data-wait="Please wait..."
        style={{ width: '100%' }}
        className={`af-class-g-submit-button w-button`} // ${cartViewFormIsValid === false || cartViewFormSent === true ? " button-disabled" : ""}
      />
    </div>
  }

  const CartSummaryFooter = () => {
    return <>
      {/* { mainContext.cartItems.length > 0 &&<a href="#" className="af-class-cart__gift-wrap w-inline-block">
          <div className="af-class-cart__gift-text">Добавить подарочную упаковку</div>
          <div className="af-class-question_icon af-class-cart" />
        </a> } */}
      <div
        className="af-class-cart__price-wrapper"
        style={{ gridTemplateColumns: "1fr 1fr" }}
      > 
        <div className="af-class-cart__total-items-desktop-text">
          Товаров в корзине
        </div>
        <div className="af-class-cart__total-items-desktop-value">{mainContext.cartItems.reduce((accumulator, currentValue) => accumulator + currentValue.cartQty, 0)}</div>
        <div className="af-class-cart__discount-text">
          Стоимость доставки
        </div>
        <div className="w-layout-grid af-class-cart__discount-value-wrapper">
          { cartFormContext.deliveryCost !== false ? <>
            <div className="af-class-cart__discount-value">
              {cartFormContext.deliveryCostDiscounted}
            </div>
            <div className="af-class-cart__discount-value-sign">
              ₽
            </div>
          </> : <div className="af-class-cart__discount-value">
            Выберите параметры
          </div> }
        </div>

        {cartFormContext.promoCodeDiscount > 0 && (
          <>
            <div className="af-class-cart__discount-text">
              Скидка по промокоду
            </div>
            <div className="w-layout-grid af-class-cart__discount-value-wrapper af-class-cart__discount-active">
              <div className="af-class-cart__discount-value">
                -{parseInt(cartFormContext.promoCodeDiscount)}
              </div>
              <div className="af-class-cart__discount-value-sign">
                ₽
              </div>
            </div>
          </>
        )}

        {cartFormContext.certificateDiscount > 0 && (
          <>
            <div className="af-class-cart__discount-text">
              Скидка по сертификату
            </div>
            <div className="w-layout-grid af-class-cart__discount-value-wrapper af-class-cart__discount-active">
              <div className="af-class-cart__discount-value">
                -{parseInt(cartFormContext.certificateDiscount)}
              </div>
              <div className="af-class-cart__discount-value-sign">
                ₽
              </div>
            </div>
          </>
        )}

        {cartFormContext.bonusesDiscount > 0 && (
          <>
            <div className="af-class-cart__discount-text">
              Оплачено бонусами
            </div>
            <div className="w-layout-grid af-class-cart__discount-value-wrapper af-class-cart__discount-active">
              <div className="af-class-cart__discount-value">
                -{cartFormContext.bonusesDiscount}
              </div>
              <div className="af-class-cart__discount-value-sign">
                ₽
              </div>
            </div>
          </>
        )}

        {regularDiscount > 0 && (
          <>
            <div className="af-class-cart__discount-text">
              Скидка
            </div>
            <div className="w-layout-grid af-class-cart__discount-value-wrapper af-class-cart__discount-active">
              <div className="af-class-cart__discount-value">
                -{parseInt(regularDiscount)}
              </div>
              <div className="af-class-cart__discount-value-sign">
                ₽
              </div>
            </div>
          </>
        )}

        {/* { deliveryTime != '' && <><div className="af-class-cart__discount-text">Срок доставки</div>
            <div className="af-class-cart__discount-value-wrapper" style={{minWidth: 0}}>
            <div className="af-class-cart__discount-value">
              { deliveryTime }
            </div>
          </div></> } */}

        <div className="af-class-cart__summ-text">
          Общая сумма
        </div>
        <div className="w-layout-grid af-class-cart__summ-value-wrapper">
          <div className="af-class-cart__summ-value">
            {(mainContext.cartTotalPrice +
              cartFormContext.deliveryCostDiscounted -
              cartFormContext.promoCodeDiscount -
              cartFormContext.certificateDiscount -
              cartFormContext.bonusesDiscount > 0)
              ? (mainContext.cartTotalPrice +
                cartFormContext.deliveryCostDiscounted -
                cartFormContext.promoCodeDiscount -
                cartFormContext.certificateDiscount -
                cartFormContext.bonusesDiscount)
              : 0}
          </div>
          <div className="af-class-cart__summ-value">₽</div>
        </div>
      </div>
    </>
  }

  return (
    <>
      <div className="af-class-section af-class-p-cart af-class-wf-section">
        <div className="af-class-container w-container">
          <h1 className="af-class-page-title af-class-p-cart">
            Оформление заказа
          </h1>

          {orderSuccessful === true ? (
            <div
              style={{
                border: "1px #777 solid",
                borderRadius: "10px",
                padding: "10px",
                width: "100%",
                marginBottom: "30px",
              }}
            >
              Заказ успешно оформлен!
            </div>
          ) : (
            <></>
          )}

          <div
            className="w-layout-grid af-class-cart-content"
            style={{ marginBottom: "30px" }}
          >
            {mainContext.cartItems.length > 0 && (
              <>
                <YMaps>
                  <CartForm
                    setOrderSuccessful={setOrderSuccessful}
                    setCartViewFormIsValid={setCartViewFormIsValid}
                    setCartViewFormSent={setCartViewFormSent}
                    cartViewFormSubmit={cartViewFormSubmit}
                    setCartViewFormSubmit={setCartViewFormSubmit}
                  />
                </YMaps>
              </>
            )}

            <div style={{ minWidth: 0, overflow: 'hidden' }}>
              <div
                className="af-class-cart__summary"
                id="w-node-bbc00f56-5019-5b0b-692a-bc1f9fb4b506-4e3d362f"
                style={{ position: 'relative' }}
              >
                { cartItemsRefreshing && <div style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0, zIndex: 999, background: 'rgba(255, 255, 255, 0.5)', borderRadius: '20px' }}></div> }
                <div className="af-class-cart__summary-header">
                  <h2 className="af-class-cart__summary-title">Корзина</h2>
                  {mainContext.cartItems.length > 0 && (
                    <a
                      onClick={handleClear}
                      className="af-class-button--secondry w-inline-block"
                    >
                      <div className="af-class-cart__button--secondry__text">
                        Очистить
                      </div>
                      <div className="af-class-cart__button--secondry__underline" />
                    </a>
                  )}
                </div>
                <div className="af-class-cart__summary-body w-form" ref={cartSummaryBodyRef}>
                  <div className="af-class-cart__summary-list">
                    {mainContext.cartItems.map((props, index) => (
                      <CartItem
                        key={props.id + "" + props.variationId}
                        {...props}
                      />
                    ))}
                  </div>
                </div>

                {mainContext.cartItems.length > 0 ? (
                  <div className="af-class-cart__summary-footer" ref={cartSummaryFooterRef}>
                    <CartSummaryFooter />
                    <div className="cart-order-button-container" style={{ position: 'absolute', left: 0, bottom: '-80px', width: '100%' }}>
                      <OrderButton />
                      {formInvalidAlert === true && <div className="cart-form-invalid-alert" style={{ position: 'absolute', top: '80px', border: '1px #F0F0F0 solid', padding: '25px 30px', background: '#fff', borderRadius: '15px', width: '100%', color: '#EE2A2A' }}>Пожалуйста, заполните все необходимые поля и повторно нажмите кнопку "Оформить заказ"</div>}
                    </div>
                  </div>
                ) : (
                  <div>Корзина пуста</div>
                )}
              </div>

              {/* <div
                style={{
                  border: "1px #e0e0e0 solid",
                  backgroundColor: "#fff",
                  padding: "30px",
                  borderRadius: "20px",
                  marginTop: "50px",
                }}
              >
                <p
                  className="color-branded-blue"
                  style={{ fontWeight: "bold" }}
                >
                  Изменение бонусной системы rockets.coffee
                </p>
                <p style={{ marginBottom: 0 }}>
                  Теперь, покупая любые товары в нашем интернет-магазине, вы
                  получаете кешбек баллами, а накопив достаточное количество, вы
                  сможете оплатить ими до 50% заказа
                </p>
                {/* <p className="color-branded-blue" style={{fontWeight: 'bold', marginTop: '25px'}}>Новогодние праздники и увеличенные сроки доставки</p>
                    <p style={{marginBottom: 0}}>Транспортные компании работают с перегрузками и некоторые заказы доставляются с задержкой. Учитывайте это при оформлении заказа.</p> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      { mainContext.cartItems.length > 0 && <div className="af-class-cart__form-submit-button-wrapper-mobile">
        <div className="af-class-cart__total-items-mobile-text">
          Товаров в корзине ({mainContext.cartItems.reduce((accumulator, currentValue) => accumulator + currentValue.cartQty, 0)})
        </div>
        <CartSummaryFooter />
        {formInvalidAlert === true && <div style={{ border: '1px #F0F0F0 solid', padding: '10px', marginBottom: '15px', background: '#fff', borderRadius: '15px', width: '100%', color: '#EE2A2A', fontSize: '12px' }}>Пожалуйста, заполните все необходимые поля и повторно нажмите кнопку "Оформить заказ"</div>}
        <OrderButton />
      </div> }

      {suggestedProducts.length > 0 && (
        <div className="af-class-section af-class-background-white af-class-p-cart af-class-wf-section cart-recommended-wrapper">
          <div className="af-class-container w-container">
            <div className="af-class-slider-wrapper af-class-recommend">
              <div className="cart-recommended-header">Рекомендуем</div>
              <div style={{ gridColumn: "1 / -1" }}>
                <Splide
                  className="af-class-splide af-class-splide__country-page"
                  options={{
                    perPage: 3,
                    perMove: 1,
                    gap: "15px",
                    breakpoints: { 991: { perPage: 1 } },
                    pagination: false,
                  }}
                >
                  {suggestedProducts.map((elem, index) => (
                    <SplideSlide
                      className="af-class-splide__slide"
                      key={elem.id}
                    >
                      <Product
                        key={elem.id}
                        {...elem}
                        style={{ width: "100%" }}
                        styleContainer={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#fff",
                        }}
                        styleContent={{ backgroundColor: '#F5F5F5' }}
                      />
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartView;

/* eslint-enable */
