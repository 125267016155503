import { useState, useContext } from "react";
import { MainContext } from "../../../contexts/MainContext";
import { CartFormContext } from "../../../contexts/CartFormContext";
import { fetchCertificate } from "../../../fetch/WPAPI";

const CartFormFieldCertificate = () => {
  const mainContext = useContext(MainContext);
  const cartFormContext = useContext(CartFormContext);

  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);

  const applyCertificate = () => {
    if (cartFormContext.certificate.length > 0) {
      setFailed(false);
      setFailedMessage(null);

      fetchCertificate(cartFormContext.certificate).then((result) => {
        if (result.length > 0) {
          let certificate = result[0];
          let discount =
            certificate.balance <= mainContext.cartTotalPrice
              ? certificate.balance
              : mainContext.cartTotalPrice;
          cartFormContext.setCertificateDiscount(discount);
          cartFormContext.setCertificateApplied(certificate);
        } else {
          setFailed(true);
        }
      });
    }
  };

  return (
    <div
      className={`
        af-class-cart__user-promocode-wrapper
        ${failed !== false ? " user-input-invalid" : ""}
        ${cartFormContext.certificateApplied !== false ? " user-input-valid" : ""}
      `}
    >
      <div className={`
        af-class-cart__user-field-label
        ${(cartFormContext.certificate && cartFormContext.certificate.length) && 'af-class-cart__user-field-label-active'}
      `}>Сертификат</div>
      <input
        value={cartFormContext.certificate ? cartFormContext.certificate : ''}
        onChange={(e) => cartFormContext.setCertificate(e.target.value)}
        type="text"
        className={`
          af-class-cart__user-promocode-field w-input
          ${failed !== false ? " user-input-invalid" : ""}
          ${cartFormContext.certificateApplied !== false ? " user-input-valid" : ""}
        `}
        maxLength={256}
        name="Promocode"
        data-name="Promocode"
        placeholder="Сертификат"
        id="field"
        required
      />
      <a
        onClick={applyCertificate}
        className="af-class-cart__user-promocode-button w-button"
      />
    </div>
  );
}

export default CartFormFieldCertificate