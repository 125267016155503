import React, { useState, useEffect, useContext } from 'react'
import useToken from '../../hooks/useToken.js'
import { MainContext } from '../../contexts/MainContext'
import { createCustomReview } from '../../fetch/WPAPI'

const ReviewForm = (props) => {
  const { token, setToken, nonce, setNonce } = useToken()
  const mainContext = useContext(MainContext)

  const [ user, setUser ] = useState(null)

  useEffect(() => {
    setUser(mainContext.userData)
  }, [mainContext.userData])

  // Review Popup Form
  const reviewCategories = [
    {id: 435, name: 'О доставке'},
    {id: 436, name: 'О магазине'},
    {id: 437, name: 'Об оплате'},
    {id: 438, name: 'О команде'},
  ]
  const [ reviewFormDropdown, setReviewFormDropdown ] = useState(false)
  const [ reviewFormCategory, setReviewFormCategory ] = useState(reviewCategories[0])
  const handleReviewFormChange = (value) => {
    setReviewFormDropdown(false)
    setReviewFormCategory(value)
  }
  const [ reviewFormText, setReviewFormText ] = useState('')
  const handleReviewFormTextChange = (value) => {
    setReviewFormText(value)
  }
  const [ proposedRating, setProposedRating ] = useState(0)
  const [ selectedRating, setSelectedRating ] = useState(0)
  const handleSelectedRating = (value) => {
    setSelectedRating(value)
  }
  useEffect(() => {
    if (reviewFormText.length > 10 && selectedRating > 0) {
      setReviewFormIsValid(true)
    }
  }, [reviewFormText, selectedRating])
  const [ reviewFormIsValid, setReviewFormIsValid ] = useState(false)
  const handleReviewSubmit = () => {
    if (reviewFormIsValid) {
      setProposedRating(0)
      setSelectedRating(0)

      let data = {
        acf: { оценка: selectedRating },
        kategoriya_otzivov: [ reviewFormCategory.id ],
        author: user.id,
        status: 'publish',
        content: reviewFormText,
      }

      createCustomReview(data, token.token)
        .then(result => {
          props.handleNewUserReview({
            id: result.id,
            date: result.date,
            label: reviewCategories.find(cat => result.kategoriya_otzivov.indexOf(cat.id) !== -1)?.name,
            link: null,
            rating: result.acf.оценка,
            text: result.content.rendered,
            category: reviewFormCategory.id,
          })
        })

      props.setReviewPopupOpened(false)
    }
  }
  
  return <div style={{display: props.reviewPopupOpened === true ? 'flex' : 'none', zIndex: '2147483647'}} className="af-class-popup--p-reviews">
    <div data-w-id="a727d6a9-0d07-a7b0-0c61-5f2c1a7caed1" className="af-class-div-block-13" />
      <div className="af-class-popup--p-reviews__content">
        <div className="af-class-popup--p-reviews__title">Напишите отзыв</div>
        <div className="w-layout-grid af-class-popup--p-reviews__form-wrapper">
          <div className="af-class-popup--p-reviews__button-wrapper">
            <div data-hover="false" data-delay={0} className="af-class-popup--p-reviews__button w-dropdown">
              <div className="af-class-dropdown-toggle w-dropdown-toggle" onClick={() => setReviewFormDropdown(!reviewFormDropdown)}>
                <div className="af-class-text-button-card-p_catalog">{reviewFormCategory.name}</div>
                <img src="/images/arrow-down.svg" loading="lazy" width={7} alt="" className="af-class-icon-button-card-p_catalog" />
              </div>
              <nav className={`w-dropdown-list`} style={{display: reviewFormDropdown === true ? ' block' : 'none'}}>
                <div className="af-class-dropdown-list af-class-review-popup">
                { reviewCategories.map((elem, index) => <a key={index} className="af-class-text-dropdown-grey w-dropdown-link" onClick={() => handleReviewFormChange(elem)}>{elem.name}</a>)}
                </div>
              </nav>
            </div>
          </div>
          <div className="af-class-popup--p-reviews__form-block w-form">
            <div id="email-form" name="email-form" data-name="Email Form" method="get" className="af-class-popup--p-reviews__form">
              <textarea placeholder="Комментарий" maxLength={5000} id="field-2" name="field-2" data-name="Field 2" className="af-class-popup--p-reviews__text-area w-input" value={reviewFormText} onChange={(e) => handleReviewFormTextChange(e.target.value)} />
              <div className="af-class-popup--p-reviews__raiting-wrapper--2">
                <div className="af-class-popup--p-reviews__raiting-title">Ваша оценка</div>
                <div onMouseLeave={() => setProposedRating(0)} className="w-layout-grid af-class-popup--p-reviews__raiting-stars" style={{cursor: 'pointer'}}>
                  <div onClick={() => handleSelectedRating(1)} onMouseOver={() => setProposedRating(1)} data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf350c" className="af-class-review-mark-wrapper af-class-_1">
                    <img style={{display: (proposedRating < 1 && selectedRating < 1) ? 'block': 'none'}} src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" />
                    <img style={{display: (proposedRating < 1 && selectedRating < 1) ? 'none': 'block'}} src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_1" />
                  </div>
                  <div onClick={() => handleSelectedRating(2)} onMouseOver={() => setProposedRating(2)} data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf350f" className="af-class-review-mark-wrapper af-class-_2">
                    <img style={{display: (proposedRating < 2 && selectedRating < 2) ? 'block': 'none'}} src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" />
                    <img style={{display: (proposedRating < 2 && selectedRating < 2) ? 'none': 'block'}} src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_2" />
                  </div>
                  <div onClick={() => handleSelectedRating(3)} onMouseOver={() => setProposedRating(3)} data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf3512" className="af-class-review-mark-wrapper af-class-_3">
                    <img style={{display: (proposedRating < 3 && selectedRating < 3) ? 'block': 'none'}} src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" />
                    <img style={{display: (proposedRating < 3 && selectedRating < 3) ? 'none': 'block'}} src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_3" />
                  </div>
                  <div onClick={() => handleSelectedRating(4)} onMouseOver={() => setProposedRating(4)} data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf3515" className="af-class-review-mark-wrapper af-class-_4">
                    <img style={{display: (proposedRating < 4 && selectedRating < 4) ? 'block': 'none'}} src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" />
                    <img style={{display: (proposedRating < 4 && selectedRating < 4) ? 'none': 'block'}} src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_4" />
                  </div>
                  <div onClick={() => handleSelectedRating(5)} onMouseOver={() => setProposedRating(5)} data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf3518" className="af-class-review-mark-wrapper af-class-_5">
                    <img style={{display: (proposedRating < 5 && selectedRating < 5) ? 'block': 'none'}} src="images/icon__review-star.svg" loading="lazy" alt="" className="af-class-review-star--unchecked" />
                    <img style={{display: (proposedRating < 5 && selectedRating < 5) ? 'none': 'block'}} src="images/review-star--checked.svg" loading="lazy" alt="" className="af-class-review-star--checked af-class-_5" />
                  </div>
                </div>
              </div>
              {/* <div className="w-layout-grid af-class-grid-34">
                <div className="af-class-popup--p-reviews__raiting-title">Добавить фото</div>
                <div id="w-node-_59ea9f10-b183-e37b-c72b-7c4889378024-5d3d362c" className="af-class-div-block-19"><img src="images/icon--add-photo.svg" loading="lazy" alt="" className="af-class-image-5" /></div>
              </div> */}

              <div id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432076-5d3d362c" className="af-class-cart__form-submit-button-wrapper-copy">
                <input onClick={handleReviewSubmit} type="button" value="Отправить" id="w-node-f04d8cc5-d9ba-0265-741f-a5d56c432077-5d3d362c" className={`af-class-g-submit-button w-button${(reviewFormIsValid === false) ? ' button-disabled' : ''}`} />
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => props.setReviewPopupOpened(false)} data-w-id="5945d47f-6383-fc3f-e88a-7f3e02bf3522" className="af-class-popup--p-reviews__close-icon-wrapper">
          <img src="/images/icon--close-popup.svg" loading="lazy" alt="" className="af-class-image-7" />
        </div>
      </div>
    </div>
}

export default ReviewForm