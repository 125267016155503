import { fetchWPItems, postRequestAuth, getRequestBearer, postRequestBearer, postRequestBearerFile, putRequestAuth, createInvoiceRequest, postRequestSingle, postRequestSingleCookies, postRequestSingleBearer, getRequestSingle, getRequestSingleBearerStoreNonce, postRequestSingleBearerStoreNonce, postRequestSingleBearerStoreNoncePromise, putRequestSingleBearerStoreNonce, deleteRequestSingleBearerStoreNonce, getRequestSingleBearer } from '../fetch/fetchWPItems'

export const createCustomReview = async (data, token) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/otzivi`
  return await postRequestBearer(apiUrl, data, token)
}

export const createProductReview = async (data) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/products/reviews?consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await postRequestSingle(apiUrl, data)
}

export const fetchCustomReviews = async (category) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/otzivi?per_page=100&kategoriya_otzivov=${category}`
  return await fetchWPItems(apiUrl)
}

export const fetchCurrentUserCustomReviews = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/otzivi?per_page=100&author=${id}`
  return await fetchWPItems(apiUrl)
}

export const fetchFibosearch = async (query) => {
  let apiUrl = `https://panel.rockets.coffee/wp-content/plugins/ajax-search-for-woocommerce-premium/includes/Engines/TNTSearchMySQL/Endpoints/search.php?s=${query}`
  return await createInvoiceRequest(apiUrl)
}

export const authorizeUser = async (email, password) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/jwt-auth/v1/token?username=${email}&password=${password}`
  return await postRequestAuth(apiUrl, {
    email,
    password
  })
}

export async function getSummMCRM(phone) {
  const key = '3fed0fbe11167841bd8fe7fd75dbab024c7734102d37fc6f568ba5324da15b72'
  const apiUrl = `https://rockets.marketingcrm.online/cashapi/getcard?number=${phone}&key=${key}`

  let result = await fetch(apiUrl, {
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
  })

  return await result.json()
}


export async function verifyMCRM(phone) {
  const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
  const data = {
    'phone': phone
  }
  const apiUrl = `https://rockets.marketingcrm.online/userapi/verify?resto_key=${resto_key}`
  const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

  let result = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    body: formBody
  })

  return await result.json()
}
export async function recoverMCRM(phone) {
  const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
  const data = {
    'login': phone
  }
  const apiUrl = `https://rockets.marketingcrm.online/userapi/recover?resto_key=${resto_key}`
  const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

  let result = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    body: formBody
  })

  return await result.json()
}


export const registerMCRM = async (user) => {
  const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
  const apiUrl = `https://rockets.marketingcrm.online/userapi/register?resto_key=${resto_key}`
  const formBody = Object.keys(user).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(user[key])).join('&');

  let result = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    body: formBody
  })

  return await result.json()
}

export const authMCRM = async (data) => {
  const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
  const apiUrl = `https://rockets.marketingcrm.online/userapi/userauth?resto_key=${resto_key}`
  const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

  let result = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    body: formBody
  })

  return await result.json()
}

export const getUserMCRM = async () => {
  const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
  const access_token = localStorage.getItem('access')
  const apiUrl = `https://rockets.marketingcrm.online/userapi/getuserdata?resto_key=${resto_key}&access_token=${access_token}`

  let result = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
  })

  return await result.json()
}

export const getBonusesMCRM = async () => {
  const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
  const access_token = localStorage.getItem('access')
  const apiUrl = `https://rockets.marketingcrm.online/userapi/getbalance?resto_key=${resto_key}&access_token=${access_token}`


  let result = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },

  })

  return await result.json()
}

// export const payBonusesMCRM = async (data) => {
//   const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
//   const access_token = localStorage.getItem('access')
//   const apiUrl = `https://rockets.marketingcrm.online/userapi/payorder?resto_key=${resto_key}&access_token=${access_token}`
//   const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');


//   let result = await fetch(apiUrl, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
//     body: formBody
//   })

//   return await result.json()
// }

export const payBonusesMCRM = async (data) => {
  const resto_key = '4561f6f551961c7b868b48ac5113e9102e81598ac6a8989e4f1cb3408ea6de77'
  const access_token = localStorage.getItem('access')
  const apiUrl = `https://rockets.marketingcrm.online/api/v3/cash/spendbonus?api_key=${resto_key}`

  let result = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })

  return await result.json()
}

export const createUser = async (email, password, username, phone, first_name, last_name) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/register-user`
  return await postRequestSingle(apiUrl, {
    email: email,
    password: password,
    username: username,
    billing: {
      phone: phone,
      first_name: first_name,
      last_name: last_name
    }
  })
}

export const fetchCurrentUser = async (token) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/users/me`
  return await getRequestBearer(apiUrl, token)
}

export const fetchCurrentUserNonce = async (token) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/get-nonce`
  return await getRequestBearer(apiUrl, token)
}

export const updateCurrentUser = async (id, data) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/customers/${id}?consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await putRequestAuth(apiUrl, data)
}

export const updateCurrentWpUser = async (id, data, token) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/users/${id}`
  return await postRequestBearer(apiUrl, data, token)
}

export const uploadWpMedia = async (data, token, headers) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/media`
  return await postRequestBearerFile(apiUrl, data, token, headers)
}

export const fetchCurrentUserData = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/customers/${id}?consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchCurrentUserOrders = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/orders?customer=${id}`
  return await fetchWPItems(apiUrl)
}

export const fetchProducts = async (categoryId) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/public-woo/v1/products?status=publish&per_page=100&category=${categoryId}` // &attribute=pa_geo&attribute_term=86
  return await fetchWPItems(apiUrl)
}

export const fetchProductsMultipleCategories = async (categories) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/public-woo/v1/products?status=publish&per_page=100&category=${categories.join(',')}` // &attribute=pa_geo&attribute_term=86
  return await fetchWPItems(apiUrl)
}

export const fetchCurrentUserReviews = async (email) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/products/reviews?reviewer_email=${email}&status=approved&per_page=100&consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchReviews = async () => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/products/reviews?status=approved&per_page=100&consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchProductsById = async (ids) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/public-woo/v1/products?status=publish&per_page=100&include=${ids.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchProductsBySlug = async (slugs) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/public-woo/v1/products?status=publish&per_page=100&slug=${slugs.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchProductReviews = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/products/reviews?product=${id}&status=all&consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchProductCategories = async () => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/products/categories?per_page=100&consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchProductCategory = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/products/categories/${id}?consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchPage = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/pages/${id}`
  return await fetchWPItems(apiUrl)
}

export const fetchPost = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/posts/${id}`
  return await fetchWPItems(apiUrl)
}

export const fetchPosts = async (ids) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/posts?status=publish&per_page=100&include=${ids.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchPostsBySlug = async (slugs) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/posts?status=publish&per_page=100&slug=${slugs.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchPostsByCategory = async (categories) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/posts?status=publish&per_page=100&categories=${categories.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchCategories = async (categories) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/categories?per_page=100&include=${categories.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchCustomCategories = async (categories) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/kategory?per_page=100&include=${categories.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchCustomPosts = async (postType, searchQuery = '') => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/${postType}?per_page=100&status=publish&search=${searchQuery}`
  return await fetchWPItems(apiUrl)
}

export const fetchCustomPost = async (postType, id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/${postType}/${id}?status=publish`
  return await fetchWPItems(apiUrl)
}

export const fetchCustomPostsBySlug = async (postType, slugs) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/${postType}?status=publish&slug=${slugs.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchMedia = async (id) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/media/${id}`
  return await fetchWPItems(apiUrl)
}

export const fetchMedias = async (ids) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/media/?per_page=100&include=${ids.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchUsers = async (ids) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wp/v2/users/?per_page=100&include=${ids.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchUsersMeta = async (ids) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/get-users-full?per_page=100&include=${ids.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchUsersIdsByEmails = async (emails) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/get-users-ids-by-emails?per_page=100&include=${emails.join(',')}`
  return await fetchWPItems(apiUrl)
}

export const fetchCustomers = async (ids) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/customers?per_page=100&include=${ids.join(',')}&consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchCoupon = async (code) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/coupons?code=${code}&status=publish&consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const fetchCertificate = async (code) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-pimwick/v1/pw-gift-cards?number=${code}&consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await fetchWPItems(apiUrl)
}

export const createPayment = async (orderId) => {
  let apiUrl = `https://panel.rockets.coffee/rbsapi/pay?token=LJNdou3JNjsbfno4uthljNJBJBJBDe3r83fKNksfKNDSDN83rjns&order_id=${orderId}`
  return await createInvoiceRequest(apiUrl)
}

export const createOrder = async (orderData, token = null, nonce = null) => {
  let paymentType = 'cod'
  let paymentTypeName = 'Оплата при получении'
  let paid = false
  switch (orderData.paymentType) {
    case 'card':
      paymentType = 'rbspayment'
      paymentTypeName = 'Оплата онлайн банковской картой'
      break
    case 'cash':
      paymentType = 'cod'
      paymentTypeName = 'Оплата при получении'
      paid = true
      break
    case 'wire':
      paymentType = 'bacs'
      paymentTypeName = 'Прямой банковский перевод'
      break
  }

  let address2 = []
  // if (orderData.address.block.length > 0) {
  //   address2.push('корпус ' + orderData.address.block)
  // }
  // if (orderData.address.apartment.length > 0) {
  //   address2.push('квартира ' + orderData.address.apartment)
  // }
  if (orderData.address?.entrance?.length > 0) {
    address2.push('подъезд ' + orderData.address.entrance)
  }
  if (orderData.address?.floor?.length > 0) {
    address2.push('этаж ' + orderData.address.floor)
  }
  if (orderData.address?.flat?.length > 0) {
    address2.push('квартира ' + orderData.address.flat)
  }
  if (orderData.address?.homephone?.length > 0) {
    address2.push('домофон ' + orderData.address.homephone)
  }
  address2 = address2.join(', ')

  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/orders`
  // let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/orders`
  let data = {
    payment_method: paymentType,
    payment_method_title: paymentTypeName,
    status: orderData.status,
    set_paid: paid,
    customer_id: orderData.customerId,
    billing: {
      first_name: orderData.customer.name,
      last_name: orderData.customer.surname,
      address_1: orderData.address.fullAddress || '',
      address_2: address2,
      city: orderData.city,
      email: orderData.customer.email,
      phone: orderData.customer.phone
    },
    shipping: {
      first_name: orderData.customer.name,
      last_name: orderData.customer.surname,
      address_1: orderData.address.fullAddress || '',
      address_2: address2,
      city: orderData.city,
    },
    line_items: orderData.items.map(elem => {
      return {
        product_id: elem.id,
        variation_id: elem.variationId,
        quantity: elem.cartQty === false ? 1 : elem.cartQty
      }
    }),
    customer_note: orderData.comments,
    coupon_discount: orderData.promoCodeDiscount,
    ym_client_id: orderData.ymClientID,
    // shipping_lines: [
    //   {
    //     method_title: orderData.shipping,
    //   }
    // ],
  }

  if (orderData.promoCode !== false) {
    data['coupon_lines'] = [
      {
        code: orderData.promoCode.code,
      }
    ]
  }

  if (orderData.certificate !== false) {
    data['certificate'] = [
      {
        number: orderData.certificate.number,
        balance: orderData.certificateDiscount,
        pimwick_gift_card_id: orderData.certificate.pimwick_gift_card_id,
      }
    ]
  }

  if (orderData.formBonuses) {
    data['bonuses'] = orderData.formBonuses
  }

  if (orderData.deliveryCostDiscounted) {
    data['delivery_fee'] = orderData.deliveryCostDiscounted
  }

  //console.log(data)
  //console.log(orderData)
  // console.log(token)
  // console.log(nonce)

  if (!token || !nonce) {
    return await postRequestSingle(apiUrl, data)
  } else {
    return await postRequestSingleBearerStoreNonce(apiUrl, data, token, nonce)
  }
}

export const updateOrder = async (cartOrderId, data) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/v3/orders/${cartOrderId}?consumer_key=${process.env.REACT_APP_WOO_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
  return await postRequestAuth(apiUrl, data)
}

export const login = async () => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/public-woo/v1/products?status=publish&per_page=100`
  return await fetchWPItems(apiUrl)
}

export const getCDEKPoints = async (city, country = null, postal_code = null) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/cdek-delivery-points?city=${city}&country=${country}&postal_code=${postal_code}`
  return await fetchWPItems(apiUrl)
}

export const getYandexPoints = async (city) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/yandex-delivery-points?city=${city}`
  return await fetchWPItems(apiUrl)
}

export const getCDEKPrice = async (tariff, to_city, to_postal_code, to_address, weight, to_country = null) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/cdek-delivery-cost?to_location_city=${to_city}&to_postal_code=${to_postal_code}&to_location_address=${to_address}&weight=${weight}&tariff_code=${tariff}&to_location_country=${to_country}`
  return await fetchWPItems(apiUrl)
}

export const getDalliPrice = async (to_address, weight) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/dalli-delivery-cost?to_location_address=${to_address}&weight=${weight}`
  return await fetchWPItems(apiUrl)
}

export const getRussianPostPrice = async (fias, weight) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/russian-post-delivery-cost?fias=${fias}&weight=${weight}`
  return await fetchWPItems(apiUrl)
}

export const getYandexPriceCourier = async (to_address, weight) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/yandex-delivery-cost-courier?to_location_address=${to_address}&weight=${weight}`
  return await fetchWPItems(apiUrl)
}

export const getYandexPricePvz = async (to_station_id, weight) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/yandex-delivery-cost-pvz?to_station_id=${to_station_id}&weight=${weight}`
  return await fetchWPItems(apiUrl)
}

export const getCitiesSuggestions = async (part) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/get-cities-suggestions?part=${part}`
  return await fetchWPItems(apiUrl)
}

export const fetchRecoveryKey = async (email) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/reset-password?email=${email}`
  return await getRequestSingle(apiUrl)
}

export const processRecoveryKey = async (email, key) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/check-reset-password?email=${email}&key=${key}`
  return await getRequestSingle(apiUrl)
}

export const wooAddToCart = async (data, token, nonce) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/store/v1/cart/add-item`
  return postRequestSingleBearerStoreNoncePromise(apiUrl, data, token, nonce)
}

export const wooEditCartItem = async (key, data, token, nonce) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/store/v1/cart/items/${key}?quantity=${data.quantity}`
  return await putRequestSingleBearerStoreNonce(apiUrl, token, nonce)
}

export const wooDeleteCartItem = async (key, token, nonce) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/store/v1/cart/items/${key}`
  return await deleteRequestSingleBearerStoreNonce(apiUrl, token, nonce)
}

export const wooClearCart = async (token, nonce) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/store/v1/cart/items`
  return await deleteRequestSingleBearerStoreNonce(apiUrl, token, nonce)
}

export const wooCartApplyCoupon = async (coupon, token, nonce) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/store/v1/cart/apply-coupon?code=${coupon}`
  return postRequestSingleBearerStoreNonce(apiUrl, {}, token, nonce)
}

export const wooCartRemoveCoupon = async (coupon, token, nonce) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc/store/v1/cart/remove-coupon?code=${coupon}`
  return postRequestSingleBearerStoreNonce(apiUrl, {}, token, nonce)
}

export const getCurrentUserLoyalty = async (token) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/get-user-loyalty`
  return getRequestSingleBearer(apiUrl, token)
}

export const sendContactsForm = async (data) => {
  let apiUrl = `https://ingeni.app/15/sites/martios.store/`
  postRequestSingle(apiUrl, data)
}

export const fetchFullVariations = async () => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/get-full-variations`
  return await fetchWPItems(apiUrl)
}
export const getYandexDeliveryDates = async (city) => {
  let apiUrl = `https://panel.rockets.coffee/wp-json/wc-custom-react/v1/yandex-get-delivery-dates?city=${city}`
  const res =  await fetchWPItems(apiUrl)
    return res['from']
}
