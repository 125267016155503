/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { createScope, map, transformProxies } from './helpers'
import { useParams, useNavigate } from 'react-router-dom'
import { fetchPost, fetchCustomPost, fetchCustomPostsBySlug, fetchMedia } from '../fetch/WPAPI'

const EducationOneView = () => {

  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()

  const params = useParams()

  const [ post, setPost ] = useState({})
  const [ media, setMedia ] = useState({})

  useEffect(() => {
    fetchCustomPostsBySlug('znania', [params.slug])
      .then(result => {
        // fetchMedia(result.featured_media)
        //   .then(result => {
        //     console.log(result)
        //     setMedia(result)
        //   })
        if (result.length > 0) {
          setPost(result[0])
        }

        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    document.title = post?.yoast_head_json?.title
    document.querySelector('meta[name="description"]').setAttribute("content", post?.yoast_head_json?.description)

    document.querySelectorAll('.af-class-eduction--one__content img').forEach(elem => {
      elem.removeAttribute('width')
      elem.removeAttribute('height')
    })
  }, [post])

  return <>
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-container w-container">
                <h1 className="af-class-title-main-p_knowledge" dangerouslySetInnerHTML={{__html: post?.title?.rendered}}></h1>
                <div className="w-layout-grid af-class-grid-main-p_knowledge">
                  <div id="w-node-_5a589d89-8e48-616c-2369-8ba3f6e3c497-c03d363c" className="w-layout-grid af-class-text_and_anchors">
                    <div style={{cursor: 'pointer'}} onClick={() => navigate(`/knowledge`)} id="w-node-_72aea978-0b64-cd2e-2c41-dca9fe8ee47c-f83d3637" className="w-layout-grid af-class-eduction--one__back-button">
                      <img src="/images/icon--back.svg" loading="lazy" id="w-node-_4e5eb958-1004-43c7-3367-1f9b84b5fb54-f83d3637" alt="" />
                      <div id="w-node-_1831b2f5-b254-3fba-1ef8-ea7446b44bd4-f83d3637" className="af-class-text-block-55">Назад ко всем знаниям</div>
                    </div>
                  </div>

                  <div className="w-layout-grid af-class-eduction--one__content" style={{maxWidth: '800px'}}>
                    
                    { isLoading === true && <div className="loading-animation"><img src="/images/loading-rocket.svg" alt="" /></div> }

                    {/* <h1 className="af-class-page-title" dangerouslySetInnerHTML={{__html: post?.title?.rendered}}></h1> */}
                    <div id="w-node-_747bcdc6-9fa5-bf3b-6f14-e8c1a9e1e221-f83d3637" className="w-layout-grid af-class-eduction--one__list">
                      <div dangerouslySetInnerHTML={{__html: post?.content?.rendered}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>
}

export default EducationOneView

/* eslint-enable */