import { useState } from 'react';
import CatalogPage from '../components/Catalog/CatalogPage';

export default function CatalogViewNew() {
  const [categoryFiltersQuick, setCategoryFiltersQuick] = useState([]);

  const categoryFilters = [
    {
      id: 9992,
      name: 'Тип аксессуара',
      options: [
        'Кофемолки',
        'Аксессуары для заваривания',
        'Аксессуары бариста',
        'Весы',
        'Посуда',
        'Бумажные фильтры',
        'Фильтры для воды',
        'Мерч',
        'Химия',
      ]
    },
  ];

  return <CatalogPage
    categoryId={23}
    categoryFiltersQuick={categoryFiltersQuick}
    setCategoryFiltersQuick={setCategoryFiltersQuick}
    categoryFilters={categoryFilters}
    filterByAttributes={[26]}
  />
}