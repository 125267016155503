import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MainContext } from '../../contexts/MainContext'
import { useNavigate } from 'react-router-dom'

const Footer = (props) => {
  const navigate = useNavigate()
  const mainContext = useContext(MainContext)

    return (
            <footer bind="eabf7b61-8869-1054-eae6-fa0f0f7c5e13" className="af-class-section af-class-footer af-class-an-scroll-into-view af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="w-layout-grid af-class-grid">
                  <div id="w-node-c1383a80-b8fd-68cd-8a2b-5d80e2e6e3eb-0f7c5e13" className="af-class-footer__links-block af-class-_1">
                    <div className="af-class-footer__products_links-block">
                      <a className="af-class-footer__main-link af-class-title">Продукция</a>
                      <Link to="/catalog/coffee" className="af-class-footer_link">Кофе</Link>
                      <Link to="/catalog/tea#" className="af-class-footer_link">Чай</Link>
                      <Link to="/catalog/accessories#" className="af-class-footer_link">Аксессуары</Link>
                      <Link to="/catalog/merch#" className="af-class-footer_link">Мерч</Link>
                    </div>
                    <div className="af-class-footer__knowledgelinks-block">
                      <a className="af-class-footer__main-link af-class-title">Образование</a>
                      <Link to="/education-all#" className="af-class-footer_link">Обучение</Link>
                      <Link to="/knowledge#" className="af-class-footer_link">Знания</Link>
                      <Link to="/coffee-dictionary#" className="af-class-footer_link">Кофейный словарь</Link>
                    </div>
                  </div>
                  <div id="w-node-_0ffd5765-9fa9-4657-df2b-801b6a96a2cc-0f7c5e13" className="af-class-footer__links-block af-class-_2">
                    <a href='https://b2b-rockets.coffee' target='_blank' className="af-class-footer__main-link">Сотрудничество</a>
                    <Link to="/loyalty-system#" className="af-class-footer__main-link">Бонусная система</Link>
                    <Link to='/coffee-shops#' className="af-class-footer__main-link">Наши проекты</Link>
                    <Link to="/shipping-and-payment#" className="af-class-footer__main-link af-class-other">Доставка и оплата</Link>
                    <Link to='/reviews#' className="af-class-footer__main-link af-class-other">Отзывы</Link>
                    <Link to="/faq#" className="af-class-footer__main-link af-class-other">FAQ</Link>
                    <Link to='/about-us#' className="af-class-footer__main-link af-class-other">О нас</Link>
                    <Link to="/contacts#" className="af-class-footer__main-link">Контакты</Link>

                    {/* <Link to={`/y8ipgm`} className="af-class-footer__main-link">Прайс для Б2Б 1</Link>
                    <Link to={`/crcyd3`} className="af-class-footer__main-link">Прайс для Б2Б 2</Link>
                    <Link to={`/price-distributor`} className="af-class-footer__main-link">Прайс для Б2Б 3</Link>
                    <Link to={`/price-south-distribution`} className="af-class-footer__main-link">Прайс для Б2Б 4</Link> */}
                  </div>
                  <div id="w-node-edd8bb71-d868-c442-a892-a5d76fbeb38c-0f7c5e13" className="af-class-footer__links-block af-class-_3">
                    <div className="af-class-footer__phones_links-block">
                      <div className="af-class-foote_links_label">Для звонков по России</div>
                      <a href={`tel:${mainContext.homePageData?.acf?.номер_в_подвале}`} className="af-class-footer__main-link">{mainContext.homePageData?.acf?.номер_в_подвале}</a>
                    </div>
                    {/* <div className="af-class-footer__phones_links-block">
                      <div className="af-class-foote_links_label">Для звонков по Москве</div>
                      <a href="tel:8495108-11-44" className="af-class-footer__main-link">8 495 108-11-44</a>
                    </div> */}
                    <div className="af-class-footer__work-time-block">
                      <div className="af-class-foote_links_label">Мы принимаем звонки</div>
                      <a href="#" className="af-class-footer__main-link">Ежедневно: c 10-00 до 18-00</a>
                      
                      <div className="hide-on-biggest-screens">
                        <div className="af-class-footer__adres-block">
                          <div className="af-class-foote_links_label">По общим вопросам</div>
                          <a href="mailto:info@rockets.coffee" className="af-class-footer__main-link">info@rockets.coffee</a>
                        </div>

                        <div className="af-class-footer__adres-block">
                          <div className="af-class-foote_links_label">По заказам интернет-магазина или для обратной связи</div>
                          <a href="mailto:shop@rockets.coffee" className="af-class-footer__main-link">shop@rockets.coffee</a>
                        </div>

                        <div className="af-class-footer__adres-block">
                          <div className="af-class-foote_links_label">По вопросам сотрудничества</div>
                          <a href="mailto:b2b@rockets.coffee" className="af-class-footer__main-link">b2b@rockets.coffee</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="w-node-d3f843af-27e9-e0f7-87fd-1b168e636fa6-0f7c5e13" className="af-class-footer__links-block af-class-_4">
                    <div className="af-class-footer__adres-block">
                      <div className="af-class-foote_links_label">Адрес производства</div>
                      <span className="af-class-footer__main-link">108820, Россия, Москва,<br />п. завода Мосрентген,<br />ул. Героя России Соломатина,<br /> д. 6, к. 23</span>
                    </div>
                    <div className="af-class-footer__adres-block">
                      <div className="af-class-foote_links_label">Адрес офиса</div>
                      <span className="af-class-footer__main-link">115054 Москва<br />ул. Валовая, 35</span>
                    </div>
                    <div className="af-class-footer__social_links-block">
                      {/* <a href="tel:8800777-04-14" className="af-class-footer__main-link af-class-socials">Facebook</a>
                      <a href="tel:8800777-04-14" className="af-class-footer__main-link af-class-socials">Vkontakte</a>
                      <a href="tel:8800777-04-14" className="af-class-footer__main-link af-class-socials">Instagram</a> */}
                      <a href="https://t.me/rocketscoffee" className="af-class-footer__main-link af-class-socials">Telegram</a>
                    </div>
                  </div>
                  <div id="w-node-_226d6b27-0b12-d460-55c9-04b051bef1ca-0f7c5e13" className="af-class-footer__links-block af-class-desktop-max af-class-_5">
                    
                    <div className="af-class-footer__adres-block">
                      <div className="af-class-foote_links_label">По общим вопросам</div>
                      <a href="mailto:info@rockets.coffee" className="af-class-footer__main-link">info@rockets.coffee</a>
                    </div>

                    <div className="af-class-footer__adres-block">
                      <div className="af-class-foote_links_label">По заказам интернет-магазина или для обратной связи</div>
                      <a href="mailto:shop@rockets.coffee" className="af-class-footer__main-link">shop@rockets.coffee</a>
                    </div>

                    <div className="af-class-footer__adres-block">
                      <div className="af-class-foote_links_label">По вопросам сотрудничества</div>
                      <a href="mailto:b2b@rockets.coffee" className="af-class-footer__main-link">b2b@rockets.coffee</a>
                    </div>

                    <div className="af-class-footer__social_links-block af-class-breakpoint-max">
                      {/* <a href="tel:8800777-04-14" className="af-class-footer__main-link">Facebook</a>
                      <a href="tel:8800777-04-14" className="af-class-footer__main-link">Vkontakte</a>
                      <a href="tel:8800777-04-14" className="af-class-footer__main-link">Instagram</a> */}
                      <a href="https://t.me/rocketscoffee" className="af-class-footer__main-link af-class-socials">Telegram</a>
                    </div>
                  </div>
                  <div id="w-node-fb22d405-1ad3-b120-0530-899d0839fea5-0f7c5e13" className="af-class-dvivider-footer" />
                  <div id="w-node-fb3868c5-6258-6d77-cf9d-999c5d39a158-0f7c5e13" className="af-class-links-copyright-wrapper-footer">
                    <div className="af-class-footer__legal-text">Copyright 2014-{new Date().getFullYear()} ROCKETS COFFEE ROASTERS</div>
                    <div id="w-node-_24409c14-6f5d-d704-bb0f-50ad554e6012-0f7c5e13" className="af-class-footer__legal-text">Rockets ® is a registered Trademark</div>
                    <a onClick={() => navigate(`/user-agreement`)} id="w-node-a609fb3c-85fa-4d47-fd94-1ab289b8b18b-0f7c5e13" href="#" className="af-class-footer__legal-text af-class-user_agreement">Пользовательское соглашение</a>
                  </div>
                  <div id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bacb-0f7c5e13" className="af-class-logos-payment-footer">
                    <div id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bacc-0f7c5e13" className="af-class-foooter__paymens-block">
                      {/* <img src="/images/footer-logo-sber.jpg" loading="lazy" id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bacd-0f7c5e13" alt="" className="af-class-foooter__paymens-img" style={{height: '30px'}} /> */}
                      <img src="/images/footer-logo-cards.jpg" loading="lazy" id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bacd-0f7c5e13" alt="" className="af-class-foooter__paymens-img" style={{height: '30px'}} />
                      {/* <img src="/images/master-card.svg" loading="lazy" id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bacd-0f7c5e13" alt="" className="af-class-foooter__paymens-img" />
                      <img src="/images/visa.svg" loading="lazy" id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bace-0f7c5e13" alt="" className="af-class-foooter__paymens-img" />
                      <img src="/images/paykeeper.svg" loading="lazy" id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bacf-0f7c5e13" alt="" className="af-class-foooter__paymens-img" /> */}
                    </div>
                    {/* <div id="w-node-d907eb4e-895b-81b0-db34-e3b4a5e7bad0-0f7c5e13" className="af-class-developer__name af-class-_2">
                      Дизайн сайта <a href="https://www.agency.uprock.ru/" target="_blank" className="af-class-developer__link">UPROCK</a><br />
                      Сайт разработан в <a href="https://annyjess.ru/" target="_blank" className="af-class-developer__link">Энни’Джесс</a>
                    </div> */}
                  </div>
                  <a onClick={() => navigate(`/user-agreement`)} id="w-node-_9ca922b6-0364-1e5c-edc2-12a1426c2940-0f7c5e13" href="#" className="af-class-footer__legal-text af-class-user_agreement af-class-_2">Пользовательское соглашение</a>
                  {/* <div id="w-node-_7540bf0b-cfd9-632a-a45d-e9bea2e7c501-0f7c5e13" className="af-class-developer__name">
                    Дизайн сайта <a href="https://www.agency.uprock.ru/" target="_blank" className="af-class-developer__link">UPROCK</a><br />
                    Сайт разработан в <a href="https://annyjess.ru/" target="_blank" className="af-class-developer__link">Энни’Джесс</a>
                  </div> */}
                </div>
              </div>
            </footer>
    )
}

export default Footer